/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GoogleRpcStatus } from './google-rpc-status';


/**
 * Info for CompReel created with this label.
 */
export interface ApiCompReelData { 
    /**
     * Output only. CompReel content state, this will be present when a CompReel is created and may change when it is processing.
     */
    readonly contentCurrent?: ApiCompReelData.ContentCurrentEnum;
    /**
     * Output only. CompReel Create Time.
     */
    readonly createTime?: string;
    errorDetails?: GoogleRpcStatus;
    /**
     * Output only. CompReel OnPrem filepath relative to the export folder.
     */
    readonly filepath?: string;
    /**
     * Output only. CompReel ID.
     */
    readonly id?: string;
    /**
     * Output only. CompReel processing state.
     */
    readonly state?: ApiCompReelData.StateEnum;
    /**
     * Output only. CompReel Update Time.
     */
    readonly updateTime?: string;
}
export namespace ApiCompReelData {
    export type ContentCurrentEnum = 'COMP_REEL_CONTENT_STATE_UNSPECIFIED' | 'COMP_REEL_CURRENT' | 'COMP_REEL_STALE';
    export const ContentCurrentEnum = {
        COMP_REEL_CONTENT_STATE_UNSPECIFIED: 'COMP_REEL_CONTENT_STATE_UNSPECIFIED' as ContentCurrentEnum,
        COMP_REEL_CURRENT: 'COMP_REEL_CURRENT' as ContentCurrentEnum,
        COMP_REEL_STALE: 'COMP_REEL_STALE' as ContentCurrentEnum
    };
    export type StateEnum = 'COMP_REEL_STATE_UNSPECIFIED' | 'COMP_REEL_PENDING' | 'COMP_REEL_FAILED' | 'COMP_REEL_READY';
    export const StateEnum = {
        COMP_REEL_STATE_UNSPECIFIED: 'COMP_REEL_STATE_UNSPECIFIED' as StateEnum,
        COMP_REEL_PENDING: 'COMP_REEL_PENDING' as StateEnum,
        COMP_REEL_FAILED: 'COMP_REEL_FAILED' as StateEnum,
        COMP_REEL_READY: 'COMP_REEL_READY' as StateEnum
    };
}


