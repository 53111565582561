import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

import {ErrorService} from '../error_service/error_service';
import {BinService} from '../services/bin.service';
import {SnackBarService} from '../services/snackbar_service';

/** Clipbin creation dialog */
@Component({
  selector: 'mam-create-bin-dialog',
  templateUrl: './create_bin_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
    @import './shared/mixins';
    :host {
      max-width: 502px;
      width: 70vw;
      display: block;

      @include breakpoint-md-max {
        max-width: 95vw;
        width: 65vw;
        }

      @include breakpoint-sm-max {
        width: initial;
        max-width: initial;
      }
      }`
  ]
})
export class CreateBinDialog {
  static readonly dialogOptions = {
    hasBackdrop: true,
  };

  constructor(
      snackBar: SnackBarService,
      errorService: ErrorService,
      binService: BinService,
      readonly dialogRef: MatDialogRef<CreateBinDialog>,
  ) {
    dialogRef.afterClosed().subscribe((name?: string) => {
      if (!name?.trim()) return;
      binService.create(name).subscribe({
        next: () => {
          snackBar.message('Clip bin has been created successfully');
        },
        error: (error: unknown) => {
          snackBar.error('Clip bin could not be created.', undefined, error);
        }
      });
    });
  }
}
