import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {CloudTransferOperation, Folder} from 'models';

import {DialogService} from '../services/dialog_service';
import {ApiCloudTransferOperationStateEnum, ApiStorageTransferInfoJobStateEnum} from '../services/ias_types';

const ALL_COLUMNS = [
  'status',
  'size',
  'duration',
  'startTime',
  'endTime',
] as const;

/** Details panel displayed when a transfer task row is expanded. */
@Component({
  selector: 'mam-cloud-ingest-details',
  templateUrl: './cloud_ingest_details.ng.html',
  styleUrls: ['./cloud_ingest_details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloudIngestDetails {
  @Input() folder?: Folder;

  @Input() runs?: CloudTransferOperation[] = [];

  displayedColumns = ALL_COLUMNS;

  readonly ApiCloudTransferOperationStateEnum =
      ApiCloudTransferOperationStateEnum;

  constructor(
      private readonly dialogService: DialogService,
  ) {}

  isJobEnabled(): boolean {
    return this.folder?.storageTransferInfo?.jobState ===
        ApiStorageTransferInfoJobStateEnum.ENABLED;
  }

  getDurationInSecond(startTime: string, endTime: string): number {
    return (Date.parse(endTime) - Date.parse(startTime)) / 1000;
  }

  clickOnColumn(run: CloudTransferOperation) {
    if (run.operationState === ApiCloudTransferOperationStateEnum.FAILED) {
      this.openErrorDetailsDialog(run.statusInfo);
    }
  }

  private openErrorDetailsDialog(statusInfo: Record<string, string>) {
    this.dialogService.showMessage({
      title: 'Error Details',
      question: JSON.stringify(statusInfo, null, 2),
    });
  }

  trackByRow(index: number, row: CloudTransferOperation) {
    return row.transferJobId;
  }
}
