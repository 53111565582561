import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Sort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {DateTime} from 'luxon';
import {BehaviorSubject, firstValueFrom, merge, Observable, of, Subject} from 'rxjs';
import {debounceTime, filter, finalize, map, shareReplay, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {assumeExhaustiveAllowing} from 'asserts/asserts';
import {FeatureFlagService} from 'feature_flag/feature_flag_service';
import { Site } from 'models';

import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {ActiveItems, StagingService, StagingView} from '../right_panel/staging_service';
import {AssetState, MetadataField} from '../services/asset_api_service';
import {AssetCopy, AssetService, Original} from '../services/asset_service';
import { MediaCacheService } from '../services/media_cache_service';
import {ProgressbarService} from '../services/progressbar_service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';
import {TableUtils} from '../services/table_utils';
import {TimezoneService} from '../services/timezone_service';


import {StagingTable} from './staging_table_base';


const enum LiveAssetIcon {
  SPINNER = 'spinner',
  APPROVED = 'check',
  ERROR = 'error',
  DEFAULT = 'youtube_live'
}

/** All available columns for live staging table. */
const ALL_COLUMNS = [
  'select',
  'title',
  'source',
  'type',
  'description',
  'sport',
  'start',
  'end',
  'camera',
  'courtesy',
  'cutdown',
  'expand'
] as const;

type Column = typeof ALL_COLUMNS[number];

/** Provides metadata field name based on table column name. */
const COLUMN_TO_METADATA_KEY = {
  camera: MetadataField.CAMERA_ANGLE_TYPE,
  courtesy: MetadataField.COURTESY,
  description: MetadataField.DESCRIPTION,
  sport: MetadataField.SPORT,
  type: MetadataField.CONTENT_TYPE,
  title: MetadataField.TITLE
} as const;

const DEFAULT_SORT: Sort = {
  active: 'title',
  direction: 'asc'
};

/** Url param name for selected date in Live Staging. */
export const LIVE_STAGING_DATE_PARAM = 'lsDate';

/** 'All sites' option (for the site filter). */
export const ALL_SITES_OPTION = {siteId: 'All Sites'} as Site;

/**
 * Live content staging table.
 */
@Component({
  selector: 'mam-live-staging-table',
  templateUrl: './live_staging_table.ng.html',
  styleUrls: ['./live_staging_table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveStagingTable extends StagingTable implements OnInit {
  readonly COLUMN_TO_METADATA_KEY = COLUMN_TO_METADATA_KEY;

  displayedColumns: Column[] = [...ALL_COLUMNS];

  currentSort: Sort = DEFAULT_SORT;

  /** Form control for search input. */
  readonly search = new FormControl<string>('');

  /** Current query entered by the user that is be used in asset request. */
  userQuery = '';

  readonly selectedDate$: Observable<DateTime>;

  readonly selectedDateLabel$: Observable<string>;

  readonly todayButtonDisabled$: Observable<boolean>;

  /** On emission triggers table refresh. */
  readonly refresh$ = new Subject<void>();

  readonly expandedAssets = new Set<string>();

  readonly view: StagingView = 'live';

  /** Flag to show assets' source column. */
  readonly showAssetsSource = this.featureService.featureOn('show-user-information');

  /** Flag to show site/source filter. */
  readonly enableSiteFilter = this.featureService.featureOn('enable-site-filter');

  /**
   * Cutdown cache for cutdown details component to restore their state from
   * when they are re-created.
   * Having the cache on the LiveStagingTable component allows for simple
   * lifecycle management of the cache.
   */
  readonly cutdownCache = new Map<string, AssetCopy[]>();

  /** Selectable sites (possible values for the site filter). */
  readonly selectableSites$ = this.mediaCache.state.selectableSites$;

  /** 'All sites' option (for the site filter). */
  allSitesOption = ALL_SITES_OPTION;

  /** Selected site (in the site filter). */
  selectedSite$ = new BehaviorSubject<Site>(this.allSitesOption);

  /**
   * Assets filtered by selected site.
   * This property is used to show/render assets data on the page instead of the
   * 'assets' property defined in the parent class.
   */
  filteredAssets: Original[] = [];


  constructor(
      private readonly timezoneService: TimezoneService,
      private readonly progressbar: ProgressbarService,
      private readonly analyticsService: FirebaseAnalyticsService,
      private readonly snackbar: SnackBarService,
      private readonly mediaCache: MediaCacheService,
      stagingService: StagingService,
      route: ActivatedRoute,
      tableUtils: TableUtils,
      assetService: AssetService,
      cdr: ChangeDetectorRef,
      stateService: StateService,
      router: Router,
      private readonly featureService: FeatureFlagService,
  ) {
    super(stagingService, tableUtils, assetService, cdr, stateService, router);

    this.search.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(300))
        .subscribe(query => {
          this.userQuery = query ?? '';
          this.refreshTable();
        });

    this.selectedDate$ = route.queryParamMap.pipe(
        map(params => params.get(LIVE_STAGING_DATE_PARAM)),
        filter((date): date is string => !!date),
        // Format date in pre-defined timezone at 12:00am.
        map(date => this.timezoneService.parseFromIso(date).startOf('day')),
        filter(date => date.isValid),
        shareReplay({bufferSize: 1, refCount: true}),
    );

    this.selectedDateLabel$ = this.selectedDate$.pipe(
        map(date => date.toLocaleString(DateTime.DATE_FULL, { locale: "en" })));

    this.todayButtonDisabled$ = this.selectedDate$.pipe(
        map(date => date.equals(this.timezoneService.getTodayDate())));

    // Override absent or invalid date with today date.
    const selectedDate =
        route.snapshot.queryParamMap.get(LIVE_STAGING_DATE_PARAM);
    if (!selectedDate || !DateTime.fromISO(selectedDate).isValid) {
      this.changeDate(this.timezoneService.getTodayDate());
    }

    // Listen for the single site selected in the major sites selector.
    this.mediaCache.state.selectedSite$.pipe(take(1)).subscribe(site => {
      this.selectedSite$.next(site);
    });
  }

  override ngOnInit() {
    super.ngOnInit();
    this.startFetchingAssetsOnDateChange();
  }

  async nextDay() {
    const currentDate = await firstValueFrom(this.selectedDate$);
    this.changeDate(currentDate.plus({days: 1}));
  }

  async previousDay() {
    const currentDate = await firstValueFrom(this.selectedDate$);
    this.changeDate(currentDate.minus({days: 1}));
  }

  today() {
    this.changeDate(this.timezoneService.getTodayDate());
  }

  getMetadataValue(asset: Original, key: MetadataField) {
    const value = asset.assetMetadata.jsonMetadata[key] as unknown;
    if (value == null) return '-';
    if (Array.isArray(value)) return value.join(', ');

    return String(value);
  }

  formatCutdownStatus(asset: Original) {
    const {completedCount, totalCount} = asset.copyStats;
    if (totalCount === 0) return '';

    return `${completedCount} of ${totalCount}`;
  }

  /** Drops cache and refresh the data for the selected date. */
  async refreshTable() {
    this.assetCache.clear();
    this.refresh$.next();
  }

  /**
   * Filters assets data (from assets property) by the selected site.
   * Result is placed into filteredAssets property.
   */
  filterData() {
    if (!this.assets) return;

    const site = this.selectedSite$?.getValue();
    this.filteredAssets = site === this.allSitesOption ?
        this.assets :
        this.assets.filter(a => a.source?.toLowerCase() === site.siteId.toLowerCase());
  }

  /**
   * Sorts the assets based on provided `sort`. If no `sort` is provided sorts
   * based on `this.currentSort`.
   */
  sortData(sort?: Sort) {
    if (sort) {
      // Update currentSort.
      this.currentSort = (!sort.active || sort.direction === '') ? DEFAULT_SORT : sort;
    }

    if (!this.assets) return;

    this.analyticsService.logEvent('Sort live staging table', {
      eventType: AnalyticsEventType.LOG,
      string1: this.currentSort.active,
      string2: this.currentSort.direction,
    });

    // Apply sort based on currentSort.
    // Here `...` is used to update the ref and trigger mat-table refresh.
    this.assets = [...this.assets].sort((a, b) => {
      const isAsc = this.currentSort.direction === 'asc';
      const sortColumn = this.currentSort.active as Column;
      switch (sortColumn) {
        case 'camera':
        case 'courtesy':
        case 'description':
        case 'sport':
        case 'type':
        case 'title':
          return this.metadataCompare(
              a, b, isAsc, COLUMN_TO_METADATA_KEY[sortColumn]);
        case 'end':
          return this.compare(a.eventEndTime, b.eventEndTime, isAsc);
        case 'start':
          return this.compare(a.eventStartTime, b.eventStartTime, isAsc);
        case 'cutdown':
          return this.compare(
              this.formatCutdownStatus(a), this.formatCutdownStatus(b), isAsc);
        default:
          assumeExhaustiveAllowing<'select'|'expand'|'source'>(sortColumn);
          return 0;
      }
    });

    this.filterData();
  }

  getColumnTitleTooltip(field: MetadataField) {
    return `"${field}" metadata field`;
  }

  getStatusIcon(asset: Original) {
    if (this.hasError(asset)) {
      return LiveAssetIcon.ERROR;
    }
    if (asset.state === AssetState.PROCESSING ||
        this.hasCutdownsInProgress(asset)) {
      return LiveAssetIcon.SPINNER;
    }
    if (asset.approved) return LiveAssetIcon.APPROVED;

    return LiveAssetIcon.DEFAULT;
  }

  hasError(asset: Original) {
    return asset.hasError || asset.copyStats.errorCount > 0;
  }

  getStatusTooltip(asset: Original) {
    if (asset.hasError) return asset.errorReason || 'Error';

    if (asset.copyStats.errorCount > 0) return 'Cutdown(s) failed';

    if (this.hasCutdownsInProgress(asset)) {
      return 'Cutdown is in progress';
    }

    if (asset.state === AssetState.PROCESSING) return 'Processing';
    if (asset.approved) return 'Approved';

    return '';
  }

  isExpanded(asset: Original) {
    return this.expandedAssets.has(asset.name);
  }

  toggleExpansion(asset: Original, activeItems?: ActiveItems|null) {
    if (!this.expandedAssets.has(asset.name)) {
      this.expandedAssets.add(asset.name);
      return;
    }

    this.expandedAssets.delete(asset.name);

    // Close cut-down metadata panel if it displayed cut-down from the list that
    // is being collapsed.
    if (activeItems?.cutdownParent?.name === asset.name) {
      this.stagingService.setActive(undefined);
    }
  }

  protected override updateCache(changedItems: Map<string, Original>) {
    for (const cachedPage of this.assetCache.values()) {
      for (let i = 0; i < cachedPage.length; i++) {
        const changedItem = changedItems.get(cachedPage[i].name);
        if (changedItem) {
          cachedPage[i] = changedItem;
        }
      }
    }
  }

  /** Map of asset results by date, represented by epoch milliseconds. */
  private readonly assetCache = new Map<number, Original[]>();

  /**
   * Listens to the selected date changes and fetches data for the page
   * from the cache or the data service. Results are cached until
   * search query is changed or the component is destroyed.
   */
  private startFetchingAssetsOnDateChange() {
    merge(
        this.selectedDate$,
        this.refresh$.pipe(switchMap(() => this.selectedDate$)))
        .pipe(
            takeUntil(this.destroyed$), tap(() => {
              this.cdr.markForCheck();
              this.loading = true;
              this.progressbar.show();
            }),
            switchMap(date => {
              const cached = this.assetCache.get(date.toMillis());
              if (cached) return of(cached);

              return this.stagingService
                  .getLiveAssets({
                    query: this.userQuery,
                    date,
                  })
                  .pipe(tap(assets => {
                    if (assets) {
                      this.assetCache.set(date.toMillis(), assets);
                    }
                  }));
            }),
            finalize(() => {
              this.progressbar.hide();
            }))
        .subscribe(assets => {
          this.loading = false;
          this.scrollTopNeeded.emit();
          this.cdr.markForCheck();
          this.expandedAssets.clear();
          this.progressbar.hide();

          if (assets == null) {
            this.assets = [];
            this.snackbar.message('Failed to load live staging assets');
          } else {
            this.assets = assets;
          }
          this.sortData();
          this.refreshActiveAndSelectedItems();
        });
  }

  private changeDate(newDate: DateTime|null) {
    this.router.navigate([], {
      queryParams: {[LIVE_STAGING_DATE_PARAM]: newDate?.toISODate()},
      queryParamsHandling: 'merge',
    });
  }

  private compare<T extends number|string>(a: T, b: T, isAsc: boolean) {
    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b) * (isAsc ? 1 : -1);
    }

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private metadataCompare(
      a: Original, b: Original, isAsc: boolean, key: MetadataField) {
    const aValue = this.getMetadataValue(a, key);
    const bValue = this.getMetadataValue(b, key);
    return this.compare(aValue, bValue, isAsc);
  }


  private hasCutdownsInProgress(asset: Original) {
    const cutdownsInProgress = asset.copyStats.approvedCount -
        asset.copyStats.completedCount - asset.copyStats.errorCount;
    return cutdownsInProgress > 0;
  }

  /**Action - rediecting to metadta panel */
  override selectOrActivate(asset: Original, selectedAssetSet: Set<string>, shiftPressed?: boolean) {
    super.selectOrActivate(asset, selectedAssetSet, shiftPressed);
  }

  onSortByField(rows: Original[]) {
    this.filteredAssets = rows;
    this.cdr.detectChanges();
  }

  selectAssetsSite(site: Site = {siteId: ''} as Site) {
    this.selectedSite$?.next(site);
    this.filterData();
  }

  protected override refreshActiveAndSelectedItems() {
    super.refreshActiveAndSelectedItems();

    this.filterData();
  }
}
