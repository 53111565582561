/// <reference types="google.accounts" />

import {Injectable} from '@angular/core';
import {Credentials} from 'google-auth-library';
import {firstValueFrom} from 'rxjs';

import {ApiResponse} from 'server/response-utils';

import {environment} from '../environments/environment';

import {AuthService} from './auth_service';

const STORAGE_KEY = 'ias_mam_tokens';
const BINDING_VALUE_KEY = 'ias_mam_binding';

const GSI_SRC = 'https://accounts.google.com/gsi/client';

/**
 * AuthService implementation when MAM UI is used directly from a web browser
 * and not within the Premiere plugin. It follows the web-app OAuth flow.
 */
@Injectable({providedIn: 'root'})
export class AuthBrowserService extends AuthService {
  isPlugin() {
    if (this.pluginService.isIframe()) return true;
    // Avoid non-googlers from seeing plugin version in the browser.
    // The query parameter "plugin=x.x.x" allow Googlers simulate that they are
    // in plugin. `plugin=true` will behave as if it was the latest version.
    const forcePluginMode = !!this.isInternalUser() &&
        this.pluginService.version != null &&
        this.pluginService.version !== 'false';
    return forcePluginMode;
  }

  /**
   * Returns a callback that will replace the current page with the OAUth2 login
   * screen, which then will redirect the uer back to the current page.
   */
  async prepareOauth2Redirect(): Promise<(() => void) | null> {
    const oauth2 = await this.getOauth2();
    if (!oauth2) return null;

    // Used to ensure that the origin and redirected page from OAuth login
    // screen are the same.
    const bindingValue = Math.random().toString().slice(2);

    const client = oauth2.initCodeClient({
      /* eslint-disable camelcase */
      client_id: environment.clientId,
      scope: environment.scope || '',
      ux_mode: 'redirect',
      redirect_uri: window.location.origin,
      state: bindingValue,
      /* eslint-enable camelcase */
    });

    sessionStorage.setItem(BINDING_VALUE_KEY, bindingValue);
    return () => client.requestCode();
  }

  getAccessToken() {
    return this.getTokens()?.access_token ?? '';
  }

  protected override async clearAuthStorage() {
    super.clearAuthStorage();

    // Read access token before clearing it from localStorage.
    const accessToken = this.getTokens()?.access_token;

    localStorage.removeItem(STORAGE_KEY);

    if (!accessToken) return;
    const oauth2 = await this.getOauth2();
    if (!oauth2) return;

    return new Promise<void>(resolve => {
      oauth2.revoke(accessToken, resolve);
    });
  }

  protected async isLoggedInInternal() {
    await this.runTokenRetrievalFlow();
    return Boolean(this.getAccessToken());
  }

  protected async refreshAuthIfTokenExpired(minTimeRemaining: number) {
    const tokens = this.getTokens();
    if (!tokens || !tokens.expiry_date) {
      // User may have logged out from another tab.
      location.reload();
      return;
    }

    const timeRemaining = tokens.expiry_date - Date.now();
    if (timeRemaining < minTimeRemaining) {
      await this.refreshToken();
    }
  }

  private getTokens() {
    return this.decodePayload<Credentials>(localStorage.getItem(STORAGE_KEY) ?? '');
  }

  private setTokens(tokens: Credentials) {
    const value = this.encodePayload(tokens);
    localStorage.setItem(STORAGE_KEY, value);
  }

  private async refreshToken(): Promise<void> {
    const currentTokens = this.getTokens();
    if (!currentTokens) {
      // force re-auth.
      location.reload();
      return;
    }

    const headers: Record<string, string> =
        {'X-Requested-With': 'XmlHttpRequest'};

    const payload = this.encodePayload(currentTokens);
    const resp = await firstValueFrom(this.http
        .get<ApiResponse<string>>(
            `auth/refresh?clientId=${environment.clientId}&payload=${payload}&origin=${
                location.origin}`,
            {headers}));

    if (!resp.success) return;

    const tokens = this.decodePayload<Credentials>(resp.result);
    if (!tokens) return;

    this.setTokens(tokens);
  }

  private async loadGsiScript() {
    // GSI_SRC script is already loaded.
    if (document.querySelector(`script[src="${GSI_SRC}"]`)) return;

    return new Promise<void>((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.setAttribute('src', GSI_SRC);
      scriptElement.onload = () => {
        window.google ? resolve() : reject();
      };
      scriptElement.onerror = () => {
        reject();
      };
      document.head.appendChild(scriptElement);
    });
  }

  private async getOauth2() {
    try {
      await this.loadGsiScript();
    } catch {
      return null;
    }

    return google.accounts.oauth2;
  }

  /**
   * If the user was redirected back to the MAM UI from the temporary OAuth2
   * login screen, the URL will contain the code that can be exchanged to an
   * auth token.
   */
  private async runTokenRetrievalFlow(): Promise<void> {
    const bindingValue = sessionStorage.getItem(BINDING_VALUE_KEY);
    if (!bindingValue) return;
    sessionStorage.removeItem(BINDING_VALUE_KEY);

    const url = new URL(location.href);
    const state = url.searchParams.get('state');
    const code = url.searchParams.get('code');
    if (state !== bindingValue || !code) {
      this.errorService.handle(`Token retrieval mismatch: ${state} | ${bindingValue} | ${code}`);
      return;
    }

    const headers: Record<string, string> =
        {'X-Requested-With': 'XmlHttpRequest'};
    const resp = await firstValueFrom(this.http
      .get<ApiResponse<string>>(
          `auth/code?clientId=${environment.clientId}&code=${code}&origin=${location.origin}`,
          {headers}));

    if (!resp.success) return;

    const tokens = this.decodePayload<Credentials>(resp.result);
    if (!tokens?.access_token) return;

    this.setTokens(tokens);
    await this.loadUserProfile(tokens.access_token);
  }
}
