<!-- Clip Bins Section -->
<section class="clipbins">
	<header class="clipbins_header">
		<div class="clipbins_header_owner">
			<h2 class="clipbins_header__title">{{ username }}'s page</h2>
		</div>

		<div class="clipbins_header_search">
			<!-- search box -->
			<mat-form-field
				#searchField
				appearance="outline"
				class="clipbins_search-form"
				[class.is-focused]="searchInput.focus"
			>
				<mat-icon matPrefix class="clipbins_search-form__icon">search</mat-icon>
				<input
					#searchInput
					#trigger="matAutocompleteTrigger"
					[matAutocomplete]="auto"
					matInput
					type="text"
					[formControl]="searchControl"
					[value]="searchControl.value"
					[placeholder]="getPlaceholderText()"
					aria-label="Search inside clip bins section"
					(keydown)="$event.stopPropagation()"
					class="clipbins_search-form__input"
				/>

				<!-- Clear icon (hidden on small screens) -->
				@if (searchInput.value !== '') {
				<button
					mat-icon-button
					matSuffix
					aria-label="Clear"
					matTooltip="Clear"
					mam-firebase-ga="Clear search input"
					(click)="onSearchClear(); $event.stopPropagation()"
					class="clipbins_search-form__clear clear-button"
				>
					<mat-icon>close</mat-icon>
				</button>
				}

				<!-- Search mode dropdown -->
				<ng-container matSuffix>
					<mat-divider *ngIf="searchInput.value !== ''" [vertical]="true"></mat-divider>
					<button
						mat-button
						class="clipbins_search__dropdown search_dropdown"
						[matMenuTriggerFor]="menu"
						[class.active]="menuTrigger.menuOpen"
						#menuTrigger="matMenuTrigger"
						mam-firebase-ga="Open search mode selector via dropdown"
						(click)="$event.stopPropagation()"
					>
						<div class="search_dropdown_inner">
							<span>{{formatBinSectionContent(searchModeSelected)}}</span>
							<mat-icon class="arrow">arrow_drop_down</mat-icon>
						</div>
					</button>

					<!-- Search mode menu -->
					<mat-menu #menu="matMenu" class="clipbins_search__dropdown_options search_dropdown_options">
						<div class="mat-menu-title">RESULT TYPE</div>
						<button
							*ngFor="let mode of searchModeOptions"
							mat-menu-item
							class="search_dropdown_options__item"
							(click)="handleSearchModeChange(mode)"
							mam-firebase-ga="Select search mode"
							[mam-firebase-resource]="mode"
              [disabled]="searchModeDisabled"
						>
							<mat-icon [style.opacity]="mode === searchModeSelected ? 1 : 0">done</mat-icon>
							<span>{{formatBinSectionContent(mode)}}</span>
						</button>
					</mat-menu>
				</ng-container>

				<mat-autocomplete
					#auto="matAutocomplete"
					class="clipbins_autocomplete"
					[autoActiveFirstOption]="false"
					[displayWith]="getSuggestionText"
				>
					<mat-option
						*ngFor="let suggestion of activeSuggestions$  | async | slice:0:6; trackBy: trackSuggestion; let index = index"
						[value]="suggestion.text"
						tabindex="0"
					>
						<span>
							<span
								*ngFor="let word of getWordsStyles(hideParens(suggestion.text))"
								[class.bold]="word.matches"
								[class.light]="!word.matches"
							>
								{{word.value}}
							</span>
						</span>
						<button
							*ngIf="suggestion.isFromHistory"
							mat-icon-button
							mam-firebase-ga="Remove suggestion from history"
							(click)="removeFromHistory(suggestion, $event)"
							aria-label="Remove from history"
							matTooltip="Remove from history"
							class="gmat-button"
						>
							<mat-icon>clear</mat-icon>
						</button>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
		</div>

		<div class="clipbins_header_views">
			<mat-slide-toggle
				name="bins_toggle"
				color="primary"
				class="clipbins_header__toggle"
				[checked]="showAllBins"
				[disabled]="isShowAllBinsDisabled"
				(change)="onShowAllBinsChange($event)"
				title="Show user's or all clip bins"
			>
				Show all
			</mat-slide-toggle>
		</div>
	</header>

	<div class="clipbins_actions">
		<div class="clipbins_actions_bulk">
			<!-- Display mode toggle -->
			<button
				mat-mini-fab
				color="primary"
				class="clipbins_actions__display-mode bins_display-mode"
				(click)="this.toggleViewMode()"
				[matTooltip]="'Switch to ' + (isGrid(displayMode) ? 'list view': 'grid view')"
				[mam-firebase-ga]="'Toggle ' + (isGrid(displayMode) ? 'list view': 'grid view')"
			>
      <mat-icon class="material-icons-outlined"> {{isGrid(displayMode) ? 'list' : 'view_module'}} </mat-icon>
			</button>

			<!-- Select/De-select all. -->
			<mat-checkbox
				class="bulk-actions__select-all"
				color="primary"
				matTooltipPosition="above"
				mam-firebase-ga="Toggle selection for all segments in search results"
				(change)="toggleSelectAll()"
				*ngIf="searchModeVisible === SEARCH_MODE.BIN && !showAllBins"
				[checked]="allChecked"
				[indeterminate]="someChecked"
			>
			</mat-checkbox>

			<div class="bulk-actions" *ngIf="this.itemMultiSelection.size !== 0">
				<!-- <button mat-icon-button><mat-icon class="material-icons-outlined">drive_file_move</mat-icon></button> -->
				<button mat-icon-button (click)="deleteSelection()">
					<mat-icon class="material-icons-outlined">delete</mat-icon>
				</button>
			</div>
		</div>
		<div class="clipbins_actions_pagination">
      <!-- Pagination -->
      @switch(searchModeVisible){
        @case(SEARCH_MODE.BIN){
          <mat-paginator
            hidePageSize
            class="main__mat-paginator--border"
            [length]="binsPagination.totalCount"
            [pageSize]="binsPagination.pageSize"
            [pageIndex]="binsPagination.pageIndex"
            [disabled]="resultsLoading"
            (page)="onPageChange($event)"
            aria-label="Select page"
          >
          </mat-paginator>
        }
        @case(SEARCH_MODE.CLIP){
          <mat-paginator
            hidePageSize
            class="main__mat-paginator--border"
            [length]="clipsPagination.totalCount"
            [pageSize]="clipsPagination.pageSize"
            [pageIndex]="clipsPagination.pageIndex"
            [disabled]="resultsLoading"
            (page)="onPageChange($event)"
            aria-label="Select page"
          >
          </mat-paginator>
        }
        @case(SEARCH_MODE.FOLDER){
          <!-- <mat-paginator
            hidePageSize
            class="main__mat-paginator--border"
            [length]="foldersPagination.totalCount"
            [pageSize]="foldersPagination.pageLimit"
            [pageIndex]="foldersPagination.pageIndex"
            [disabled]="resultsLoading"
            (page)="onPageChange($event)"
            aria-label="Select page"
          >
          </mat-paginator> -->
        }
      }

		</div>
	</div>



  <!-- List and Grid -->
  @if(!folderUrlId) {
  <ng-container *ngIf="pageResults$ | async as results">
    @if(resultsLoading){
      <!-- Loading -->
      <div class="skeletons" *ngIf="resultsLoading$ | async">
        <mam-assets-skeleton class="mam-skeleton" *ngFor="let item of resultsLoading$ | async" [display]="displayMode"></mam-assets-skeleton>
      </div>
    }@else{
        @if(!results.length){
          <!-- Empty bins -->
          <mam-empty-assets-card
            [assetsType]="formatBinSectionContent(searchModeSelected)"
            [hasSearchText]="!!searchText"
            [helperText]="true">
            <p>
              Use the <span><mat-icon>add</mat-icon> New</span> button in the top left to create one and start collecting {{ formatBinSectionContent(searchModeVisible) }}.
            </p>
          </mam-empty-assets-card>
        }@else{
          <!-- Display results -->
          <div
            class="clipbins_grid"
            [class.clipbins_grid--list]="displayMode === 'list'"
          >
            @switch (searchModeVisible) {
              @case (SEARCH_MODE.CLIP) {
                @for(clip of clipResults; track clip){
                  <div class="clipbins_grid_card_container">
                    <mam-clip-preview
                      class="clip-preview"
                      [clip]="clip"
                      [viewMode]="displayMode"
                      ></mam-clip-preview>
                  </div>
                }
              }
              @case (SEARCH_MODE.FOLDER) {
                @for(folder of folderResults; track folder){
                <div class="clipbins_grid_card_container folder_grid_card_container">
                  <mam-clip-bin-folder-preview
                    [isAdmin]="isAdmin"
                    [userEmail]="userEmail"
                    [folder]="folder"
                    [viewMode]="displayMode"
                    class="clipbins_grid__card folder_grid__card"
                    [class.clipbins_grid__card--list]="displayMode === 'list'"
                  ></mam-clip-bin-folder-preview>
                </div>
                }
              }
              @case (SEARCH_MODE.BIN) {
                @for(bin of binResults; track bin){
                  <div class="clipbins_grid_card_container">
                    <div
                      class="clipbins_checkbox"
                      [class.list_mode]="displayMode === 'list'"
                      *featureFlagOn="'use-clips-selection'">
                      <mat-checkbox
                        color="primary"
                        *ngIf="!showAllBins"
                        (click)="toggleSelection($event,bin); $event.stopPropagation();"
                        [checked]="itemMultiSelection.has(bin.name)"
                      >
                      </mat-checkbox>
                    </div>
                    <mam-clip-bin-preview
                      [bin]="bin"
                      [checkboxPresent]="true"
                      [isAdmin]="isAdmin"
                      [showAllBins]="showAllBins"
                      [binsViewMode]="displayMode"
                      [active]="itemMultiSelection.has(bin.name)"
                      class="clipbins_grid__card"
                      [class.clipbins_grid__card--list]="displayMode === 'list'"
                    ></mam-clip-bin-preview>
                  </div>
                }
              }
            }
          </div>
        }

    }
  </ng-container>
} @else {
  <mam-folder-content
    [isAdmin]="isAdmin"
    [userEmail]="userEmail"
    [folderId]="folderUrlId"
    [displayMode]="displayMode"
  ></mam-folder-content>
}
</section>
