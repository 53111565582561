import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {checkExhaustive} from 'asserts/asserts';

import {MetadataField} from '../services/asset_api_service';
import {Asset} from '../services/asset_service';

/**
 * Presentation type icon of an asset. A live asset that is archived is
 * presented with a VoD (home) icon. A SLS live asset is presented with a soccer
 * ball icon.
 */
@Component({
  selector: 'mam-asset-type-icon',
  templateUrl: './asset_type_icon.ng.html',
  styleUrls: ['./asset_type_icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetTypeIcon {
  @Input() asset?: Asset;

  getIcon() {
    const presentationType = this.getPresentationType();
    switch (presentationType) {
      case 'vod':
        return 'home';
      case 'live-sls':
        return 'sports_and_outdoors';
      case 'live':
        return 'youtube_live';
      default:
        checkExhaustive(presentationType);
    }
  }

  getTooltip() {
    const assetOrClip = this.asset?.original ? 'clip' : 'asset';
    const presentationType = this.getPresentationType();
    switch (presentationType) {
      case 'vod':
        return `VoD ${assetOrClip}`;
      case 'live-sls':
        return `Live SLS ${assetOrClip}`;
      case 'live':
        return `Live ${assetOrClip}`;
      default:
        checkExhaustive(presentationType);
    }
  }

  /**
   * The presentation type of an asset is:
   * - "vod" for a VoD asset (regular, archive from live, etc.)
   * - "live-sls" for a live asset that contains a secondary high res path
   * - "live" for other live assets
   */
  private getPresentationType(): 'vod'|'live'|'live-sls' {
    if (!this.asset?.isLive) {
      return 'vod';
    }
    if (this.asset?.assetMetadata
            .jsonMetadata[MetadataField.SECONDARY_HI_RES_FILE_PATH]) {
      return 'live-sls';
    }
    return 'live';
  }
}
