import {EnvironmentType} from 'environments/environment_types';

/** Helper function to ensure type safety for FEATURE_CONFIG values.  */
function restrictValuesToEnvironmentType<T>(
    features: {[K in keyof T]: Readonly<EnvironmentType[]>}) {
  return features;
}

/** Feature is only available in mock environment. */
const MOCK = ['mock'] as const;
/** Feature is only available in local and lower environments. */
const LOCAL = [...MOCK, 'local'] as const;
/** Feature is only available in dev and lower environments. */
const DEV = [...LOCAL, 'dev'] as const;
/** Feature is available in qa and lower environments.*/
const QA = [...DEV, 'qa'] as const;
/** Feature is available in int and lower environments. */
const INT = [...QA, 'int'] as const;
/** Feature is available in preprod and lower environments. */
const PREPROD = [...INT, 'preprod'] as const;
/** Feature is available in canaryprod and lower environments. */
const CANARYPROD = [...PREPROD, 'canaryprod'] as const;
/** Feature is available in prod and all other environments. */
const PROD = [...CANARYPROD, 'prod'] as const;
/**
 * Configuration of feature flags based on environment type. Wildcard `*`
 * indicates that a feature is enabled for all environments.
 */
export const FEATURE_CONFIG = restrictValuesToEnvironmentType({
  'use-airing-annotated-segments-loading': PROD,
  'use-copy-api-cutdown': PROD,
  'use-bulk-clips-export': PROD,
  'use-clips-selection': PROD,
  'use-vod-staging-page-size-selector': PROD,
  'use-bulk-clips-import': PROD,
  'use-bulk-clips-download': PROD,
  'use-vod-live-export-monitor': PROD,
  'use-comp-reel-export-monitor': PROD,
  'use-schema-scope-filter': PROD,
  'use-sync-metadata': PROD,
  'use-original-asset-export': PROD,
  'use-comp-reel-custom-name': PROD,
  'use-cloud-ingest': DEV,
  'use-multi-camera-view': DEV,
  'use-persistent-related-views': DEV,
  'play-feed-improvements': PROD,
  'player-controls-improvements': PROD,
  'show-user-information': PROD,
  'show-fullscreen-buttons-desktop': PROD,
  'store-user-information': PROD,
  'live-site-filter': PROD,
  'show-col-option': MOCK,
  'show-col-client-sort': PROD,
  'show-admin-metrics-tab': MOCK,
  'show-admin-page': PROD,
  'adjust-clt-assets-manifest-start-time': PROD,
  'enable-clip-trim': DEV,
  'enable-clips-sort': CANARYPROD,
  'calculate-anchor-for-shift-selection': DEV,
  'use-decreased-manifest-load-attempts': CANARYPROD,
  'enable-fix-for-live-rewind-forward': CANARYPROD,
  'enable-site-filter': CANARYPROD,
  'enable-favorites-for-facets-dropdowns': CANARYPROD,
  'enable-filtering-for-facets-dropdowns': CANARYPROD,
});
/** Represents a union type for all feature flag names. */
export type FeatureName = keyof typeof FEATURE_CONFIG;
/**
 * Represents feature config type based on existing feature flag names and
 * environments.
 */
export type FeatureConfig = Record<FeatureName, Readonly<EnvironmentType[]>>;
