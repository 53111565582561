import {Injectable, NgZone} from '@angular/core';
import {Event, NavigationEnd} from '@angular/router';
import {PerformanceTrace} from 'firebase/performance';
import {Observable, OperatorFunction} from 'rxjs';
import {filter, map, take, tap} from 'rxjs/operators';

import {environment} from '../environments/environment';
import {ErrorService} from '../error_service/error_service';

import {AnalyticsEventType, FirebaseAnalyticsService} from './firebase_analytics_service';
import {FirebaseResolver} from './firebase_resolver';

/** All performance attributes and metrics that we record. */
export declare interface MamTraceData {
  attributes?: {
    environment?: string,
    initialPageLoad?: boolean,
    status?: string,
    assetDuration?: number,
    videoDuration?: number,
    count?: number,
    isLive?: boolean,
  };
  metrics?: {[name: string]: number;};
}

/** Names of traces. */
export enum TraceName {
  UNKNOWN = 'unknown',
  DETAILS_PAGE_READY = 'details_page_ready',
  CLIP_BIN_PAGE_READY = 'clip_bin_page_ready',
  SEARCH_RESULT_PAGE_READY = 'search_result_page_ready',
  LIVE_LANDING_PAGE_READY = 'live_landing_page_ready',
  DETAILS_LOAD_MAIN_MANIFEST = 'details_load_main_manifest',
  AUTH_CHECK_PERMISSION = 'auth_check_permission',
  ASSET_API_GET_ASSET = 'asset_api_get_asset',
  ASSET_API_GET_RECENTS = 'asset_api_get_recents',
  ASSET_THUMBNAIL_LOADED = 'asset_thumbnail_loaded',
}

/**
 * Prefix that is added to a trace name when it is logged as an analytics
 * event.
 */
export const ANALYTICS_PREFIX = 'trace_';

/**
 * A service for firebase performance related tasks.
 */
@Injectable({providedIn: 'root'})
export class FirebasePerformanceService {
  constructor(
      private readonly errorService: ErrorService,
      private readonly firebaseResolver: FirebaseResolver,
      private readonly analyticsService: FirebaseAnalyticsService,
      private readonly ngZone: NgZone,
  ) {}

  /** Start performance tracing. */
  startTrace(traceName: TraceName, data?: MamTraceData): Trace|undefined {
    const startMs = this.analyticsService.getTimestamp();

    // Run this block outside of Angular to prevent e2e tests from timing out
    // waiting for all logging related async operations to resolve.
    return this.ngZone.runOutsideAngular(() => {
      let perfTrace: PerformanceTrace|undefined = undefined;
      if (traceName !== TraceName.UNKNOWN) {
        perfTrace = this.firebaseResolver.trace(traceName);
      }

      try {
        perfTrace?.start();
        const traceWrapper = new Trace(
            traceName, perfTrace, this.ngZone,
            error => {
              this.onError(error);
            },
            /* onStop */
            () => {
              const duration =
                  Math.round(this.analyticsService.getTimestamp() - startMs);

              // Also log performance traces to analytics so that they are
              // exported to BigQuery.
              if (perfTrace) {
                const eventName = `${ANALYTICS_PREFIX}${traceName}`;
                this.analyticsService.logEvent(eventName, {
                  eventType: AnalyticsEventType.TRACE,
                  duration,
                  ...perfTrace?.getAttributes(),
                });
              }
              // When Firebase is not enabled, log the duration of the trace to
              // the console.
              else {
                const msg = `[Trace] "${traceName}" finished in ${duration}ms`;
                console.debug(msg);
              }
            });

        if (data) {
          traceWrapper.recordData(data);
        }
        return traceWrapper;
      } catch (error: unknown) {
        this.onError(error);
        return;
      }
    });
  }

  // Track firebase errors via strackdriver.
  private onError(error: unknown) {
    if (error instanceof Error) {
      this.errorService.handle(error);
    } else {
      this.errorService.handle(
          new Error('[Performance service]: unknown error'));
    }
  }

  /**
   * Sets 'initial_page_load' trace attribute by piping router events.
   * Works only if called from top level view.
   */
  recordInitialPageLoad(pageTrace: Trace|
                        undefined): OperatorFunction<Event, boolean> {
    return (routerEvents) =>
               this.extractInitialPageLoad(routerEvents)
                   .pipe(tap(firstPageLoad => {
                     pageTrace?.recordData({
                       attributes: {initialPageLoad: firstPageLoad},
                     });
                   }));
  }

  /**
   * Check if this is the first page load or in-app navigation by piping router
   * events. Works only if called from top level view.
   */
  initialPageLoad(): OperatorFunction<Event, boolean> {
    return (routerEvents) => this.extractInitialPageLoad(routerEvents);
  }

  /**
   * Goes through router events and extracts the id of NavigationEnd event. If
   * it equals 1 then this is the first navigation - first page load.
   */
  private extractInitialPageLoad(routerEvents: Observable<Event>):
      Observable<boolean> {
    return routerEvents.pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        take(1),
        map(({id}) => id === 1),
    );
  }
}

/** Performance trace. */
export class Trace {
  private running = true;
  private remainingStopCallCount = 1;

  constructor(
      readonly name: TraceName,
      private readonly trace: PerformanceTrace|undefined,
      private readonly ngZone: NgZone,
      private readonly onError: (error: unknown) => void,
      private readonly onStop: () => void,
  ) {}

  abort() {
    this.running = false;
  }

  stop(data?: MamTraceData) {
    // Run this block outside of Angular to prevent e2e tests from timing out
    // waiting for all logging related async operations to resolve.
    this.ngZone.runOutsideAngular(() => {
      if (!this.running) {
        return;
      }

      // If enabled by environment, log measurements to console even when
      // firebase toolkit is not available.
      if (this.trace) {
        this.recordDataInternal(data, true);
        try {
          this.trace.stop();
        } catch (error: unknown) {
          this.onError(error);
        }
      }
      this.running = false;
      this.onStop();
    });
  }

  /** Set the number of maybeStop() calls expected to stop the trace. */
  stopAfter(callCount: number) {
    if (callCount < 0 || !Number.isInteger(callCount)) {
      throw new Error(
          `callCount parameter should be a non-negative integer. Got: ${
              callCount}`);
    }
    this.remainingStopCallCount = callCount;
  }

  /**
   * Will decrement the number of calls remaining for trace to stop that was
   * set by stopAfter(). If that number becomes zero the trace will be
   * stopped. If stopAfter() was not called, the trace will be stopped
   * immediately.
   * 
   * @returns Status if the trace is still running.
   */
  maybeStop(): boolean {
    if (--this.remainingStopCallCount <= 0) {
      this.stop();
    }
    return this.running;
  }

  recordData(data: MamTraceData) {
    this.recordDataInternal(data);
  }

  private recordDataInternal(data?: MamTraceData, includeCommonData = false) {
    if (!this.trace || !this.running) {
      return;
    }
    let attributes = data?.attributes;
    if (includeCommonData) {
      attributes = {...attributes, environment: environment.tag};
    }

    try {
      for (const [key, value] of Object.entries(attributes || {})) {
        this.trace.putAttribute(key, String(value));
      }
      for (const [key, value] of Object.entries(data?.metrics || {})) {
        this.trace.putMetric(key, value);
      }
    } catch (error: unknown) {
      this.onError(error);
    }
  }
}
