import { Injectable } from '@angular/core';

import { PlaybackAuthorizationService } from 'services/playback-authorization/playback-authorization.service';

@Injectable({ providedIn: 'root' })
export class RenditionServiceDependencies {
    constructor(
        public readonly playbackAuthorizationService: PlaybackAuthorizationService
    ) {
    }
}