import {Pipe, PipeTransform} from '@angular/core';

/**
 * Formats number-like values as bytes. Uses 1000-based units like MB and GB.
 * Values above the maximum safe integer will be rendered as `INF`.
 * Usage:
 *   value | bytes:digits
 * Example:
 *   {{ 1250 | bytes }} => 1.25 KB
 *   {{ 1250 | bytes:1 }} => 1.3 KB
 */
@Pipe({name: 'bytes'})
export class BytesPipe implements PipeTransform {
  private readonly UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(value: number|string, digits = 2): string {
    const bytes = Number(value) || 0;
    if (bytes > Number.MAX_SAFE_INTEGER) {
      return 'INF';
    } else if (!bytes || bytes < 0) {
      return `0 ${this.UNITS[0]}`;
    }

    // power is also the index of UNITS, for instance MB is 1000^2
    const power = Math.floor(Math.log10(bytes) / 3);
    const unitName = this.UNITS[power];
    const unitValue = bytes / (1000 ** power);
    return `${Number(unitValue.toFixed(digits))} ${unitName}`;
  }
}
