import {ErrorHandler, Injectable} from '@angular/core';
import {Observable, ObservableInput, of, OperatorFunction, throwError, timer} from 'rxjs';
import {catchError, mergeMap, retryWhen} from 'rxjs/operators';

import {ApiError} from './api_error';
import {ErrorResponse} from './error_response';
import {StatusCode} from './status_code';

/**
 * Service for error handling.
 */
@Injectable({providedIn: 'root'})
export class ErrorService {
  constructor(private readonly errorHandler: ErrorHandler) {}

  /**
   * Retries up to 3 times with increments of 1s, for a total of 6s. Preferred
   * when manually retrying may not be convenient, or getting a quick error is
   * not valuable.
   */
  retryLong<T>(excludedStatusCodes?: StatusCode[]) {
    return this.retry<T>(
        {maxRetryAttempts: 3, scalingDuration: 1000, excludedStatusCodes});
  }

  /**
   * Retries up to 3 times with increments of 150ms, for a total of 900ms.
   * Preferred when we would rather get an error quickly and let the user retry
   * when desired.
   */
  retryShort<T>(excludedStatusCodes?: StatusCode[]) {
    return this.retry<T>(
        {maxRetryAttempts: 3, scalingDuration: 150, excludedStatusCodes});
  }

  retry<T>(config: RetryConfig): OperatorFunction<T, T> {
    // eslint-disable-next-line deprecation/deprecation -- FIXME
    return retryWhen(this.genericRetryStrategy(config));
  }

  /** Reports and logs error. */
  handle(error: unknown) {
    this.errorHandler.handleError(error);
  }

  /** Reports and logs error. Returns ErrorResponse wrapper for it. */
  handleAndBuildErrorResponse(error: string|ApiError) {
    this.handle(error);
    return new ErrorResponse(error);
  }

  /** Intercepts an error, logs it and emits error response wrapper. */
  catchError<T>(notLoggedStatusCodes: StatusCode[] = []) {
    return catchError<T, ObservableInput<ErrorResponse>>(error => {
      if (!notLoggedStatusCodes.includes(error.status)) {
        this.handle(error);
      }
      return of(new ErrorResponse(error));
    });
  }

  // Reference:
  // https://www.learnrxjs.io/learn-rxjs/operators/error_handling/retrywhen
  private genericRetryStrategy({
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [],
  }: RetryConfig) {
    excludedStatusCodes.push(...NEVER_RETRY_STATUS_CODES);
    return (errors: Observable<ApiError>) => {
      return errors.pipe(mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // If maximum number of retries has been met
        // or response is a status code we don't wish to retry, throw error.
        if (retryAttempt > maxRetryAttempts ||
            excludedStatusCodes.includes(error.status)) {
          return throwError(() => error);
        }
        // Retry after 1s, 2s, etc...
        return timer(retryAttempt * scalingDuration);
      }));
    };
  }
}

/** Error status codes that will never be retried. */
export const NEVER_RETRY_STATUS_CODES = [
  StatusCode.BAD_REQUEST,
  StatusCode.FORBIDDEN,
  StatusCode.UNKNOWN_ERROR,
  StatusCode.PRECONDITION_FAILED,
];

/** Configuration for request retrying. */
export interface RetryConfig {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: StatusCode[];
}
