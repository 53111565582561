import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {firstValueFrom, forkJoin, Observable, of, ReplaySubject} from 'rxjs';
import {debounceTime, filter, first, map, mergeMap, share, switchMap, tap} from 'rxjs/operators';

import {castExists} from 'asserts/asserts';

import {ErrorResponse, hasAdminRightsMissing, isErrorResponse, mapOnError} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {FirebaseFirestoreDataService, IASEvent} from '../firebase/firebase_firestore_data_service';
import {FirestoreIASEventHelper} from '../firebase/firebase_firestore_ias_event_helper';
import {TzDatePipe} from '../pipes/tzdate_pipe';
import {Asset, AssetService, Clip, Original} from '../services/asset_service';
import {DialogService} from '../services/dialog_service';
import {MediaCacheService} from '../services/media_cache_service';
import {PubsubApiService} from '../services/pubsub_api_service';
import {SharedLinksApiService, SignUrlResponse} from '../services/shared_links_api_service';
import {SNACKBAR_LONG_DURATION, SnackBarService} from '../services/snackbar_service';
import {UtilsService} from '../services/utils_service';


import {ExportAssetDialog, ExportAssetDialogInputData} from './export_asset_dialog';
import {ExtendTtlDialog, ExtendTtlDialogInputData} from './extend_ttl_dialog';
import {SelectBinsDialog, SelectBinsDialogInputData, SelectBinsDialogOutputData} from './select_bins_dialog';
import {SyncMetadataDialog, SyncMetadataDialogInputData, SyncMetadataDialogOutputData} from './sync_metadata_dialog';

export {AssetState} from '../services/asset_api_service';

/**
 * We cache signed URLs for 4 hours minus 1 minute as default signatures have
 * a validity of 4 hours.
 */
export const AUTHENTICATED_SIGNATURE_EXPIRATION_SEC = 4 * 3600 - 60;

/**
 * Public resources are only cached for 10 seconds. We limit it to 8s on our
 * side to account for latency. This is used for when Shaka player tries to
 * download a same segment almost immediately.
 */
export const UNAUTHENTICATED_SIGNATURE_EXPIRATION_SEC = 8;

/** Handles API calls affecting multiple resources at once. */
@Injectable({providedIn: 'root'})
export class BatchOperationService {

  currentLinkName?: string;

  constructor(
      private readonly assetService: AssetService,
      private readonly sharedLinksApi: SharedLinksApiService,
      private readonly utils: UtilsService,
      private readonly mediaCache: MediaCacheService,
      private readonly snackBar: SnackBarService,
      private readonly dialogService: DialogService,
      private readonly dialog: MatDialog,
      private readonly pubsubApiService: PubsubApiService,
      private readonly errorService: ErrorService,
      private readonly tzDatePipe: TzDatePipe,
      private readonly featureFlag: FeatureFlagService,
      private readonly iasEventHelper: FirestoreIASEventHelper,
      private readonly dataService: FirebaseFirestoreDataService,
  ) {}
  /**
   * Side-effect observable. On subscription, adds the requested raw URL to the
   * queue for the next batch request, and waits until a batch request contains
   * the matching signed URL (it will emit only once).
   */
  batchSignUrl(input: string, linkName?: string): Observable<string|null> {

    if (linkName === undefined) {
      if (!this.batchProcessor$) {
          this.batchProcessor$ = this.makeBatchProcessor(linkName);
      }
    }
    else{
      const sameLinkName = (this.currentLinkName === linkName && linkName !== undefined);
      if (!this.batchProcessor$ || !sameLinkName) {
          this.currentLinkName = linkName;
          this.batchProcessor$ = this.makeBatchProcessor(linkName);
      }
    }

    // Ensure that inputs are in the format gs://uri.
    const rawUrl = input.replace('https://storage.googleapis.com/', 'gs://');
    const nowInSeconds = Date.now() / 1000;
    return of(null).pipe(
        tap(() => {
          // Add given URL to the queue.
          this.urlsToSign.next(rawUrl);
        }),
        switchMap(() => {
          // Check if raw URL has been cached.
          const cached = this.signedUrlCache.get(rawUrl);
          if (cached) {
            // If cached item is still valid, return it, otherwise clear
            // it from the cache and continue with the batch signing.
            const expiration = linkName ?
                UNAUTHENTICATED_SIGNATURE_EXPIRATION_SEC :
                AUTHENTICATED_SIGNATURE_EXPIRATION_SEC;
            if (cached.time + expiration >= nowInSeconds) {
              return of(cached.signed);
            } else {
              this.signedUrlCache.delete(rawUrl);
            }
          }

          // Emits when the batch processor gives a matching result.
          return castExists(this.batchProcessor$).pipe(
              filter(
                  signUrlsResponse =>
                      Boolean(signUrlsResponse?.signedUrls.length)),
              map(signUrlsResponse => signUrlsResponse?.signedUrls.find(
                      item => item.rawUrl === rawUrl)),
              filter(signedItem => Boolean(signedItem)),
              // We received the signed URL, the task is done, close
              // observable.
              first(),
              map(signedItem => signedItem?.signedUrl || null),
              // Cache signed response for faster retrieval next time.
              tap((signedUrl) => {
                if (signedUrl) {
                  this.signedUrlCache.set(rawUrl, {
                    time: nowInSeconds,
                    signed: signedUrl,
                  });
                }
              }),
          );
        }),
    );
  }

  /**
   * Side-effect observable. On subscription, adds the requested raw URLs to the
   * queue for the next batch request, and waits until a batch request contains
   * the matching signed URLs (it will emit only once).
   */
  batchSignUrls(rawUrls: string[], linkName?: string):
      Observable<Array<string|null>> {
    return forkJoin(rawUrls.map(rawUrl => this.batchSignUrl(rawUrl, linkName)));
  }

  /**
   * Batch deletes all provided assets if confirmed in a dialog. Displays the
   * result status with a snackbar.
   */
  async deleteAssetsWithConfirmation(assets: Original[]): Promise<void> {
    // Deleted assets are not selectable, but in case they were, prevent
    // the deletion of an already deleted asset.
    const nonDeletedAssets = assets.filter(asset => !asset.isDeleted);

    const count = nonDeletedAssets.length;
    if (!count) return;

    let question = `Do you want to delete ${count} assets?`;
    if (count === 1) {
      const asset = nonDeletedAssets[0];
      const title = this.assetService.getAssetTitle(asset) || '1 asset';
      question = `Do you want to delete "${title}"?`;
    }

    const extraChoice: [string, boolean]|undefined =
        !assets[0].isLive ? ['Also purge on-prem file(s)', true] : undefined;

    const confirmed = await firstValueFrom(this.dialogService.showConfirmation({
      title: `Delete Asset${count > 1 ? 's' : ''}`,
      question,
      extraChoice,
      primaryButtonText: 'Delete',
    }));

    if (!confirmed) return;

    const alsoPurgeFile = Boolean(confirmed.extraChoice);

    const responses$ = this.utils.batchApiCalls(
        nonDeletedAssets,
        asset =>
          {
            this.storeIASEventForDeleteAsset(asset);
            return this.mediaCache.purgeAndDelete(asset, !alsoPurgeFile);
          },
        {abortIfNonAdmin: true});
    const responses = await firstValueFrom(responses$);
    // eslint-disable-next-line unicorn/no-array-callback-reference -- https://github.com/microsoft/TypeScript/issues/38390
    const errors = responses.filter(isErrorResponse);

    const assetLabel = count > 1 ? 'assets' : 'asset';

    if (!errors.length) {
      // For instance "Successfully deleted 4 assets." or
      // "Successfully deleted and purged asset".
      const action = alsoPurgeFile ? 'deleted and purged' : 'deleted';
      this.snackBar.message(`Successfully ${action} ${
          count > 1 ? `${count} ` : ''}${assetLabel}.`);
      return;
    }

    if (hasAdminRightsMissing(errors)) {
      this.snackBar.error(`Asset deletion is reserved for administrators.`);
      return;
    }

    // For instance "Failed to delete selected asset." or
    // "Failed to delete 5 assets.".
    this.snackBar.error({
      message: `Failed to delete ${
          errors.length === count ?
              'selected' :
              errors.length} ${errors.length > 1 ? 'assets' : 'asset'}.`,
      details: errors[0]?.message,
      doNotLog: true,
    });
  }

  /** Purge asset files from premises with confirmation. */
  async purgeAssetsWithConfirmation(assets: Original[]): Promise<void> {
    const total = assets.length;
    const confirmed = await firstValueFrom(this.dialogService.showConfirmation({
      title: `Delete on-prem file(s)`,
      question: `Are you sure you want to delete asset ${
          this.utils.pluralize(total, 'file', 'files')} from premises?`,
      primaryButtonText: 'Delete',
    }));

    if (!confirmed) return;

    const responses = await firstValueFrom(this.mediaCache.purgeAssets(assets));

    const errors: ErrorResponse[] =
        responses
            // eslint-disable-next-line unicorn/no-array-callback-reference -- https://github.com/microsoft/TypeScript/issues/38390
            .filter(isErrorResponse)
            // Ignore errors indicating that the file doesn't exist.
            .filter(error => !this.mediaCache.isFileExistError(error.status));
    if (!errors.length) {
      this.snackBar.message(`On-prem deletion scheduled successfully`);
      return;
    }

    if (hasAdminRightsMissing(errors)) {
      this.snackBar.error(`On-prem deletion is reserved for administrators.`);
      return;
    }

    const allFailed = errors.length === total;

    this.snackBar.error({
      message: `Failed to schedule on-prem deletion for ${
          allFailed ?
              'selected' :
              'some'} ${this.utils.pluralize(total, 'asset', 'assets')}`,
      details: errors[0].message,
      doNotLog: true,
    });
  }

  /**
   * Shows a clip bin selector and then for each selected asset creates a full
   * duration clip to add to each selected bin.
   */
  async addClipsToBinsWithConfirmation(assets: Original[]): Promise<void> {
    if (!assets.length) return;

    const validAssets = assets.filter(
        asset =>
            !!asset.renditions.length && (!asset.isLive || !asset.approved));

    const invalidCount = assets.length - validAssets.length;
    if (invalidCount) {
      if (!validAssets.length) {
        this.snackBar.error(
            'Assets without renditions and approved live assets cannot be added as clips.');
        return;
      }

      const ignoredMsg = this.utils.pluralize(
          invalidCount,
          `1 asset will be ignored because it is live and approved or has no renditions`,
          `${invalidCount} assets will be ignored because they are live and approved or have no renditions`);
      const confirmed =
          await firstValueFrom(this.dialogService.showConfirmation({
            title: 'Add Clips',
            question: [
              ignoredMsg, `Proceed for remaining ${validAssets.length}?`
            ].join('\n'),
            primaryButtonText: 'Proceed',
          }));

      if (!confirmed) return;
    }

    const assetLabel = `${validAssets.length} ${
        this.utils.pluralize(validAssets.length, 'asset', 'assets')}`;

    const bins$ =
        this.dialog
            .open<
                SelectBinsDialog, SelectBinsDialogInputData,
                SelectBinsDialogOutputData>(
                SelectBinsDialog,
                SelectBinsDialog.getDialogOptions(
                    {isMultiselect: true, title: `Add ${assetLabel} to`}))
            .afterClosed();

    const selectedBins = await firstValueFrom(bins$);

    if (!selectedBins?.length) return;

    const binLabel = this.utils.pluralize(selectedBins.length, 'bin', 'bins');
    this.snackBar.message(
        `Adding ${assetLabel} to the selected ${binLabel}...`);

    const responses =
        await firstValueFrom(this.assetService.createFullDurationClipsInBins(
            validAssets, selectedBins));

    const errorCount =
        responses.reduce((total, r) => total + (isErrorResponse(r) ? 1 : 0), 0);

    if (errorCount === 0) {
      this.snackBar.message(
          `Successfully added ${assetLabel} to the selected ${binLabel}.`);
      return;
    }

    const firstError = responses.find(r => isErrorResponse(r)) as ErrorResponse;

    const message = errorCount === responses.length ?
        `Failed to add ${assetLabel} to the selected ${binLabel}.` :
        `Failed to add some assets to the selected ${binLabel}.`;

    this.snackBar.error({message, details: firstError.message, doNotLog: true});
  }

  /**
   * Shows the sync metadata dialog and then for each selected asset, send
   * the pubsub message.
   */
  async syncMetadata(assets: Original[]): Promise<void> {
    const count = assets.length;
    const assetLabel = `${count} asset${count > 1 ? 's' : ''}`;

    const data = await firstValueFrom(
        this.dialog
            .open<
                SyncMetadataDialog, SyncMetadataDialogInputData,
                SyncMetadataDialogOutputData>(
                SyncMetadataDialog, SyncMetadataDialog.getDialogOptions({
                  title: `Sync metadata for ${assetLabel}`,
                }))
            .afterClosed());

    if (!data) return;

    const response$ = this.utils.batchApiCalls(
        assets,
        asset => this.pubsubApiService
                     .publish({
                       'assetName': asset.name,
                       'schemaName': data.schemaName,
                       'foxSportsEventUri': data.foxSportsEventUri
                     })
                     .pipe(this.errorService.catchError()),
        {abortIfNonAdmin: true});

    const resp = await firstValueFrom(response$);
    // eslint-disable-next-line unicorn/no-array-callback-reference -- https://github.com/microsoft/TypeScript/issues/38390
    const errors = resp.filter(isErrorResponse);
    if (!errors.length) {
      this.snackBar.message(`Successfully sent ${count} synchronization ${
          count > 1 ? 'requests' : 'request'}.`);
      return;
    }

    if (hasAdminRightsMissing(errors)) {
      this.snackBar.error(`Sync metadata is reserved for administrators.`);
      return;
    }

    this.snackBar.error({
      message: `Failed to sync ${
          errors.length === count ?
              'selected' :
              errors.length} ${errors.length > 1 ? 'assets' : 'asset'}.`,
      details: errors[0]?.message,
      doNotLog: true,
    });
  }

  /**
   * Extend onprem files TTL to selected expiry date.
   */
  async extendTtlWithDatePicker(assets: Original[]): Promise<void> {
    const total = assets.length;
    const config: ExtendTtlDialogInputData = {silent: true};
    const extendTtlDialogResponse =
        this.dialog.open(ExtendTtlDialog, {data: config}).afterClosed();
    const expiryDate: string = await firstValueFrom(extendTtlDialogResponse);

    if (!expiryDate) return;

    const responses = await firstValueFrom(
        this.mediaCache.extendAssetsTtl(assets, expiryDate));

    // Since the UI does not verify whether TTL extension is possible, consider
    // invalid cases as no-op and do not report them as an error to the user.
    const noOps: ErrorResponse[] =
        // eslint-disable-next-line unicorn/no-array-callback-reference -- https://github.com/microsoft/TypeScript/issues/38390
        responses.filter(isErrorResponse)
            .filter(error => this.mediaCache.isFileExistError(error.status));

    // Other, actual errors that are not expected.
    const errors: ErrorResponse[] =
        responses
            // eslint-disable-next-line unicorn/no-array-callback-reference -- https://github.com/microsoft/TypeScript/issues/38390
            .filter(isErrorResponse)
            // Ignore errors indicating that the file doesn't exist.
            .filter(error => !this.mediaCache.isFileExistError(error.status));

    const successCount = responses.length - noOps.length;

    if (!errors.length) {
      if (successCount === 0) {
        this.snackBar.message(
            'No selected asset can have their file TTL extended.');
        return;
      }

      const t = this.getFormattedDate(expiryDate);
      this.snackBar.message(
          `On-prem file TTL scheduled to ${t} for ${successCount} file(s)`,
          undefined, SNACKBAR_LONG_DURATION);
      return;
    }

    if (hasAdminRightsMissing(errors)) {
      this.snackBar.error(
          `On-prem file TTL extension is reserved for administrators.`);
      return;
    }

    const allFailed = errors.length === total;

    this.snackBar.error({
      message: `Failed to schedule on-prem TTL for ${
          allFailed ?
              'selected' :
              'some'} ${this.utils.pluralize(total, 'asset', 'assets')}`,
      details: errors[0].message,
      doNotLog: true,
    });
  }

  /**
   * Export original assets to selected export folder by the export folder
   * dialog.
   */
  exportAssetsWithDialog(assets: Asset[]) {
    if (!assets.length) return;

    this.dialog
        .open<ExportAssetDialog, ExportAssetDialogInputData>(
            ExportAssetDialog, ExportAssetDialog.getDialogOptions({assets}))
        .afterClosed();
  }

  /** Emits any time a URL needs to be signed. */
  private readonly urlsToSign = new ReplaySubject<string>(1);

  /** Contains the list of all the URL that need to be batch signed. */
  private readonly batchQueue = new Set<string>();

  /** Cache signed URLs until they expire.  */
  private readonly signedUrlCache =
      new Map<string, {time: number, signed: string}>();

  /** Emits every time a batch request completes. */
  private batchProcessor$?: Observable<SignUrlResponse|null>;

  /**
   * Returns an observable that collects requests for URL signing being made
   * during the same event tick, makes one network batch request, then emits the
   * results.
   */
  private makeBatchProcessor(linkName?: string):
      Observable<SignUrlResponse|null> {
    return this.urlsToSign.pipe(
        // If the URL is already queued, ignore it.
        filter((rawUrl) => !this.batchQueue.has(rawUrl)),
        // Queue URL for the next batch request.
        tap((rawUrl) => {
          this.batchQueue.add(rawUrl);
        }),
        // Wait until the next tick to process all URLs requested
        // synchronously in one batch network call.
        debounceTime(0),
        // Now ready to execute request, reset the queue so that following
        // URLs will start being queued for the next batch call.
        map(() => Array.from(this.batchQueue)),
        tap(() => {
          this.batchQueue.clear();
        }),
        // Do not use `switchMap` here as if we execute a batch request while
        // another one is running, we want to be notified of both results.
        mergeMap((rawUrls) => this.signUrls(rawUrls, linkName)),
        // Multicast the batch result to all the subscribers.
        share(),
    );
  }

  /** Signs a list of GCS paths for read access. */
  private signUrls(rawUrls: string[], linkName?: string):
      Observable<SignUrlResponse|null> {
    if (linkName) {
      return this.sharedLinksApi.signUrls(linkName, rawUrls);
    }

    return this.assetService.signUrls(rawUrls).pipe(mapOnError(() => null));
  }

  private getFormattedDate(isoTime: string|null): string {
    if (!isoTime) return '';
    return this.tzDatePipe.transform(isoTime, 'MMM d, y, h:mm a') || '';
  }

  private async storeIASEventForDeleteAsset(asset: Original | Clip) {
    if (this.featureFlag.featureOn('store-user-information')) {
      const iasEvent : IASEvent = this.iasEventHelper.formatDeletedAssetsIASEvent(asset);
      await this.dataService.createIASEvent(iasEvent);
    }
  }
}
