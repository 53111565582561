<h1 mat-dialog-title class="dialog__title--size">Open Clip Bin</h1>
<div mat-dialog-content>
  <mat-form-field class="dialog__input--spacing dialog__input--size" appearance="outline" subscriptSizing="dynamic">
    <input matInput
        placeholder="Url"
        cdkFocusInitial
        #input
        autocomplete="off"
        (keyup.enter)="dialogRef.close(input.value)">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
      mam-firebase-ga="Cancel navigate to clip bin dialog"
      mat-button
      mat-dialog-close>
    Cancel
  </button>

  <button mat-button
      mam-firebase-ga="Confirm navigate to clip bin dialog"
      [mat-dialog-close]="input.value"
      [disabled]="!input.value.trim()"
      class="primary"
      #primary>
    Open
  </button>
</div>
