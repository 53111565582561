import {Injectable} from '@angular/core';
import {firstValueFrom, fromEvent, Observable, of, throwError} from 'rxjs';
import {filter, map, switchMap, take, timeout} from 'rxjs/operators';

import {getEnvironmentParam} from '../environments/util';
import {ErrorResponse, isErrorResponse} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';

import {MamUiMessage, PluginAction, PluginMessage} from './plugin_types';

// Used for the target origin in the `dispatch` method. In the plugin,
// window.location.origin resolves to `file://`
const ENVIRONMENT_ORIGIN = 'file://';

/** Used to capture the iasType as a generic ACTION. */
type Request<ACTION> = MamUiMessage&{iasType: ACTION};

/** Used to type the response payload knowing the request generic ACTION. */
type Response<ACTION> = PluginMessage&{pluginMessageType: ACTION};

/**
 * Service to set and retrieve Plugin status
 */
@Injectable({providedIn: 'root'})
export class PluginService {
  /** Current plugin version, such as `1.2.3`. */
  version: string|undefined = getEnvironmentParam('plugin', '') || undefined;

  constructor(private readonly errorService: ErrorService) {}

  // Indicates whether we are currently in an iframe, which is necessary to emit
  // messages to the plugin host. Plugin functionalities may be simulated in a
  // browser for debug purposes by setting a `plugin` query parameter.
  isIframe() {
    return (window !== window.top);
  }

  /**
   * Whether the current semver version is greater or equal than the one
   * provided. For instance, `0.10.0 >= 0.9.0`. The string "true" will be
   * greater than any number, and "false" never.
   */
  isVersionAtLeast(version: string): boolean {
    if (!this.version) return false;
    if (this.version === 'true') return true;
    if (this.version === 'false') return false;
    return this.version.localeCompare(version, undefined, {
      numeric: true,
      sensitivity: 'base',
    }) >= 0;
  }

  /**
   * Reloads the plugin iframe contents
   */
  reload() {
    location.reload();
  }

  /**
   * Triggers an event on the Adobe panel that executes an action in the
   * Adobe Premiere host application JSX.
   */
  dispatch(message: MamUiMessage): void {
    const propertyRenamingSafeMessage = {
      'iasType': message.iasType,
      'payload': message.payload,
      'requestId': message.requestId,
    };

    if (!this.isIframe()) {
      console.debug('[PluginService->dispatch]', propertyRenamingSafeMessage);
      return;
    }

    window.top?.postMessage(propertyRenamingSafeMessage, ENVIRONMENT_ORIGIN);
  }

  /**
   * Posts a message to the plugin app, and expects a response to be posted
   * back.
   */
  request<ACTION extends PluginAction>(
      message: Request<ACTION>,
      timeoutMs: number): Observable<Response<ACTION>|ErrorResponse> {
    // `requestId` will be sent back from the plugin along with its response
    // payload so that we recognize which request it is the response to.
    const requestId = `RID:${message.iasType}:${this.requestCounter++}`;

    if (!this.isIframe()) {
      console.debug('[PluginService->request]', {...message, requestId});
      return of(new ErrorResponse('Plugin simulated'));
    }

    // Listen to all messages until we get one matching the `requestId`.
    const response$ = this.pluginMessages$.pipe(
        // Filter out messages sent from other requests.
        filter(event => event.data.requestId === requestId),
        // No need to listen to more messages for this request.
        take(1),
        // Timeout if a response isn't received after the given `timeoutMs`.
        timeout({
          each: timeoutMs,
          with: () => throwError(() => `${message.iasType} timeout.`)
        }),
        // Extract message data.
        map(event => event.data as PluginMessage & {pluginMessageType: ACTION}),
        // Throw error if the response indicates an error.
        switchMap(message => {
          return message.error ? throwError(() => message.error) : of(message);
        }),
        this.errorService.catchError(),
    );

    // Now we listen for a response message, send the request.
    this.dispatch({...message, requestId});

    return response$;
  }

  /** Makes a request and converts response to a promise. */
  async fetch<ACTION extends PluginAction>(
      message: Request<ACTION>, timeoutMs: number): Promise<Response<ACTION>> {
    const response$ = this.request(message, timeoutMs);
    return firstValueFrom(response$.pipe(switchMap(response => {
      return isErrorResponse(response) ? throwError(() => response) : of(response);
    })));
  }

  private requestCounter = 0;

  /** Emits any message received from the PPro plugin host. */
  private readonly pluginMessages$ =
      fromEvent(window, 'message')
          .pipe(
              map(event => event as MessageEvent<PluginMessage>),
              // Ensure that messages come from our plugin host.
              filter(event => event?.origin === ENVIRONMENT_ORIGIN));
}
