import { Injectable } from '@angular/core';

import { AssetRendition } from 'models';
import { PlaybackAuthorizationService } from 'services/playback-authorization/playback-authorization.service';

import { Asset } from '../asset_service';
import { RenditionVersion, RenditionVersionEnum } from '../ias_types';

import { RenditionServiceDependencies } from './rendition-service-dependencies';

@Injectable({ providedIn: null })
export abstract class RenditionService {
    private readonly playbackAuthorizationService: PlaybackAuthorizationService;

    constructor(
        dependencies: RenditionServiceDependencies,
        private versionMap: Map<RenditionVersion, RenditionVersion>
    ) {
        this.playbackAuthorizationService = dependencies.playbackAuthorizationService;
    }

    public async getRenditionUrl(asset: Asset, version: RenditionVersion, exactVersion: boolean) {
        return await this.getAuthorizedRenditionUrl(this.getRendition(asset, version, exactVersion));
    }

    public async getManifestUrl(asset: Asset) {
        // TODO-HLS: Adapt VideoProtocolService to provide RenditionVersionEnum for main live/preview.
        const version = asset.isLive ? RenditionVersionEnum.LIVE_MAIN_DASH : RenditionVersionEnum.PREVIEW_MAIN_DASH;

        // Fake backend does not have VoD DASH/HLS manifests, so we fallback to MP4.
        return (await this.getRenditionUrl(asset, version, false)) || 
            (await this.getRenditionUrl(asset, RenditionVersionEnum.PREVIEW_MAIN, true));
    }

    private async getAuthorizedRenditionUrl(rendition?: AssetRendition) {
        return (rendition?.url) ?
            (await this.playbackAuthorizationService.getAuthorizedUri(rendition.url)) :
            undefined;
    }

    private selectVersion(version: RenditionVersion): RenditionVersion {
        return this.versionMap.get(version) ?? version;
    }

    public getRendition(asset: Asset, version: RenditionVersion, exactVersion = false) {
        const targetVersion = exactVersion ? version : this.selectVersion(version);

        return asset.renditions.find(r => r.version === targetVersion);
    }
}