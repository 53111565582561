import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {delay} from 'rxjs/operators';

import {ExportInfo, ExportStatus} from 'models';

import {environment} from '../environments/environment';

import {getFakeVodOriginals, makeFakeClip, makeFakeLiveClip} from './asset_api_fake_service';
import {Clip, ListResponse, Original} from './asset_service';
import {getClipBins} from './bin_api_fake_service';
import {ClipApiService} from './clip_api_service';
import {makeFakePage, pseudoRandom} from './fake_api_utils';
import {Interface} from './utils_service';

const FIRST_PAGE_TOKEN = 'FIRST_PAGE_TOKEN';
const SECOND_PAGE_TOKEN = 'SECOND_PAGE_TOKEN';

/** Fake ClipApiService implementation for local development. */
@Injectable({providedIn: 'root'})
export class FakeClipApiService implements Interface<ClipApiService> {
  create(
      original: Original, label: string, startTime: number, endTime: number,
      title: string): Observable<Clip> {
    const clip = makeFakeClip(
        {
          name: `clip_from_${original.name}`,
          label,
          title,
          startTime,
          endTime,
        },
        original);

    // Only for local development demo, update local mock DB one time without
    // persisting the data permanently.
    fakeClipsDb.push(clip);

    return of(clip).pipe(delay(100));
  }

  move(name: string): Observable<Clip> {
    const movedClip = fakeClipsDb.find(clip => clip.name === name) ||
        makeFakeClip({name: 'test'});
    return of(movedClip).pipe(delay(100));
  }

  delete(name: string): Observable<null> {
    fakeClipsDb.splice(fakeClipsDb.findIndex(clip => clip.name === name), 1);
    return of(null).pipe(delay(100));
  }

  download(): Observable<Clip> {
    return throwError(() => 'Not implemented in local environment').pipe(delay(1600));
  }

  export(clipName: string): Observable<Clip> {
    const exportedClip = fakeClipsDb.find(clip => clip.name === clipName) ||
        makeFakeClip({name: 'exportedClip'});

    return of(exportedClip).pipe(delay(100));
  }

  rename(name: string, title: string): Observable<Clip> {
    const renamedClip = fakeClipsDb.find(clip => clip.name === name) ||
        makeFakeClip({name: 'test'});
    renamedClip.title = title;
    return of(renamedClip).pipe(delay(100));
  }

  searchClips(
      binName: string, query: string, pageToken?: string,
      pageSize = 24): Observable<ListResponse<Clip>> {
    const listAssetsResponse: ListResponse<Clip> = {assets: []};
    let count = pageSize;

    // Do not return more clips than the fake clipbin contains.
    const bin = getClipBins().find(bin => bin.name === binName);
    if (bin && bin.assetCount && !Number.isNaN(+bin.assetCount)) {
      count = Math.min(+bin.assetCount, count);
    }

    // Generate fake clips on-demand (they do not pre-exist this call, unlike
    // fake original assets) by changing some properties of the fake assets.
    listAssetsResponse.assets =
        getFakeVodClips()
            .map((asset, index) => {
              // Simulate clips with arbitrary durations
              // `startTime` is from 0 to 49% of the duration
              // `endTime` is from 2% to full duration
              const ratio =
                  pseudoRandom(binName + (pageToken || '') + index.toString());
              const startTime = asset.duration * (ratio * 0.49);
              const endTime = startTime * 2 + (asset.duration * 0.02);
              const clip: Clip = {
                ...asset,
                label: binName,
                startTime,
                endTime,
                duration: endTime - startTime,
              };
              return clip;
            })
            .filter(clip => clip.title.includes(query))
            .slice(0, count);

    // Generate three pages of results
    switch (pageToken) {
      case FIRST_PAGE_TOKEN:
        listAssetsResponse.nextPageToken = SECOND_PAGE_TOKEN;
        break;
      case SECOND_PAGE_TOKEN:
        listAssetsResponse.nextPageToken = undefined;
        break;
      default:
        listAssetsResponse.nextPageToken = FIRST_PAGE_TOKEN;
        break;
    }

    return of(listAssetsResponse).pipe(delay(100));
  }

  searchClipsByTitle(
    query: string,
    pageSize: number = 12,
    pageToken: string = ''
  ): Observable<ListResponse<Clip>> {
      return this.searchClips('',query,pageToken,pageSize);
  }

  getClipsFromAsset(
      originalAssetName: string, pageSize: number, pageToken?: string) {
    const allAssets = [...getFakeVodClips(), ...fakeClipsDb];
    const allClips =
        allAssets.filter(clip => clip.original?.name === originalAssetName);
    const result = makeFakePage(allClips, pageSize, pageToken);
    const listResponse: ListResponse<Clip> = {
      assets: result.pageOfItems,
      nextPageToken: result.nextPageToken,
    };
    return of(listResponse).pipe(delay(300));
  }

  getCount(clipbinName: string) {
    return of({count: clipbinName.length * 30}).pipe(delay(2000));
  }

  getLiveClipCount() {
    return of({count: 0}).pipe(delay(2000));
  }

  getOne(name: string) {
    // Combine all fake assets with any fake clip created since the start of
    // this session to retrieve an individual clip.
    const allAssets = [...getFakeVodClips(), ...fakeClipsDb];
    const clip = allAssets.find(clip => clip.name === name);
    const clip$ = clip ? of(clip) : throwError(() => `Clip not found: ${name}`);
    return clip$.pipe(delay(300));
  }

  reorder(clip: Clip) {
    return of(clip);
  }

  listExportPfrClips() {
    const allClips = [...getFakeVodClips(), ...fakeClipsDb];
    const result = makeFakePage(allClips, 20, '');
    const listResponse: ListResponse<Clip> = {
      assets: result.pageOfItems,
      nextPageToken: result.nextPageToken,
    };
    return of(listResponse).pipe(delay(300));
  }

  listExportLiveClips() {
    const liveClipsResponse: ListResponse<Clip> = {
      ...getFakeLiveClips(),
      nextPageToken: undefined,
    };
    return of(liveClipsResponse).pipe(delay(300));
  }
}

/** Contains extra clips created during this session. */
const fakeClipsDb: Clip[] = [];

/** Simulates a database call with new references each call.  */
export function getFakeVodClips(): Clip[] {
  const vodAssets = getFakeVodOriginals();
  return vodAssets.map(
      original => makeFakeClip({name: `clip_of_${original.name}`}, original));
}

function getFakeLiveClips(): ListResponse<Clip> {
  return {
    assets: [
      makeFakeLiveClip({
        name: 'ChromeCast',
        label: 'ChromeCast Ad',
        original: undefined,
        exportInfo: new ExportInfo({
          stateMap: {
            [environment.mamApi.parent + '/sites/lax/folders/' + 'fake-folder']: new ExportStatus({
              updateTime: new Date().toISOString(),
              exportState: 'EXPORT_PENDING',
            })
          },
        }),
      }),
      makeFakeLiveClip({
        name: 'BBB',
        label: 'Big Buck Bunny',
        original: undefined,
        exportInfo: new ExportInfo({
          stateMap: {
            [environment.mamApi.parent + '/sites/lax/folders/' + 'fake-folder']: new ExportStatus({
              updateTime: new Date().toISOString(),
              exportState: "EXPORT_COMPLETED",
            })
          },
        }),
      }),
      makeFakeLiveClip({
        name: 'SundarInterview',
        label: 'Sundar Interview',
        original: undefined,
        exportInfo: new ExportInfo({
          stateMap: {
            [environment.mamApi.parent + '/sites/lax/folders/' + 'fake-folder']: new ExportStatus({
              updateTime: new Date().toISOString(),
            })
          },
        }),
      }),
      makeFakeLiveClip({
        name: 'Sintel',
        label: 'Sintel',
        original: undefined,
        exportInfo: new ExportInfo({
          stateMap: {
            [environment.mamApi.parent + '/sites/lax/folders/' + 'fake-folder']: new ExportStatus({
              updateTime: new Date().toISOString(),
              //exportState: "EXPORT_COMPLETED",
              exportState: 'EXPORT_FAILED',
            })
          },
        }),
      }),
    ]
  };
}
