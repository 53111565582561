import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';

import {FeatureName} from './feature_config';
import {FeatureFlagService} from './feature_flag_service';

/**
 * Directive used to turn on a feature when the feature flag is given.
 *
 * Example usage:
 *
 * {@code
 * <shiny-new-feature-component
 *   *featureFlagOn="featureName"
 * </shiny-new-feature-component>
 */
@Directive({
  selector: '[featureFlagOn]',
})
export class FeatureFlagOn implements OnInit {
  /** The name of the feature flag. */
  @Input() featureFlagOn?: FeatureName;


  constructor(
      private readonly vcr: ViewContainerRef,
      private readonly tpl: TemplateRef<{}>,
      private readonly featureFlagService: FeatureFlagService) {}


  ngOnInit() {
    if (this.featureFlagOn &&
        this.featureFlagService.featureOn(this.featureFlagOn)) {
      this.vcr.createEmbeddedView(this.tpl);
    }
  }
}
