/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import * as CryptoJS from 'crypto-js';

import { environmentDetails } from './environment_cred';
import { Environment, environmentDefaults } from './environment_types';

const decrypted = CryptoJS.AES.decrypt(environmentDetails.encrypt, environmentDetails.enryptID);
const decrypt = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
const cred = JSON.parse(decrypt);

/** Makes real API calls */
export const environment: Environment = {
    ...environmentDefaults,
    tag: 'qa',
    projectId: cred.projectId,
    clientId:
        cred.qa_client,
    mamApi: {
        baseUrl:
            'https://staging-intelligentassetservice-googleapis.sandbox.google.com',
        parent:
            'projects/234973717435/locations/global',
        bucketRootUri:
        'gs://foxsports-ias-sandbox-archive01/'
    },
    pubsubApi: {
        baseUrl: 'https://pubsub.googleapis.com',
        topic: 'projects/foxsports-mediaarchive-sandbox/topics/ias_metadata_topic',
    },
    stackDriverErrorReportingApiKey: cred.qa_stack,
    firebaseConfig: {
        apiKey: cred.qa_fireapi,
        appId: '1:369705616743:web:393e7783831f7d3ad21bc2',
        projectId: cred.projectId,
    },
    maintenance: false,
    defaultSuggestions: cred.defaultSuggestions,
};
