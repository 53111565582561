import {ApiCloudTransferOperation} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

/**
 * UI model containing information about a single storage transfer operation.
 */
export class CloudTransferOperation implements
    RequiredRecursively<ApiCloudTransferOperation> {
  name = this.apiOperation.name ?? '';
  endTime = this.apiOperation.endTime ?? '';
  operationState =
      this.apiOperation.operationState ?? 'OPERATION_STATE_UNSPECIFIED';
  startTime = this.apiOperation.startTime ?? '';
  statusInfo = this.apiOperation.statusInfo ?? {};
  transferJobId = this.apiOperation.transferJobId ?? '';

  constructor(private apiOperation: ApiCloudTransferOperation = {}) {}
}
