import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';

import {BaseQueryExpressions, QueryArg} from './base_query_expressions';

/** Asset properties that are indexed by VCMS  */
export type VcmsField = 'AssetState'|'AssetApprovalState'|'EventStartTime'|
    'AssetCreateTime'|'IsBroadcast'|'title';

/**
 * Utility service that provides convenience methods to construct VCMS
 * search query.
 */
@Injectable({providedIn: 'root'})
export class VcmsQueryExpressions extends BaseQueryExpressions<VcmsField> {
  /**
   * Creates a key-value query.
   * 
   * @example
   * is('AssetState', 'STATE_READY') // 'AssetState: (STATE_READY)'
   */
  override is(key: VcmsField, value: QueryArg) {
    if (typeof value === 'string') value = value.trim();
    if (value === '') return '';

    return `${key}: (${value})`;
  }

  /**
   * Creates query that ensures that the results fall between
   * `00:00:00.000 - 23:59:59.999` on a certain day using `_unix_sec_before` and
   * `_unix_sec_after` search operations.
   *
   * We can't simply compare dates because BE stores dates in UTC while MAM
   * needs timezone support.
   *
   * @example
   * const date = DateTime.fromObject({year: 2021, month: 4, day: 15});
   * withinDate('EventStartTime', date);
   *
   * // Produces:
   * // (EventStartTime_unix_sec_after: (1618470000)) AND
   * // (EventStartTime_unix_sec_before: (1618556399))
   */
  withinDate(field: VcmsField, value: DateTime) {
    const dayStart = value.startOf('day');
    const dayEnd = value.endOf('day');
    return this.and(
        [this.dateAfter(field, dayStart), this.dateBefore(field, dayEnd)]);
  }

  /**
   * Creates query that ensures that the results fall at or after certain
   * timestamp.
   *
   * @example
   * const date = DateTime.fromObject({year: 2021, month: 4, day: 15});
   * dateAfter('EventStartTime', date);
   *
   * // Produces:
   * // EventStartTime_unix_sec_after: (1618470000)
   */
  dateAfter(field: VcmsField, value: DateTime) {
    const dateInSeconds = this.convertDateToTotalSeconds(value);
    return this.is(`${field}_unix_sec_after` as VcmsField, dateInSeconds);
  }

  /**
   * Creates query that ensures that the results fall at or before certain
   * timestamp.
   *
   * @example
   * const date = DateTime.fromObject({year: 2021, month: 4, day: 15});
   * dateBefore('EventStartTime', date);
   *
   * // Produces:
   * // EventStartTime_unix_sec_before: (1618556399)
   */
  dateBefore(field: VcmsField, value: DateTime) {
    const dateInSeconds = this.convertDateToTotalSeconds(value);
    return this.is(`${field}_unix_sec_before` as VcmsField, dateInSeconds);
  }

  private convertDateToTotalSeconds(value: DateTime) {
    return Math.floor(value.toMillis() / 1000);
  }
}
