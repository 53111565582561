import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ReplaySubject} from 'rxjs';

import {Clip} from '../services/asset_service';

import {MoveClipAction} from './move_clip_button';

/**
 * The dialog that provides move/copy clip functionality.
 *
 * Relies on components like `ClipbinSearchPanel` to display/interact with bin
 * list and `MoveClipButton` to perform the action.
 */
@Component({
  selector: 'mam-move-clip-dialog',
  templateUrl: './move_clip_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
    @import './shared/mixins';
    :host {
      max-width: 502px;
      width: 70vw;
      display: block;

        @include breakpoint-md-max {
          max-width: 95vw;
          width: 65vw;
          }

        @include breakpoint-sm-max {
          width: initial;
          max-width: initial;
        }
      }`
  ]
})
export class MoveClipDialog {
  static getDialogOptions(data: MoveClipDialogInputData):
      MatDialogConfig<MoveClipDialogInputData> {
    return {data};
  }

  selectedBins: string[] = [];

  constructor(
      readonly dialogRef: MatDialogRef<MoveClipDialog>,
      @Inject(MAT_DIALOG_DATA) public data: MoveClipDialogInputData) {}

  close(actionDone$: ReplaySubject<boolean>) {
    this.dialogRef.close(actionDone$);
  }
}

/** Dialog data for MoveClipDialog */
export interface MoveClipDialogInputData {
  clips: Clip[];
  action: MoveClipAction;
}
