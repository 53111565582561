import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable } from '@angular/core';

import { NAVIGATOR } from '../shared/tokens';

export type VideoProtocolType = 'DASH' | 'HLS';

@Injectable({ providedIn: 'root' })
export class VideoProtocolService {

    constructor(
        private readonly platform: Platform,
        @Inject(NAVIGATOR) private readonly navigator: Navigator,
    ) {
    }

    public getVideoProtocol() : VideoProtocolType {
        return this.isMobileWebKit() ? 'HLS': 'DASH';
    }

    public isMobileWebKit(): boolean {
        /*
        * Sadly there isn't a reliable mechanism to detect mobile webkit by angular material or by shaka player.
        *
        * At the time of writing there was an open issue with
        * angular material https://github.com/angular/components/issues/17220
        *
        * At the time of writing shaka raised that there was no good way of
        * detecting the mobile. https://github.com/shaka-project/shaka-player/issues/2360
        *
        * There is something, but it may be dangerous to bind ourselves to it since
        * it is a low priority for shaka. See comments in commit
        * https://github.com/shaka-project/shaka-player/commit/65ad9adf2b7aef3dea8b2b6ea64ae1523cedae85
        *
        * We've done a basic setup which may work for now, but keep an eye on this as Apple
        * make it harder to tell them apart as raised in the shaka commit.
        */
        return this.platform.WEBKIT && (this.navigator.maxTouchPoints > 0);
    }
}
