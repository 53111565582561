import {ApiPFRStateInfo} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

import {RpcStatus} from './rpc-status';

/** A UI model representing clip's pfr state */
export class PfrStateInfo implements RequiredRecursively<ApiPFRStateInfo> {
  createTime = this.apiInfo.createTime ?? '';
  errorDetails = new RpcStatus(this.apiInfo.errorDetails ?? {});
  outputGcsUri = this.apiInfo.outputGcsUri ?? '';
  state = this.apiInfo.state ?? 'STATE_UNSPECIFIED';
  updateTime = this.apiInfo.updateTime ?? '';

  constructor(private apiInfo: ApiPFRStateInfo = {}) {}
}
