import { BreakpointObserver } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { DomPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, shareReplay, skip, withLatestFrom } from 'rxjs/operators';

import { FullscreenElementPriority } from './fullscreen_element_priority';

type Priority = FullscreenElementPriority & { element: HTMLElement };

enum Orientation {
  Portrait = '(orientation: portrait)',
  // `ponter: coarse` needed here for edge case going from mobile+portrait into mouse+landscape (ex. dev tools)
  Landscape = '(orientation: landscape) and (pointer: coarse)',
}

@Injectable({ providedIn: 'root' })
export class PlayerFullscreenService {
  // elements registered to be fullscreened
  private fullScreenElementPrioritiesSubject$;
  // the selected highest priority element to be fullscreened
  public fullscreenElement$: Observable<DomPortal | undefined>;

  // tracks the intent to go into fullscreen or not
  private setFullScreenIntentSubject$;
  // tracks whether the app actually is in fullscreen or not
  public isFullScreen$: Observable<boolean>;
  /*
    this is always updated to the latest value of `isFullScreen`
    used in the toggle where you need to know if the app is actually in fullscreen
  */
  private isFullScreen;

  // subject emits when ever there's a reuqest to open the native full screen player
  private nativeFullscreenSubject$ = new Subject<void>();

  // Track if the automatic full screen on landscape rotation is enabled
  public automaticFullScreenEnabled: boolean = true;

  isAndroid: boolean = this.platform.ANDROID;

  isIos: boolean = this.platform.IOS;

  isSmallScreen$ = this.breakpointObserver
  .observe(['(max-width: 1280px)'])
  .pipe(
    map((result) => result.matches),
    shareReplay(1)
  );

  isLandscape$ = this.breakpointObserver
  .observe('(orientation: landscape)')
  .pipe(
      map(result => result.matches),
      shareReplay()
  );

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly platform: Platform
  ) {
    this.fullScreenElementPrioritiesSubject$ = new BehaviorSubject<Array<Priority>>([]);
    this.fullscreenElement$ = this.fullScreenElementPrioritiesSubject$.pipe(
      map((priorities: Array<Priority>) => priorities.reduce<Priority | undefined>(
        (accum, curr) => (!accum || curr.priority > accum.priority) ? curr : accum, undefined)),
      map((priority: Priority | undefined) => priority && new DomPortal(priority.element)));

    this.setFullScreenIntentSubject$ = new BehaviorSubject<boolean>(false);
    this.isFullScreen$ = this.setFullScreenIntentSubject$.pipe(
      withLatestFrom(this.fullscreenElement$),
      map(([fullscreenIntent, portal]) => portal ? fullscreenIntent : false),
      shareReplay(1));
    this.isFullScreen = false;

    this.nativeFullscreenSubject$.pipe(
      withLatestFrom(this.fullscreenElement$)
    ).subscribe(( [, portal]) => {
      this.openNativeFullScreenMode(portal as DomPortal);
    });

    this.observeFullScreen();
    this.observeOrientation();
    this.observeFullscreenElement();
  }

  private observeFullScreen() {
    this.isFullScreen$.subscribe(isFullScreen => {
      this.isFullScreen = isFullScreen;
    });
  }

  private observeOrientation() {
    this.breakpointObserver.observe(Object.values(Orientation))
      .pipe(skip(1))
      .subscribe(result => {
        if (result.breakpoints[Orientation.Portrait]) {
          this.disableFullScreen();
        }

        if (result.breakpoints[Orientation.Landscape] && this.automaticFullScreenEnabled) {
          this.enableFullScreen();
        }
      });
  }

  private observeFullscreenElement() {
    this.fullscreenElement$.subscribe(portal => {
      if (this.isFullScreen && !portal) {
        this.setFullScreenIntentSubject$.next(false);
      }
    });
  }

  private getIsFullScreen() {
    return this.isFullScreen;
  }

  toggleFullScreenMode() {
    const isFullScreen = this.getIsFullScreen();

    if (isFullScreen) {
      this.disableFullScreen();
    } else {
      this.enableFullScreen();
    }
  }

  enableFullScreen() {
    this.setFullScreenIntentSubject$.next(true);
  }

  disableFullScreen() {
    this.setFullScreenIntentSubject$.next(false);
  }

  openNativeFullScreen() {
    this.nativeFullscreenSubject$.next();
  }

  /* Full screen is very much platform dependent */
  private openNativeFullScreenMode(domPortal: DomPortal | undefined) {
    const vid = domPortal?.element.querySelector('video') as FullScreenVideoElement;
    if (!vid) {
      return;
    }

    if(vid.requestFullscreen) {
      vid.requestFullscreen();
    } else if (vid.webkitRequestFullScreen) {
      vid.webkitRequestFullScreen();
    } else if (vid.webkitRequestFullscreen) {
      vid.webkitRequestFullscreen();
    } else if (vid.webkitEnterFullscreen) {
      vid.webkitEnterFullscreen();
    } else if (vid.mozRequestFullScreen) {
      vid.mozRequestFullScreen();
    } else if (vid.msRequestFullscreen) {
      vid.msRequestFullscreen();
    }
  }

  private getFullScreenElementPriorities() {
    return this.fullScreenElementPrioritiesSubject$.getValue();
  }

  // in-place mutation of priorities
  private removeElementPriority(key: string, priorities: Array<Priority>): boolean {
    const priority = priorities.find(p => p.key === key);

    if (!priority) {
      return false;
    }

    priorities.splice(priorities.indexOf(priority), 1);
    return true;
  }

  // this will add/update an element priority
  registerElement(element: HTMLElement, elementPriority: FullscreenElementPriority) {
    const priorities = this.getFullScreenElementPriorities();

    // rather than deal with finding and update logic in case key exists, just delete if present and add
    this.removeElementPriority(elementPriority.key, priorities);

    priorities.push({ ...elementPriority, element });
    this.fullScreenElementPrioritiesSubject$.next(priorities);
  }

  deregisterElement(key: string) {
    const priorities = this.getFullScreenElementPriorities();
    const res = this.removeElementPriority(key, priorities);

    if (!res) {
      console.error(`Unable to find key "${key}" to deregister`);
      return;
    }

    this.fullScreenElementPrioritiesSubject$.next(priorities);
  }
}

interface FullScreenVideoElement extends HTMLVideoElement {
  webkitRequestFullScreen?(): void;
  webkitRequestFullscreen?(): void;
  webkitEnterFullscreen?(): void;
  mozRequestFullScreen?(): void;
  msRequestFullscreen?(): void;
}
