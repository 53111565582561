import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';

import {ConfirmDialogModule} from 'confirm_dialog/confirm_dialog_module';
import {SharedClipBinModule} from 'shared_clipbin/shared_link_clipbin_module';
import {SharedLinkModule} from 'shared_link/shared_link_module';

import {ErrorServiceModule} from '../error_service/error_service_module';

import {App} from './app';
import {FullscreenOutlet} from './fullscreen-outlet';
import {RoutingModule} from './routing_module';


@NgModule({
  declarations: [App, FullscreenOutlet],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    ErrorServiceModule,
    RouterModule,
    RoutingModule,
    ConfirmDialogModule,
    SharedLinkModule,
    SharedClipBinModule,
    PortalModule,
  ],
  bootstrap: [App],
})
export class AppModule {
}
