<mat-card
    appearance="outlined"
    class="folder_card"
		[class.mam-elevation-1]="viewMode === 'grid'"
		[class.folder_card--list]="viewMode === 'list'"
    [routerLink]="['/folders', folder.id]"
    queryParamsHandling="merge"
    mam-firebase-ga="Open clip folder from its preview"
    [mam-firebase-resource]="folder.id">
  <div class="card-header-image">
  </div>
  <div class="card-content">
    <div class="card-content-width">
      <div class="card-content-title" [attr.title]="folder.displayName">
        <div class="name-side-icon">
          <img src="../../images/folder_icon_light.svg" alt="Folder Icon Light">
        </div>
        <p>{{ folder.displayName }}</p>
      </div>
      <div class="card-footer">
        <div class="card-content-subtitle" [ngPlural]="(folder.items)? folder.items : 0">
           <ng-template ngPluralCase="=0">No items</ng-template>
          <ng-template ngPluralCase="=1">1 item</ng-template>
          <ng-template ngPluralCase="other">{{folder.items}} items</ng-template>
        </div>
      </div>
    </div>
    @if(isAdmin || userEmail === folder.createdBy){
    <button
        mat-icon-button
        class="folder-button main__icon--size"
        [matMenuTriggerFor]="menu"
        aria-label="Open folder Menu"
        (click)="$event.stopPropagation()"
        mam-firebase-ga="Open folder menu"
        [mam-firebase-resource]="folder.id">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu
        #menu="matMenu"
        class="folder-options-menu">
      <button
          mat-menu-item
          (click)="openRenameFolder()"
          mam-firebase-ga="Open clip folder rename dialog"
          [mam-firebase-resource]="folder.id">
        Rename
      </button>
      <button
          mat-menu-item
          (click)="openDeleteFolder()"
          class="delete-button"
          mam-firebase-ga="Select folder deletion"
          [mam-firebase-resource]="folder.id">
        Delete folder
      </button>
    </mat-menu>
    }
  </div>
</mat-card>
