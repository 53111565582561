<span (click)="onFolderIconClick(); $event.preventDefault();" class="folder-name">
  <img  src="../../images/folder_icon_light.svg" alt="Folder icon">
  {{currentFolder?.displayName}}
</span>
@if (!resultsLoading) {
<div class="content-container"
[class.folders_grid]="hasContent"
[class.folders_grid--list]="displayMode === 'list'">

  @if (hasContent) {
  @for (folder of folderContent; track trackBy) {
  <div class="folders_grid_card_container">
    <div
      class="clipbins_checkbox"
      [class.list_mode]="displayMode === 'list'"
      *featureFlagOn="'use-clips-selection'">
      <!-- <mat-checkbox
        color="primary"
        *ngIf="!showAllBins"
        (click)="toggleSelection($event,bin); $event.stopPropagation();"
        [checked]="itemMultiSelection.has(bin.name)"
      >
      </mat-checkbox> -->
    </div>
    <mam-clip-bin-folder-preview
      class="folders_grid__card"
      [isAdmin]="isAdmin"
      [userEmail]="userEmail"
      [folder]="folder"
      [viewMode]="displayMode">
    </mam-clip-bin-folder-preview>
  </div>
  }
  @for (bin of binContent; track trackBy) {
  <div class="folders_grid_card_container">
    <div
      class="clipbins_checkbox"
      [class.list_mode]="displayMode === 'list'"
      *featureFlagOn="'use-clips-selection'">
      <!-- <mat-checkbox
        color="primary"
        *ngIf="!showAllBins"
        (click)="toggleSelection($event,bin); $event.stopPropagation();"
        [checked]="itemMultiSelection.has(bin.name)"
      >
      </mat-checkbox> -->
    </div>
    <mam-clip-bin-preview
      class="folders_grid__card"
      [isAdmin]="isAdmin"
      [isMyFolder]="currentFolder?.createdBy === userEmail"
      (contentEvent)="onContentEvent($event)"
      [bin]="bin"
      [binsViewMode]="displayMode">
    </mam-clip-bin-preview>
  </div>

  }
  } @else {

  <mam-empty-assets-card [assetsType]="'content'" [hasSearchText]="false" [helperText]="true">
    <p>
      Use the <span><mat-icon>add</mat-icon> New</span> button in the top left to create bins and folders and start
      adding.
    </p>
  </mam-empty-assets-card>
  }
</div>
} @else {
<!-- Loading -->
<div class="skeletons">
  <mam-assets-skeleton class="mam-skeleton" *ngFor="let i of seletonItems"
    [display]="displayMode"></mam-assets-skeleton>
</div>
}
