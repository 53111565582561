<mat-card appearance="outlined" class="mam-elevation-2" *ngIf="asset">
  <!-- Live asset content preview-->
  <mam-live-asset-content-preview
      *ngIf="asset.isLive; else vodCard"
      [asset]="asset"
      [manifestInfo]="liveManifestInfo"
      (thumbnailLoad)="onThumbnailLoaded($event)">
  </mam-live-asset-content-preview>

  <!-- Vod asset content preview-->
  <ng-template #vodCard>
    <mam-seekable-preview
        [previewUrl]="(asset | bestSeekUrl | async)!"
        [start]="asset.startTime"
        [end]="asset.endTime"
        [asset]="asset"
        (thumbnailLoad)="onThumbnailLoaded($event)">
    </mam-seekable-preview>
  </ng-template>

  <!-- Asset title and secondary description. -->
  <div class="description">
    <div class="title" [attr.title]="asset.title">{{ asset.title }}</div>
    <div class="time-info">

      <!-- Vod asset secondary description -->
      <ng-container *ngIf="!asset.isLive; else liveTimeInfo">
        {{ asset.eventTime | date }}
      </ng-container>

      <!-- Live asset secondary information -->
      <ng-template #liveTimeInfo>
        {{ asset.eventStartTime | tzdate : 'HH:mm' }}
        -
        {{ asset.eventEndTime| tzdate : 'HH:mm' }}
        <ng-container *ngIf="liveManifestInfo">
          <ng-container *ngIf="asset.state === AssetState.AIRING">
            &bull; Started {{ timePassedSinceLive$ | async | timeAgo }}
          </ng-container>
          <ng-container
              *ngIf="asset.state === AssetState.ENDED || asset.state === AssetState.PROCESSING">
            &bull; Ended {{ timePassedSinceLiveEnded$ | async | timeAgo}}
          </ng-container>
        </ng-container>
      </ng-template>
    </div>

    <!-- Status tag shown on the right. -->
    <div class="status-container">
      <ng-container
          *ngIf="!hasError; else displayError">
        <!-- Always show duration for live clips and VOD assets. -->
        <ng-container
            *ngIf="asset.original || !asset.isLive; else liveAssetStatus">
          <div class="status-with-optional-spinner">
            <!-- Show a spinner next to the duration for processing assets -->
            <mat-spinner
                *ngIf="asset.state === AssetState.PROCESSING"
                diameter="20"
                matTooltip="PROCESSING"></mat-spinner>
            <div class="status-label">{{asset.duration | duration}}</div>
          </div>
        </ng-container>

        <!-- Show label based on the live asset state. -->
        <ng-template #liveAssetStatus [ngSwitch]="asset.state">
          <div *ngSwitchCase="AssetState.PENDING" class="status-label">
            {{getStreamingStateLabelForOriginal()}}
          </div>
          <div
              *ngSwitchCase="AssetState.AIRING"
              class="status-label"
              [class.is-airing]="isTrulyLive()">
            {{getStreamingStateLabelForOriginal()}}
          </div>
          <ng-container *ngSwitchCase="AssetState.ENDED">
            <div class="status-label">
              {{liveManifestInfo?.durationInSeconds | duration}}
            </div>
          </ng-container>
          <div
              *ngSwitchCase="AssetState.PROCESSING"
              class="status-with-optional-spinner">
            <mat-spinner diameter="20" matTooltip="PROCESSING"></mat-spinner>
            <div class="status-label">
              {{liveManifestInfo?.durationInSeconds | duration}}
            </div>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #displayError>
        <div
            class="status-label"
            [class.has-error-message]="!!errorMessage"
            [matTooltip]="errorMessage"
            (click)="$event.preventDefault();$event.stopPropagation()">
          ERROR
        </div>
      </ng-template>
    </div>

    <!-- Label tag shown on the left. -->
    <div *ngIf="getTagLabel()" class="tag-container">
      <div class="tag-label">
        {{getTagLabel()}}
      </div>
    </div>
  </div>

  <!-- Asset actions -->
  <div class="actions">

    <!-- Internally visible button to copy asset info -->
    <mam-asset-info-icon-button [asset]="asset">
    </mam-asset-info-icon-button>

    <!-- Asset type icon (VoD, Live, or SLS) -->
    <mam-asset-type-icon [asset]="asset"></mam-asset-type-icon>

    <!-- Asset file status + Plugin functionality (for clips and live assets) -->
    <mam-asset-location-status [asset]="asset"></mam-asset-location-status>

    <!-- Extra actions (deletion, link sharing) -->
    <mam-asset-extra-actions
        [asset]="asset"
        [enableFullMenu]="true">
    </mam-asset-extra-actions>
  </div>
</mat-card>
