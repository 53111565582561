import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {FirebaseModule} from '../firebase/firebase_module';
import {PipesModule} from '../pipes/pipes_module';
import {PlayerModule} from '../player/player_module';
import {SharedModule} from '../shared/shared_module';

import {LiveAssetService} from './live_asset_service';
import {LiveLanding} from './live_landing';
import {LiveNavigationService} from './live_navigation_service';
import {LiveToolbarContent} from './live_toolbar_content';

@NgModule({
  imports: [
    CommonModule,
    FirebaseModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    PipesModule,
    PlayerModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    LiveLanding,
    LiveToolbarContent,
  ],
  exports: [
    LiveLanding,
    LiveToolbarContent,
  ],
  providers: [
    LiveAssetService,
    LiveNavigationService,
  ],
})
export class LiveModule {
}
