<button
    class="time-format-menu"
    mat-icon-button
    aria-label="time format menu"
    [matMenuTriggerFor]="formatMenu">
  <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #formatMenu="matMenu">
  <ng-template matMenuContent>
    <div class="mat-menu-title">TIME FORMATTING</div>
    <button
        mat-menu-item
        *ngFor="let format of formatOptions"
        [matTooltip]="getTooltip(format)"
        (click)="selectTimeFormat(format)">
      <mat-icon [style.opacity]="selectedFormat === format ? 1 : 0">
        done
      </mat-icon>
      <span>{{format}}</span>
    </button>
  </ng-template>
</mat-menu>
