<mat-form-field
  class="schema-selector"
  [class.metadata-field]="metadataFieldStyle"
  floatLabel="always"
  subscriptSizing="dynamic"
  appearance="outline">
  <mat-label *ngIf="showLabel">Schema</mat-label>
  <mat-select
      [formControl]="control"
      mam-firebase-ga="Open schema selection panel"
      [mam-firebase-resource]="resourceKey"
      disableOptionCentering
      [compareWith]="compareSchemas"
      [placeholder]="placeholder"
      panelClass="select-schema-panel-mat-select"
      >
    <!-- Selected schema -->
    <mat-select-trigger>
      {{ formatSchemaTitle(control.value) }}
    </mat-select-trigger>

    <!-- List of schemas -->
    <div class="scrollable-options">
      <mat-option
          class="metadata-item"
          *ngFor="let schema of schemas"
          mam-firebase-ga="Select schema panel"
          [mam-firebase-resource]="schema.name"
          [attr.title]="formatSchemaName(schema)"
          [value]="schema">
        {{ formatSchemaTitle(schema) }}
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
