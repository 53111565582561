import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MediaCacheService } from '../services/media_cache_service';


/** Change region dialog */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- FIXME
@Component({
    selector: 'mam-change-region',
    templateUrl: './change_region.ng.html',
    styleUrl: './change_region.scss'
})

export class ChangeRegionComponent {
    static readonly dialogOptions = { hasBackdrop: true };

    readonly selectableSites$ = this.mediaCache.state.selectableSites$;

    constructor(
        readonly mediaCache: MediaCacheService,
        readonly dialogRef: MatDialogRef<ChangeRegionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, name: string }) {
    }

    /** close the change region dialog when  user click on close button */
    closeDialog() {
        this.dialogRef.close();
    }
}


