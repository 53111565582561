<!-- Mute / Unmute -->
<button
  mat-icon-button
  class="gmat-button mute-toggle"
  [attr.aria-label]="volume === 0 ? 'Unmute video' : 'Mute video'"
  [mam-firebase-ga]="volume === 0 ? 'Mute video' : 'Unmute video'"
  (click)="toggleMuteUnmute()"
  (mouseover)="toggleVolumeSlider(false)"
  (mouseout)="toggleVolumeSlider(true)">
  <mat-icon>{{volume === 0 ? 'volume_off' : 'volume_up'}}</mat-icon>
</button>

<!-- Volume Slider -->
<mat-slider
  class="volume-slider"
  min="0"
  max="1"
  step=".05"
  [class.mat-mdc-slider-active]="!volumeSliderHidden"
  (mouseover)="toggleVolumeSlider(false)"
  (mouseout)="toggleVolumeSlider(true)" [disableRipple]="true" #ngSlider><input matSliderThumb [value]="volume"(change)="changeVideoVolume({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value!, true)" #ngSliderThumb="matSliderThumb" (input)="slidingVideoVolume({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value!)" />
</mat-slider>
