import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mam-admin-metrics-list',
  templateUrl: './admin_metrics_list.ng.html',
  styleUrls: ['./admin_metrics_list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminMetricsList {
}
