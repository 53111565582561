import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Inject, InjectionToken, Input, OnDestroy, Output} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil, takeWhile} from 'rxjs/operators';

import {assertTruthy, castExists, checkExhaustive} from 'asserts/asserts';

import {ErrorResponse, isErrorResponse} from '../error_service/error_response';
import {StatusCode} from '../error_service/status_code';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {FirebasePerformanceService, Trace, TraceName} from '../firebase/firebase_performance_service';
import {MetadataField} from '../services/asset_api_service';
import {Asset, AssetState} from '../services/asset_service';
import {LiveAssetManifestInfo, ManifestService} from '../services/manifest.service';
import {UtilsService} from '../services/utils_service';

/** The interval to refresh preview labels, e.g. "Ended 1 minute ago" */
const TIME_PASSED_UPDATE_INTERVAL_MS = 10_000;

/**
 * The interval at which we request manifest file until it is located or the
 * request errors out.
 */
const MANIFEST_POLLING_INTERVAL_MS = 10_000;

/**
 * States of live asset that indicate that the live stream manifest is present
 * in GCS.
 */
const LIVE_ASSET_STATES_WITH_MANIFEST = new Set([
  AssetState.AIRING,
  AssetState.ENDED,
  AssetState.PROCESSING,
]);

/** Tag types that can be displayed for asset preview. */
export enum AssetPreviewTag {
  CAMERA_LABEL = 'CAMERA_LABEL',
  CAMERA_COUNT = 'CAMERA_COUNT'
}

/** Injection token for `Date.now()`. */
export const DATE_NOW = new InjectionToken<() => number>(
    'Returns the number of milliseconds elapsed since the UNIX epoch',
    {factory: () => () => Date.now()});

/**
 * Preview a VOD or live asset with seek-on-hover in a card.
 */
@Component({
  selector: 'mam-asset-preview',
  templateUrl: './asset_preview.ng.html',
  styleUrls: ['./asset_preview.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetPreview implements OnDestroy {
  @Input()
  set asset(value: Asset) {
    const nameChanged = value.name !== this.assetInternal?.name;
    const statusChanged = this.assetInternal?.state !== value.state;
    this.assetInternal = value;

    if (nameChanged || statusChanged) {
      this.assetStateChanged$.next();
    }
  }
  get asset() {
    return castExists(this.assetInternal);
  }

  /** Additional tag to display if available. */
  @Input() tag?: AssetPreviewTag;

  @Output() readonly thumbnailLoad = new EventEmitter();

  /** Preview is disabled for live original assets that don't have manifest. */
  @HostBinding('class.preview-disabled')
  get isPreviewDisabled() {
    return this.asset.isLive && !this.asset.original && !this.liveManifestInfo;
  }

  /**
   * Original asset has no rendition for playback or preview, for instance as
   * they are generated during its PROCESSING state, or in case of errors.
   */
  @HostBinding('class.no-rendition')
  get hasNoRendition() {
    return !this.asset.renditions.length;
  }

  /** Original asset is deleted. */
  @HostBinding('class.deleted')
  get isDeleted() {
    return !this.asset.original && this.asset.isDeleted;
  }

  readonly AssetState = AssetState;

  /**
   * Disabled the export option when the live clip is not from the same site as
   * the current site or no selected folder.
   */
  isExportDisabled$: Observable<boolean> = of(true);

  disabledExportTooltip = 'The live asset is not in your current site.';

  liveManifestInfo?: LiveAssetManifestInfo;

  /**
   * Indicates that there is some error with the asset but we can still show
   * its preview. Example: asset.hasError
   *
   * When true, the status label will show `ERROR`.
   */
  hasError = false;

  errorMessage = '';

  private readonly assetStateChanged$ = new ReplaySubject<void>(1);

  /** Actual start time and duration of a live asset. */
  private readonly liveManifestInfo$:
      Observable<LiveAssetManifestInfo|ErrorResponse|undefined>;

  private readonly liveManifestUpdate$: Observable<LiveAssetManifestInfo>;

  /** Periodically updated time passed since live asset stream start. */
  readonly timePassedSinceLive$: Observable<number>;

  /** Periodically updated time passed since live asset stream end. */
  readonly timePassedSinceLiveEnded$: Observable<number>;

  constructor(
      private readonly featureFlag: FeatureFlagService,
      private readonly performanceService: FirebasePerformanceService,
      private readonly manifestService: ManifestService,
      private readonly utilsService: UtilsService,
      cdr: ChangeDetectorRef,
      @Inject(DATE_NOW) private readonly dateNow: () => number,
  ) {
    this.performanceTrace =
        this.performanceService.startTrace(TraceName.ASSET_THUMBNAIL_LOADED);

    this.liveManifestInfo$ = this.monitorLiveManifest();

    // Monitor live manifest information. Raise the error flag if the live
    // manifest information cannot be retrieved when it should be available
    // (airing/ended live assets).
    this.liveManifestInfo$.pipe(takeUntil(this.destroyed$))
        .subscribe(liveManifestInfo => {
          cdr.markForCheck();
          this.hasError = false;
          this.errorMessage = '';
          this.liveManifestInfo = undefined;

          if (isErrorResponse(liveManifestInfo)) {
            // Processing assets may be VoD with no renditions yet, so manifest
            // errors are expected and they will appear with a spinner icon.
            const original = this.asset.original || this.asset;
            if (!original.hasError &&
                original.state === AssetState.PROCESSING) {
              return;
            }

            // Manifest error (e.g not parsable manifest) is considered to be a
            // fatal error.
            this.hasError = true;
            this.errorMessage = liveManifestInfo.message;
            return;
          }

          this.liveManifestInfo = liveManifestInfo;

          if (!this.asset.original && this.asset.hasError) {
            this.hasError = true;
            this.errorMessage = this.asset.errorReason ?? '';
          }
        });

    this.liveManifestUpdate$ = this.getLiveAssetManifestUpdate();
    this.timePassedSinceLive$ = this.liveManifestUpdate$.pipe(
        map(({start}) => {
          const adjustedStartTime = this.adjustManifestStartTime(start.getTime());
          return this.dateNow() - adjustedStartTime;
        }));
    this.timePassedSinceLiveEnded$ =
        this.liveManifestUpdate$.pipe(map(({start, durationInSeconds}) => {
          const adjustedStartTime = this.adjustManifestStartTime(start.getTime());
          return this.dateNow() - adjustedStartTime - durationInSeconds * 1000;
        }));
  }

  /**
   * If ff is off - return original value from manifest;
   * If asset isn't of 'CLT' site - return original value from manifest;
   * If there is no event start time in asset's metadata - return original value from manifest;
   * If difference between two start times (from metadata and manifest) is less than 30 mins -
   * return original value from manifest;
   * Otherwise return start time from the asset's metadata.
   */
  adjustManifestStartTime(manifestStartTime: number): number {
    if (this.featureFlag.featureOff('adjust-clt-assets-manifest-start-time')) {
      return manifestStartTime;
    }

    const site = this.asset.assetMetadata.jsonMetadata[MetadataField.SITE];
    if (site !== 'CLT') {
      return manifestStartTime;
    }

    if (!this.asset.eventStartTime) {
      return manifestStartTime;
    }

    const diff = Math.abs(manifestStartTime - this.asset.eventStartTime);
    if (diff < 30 * 60 * 1000) {
      return manifestStartTime;
    }

    return this.asset.eventStartTime;
  }

  onThumbnailLoaded(success: boolean) {
    this.performanceTrace?.stop({
      attributes: {
        status: success ? 'success' : 'failure',
        isLive: this.asset.isLive,
      },
    });
    this.thumbnailLoad.emit();
  }

  /** Provides value for the asset preview tag displayed on the left. */
  getTagLabel() {
    if (!this.tag || this.featureFlag.featureOff('use-multi-camera-view')) {
      return undefined;
    }

    switch (this.tag) {
      case AssetPreviewTag.CAMERA_LABEL:
        return this.asset.camera?.isBroadcast ? 'primary' :
                                                this.asset.camera?.label;
      case AssetPreviewTag.CAMERA_COUNT: {
        const cameraCount = this.asset.camera?.totalCount ?? 1;
        return cameraCount > 1 ? `cameras: ${cameraCount}` : '';
      }
      default:
        checkExhaustive(this.tag);
    }
  }

  getStreamingStateLabelForOriginal(): string {
    assertTruthy(
        this.asset.isLive && !this.asset.original,
        'Streaming state should not be shown for clips or VoDs.');

    if (this.asset.streamingState === 'STREAMING_STATE_UNSPECIFIED') {
      return 'UNKNOWN';
    }

    if (this.isTrulyLive()) return 'LIVE';

    return this.asset.streamingState.replace(/_/g, ' ');
  }

  /**
   * Live original asset that has a dynamic live manifest and streamingState
   * STREAMING is considered truly live.
   */
  isTrulyLive() {
    // Never consider VoDs or clips as airing.
    if (!this.asset.isLive || this.asset.original) return false;

    return this.liveManifestInfo?.dynamic &&
        this.asset.streamingState === 'STREAMING';
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private readonly performanceTrace?: Trace;
  private assetInternal?: Asset;
  private readonly destroyed$ = new ReplaySubject<void>(1);

  private monitorLiveManifest():
      Observable<LiveAssetManifestInfo|ErrorResponse|undefined> {
    return this.assetStateChanged$.pipe(
        switchMap(() => {
          if (!LIVE_ASSET_STATES_WITH_MANIFEST.has(this.asset.state)) {
            // If the asset is not live or is not airing/ended the live manifest
            // doesn't exist and is not expected.
            return of(undefined);
          }

          // Start polling for the manifest (10 second interval).
          // Polling stops when:
          // - Manifest info is fetched.
          // - Manifest request errors out with any status other than 404.
          // - Manifest request errors out with 404 for ended or processing live
          //   asset.
          return this.utilsService.timer(MANIFEST_POLLING_INTERVAL_MS)
              .pipe(
                  switchMap(() => {
                    // Do not retry 404.
                    const excludedStatusCodes = [StatusCode.NOT_FOUND];
                    return this.manifestService.getLiveAssetTimeline(
                        this.asset, {excludedStatusCodes});
                  }),
                  map(response => {
                    if (isErrorResponse(response) &&
                        response.status === StatusCode.NOT_FOUND &&
                        this.asset.state === AssetState.AIRING) {
                      // When the asset is in airing state do not treat 404 as
                      // an error, instead keep polling.
                      return undefined;
                    }
                    return response;
                  }),
                  // Stop polling once we get an error or manifest info.
                  takeWhile(response => !response, /* inclusive */ true),
                  distinctUntilChanged(),
              );
        }),
        shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  private getLiveAssetManifestUpdate() {
    return this.liveManifestInfo$.pipe(
        filter(
            (manifest): manifest is LiveAssetManifestInfo =>
                !!manifest && !isErrorResponse(manifest)),
        switchMap(
            manifest => this.utilsService.timer(TIME_PASSED_UPDATE_INTERVAL_MS)
                            .pipe(map(() => manifest))));
  }
}
