import {ChangeDetectionStrategy, Component} from '@angular/core';

import {StagingService} from './staging_service';

/**
 * Persistent panel tab that displays staging assets and cutdown metadata.
 */
@Component({
  selector: 'mam-staging-panel',
  templateUrl: './staging_panel.ng.html',
  styleUrls: ['./staging_panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StagingPanel {
  constructor(readonly stagingService: StagingService) {}
}
