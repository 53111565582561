/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Approval State of the asset.
 */
export interface ApiApprovalState { 
    /**
     * Approval State of the asset.
     */
    state?: ApiApprovalState.StateEnum;
}
export namespace ApiApprovalState {
    export type StateEnum = 'STATE_UNSPECIFIED' | 'DRAFT' | 'SUBMITTED' | 'APPROVED';
    export const StateEnum = {
        STATE_UNSPECIFIED: 'STATE_UNSPECIFIED' as StateEnum,
        DRAFT: 'DRAFT' as StateEnum,
        SUBMITTED: 'SUBMITTED' as StateEnum,
        APPROVED: 'APPROVED' as StateEnum
    };
}


