/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiSiteConfigEntry } from './api-site-config-entry';


/**
 * A MediaCache site
 */
export interface ApiSite { 
    /**
     * Cloud bucket name to which all uploads from this site will be pushed.
     */
    cloudbucket?: string;
    /**
     * E.g.: {\"GoogleDirectConnectSpeedGbps\", \"10\" }
     */
    configs?: Array<ApiSiteConfigEntry>;
    /**
     * The timestamp that this site was created
     */
    createTime?: string;
    /**
     * Name of the site: projects/{project}/locations/{location}/sites/{site}
     */
    name?: string;
    /**
     * A short prefix version of the name Used to mark site specific resources like \"las\" for losangeles-studio and attached to assets uploaded from it
     */
    shortprefix?: string;
    /**
     * The user generated site id.
     */
    siteId?: string;
    /**
     * Type of the site.
     */
    siteType?: ApiSite.SiteTypeEnum;
}
export namespace ApiSite {
    export type SiteTypeEnum = 'SITE_TYPE_UNSPECIFIED' | 'SITE_TYPE_DATACENTER' | 'SITE_TYPE_CLOUD_GCP';
    export const SiteTypeEnum = {
        SITE_TYPE_UNSPECIFIED: 'SITE_TYPE_UNSPECIFIED' as SiteTypeEnum,
        SITE_TYPE_DATACENTER: 'SITE_TYPE_DATACENTER' as SiteTypeEnum,
        SITE_TYPE_CLOUD_GCP: 'SITE_TYPE_CLOUD_GCP' as SiteTypeEnum
    };
}


