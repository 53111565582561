
import {Injectable} from '@angular/core';

import {environment} from '../environments/environment';
import {AppAuthAction, Environment, RestrictedAuthTokens} from '../plugin/plugin_types';

import {AuthService} from './auth_service';

/** Initiate OAuth needs user input and may take long, timeout is 10min. */
const INITIATE_AUTH_TIMEOUT_MS = 600_000;
/** Auth retrieval should be immediate. Timeout is 1s. */
const RETRIEVE_AUTH_TIMEOUT_MS = 1000;
/**
 * Auth refresh includes 1 API call to OAuth and should be fast. Timeout is 4s.
 */
const REFRESH_AUTH_TIMEOUT_MS = 4000;

/**
 * AuthService implementation when MAM UI is used inside an iframe from the
 * Premiere plugin. It follows the desktop application OAuth flow which does not
 * have available helpers in google3.
 */
@Injectable({providedIn: 'root'})
export class AuthPluginService extends AuthService {
  isPlugin() {
    return true;
  }

  /**
   * Sends a message to the plugin host to have it initiate auth through the
   * opening of an external auth window. The response will be send back to the
   * UI through an iframe message.
   */
  async initiateAuth() {
    // Don't catch errors for the login page to show a snackbar.
    const {payload} = await this.pluginService.fetch(
        {iasType: AppAuthAction.INITIATE_AUTH, payload: this.getEnv()},
        INITIATE_AUTH_TIMEOUT_MS);
    this.setTokens(payload.tokens);
    if (payload.tokens?.access_token) {
      await this.loadUserProfile(payload.tokens.access_token);
    }
  }

  getAccessToken(): string {
    return this.tokens?.access_token || '';
  }

  protected async isLoggedInInternal(): Promise<boolean> {
    // Check for existing tokens in memory or retrieved from the plugin. Do not
    // attempt to refresh auth tokens yet as if there is an error from the
    // plugin to refresh tokens, we will not be able to complete logging out
    // which checks whether we are logged in.
    await this.loadTokens();
    return Boolean(this.getAccessToken());
  }

  protected async refreshAuthIfTokenExpired(minTimeRemaining: number) {
    try {
      await this.loadTokens();

      // If the current tokens have expired, refresh them through a call to the
      // plugin auth server.
      const expireAtMs = this.tokens?.expires_at;
      if (expireAtMs != null && expireAtMs - Date.now() < minTimeRemaining) {
        const {payload} = await this.pluginService.fetch(
            {iasType: AppAuthAction.REFRESH_AUTH, payload: this.getEnv()},
            REFRESH_AUTH_TIMEOUT_MS);
        this.setTokens(payload.tokens);
      }
    } catch (error) {
      let errorMessage = String(error);
      if (error && typeof error === 'object') {
        errorMessage = (error as Error).message || JSON.stringify(error);
      }
      this.errorService.handle(`Error refreshing auth token: ${errorMessage}`);
      await this.logout();
    }
  }

  protected override clearAuthStorage() {
    super.clearAuthStorage();
    this.pluginService.dispatch({iasType: AppAuthAction.CLEAR_AUTH});
    this.setTokens(undefined);
  }

  /** Current auth tokens in memory. */
  private tokens?: RestrictedAuthTokens;

  /**
   * Only emits env properties required by the plugin to avoid potential
   * logging or transport of secrets.
   */
  private getEnv(): Environment {
    return {
      projectId: environment.projectId,
      scope: environment.scope,
    };
  }

  private setTokens(tokens?: RestrictedAuthTokens) {
    if (tokens) {
      // Verify if "expires_at" has been properly set by the plugin, because it
      // is not available by default from OAuth itself, and instead is derived
      // from "expires_in".
      if (!tokens.expires_at) {
        this.errorService.handle('Missing "expires_at" token property');
      }
      // Refresh tokens are not used by MAM UI and should not be sent by the
      // plugin.
      if ('refresh_token' in tokens) {
        this.errorService.handle('Unexpected refresh_token passed to MAM UI.');
      }
    }
    this.tokens = tokens;
  }

  /**
   * If we don't have tokens in memory yet, fetches the plugin state for the
   * latest ones stored if any, so that we can sign-in the user without
   * having to go through the auth page.
   */
  private async loadTokens() {
    if (!this.getAccessToken()) {
      const {payload} = await this.pluginService.fetch(
          {iasType: AppAuthAction.RETRIEVE_AUTH, payload: this.getEnv()},
          RETRIEVE_AUTH_TIMEOUT_MS);
      this.setTokens(payload.tokens);
    }
  }
}
