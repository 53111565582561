import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

/**
 * Used for generic approval dialogs, such as staged asset approval.
 */
@Component({
  selector: 'mam-confirm-dialog',
  templateUrl: './confirm_dialog.ng.html',
  styleUrls: ['./confirm_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialog {
  constructor(
      readonly dialogRef: MatDialogRef<ConfirmDialog>,
      @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {}

  getOutput() {
    return this.data.extraChoice ? {extraChoice: this.data.extraChoice[1]} : {};
  }
}

/** Data for Asset Approval Dialog. */
export interface ConfirmDialogData {
  /** Confirmation dialog header. */
  title: string;
  /** Confirmation dialog question. Preserves line breaks. */
  question: string;
  /** Text of the primary button. Defaults to `Confirm` */
  primaryButtonText?: string;
  /**
   * Optional extra question shown with a checkbox. The second value is the
   * default checkbox state.
   */
  extraChoice?: [string, boolean];
  /**
   * Resource used to log in the analytics event when clicking primary or
   * cancel buttons.
   */
  firebaseResource?: string;
  /** Whether to hide cancel button. Defaults to `false`. */
  hideCancelButton?: boolean;
}
