<ng-container *ngIf="{
  isAdditionalControlsShown: playerDetailsService.isAdditionalPlayerControlsShown$ | async,
} as vars">

<div class="move-playhead" [class.hidden]="!vars.isAdditionalControlsShown">
  <button mat-icon-button class="skip-back" (click)="movePlayheadSeconds(-10)">
    <mat-icon>replay_10</mat-icon>
  </button>
  <button 
    mat-icon-button
    class="playback-toggle-fullscreen"
    [attr.aria-label]="isPaused ? 'Play video' : 'Pause video'"
    mam-firebase-ga="Toggle play/pause button"
    (click)="togglePlayPause()">
    <mat-icon>{{isPaused ? 'play_arrow' : 'pause'}}</mat-icon>
  </button>
  <button mat-icon-button
    class="skip-forward" (click)="movePlayheadSeconds(10)">
    <mat-icon>forward_10</mat-icon>
  </button>
</div>
</ng-container>