import {ApiAssetUserRenditions, ApiAssetUserRenditionsRendition, ApiSharedLink} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

import {AssetRendition} from './asset-rendition';
import {AssetStateDetail} from './asset-state-detail';
import {Snippet} from './snippet';

/** UI model for ApiSharedLink */
export class SharedLink implements RequiredRecursively<ApiSharedLink> {
  additionalProperties = this.apiSharedLink.additionalProperties ?? {};
  asset = this.apiSharedLink.asset ?? '';
  clip = this.apiSharedLink.asset ?? '';
  downloadable = this.apiSharedLink.downloadable ?? false;
  downloadableSize = this.apiSharedLink.downloadableSize ?? '';
  endOffset = this.apiSharedLink.endOffset ?? '';
  expireTime = this.apiSharedLink.expireTime ?? '';
  name = this.apiSharedLink.name ?? '';
  original = this.apiSharedLink.original ?? '';
  startOffset = this.apiSharedLink.startOffset ?? '';
  streamingState =
      this.apiSharedLink.streamingState ?? 'STREAMING_STATE_UNSPECIFIED';
  title = this.apiSharedLink.title ?? '';
  ttl = this.apiSharedLink.ttl ?? '';
  renditions =
      (this.apiSharedLink.renditions ?? []).map(r => new AssetRendition(r));
  snippet = new Snippet(this.apiSharedLink.snippet ?? {});
  stateDetail = new AssetStateDetail(this.apiSharedLink.stateDetail ?? {});
  userRenditions =
      convertToUiAssetUserRenditions(this.apiSharedLink.userRenditions ?? {});
  type : string = '';
  url : string = '' ;
  constructor(private apiSharedLink: ApiSharedLink = {}) {}
}

function convertToUiAssetUserRenditions(renditions: ApiAssetUserRenditions):
    RequiredRecursively<ApiAssetUserRenditions> {
  const renditionMap:
      Record<string, RequiredRecursively<ApiAssetUserRenditionsRendition>> = {};
  for (const [key, value] of Object.entries(renditions.renditionMap ?? {})) {
    renditionMap[key] = {
      url: value.url ?? '',
    };
  }
  return {renditionMap};
}
