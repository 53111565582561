import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { forkJoin } from 'rxjs';

import {BinService} from '../../services/bin.service';
import {SnackBarService} from '../../services/snackbar_service';

/** Clipbin deletion dialog */
@Component({
  selector: 'mam-delete-multiple-bin-dialog',
  templateUrl: './delete_multiple_bin_dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteMultipleBinDialog implements OnInit {
  static readonly dialogOptions = {
    hasBackdrop: true,
  };

  constructor(
      readonly dialogRef: MatDialogRef<DeleteMultipleBinDialog>,
      private readonly snackBar: SnackBarService,
      private readonly binService: BinService,
      @Inject(MAT_DIALOG_DATA) public data: {bins: Set<string>, onOpen: CallableFunction, onConfirm: CallableFunction, onCancel: CallableFunction }) {}

  ngOnInit() {
    this.dialogRef.afterOpened().subscribe(() => this.open());
    this.dialogRef.afterClosed().subscribe((deletionConfirmed) => {
      if (!deletionConfirmed) {
        this.cancel();
        return;
      }
      const deletionObs = Array.from(this.data.bins.values()).map(name => this.binService.delete(name));
      forkJoin(deletionObs)
      .subscribe({
        next: () => {
          this.snackBar.message(`${this.data.bins.size} clip bins have been deleted successfully.`);
          this.confirm();
        },
        error: (error) => {
          this.snackBar.error('Clip bins could not be deleted.', undefined, error);
        }
      });
    });
  }

  open() {
    if (this.data.onOpen) this.data.onOpen();
  }

  confirm() {
    if (this.data.onConfirm) this.data.onConfirm();
  }

  cancel() {
    if(this.data.onCancel) this.data.onCancel();
  }
}
