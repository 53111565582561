import {Injectable} from '@angular/core';
import {map, Observable, throwError} from 'rxjs';

import {AssetsAnnotatedSegmentsListRequestParams} from 'api/ias/api/projects.service';
import {ApiAnnotatedSegment} from 'api/ias/model/models';
import {AnnotatedSegment, Metadata} from 'models';

import {IasApiClient} from './api_client.module';

/** Possible ways to bound the segments loaded by time or offsets. */
export interface ListFilter {
  minimumEndOffset?: number;
  maximumStartOffset?: number;
  minimumEndTime?: string;
  maximumStartTime?: string;
}

/**
 * AnnotatedSegmentsApiService to interact with AnnotatedSegments for an asset
 * related APIs
 */
@Injectable({providedIn: 'root'})
export class AnnotatedSegmentsApiService {
  constructor(
      private readonly apiClient: IasApiClient,
  ) {}

  /**
   * Fetches a page of annotated segments.
   * 
   * @param assetName source asset for the segments
   * @param pageSize how many segments to load
   * @param pageToken optional token to fetch a different page
   * @param filters restrict segments to those matching these rules:
   *  - minimumEndOffset/minimumEndTime: exclude segments that end before
   *  - maximumStartOffset/maximumStartTime: exclude segments that start after
   */
  list(
      assetName: string,
      pageSize: number,
      pageToken?: string,
      filters?: ListFilter,
  ) {
    const params: AssetsAnnotatedSegmentsListRequestParams = {
      parent: assetName,
      pageToken,
      pageSize,
    };

    // Add offset filter if any.
    let filter = '';
    if (filters?.minimumEndOffset != null) {
      filter += ` endOffset>${filters?.minimumEndOffset}s`;
    }
    if (filters?.maximumStartOffset != null) {
      filter += ` startOffset<=${filters?.maximumStartOffset}s`;
    }
    if (filters?.minimumEndTime != null) {
      filter += ` endTime>${filters?.minimumEndTime}`;
    }
    if (filters?.maximumStartTime != null) {
      filter += ` startTime<=${filters?.maximumStartTime}`;
    }
    if (filter) {
      params.filter = filter;
    }

    try {
      return this.apiClient.assetsAnnotatedSegmentsList(params).pipe(
          map(resp => ({
                annotatedSegments: (resp.annotatedSegments ??
                                    []).map(s => new AnnotatedSegment(s)),
                nextPageToken: resp.nextPageToken,
              })));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  create(assetName: string, segment: ApiAnnotatedSegment) {
    try {
      return this.apiClient
          .assetsAnnotatedSegmentsCreate({
            parent: assetName,
            body: segment,
          })
          .pipe(map(s => new AnnotatedSegment(s)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  delete(name: string): Observable<unknown> {
    try {
      return this.apiClient.assetsAnnotatedSegmentsDelete({name});
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  updateMetadata(name: string, segmentMetadata: Metadata) {
    try {
      return this.apiClient
          .assetsAnnotatedSegmentsPatch({
            name,
            body: {segmentMetadata: segmentMetadata},
            updateMask: 'segmentMetadata',
          })
          .pipe(map(s => new AnnotatedSegment(s)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }
}
