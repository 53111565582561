<h1 mat-dialog-title>On-Prem Path</h1>
<div mat-dialog-content>
  <div *ngIf="localPath$ | async as localPath; else noLocalPath">
    <label for="windows">Windows</label>
    <div class="path-field" id="windows">{{localPath.windows}}</div>

    <label for="unix">Unix</label>
    <div class="path-field" id="unix">{{localPath.unix}}</div>
  </div>

  <ng-template #noLocalPath>
    Failed to locate file on-prem.
  </ng-template>
</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Close on-prem path dialog"
      [mam-firebase-resource]="data.asset.name">
    Close
  </button>
</div>
