import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter,Input, NgZone, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChip, MatChipGrid } from '@angular/material/chips';
import {animationFrameScheduler, fromEvent, merge, Observable,ReplaySubject, Subject} from 'rxjs';
import {auditTime, filter, take, takeUntil} from 'rxjs/operators';

import {assertExists, assertTruthy} from 'asserts/asserts';

import {DeviceInputService} from '../services/device_input_service';
import {ResizeObserverService} from '../services/resize_observer_service';
import {SearchMode, SearchType} from '../services/search_input_service';

/** Suggestion offered by the autocomplete list. */
export interface Suggestion {
  /** Display text of the suggestion */
  text: string;
  /** Whether this suggestion is a label such as `content-type:` */
  searchOperatorSuggestion?: boolean;
  /** Whether this suggestion comes from local history. */
  isFromHistory?: boolean;
}

export enum BreakPoint {
  SMALL = '(max-width: 720px)',
}

/**
 * How many pixels are necessary for the placeholder "Search videos segments"
 * to be fully displayed.
 */
const PLACEHOLDER_WIDTH = 160;

/**
 * When the search bar (= host) width is below this value, it switches to a
 * compact mode which hides the clear icon and uses an icon instead of a
 * dropdown for the mode selector.
 */
const COMPACT_WIDTH_THRESHOLD = 460;

/** Search input with a list of suggestions below. */
@Component({
  selector: 'mam-search-input',
  templateUrl: './search_input.ng.html',
  styleUrls: ['./search_input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInput implements AfterViewInit, OnInit, OnDestroy {
  /** Get the search mode */
  @Input() searchMode?: SearchMode;

   /**
    * Observes changes to layout. Minimum large screen breakpoint is 780px and
    * set in variables.scss
    */
  layoutChanges?: Observable<BreakpointState>;

   /** True when layout switches to SMALL. */
   isSmallScreen = false;

   private readonly destroyed$ = new ReplaySubject<void>(1);

  /** Search type: live or VOD. */
  @Input() searchType?: SearchType;

  /** List of suggestions to be displayed under the search bar. */
  @Input()
  get suggestions(): Suggestion[]|null {
    return this.suggestionsInternal;
  }
  set suggestions(suggestions: Suggestion[]|null) {
    this.suggestionsInternal = suggestions || [];
    this.openPanelIfNeeded();
  }

  /** List of chips contained in the search bar. */
  @Input()
  get chips(): string[] {
    return this.chipsInternal || [];
  }
  set chips(chips: string[]) {
    const isInitialDataset = this.chips == null;
    this.chipsInternal = chips;

    // When a chip is added or removed, ensure that the panel is closed and
    // input has focus.
    if (!isInitialDataset) {
      // Suggestions reset guaranties panel willn't be openned on input focus.
      this.suggestions = [];
      this.updateLayout(true);
      this.trigger?.closePanel();
    }
  }

  /** Emits when click the search mode change button. */
  @Output() readonly searchModeChanged = new EventEmitter<SearchMode>();

  /** Emits when we press Enter from the input. */
  @Output() readonly search = new EventEmitter<string>();

  /** Emits when we click or press `Tab` on a suggestion. */
  @Output() readonly suggestionSelected = new EventEmitter<Suggestion>();

  /** Emits the index of the chip to remove. */
  @Output() readonly chipRemoved = new EventEmitter<number>();

  /** Emits the index and new text content of an existing chip. */
  @Output()
  readonly chipEdited = new EventEmitter<{index: number, content: string}>();

  /** Emits when the clear button is clicked. */
  @Output() readonly clear = new EventEmitter<void>();

  /** Emits when a history suggestion's remove is clicked. */
  @Output() readonly remove = new EventEmitter<Suggestion>();

  @ViewChild('input') readonly input!: ElementRef<HTMLInputElement>;

  @ViewChild(MatChipGrid, { read: ElementRef })
  readonly chipList!: ElementRef<HTMLElement>;

  @ViewChild('sizingHelper', {read: ElementRef})
  readonly sizingHelper!: ElementRef<HTMLElement>;

  @ViewChildren(MatChip, {read: ElementRef})
  chipsElements!: QueryList<ElementRef<HTMLElement>>;

  @ViewChild(MatAutocomplete) readonly auto!: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger) readonly trigger!: MatAutocompleteTrigger;

  readonly SearchType = SearchType;

  readonly SearchMode = SearchMode;

  /** Number of chips that are hidden from the search bar. */
  hiddenChipsCount = 0;

  suggestionType = {
    HISTORY: 0,
    LABEL: 1,
    QUERY: 2,
  };

  /** Filtered list of chips that are currently displayed in the search bar.  */
  visibleChips?: string[];

  /**
   * Whether search bar is in compact display mode. Defaults to false. When
   * compact, the clear icon is hidden and the mode selector becomes a simple
   * icon button.
   */
  compact = false;

  /**
   * Used for very small screens when there is not enough space for the
   * placeholder.
   */
  hiddenPlaceholder = false;

  /**
   * To avoid getting different order of searchModes by using the for loop of
   * SearchMode enum, uses an array to guarantee the order.
   */
  searchModeChoices = [SearchMode.SEGMENT, SearchMode.VIDEO];

  /**
   * Index in the `chips` array of the chip being currently edited (on
   * double-click), if any.
   */
  chipEditIndex?: number;

  /** Actual value of the input element. */
  get value() {
    return this.input?.nativeElement.value || '';
  }
  set value(text: string) {
    this.input.nativeElement.value = text || '';
  }

  constructor(
      private readonly cdr: ChangeDetectorRef,
      private readonly host: ElementRef<HTMLElement>,
      private readonly ngZone: NgZone,
      private readonly resizeObserver: ResizeObserverService,
      private readonly breakpointObserver: BreakpointObserver,
      private readonly deviceInputType: DeviceInputService,
  ) {}

  ngOnInit() {
    this.layoutChanges =
      this.breakpointObserver.observe(Object.values(BreakPoint));

    // General layout responsiveness
    this.layoutChanges.pipe(takeUntil(this.destroyed$)).subscribe((result) => {
      this.isSmallScreen = result.breakpoints[BreakPoint.SMALL];
      this.cdr.markForCheck();
    });
  }

  ngAfterViewInit() {
    this.registerKeydownListeners();
    // Update layout when the available space for chips changes. We observe the
    // matFormFieldInfix rather than `chipList` because when we open the panel,
    // if there are too many chips, a scrollbar will be added to `chipList`
    // which changes its width, and we do not want to execute this observer at
    // that moment. See https://critique-ng.corp.google.com/cl/378814674]
    const matFormFieldInfix =
      this.host.nativeElement.querySelector('.mat-mdc-form-field-infix');
    assertTruthy(matFormFieldInfix, 'matFormFieldInfix not found');

    this.resizeObserver.observe(matFormFieldInfix)
        .pipe(
            // `updateLayout` can cause a layout reflow, so we only run it
            // when the browser is ready to paint. We also cancel any previously
            // scheduled call and only run the most recent one.
            auditTime(0, animationFrameScheduler),
            takeUntil(this.destroyed),
            )
        .subscribe(domRect => {
          // Only consider events when the width of the chips list changed.
          const chipListWidth = domRect.width;
          if (this.previousChipListWidth === chipListWidth) return;
          this.previousChipListWidth = chipListWidth;

          this.updateLayout(false);
        });
  }

  isEmpty() {
    return !this.chips.length && !this.value;
  }

  focus() {
    this.input?.nativeElement.focus();
  }

  getPlaceholderText() {
    if (!this.searchType || !this.searchMode) {
      return '';
    } else if (this.searchType === SearchType.LIVE) {
      return 'Search live videos';
    } else if (this.searchMode === SearchMode.VIDEO) {
      return 'Search full videos';
    } else {
      return 'Search enhanced';
    }
  }

  formatSearchMode(mode: SearchMode) {
    return mode === SearchMode.SEGMENT ? 'Enhanced' : 'Videos';
  }

  changeSearchMode(mode: SearchMode) {
    this.searchModeChanged.emit(mode);
  }

  clearClicked() {
    this.clear.emit();
  }

  removeChip(index: number) {
    // Without this, Angular would warn "Navigation triggered outside Angular
    // zone". Is there an issue in MatChip?
    this.ngZone.run(() => {
      this.chipRemoved.emit(index);
    });
  }

  onOptionSelected(suggestion: Suggestion) {
    if (!suggestion) return;
    this.suggestionSelected.emit(suggestion);
  }

  onEnterPressed(event: KeyboardEvent) {
    event.stopImmediatePropagation();
    if (!this.auto.isOpen || !this.activeOption) {
      this.search.emit(this.value);
      this.value = '';
      return;
    }

    this.onOptionSelected(this.activeOption);
  }

  hasFocus() {
    return document.activeElement === this.input?.nativeElement;
  }

  /**
   * When double-clicking on a chip, we enter chip edition. This is done by
   * adding a clone chip on top of the chips list with a `contenteditable`
   * attribute. We do this with vanilla DOM manipulation instead of editing the
   * same chip in place or binding to one in the template due to conflicts with
   * the Material autocomplete and chips list components that would otherwise
   * not allow us to press the delete key.
   */
  openChipEditor(index: number) {
    this.chipEditIndex = index;

    // Create a clone of the chip that we want to edit, and position it
    // on top of the original chip.
    const chipToEdit = this.chipList.nativeElement.querySelectorAll(
      '.mat-mdc-chip:not(.special-chip)')[index];
    const chipToEditSpan = chipToEdit.querySelector('.mdc-evolution-chip__text-label');
    assertTruthy(chipToEditSpan, 'Edited chip does not have a <span> child.');
    const clone = chipToEdit.cloneNode(true) as HTMLElement;
    clone.classList.add('special-chip', 'chip-editing');
    const bcr = chipToEdit.getBoundingClientRect();
    Object.assign(clone.style, {
      position: 'fixed',
      top: `${bcr.top}px`,
      left: `${bcr.left}px`,
    });
    this.host.nativeElement.appendChild(clone);

    // Make the clone editable and pre-select its content.
    const contentEditable = clone.querySelector('.mdc-evolution-chip__text-label') as HTMLElement;
    assertExists(contentEditable);
    contentEditable.contentEditable = 'true';
    const range = document.createRange();
    range.selectNodeContents(contentEditable);
    const sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);

    // Mirror user input to the edited chip text. This bypasses Angular model
    // and only temporarily updates the html. Once the chip edit is confirmed,
    // the model `this.chips` will be updated and properly reflected in the DOM.
    const cloneDestroyed$ = new Subject<void>();
    fromEvent(contentEditable, 'input')
        .pipe(takeUntil(this.destroyed), takeUntil(cloneDestroyed$))
        .subscribe(() => {
          // If the text is empty, add a dot `.` character to
          // the edited chip (which is hidden) because an actually empty first
          // chip causes a layout shift.
          chipToEditSpan.textContent = contentEditable.textContent || '.';
        });

    // Whenever we focus away from the editing chip or press Enter, emit an
    // update event for this chip and close the editor. If the chip's content
    // was emptied, remove the chip instead.
    const onBlur = fromEvent(contentEditable, 'blur');
    const onPressEnter = fromEvent<KeyboardEvent>(contentEditable, 'keypress')
                             .pipe(filter(event => event.key === 'Enter'));
    merge(onBlur, onPressEnter)
        .pipe(take(1), takeUntil(this.destroyed))
        .subscribe(() => {
          cloneDestroyed$.next();
          cloneDestroyed$.complete();
          const content = contentEditable.textContent?.trim();
          clone.remove();
          this.chipEditIndex = undefined;
          if (!content) {
            this.removeChip(index);
          } else {
            this.chipEdited.emit({index, content});
          }
          this.cdr.detectChanges();
          this.focus();
        });
  }

  /**
   * Updates the list of hidden chips, the visibility of the placeholder, and
   * determine if we are in compact mode. Should we called when the size changes
   * or chips are updated.
   */
  updateLayout(chipsOnMultipleRows: boolean) {
    if (!this.chipList) return;

    this.compact =
        this.host.nativeElement.offsetWidth < COMPACT_WIDTH_THRESHOLD;

    // Start by displaying all chips and no placeholder to calculate which
    // ones must be hidden.
    this.hiddenPlaceholder = true;
    this.setChipsHiddenCount(0);
    this.cdr.detectChanges();

    // When suggestions are not displayed, we only render one row of chips,
    // potentially hiding extra ones in a "counter" chip.
    if (!chipsOnMultipleRows) {
      this.squeezeChipsInOneRow();
      this.cdr.detectChanges();
      // Display placeholder if it still can fit.
      this.hiddenPlaceholder = this.shouldPlaceholderBeHidden();
    }
    // When suggestions are displayed, force focus to the input (which we want
    // to do right after deleting a chip).
    else {
      this.hiddenPlaceholder = false;

      if(!this.deviceInputType.isInputViaTouch()){
        this.focus();
      }
    }

    this.cdr.detectChanges();
  }

  onInputBlur(e: FocusEvent) {
    // When input loses the focus, update layout to a single row and close
    // suggestion panel, unless we lost focus by clicking an internal element
    // such as a suggestion row or a chip.
    const blurInside =
      this.isInternalElement(e.relatedTarget as Element | null);
    if (!blurInside) {
      this.updateLayout(false);
      this.trigger.closePanel();
    }
  }

  getMinInputWidth() {
    return this.hiddenPlaceholder ? 0 : PLACEHOLDER_WIDTH;
  }

  /** Removes all parentheses. */
  hideParens(suggestion = '') {
    return suggestion.replace(/[()]/g, '');
  }

  getSuggestionText(suggestion: Suggestion|null) {
    return suggestion?.text || '';
  }

  trackSuggestion(index: number, suggestion: Suggestion) {
    return suggestion?.text || '';
  }

  getSuggestionType(suggestion: Suggestion) {
    if (suggestion.isFromHistory) return this.suggestionType.HISTORY;
    if (suggestion.searchOperatorSuggestion) return this.suggestionType.LABEL;
    return this.suggestionType.QUERY;
  }

  /** Tag words that match the prefix so they can be displayed bold. */
  getWordsStyles(text: string): Array<{matches: boolean, value: string}> {
    // TODO: Obtain bold/light information from suggest API.
    const prefixes = this.value.trim().toLowerCase().split(' ');
    return text.split(' ').map(value => {
      const matches = prefixes.some(
          prefix => prefix && value.toLowerCase().startsWith(prefix));
      return {matches, value};
    });
  }

  removeFromHistory(suggestion: Suggestion, event?: MouseEvent) {
    event?.stopPropagation();
    this.remove.emit(suggestion);
  }

  registerKeydownListeners() {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.target !== this.input.nativeElement) return;
      if (e.key === 'Enter') {
        this.onEnterPressed(e);
      }
    };
    // b/168329735: In order to capture Enter and Tab events before
    // MaterialAutocomplete does and override its behavior, we listen for the
    // capture phase on the document which happens before the event descend to
    // the input element.
    document.addEventListener('keydown', onKeydown, {capture: true});
    this.destroyed.subscribe(() => {
      document.removeEventListener('keydown', onKeydown);
    });
  }

  updateInputWithActiveOption() {
    if (!this.activeOption) return;
    this.value = this.hideParens(this.activeOption.text);
  }

  private suggestionsInternal: Suggestion[] = [];

  private chipsInternal: string[] = [];

  private previousChipListWidth = -1;

  private get activeOption(): Suggestion|undefined {
    return this.trigger?.activeOption?.value ?? undefined;
  }

  /**
   * Whether the given element is part of this component (also including the
   * autocomplete suggestions panel displayed below it).
   */
  private isInternalElement(element: Element|null) {
    let blurInside = false;
    while (element) {
      blurInside = element === this.host.nativeElement ||
          element === this.auto.panel?.nativeElement;
      if (blurInside) return true;
      element = element.parentElement;
    }

    return false;
  }

  private squeezeChipsInOneRow() {
    // Calculate index of the first chip that should be hidden.
    const firstHidden = this.getFirstHiddenChipIndex();
    if (this.isSmallScreen) {
      this.setChipsHiddenCount(this.chips.length);
    }
    else if (firstHidden === -1) return;
    else {
      this.setChipsHiddenCount(this.chips.length - firstHidden);
    }
  }

  /**
   * Hide a number of chips and display a counter chip instead, unless
   * `hiddenCount` is 0 in which case all chips are displayed.
   */
  private setChipsHiddenCount(hiddenCount: number) {
    this.hiddenChipsCount = hiddenCount;
    if (!hiddenCount) {
      this.visibleChips = this.chips;
    } else {
      const totalCount = this.chips.length;
      this.visibleChips = this.chips.filter((c, i) => {
        return i < totalCount - hiddenCount;
      });
    }
  }

  /**
   * Right after we receive new suggestions, this will ensure the suggestions
   * are displayed, unless in a situation when they should not.
   */
  private openPanelIfNeeded() {
    // Not if there are no suggestions to display.
    if (!this.suggestionsInternal.length) return;
    // Not if the input is empty.
    if (!this.value.length) return;
    // Not if the input is not currently focused.
    if (!this.hasFocus()) return;
    // Not if the panel is already opened.
    if (this.auto.isOpen) return;
    // Otherwise, open it.
    this.trigger.openPanel();
  }

  /**
   * Computes the index of the first chip in the list that should be hidden (or
   * -1 when none) so that we have enough space to render in one single line:
   * - the preceding chips
   * - an extra chip counting the number of hidden chips
   * - a minimum search input
   */
  private getFirstHiddenChipIndex() {
    const hostHeight = this.host.nativeElement.offsetHeight;
    const chipListWidth = this.chipList.nativeElement.offsetWidth;

    const realChipsElements = Array.from(this.chipsElements).filter(c => {
      return !c.nativeElement.classList.contains('special-chip');
    });

    const margin = this.sizingHelper.nativeElement.offsetLeft;
    const sizingHelperWidth = this.sizingHelper.nativeElement.offsetWidth;
    // 2 margins on each side of the counter chip + 1 margin for the input.
    const minimumSpaceAfterChips = sizingHelperWidth + margin * 3;

    // Index of the first chip that doesn't leave enough space for the input
    // if it was fully displayed on the first row.
    const firstOverflownChipIndex =
        realChipsElements.findIndex((matChip, i) => {
          const el = matChip.nativeElement;
          // Chip is not on the first row, it must be hidden.
          if (el.offsetTop > hostHeight / 2) return true;

          const spaceAfterChip =
              chipListWidth - (el.offsetLeft + el.offsetWidth);

          // Chip is the last one, fits the first row, and leaves enough space
          // for the input (8px): no need to hide it.
          if (i === realChipsElements.length - 1 && spaceAfterChip > 8) {
            return false;
          }

          // Chip's right edge does not leave enough space for the extra counter
          // chip, it must be hidden.
          if (spaceAfterChip < minimumSpaceAfterChips) {
            return true;
          }

          // Chip has enough space to be displayed, not need to hide it.
          return false;
        });

    // Return -1 if no chip needs to be hidden.
    if (firstOverflownChipIndex === -1) return -1;

    // Ensure that at least 1 chip is not hidden (it will be ellipsed).
    return Math.max(firstOverflownChipIndex, 1);
  }

  private shouldPlaceholderBeHidden() {
    // Check whether the placeholder can fit in the available
    // space after the last chip, otherwise hide it.
    const chipListWidth = this.chipList.nativeElement.offsetWidth;
    const chips = this.chipsElements.map(c => c.nativeElement);
    const lastChip = chips[chips.length - 1];
    const spaceAfterLastChip =
        chipListWidth - (lastChip.offsetLeft + lastChip.offsetWidth);
    const margin = this.sizingHelper.nativeElement.offsetLeft;

    return spaceAfterLastChip < PLACEHOLDER_WIDTH + margin * 2;
  }

  private readonly destroyed = new ReplaySubject<void>(1);

  ngOnDestroy() {
    // Unsubscribes all pending subscriptions.
    this.destroyed.next();
    this.destroyed.complete();
  }
}
