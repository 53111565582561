/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GoogleRpcStatus } from './google-rpc-status';


/**
 * StateDetail denotes the state of the Copy.
 */
export interface ApiCopyStateDetail { 
    errorDetails?: GoogleRpcStatus;
    /**
     * State is the current state of the copy process. It is not modifiable by the client.
     */
    state?: ApiCopyStateDetail.StateEnum;
}
export namespace ApiCopyStateDetail {
    export type StateEnum = 'STATE_UNSPECIFIED' | 'STATE_DRAFT' | 'STATE_QUEUED' | 'STATE_PFR_PROCESSING' | 'STATE_PFR_SUCCEEDED' | 'STATE_VOD_PROCESSING' | 'STATE_VOD_READY' | 'STATE_ERROR';
    export const StateEnum = {
        STATE_UNSPECIFIED: 'STATE_UNSPECIFIED' as StateEnum,
        STATE_DRAFT: 'STATE_DRAFT' as StateEnum,
        STATE_QUEUED: 'STATE_QUEUED' as StateEnum,
        STATE_PFR_PROCESSING: 'STATE_PFR_PROCESSING' as StateEnum,
        STATE_PFR_SUCCEEDED: 'STATE_PFR_SUCCEEDED' as StateEnum,
        STATE_VOD_PROCESSING: 'STATE_VOD_PROCESSING' as StateEnum,
        STATE_VOD_READY: 'STATE_VOD_READY' as StateEnum,
        STATE_ERROR: 'STATE_ERROR' as StateEnum
    };
}


