import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {TransferTask} from 'models';

import {UtilsService} from '../services/utils_service';

interface Field {
  name: string;
  value: string|number;
  type?: FieldType;
}

/** Describes how to format the field value. */
enum FieldType {
  BYTES,
  BYTES_PER_SECOND
}

/** Details panel displayed when a transfer task row is expanded. */
@Component({
  selector: 'mam-transfer-details',
  templateUrl: './transfer_details.ng.html',
  styleUrls: ['./transfer_details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferDetails {
  @Input()
  set task(task: TransferTask|undefined) {
    this.taskInternal = task;
    this.updateProperties(task);
  }
  get task(): TransferTask|undefined {
    return this.taskInternal;
  }

  @Input() hiddenFields: string[] = [];

  readonly FieldType = FieldType;

  fields: Field[] = [];

  constructor(private readonly utils: UtilsService) {}

  isFieldHidden(name: string) {
    return this.hiddenFields.includes(name);
  }

  private taskInternal?: TransferTask;

  private updateProperties(task?: TransferTask) {
    if (!task) return;

    const folder = task.folder;
    const file = task.files[0] || {};

    // Special formatting from
    // https://docs.google.com/presentation/d/1oqNCyIoO6hGSv0O3FOyvLAy-rH2C5MfTGM18nd4bB8E#slide=id.g10d10f26c1f_0_88
    this.fields = [
      {name: 'file name', value: this.utils.lastPart(file.name)},
      {name: 'file size', value: file.filesize, type: FieldType.BYTES},
      {name: 'folder type', value: folder.type},
      {name: 'transferAgentId', value: file.transferAgentId},
      {name: 'on-prem to cloud', value: file.onprem2cloud},
      {name: 'cloud to on-prem', value: file.cloud2onprem},
      {name: 'transfer mod time', value: task.transferModTime},
      {name: 'progress bytes', value: file.progressBytes},
      {name: 'error', value: file.error},
      {name: 'on-prem file path', value: file.filename},
      {name: 'google transfer id', value: task.name},
    ];

    if (task.transferState === 'TRANSFER_STATE_COMPLETED') {
      this.fields.push({
        name: 'transfer rate',
        // `transferRate` is given in MiB/s, convert it to bytes/s.
        value: task.transferRate * (1024 ** 2),
        type: FieldType.BYTES_PER_SECOND
      });
    }
  }
}
