<div *ngIf="asset">
  <button
      mat-icon-button
      aria-label="Media Cache Operations"
      [matMenuTriggerFor]="operationMenu">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #operationMenu="matMenu" class="onprem-operation-menu">
    <button
        mat-menu-item
        mam-firebase-ga="Open extend ttl dialog"
        [mam-firebase-resource]="asset.name"
        (click)="openOnPremPathDialog(asset)">
      <mat-icon class="material-icons-outlined">pin_drop</mat-icon>
      Show on-prem path
    </button>
    <button
        mat-menu-item
        class="extend-ttl-button"
        mam-firebase-ga="Open extend ttl dialog"
        [mam-firebase-resource]="asset.name"
        (click)="openExtendTtlDialog(asset)">
      <mat-icon>more_time</mat-icon>
      Extend on-prem file TTL
    </button>
    <button
        mat-menu-item
        class="delete-button"
        mam-firebase-ga="Open delete on-prem file dialog"
        [mam-firebase-resource]="asset.name"
        (click)="openDeleteOnpremFileDialog(asset)">
      <mat-icon class="material-icons-outlined">folder_delete</mat-icon>
      Delete on-prem file
    </button>
  </mat-menu>
</div>
