import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';

import {AuthService} from '../auth/auth_service';

/**
 * Directive used to show content only when running in the plugin.
 *
 * @example
 * ```html
 * <some-element *mamPluginModeOn></some-element>
 * ```
 */
@Directive({
  selector: '[mamPluginModeOn]',
})
export class PluginModeOn {
  constructor(
      viewContainerRef: ViewContainerRef,
      templateRef: TemplateRef<unknown>,
      authService: AuthService,
  ) {
    if (authService.isPlugin()) {
      viewContainerRef.createEmbeddedView(templateRef);
    }
  }
}
