import {ApiFile} from 'api/ias/model/api-file';
import {RequiredRecursively} from 'services/utils_service';

/** UI model for MediaCache file resource */
export class FileResource implements RequiredRecursively<ApiFile> {
  name = this.apiFile.name ?? '';
  cloud2onprem =
      this.apiFile.cloud2onprem ?? 'ASSET_TRANSFER_STATE_UNSPECIFIED';
  cloudLocation = this.apiFile.cloudLocation ?? '';
  error = this.apiFile.error ?? '';
  filename = this.apiFile.filename ?? '';
  filesize = this.apiFile.filesize ?? '';
  lastDownloadedTime = this.apiFile.lastDownloadedTime ?? '';
  lastSyncTime = this.apiFile.lastSyncTime ?? '';
  lifecycleInfo = {
    lifecycleState: this.apiFile.lifecycleInfo?.lifecycleState ??
        'LIFECYCLE_STATE_UNSPECIFIED',
    manualOverride: this.apiFile.lifecycleInfo?.manualOverride ?? false,
    scheduleTime: this.apiFile.lifecycleInfo?.scheduleTime ?? '',
  };
  modTime = this.apiFile.modTime ?? '';
  onprem2cloud =
      this.apiFile.onprem2cloud ?? 'ASSET_TRANSFER_STATE_UNSPECIFIED';
  onpremLocation = this.apiFile.onpremLocation ?? '';
  progressBytes = this.apiFile.progressBytes ?? '';
  runMl = this.apiFile.runMl ?? false;
  startTime = this.apiFile.startTime ?? '';
  transferAgentId = this.apiFile.transferAgentId ?? '';
  updateTime = this.apiFile.updateTime ?? '';

  constructor(private apiFile: ApiFile = {}) {}
}
