import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild} from '@angular/core';
import {fromEvent, ReplaySubject} from 'rxjs';
import {count, filter, take, takeUntil, throttleTime} from 'rxjs/operators';

import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';

/** The duration for which subsequent scroll events will be ignored. */
export const SCROLL_THROTTLE_MS = 500;

/** Search help tooltip */
@Component({
  selector: 'mam-search-help',
  templateUrl: './search_help.ng.html',
  styleUrls: ['./search_help.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchHelp implements AfterViewInit, OnDestroy {
  @Output() readonly closePanel = new EventEmitter<void>();

  @ViewChild('itemContainer') itemContainer!: ElementRef<HTMLElement>;

  constructor(
      private readonly analyticsService: FirebaseAnalyticsService,
  ) {
    this.visitStartTimeMs = this.analyticsService.getTimestamp();
  }

  onCopy() {
    const selectedText = document.getSelection()?.toString();
    if (selectedText) {
      this.analyticsService.logEvent('Search help text copied', {
        term: selectedText,
        eventType: AnalyticsEventType.COPY,
      });
    }
  }

  ngAfterViewInit() {
    const scroll$ = fromEvent(this.itemContainer.nativeElement, 'scroll')
                        .pipe(takeUntil(this.destroyed));
    scroll$
        .pipe(
            filter(event => {
              const target = event.target as HTMLElement | null;
              return !!target &&
                  (
                    (Math.floor(target.scrollHeight) - Math.floor(target.scrollTop)) ===
                    Math.floor(target.clientHeight)
                  );
            }),
            take(1),
        )
        .subscribe(() => {
          this.analyticsService.logEvent('Search help scrolled to bottom', {
            eventType: AnalyticsEventType.SCROLL,
          });
        });

    scroll$.pipe(throttleTime(SCROLL_THROTTLE_MS), count()).subscribe(count => {
      this.analyticsService.logEvent('Search help visited', {
        eventType: AnalyticsEventType.SCROLL,
        duration: Math.round(
            this.analyticsService.getTimestamp() - this.visitStartTimeMs),
        number1: count,
      });
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private readonly destroyed = new ReplaySubject<void>(1);
  private readonly visitStartTimeMs;
}
