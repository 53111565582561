import {Pipe, PipeTransform} from '@angular/core';

/**
 * Formats a number of seconds in mm:ss syntax if under one hour,
 *   otherwise in hh:mm:ss syntax.
 * Usage:
 *   value | duration
 * Example:
 *   {{ 77 | duration }}
 *   formats to: '01:17'
 */
@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {
  transform(value: number|undefined): string {
    if (value == null || Number.isNaN(value)) return '';

    let hours = 0;
    let minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);
    if (minutes >= 60) {
      hours = Math.floor(minutes / 60);
      minutes %= 60;
    }

    const secondsStr = (seconds < 10 ? '0' : '') + String(seconds);
    const minutesStr = (minutes < 10 ? '0' : '') + String(minutes);
    const hoursStr = (hours < 10 ? '0' : '') + String(hours);

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }
}
