<h1 mat-dialog-title>
  {{data.action | titlecase}} clip(s) to...
</h1>
<div mat-dialog-content>
  <mam-clip-bin-search-panel
      [isMultipleOptions]="data.action === 'copy'"
      (selectedBinsChanged)="selectedBins = $event">
  </mam-clip-bin-search-panel>
</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel {{data.action}} clip dialog">
    Cancel
  </button>
  <mam-move-clip-button
      [action]="data.action"
      [clips]="data.clips"
      [selectedBins]="selectedBins"
      (actionTriggered)="close($event)">
  </mam-move-clip-button>
</div>
