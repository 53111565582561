import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';

import {ClipsListRequestParams} from 'api/ias/api/projects.service';
import {ApiClip} from 'api/ias/model/models';
import {ExportInfo, PfrInfo} from 'models';


import {environment} from '../environments/environment';
import {GcmaQueryExpressions} from '../query_expressions/gcma_query_expressions';

import {IasApiClient} from './api_client.module';
import {convertApiAssetToUiAsset} from './asset_api_service';
import {Asset, Clip, ListResponse} from './asset_service';

export type ClipListResponse = ListResponse<Clip> & {}

/**
 *
 * Interacts with IAS backend clip related APIs
 */
@Injectable({providedIn: 'root'})
export class ClipApiService {
  constructor(
      private readonly apiClient: IasApiClient,
      private readonly gcmaQueries: GcmaQueryExpressions,
  ) {}

  /**
   * @param original Source original asset.
   * @param binName label resource name (clipbin) for this clip.
   * @param startTime start offset in seconds from original video.
   * @param endTime end offset in seconds from original video.
   * @param title title for this clip, defaults to original title.
   */
  create(
      original: Asset, binName: string, startTime: number, endTime: number,
      title: string): Observable<Clip> {
    const requestBody: ApiClip = {
      original: original.name,
      title: title || original.title,
      label: binName,
      startOffset: `${startTime.toFixed(3)}s`,
      endOffset: `${endTime.toFixed(3)}s`,
    };

    try {
      return this.apiClient
          .clipsCreate({parent: environment.mamApi.parent, body: requestBody})
          .pipe(map(convertApiClipToUiClip));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  delete(name: string): Observable<null> {
    try {
      return this.apiClient.clipsDelete({name}).pipe(map(() => null));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  download(clipName: string, downloadFolder: string): Observable<Clip> {
    try {
      return this.apiClient
          .clipsDownload({name: clipName, body: {downloadFolder}})
          .pipe(map(convertApiClipToUiClip));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  export(clipName: string, exportFolder: string): Observable<Clip> {
    try {
      return this.apiClient
          .clipsExport({name: clipName, body: {exportFolder}})
          .pipe(map(convertApiClipToUiClip));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  rename(name: string, title: string): Observable<Clip> {
    try {
      return this.apiClient
          .clipsPatch({name, body: {title}, updateMask: 'title'})
          .pipe(map(convertApiClipToUiClip));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  move(name: string, label: string): Observable<Clip> {
    try {
      return this.apiClient
          .clipsPatch({name, body: {label}, updateMask: 'label'})
          .pipe(map(convertApiClipToUiClip));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  searchClips(
      binName: string, query: string, pageToken?: string,
      pageSize?: number): Observable<ListResponse<Clip>> {
    const params: Partial<ClipsListRequestParams> = {
      pageToken,
      pageSize,
      filter: `label=${binName} title:"${query}"`,
    };

    return this.list(params);
  }
  searchClipsByTitle(
    query: string, pageSize = 12,pageToken?: string,): Observable<ListResponse<Clip>> {
    const params: Partial<ClipsListRequestParams> = {
      pageToken,
      pageSize,
      filter: `title:"${query}"`,
    };

    return this.list(params);
  }

  getClipsFromAsset(
      originalAssetName: string, pageSize: number,
      pageToken?: string): Observable<ListResponse<Clip>> {
    const params: Partial<ClipsListRequestParams> = {
      pageToken,
      pageSize,
      filter: `original=${originalAssetName}`,
    };
    return this.list(params);
  }

  getCount(clipbinName: string) {
    try {
      return this.apiClient
          .clipsGetCount({
            parent: environment.mamApi.parent,
            filter: `label=${clipbinName}`
          })
          .pipe(map(resp => ({count: resp.count ?? 0})));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  getLiveClipCount(clipbinName: string) {
    const filter = `label=${clipbinName} gcsObject=null`;
    try {
      return this.apiClient
          .clipsGetCount({parent: environment.mamApi.parent, filter})
          .pipe(map(resp => ({count: resp.count ?? 0})));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  getOne(name: string): Observable<Clip> {
    try {
      return this.apiClient.clipsGet({name}).pipe(
          map(convertApiClipToUiClip));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  reorder(clip: Clip, previousClipName?: string, nextClipName?: string):
      Observable<Clip> {
    return this.apiClient
        .clipsReorder({
          name: clip.name,
          body: {previous: previousClipName, next: nextClipName}
        })
        .pipe(map(convertApiClipToUiClip));
  }

  /**
   * Specifying export folder path with site in order to request the API
   * returning PFRs that locate in those folders. Filter by
   * pfrState=STATE_UNSPECIFIED to return all PFRs.
   */
  listExportPfrClips(
      userQuery: string, pageSize: number, pageToken?: string,
      date?: DateTime): Observable<ListResponse<Clip>> {
    const params: Partial<ClipsListRequestParams> = {
      pageToken,
      pageSize,
      filter: this.gcmaQueries.and([
        // Get all PFR clips.
        this.gcmaQueries.isNot('pfrCreateTime', 'null'),
        // Matching user query (if any)
        userQuery ? this.gcmaQueries.includes('title', userQuery) : '',
        // PFR is updated on the given date.
        date ? this.gcmaQueries.withinDate('pfrUpdateTime', date) : '',
      ]),
    };

    return this.list(params);
  }

  /**
   * Specifying export folder path with site in order to request the API
   * returning live clips that locate in those folders.
   */
  listExportLiveClips(
      userQuery: string, pageSize: number, pageToken?: string,
      date?: DateTime): Observable<ListResponse<Clip>> {
    const params: Partial<ClipsListRequestParams> = {
      pageToken,
      pageSize,
      filter: this.gcmaQueries.and([
        // Get all live clips.
        this.gcmaQueries.isNot('liveExportCreateTime', 'null'),
        // Matching user query (if any)
        userQuery ? this.gcmaQueries.includes('title', userQuery) : '',
        // Live clip is updated on the given date.
        date ? this.gcmaQueries.withinDate('liveExportUpdateTime', date) : '',
      ]),
    };

    return this.list(params);
  }

  private list(params: Partial<ClipsListRequestParams> = {}) {
    try {
      return this.apiClient
          .clipsList({parent: environment.mamApi.parent, ...params})
          .pipe(map(result => {
            return {
              nextPageToken: result.nextPageToken,
              assets: (result.clips ?? []).map(c => convertApiClipToUiClip(c)),
            };
          }));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }
}

/**
 * Converts an IAS clip resource into a UI Clip object literal.
 */
export function convertApiClipToUiClip(apiClip: ApiClip): Clip {
  const originalApiAsset = apiClip.sourceAsset;
  const original = convertApiAssetToUiAsset(originalApiAsset);

  // Start by copying all original properties and override those specific to
  // this clip from the API response.
  const startTime = Number(apiClip.startOffset?.replace('s', '')) || 0;
  const endTime =
      Number(apiClip.endOffset?.replace('s', '')) || original.endTime;

  const clip: Clip = {
    ...original,
    name: apiClip.name ?? '',
    original,
    title: apiClip.title || original.title,
    createTime: Date.parse(apiClip.createTime ?? ''),
    startTime,
    endTime,
    duration: endTime - startTime,
    pfrInfo: new PfrInfo(apiClip.pfrInfo),
    exportInfo: new ExportInfo(apiClip.exportInfo),
    label: apiClip.label ?? '',
  };

  return clip;
}
