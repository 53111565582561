import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';

import {AnalyticsEventType, PAGE_CONTEXT_TOKEN} from '../firebase/firebase_analytics_service';
import {VideoPlayer} from '../player/video_player';
import {Asset} from '../services/asset_service';
import {DeviceInputService} from '../services/device_input_service';
import {ScrubbingService} from '../shared/scrubbing_service';
import {SpritesheetService} from '../shared/spritesheet_service';

/**
 * Video preview that seeks on hover. By default, the metadata of videos is
 * preloaded (property `preload` set to `metadata`) so that we can immediately
 * start seeking on hover: more information in cl/331658013. Fully preloading
 * the video ('auto') might use too much memory when displaying multiple videos,
 * especially within the Adobe Premiere plugin.
 */
@Component({
  selector: 'mam-seekable-preview',
  templateUrl: './seekable_preview.ng.html',
  styleUrls: ['./seekable_preview.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeekablePreview implements AfterViewInit, OnDestroy {
  /**
   * previewUrl is needed for seek on hover but is not required initially
   * because we display an asset thumbnail until the component is hovered.
   * Additionally we expect previewUrl to be set only once and use rxjs
   * ReplaySubject to delay referencing videoPlayer ViewChild because it might
   * not be available at the time previewUrl is set.
   */
  @Input()
  set previewUrl(src: string) {
    this.previewUrlInternal = src;
    this.previewUrl$.next(src);
  }
  get previewUrl() {
    return this.previewUrlInternal ?? '';
  }

  @Input() start = 0;

  @Input() end?: number;

  /** Used to display a thumbnail initially. */
  @Input() asset?: Asset;
  @Input() thumbTime?: number;

  @Input() isRecentAsset!: boolean;

  /**
   * Sets the `preload` attribute of the <video> element.
   * For details, see
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#attr-preload
   */
  @Input() preload: 'auto'|'metadata'|'none' = 'metadata';

  /** Emits when the underlying thumbnail component is loaded. */
  @Output() readonly thumbnailLoad = new EventEmitter<boolean>();

  @ViewChild('videoPlayer') videoPlayer!: VideoPlayer;

  /** Left position of the playhead */
  playheadLeft = 0;
  readonly PAGE_CONTEXT_TOKEN = PAGE_CONTEXT_TOKEN;
  readonly AnalyticsEventType = AnalyticsEventType;

  constructor(
      private readonly spritesheetService: SpritesheetService,
      private readonly scrubbingService: ScrubbingService,
      private readonly deviceInput: DeviceInputService,
  ) {}

  @HostBinding('class.no-rendition')
  get isInvalid() {
    return !this.asset?.renditions.length;
  }

  @HostListener('mousemove', ['$event'])
  @HostListener('touchmove', ['$event'])
  mouseMove(event:UIEvent) {
    const pos = this.getOffsetX(event);
    
    // Move playhead 
    this.playheadLeft = pos;

    const target = event.currentTarget as HTMLElement;
    const width = target.offsetWidth;
    // Calculate a horizontal position ratio from 0 to 1
    const ratio = pos / width;
    this.seekToRatio(ratio);
  }

  @HostListener('touchstart')
  touchStart() {
    this.scrubbingService.startScrubbingEvent();
  }

  @HostListener('mouseleave')
  @HostListener('touchend')
  mouseLeave() {
    this.playheadLeft = 0;
    this.videoPlayer.seek(this.start);

    this.scrubbingService.endScrubbingEvent();
  }

  /** Whether to display a thumbnail on top of the video. */
  showThumbnail() {
    // If no asset is provided, show video (used by mam-recent-assets)
    if (!this.asset) return false;
    // If no rendition is available, display the empty placeholder thumbnail.
    if (!this.asset.renditions.length) return true;
    // If we have renditions but no spritesheet, hide thumbnails so that we
    // can see the first frame of the video underneath.
    return this.spritesheetService.getSpriteSheet(this.asset) != null;
  }

  onThumbnailLoaded(success: boolean) {
    this.thumbnailLoad.emit(success);
  }

  private readonly previewUrl$ = new ReplaySubject<string>(1);
  private previewUrlInternal?: string;

  /**
   * Seek to provided ratio of the video duration. 0% is the first frame and
   * 100% the last one.
   */
  private seekToRatio(ratio: number) {
    // Abort if video is not loaded yet
    if (Number.isNaN(this.videoPlayer.duration)) return;

    const start = this.start;
    const end = this.end || this.videoPlayer.duration;
    const time = start + (end - start) * ratio;

    this.videoPlayer.fastSeek(time);
  }

  ngAfterViewInit() {
    this.previewUrl$
        .pipe(
            takeUntil(this.destroyed),
            filter(url => Boolean(url)),
            /**
             * previewUrl is expected to be set only once as it always
             * references the same video manifest for particular asset. But
             * recent assets are refreshed every 4 seconds, and every time the
             * asset is refreshed, its rendition manifest urls (while still
             * pointing to the same manifests) have signature and expiration
             * params changed. This triggers a new previewUrl$ emission and
             * causes video player to reload the same video.
             */
            take(1),
            )
        .subscribe(url => {
          if (this.start > 0 || this.end != null) {
            url += `#t=${this.start},${this.end}`;
          }
          this.videoPlayer.src = url;
        });
  }

  private readonly destroyed = new ReplaySubject<void>(1);
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private getOffsetX(event: UIEvent): number {
    const currentTarget = event.currentTarget as HTMLElement | null;
    let pos = 0;
    if (this.deviceInput.isTouchEvent(event)) {
      const touchEvent = event as TouchEvent;
      pos = currentTarget
        ? (touchEvent.changedTouches[0].clientX - currentTarget.getBoundingClientRect().left)
        : touchEvent.changedTouches[0].screenX;
    } else {
      const mouseEvent = event as MouseEvent;
      pos = mouseEvent.offsetX;
    }
    return pos;
  }
}
