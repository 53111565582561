import {Injectable} from '@angular/core';

import {environment} from '../environments/environment';
import {Clip, Original} from '../services/asset_service';
import {Path} from '../services/media_cache_state_service';
import {TimezoneService} from '../services/timezone_service';

import {IASEvent, IASEventType} from './firebase_firestore_data_service';

@Injectable({providedIn: 'root'})
export class FirestoreIASEventHelper {

  constructor(
    private readonly timezone: TimezoneService
  ) {}

  formatTransferIASEvent(clip: Clip, assetTitle: string, path: Path): IASEvent {

    const pfrInfo= clip.pfrInfo;
    const gcpFolderKey = `${environment.mamApi.parent}/sites/${path.siteId}/folders/${path.folderId}`;

    let outputGcsUri = '';
    let filename = '';
    let state = '';
    let createTime  = new Date().toISOString();
    let completeIASEventInfo = false;
    const transferType = IASEventType.TRANSFER;

    const startOffset = clip.startTime;
    const endOffset = clip.endTime;
    const durationResponse =clip.original.duration;

    if ((endOffset - startOffset === durationResponse)) {
      outputGcsUri = clip.gcsLocationUrl.split('?')[0];
      filename = outputGcsUri.replace(environment.mamApi.bucketRootUri ?? '', '');
      state = clip.state;
      createTime = this.timezone.formatTimestampToISOString(clip.createTime);
      completeIASEventInfo = true;
    }

    if (!completeIASEventInfo && pfrInfo.stateMap && Object.keys(pfrInfo.stateMap).length != 0) {
      outputGcsUri = pfrInfo.stateMap[gcpFolderKey].outputGcsUri;
      filename = outputGcsUri.replace(environment.mamApi.bucketRootUri ?? '', '');
      state = pfrInfo.stateMap[gcpFolderKey].state;
      createTime  = pfrInfo.stateMap[gcpFolderKey].createTime;
    }

    return { type: transferType,
      assetTitle: assetTitle,
      assetName: clip.name,
      filename: filename,
      state: state,
      createTime: createTime,
      formattedCreateDate: this.timezone.formatTimeZoneStringToYyyyMMdd(createTime)
    } as IASEvent;
  }

  formatDeletedAssetsIASEvent(asset: Original | Clip) {
    const createTime = this.timezone.formatTimestampToISOString(asset.createTime);
    return { type: IASEventType.DELETE_ASSET,
      assetTitle: asset.title,
      assetName: asset.name,
      state: asset.state,
      createTime: createTime,
      formattedCreateDate: this.timezone.formatTimeZoneStringToYyyyMMdd(createTime)
    } as IASEvent;
  }
}
