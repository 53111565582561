/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Information about a single storage transfer operation.
 */
export interface ApiCloudTransferOperation { 
    /**
     * EndTime: End time of this transfer execution.
     */
    endTime?: string;
    /**
     * Name of the transfer operation.
     */
    name?: string;
    /**
     * OperationState: Status of the transfer operation.
     */
    operationState?: ApiCloudTransferOperation.OperationStateEnum;
    /**
     * StartTime: Start time of this transfer execution.
     */
    startTime?: string;
    /**
     * StatusInfo: contains detailed status/error messages.
     */
    statusInfo?: { [key: string]: string; };
    /**
     * Transfer job id associated with this operation.
     */
    transferJobId?: string;
}
export namespace ApiCloudTransferOperation {
    export type OperationStateEnum = 'OPERATION_STATE_UNSPECIFIED' | 'IN_PROGRESS' | 'PAUSED' | 'SUCCEEDED' | 'FAILED' | 'ABORTED' | 'QUEUED';
    export const OperationStateEnum = {
        OPERATION_STATE_UNSPECIFIED: 'OPERATION_STATE_UNSPECIFIED' as OperationStateEnum,
        IN_PROGRESS: 'IN_PROGRESS' as OperationStateEnum,
        PAUSED: 'PAUSED' as OperationStateEnum,
        SUCCEEDED: 'SUCCEEDED' as OperationStateEnum,
        FAILED: 'FAILED' as OperationStateEnum,
        ABORTED: 'ABORTED' as OperationStateEnum,
        QUEUED: 'QUEUED' as OperationStateEnum
    };
}


