import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import {StatusCode} from './status_code';

/** Formatted errors returned by IAS API calls. */
export class ApiError extends Error {
  status: StatusCode;
  headers: HttpHeaders;
  /** Inner error message provided by IAS. */
  readonly innerErrorMessage: string;

  constructor(httpError: HttpErrorResponse) {
    // HttpErrorResponse cannot be reported to stackDriver as they lack
    // a stack trace and toString() serialization. We convert this http
    // response to a true Error instance.
    let message = `[${httpError.status}] ${httpError.message}`;
    if (typeof httpError.error === 'string') {
      message += `; ${httpError.error}`;
    }
    super(message);
    this.name = 'ApiError';
    // Note that `status` is `0` when it is due to a loss of connectivity.
    this.status = httpError.status;
    this.headers = httpError.headers;
    this.innerErrorMessage = this.tryParseInnerMessage(httpError) || '';
  }

  /**
   * Try get inner API error that may provide valuable information for the user.
   */
  private tryParseInnerMessage(httpError: HttpErrorResponse): string|undefined {
    try {
      if (typeof httpError.error !== 'string') return;
      const container = JSON.parse(httpError.error) as {error?: InnerApiError};
      return container?.error?.message;

    } catch {
      // Undefined will be returned.
    }
    return;
  }
}

/** Creates ApiError object for use in tests. */
export function makeFakeApiError(errorMsg: string, statusCode = 400) {
  const innerError: InnerApiError = {
    code: statusCode,
    message: errorMsg,
    status: 'test_error',
  };

  return new ApiError(new HttpErrorResponse({
    status: statusCode,
    error: JSON.stringify({error: innerError}),
  }));
}

interface InnerApiError {
  code: number;
  message: string;
  status: string;
}
