import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {ApiSuggestQueriesResponse} from 'api/ias/model/models';
import {environment} from 'environments/environment';

import {ErrorResponse} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {VcmsQueryExpressions} from '../query_expressions/vcms_query_expressions';

import {MetadataField} from './asset_api_service';
import {Original} from './asset_service';
import {ApiApprovalStateStateEnum, ApiAssetStateEnum} from './ias_types';
import {SearchApiService, SearchMode, SearchParams, SearchQueryParams, SearchType} from './search_api.service';
import {FacetGroup} from './search_facet_service';

export {DATE_RANGE_FORMAT, SearchMode, SearchParams, SearchQueryParams, SearchType} from './search_api.service';


/**
 * Period of time after which cached multi camera event is considered outdated.
 */
export const MULTICAMERA_EVENT_CACHE_EXPIRATION_MS = 60 * 1000;

/** Character used to split a query string to chips. */
export const QUERY_SEPARATOR = ';';

/** Service that provides access to search API. */
@Injectable({providedIn: 'root'})
export class SearchService {
  constructor(
      private readonly searchApi: SearchApiService,
      private readonly errorService: ErrorService,
      private readonly vcmsExpressions: VcmsQueryExpressions,
  ) {}

  /**
   * Searches for live or VoD assets using VCMS.
   *
   * Through out MAM UI, except for Content staging we show only approved and
   * STATE_READY VoD assets. This method adds additional filters to ensure that.
   */
  search(searchParams: SearchParams): Observable<SearchResponse|ErrorResponse> {
    let query = searchParams.query.trim();
    if (searchParams.searchType === SearchType.VOD) {
      query = this.restrictVodToApprovedAndReady(query);
    }

    return this.searchUnrestricted({...searchParams, query});
  }

  /**
   * Searches for live or VoD assets using VCMS.
   *
   * Does not restrict assets to approved and STATE_READY. Use only for Content
   * Staging.
   */
  searchUnrestricted(searchParams: SearchParams) {
    return this.searchApi.search(searchParams)
        .pipe(this.errorService.retryShort(), this.errorService.catchError());
  }

  /**
   * Searches for live or VoD assets using VCMS.
   * Fetches all matching results. See `SearchApiService.searchAll` for more
   * details.
   *
   * Through out MAM UI, except for Content staging we show only approved and
   * STATE_READY VoD assets. This method adds additional filters to ensure that.
   */
  searchAll(
      searchParams: SearchQueryParams, restrictVodToApprovedAndReady = true):
      Observable<SearchResponse|ErrorResponse> {
    let query = searchParams.query.trim();
    if (searchParams.searchType === SearchType.VOD &&
        restrictVodToApprovedAndReady) {
      query = this.restrictVodToApprovedAndReady(query);
    }

    return this.searchAllUnrestricted({...searchParams, query});
  }

  /**
   * Searches for live or VoD assets using VCMS.
   * Fetches all matching results. See `SearchApiService.searchAll` for more
   * details.
   *
   * Does not restrict assets to approved or STATE_READY. Use only for Content
   * Staging.
   */
  searchAllUnrestricted(searchParams: SearchQueryParams):
      Observable<SearchResponse|ErrorResponse> {
    return this.searchApi.searchAll(searchParams)
        .pipe(this.errorService.retryShort(), this.errorService.catchError());
  }

  suggest(
      queryPrefix: string, searchMode: SearchMode, searchType: SearchType,
      resultSize = 30) {
    if (!queryPrefix) {
      const defaultSuggestions = this.getDefaultSuggestions(searchMode, searchType);
      if (defaultSuggestions) {
        return defaultSuggestions;
      }
    }

    return this.searchApi
        .suggest(queryPrefix, searchMode, searchType, resultSize)
        .pipe(this.errorService.retryShort(), this.errorService.catchError());
  }

  /**
   * Returns default suggestions for the specified search mode/type if they were
   * configured.
   */
  private getDefaultSuggestions(searchMode: SearchMode, searchType: SearchType) {
    const rawSuggestions = this.getDefaultRawSuggestions(searchMode, searchType);
    if (!rawSuggestions) {
      return;
    }

    return of({
      requestType: ApiSuggestQueriesResponse.RequestTypeEnum.SUGGEST_QUERIES_REQUEST_TYPE_UNSPECIFIED,
      suggestedQueries: rawSuggestions.split(',,').map(r => this.convertToQuery(r)),
    });
  }

  /**
   * Tries to find appropriate default suggestions for the specified search mode/type
   * in the environment/ui configuration.
   * Firstly suggestions are searched by the 'type/mode' complex key.
   * If nothing were found then simple 'type' key.
   * If again nothing was found, then no default suggestions are returned.
   */
  private getDefaultRawSuggestions(searchMode: SearchMode, searchType: SearchType) {
    if (!environment.defaultSuggestions) {
      return;
    }

    const rawSuggestions = environment.defaultSuggestions.split('||');

    const fullKey = `${searchType.toLowerCase()}|${searchMode.toLowerCase()}=`;
    const simpleKey = `${searchType.toLowerCase()}=`;

    for (const key of [fullKey, simpleKey]) {
      for (const rawSuggestion of rawSuggestions) {
        if (rawSuggestion.startsWith(key)) {
          return rawSuggestion.slice(key.length + 1);
        }
      }
    }

    return;
  }

  private convertToQuery(rawSuggestion: string) {
    return {
      text: rawSuggestion,
      searchOperator: '',
      searchOperatorSuggestion: rawSuggestion.endsWith(':'),
    };
  }

  /**
   * Updates the query to restrict result to approved STATE_READY VoD assets.
   * This allows for searches with no chips from the user perspective (when only
   * searching by facets), as well as removes draft, not ready and live assets
   * from VoD searches.
   */
  private restrictVodToApprovedAndReady(query: string) {
    return this.vcmsExpressions.and([
      query,
      this.vcmsExpressions.is('AssetState', ApiAssetStateEnum.STATE_READY),
      this.vcmsExpressions.is(
          'AssetApprovalState', ApiApprovalStateStateEnum.APPROVED),
    ]);
  }

  /**
   * Returns all assets that represent different camera angle views for the same
   * multi-camera event.
   */
  getCameraAssets(correlationId: string, ignoreCache = false):
      Observable<Original[]|ErrorResponse> {
    const cached = ignoreCache ? null : this.cachedMultiCameraEvent;
    // Expected max number of camera angles is 8.
    const pageSize = 25;

    if (cached && cached.correlationId === correlationId &&
        Date.now() - cached.timestamp < MULTICAMERA_EVENT_CACHE_EXPIRATION_MS) {
      return of(cached.assets);
    }

    const searchRequest: SearchParams = {
      pageSize,
      query: `${MetadataField.CORRELATION_ID}: ${correlationId}`,
      facetSelections: [],
      searchMode: SearchMode.VIDEO,
      searchType: SearchType.LIVE,
    };
    return this.searchApi.search(searchRequest)
        .pipe(
            this.errorService.retryShort(),
            map(response => {
              if (response.nextPageToken) {
                // Document that the number of assets has exceeded the page
                // size.
                this.errorService.handle(new Error(`More than ${
                    pageSize} assets were found with correlationId: ${
                    correlationId}`));
              }
              return response.videoSegments.map(vs => vs.asset).sort((a, b) => {
                // Make primary asset appear first.
                if (a.camera?.isBroadcast) return -1;
                if (b.camera?.isBroadcast) return 1;
                return a.title.localeCompare(b.title);
              });
            }),
            tap(assets => {
              this.cachedMultiCameraEvent = {
                correlationId,
                assets,
                timestamp: Date.now(),
              };
            }),
            this.errorService.catchError(),
        );
  }

  getCachedCameraAssets() {
    return this.cachedMultiCameraEvent?.assets ?? [];
  }

  /**
   * Used internally for caching purposes and in details navigation as a
   * source of items for `cameras` context.
   */
  private cachedMultiCameraEvent?: CachedMultiCameraEvent;
}

/** Contains information about a query */
export interface QuerySegment {
  startTime: number;
  endTime: number;
  query: string;
  /** The event id associated with the original search request. */
  eventId: string;
  /** Whether this is a result from a Segment or Video level search */
  searchMode: SearchMode;
}

/** Represents a raw search result */
export interface SearchSegment extends QuerySegment {
  /** Asset of the video segment. */
  asset: Original;
  /** Search segment identifier based on asset name and start/end times. */
  name: string;
}

/** Represents the search results with nextPageToken */
export interface SearchResponse {
  videoSegments: SearchSegment[];
  searchMode: SearchMode;
  nextPageToken?: string;
  facetGroups?: FacetGroup[];
  /**
   * Whether this search response was automatically fetched to populate the
   * initial facets.
   */
  isInitialFacetsResponse?: boolean;
}

/** Cached assets that represent different camera angles of the same event. */
export interface CachedMultiCameraEvent {
  correlationId: string;
  timestamp: number;
  assets: Original[];
}

/** Used to pass relevant segments for one keyword search. */
export interface KeywordResult {
  /** Search term */
  keyword: string;
  /** Results array */
  segments: QuerySegment[];
}
