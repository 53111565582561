import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, shareReplay, switchMap, take } from 'rxjs/operators';

import { castExists } from 'asserts/asserts';
import { Folder, Site, SiteTransferStats, TransferTask } from 'models';

import { ApiTransferTask } from '../api/ias/model/api-transfer-task';
import { isErrorResponse } from '../error_service/error_response';

import { ApiTransferTaskTransferState, ApiTransferTaskTransferType } from './ias_types';
import { MediaCacheService } from './media_cache_service';
import { ProgressbarService } from './progressbar_service';
import { SitesApiService } from './sites_api_service';
import { TaskUsersService } from './task_user_service';
import { TransferTaskApiService, TransferTaskPropertyName } from './transfer_task_api_service';
import { UtilsService } from './utils_service';

/** How often the stats are updated, in ms. */
export const STATS_UPDATE_INTERVAL = 60_000;

/** Valid statuses for a single transfer task. */
export enum TaskStatus {
  /** Either pending, processing, or unknown status on the backend. */
  ACTIVE = 'active',
  /** Download or upload did not complete and may be retried. */
  FAILED = 'failed',
  /** Download or upload is complete. */
  COMPLETED = 'completed',
}

/** Transfer task types. */
export enum TransferType {
  UPLOAD = 'upload',
  DOWNLOAD = 'download'
}

/** Statistics for display in the summary row. */
export interface TransferStats {
  activeCount: number;
  failedCount: number;
  /** Total transfer volume in bytes */
  volume: number;
  /** Average transfer rate in bytes/second */
  rate: number;
  watchFoldersCount: number;
}

/** Individual row displayed in the main table. */
export interface TransferRow {
  /** Unique identifier for this task */
  id: string;
  /** Display name */
  name: string;

  user: string;

  /** File size in bytes */
  size: number;
  /** Time of last modification, ISO date format */
  modifiedTime: string;
  /** Task progress from `0` to `1` */
  progress: number;
  /** Whether this task is processing, failed, or completed. */
  status: TaskStatus;
  /** Task type: upload or download */
  type: TransferType;
  /** Whether Machine Learning is enabled on this task. */
  isMlEnabled: boolean;
  /** Reference to the full task returned by the API. */
  rawTask?: TransferTask;
}

/** Order by options for transfer rows. */
export interface TransferRowSort {
  active: 'name'|'size'|'modifiedTime';
  direction: 'asc'|'desc';
}

/** Filter option for transfer rows */
export interface TransferRowFilter {
  status?: TaskStatus;
  type?: TransferType;
  name?: string;
}

/**
 * Criteria (filters) for the stats calculation.
 * The 'site'  is mandatory thing to call related API.
 */
interface StatsCalcCriteria {
  site: Site;
  type?: TransferType;
}

/** Represents a single smallest change of filter. */
export type TransferRowFilterChange = {
  [K in keyof TransferRowFilter] -?: {type: K; value: TransferRowFilter[K];}
}[keyof TransferRowFilter];

/**
 * Service for the transfer monitor page.
 */
@Injectable({providedIn: 'root'})
export class TransferService {
  /** Selected Site that is used specifically for the transfers tab */
  private readonly transferSelectedSiteInternal$ = new ReplaySubject<Site>(1);

  /**
   * Transfer specific site. This only emits once in the constructor and any
   * time a different site is selected on ../jobs/transfers is selected.
   */
  readonly transferSelectedSite$ = this.transferSelectedSiteInternal$.pipe(
      distinctUntilChanged((site1: Site, site2: Site) => {
        return site1.siteId === site2.siteId;
      }),
  );

  /** Transfers statistics for the summary row, updated every few seconds. */
  stats$: Observable<TransferStats|null> = of(null);

  /** Exportable folders in the selected transfer site. */
  readonly siteAndExportFolders$: Observable<{site: Site, folders: Folder[]}>;

  /** Whether it's processing search. */
  processing$ = new BehaviorSubject(false);

  /**
   * Criteria for the stats calculation.
   * Isn't initialized initially because of the 'site' absence.
   * Initialized when 'site' come first time.
   */
  private statsCalcCriteria$?: BehaviorSubject<StatsCalcCriteria>;

  constructor(
      private readonly mediaCache: MediaCacheService,
      private readonly progressbar: ProgressbarService,
      private readonly sitesApi: SitesApiService,
      private readonly transferTaskApi: TransferTaskApiService,
      private readonly utils: UtilsService,
      private readonly transferTaskUserService: TaskUsersService
  ) {
    this.transferSelectedSite$.subscribe(site =>
       this.updateStatsCalcCriteriaWithSiteData(site));

    // Initiate transferSelectedService
    this.mediaCache.state.selectedSite$.pipe(take(1)).subscribe(site => {
      this.transferSelectedSiteInternal$.next(site);
    });

    this.siteAndExportFolders$ = this.transferSelectedSite$.pipe(
        switchMap(site => {
          return this.mediaCache.state.getExportFolders(site).pipe(
              map(folders => {
                return isErrorResponse(folders) ? {site, folders: []} :
                                                  {site, folders};
              }));
        }),
        shareReplay({bufferSize: 1, refCount: true}));

    this.processing$.subscribe(processing => {
      if (processing) {
        this.progressbar.show();
        return;
      }
      this.progressbar.hide();
    });
  }

  /** Updates query params with the site ID to select. */
  selectTransferSite(site: Site) {
    this.transferSelectedSiteInternal$.next(site);
  }

  searchTasks(
      siteId: string, pageIndex: number, pageSize: number,
      filter: TransferRowFilter, orderBy: TransferRowSort):
      Observable<{rows: TransferRow[], totalSize: number}|null> {
    this.updateStatsCalcCriteriaWithFilterData(filter);

    const orderByExpr = this.getSortExpression(orderBy);
    const filterExpr = this.getFilterExpression(filter);
    return this.transferTaskApi
        .search(siteId, pageIndex + 1, pageSize, filterExpr, orderByExpr)
        .pipe(
          mergeMap(
            originalResponse => {
              if (!originalResponse) return of (originalResponse);
              const originalTransferTasks = originalResponse.transferTasks;
              const totalSize = originalResponse.totalSize;

              if (!originalTransferTasks || originalTransferTasks.length == 0 ) return of (originalResponse);
              const completedTransferTasks = originalTransferTasks.map(
                originalTransferTask => this.transferTaskUserService
                  .completeTransferTaskWithUser(originalTransferTask)
              );

              return combineLatest(completedTransferTasks).pipe(
                map(list => {
                  return { transferTasks: list, totalSize };
                })
              );
            })
        )
        .pipe(map(response => {
          if (!response) return null;
          const totalSize = response.totalSize;
          const rows: TransferRow[] = response.transferTasks.map(task => {
            const row: TransferRow = {
              id: task.name,
              name: task.files[0].filename,
              user: task.user,
              size: Number(task.files[0].filesize),
              modifiedTime: task.transferModTime,
              progress: task.fileProgress[0],
              isMlEnabled: task.files[0].runMl,
              status: task.transferState === 'TRANSFER_STATE_ERROR' ?
                  TaskStatus.FAILED :
                  (
                    task.transferState === 'TRANSFER_STATE_COMPLETED' ?
                      TaskStatus.COMPLETED :
                      TaskStatus.ACTIVE
                  ),
              type: task.transferType === 'TRANSFER_DIRECTION_DOWNLOAD' ?
                  TransferType.DOWNLOAD :
                  TransferType.UPLOAD,
              rawTask: task,
            };
            return row;
          });
          return {rows, totalSize};
        }));
  }

  retryTask(taskId: string) {
    return this.transferTaskApi.retry(taskId);
  }

  private readonly taskPropertyMapping =
      new Map<keyof TransferRow, TransferTaskPropertyName>([
        ['name', TransferTaskPropertyName.FILE_NAME],
        ['size', TransferTaskPropertyName.FILE_SIZE],
        ['modifiedTime', TransferTaskPropertyName.TRANSFER_LAST_MODIFIED],
        ['status', TransferTaskPropertyName.TRANSFER_STATE],
        ['type', TransferTaskPropertyName.TRANSFER_TYPE],
      ]);

  private readonly statusValueMapping =
      new Map<TaskStatus, ApiTransferTaskTransferState[]>([
        [
          TaskStatus.ACTIVE,
          ['TRANSFER_STATE_PENDING', 'TRANSFER_STATE_PROCESSING']
        ],
        [TaskStatus.COMPLETED, ['TRANSFER_STATE_COMPLETED']],
        [TaskStatus.FAILED, ['TRANSFER_STATE_ERROR']],
      ]);

  private readonly typeValueMapping =
      new Map<TransferType, ApiTransferTaskTransferType[]>([
        [TransferType.UPLOAD, ['TRANSFER_DIRECTION_UPLOAD']],
        [TransferType.DOWNLOAD, ['TRANSFER_DIRECTION_DOWNLOAD']],
      ]);

  private watchStats(criteria: StatsCalcCriteria): Observable<TransferStats> {
    return this.utils.timer(STATS_UPDATE_INTERVAL)
        .pipe(
            switchMap(() => {
              return this.sitesApi.fetchStats(criteria.site.siteId);
            }),
            filter((stats): stats is SiteTransferStats => stats != null),
            map(stats => stats),
            map(stats => {
              return {
                activeCount: this.getActiveTransfersCount(stats),
                failedCount: this.getFailedTransfersCount(stats),
                volume: this.getTransferVolume(stats, criteria.type),
                rate: this.getTransferRate(stats),
                watchFoldersCount: this.getWatchFoldersCount(stats),
              };
            }),
        );
  }

  private getActiveTransfersCount(stats: SiteTransferStats): number {
    const uploadsAndDownloads = this.getDownloadsAndUploadsStats(stats);
    return uploadsAndDownloads.reduce((total, it) => {
      return total +
          it.transferStateStats
              .filter(
                  it => it.state === 'TRANSFER_STATE_PROCESSING' ||
                      it.state === 'TRANSFER_STATE_PENDING')
              .reduce((total, it) => total + Number(it.numTransfers), 0);
    }, 0);
  }

  private getFailedTransfersCount(stats: SiteTransferStats): number {
    const uploadsAndDownloads = this.getDownloadsAndUploadsStats(stats);
    return uploadsAndDownloads.reduce((total, it) => {
      return total +
          it.transferStateStats
              .filter(it => it.state === 'TRANSFER_STATE_ERROR')
              .reduce((total, it) => total + Number(it.numTransfers), 0);
    }, 0);
  }

  /** Returns the total transfer volume in bytes. */
  private getTransferVolume(stats: SiteTransferStats, type?: TransferType): number {
    const uploadsAndDownloads = this.getDownloadsAndUploadsStats(stats, type);
    return uploadsAndDownloads.reduce((total, it) => {
      return total +
          it.transferStateStats
              .filter(it => it.state === 'TRANSFER_STATE_COMPLETED')
              .reduce((total, it) => {
                // `transferVolumeMbytes` is given in MiB, convert it to bytes.
                const increment = Number(it.transferVolumeMbytes) * (1024 ** 2);
                return total + increment;
              }, 0);
    }, 0);
  }

  /**
   * Returns the average transfer rate in bytes/second, read from the stat on
   * DIRECTION_ALL.
   */
  private getTransferRate(stats: SiteTransferStats): number {
    const allDirections = stats.transferDirectionStats.find(
        direction => direction.direction === 'TRANSFER_DIRECTION_ALL');
    const completed = allDirections?.transferStateStats.find(
        it => it.state === 'TRANSFER_STATE_COMPLETED');

    // `transferRateMbytes` is given in MiB/s, convert it to bytes/s.
    return (completed?.transferRateMbytes || 0) * (1024 ** 2);
  }

  private getWatchFoldersCount(stats: SiteTransferStats): number {
    return stats.numWatchfolders;
  }

  private getSortExpression(sort: TransferRowSort) {
    return `${this.taskPropertyMapping.get(sort.active)} ${sort.direction}`;
  }

  private getDownloadsAndUploadsStats(stats: SiteTransferStats, type?: TransferType) {
    let matchedDirections = ['TRANSFER_DIRECTION_DOWNLOAD', 'TRANSFER_DIRECTION_UPLOAD'];
    if (type) {
      matchedDirections = this.castTransferType(type);
    }

    return stats.transferDirectionStats.filter(direction =>
        matchedDirections.includes(direction.direction));
  }

  private getFilterExpression(filter: TransferRowFilter) {
    const subFilters: string[] = [];

    if (filter.status) {
      subFilters.push(this.getSubFilterExpression(
          'status', castExists(this.statusValueMapping.get(filter.status))));
    }

    if (filter.type) {
      subFilters.push(this.getSubFilterExpression(
          'type', this.castTransferType(filter.type)));
    } else {
      // If no type is specified, default to only DOWNLOAD and UPLOAD.
      const allTypesSupported: ApiTransferTaskTransferType[] =
          ['TRANSFER_DIRECTION_DOWNLOAD', 'TRANSFER_DIRECTION_UPLOAD'];
      subFilters.push(this.getSubFilterExpression('type', allTypesSupported));
    }

    if (filter.name) {
      subFilters.push(this.getSubFilterExpression('name', [filter.name]));
    }

    return subFilters.join(' AND ');
  }

  private getSubFilterExpression(
      property: keyof TransferRow, values: Array<string|number>) {
    if (!values || !values.length) {
      throw new Error(
          `Filter expression must contain at least one value for property ${
              property}`);
    }
    const apiPropertyName = this.taskPropertyMapping.get(property);
    if (!apiPropertyName) {
      throw new Error(`Filtering is not supported for property: ${property}`);
    }
    const subExpressions = values.map(value => {
      if (typeof value === 'string') {
        value = `"${value}"`;
      }
      return `${apiPropertyName}=${value}`;
    });
    return `(${subExpressions.join(' OR ')})`;
  }

  /**
   * Updates stats calculation criteria on site changes.
   * Initializes criteria object if it wasn't initialized yet.
   * Skips processing in case current and previous criteria are equal.
   */
  private updateStatsCalcCriteriaWithSiteData(site: Site) {
    if (this.statsCalcCriteria$) {
      const type = this.statsCalcCriteria$.value.type;
      this.statsCalcCriteria$.next({site, type});
    } else {
      this.statsCalcCriteria$ = new BehaviorSubject({site});

      // Periodically refresh transfer stats.
      this.stats$ = this.statsCalcCriteria$.pipe(
        distinctUntilChanged((a, b) => compareCriteria(a, b)),
        switchMap(criteria => this.watchStats(criteria)),
        // Do not keep track of refCount as it temporarily becomes 0 then 1
        // when we navigate between the Home and Transfer Monitor pages, which
        // would restart the watchStats observable and make an extra stats API
        // call.
        shareReplay({bufferSize: 1, refCount: false}),
     );
    }
  }

  /**
   * Updates stats calculation criteria on search filter changes.
   * Skips processing if criteria object wasn't initialized yet.
   */
  private updateStatsCalcCriteriaWithFilterData(filter: TransferRowFilter) {
    if (!this.statsCalcCriteria$) {
      return;
    }

    const newCriteria = {
      site: this.statsCalcCriteria$.value.site,
      type: filter.type,
    };
    this.statsCalcCriteria$.next(newCriteria);
  }

  private castTransferType(type: TransferType): ApiTransferTask.TransferTypeEnum[] {
    return castExists(this.typeValueMapping.get(type));
  }
}

function compareCriteria(a: StatsCalcCriteria, b: StatsCalcCriteria): boolean {
  return a.site === b.site && a.type === b.type;
}
