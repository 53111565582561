import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';

import {ErrorService} from '../error_service/error_service';
import {PluginService} from '../plugin/plugin_service';

import {AuthBrowserService} from './auth_browser_service';
import {AuthPluginService} from './auth_plugin_service';
import {AuthService, RELOAD_PAGE} from './auth_service';
import {PluginModeOff} from './plugin_mode_off';
import {PluginModeOn} from './plugin_mode_on';

/** Services and API factories */
@NgModule({
  imports: [],
  providers: [
    {
      provide: AuthService,
      useFactory: authServiceFactory,
      deps: [RELOAD_PAGE, PluginService, ErrorService, HttpClient],
    },
  ],
  declarations: [
    PluginModeOn,
    PluginModeOff,
  ],
  exports: [
    PluginModeOn,
    PluginModeOff,
  ],
})
export class AuthModule {
}

/**
 * Provides an implementation for AuthService for browser users (using google3
 * gapi helpers), or plugin users (using external desktop OAuth flow).
 */
function authServiceFactory(
    reloadPage: () => void,
    pluginService: PluginService,
    errorService: ErrorService,
    http: HttpClient,
) {
  const isIframe = window !== window.top;
  const isPluginCompatible = pluginService.isVersionAtLeast('3.3.0');
  const params =
      [reloadPage, pluginService, errorService, http] as const;
  return isIframe && isPluginCompatible ? new AuthPluginService(...params) :
                                          new AuthBrowserService(...params);
}
