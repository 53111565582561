import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { VideoProtocolService } from 'services/video-protocol.service';


/** Max attempts to retrieve the list of audio tracks. */
const AUDIO_TRACK_RETREIVAL_MAX_ATTEMPTS = 10;

@Injectable({ providedIn: 'root' })
export class AudioTrackService {

  private audioTracksLoadedSubject$ = new Subject<shaka.extern.Track[]>();

  // Observer used to notify when the audio track(s) have been loaded.
  audioTracksLoaded$ = this.audioTracksLoadedSubject$.asObservable();

  constructor(
    private readonly videoProtocolService: VideoProtocolService,
  ) {
  }

  isAudioSyncEnabled(): boolean {
    return this.videoProtocolService.isMobileWebKit();
  }

  async initAudioTracks(shakaPlayer: shaka.Player) {
    let tracks = this.sort(shakaPlayer.getVariantTracks());

    // WebKit (IOS) native player workaround. Poll for the shakaPlayer's variant tracks until the tracks are defined.
    if (this.videoProtocolService.isMobileWebKit()) {
      let retryCount = 1;

      while (tracks.length === 0 && retryCount <= AUDIO_TRACK_RETREIVAL_MAX_ATTEMPTS) {
        await this.delay(1000);

        tracks = this.sort(shakaPlayer.getVariantTracks());
        retryCount++;
      }
    }

    this.audioTracksLoadedSubject$.next(tracks);
  }

  /** Refresh sorted list of tracks, filtering out any that has no audio stream. */
  sort(variantTracks: shaka.extern.Track[]): shaka.extern.Track[] {
    return variantTracks
      .filter(t => t.originalAudioId != null)
      .sort((tA, tB) => this.getTrackNumber(tA) - this.getTrackNumber(tB));
  }

  /** Return the track number from the track's originalAudioId property */
  private getTrackNumber(track: shaka.extern.Track): number {
    const strackNumberString = track.originalAudioId?.replace(/[^0-9]/g, '');
    return Number.parseInt(strackNumberString ?? '');
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}