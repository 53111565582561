/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiObjectConditions } from './api-object-conditions';
import { ApiGcsData } from './api-gcs-data';


/**
 * Information about a single storage transfer job.
 */
export interface ApiCloudTransferJob { 
    gcsData?: ApiGcsData;
    objectConditions?: ApiObjectConditions;
    /**
     * schedule type of the job.
     */
    scheduleType?: ApiCloudTransferJob.ScheduleTypeEnum;
}
export namespace ApiCloudTransferJob {
    export type ScheduleTypeEnum = 'SCHEDULE_TYPE_UNSPECIFIED' | 'ON_DEMAND' | 'AUTO';
    export const ScheduleTypeEnum = {
        SCHEDULE_TYPE_UNSPECIFIED: 'SCHEDULE_TYPE_UNSPECIFIED' as ScheduleTypeEnum,
        ON_DEMAND: 'ON_DEMAND' as ScheduleTypeEnum,
        AUTO: 'AUTO' as ScheduleTypeEnum
    };
}


