import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {ApiError} from '../error_service/api_error';
import {ErrorResponse, isErrorResponse} from '../error_service/error_response';
import {ErrorService, RetryConfig} from '../error_service/error_service';
import {StatusCode} from '../error_service/status_code';

import {Asset} from './asset_service';

/**
 * Simple regex to match availabilityStartTime property with ISO 8601 date
 * value in mpd manifest, i.e.
 * `availabilityStartTime="2021-06-08T17:36:08.123+00:00`
 */
const AVAILABILITY_START_TIME_REGEX = /availabilityStartTime="([0-9-:TZ.+ ]+)"/;

/**
 * Simple regex to match publishTime property with ISO 8601 date
 * value in mpd manifest, i.e. `publishTime="2021-03-31T06:40:39.546Z`
 */
const PUBLISH_TIME_REGEX = /publishTime="([0-9-:TZ.+ ]+)"/;

/**
 * Regex to match mediaPresentationDuration property with ISO 8601 duration
 * value in mpd manifest, i.e. `mediaPresentationDuration="PT3H2M10.123S`.
 * This is present in static manifests only.
 */
const PRESENTATION_DURATION =
    /mediaPresentationDuration="PT([0-9]+)H([0-9]+)M([0-9.]+)S"/;

/**
 * Regex to match mpd manifest type, either "static" or "dynamic".
 */
const TYPE = /type="(static|dynamic)"/;

/**
 * Maximum number of cached manifest data items.
 * Map entry size is measured roughly at 200 bytes.
 * Total cache size for 10_000 items is under 2MB.
 */
export const MAX_CACHE_SIZE = 10_000;

/**
 * Provides means for basic MPD manifest parsing.
 */
@Injectable({providedIn: 'root'})
export class ManifestService {
  constructor(
      private readonly http: HttpClient,
      private readonly errorService: ErrorService,
  ) {}

  /**
   * Extract live stream start and duration from the manifest.
   *
   * If retry config is not provided `retryShort` strategy is used. Check
   * `ErrorService` for more details.
   */
  getLiveAssetTimeline(asset: Asset, retryConfig?: RetryConfig):
      Observable<LiveAssetManifestInfo|ErrorResponse> {
    // TODO-HLS: Can the HLS manifest be used here?
    const liveRendition =
        asset.renditions.find(r => r.version === 'LIVE_MAIN_DASH');
    if (!liveRendition) {
      this.errorService.handle(
          new Error(`The asset does not have live rendition: ${asset.name}`));
      return of(new ErrorResponse('No live rendition found'));
    }

    const cached = this.cache.get(asset.name);
    if (cached) return of(cached);

    return this.http.get(liveRendition.url, {responseType: 'text'})
        .pipe(
            retryConfig ? this.errorService.retry(retryConfig) :
                          this.errorService.retryShort(),
            map(manifestContent => {
              const startTimeMatch =
                  manifestContent.match(AVAILABILITY_START_TIME_REGEX)?.[1];
              let startTime = startTimeMatch && Date.parse(startTimeMatch);

              const publishTimeMatch =
                  manifestContent.match(PUBLISH_TIME_REGEX)?.[1];
              const endTime = publishTimeMatch && Date.parse(publishTimeMatch);

              const type = manifestContent.match(TYPE)?.[1] as 'static' |
                  'dynamic' | undefined;

              const presentationDuration =
                  manifestContent.match(PRESENTATION_DURATION);

              // Fallback to event scheduled start if `availabilityStartTime`
              // cannot be found (safety-net, this should not happen).
              if (!startTime && type != null) {
                this.errorService.handle(new Error(
                    `${type} live MPD manifest has no availabilityStartTime: ${
                        asset.name}`));
                startTime = asset.eventStartTime;
              }

              if (startTime) {
                // Use presentationDuration if available to determine the
                // duration of a stream (only available on static manifests),
                // otherwise calculate it based on the publish and start times.
                let durationInSeconds = 0;
                if (presentationDuration) {
                  const [, hours, minutes, seconds] = presentationDuration;
                  durationInSeconds = Number(hours) * 3600 +
                      Number(minutes) * 60 + Number(seconds);
                } else if (endTime) {
                  durationInSeconds = (endTime - startTime) / 1000;
                }

                if (!Number.isNaN(durationInSeconds)) {
                  return {
                    start: new Date(startTime),
                    durationInSeconds,
                    dynamic: type === 'dynamic',
                  };
                }
              }
              this.errorService.handle(
                  `Failed to parse live manifest: ${liveRendition.url}`);
              return new ErrorResponse('Failed to parse live manifest');
            }),
            tap(manifestInfo => {
              // Do not cache dynamic manifests because they may change.
              if (isErrorResponse(manifestInfo) || manifestInfo.dynamic) return;

              if (this.cache.size >= MAX_CACHE_SIZE) {
                this.cache.clear();
              }
              this.cache.set(asset.name, manifestInfo);
            }),
            catchError((error: unknown) => {
              if (error instanceof ApiError &&
                  error.status === StatusCode.NOT_FOUND) {
                return of(new ErrorResponse(
                    'Live manifest is not found', StatusCode.NOT_FOUND));
              }

              this.errorService.handle(error);
              return of(new ErrorResponse('Failed to load live manifest'));
            }),
        );
  }

  private readonly cache = new Map<string, LiveAssetManifestInfo>();
}

/** Actual start time and duration of a live asset based on the manifest. */
export interface LiveAssetManifestInfo {
  /**
   * Date/time when the live stream was started based on its
   * `availabilityStartTime`.
   */
  start: Date;
  /** Live stream duration. */
  durationInSeconds: number;
  /** Whether the manifest was dynamic (live) or static (VoD). */
  dynamic: boolean;
}
