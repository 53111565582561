<button
    *ngIf="(actionInProgress$ | async) == null; else loadingButton"
    mat-stroked-button
    disableRipple
    class="comp-reel-button"
    [matMenuTriggerFor]="menuBulkActions">
  <span class="bulk-action-text">Bulk Action</span>
  <div class="action-button-inner-content">
    <div class="bulk-action-menu-button">
      <mat-icon>more_vert</mat-icon>
      <span>Bulk</span>
    </div>

    <div class="size-container" *ngIf="multiSelection.size > 0">
      <span class="size-label">{{multiSelection.size}}</span>
      <button
          (click)="multiSelection.clear(); $event.stopPropagation()"
          mat-icon-button
          aria-label="Clear clips selection"
          mam-firebase-ga="Clear clips selection"
          class="gmat-button">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <div class="multi-selection-size" *ngIf="multiSelection.size > 0">
    {{multiSelection.size}}
    <button
        (click)="multiSelection.clear(); $event.stopPropagation()"
        mat-icon-button
        aria-label="Clear clips selection"
        mam-firebase-ga="Clear clips selection"
        class="gmat-button">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</button>
<ng-template #loadingButton>
  <button
      disabled
      mat-stroked-button
      class="comp-reel-button">
    {{actionInProgress$ | async}} in progress...
  </button>
</ng-template>

<mat-menu
    #menuBulkActions="matMenu"
    class="bulk-options" backdropClass="bulk-action-popup-center">
  <div
      *ngFor="let action of bulkActions"
      matTooltipPosition="left"
      [matTooltip]="getTooltip(action)">
    <button
        *ngIf="!action.hidden"
        mat-menu-item
        (click)="execute(action)"
        class="bulk-action__{{action?.className || 'button'}}"
        [disabled]="!!getDisabledMessage(action)">
      <mat-icon class="material-icons-outlined">{{action.icon}}</mat-icon>
      <span>{{action.getLabel()}}</span>
    </button>
  </div>
</mat-menu>
