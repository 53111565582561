<!-- fieldsChanged$ is mentioned to trigger change detection on emission -->
<ng-container *ngIf="{
    isEditing: metadataService.isEditing$ | async,
    fieldsChanged: metadataService.fieldsChanged$ | async
  } as tplState">
  <form [formGroup]="metadataService.metadataFormGroup">
    <!-- List of metadata fields -->
    <ng-container formArrayName="fields" *ngIf="metadataService.fields.length">
      <ng-container
          *ngFor="let control of metadataService.fieldFormArray.controls; let index=index;">
        <ng-container
            *ngIf="metadataService.fields[index].property as property">
          <!-- Array field -->
          <ng-container *ngIf="property.isArray; else nonArrayField">
            <ng-container [formArrayName]="index">
              <!-- Readonly mode for an array field. -->
              <ng-container *ngIf="!tplState.isEditing; else editArray">
                <mat-form-field
                    class="metadata-field"
                    [class.invalid]="control.invalid">
                  <mat-label [class.mixed-content]="isMixed(index)">
                    {{getLabel(index)}}
                  </mat-label>
                  <div
                      class="formatted-display-value"
                      [attr.title]="control.value">
                    <!-- Fake input for mat-form-field to work correctly -->
                    <input hidden matInput [value]="control.value" />
                    <ng-container
                        *ngTemplateOutlet="formattedValueTemplate; context: {$implicit: getDisplayText(control.value, property.type, true)}">
                    </ng-container>
                  </div>
                </mat-form-field>
              </ng-container>
              <!-- Edit mode for an array field. -->
              <ng-template #editArray>
                <fieldset
                    class="item-editor-container"
                    [class.mixed-content]="isMixed(index)"
                    [class.invalid]="control.invalid && control.touched">
                    <legend class="item-editor-label">
                      {{getLabel(index)}}
                    </legend>
                  <div
                      class="item-editor-entry"
                      *ngFor="let childControl of assertFormArray(control, metadataService.fields[index]).controls;let childIndex=index">
                    <mat-form-field
                        class="editable metadata-field"
                        >
                      <!-- TODO: Reuse control templates between array and single values -->
                      <textarea
                          #textareaRef
                          (click)="focusGameLogsSearchInput()"
                          (keydown)="handleKeyPress($event)"
                          *ngIf="!property.options; else optionSelector"
                          aria-label="Array field entry"
                          #formArrayItem
                          [formControlName]="childIndex"
                          matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="16">
                      </textarea>
                      <ng-template #optionSelector>
                        <mat-select
                            class="in-array-dropdown"
                            [formControlName]="childIndex"
                            [placeholder]="'Choose a value'">
                          <div class="scrollable-options">
                            <mat-option
                                class="metadata-item"
                                *ngFor="let option of property.options"
                                [value]="option">
                              {{option}}
                            </mat-option>
                          </div>
                        </mat-select>
                      </ng-template>
                    </mat-form-field>
                    <button
                        *ngIf="!(metadataService.selectedSchema.value && metadataService.selectedSchema.value.title === 'LOGGER' && property.id ==='GameLogs');"
                        type="button"
                        class="remove-item-button"
                        mat-icon-button
                        aria-label="Remove item"
                        (click)="metadataService.removeArrayItem(index, childIndex)"
                        mam-firebase-ga="Remove array item from a metadata field">
                      <mat-icon inline="true">clear</mat-icon>
                    </button>
                  </div>
                  <button
                     *ngIf="!(metadataService.selectedSchema.value && metadataService.selectedSchema.value.title === 'LOGGER' && property.id === 'GameLogs');"
                      type="button"
                      class="add-item-button"
                      mat-button
                      (click)="metadataService.addArrayItem(index)"
                      mam-firebase-ga="Add array item to a metadata field">
                    <mat-icon>add</mat-icon>Add item
                  </button>
                </fieldset>
              </ng-template>
            </ng-container>
          </ng-container>

          <!-- Non-array field -->
          <ng-template #nonArrayField>
            <ng-container [ngSwitch]="property.type">
              <!-- TODO: Improve support of boolean, number/integer, and array fields -->
              <ng-container *ngSwitchCase="PropertyType.DATE_TIME">
                <mat-form-field

                    class="metadata-field"
                    [class.editable]="tplState.isEditing">
                  <mat-label [class.mixed-content]="isMixed(index)">
                    {{getLabel(index)}}
                  </mat-label>
                  <!-- TODO: Improve editing experience of datetime fields -->
                  <div
                      class="formatted-display-value"
                      [attr.title]="control.value"
                      *ngIf="!tplState.isEditing; else dateEdit">
                    <!-- Fake input for mat-form-field to work correctly. -->
                    <input
                        hidden
                        matInput
                        [formControlName]="index"
                        [value]="control.value" />
                    <span>{{getDisplayText(control.value,PropertyType.DATE_TIME)}}</span>
                  </div>
                  <ng-template #dateEdit>
                    <textarea
                        [formControlName]="index"
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="16"
                        placeholder="No time set"></textarea>
                  </ng-template>
                </mat-form-field>
              </ng-container>
              <!-- TODO: Extract to a separate component -->
              <ng-container *ngSwitchDefault>
                <mat-form-field

                    class="metadata-field"
                    [class.editable]="tplState.isEditing">
                  <mat-label [class.mixed-content]="isMixed(index)">
                    {{getLabel(index)}}
                  </mat-label>
                  <div class="formatted-display-value"
                      [attr.title]="control.value"
                      *ngIf="!tplState.isEditing; else defaultEdit">
                    <!-- Fake input for mat-form-field to work correctly. -->
                    <input
                        hidden
                        matInput
                        [formControlName]="index"
                        [value]="control.value" />
                    <ng-container
                        *ngTemplateOutlet="formattedValueTemplate; context: {$implicit: getDisplayText(control.value, property.type)}">
                    </ng-container>
                  </div>
                  <ng-template #defaultEdit>
                    <!-- Values without enumeration (no dropdown) -->
                    <ng-container
                        *ngIf="!property.options || !tplState.isEditing; else optionSelector">
                      <textarea
                          [formControlName]="index"
                          matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="16"
                          placeholder="No value">
                      </textarea>
                    </ng-container>
                    <!-- Values with enumeration (dropdown) -->
                    <!-- TODO: Reuse control templates between array and single values -->
                    <ng-template #optionSelector>
                      <mat-select
                          [formControlName]="index"
                          [placeholder]="'Choose a value'">
                        <div class="scrollable-options">
                          <mat-option
                              class="metadata-item"
                              *ngFor="let option of property.options"
                              [value]="option">
                            {{option}}
                          </mat-option>
                        </div>
                      </mat-select>
                    </ng-template>
                  </ng-template>
                </mat-form-field>
              </ng-container>
            </ng-container>
          </ng-template>

          <!-- Template for formatted values, may contain a web link.-->
          <ng-template #formattedValueTemplate let-formattedValue>
            <a
                *ngIf="isWebLink(formattedValue); else notWebLink"
                [href]="formattedValue"
                target="_blank">
              <span>{{formattedValue}}</span>
            </a>
            <ng-template #notWebLink>
              <span>{{formattedValue}}</span>
            </ng-template>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </form>
</ng-container>
