import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormArray} from '@angular/forms';
import {DateTime} from 'luxon';

import {assertArray, assertString, assertTruthy, checkExhaustive} from '../asserts/asserts';
import {PropertyType} from '../services/schema_api_service';

import {MetadataField, MetadataService} from './metadata_service';


/**
 * Component responsible for showing/editing metadata properties. Tightly
 * coupled with MetadataService from `./metadata_service`.
 */
@Component({
  selector: 'mam-metadata-fields',
  templateUrl: './metadata_fields.ng.html',
  styleUrls: ['./metadata_fields.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetadataFields implements AfterViewInit {
  @ViewChild('textareaRef') textareaRefEl!: ElementRef;
  @Output() readonly createLogs = new EventEmitter<void>();
  readonly PropertyType = PropertyType;

  constructor(
      readonly metadataService: MetadataService,
  ) {}

  ngAfterViewInit() {
    setTimeout(()=> this.focusGameLogsSearchInput(), 10 );
  }
  focusGameLogsSearchInput() : void  {
    if(this.metadataService.selectedSchema.value && this.metadataService.selectedSchema.value.title == 'LOGGER')
    this.textareaRefEl?.nativeElement.focus();
  }
  handleKeyPress(event: KeyboardEvent) : void {
    if (this.metadataService.selectedSchema.value && this.metadataService.selectedSchema.value.title == 'LOGGER' && event.key === 'Enter') {
      // Prevent the default behavior of the enter key (moving to the next line)
      event.preventDefault();
      // Calling the below function to create the Play by Play feed log
      this.createLogs.emit();
    }
  }

  getLabel(fieldIndex: number) {
    const {property} = this.metadataService.fields[fieldIndex];
    let title = property.title || property.id;
    // We do our own `required` mark as we don't use native required validator.
    const schemaObj = this.metadataService.selectedSchema.value;
      if(schemaObj && schemaObj.title == "LOGGER" && property.title=="Game Logs"){
        title = title=== "Game Logs"? "Game Logs *" : title;
      }
    const requiredMark = property.required ? '*' : '';
    const mixedContentMark = this.isMixed(fieldIndex) ? '[mixed content]' : '';

    return `${title} ${requiredMark} ${mixedContentMark} `;
  }

  /**
   * Indicates if the given metadata field contains mixed content (different
   * assets having different values for it). Used for bulk edit.
   */
  isMixed(fieldIndex: number) {
    const field = this.metadataService.fields[fieldIndex];
    const control = this.metadataService.fieldFormArray.controls[fieldIndex];
    // Remove mix content indicator if the value was changed by the user.
    return field.isMixed && !control.dirty;
  }

  assertFormArray(control: AbstractControl, field: MetadataField) {
    assertTruthy(
        control instanceof UntypedFormArray,
        `Fields of type 'array' must be attached to FormArray controls, id: ${
            field.property.id}`);
    return control;
  }

  isWebLink(value: unknown) {
    if (typeof value !== 'string') return false;
    if (value.includes('\n')) return false;
    return value.startsWith('http://') || value.startsWith('https://');
  }

  getDisplayText(value: unknown, type: PropertyType, isArray = false): string {
    if (value == null || value === '') return '';

    if (isArray) {
      assertArray(value, 'MetadataPanel.getDisplayText: expected an array');
      const formatted =
          value.map(itemValue => this.getDisplayText(itemValue, type));
      if ([PropertyType.BOOLEAN, PropertyType.NUMBER].includes(type)) {
        return formatted.join(', ');
      }
      return formatted.join('\n');
    }

    switch (type) {
      case PropertyType.NUMBER:
      case PropertyType.INTEGER:
      case PropertyType.BOOLEAN:
      case PropertyType.STRING:
        return String(value);
      case PropertyType.DATE:
      case PropertyType.DATE_TIME:
        return this.getDateDisplayText(value, type);
      default:
        checkExhaustive(type);
    }
  }

  /**
   * Formats date and date-time properties for display.
   *
   * Example of date: `April 13, 2015`
   *
   * Example of date-time: `April 13, 2015, 17:00:08 PDT`
   */
  private getDateDisplayText(
      value: unknown, type: PropertyType.DATE|PropertyType.DATE_TIME) {
    assertString(value, 'MetadataPanel.getDateDisplayText: Incorrect date');
    const date = DateTime.fromISO(value);
    if (!date.isValid) return 'Invalid date';
    if (type === PropertyType.DATE_TIME) {
      // e.g. "April 13, 2015, 17:00:08 PDT"
      return date.toFormat('MMMM d, y, HH:mm:ss ZZZZ');
    }
    // e.g. "April 13, 2015"
    return date.toFormat('MMMM d, y');
  }
}
