<section class="main-section" #mainSection *ngIf="{
  isFullScreen: playerFullscreenService.isFullScreen$ | async,
  isControlsShown: fullPlayer?.playerDetailsService?.isControlsShown$ | async,
} as vars"
  [class.is-full-screen]="vars.isFullScreen">
  <mat-toolbar *ngIf="!isSharedVideo() && vars.isControlsShown"
  [class.is-full-screen-details]="vars.isFullScreen && !fullPlayer.isClipCreation"
  [class.hidden]="vars.isFullScreen && fullPlayer.isClipCreation">
    <div class="left">
    </div>
    <div class="center">
      <div class="navigation" [hidden]="cameraGridShown">
        <button
            (click)="detailsNavigation.previous()"
            [disabled]="(detailsNavigation.canGoPrevious$ | async) !== true"
            mam-firebase-ga="Navigate to previous asset"
            [mam-firebase-resource]="asset?.name"
            mat-icon-button
            aria-label="Previous"
            class="gmat-button">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button
            (click)="detailsNavigation.next()"
            [disabled]="(detailsNavigation.canGoNext$ | async) !== true"
            mam-firebase-ga="Navigate to next asset"
            [mam-firebase-resource]="asset?.name"
            mat-icon-button
            aria-label="Next"
            class="gmat-button">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <div class="right">
      <div>
        <ng-container *featureFlagOn="'use-multi-camera-view'">
          <!-- Hide camera view buttons for VODs and any clips -->
          <ng-container *ngIf="asset?.isLive && !asset?.original">
            <button
                [hidden]="cameraGridShown"
                [disabled]="(canShowCameraView$ | async) !== true"
                matTooltip="Show all event cameras"
                mat-icon-button
                aria-label="Show all event cameras"
                class="show-camera-view"
                (click)="toggleMultiCameraView(true)"
                mam-firebase-ga="Show camera grid view"
                [mam-firebase-resource]="asset?.name">
              <mat-icon class="material-icons-outlined">view_module</mat-icon>
            </button>
            <button
                [hidden]="!cameraGridShown"
                matTooltip="Close camera view"
                mat-icon-button
                aria-label="Close camera view"
                class="hide-camera-view"
                (click)="toggleMultiCameraView(false)"
                mam-firebase-ga="Hide camera grid view"
                [mam-firebase-resource]="asset?.name">
              <mat-icon class="material-icons-outlined">close</mat-icon>
            </button>
          </ng-container>
        </ng-container>
        <mam-asset-extra-actions
            *ngIf="asset"
            [asset]="asset"
            [sharingAdditionalProperties]="getSharingAdditionalProperties()"
            [querySegment]="querySegment"
            [enableFullMenu]="true">
        </mam-asset-extra-actions>
      </div>
    </div>
  </mat-toolbar>

  <mam-player-with-controls
      [hidden]="cameraGridShown"
      [showOverlayControls]="true"
      [showFullScreenDesktopControls]="true"
      [asset]="asset"
      [userQuery]="userQuery"
      [querySegment]="querySegment"
      [keywordResults]="(keywordResults$ | async)!"
      [selectedKeyword]="(stateService.currentSelectedKeyword$ | async)!"
      [disabledMarking]="isSharedVideo() || ((clipCreationInProgress$ | async)!)"
      [disabledAddClip]="(disabledAddClip$ | async)!"
      (playerError)="onPlayerError($event)"
      (playerUpdate)="onPlayerUpdate($event)"
      (addClipClick)="openAddClipDialog($event)"
      (shortcutTriggered)="handleShortcutEvent($event)"
      (markingChanged)="onMarkingChanged($event)">
  </mam-player-with-controls>

  <div class="camera-view" *ngIf="cameraGridShown">
    <mam-multi-camera-view
        [initialAssets]="(cameraViewAssets$ | async)!"
        (closePanel)="toggleMultiCameraView(false)">
    </mam-multi-camera-view>
  </div>
</section>
