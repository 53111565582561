import {ApiSuggestQueriesResponseQuerySuggestion} from 'api/ias/model/api-suggest-queries-response-query-suggestion';
import {RequiredRecursively} from 'services/utils_service';

/** UI model representing a single search query suggestion  */
export class QuerySuggestion implements
    RequiredRecursively<ApiSuggestQueriesResponseQuerySuggestion> {
  searchOperator = this.apiSuggestion.searchOperator ?? '';
  searchOperatorSuggestion =
      this.apiSuggestion.searchOperatorSuggestion ?? false;
  text = this.apiSuggestion.text ?? '';

  constructor(private apiSuggestion: ApiSuggestQueriesResponseQuerySuggestion) {
  }
}
