import {ChangeDetectionStrategy, Component} from '@angular/core';

import { PlayerFullscreenService } from 'services/player_fullscreen_service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,selector: 'mam-fullscreen-outlet',
  templateUrl: './fullscreen-outlet.ng.html',
  styleUrls: ['./fullscreen-outlet.scss'],
})
export class FullscreenOutlet {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(public readonly playerFullscreenService: PlayerFullscreenService) { }
}
