import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, firstValueFrom, Observable, of, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';

import {isErrorResponse} from '../error_service/error_response';

import {SearchMode, SearchService, SearchType} from './search_service';
import {SearchSuggestionsHistory, SuggestionStorageType} from './search_suggestions_history';

export {SearchMode, SearchType} from './search_service';

/** Serves assets */
@Injectable({providedIn: 'root'})
export class SearchInputService {
  /** Emits on each input change (single character or full text content). */
  readonly partialQuery$ = new ReplaySubject<string>(1);

  /** Represents the search chips in the search input component. */
  readonly chips$ = new ReplaySubject<string[]>(1);

  /** Controls if the search is for segments or full videos. */
  readonly searchMode$ = new ReplaySubject<SearchMode>(1);

  /** Controls if the search is for live or VOD assets. */
  readonly searchType$ = new ReplaySubject<SearchType>(1);

  /**
   * Emits when any part of the search query (chips, search mode or asset type)
   * changes.
   */
  readonly searchQuery$: Observable<SearchQuery> = this.getSearchQuery();

  /** List of suggestions based on current search state. */
  readonly suggestions$: Observable<Suggestion[]>;
  public readonly currentSearchSuggestions$:BehaviorSubject<Suggestion[]> = new BehaviorSubject<Suggestion[]>([]);
  /* List from search history to show when there is no search input */
  public readonly suggestionHistory$: BehaviorSubject<Suggestion[]> = new BehaviorSubject<Suggestion[]>([]);

  constructor(
      private readonly searchService: SearchService,
      private readonly suggestionHistory: SearchSuggestionsHistory,
  ) {
    this.suggestions$ = this.getSuggestions();

    //Updates public observable with complete search history list
    this.suggestionHistory.all$.subscribe((history:string[])=>{
      const suggestionList = history.map((historyItem:string)=>{
        return { text: historyItem, isFromHistory:true } as Suggestion;
      });
      this.suggestionHistory$.next(suggestionList);
    });
    // Update list of recent cached queries when chips or searchMode changes.
    // The list of chips is always empty when searchMode changes.
    combineLatest([
      this.chips$, this.searchMode$, this.searchType$
    ]).subscribe(([chips, searchMode, searchType]) => {
      const suggestionType =
          this.getSuggestionStorageType(searchMode, searchType);
      this.suggestionHistory.addAll(chips, suggestionType);
    });
  }

  /**
   * Adds implicit parentheses in a user query that includes a label, for
   * instance `label: some values` will become `label: (some values)`.
   */
  async chipify(query: string) {
    // Ignore queries with no colon.
    if (!query.includes(':')) return query;

    // Ignore queries that are already in the expected format with parentheses.
    if (/^[ ]*([^: ]+)[ ]*:[ ]*\(.*\)[ ]*$/.test(query)) return query;

    // This line will change `label: some values` into `label: (some values)`.
    return query.replace(/^([^: ]+)[ ]*:[ ]*(.*)$/, '$1: ($2)');
  }

  async removeFromHistory(suggestion: Suggestion) {
    const searchSettings = await Promise.all(
        [firstValueFrom(this.searchMode$), firstValueFrom(this.searchType$)]);
    const [searchMode, searchType] = searchSettings;

    const suggestionType =
        this.getSuggestionStorageType(searchMode, searchType);
    this.suggestionHistory.remove(suggestion.text, suggestionType);
  }

  private lastSuggestionRequestTime = -1;

  /**
   * Emits when the input is changed, or when a history suggestion is added or
   * removed from memory.
   */
  private getSuggestions(): Observable<Suggestion[]> {
    // Observe every input change.
    const prefix$ = this.partialQuery$.pipe(distinctUntilChanged());

    // Fetch matching suggestions from the API.
    const apiSuggestions$ = this.getApiSuggestions(prefix$);

    return combineLatest([apiSuggestions$, this.suggestionHistory.all$])
        .pipe(
            withLatestFrom(prefix$),
            map(([[apiSuggestions, allHistorySuggestions], prefix]) => {
              const result = this.combineSuggestions(apiSuggestions, allHistorySuggestions, prefix );
              this.currentSearchSuggestions$.next(result);
              return result;
            }),
        );

  }

  public addHistorySuggestion(suggestionText:string, type:SuggestionStorageType){
    this.suggestionHistory.add(suggestionText,type);
  }

  /**
   * Given a prefix, gets the 3 most recent history suggestions matching it and
   * concatenate this to a static list of suggestions.
   */
  private combineSuggestions(
      apiSuggestions: Suggestion[], allHistorySuggestions: string[],
      prefix: string) {

    // Add 3 most recent matching historical suggestions first.
    const list: Suggestion[] =
        this.suggestionHistory
            .filterMatching(prefix || '', allHistorySuggestions, 3)
            .map(q => ({text: q, isFromHistory: true}));
    // Then append all api suggestions that are not already there.
    for (const apiSuggestion of apiSuggestions) {
      if (!list.some(s => s.text === apiSuggestion.text)) {
        list.push(apiSuggestion);
      }
    }

    return list;
  }

  private getApiSuggestions(prefix$: Observable<string|null>):
      Observable<Suggestion[]> {
    return combineLatest([
             prefix$,
             this.searchMode$.pipe(distinctUntilChanged()),
             this.searchType$.pipe(distinctUntilChanged()),
           ])
        .pipe(
            // Convert input to suggest API results. `mergeMap` offers better
            // performances than `switchMap` since no request will be canceled.
            mergeMap(([prefix, searchMode, searchType]) => {
              if (prefix == null) return of(null);
              const trim = prefix?.trim();
              const time = Date.now();
              return this.searchService.suggest(trim, searchMode, searchType)
                  .pipe(map(result => {
                    if (isErrorResponse(result)) return null;
                    return {result, time};
                  }));
            }),
            // Because `mergeMap` does not guarantee order of responses, we
            // discard any response that has an older timestamp that the most
            // recent one.
            filter(response => {
              if (!response) return true;
              return response.time >= this.lastSuggestionRequestTime;
            }),
            // Save time of latest call to discard any API call that has been
            // initiated before it.
            tap(response => {
              if (response) {
                this.lastSuggestionRequestTime = response.time;
              }
            }),
            // Extract suggestions from api response.
            map(response => response?.result?.suggestedQueries || []),
        );
  }

  private getSearchQuery(): Observable<SearchQuery> {
    return combineLatest([
             this.chips$,
             this.searchMode$,
             this.searchType$,
           ])
        .pipe(
            map(([chips, searchMode, searchType]) => {
              return {
                query: chips.join(' ').trim(),
                chips,
                searchMode,
                searchType,
              };
            }),
            distinctUntilChanged(
                (a, b) => `${a.query}|${a.searchMode}|${a.searchType}` ===
                    `${b.query}|${b.searchMode}|${b.searchType}`));
  }

  private getSuggestionStorageType(
      searchMode: SearchMode, searchType: SearchType) {
    if (searchType === SearchType.LIVE) {
      return SuggestionStorageType.LIVE_VIDEO_SEARCH;
    }
    if (searchMode === SearchMode.SEGMENT) {
      return SuggestionStorageType.VOD_SEGMENT_SEARCH;
    }
    return SuggestionStorageType.VOD_VIDEO_SEARCH;
  }
}

/** Suggestion offered by the autocomplete list. */
export interface Suggestion {
  /** Display text of the suggestion */
  text: string;
  /** Whether this suggestion is a label such as `content-type:` */
  searchOperatorSuggestion?: boolean;
  /** Whether this suggestion comes from local history. */
  isFromHistory?: boolean;
}

/** Represents search input query parameters. */
export interface SearchQuery {
  query: string;
  chips: string[];
  searchMode: SearchMode;
  searchType: SearchType;
}
