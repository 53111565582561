<ng-container
    *ngIf="transferService.transferSelectedSite$ | async as transferSelectedSite">
  <button
      mat-stroked-button
      mam-firebase-ga="Open site picker"
      [mam-firebase-resource]="transferSelectedSite.name"
      [matMenuTriggerFor]="sitesMenu"
      class="site-select">
      <mat-icon class="domain-icon-transfer-monitor">domain</mat-icon>
      <span>{{transferSelectedSite.siteId | uppercase}}</span>
      <mat-icon iconPositionEnd class="arrow">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #sitesMenu="matMenu">
    <button
        *ngFor="let site of selectableSites$ | async"
        mat-menu-item
        (click)="transferService.selectTransferSite(site)"
        mam-firebase-ga="Select a site"
        [mam-firebase-resource]="site.name">
      <mat-icon>domain</mat-icon>
      <span>{{site.siteId | uppercase}}</span>
    </button>
  </mat-menu>
</ng-container>

<div class="summary"
    *ngIf="transferService.stats$ | async as stats">
  <div class="block" title="Active transfers">
    <h3>Active transfers</h3>
    <div>{{stats.activeCount}}</div>
    <span class="monitor-popup">Active Transfers</span>
  </div>
  <hr>
  <div class="block" title="Transfer volume in last 24 hours">
    <h3>Transfer volume (24 hrs)</h3>
    <div>{{stats.volume | bytes}}</div>
    <span class="monitor-popup">Transfer Volume (24 hrs)</span>
  </div>
  <hr>
  <div class="block" title="Failed transfers in last 24 hours">
    <h3>Failed transfers (24 hrs)</h3>
    <div>{{stats.failedCount}}</div>
    <span class="monitor-popup-right">Failed Transfers (24 hrs)</span>
  </div>
  <hr>
  <div class="block" title="Number of watch folders">
    <h3>Number of watch folders</h3>
    <div>{{stats.watchFoldersCount}}</div>
    <span class="monitor-popup-right">Number of Watch Folders</span>
  </div>
</div>
<mam-transfer-table
    [rows]="(searchResponse$ | async)?.rows || []"
    [pageIndex]="(currentPage$ | async)!.pageIndex"
    [pageSize]="(currentPage$ | async)!.pageSize"
    [totalSize]="(searchResponse$ | async)?.totalSize || 0"
    [activeSort]="currentSort"
    [activeFilter]="currentFilter"
    (page)="currentPage$.next($event)"
    (retry)="retryTask($event)"
    (sort)="onSort($event)"
    (filter)="onFilter($event)">
</mam-transfer-table>
