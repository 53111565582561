import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FolderItemRef } from 'firebase/clip_bins_folders.service';
import { DisplayMode } from 'services/vod_search_service';
import { ClipBinFolderDeleteDialog } from 'shared/clipbin_folder_delete_dialog/clipbin_folder_delete_dialog';
import { ClipBinFolderUpdateDialog } from 'shared/clipbin_folder_update_dialog/clipbin_folder_update_dialog';

@Component({
  selector: 'mam-clip-bin-folder-preview',
  styleUrl: './folders-preview.component.scss',
  templateUrl: './folders-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClipBinFoldersPreview {

  @Input() isAdmin: boolean = false;
  @Input() userEmail:string = '';
  @Input() showAllFolders:boolean = false;
  @Input() folder!: FolderItemRef;
	@Input() viewMode: DisplayMode = DisplayMode.GRID;

  constructor(
    private dialog: MatDialog
  ) {

  }


  openRenameFolder() {
		this.dialog.open(ClipBinFolderUpdateDialog, {
			...ClipBinFolderUpdateDialog.dialogOptions,
			data: this.folder,
		});
	}

	openDeleteFolder() {
		this.dialog.open(ClipBinFolderDeleteDialog, {
			...ClipBinFolderDeleteDialog.dialogOptions,
			data: this.folder,
		});
	}

}
