import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AssetService} from '../services/asset_service';
import {MetadataSchema} from '../services/schema_api_service';
import {SnackBarService} from '../services/snackbar_service';

/**
 * A dialog to allow user to sync metadata schemas.
 */
@Component({
  selector: 'mam-sync-metadata-dialog',
  templateUrl: './sync_metadata_dialog.ng.html',
  styleUrls: ['./sync_metadata_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SyncMetadataDialog implements OnInit, OnDestroy {
  static getDialogOptions(data: SyncMetadataDialogInputData):
      MatDialogConfig<SyncMetadataDialogInputData> {
    return {
      data,
      width: 'auto',
    };
  }

  /** The Hard coded Metadata Key. */
  readonly FOX_SPORTS_EVENT_URI = 'FoxSportsEventUri';

  /** Dropdown selection. */
  readonly selectedSchema = new UntypedFormControl(null, [Validators.required]);

  /** Metadata value input. */
  readonly metadataValue = new UntypedFormControl('', [Validators.required]);

  /** Available schemas. */
  readonly schemas$ = new BehaviorSubject<MetadataSchema[]>([]);

  /** Display or not the metadata input. */
  readonly displayValueInput$ = new BehaviorSubject(false);

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: SyncMetadataDialogInputData,
      readonly dialogRef: MatDialogRef<SyncMetadataDialog>,
      private readonly assetService: AssetService,
      private readonly snackBar: SnackBarService,
  ) {}

  ngOnInit() {
    this.assetService.listFilteredSchemas('asset_or_undefined')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((schemas) => {
          if (schemas == null) {
            this.snackBar.error('Failed to load schemas');
            return;
          }
          schemas = schemas.filter(
              schema => schema.properties[this.FOX_SPORTS_EVENT_URI]);
          this.schemas$.next(schemas);
        });

    this.selectedSchema.valueChanges.subscribe(() => {
      this.displayValueInput$.next(true);
    });
  }

  sync() {
    const output: SyncMetadataDialogOutputData = {
      schemaName: this.selectedSchema.value.name,
      foxSportsEventUri: this.metadataValue.value,
    };

    this.dialogRef.close(output);
  }

  formatMetadataTitle() {
    return this.selectedSchema.value?.properties[this.FOX_SPORTS_EVENT_URI]
               ?.title ||
        'FOX Sports Event URI';
  }

  isValid() {
    return this.selectedSchema.valid && this.metadataValue.valid;
  }

  ngOnDestroy() {
    // Unsubscribes all pending subscriptions.
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /** Handles on-destroy Subject, used for unsubscribing. */
  private readonly destroyed$ = new ReplaySubject<void>(1);
}

/** Input data to this dialog */
export interface SyncMetadataDialogInputData {
  /** The dialog title of the dialog. */
  title: string;
}

/** Output data to this dialog */
export interface SyncMetadataDialogOutputData {
  /** The schema name of the selected schema. */
  schemaName: string;
  /** The foxSportsEventUri from the input. */
  foxSportsEventUri: string;
}
