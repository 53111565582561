import {ChangeDetectionStrategy, Component, HostBinding, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {Metadata} from 'models';

import {isErrorResponse} from '../error_service/error_response';
import {AssetService} from '../services/asset_service';
import {DisplaySegment, PlayFeedService} from '../services/play_feed_service';
import {MetadataSchema} from '../services/schema_api_service';
import {SnackBarService} from '../services/snackbar_service';

import {MetadataService, SupportItemTypes} from './metadata_service';

/**
 * Play Description dialog
 */
@Component({
  selector: 'mam-play-description-dialog',
  templateUrl: './play_description_dialog.ng.html',
  styleUrls: ['./play_description_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayDescriptionDialog implements OnDestroy {
  static getDialogOptions(data: PlayDescriptionData):
      MatDialogConfig<PlayDescriptionData> {
    return {
      data,
      autoFocus: false,
      disableClose: true,
      width: 'min(550px, 80vw)',
      minWidth: 'unset',
      maxWidth: 'unset',
    };
  }

  @HostBinding('class.loading')
  get isLoading() {
    return this.isLoading$.value;
  }

  readonly schemas$: Observable<MetadataSchema[]>;

  readonly isCreating = this.data.action === 'create';

  readonly segment: DisplaySegment;

  readonly isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: PlayDescriptionData,
      readonly metadataService: MetadataService,
      private readonly playFeedService: PlayFeedService,
      private readonly snackBar: SnackBarService,
      private readonly dialogRef: MatDialogRef<PlayDescriptionDialog>,
      assetService: AssetService,
  ) {
    // Based on the mode (display or create) take segment from payload or build
    // an empty one to pass it to MetadataService for metadata editing.
    this.segment = this.data.action === 'display' ?
        this.data.segment :
        this.createEmptySegment(this.data);

    // Send segment metadata to metadata service + metadata_fields
    this.metadataService.setItems(
        {items: [this.segment], type: SupportItemTypes.SEGMENT});


    // When creating a new segment, start in edit mode.
    if (this.data.action === 'create') {
      this.metadataService.edit();

        const schemaObj = this.metadataService.selectedSchema.value;
        if(schemaObj && schemaObj.title == "LOGGER")
        this.metadataService.addArrayItem(0);
    }

    // Provide schemas for schema selector component.
    this.schemas$ = assetService
                        .listFilteredSchemas(
                            'annotated_segment', this.segment.metadata.schema)
                        .pipe(tap(schemas => {
                          if (!schemas) {
                            this.snackBar.error('Failed to load schemas');
                          }
                        }));

    //subscribe to keyboard event
    this.dialogRef.keydownEvents()?.subscribe((e:KeyboardEvent) => {
      if (e.key === 'Escape') {
        this.dialogRef.close();
      }
    });
  }

  create() {
    if (!this.metadataService.isValid()) return;

    const schema = this.metadataService.selectedSchema.value;
    const metadata = this.metadataService.getMergedMetadata(
        {item: this.segment, type: SupportItemTypes.SEGMENT});
    if (!schema || !metadata) {
      this.snackBar.error('Invalid metadata for play description.');
      return;
    }

    this.segment.metadata = new Metadata({
      schema: schema.name,
      jsonMetadata: metadata,
    });

    this.isLoading$.next(true);
    this.playFeedService.create(this.segment, schema)
        .subscribe(response => {
          if (isErrorResponse(response)) {
            // Prevent rapid retry clicks by keeping the buttons disabled for
            // an extra second.
            setTimeout(() => {
              this.isLoading$.next(false);
            }, 1000);

            this.snackBar.error({
              message: 'Failed to create play description',
              details: response.message,
              doNotLog: true,
            });
            return;
          }

          this.snackBar.message('Play description created successfully');
          this.dialogRef.close();
        });
  }

  update() {
    if (!this.metadataService.isValid()) return;

    const schema = this.metadataService.selectedSchema.value;
    const metadata = this.metadataService.getMergedMetadata(
        {item: this.segment, type: SupportItemTypes.SEGMENT});

    if (!schema || !metadata) {
      this.snackBar.error('Failed to update play description');
      return;
    }

    this.isLoading$.next(true);
    this.playFeedService.updateMetadata(this.segment.name, schema, metadata)
        .subscribe(response => {
          this.isLoading$.next(false);
          if (isErrorResponse(response)) {
            this.snackBar.error({
              message: 'Failed to update play description',
              details: response.message,
            });
            return;
          }

          this.snackBar.message('Play description updated successfully');
          this.dialogRef.close();
        });
  }

  ngOnDestroy() {
    // Do not report that unsaved changes has been discarded because the dialog
    // can be only closed explicitly by the user.
    this.metadataService.cancel();
    this.metadataService.resetState();
  }

  private createEmptySegment(data: CreateSegmentData) {
    const schema = this.playFeedService.getDefaultSchemaName();

    const segment: DisplaySegment = {
      name: '',
      description: '',
      time: '',
      startOffset: data.startOffset,
      endOffset: data.endOffset,
      metadata: new Metadata({schema}),
    };

    return segment;
  }
}

/** Data for PlayDescriptionDialog */
export type PlayDescriptionData = DisplaySegmentData|CreateSegmentData;

interface DisplaySegmentData {
  segment: DisplaySegment;
  action: 'display';
}

interface CreateSegmentData {
  startOffset: number;
  endOffset: number;
  action: 'create';
}
