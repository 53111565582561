import {ApiCompReelData} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

import {RpcStatus} from './rpc-status';

/** A UI model representing clip bins's comp reel data for a single folder */
export class CompReelData implements RequiredRecursively<ApiCompReelData> {
  id = this.apiData.id ?? '';
  filepath = this.apiData.filepath ?? '';
  createTime = this.apiData.createTime ?? '';
  errorDetails = new RpcStatus(this.apiData.errorDetails ?? {});
  state = this.apiData.state ?? 'COMP_REEL_STATE_UNSPECIFIED';
  updateTime = this.apiData.updateTime ?? '';
  contentCurrent =
      this.apiData.contentCurrent ?? 'COMP_REEL_CONTENT_STATE_UNSPECIFIED';


  constructor(private apiData: ApiCompReelData = {}) {}
}
