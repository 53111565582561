/** Common prefix for keys stored in sessionStorage. */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
export const SESSION_STORAGE_PREFIX = 'mamui_session_';

/**
 * Get environment param from query param or session storage. The result from
 * query param overwrites the existing value in session storage if any.
 */
export function getEnvironmentParam<T extends string>(
    paramName: string, defaultValue: T, url = location.href): T {
  const parsedUrl = new URL(url);
  const urlParamValue = parsedUrl.searchParams.get(paramName);
  const sessionStorageId = `${SESSION_STORAGE_PREFIX}${paramName}`;

  if (urlParamValue != null) {
    window.sessionStorage.setItem(sessionStorageId, urlParamValue);
    return urlParamValue as T;
  }

  const storedValue = window.sessionStorage.getItem(sessionStorageId);
  return storedValue == null ? defaultValue : storedValue as T;
}
