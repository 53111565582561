import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  map,
  merge,
  Observable,
  of,
  ReplaySubject,
  startWith,
  switchMap,
  take,
  takeUntil
} from 'rxjs';

import { AuthService } from 'auth/auth_service';
import { ClipBinsFoldersService, FolderItemRef } from 'firebase/clip_bins_folders.service';
import { FirebaseAnalyticsService } from 'firebase/firebase_analytics_service';
import { BINS_PAGINATION_BREAKPOINTS, DisplayModePagination } from 'landing/landing-helpers.utils';
import { Breakpoint } from 'live/live_landing';
import { Asset, Clip } from 'services/asset_service';
import { Bin, BinSectionContent, BinSectionContentType, BinService, BinWithClips } from 'services/bin.service';
import { ClipbinsOwner } from 'services/bin_api.service';
import { ClipApiService } from 'services/clip_api_service';
import { MediaCacheService } from 'services/media_cache_service';
import { Pagination, PaginationService } from 'services/pagination_service';
import { PaginatorIntl, UNKNOWN_LENGTH } from 'services/paginator-intl';
import { SearchType } from 'services/search_api.service';
import { SearchInputService, Suggestion } from 'services/search_input_service';
import { SearchSuggestionsHistory, SuggestionStorageType } from 'services/search_suggestions_history';
import { SnackBarService } from 'services/snackbar_service';
import { StateService } from 'services/state_service';
import { DisplayMode } from 'services/vod_search_service';
import { DeleteMultipleBinDialog } from 'shared/delete_multiple_bin_dialog/delete_multiple_bin_dialog';
import { ScrubbingService } from 'shared/scrubbing_service';


const DEFAULT_BINS_PAGE_SIZE = 12;
const SEARCH_DEBOUNCE = 500;

type pageResult = {
  assets?: Asset[],
  bins?: Bin[],
  nextPageToken?: string;
};
@Component({
  selector: 'mam-clip-bin-section',
  templateUrl: './clip-bin-section.component.html',
  styleUrl: './clip-bin-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatPaginatorIntl, useClass: PaginatorIntl }],
})
export class ClipBinSection implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
  @ViewChild('searchField') searchField?: MatFormField;
  @ViewChild(MatAutocompleteTrigger) autocomplete?: MatAutocompleteTrigger;
  @ViewChild('scrollableView') scrollableView!: ElementRef<HTMLElement>;

  /* Pagination */
  binsPagination: Pagination<pageResult>;
  clipsPagination: Pagination<pageResult>;
  foldersPagination = {
    pageLimit: 6,
    lastVisible: null as FolderItemRef | null,
    pageIndex: 0,
    totalCount: 0,
  };

  pageIndex$ = new BehaviorSubject(0);
  totalCount = UNKNOWN_LENGTH;

  /* Loading */
  resultsLoading = true;
  resultsLoading$: Observable<number[]> = this.breakpointObserver.observe(['(max-width: 1300px)']).pipe(
    take(1),
    map((result) =>
      result.matches ? Array.from({ length: this.retrievePaginationLimit() }) : Array.from({ length: 5 }),
    ),
  );

  /* SearchResults */
  private searchChanged$: Observable<string | null>;
  searchControl = new FormControl<string | null>('');
  clipbinsOwner$ = new ReplaySubject<ClipbinsOwner>(1);
  pageResults$ = new BehaviorSubject<Bin[] | Clip[] | FolderItemRef[]>([]);

  get binResults() {
    return this.pageResults$.value as Bin[];
  }

  get clipResults() {
    return this.pageResults$.value as Clip[];
  }

  get folderResults() {
    // if ((this.pageResults$.value[0] as FolderItemRef)['queryableName']) {
    return this.pageResults$.value as FolderItemRef[];
    // }
    // return [];
  }

  /** searchMode array of available choices. */
  searchModeOptions = [BinSectionContent.FOLDER, BinSectionContent.BIN, BinSectionContent.CLIP];
  searchModeSelected: BinSectionContentType = BinSectionContent.BIN;
  searchModeSelected$:BehaviorSubject<BinSectionContentType> = new BehaviorSubject<BinSectionContentType>(BinSectionContent.BIN);
  searchModeVisible: BinSectionContentType = BinSectionContent.BIN;
  searchText: string | null = null;

  displayMode = DisplayMode.GRID;
  SEARCH_MODE = BinSectionContent;

  searchModeDisabled = false;

  // Display booleans
  showAllBins: boolean = false;
  // make the toggle disabled when the search mode is clips
  isShowAllBinsDisabled: boolean = false;

  // pagination
  currentPagination$: BehaviorSubject<DisplayModePagination[]> = new BehaviorSubject<DisplayModePagination[]>(
    BINS_PAGINATION_BREAKPOINTS.get(Breakpoint.LARGE) || [],
  );

  folderUrlId: string | null = null;

  protected activeSuggestions$:BehaviorSubject<Suggestion[]> = new BehaviorSubject<Suggestion[]>([]);
  protected _suggestions:Suggestion[] = [];
  protected _suggestionHistory:Suggestion[] = [];

  /** Account data */
  username: string = '';
  protected isAdmin:boolean = false;
  protected userEmail:string = '';

  // persist clipbin multiselection
  itemMultiSelection = new Set<string>();
  lastSelectedIndex: number = 0;

  get allChecked() {
    return (
      this.pageResults$.value.length > 0 &&
      this.itemMultiSelection.size === this.pageResults$.value.length
    );
  }

  get someChecked() {
    return (
      this.pageResults$.value.length > 0 &&
      this.itemMultiSelection.size > 0 &&
      this.itemMultiSelection.size < this.pageResults$.value.length
    );
  }


  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private readonly binService: BinService,
    private readonly clipService: ClipApiService,
    private readonly cdr: ChangeDetectorRef,
    private readonly snackBar: SnackBarService,
    readonly searchInputService: SearchInputService,
    private analyticsService: FirebaseAnalyticsService,
    private authService: AuthService,
    readonly stateService: StateService,
    readonly mediaCache: MediaCacheService,
    readonly scrubbingService: ScrubbingService,
    private readonly paginationService: PaginationService,
    private readonly breakpointObserver: BreakpointObserver,
    protected readonly suggestionHistory: SearchSuggestionsHistory,
    private readonly clipBinFolderService: ClipBinsFoldersService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
    // Init variables
    this.searchInputService.searchType$.next(SearchType.VOD);
    this.searchChanged$ = this.getSearchChanged();
    this.clipbinsOwner$.next(stateService.clipbinsOwner$.value);
    this.binsPagination = this.paginationService.getEmptyPagination(DEFAULT_BINS_PAGE_SIZE);
    this.clipsPagination = this.paginationService.getEmptyPagination(DEFAULT_BINS_PAGE_SIZE);
    this.binService.displayMode$.next(DisplayMode.GRID);

    this.currentPagination$.next(BINS_PAGINATION_BREAKPOINTS.get(Breakpoint.LARGE) || []);

    this.binService.displayMode$.subscribe((mode) => {
      this.displayMode = mode ?? DisplayMode.GRID;
    });

    // Define and listen to the screen breakpoint
    this.listenForScreenBreakpoint();
    // Resets pagination and scrolls top when search value changes or clip bins get updated
    this.listenForSearchAndPageChanges();
    // Get user's name
    const accountUserName = this.authService.getUserName();
    this.username = accountUserName.split(' ')[0] || 'User';

    this.listenToSuggestionsChanges();

    this.clipBinFolderService.lastQueryTotalCount$.pipe(takeUntil(this.destroyed$)).subscribe((count) => {
      this.foldersPagination.totalCount = count;
    });
    this.isAdmin = this.authService.isAdmin;
    this.userEmail = this.authService.getUserEmail();
  }

  ngOnInit() {
    this.updateResults();
    this.listenForNavigationChanges();
  }

  ngAfterViewInit(): void {
    this.registerKeydownListeners();
  }

  updateSearchSuggestions() {
    if (this.searchControl.value) {
      this.suggestionHistory.add(this.searchControl.value, SuggestionStorageType.VOD_SEGMENT_SEARCH);
      this.analyticsService.logSearchEvent('Add search term', { term: this.searchControl.value });
      return;
    }
  }

  listenForNavigationChanges() {
    combineLatest([this.route.url, this.route.queryParams])
    .pipe(takeUntil(this.destroyed$)).subscribe(([url, params]) => {
        this.resultsLoading = true;
        if (url[0]?.path === 'folders') {
          this.folderUrlId = url[1]?.path;
          this.searchModeSelected = BinSectionContent.FOLDER;
          this.resultsLoading = false;
        }

        if (params?.['mySearchMode']) {
          this.searchModeSelected$.next(params['mySearchMode'] as BinSectionContentType);
          this.searchModeSelected = params['mySearchMode'] as BinSectionContentType;
          this.cdr.detectChanges();
        }
  });

  }

  listenForSearchAndPageChanges() {
    this.pageResults$.pipe(takeUntil(this.destroyed$)).subscribe(()=>{
      this.resultsLoading = false;
    });
    merge(this.searchChanged$, this.clipbinsOwner$, this.binService.binsUpdated$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.resetPagination();
        this.updateSearchSuggestions();
        this.activeSuggestions$.next(this.searchText? this._suggestions : this._suggestionHistory);
        this.cdr.markForCheck();
      });

    // Update current page index onPageChange events
    this.pageIndex$.pipe(takeUntil(this.destroyed$)).subscribe((pageIndex) => {

      switch (this.searchModeVisible) {
        case BinSectionContent.CLIP:
          this.clipsPagination.pageIndex = pageIndex;
          this.binsPagination.pageIndex = 0;
          this.foldersPagination.pageIndex = 0;
          break;
        case BinSectionContent.BIN:
          this.binsPagination.pageIndex = pageIndex;
          this.clipsPagination.pageIndex = 0;
          this.foldersPagination.pageIndex = 0;
          break;
        case BinSectionContent.FOLDER:
          this.clipsPagination.pageIndex = 0;
          this.binsPagination.pageIndex = 0;
          break;
      }
    });
  }

  listenToSuggestionsChanges():void{
    //Updates list of suggestions based on the current search input, if there is any
    this.searchInputService.currentSearchSuggestions$.pipe(takeUntil(this.destroyed$)).subscribe((suggestions)=>{
      this._suggestions = suggestions;
      this.activeSuggestions$.next(this._suggestions);
      if(this._suggestionHistory.length < 3){
        this._suggestionHistory = suggestions;
      }
    });
    //Initializes the suggestion history

    //Updates the list of suggestion based on search history
    this.searchInputService.suggestionHistory$.pipe(takeUntil(this.destroyed$)).subscribe((suggestions)=>{
      this._suggestionHistory = suggestions;
    });
  }

  /**
   * Search folders
   */
  searchFolders(searchTerm: string | null) {
    const result = this.clipBinFolderService
      .searchFolders(searchTerm ?? '', this.retrievePaginationLimit() || this.foldersPagination.pageLimit);
      return result;
  }

  /**
   * Search bins
   */
  searchBins(searchTerm?: string, owner: ClipbinsOwner = ClipbinsOwner.USER) {
    return this.binService
      .list({ title: searchTerm, owner }, this.binsPagination.nextPageToken, this.retrievePaginationLimit());
  }

  /**
   * Search clips
   */
  searchClips(searchTerm: string | null) {
    if (!searchTerm) return EMPTY;

    return this.clipService
      .searchClipsByTitle(
        searchTerm, /* query: string */
        this.retrievePaginationLimit(), /* pageSize:number*/
        this.clipsPagination.nextPageToken /* pageToken:string */
      );
  }


  /**
   * Updates status related to pagination calculation
   */
  private updateBinsResults(res: pageResult) {
    const pageLimit = this.retrievePaginationLimit();
    const { bins, nextPageToken } = res;
    this.binsPagination.nextPageToken = nextPageToken;
    this.binsPagination.pageSize = pageLimit;
    const index = this.binsPagination.pageIndex;

    // Save new page results in cache. Avoid duplicates
    this.binsPagination.pagesCache[index] = res;

    // Combine all cached results into one list of assets for details navigation.
    const cachedResults: Bin[] = this.binsPagination.pagesCache.reduce<Bin[]>((segments, result) => {
      segments.push(...(result.bins || []));
      return segments;
    }, []);
    const portionStart = index * pageLimit;
    const portionEnd = portionStart + pageLimit;
    const portion = cachedResults.slice(portionStart, portionEnd);
    this.resultsLoading = false;
    this.pageResults$.next(portion);
    // We reached the last page, calculate the total number of results
    if (!nextPageToken) {
      const pagesCachedCount = this.binsPagination.pagesCache.length;
      const countUntilNow = (pagesCachedCount - 1) * this.binsPagination.pageSize;
      this.binsPagination.totalCount = countUntilNow + (bins?.length || 0);
    }
  }

  private updateClipsResults(res: pageResult) {
    const pageLimit = this.retrievePaginationLimit();
    const clips = res.assets as Clip[];
    const nextPageToken = res.nextPageToken;
    this.clipsPagination.nextPageToken = nextPageToken;
    this.clipsPagination.pageSize = pageLimit;
    const index = this.clipsPagination.pageIndex;

    // Save new page results in cache. Avoid duplicates
    this.clipsPagination.pagesCache[index] = res;

    // Combine all cached results into one list of assets for details navigation.
    const cachedResults: Clip[] = this.clipsPagination.pagesCache.reduce<Clip[]>((segments,result) => {
      segments.push(...result.assets as Clip[]  || []);
      return segments;
    }, []);
    const portionStart = index * pageLimit;
    const portionEnd = portionStart + pageLimit;
    const portion = cachedResults.slice(portionStart, portionEnd);
    this.resultsLoading = false;
    this.pageResults$.next(portion);

    // We reached the last page, calculate the total number of results
    if (!nextPageToken) {
      const pagesCachedCount = this.clipsPagination.pagesCache.length;
      const countUntilNow = (pagesCachedCount - 1) * this.clipsPagination.pageSize;
      this.clipsPagination.totalCount = countUntilNow + (clips?.length || 0);
    }
  }

  private updateFoldersResults(res: FolderItemRef[]) {
    this.resultsLoading = false;
    this.pageResults$.next(res);
    this.foldersPagination.lastVisible = res[res.length - 1];
  }

  private resetPagination() {

    if (this.searchModeSelected == this.searchModeVisible && this.searchText) {
      this.pageResults$.next([]);
    }

    const pageLimit = this.retrievePaginationLimit();
    this.binsPagination = this.paginationService.getEmptyPagination(pageLimit);
    this.clipsPagination = this.paginationService.getEmptyPagination(pageLimit);
    this.clipBinFolderService.getMyFolders();
  }

/**
 * Loads the initial bins list for the current owner and updates the results based on changes.
 */
updateResults() {
  combineLatest([
    this.searchChanged$,
    this.clipbinsOwner$,
    this.pageIndex$,
    this.searchModeSelected$,
    this.binService.binsUpdated$,
    this.currentPagination$,
    this.binService.displayMode$,

  ])
    .pipe(
      switchMap(([title, owner, pageIndex, searchModeSelected,binsUpdated]) => {
        //Set search mode in URL
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("mySearchMode", searchModeSelected);
        const locationUpdate = window.location.pathname + '?' + searchParams.toString();
        window.history.replaceState(null, window.document.title, locationUpdate);

        if(!binsUpdated){
          this.resultsLoading = true;
        }

        let result = new Observable<pageResult | FolderItemRef[] | null>;
        if (searchModeSelected == BinSectionContent.CLIP && this.searchText?.length) {
          const currentPage = of(this.clipsPagination.pagesCache[pageIndex]);
          result = pageIndex < this.clipsPagination.pagesCache.length ? currentPage : this.searchClips(title ?? '');
        } else if (searchModeSelected == BinSectionContent.BIN || (searchModeSelected == BinSectionContent.CLIP && !this.searchText?.length)) {
          const currentPage = of(this.binsPagination.pagesCache[pageIndex]);
          if(binsUpdated?.type && 'DELETE,CREATE'.includes(binsUpdated.type)){
            result = this.searchBins(title ?? '', owner);
          }else{
            result = pageIndex < this.binsPagination.pagesCache.length ? currentPage : this.searchBins(title ?? '', owner);
          }
        } else if (searchModeSelected == BinSectionContent.FOLDER) {
          if (this.folderResults.length) {
            this.foldersPagination.lastVisible = this.folderResults[this.folderResults.length - 1];
            this.foldersPagination.pageIndex === pageIndex ? this.foldersPagination.pageIndex++ : this.foldersPagination.pageIndex;
          }
          result = !title ? this.clipBinFolderService.getMyFolders() : this.searchFolders(title);
        }
        return result;
      }),
      takeUntil(this.destroyed$),
    )
    .subscribe((res) => {
      if (!res) {
        this.snackBar.error(`${this.formatBinSectionContent(this.searchModeSelected)} could not be loaded`);
        this.pageResults$.next([]);
      } else {
        this.processSearchResults(res);
      }
      this.cdr.detectChanges();
    });
}
  private processSearchResults(res: pageResult | FolderItemRef[] | null) {
    let result;
    switch (this.searchModeSelected) {
      case BinSectionContent.CLIP:
        result = res as pageResult;
        if(result.assets){
          this.searchModeVisible = BinSectionContent.CLIP;
          this.updateClipsResults(result);
        }else{
          this.searchModeVisible = BinSectionContent.BIN;
          this.updateBinsResults(result);
        }
        break;
      case BinSectionContent.FOLDER:
        result = res as FolderItemRef[];
        this.searchModeVisible = BinSectionContent.FOLDER;
        this.updateFoldersResults(result);
      break;

      case BinSectionContent.BIN:
        this.searchModeVisible = BinSectionContent.BIN;
        result = res as pageResult;
        this.updateBinsResults(result);
        break;
    }
  }

  /**
   * Retrieves the pagination limit based on the breakpoint pagination options
   * and active display mode.
   */
  retrievePaginationLimit(): number {
    const currentBreakpoint = this.currentPagination$.getValue();
    const activeDisplayMode = currentBreakpoint.find((el) => el.displayMode === this.displayMode);
    if (!activeDisplayMode) return DEFAULT_BINS_PAGE_SIZE;
    return activeDisplayMode?.limit || 0;
  }

  getPlaceholderText() {
    if (!this.searchModeSelected) return '';
    switch (this.searchModeSelected) {
      case BinSectionContent.FOLDER:
        return 'Search folders';
      case BinSectionContent.BIN:
        return 'Search clip bins';
      case BinSectionContent.CLIP:
        return 'Search clips';
    }
  }

  formatBinSectionContent(mode: BinSectionContentType) {
    switch (mode) {
      case BinSectionContent.FOLDER:
        return 'Folders';
      case BinSectionContent.BIN:
        return 'Clip bins';
      case BinSectionContent.CLIP:
        return 'Clips';
    }
  }

  handleSearchModeChange(mode: BinSectionContentType) {
    if(this.searchModeSelected !== mode){
      this.pageIndex$.next(0);
    }
    this.searchModeSelected = mode;
    this.searchModeSelected$.next(mode);
    if (mode !== BinSectionContent.BIN && this.showAllBins) {
      this.toggleShowAllBins(false);
    }

    this.setIsShowAllDisabled();
    this.itemMultiSelection.clear();
  }

  setIsShowAllDisabled() {
    this.isShowAllBinsDisabled = this.searchModeSelected !== BinSectionContent.BIN ? true : false;
  }

  /** Toggle the visualization of the user's clip bins and all clip bins */
  onShowAllBinsChange(event: MatSlideToggleChange) {
    this.toggleShowAllBins(event.checked);
    this.itemMultiSelection.clear();
  }
  /* Handles the toggling of clip bins visualization with or without direct 'show all' event */
  toggleShowAllBins(toggle: boolean | null = null): void {
    toggle == null ?
      (this.showAllBins = !this.showAllBins) :
      (this.showAllBins = !!toggle);

    this.clipbinsOwner$.next(this.showAllBins ? ClipbinsOwner.ALL : ClipbinsOwner.USER);
  }

  /** Toggles between grid and list view */
  toggleViewMode() {
    const nextMode = this.isGrid(this.displayMode) ? DisplayMode.LIST : DisplayMode.GRID;
    this.binService.displayMode$.next(nextMode);
  }

  isGrid(displayMode: DisplayMode) {
    return displayMode === DisplayMode.GRID;
  }

  /**
   * Listens for screen breakpoints and updates the pagination size accordingly.
   * Iterates through the breakpoints in order from largest to smallest.
   * Retrieves the pagination sizes defined for the active breakpoint
   */
  private listenForScreenBreakpoint() {
    this.breakpointObserver
      .observe(Array.from(BINS_PAGINATION_BREAKPOINTS.keys()))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ breakpoints }) => {
        const orderedBreakpoints = Object.keys(breakpoints) as Array<Breakpoint>;
        // go through each breakpoint in order from largest to smallest
        for (const currBreakpoint of orderedBreakpoints) {
          // if the breakpoint is active, then override the pagination sizes that are defined for it
          if (breakpoints[currBreakpoint]) {
            this.currentPagination$.next(BINS_PAGINATION_BREAKPOINTS.get(currBreakpoint) || []);
            this.binsPagination.pageSize = this.retrievePaginationLimit();
            this.clipsPagination.pageSize = this.retrievePaginationLimit();

            this.resetPagination();
          }
        }
        this.cdr.markForCheck();
      });
  }

  private getSearchChanged() {
    return this.searchControl.valueChanges.pipe(
      map((value) => {
        if (!value || !value.length) return null;
        this.searchText = value;
        this.searchInputService.partialQuery$.next(value);
        this.itemMultiSelection.clear();
        this.folderUrlId = null;
        return value;
      }),
      debounceTime(SEARCH_DEBOUNCE),
      startWith(''),
      distinctUntilChanged(),
    );
  }

  onSearchClear() {
    this.resultsLoading = true;
    this.searchControl.reset();
    this.searchText = null;
  }

  onPageChange({ pageIndex }: PageEvent) {
    this.pageIndex$.next(pageIndex);
    this.itemMultiSelection.clear();
  }

  trackBy(index: number, value: FolderItemRef | BinWithClips | Asset | Clip) {
    return (value as BinWithClips | Asset | Clip)?.name ?? (value as FolderItemRef)?.id;
  }

  /** Removes all parentheses. */
  hideParens(suggestion = '') {
    return suggestion.replace(/[()]/g, '');
  }

  getSuggestionText(suggestion: Suggestion | null) {
    return suggestion?.text || '';
  }

  trackSuggestion(index: number, suggestion: Suggestion) {
    return suggestion?.text || '';
  }

  /** Tag words that match the prefix so they can be displayed bold. */
  getWordsStyles(text: string): Array<{ matches: boolean; value: string }> {
    const prefixes = this.searchControl.value?.trim()?.toLowerCase().split(' ') || [];
    return text?.split(' ').map((value) => {
      const matches = prefixes?.some((prefix: string) => prefix && value?.toLowerCase().startsWith(prefix));
      return { matches, value };
    });
  }

  removeFromHistory(suggestion: Suggestion, event?: MouseEvent) {
    event?.stopPropagation();
    this.searchInputService.removeFromHistory(suggestion);
  }

  onOptionSelected(suggestion: Suggestion) {
    if (!suggestion) return;
    this.onEnterPressed();
    this.cdr.detectChanges();
  }

  private registerKeydownListeners() {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        this.onEnterPressed();
      }
    };

    if (!this.searchInput) return;
    this.searchInput.nativeElement.addEventListener('keydown', onKeydown, { capture: true });
    this.destroyed$.subscribe(() => {
      document.removeEventListener('keydown', onKeydown);
    });
  }

  private onEnterPressed() {
    this.searchInput?.nativeElement.blur();
    this.autocomplete?.closePanel();
    this.searchField?._textField.nativeElement.blur();
  }

  /**
   * Toggles manual selection of a clip bin for bulk operation. If the "shift" key
   * is pressed, all clips between the last selected one and the current clicked
   * one will be toggled.
   */
  toggleSelection(event: MouseEvent, bin: Bin) {
    const currentIndex = (this.pageResults$.value as Bin[]).findIndex(b => b.name === bin.name);

    if (event.shiftKey && this.lastSelectedIndex !== null) {
      const start = Math.min(this.lastSelectedIndex, currentIndex);
      const end = Math.max(this.lastSelectedIndex, currentIndex);
      for (let i = start; i <= end; i++) {
        this.itemMultiSelection.add((this.pageResults$.value[i] as Bin).name);
      }
    } else {
      if (this.itemMultiSelection.has(bin.name)) {
        this.itemMultiSelection.delete(bin.name);
      } else {
        this.itemMultiSelection.add(bin.name);
      }
      this.lastSelectedIndex = currentIndex;
    }
    this.cdr.markForCheck(); // Trigger change detection
  }

  toggleSelectAll() {
    if (this.allChecked && this.itemMultiSelection.size > 0) {
      this.itemMultiSelection.clear();
    } else {
      if (this.searchModeVisible == BinSectionContent.BIN) {
        this.pageResults$.value.map((bin) => this.itemMultiSelection.add((bin as Bin).name));
      }

    }
    this.cdr.markForCheck();
  }

  deleteSelection() {
    this.dialog.open(DeleteMultipleBinDialog, {
      ...DeleteMultipleBinDialog.dialogOptions,
      data: {
        bins: this.itemMultiSelection,
        onConfirm: () => {
          this.pageIndex$.next(0);
        },
        onOpen: () => {
          this.cdr.detectChanges();
          this.itemMultiSelection = new Set();
        },
        onCancel: () => {
          this.cdr.detectChanges();
        }
      },
    });
  }

  ngOnDestroy() {
    this.pageIndex$.complete();
    this.clipbinsOwner$.complete();
    this.pageResults$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
