import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, InjectionToken, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {interval, of, ReplaySubject, Subject} from 'rxjs';
import {delay, filter, switchMap, takeUntil} from 'rxjs/operators';

import {assumeExhaustive, castExists} from 'asserts/asserts';
import {RenditionService} from 'services/rendition/rendition.service';
import { ScrubbingService } from 'shared/scrubbing_service';

import {ErrorService} from '../error_service/error_service';
import {AnalyticsEventType, PAGE_CONTEXT_TOKEN} from '../firebase/firebase_analytics_service';
import {ShakaPlayer} from '../player/shaka_player';
import {Asset, AssetState} from '../services/asset_service';
import {DeviceInputService} from '../services/device_input_service';
import {LiveAssetManifestInfo} from '../services/manifest.service';
import {BatchOperationService} from '../shared/batch_operation_service';

/** Delay, after which video playback starts on hover when autoplay is false. */
export const PLAY_ON_HOVER_DELAY_MS = 200;

/**
 * Interval at which asset thumbnail is updated for live assets when autoplay
 * is off.
 */
const THUMBNAIL_UPDATE_INTERVAL_MS = 1000;

/** Injection token for `Date.now()`. */
export const DATE_NOW = new InjectionToken<() => number>(
    'Returns the number of milliseconds elapsed since the UNIX epoch',
    {factory: () => () => Date.now()});

/** Component that shows live asset content preview. */
@Component({
  selector: 'mam-live-asset-content-preview',
  templateUrl: './live_asset_content_preview.ng.html',
  styleUrls: ['./live_asset_content_preview.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveAssetContentPreview implements AfterViewInit, OnDestroy {
  /** Live asset to preview. */
  @Input()
  set asset(value: Asset|undefined) {
    this.assetInternal = value;
    this.updateLiveStreamState();
    this.maybeSetDefaultThumbnailTime();
  }
  get asset() {
    return this.assetInternal;
  }

  /**
   * While manifestInfo is not set, default thumbnail will be displayed for the
   * asset.
   */
  @Input()
  set manifestInfo(value: LiveAssetManifestInfo|undefined) {
    this.manifestInfoInner = value;
    this.updateLiveStreamState();
    this.maybeSetDefaultThumbnailTime();
  }
  get manifestInfo() {
    return this.manifestInfoInner;
  }

  private manifestInfoInner: LiveAssetManifestInfo|undefined = undefined;

  /**
   * If true the asset playback will start automatically when the asset is
   * airing.
   */
  @Input() autoPlay = true;

  /** Emits when the underlying thumbnail component is loaded. */
  @Output() readonly thumbnailLoad = new EventEmitter<boolean>();

  @ViewChild('videoPlayer') videoPlayer?: ShakaPlayer;

  /** Left position of the playhead */
  playheadLeft = 0;

  /** Time is seconds that will be used to pull a sprite for thumbnail. */
  thumbnailTime: number|undefined = undefined;

  readonly AnalyticsEventType = AnalyticsEventType;

  readonly PAGE_CONTEXT_TOKEN = PAGE_CONTEXT_TOKEN;

  constructor(
      private readonly cdr: ChangeDetectorRef,
      private readonly batchOperationService: BatchOperationService,
      private readonly errorService: ErrorService,
      private readonly renditionService: RenditionService,
      private readonly scrubbingService: ScrubbingService,
      private readonly deviceInput: DeviceInputService,
      @Inject(DATE_NOW) dateNow: () => number) {
    // When autoplay is off we want the video start playing on hover after a
    // small delay, so we don't start playing if the user just moves the cursor
    // across the screen.
    // When the component is hovered - send signal through
    // delay. When the component is no longer hovered - cancel delayed signal
    // (if any) thanks to switchMap and stop playback immediately. Ignore
    // signals when autoplay is on.
    this.hovered$
        .pipe(
            filter(() => !this.autoPlay),
            switchMap(
                hovered => hovered ?
                    of(true).pipe(delay(PLAY_ON_HOVER_DELAY_MS)) :
                    of(false)),
            takeUntil(this.destroyed))
        .subscribe(hovered => {
          this.playbackRequested = hovered;
          this.updateLiveStreamState();
        });

    // If autoplay is off and the video is live - periodically update thumbnail
    // to the latest one.
    interval(THUMBNAIL_UPDATE_INTERVAL_MS)
        .pipe(
            filter(() => this.isIntervalRefreshOn()), takeUntil(this.destroyed))
        .subscribe(() => {
          this.thumbnailTime =
              (dateNow() - castExists(this.manifestInfo).start.getTime()) / 1000;
          this.cdr.markForCheck();
        });
  }

  ngAfterViewInit() {
    const player = castExists(this.videoPlayer);
    player.addSegmentSigningHook(
        rawUrls => this.batchOperationService.batchSignUrls(rawUrls));
    this.updateLiveStreamState();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  @HostListener('mouseenter', ['$event'])
  @HostListener('touchstart', ['$event'])
  mouseEnter() {
    this.hovered$.next(true);
   
    this.scrubbingService.startScrubbingEvent();  
  }

  @HostListener('mouseleave')
  @HostListener('touchend')
  mouseLeave() {
    this.hovered$.next(false);

    this.playheadLeft = 0;

    // Set default cover image back.
    this.maybeSetDefaultThumbnailTime();

    this.scrubbingService.endScrubbingEvent();
  }

  @HostListener('mousemove', ['$event'])
  @HostListener('touchmove', ['$event'])
  mouseMove(event: UIEvent) {
    // Disable seek on hover when in interval sprite refresh mode.
    // In this mode we show video player when hovered.
    if (this.isIntervalRefreshOn()) return;

    const duration = this.getVideoDuration();
    if (!duration) return;

    const pos = this.getOffsetX(event);
    // Move playhead
    this.playheadLeft = pos;

    const target = event.currentTarget as HTMLElement;
    const width = target.offsetWidth;
    // Calculate a horizontal position ratio from 0 to 1
    const ratio = pos / width;

    // Account for start offset for clips. For original assets startTime is 0.
    this.thumbnailTime =
        (this.asset?.startTime || 0) + Math.max(0, ratio * duration);
  }

  playingLive() {
    return this.videoPlayer?.instance.isLive();
  }

  getVideoDuration() {
    // Live clips have defined duration.
    if (this.asset?.original) return this.asset.duration;

    // Use current time for assets playing live.
    if (this.playingLive()) return this.videoPlayer?.currentTime;

    // Use manifest information for live assets that have ended.
    return this.manifestInfo?.durationInSeconds;
  }

  onThumbnailLoaded(success: boolean) {
    this.thumbnailLoad.emit(success);
  }

  private playbackRequested = false;
  private assetInternal?: Asset;
  private readonly hovered$ = new Subject<boolean>();
  private readonly destroyed = new ReplaySubject<void>(1);

  /** Starts or stops live playback based on the asset state. */
  private async updateLiveStreamState() {
    if (!this.videoPlayer || !this.asset || this.asset.original ||
        !this.manifestInfo) {
      return;
    }

    switch (this.asset.state) {
      case AssetState.AIRING:
        await this.toggleLiveStreamPlayback(this.allowPlay());
        break;
      case AssetState.VOD:
        await this.toggleLiveStreamPlayback(false);
        this.errorService.handle(
            `Unexpected asset state received in live stream playback: ${
                this.asset.name}`);
        break;
      case AssetState.SCHEDULED:
      case AssetState.PENDING:
      case AssetState.PROCESSING:
      case AssetState.ENDED:
        await this.toggleLiveStreamPlayback(false);
        break;
      default:
        assumeExhaustive(this.asset.state);
        break;
    }
  }

  private async toggleLiveStreamPlayback(play: boolean) {
    if (!this.videoPlayer || !this.asset) {
      return;
    }
    if (!play) {
      if (this.playingLive()) {
        await this.videoPlayer.instance.unload();
        // Reflect changes to playingLive() that relies on player.isLive().
        // player.isLive() will change to false after unload() is resolved.
        this.cdr.markForCheck();
      }
      return;
    }
    if (this.playingLive()) {
      return;
    }

    // Prioritize live preview rendition.
    let liveRendition =
        this.renditionService.getRendition(this.asset, 'LIVE_PREVIEW_DASH', true);

    if (!liveRendition) {
      // Fall back to live main rendition.
      liveRendition =
          this.renditionService.getRendition(this.asset, 'LIVE_MAIN_DASH', true);
      if (liveRendition) {
        this.errorService.handle(new Error(
            `Live preview rendition is missing, asset: ${this.asset.name}`));
      } else {
        this.errorService.handle(new Error(
            `Live renditions are missing, asset: ${this.asset.name}`));
        return;
      }
    }

    await this.videoPlayer.loadManifest(liveRendition.url);
    if (!this.allowPlay()) {
      // Live stream should no longer be played - unload. Can happen when
      // autoplay is off and the component is no longer hovered.
      return this.videoPlayer.instance.unload();
    }
    // Select track with the lowest possible bandwidth.
    const lowestBandwidthTrack = this.videoPlayer.tracks?.slice(0).sort(
        (track1, track2) => track1.bandwidth - track2.bandwidth)[0];
    if (lowestBandwidthTrack) {
      this.videoPlayer.selectVariantTrack(lowestBandwidthTrack);
    }
    // Work around for `User didn't interact with the document first`.
    // More information: https://goo.gl/xX8pDD
    // Player needs to be explicitly muted even though we actually ignore audio
    // stream altogether for live asset previews.
    this.videoPlayer.mute();
    await this.videoPlayer.play();
    // Reflect changes to playingLive() that relies on player.isLive().
    // player.isLive() will change to true after play() is resolved.
    this.cdr.markForCheck();
  }

  private allowPlay(): boolean {
    return this.autoPlay || this.playbackRequested;
  }

  private maybeSetDefaultThumbnailTime() {
    // Force default empty thumbnail for pending assets or when no
    // manifest available, because no sprites are available in this case.
    if (this.asset && this.asset.state === AssetState.PENDING ||
        this.manifestInfo == null) {
      // Show default thumbnail
      this.thumbnailTime = -1;
      this.cdr.markForCheck();
      return;
    }

    // Do not set cover image when in interval sprite refresh mode.
    if (this.isIntervalRefreshOn()) return;

    // Do not set thumbnail while duration is not yet available.
    const duration = this.getVideoDuration();
    if (duration == null) return;

    // Account for start offset for clips. For original assets startTime is 0.
    this.thumbnailTime = (this.asset?.startTime || 0) + duration / 2;
    this.cdr.markForCheck();
  }

  private isIntervalRefreshOn() {
    return !this.autoPlay && !!this.manifestInfo?.dynamic;
  }

  private getOffsetX(event: UIEvent): number {
    const currentTarget = event.currentTarget as HTMLElement | null;
    let pos = 0;
    if (this.deviceInput.isTouchEvent(event)) {
      const touchEvent = event as TouchEvent;
      pos = currentTarget
        ? (touchEvent.changedTouches[0].clientX - currentTarget.getBoundingClientRect().left)
        : touchEvent.changedTouches[0].screenX;
    } else {
      const mouseEvent = event as MouseEvent;
      pos = mouseEvent.offsetX;
    }
    return pos;
  }
}
