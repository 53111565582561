import { Injectable } from '@angular/core';

import { RenditionVersionEnum } from '../ias_types';

import { RenditionServiceDependencies } from './rendition-service-dependencies';
import { RenditionService } from './rendition.service';

@Injectable({ providedIn: 'root' })
export class HlsRenditionService extends RenditionService {
    private readonly focalVersions = [
        RenditionVersionEnum.LIVE_MAIN_HLS,
        RenditionVersionEnum.LIVE_PREVIEW_HLS,
        RenditionVersionEnum.PREVIEW_MAIN_HLS
    ];

    constructor(
        dependencies: RenditionServiceDependencies
    ) {
        const mappings = new Map([
            [RenditionVersionEnum.LIVE_MAIN_DASH, RenditionVersionEnum.LIVE_MAIN_HLS],
            [RenditionVersionEnum.LIVE_PREVIEW_DASH, RenditionVersionEnum.LIVE_PREVIEW_HLS],
            [RenditionVersionEnum.PREVIEW_MAIN_DASH, RenditionVersionEnum.PREVIEW_MAIN_HLS]
        ]);

        super(dependencies, mappings);
    }
}
