import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';

import { ClipBinsFoldersService, FolderItemRef } from 'firebase/clip_bins_folders.service';

import { isErrorResponse } from '../../error_service/error_response';
import { SnackBarService } from '../../services/snackbar_service';

/**
 * Cloud ingest creation dialog
 */
@Component({
  selector: 'mam-clipbin-creation-folder-dialog',
  templateUrl: './clipbin_folder_creation_dialog.ng.html',
  styleUrls: ['./clipbin_folder_creation_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClipbinFolderCreationDialog {

  readonly form = new UntypedFormGroup({
    folderName: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private readonly snackBar: SnackBarService,
    private readonly clipBinsFolderService: ClipBinsFoldersService,
    readonly dialogRef: MatDialogRef<ClipbinFolderCreationDialog>,
  ) { }

  submit() {
    if (!this.form.valid) return;

    const formControls = this.form.controls;
    const folderName = formControls['folderName'].value;
    const folderItemRef = {
        displayName: folderName,
        refType: 'folder',
    } as unknown as FolderItemRef;
    this.clipBinsFolderService.createFolder(folderItemRef)
      .pipe(
        take(1)
      )
      .subscribe(result => {
        if (isErrorResponse(result)) {
          this.snackBar.error({
            message: 'Create folder failed.',
            details: result.message,
            doNotLog: true,
          });
        } else {
          this.snackBar.message('Folder has been created');
          this.dialogRef.close();
        }
      });
  }



}
