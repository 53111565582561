import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {delay} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';
import {TransferTask} from 'models';

import {createPseudoRandomGenerator, pseudoRandom} from './fake_api_utils';
import {ApiTransferTaskTransferState} from './ias_types';
import {TransferTaskApiService, TransferTaskPropertyName} from './transfer_task_api_service';

const FAKE_SITE_SEPARATOR = ':';

/** Fake TransferTaskApi implementation for local development. */
@Injectable({providedIn: 'root'})
export class FakeTransferTaskApiService implements
    Omit<TransferTaskApiService, 'client'> {
  retry(taskName: string) {
    const [site, ] = taskName.split(FAKE_SITE_SEPARATOR);
    const retriedTask = this.getTasksFromFakeDb(site, true)
                            .find(task => task.name === taskName);
    assertTruthy(retriedTask);
    // Change original task in fake db.
    retriedTask.transferState = 'TRANSFER_STATE_PENDING';
    retriedTask.fileProgress = [0];
    retriedTask.transferModTime = new Date().toISOString();

    // Clone to avoid other components having direct references.
    const clonedTask = this.cloneTask(retriedTask);
    return of(clonedTask).pipe(delay(200));
  }

  /**
   * Gets a page of transfer tasks.
   * 
   * @param site site id.
   * @param pageNumber page number, should start with 1.
   * @param pageSize number of tasks per page.
   * @param filter filter expression.
   * @param orderBy order by expression.
   */
  search(
      site: string, pageNumber: number, pageSize: number, filter?: string,
      orderBy?: string) {
    if (!pageNumber) {
      throw new Error(`Page number should start with 1, was: ${pageNumber}`);
    }
    let transferTasks = this.getTasksFromFakeDb(site);
    if (filter) {
      // eslint-disable-next-line unicorn/no-array-method-this-argument -- False positive (https://github.com/sindresorhus/eslint-plugin-unicorn/issues/1394)
      transferTasks = this.filter(transferTasks, filter);
    }
    const totalSize = transferTasks.length;
    if (orderBy) {
      transferTasks = this.sort(transferTasks, orderBy);
    }
    transferTasks =
        transferTasks.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    const response = {transferTasks, totalSize};
    return of(response).pipe(delay(200));
  }

  /** Arbitrary words used for generating fake file names. */
  private readonly words = [
    'Intelligent Asset Service',
    'Media Asset Manager',
    'New File',
    'Latest video (recently released)',
    'exclusive footage',
    '_chunk_',
    'updated version 2.0',
    '[compressed]',
    'renamed',
  ];

  /** Other components should not have direct references to these tasks. */
  private readonly taskFakeDatabase = new Map<string, TransferTask[]>();

  /** Generate a list of fake transfers. */
  private generateFakeTransfers(site: string, totalSize: number):
      TransferTask[] {
    const transfers: TransferTask[] = [];
    const rnd = createPseudoRandomGenerator(`${site}:${totalSize}`);

    for (let i = 0; i < totalSize; i++) {
      // Create pseudo random percentages for the different fake properties.
      const rands = Array.from({length: 9}).map(() => Math.floor(rnd() * 100));

      const fileName = this.words[rands[0] % this.words.length] + ' ' +
          this.words[rands[1] % this.words.length] + ' ' +
          this.words[rands[2] % this.words.length] + '.mp4';
      // We simulate tasks with different status to see how this affects the UI.
      // 20% will be "error" and the remaining 80% will be "processing".

      let state: ApiTransferTaskTransferState = 'TRANSFER_STATE_PROCESSING';
      if (rands[3] < 15) {
        state = 'TRANSFER_STATE_COMPLETED';
      } else if (rands[3] < 20) {
        state = 'TRANSFER_STATE_ERROR';
      }
      const modTime =
          new Date(1_600_000_000_000 * (1 + (rands[4] % 10 / 1000))).toISOString();
      const fileProgress = rands[5];

      transfers.push(new TransferTask({
        name: `${site}${FAKE_SITE_SEPARATOR}task#${i}`,
        fileProgress: [fileProgress],
        transferState: state,
        transferModTime: modTime,
        transferRate: rands[7] / 10 * (1000 ** (rands[7] / 100)),
        files: [{
          filename: `[${site.toUpperCase()}] ${fileName}`,
          filesize: String(rands[6] ** 7 % 1e12),
          runMl: state === 'TRANSFER_STATE_ERROR' ? false : rands[7] < 30,
        }],
        transferType: rands[8] < 50 ? 'TRANSFER_DIRECTION_DOWNLOAD' :
                                      'TRANSFER_DIRECTION_UPLOAD'
      }));
    }

    return transfers;
  }

  private getTasksFromFakeDb(site: string, original = false) {
    if (!this.taskFakeDatabase.has(site)) {
      this.taskFakeDatabase.set(
          site, this.generateFakeTransfers(site, 500 * pseudoRandom(site)));
    }
    const tasks = this.taskFakeDatabase.get(site);
    assertTruthy(tasks);

    if (original) {
      return tasks;
    }

    // Clone tasks so components don't hold direct references on tasks in fake
    // database.
    return tasks.map(task => this.cloneTask(task));
  }

  /** Deep clones the task */
  private cloneTask(task: TransferTask) {
    return new TransferTask({
      name: task.name,
      fileProgress: [...task.fileProgress],
      transferState: task.transferState,
      transferType: task.transferType,
      files: [task.files[0]],
      transferModTime: task.transferModTime,
      transferRate: task.transferRate,
    });
  }

  private filter(tasks: TransferTask[], filterExpr: string) {
    // Simplified filter expression parsing for fake.
    // Example: '(prop1=1 OR prop1=3) AND (prop2="test")'
    if (!filterExpr) {
      return tasks;
    }

    const exprs: {
      and: Array<{
        or: Array<[TransferTaskPropertyName, string]>,
      }>,
    } = {and: []};

    const andParts = filterExpr.split(' AND ');
    for (const andPart of andParts) {
      exprs.and.push({or: []});
      // Inner expressions are wrapped in parentheses that need to be removed.
      const orParts = andPart.slice(1, - 1).split(' OR ');
      for (const orPart of orParts) {
        const [prop, value] = orPart.split('=');
        let updateValue = value;
        // String values are wrapped in quotes that need to be removed.
        if (/"[^"]+"/.test(value)) {
          updateValue = value.slice(1, - 1);
        }
        exprs.and[exprs.and.length - 1].or.push(
            [prop as TransferTaskPropertyName, updateValue]);
      }
    }

    return tasks.filter(task => {
      for (const andExpr of exprs.and) {
        let matched = false;
        for (const [prop, value] of andExpr.or) {
          const taskPropertyValue = this.getTaskPropertyValue(task, prop);
          if (prop === TransferTaskPropertyName.FILE_NAME) {
            assertTruthy(typeof taskPropertyValue === 'string');
            matched ||= taskPropertyValue.includes(value);
          } else {
            matched ||= taskPropertyValue === value;
          }
        }
        if (!matched) {
          return false;
        }
      }
      return true;
    });
  }

  private sort(transferTasks: TransferTask[], orderBy: string) {
    const [column, direction] = orderBy.split(' ');
    const directionMod = direction === 'asc' ? 1 : -1;
    return transferTasks.sort((t1, t2) => {
      const t1PropValue =
          this.getTaskPropertyValue(t1, column as TransferTaskPropertyName);
      const t2PropValue =
          this.getTaskPropertyValue(t2, column as TransferTaskPropertyName);

      if (typeof t1PropValue === 'string') {
        return t1PropValue.localeCompare(t2PropValue as string) * directionMod;
      }

      if (typeof t1PropValue === 'number') {
        return (t1PropValue - (t2PropValue as number)) * directionMod;
      }

      if (t1PropValue instanceof Date) {
        return (t1PropValue.getTime() - (t2PropValue as Date).getTime()) *
            directionMod;
      }

      throw new Error(`Unsupported value: ${t1PropValue}`);
    });
  }

  private getTaskPropertyValue(
      transferTask: TransferTask, propertyName: TransferTaskPropertyName) {
    switch (propertyName) {
      case TransferTaskPropertyName.TRANSFER_STATE:
        return transferTask.transferState;
      case TransferTaskPropertyName.TRANSFER_TYPE:
        return transferTask.transferType;
      case TransferTaskPropertyName.FILE_NAME:
        return transferTask.files[0].filename;
      case TransferTaskPropertyName.FILE_SIZE:
        return Number(transferTask.files[0].filesize) || 0;
      case TransferTaskPropertyName.TRANSFER_LAST_MODIFIED:
        // This is expensive when done inside sort but ok for Fake service.
        return new Date(transferTask.transferModTime);
      default:
        throw new Error(`Unsupported property: ${propertyName}`);
    }
  }
}
