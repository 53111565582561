import { NgModule, ProviderToken } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AuthModule } from '../auth/auth_module';
import { environment } from '../environments/environment';
import { ErrorService } from '../error_service/error_service';
import { FirebaseModule } from '../firebase/firebase_module';
import { GcmaQueryExpressions } from '../query_expressions/gcma_query_expressions';

import { FakeAnnotatedSegmentsApiService } from './annotated_segments_api_fake_service';
import { AnnotatedSegmentsApiService } from './annotated_segments_api_service';
import { ApiClientModule, IasApiClient, PubsubApiClient } from './api_client.module';
import { FakeAssetApiService } from './asset_api_fake_service';
import { AssetApiService } from './asset_api_service';
import { BinApiService } from './bin_api.service';
import { FakeBinApiService } from './bin_api_fake_service';
import { FakeClipApiService } from './clip_api_fake_service';
import { ClipApiService } from './clip_api_service';
import { FakeCloudIngestApiService } from './cloud_ingest_api_fake_service';
import { CloudIngestApiService } from './cloud_ingest_api_service';
import { IS_TOUCH_INPUT } from './device_input_service';
import { FakeMediaCacheApiService } from './media_cache_api_fake_service';
import { MediaCacheApiService } from './media_cache_api_service';
import { FakePlaybackAuthorizationService } from './playback-authorization/fake-playback-authorization.service';
import { PathPlaybackAuthorizationService } from './playback-authorization/path-playback-authorization.service';
import { PlaybackAuthorizationService } from './playback-authorization/playback-authorization.service';
import { QueryStringPlaybackAuthorizationService } from './playback-authorization/query-string-playback-authorization.service';
import { FakePubsubApiService, PubsubApiService } from './pubsub_api_service';
import { DashRenditionService } from './rendition/dash-rendition.service';
import { HlsRenditionService } from './rendition/hls-rendition.service';
import { RenditionService } from './rendition/rendition.service';
import { FakeSchemaApiService } from './schema_api_fake_service';
import { SchemaApiService } from './schema_api_service';
import { FakeSearchApiService } from './search_api.fake.service';
import { SearchApiService } from './search_api.service';
import { FakeSharedLinksApiService } from './shared_links_api_fake_service';
import { SharedLinksApiService } from './shared_links_api_service';
import { FakeSitesApiService } from './sites_api_fake_service';
import { SitesApiService } from './sites_api_service';
import { FakeTransferTaskApiService } from './transfer_task_api_fake_service';
import { TransferTaskApiService } from './transfer_task_api_service';
import { IS_E2E_TESTING } from './utils_service';
import { VideoProtocolService } from './video-protocol.service';

const useMockService = !environment.clientId;

/** Services and API factories */
@NgModule({
  imports: [
    ApiClientModule,
    AuthModule,
    MatSnackBarModule,
    FirebaseModule,
  ],
  providers: [
    {
      provide: IS_E2E_TESTING,
      useValue: window.location.search.includes('e2e=true'),
    },
    {
      provide: IS_TOUCH_INPUT,
      useValue: () => window.matchMedia("(pointer: coarse)").matches,
    },
    {
      provide: AnnotatedSegmentsApiService,
      useFactory: getAnnotatedSegmentApi,
      deps: [IasApiClient],
    },
    {
      provide: AssetApiService,
      useFactory: getAssetApi,
      deps: [IasApiClient, GcmaQueryExpressions],
    },
    {
      provide: BinApiService,
      useFactory: getBinApi,
      deps: [IasApiClient, GcmaQueryExpressions],
    },
    {
      provide: SchemaApiService,
      useFactory: getSchemaApi,
      deps: [IasApiClient],
    },
    {
      provide: ClipApiService,
      useFactory: getClipApi,
      deps: [
        IasApiClient,
        GcmaQueryExpressions,
      ],
    },
    {
      provide: CloudIngestApiService,
      useFactory: getCloudIngestApi,
      deps: [
        IasApiClient,
        ErrorService,
      ],
    },
    {
      provide: MediaCacheApiService,
      useFactory: getMediaCacheApi,
      deps: [IasApiClient],
    },
    {
      provide: SearchApiService,
      useFactory: getSearchApi,
      deps: [IasApiClient],
    },
    {
      provide: SharedLinksApiService,
      useFactory: getSharedLinksApi,
      deps: [IasApiClient, ErrorService],
    },
    {
      provide: SitesApiService,
      useFactory: getSitesApi,
      deps: [IasApiClient, ErrorService],
    },
    {
      provide: TransferTaskApiService,
      useFactory: getTransferTaskApi,
      deps: [IasApiClient, ErrorService],
    },
    {
      provide: PubsubApiService,
      useFactory: getPubsubApi,
      deps: [PubsubApiClient, ErrorService],
    },
    {
      provide: RenditionService,
      useFactory: provideByVideoProtocol,
      deps: [VideoProtocolService, DashRenditionService, HlsRenditionService, DashRenditionService]
    },
    {
      provide: PlaybackAuthorizationService,
      useFactory: provideByVideoProtocol,
      deps: [VideoProtocolService, QueryStringPlaybackAuthorizationService, PathPlaybackAuthorizationService, FakePlaybackAuthorizationService]
    }
  ],
})
export class ServicesModule {
}

function provideByVideoProtocol<T>(
  videoProtocolService: VideoProtocolService,
  dashService: ProviderToken<T>,
  hlsService: ProviderToken<T>,
  fakeService: ProviderToken<T>
) {
  return useMockService ?
    fakeService :
    ((videoProtocolService.getVideoProtocol() === 'HLS') ? hlsService : dashService);
}

/** Switch between fake and real `AnnotatedSegmentApiService` API. */
export function getAnnotatedSegmentApi(iasApiClient: IasApiClient) {
  return useMockService ? new FakeAnnotatedSegmentsApiService() :
    new AnnotatedSegmentsApiService(iasApiClient);
}

/** Switch between fake and real `AssetApiService` API. */
function getAssetApi(
  iasApiClient: IasApiClient, gcmaQuery: GcmaQueryExpressions) {
  return useMockService ? new FakeAssetApiService() :
    new AssetApiService(iasApiClient, gcmaQuery);
}

/** Switch between fake and real `BinApiService` API. */
function getBinApi(
  iasApiClient: IasApiClient, gcmaQuery: GcmaQueryExpressions) {
  return useMockService ? new FakeBinApiService() :
    new BinApiService(iasApiClient, gcmaQuery);
}

/** Switch between fake and real `ClipApiService` API. */
function getClipApi(
  iasApiClient: IasApiClient, gcmaQuery: GcmaQueryExpressions) {
  return useMockService ? new FakeClipApiService() :
    new ClipApiService(iasApiClient, gcmaQuery);
}

/** Switch between fake and real `SchemaApiService` API. */
function getSchemaApi(
  iasApiClient: IasApiClient,
  errorService: ErrorService,
) {
  return useMockService ? new FakeSchemaApiService() :
    new SchemaApiService(iasApiClient, errorService);
}

/** Switch between fake and real `MediaCacheApiService` API. */
function getMediaCacheApi(iasApiClient: IasApiClient) {
  return useMockService ? new FakeMediaCacheApiService() :
    new MediaCacheApiService(iasApiClient);
}

/** Switch between fake and real `SearchApiService` API. */
function getSearchApi(iasApiClient: IasApiClient) {
  return useMockService ? new FakeSearchApiService() :
    new SearchApiService(iasApiClient);
}


/** Switch between fake and real `SharedLinksApiService` API. */
function getSharedLinksApi(
  iasApiClient: IasApiClient, errorService: ErrorService) {
  return useMockService ? new FakeSharedLinksApiService() :
    new SharedLinksApiService(iasApiClient, errorService);
}

/** Switch between fake and real `SitesApiService` API. */
function getSitesApi(iasApiClient: IasApiClient, errorService: ErrorService) {
  return useMockService ? new FakeSitesApiService() :
    new SitesApiService(iasApiClient, errorService);
}

/** Switch between fake and real `TransferTaskApiService` API. */
function getTransferTaskApi(
  apiService: IasApiClient, errorService: ErrorService) {
  return useMockService ? new FakeTransferTaskApiService() :
    new TransferTaskApiService(apiService, errorService);
}

/** Switch between fake and real `CloudIngestApiService` API. */
function getCloudIngestApi(iasApiClient: IasApiClient) {
  return useMockService ? new FakeCloudIngestApiService() :
    new CloudIngestApiService(iasApiClient);
}

/** Switch between fake and real `Pubsub` API. */
function getPubsubApi(apiService: PubsubApiClient) {
  return useMockService ? new FakePubsubApiService() :
    new PubsubApiService(apiService);
}
