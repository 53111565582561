import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, merge, Observable, of, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';

import {Folder, Site} from 'models';

import {ErrorResponse, isErrorResponse, mapOnSuccess} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {ApiSiteTypeEnum} from '../services/ias_types';

import {MediaCacheApiService} from './media_cache_api_service';
import {PreferencesService} from './preferences_service';
import {SharedLinksService} from './shared_links_service';
import {SitesApiService} from './sites_api_service';
import {SnackBarService} from './snackbar_service';
import {UtilsService} from './utils_service';

/** Combines site and folder information. */
export interface Path {
  siteId: string;
  folderId: string;
  folderMetadata: Record<string, string|undefined>;
}

/**
 * We request 100 sites as we don't support pagination for the dropdown, and we
 * consider that there won't be more than 100 sites.
 */
const NUMBER_OF_SITES_LOADED = 100;

/** At which frequency to fetch file state, in ms. */
export const FILE_STATE_INTERVAL = 12_000;

/** Stores the current site and other state related to Media Cache. */
@Injectable({providedIn: 'root'})
export class MediaCacheStateService {
  readonly sites$ = this.getSites();

  /** Filter out GCP sites which are used for storage transfer (cloud ingest) */
  readonly selectableSites$ = this.getSelectableSites();

  /** Global selected site */
  private readonly selectedSiteInternal$ =
      new BehaviorSubject<Site|undefined>(undefined);

  /**
   * Source of truth is URL query params, reflected in this property. Only emits
   * initially and when a different site is selected.
   */
  readonly selectedSite$ = this.selectedSiteInternal$.pipe(
      filter(Boolean),
      distinctUntilChanged((site1: Site, site2: Site) => {
        return site1.siteId === site2.siteId;
      }),
  );

  /** Current site and the first cache folder.  */
  readonly filePath$: Observable<Path> = this.watchFilePath();

  /** Exportable folders in the current site. */
  readonly foldersForExport$: Observable<Folder[]>;

  /** The scratch folder in the current site. */
  readonly scratchFolder$: Observable<Folder|undefined>;

  /**
   * When the MediaCache emits an update event, each asset on the page will
   * fetch their latest file state. This update is emitted periodically,
   * whenever the current site changes.
   */
  readonly mediaCacheUpdate$: Observable<Path>;

  /**
   * Triggers an immediate emission from `mediaCacheUpdate$` to refresh all
   * file states.
   */
  readonly manualUpdate$ = new BehaviorSubject(undefined);

  constructor(
      private readonly router: Router,
      private readonly route: ActivatedRoute,
      private readonly errorService: ErrorService,
      private readonly mediaCacheApi: MediaCacheApiService,
      private readonly sharedLinks: SharedLinksService,
      private readonly sitesApi: SitesApiService,
      private readonly snackbar: SnackBarService,
      private readonly utils: UtilsService,
      private readonly preferences: PreferencesService,
  ) {
    // Emits periodically and when the current site changes.
    const periodicUpdates$ = this.filePath$.pipe(switchMap(
        (path) => this.utils.timer(FILE_STATE_INTERVAL).pipe(map(() => path))));
    // Combine periodic and manual refreshes.
    const sources$ = combineLatest([periodicUpdates$, this.manualUpdate$]);
    this.mediaCacheUpdate$ = sources$.pipe(
        map(([path]) => path), shareReplay({bufferSize: 1, refCount: true}));

    this.allExportFolders$ = this.selectedSite$.pipe(
        switchMap(site => {
          return this.getExportFolders(site).pipe(
              map(folders => isErrorResponse(folders) ? [] : folders));
        }),
        shareReplay({bufferSize: 1, refCount: false}));
    this.foldersForExport$ = this.allExportFolders$.pipe(
        map(folders => folders.filter(folder => !this.isScratchFolder(folder))),
        shareReplay({bufferSize: 1, refCount: false}));
    this.scratchFolder$ = this.allExportFolders$.pipe(
        map(folders => folders.find(folder => this.isScratchFolder(folder))),
        shareReplay({bufferSize: 1, refCount: false}));

    if (!this.sharedLinks.isSharedLinksPage()) {
      // Observe query params to update the current `selectedSite$`.
      this.route.queryParamMap
          .pipe(
              map(params => params.get('site')),
              distinctUntilChanged(),
              switchMap((siteId) => this.selectableSites$.pipe(map(sites => {
                return {siteId, sites};
              }))),
              )
          .subscribe(({siteId, sites}) => {
            this.onSiteUrlChange(siteId, sites || []);
          });
    }
  }

  /** Updates query params with the site ID to select. */
  selectSite(site: Site) {
    this.router.navigate([], {
      queryParams: {'site': site.siteId},
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
  }

  /**
   * Get all export type folders from the given site.
   */
  getExportFolders(site: Site): Observable<Folder[]|ErrorResponse> {
    return this.mediaCacheApi.listFolders(site.siteId, 'FOLDER_TYPE_EXPORT')
        .pipe(
            this.errorService.retryShort(), this.errorService.catchError(),
            tap(response => {
              if (isErrorResponse(response)) {
                this.snackbar.error({
                  message:
                      `Failed to get export folder for site "${site.siteId}"`,
                  details: response.message,
                  doNotLog: true,
                });
              }
            }),
            mapOnSuccess(response => response.folders));
  }

  /** All export folders in the current site.  */
  private readonly allExportFolders$: Observable<Folder[]>;

  /** Fetches all sites once and shares value. */
  private getSites(): Observable<Site[]|null> {
    // No site is available from a public shared links page.
    if (this.sharedLinks.isSharedLinksPage()) {
      return of([]);
    }

    return this.sitesApi.list(NUMBER_OF_SITES_LOADED)
        .pipe(
            // Reverse sites order to show them by oldest to newest
            map(response => response?.sites.reverse() || null),
            shareReplay({bufferSize: 1, refCount: false}));
  }

  /**
   * Verify site ID from URL and updates `selectedSite$` if it is valid,
   * otherwise reset the URL with a default valid site.
   */
  private async onSiteUrlChange(urlSiteId: string|null, sites: Site[]) {
    let newSiteId = urlSiteId;

    if (!sites.length) {
      this.snackbar.error(`No transfer site found`);
      return;
    }

    // When no site is in the URL, use the last selected one if it was saved,
    // otherwise default to the first site available.
    if (!newSiteId) {
      newSiteId = this.preferences.load('user_site') || sites[0].siteId;
    }

    const site = sites.find(
        site => site.siteId.toLowerCase() === newSiteId?.toLowerCase());

    if (!site) {
      this.snackbar.error(`Unknown site: ${newSiteId}`);
      this.selectSite(sites[0]);
      return;
    }

    // Case when we want to select a different site than what was in the URL,
    // update the current URL which will trigger `onSiteUrlChange` again.
    if (newSiteId !== urlSiteId) {
      this.selectSite(site);
      return;
    }

    // Case when the URL site is valid, we update the state with it which is
    // observed by the different components. We also save this site in the user
    // preferences to use it by default if no site is in the URL in the next
    // session.
    this.preferences.save('user_site', site.siteId);
    this.selectedSiteInternal$.next(site);
  }

  private getSelectableSites(): Observable<Site[]|null> {
    return this.sites$.pipe(map(sites => {
      if (!sites) return null;
      return sites.filter(
          site => site.siteType !== ApiSiteTypeEnum.SITE_TYPE_CLOUD_GCP);
    }));
  }

  private isScratchFolder(folder: Folder) {
    return folder.customMetadata?.['COMPREEL_INPUT']?.toLowerCase() === 'true';
  }

  /**
   * Any time the current site changes, we list the folders of this site and
   * pick the first one of type "cache" to create the API calls path.
   */
  private watchFilePath() {
    return this.selectedSite$.pipe(
        switchMap(site => {
          return this.mediaCacheApi
              .listFolders(site.siteId, 'FOLDER_TYPE_CACHE', /* single */ true)
              .pipe(
                  this.errorService.retryShort(),
                  this.errorService.catchError(),
                  tap(response => {
                    if (isErrorResponse(response)) {
                      this.snackbar.error({
                        message: `Failed to get cache folder for site "${
                            site.siteId}"`,
                        details: response.message,
                        doNotLog: true,
                      });
                    } else {
                      if (!response.folders.length) {
                        this.snackbar.error(
                            `No cache folder found for the site "${
                                site.siteId}"`);
                      }
                    }
                  }),
                  map(response => {
                    const folders =
                        isErrorResponse(response) ? [] : response.folders;
                    const firstCacheFolder = folders[0];
                    return {
                      siteId: site.siteId,
                      folderId: firstCacheFolder?.folderId || '',
                      folderMetadata: firstCacheFolder?.customMetadata ?? {},
                    };
                  }),
              );
        }),
        // Ensure that only 1 call to the `listFolders` API is made every time a
        // different site is selected.
        shareReplay({bufferSize: 1, refCount: false}),
    );
  }
}

/**
 * Provides a mock for the MediaCache service. By default it does not emit any
 * update so component that do not need to test MediaCache related features will
 * not have its code executed.
 */
@Injectable({providedIn: 'root'})
export class MediaCacheStateStub extends MediaCacheStateService {
  private readonly fakeUpdate$ = new ReplaySubject<Path>(1);

  private readonly DEFAULT_SITES = [
    new Site({siteId: 'site0'}),
    new Site({siteId: 'site1'}),
  ];

  override readonly sites$ = new BehaviorSubject(this.DEFAULT_SITES);

  override readonly selectableSites$ = new BehaviorSubject(this.DEFAULT_SITES);

  override readonly selectedSite$ =
      new BehaviorSubject(new Site({siteId: this.DEFAULT_SITES[0].siteId}));

  override readonly foldersForExport$ = new BehaviorSubject<Folder[]>([
    makeFakeExportFolder('export folder 1'),
    makeFakeExportFolder('export folder 2'),
  ]);

  override readonly scratchFolder$ =
      new BehaviorSubject<Folder>(makeFakeScratchFolder('scratch-folder'));

  override readonly filePath$ = new ReplaySubject<Path>(1);

  override readonly mediaCacheUpdate$ = merge(this.filePath$, this.fakeUpdate$);

  override selectSite(site: Site) {
    this.selectedSite$.next(site);
  }

  /** Unit tests helper to simulate a non-empty filePath */
  initializeFilePath() {
    this.filePath$.next({
      siteId: 'site0',
      folderId: 'folder0',
      folderMetadata: {},
    });
  }

  /** Unit tests helper to simulate a mediaCacheUpdate */
  triggerFakeUpdate() {
    this.filePath$.pipe(take(1)).subscribe(path => {
      this.fakeUpdate$.next(path);
    });
  }
}

/** Creates a fake export folder for unit tests. */
export function makeFakeExportFolder(folderId: string) {
  return new Folder({folderId, type: 'FOLDER_TYPE_EXPORT', name: folderId});
}

/** Creates a fake scratch folder for unit tests. */
export function makeFakeScratchFolder(folderId: string) {
  return new Folder({
    folderId,
    type: 'FOLDER_TYPE_EXPORT',
    name: folderId,
    customMetadata: {
      'COMPREEL_INPUT': 'TRUE',
    }
  });
}

/** Folder level metadata properties. */
export enum FolderMetadata {
  EVS_PATH_PREFIX = 'EVS_PATH_PREFIX',
  WINDOWS_DRIVE_PREFIX = 'WINDOWS_DRIVE_PREFIX',
  UNIX_PATH_PREFIX = 'UNIX_PATH_PREFIX',
}
