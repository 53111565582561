import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {delay} from 'rxjs/operators';

import {ApiMetadataSchema} from 'api/ias/model/models';

import {covertToUiMetadataSchema, MetadataSchema, SchemaApiService} from './schema_api_service';
import {Interface} from './utils_service';

/** Fake SchemaApiService implementation for local development. */
@Injectable({providedIn: 'root'})
export class FakeSchemaApiService implements Interface<SchemaApiService> {
  getSchema(uri: string): Observable<MetadataSchema> {
    return of(makeFakeSchema(uri)).pipe(delay(300));
  }

  list(): Observable<MetadataSchema[]> {
    const schemas: MetadataSchema[] = [];
    for (let i = 0; i < 20; i++) {
      schemas.push(makeFakeSchema(`/fake/schema#${i}`, i));
    }

    return of(schemas).pipe(delay(300));
  }
}

/** Fake schema and used for local development only */
export function makeFakeSchema(
    uri: string,
    filteredFieldIndex?: number,
    labels?: Record<string, string>,
    ): MetadataSchema {
  const num = Number(uri.match(/\d+/)?.[0] ?? -1);
  const properties = {
    'ContentType': {
      '$id': '#/properties/ContentType',
      'type': 'string',
      'title': 'ContentType',
      'description': 'Content Type of the schema.',
      'default': '',
      'examples': ['Melt', 'Dirty Feed', 'ENG', 'Films']
    },
    'EventDate': {
      '$id': '#/properties/EventDate',
      'type': 'string',
      'format': 'date-time',
      'title': 'Event Date',
      'description': 'The date of the event.',
      'default': '',
      'examples': ['2015-04-11T07:00:00Z']
    },
    'Description': {
      '$id': '#/properties/Description',
      'type': 'string',
      'title': 'Description',
      'description': 'The description of this asset.',
      'default': '',
      'examples': ['10/11/2015 Seattle Seahawks vs Cincinnati Bengals']
    },
    'EventLeague': {
      '$id': '#/properties/EventLeague',
      'type': 'string',
      'title': 'Event League',
      'description': 'The event league for the sports.',
      'default': '',
      'examples': ['MLB', 'NASCAR Truck Series', 'High School']
    },
    'Sport': {
      '$id': '#/properties/Sport',
      'type': 'string',
      'title': 'Sport',
      'description': 'The Sport of this video, important top level facet.',
      'default': '',
      'examples': [
        'Baseball', 'Tennis', 'Boxing', 'Mixed Martial Arts', 'Poker', 'Soccer'
      ]
    },
    'Category': {
      '$id': '#/properties/Category',
      'type': 'string',
      'title': 'Category',
      'description': 'IMPORTANT: Category of this video field.',
      'default': '',
      'examples': ['CATEGORIES/Sports Library/MLB/Melts/2015/Week 1/BOS/NYY']
    },
    'CreatedAt': {
      '$id': '#/properties/CreatedAt',
      'type': 'string',
      'format': 'date-time',
      'title': 'Create Time',
      'description': 'The creation time of this event.',
      'default': '',
      'examples': ['2015-04-14T00:00:08.410Z']
    },
    'Filename': {
      '$id': '#/properties/Filename',
      'type': 'string',
      'title': 'Filename',
      'description': 'The Filename to locate the corresponding video asset.',
      'default': '',
      'examples': ['1614914434-melt.mxf']
    },
    'Title': {
      '$id': '#/properties/Title',
      'type': 'string',
      'title': 'Title',
      'description': 'The Title of this video. Required field',
      'default': '',
      'examples': ['MLB 20150411 BOS at NYY BOS Kelly strikes out Beltran wide']
    },
    'EventLocation': {
      '$id': '#/properties/EventLocation',
      'type': 'array',
      'items': {'type': 'string'},
      'title': 'Event Location',
      'description': 'The location of the sports event.',
      'default': '',
      'examples': ['Bronx, New York']
    },
    'EventSeasonType': {
      '$id': '#/properties/EventSeasonType',
      'title': 'Season Type',
      'description': 'Major division of the season.',
      'type': 'array',
      'items':
          {'type': 'string', 'enum': ['Spring Training', 'Regular', 'Post']}
    },
    'DoubleHeaderGameNumber': {
      '$id': '#/properties/DoubleHeaderGameNumber',
      'title': 'Double Header Game Number',
      'description': 'The doubleheader game number.',
      'type': 'integer',
      'enum': [1, 2]
    },
    'ModifiedAt': {
      '$id': '#/properties/ModifiedAt',
      'type': 'string',
      'format': 'date-time',
      'title': 'Modify Time',
      'description': 'The last modified time for this metadata.',
      'default': '',
      'examples': ['2020-06-10T00:18:06.330Z']
    },
    'StartOfMaterial': {
      '$id': '#/properties/StartOfMaterial',
      'type': 'string',
      'title': 'Start Of Material',
      'description': 'An important field capturing the timecode of the event.',
      'default': '',
      'examples': ['2015-04-13T20:16:27.372Z']
    },
    'CategoryList': {
      '$id': '#/properties/CategoryList',
      'type': 'array',
      'title': 'Category List',
      'description': 'Alternative categories for this event.',
      'default': '',
      'examples': ['Sports Library', 'MLB', 'Melts']
    },
    'ViewCount': {
      '$id': '#/properties/ViewCount',
      'type': 'number',
      'title': 'View Count',
      'description': '',
      'default': '',
      'examples': [20],
    },
    'DisableMLAnnotations': {
      '$id': '#/properties/DisableMLAnnotations',
      'type': 'boolean',
      'title': 'Disable ML Annotations',
      'description': '',
      'default': '',
      'examples': [true]
    },
    'FoxSportsEventUri': {},
  };
  if (Math.floor(num / 2) === 0) {
    properties['FoxSportsEventUri'] = {
      'type': 'array',
      '$id': '#/properties/FoxSportsEventUri',
      'description': 'Fox Sports Data API Event URI.',
      'examples': ['football/cfb/events/35928'],
      'items': {'type': 'string'},
      'title': 'Fox Sports Event URI'
    };
  }
  const jsonSchema = {
    'title': `Fake Schema #${num}`,
    '$schema':
        'https://intelligentassetservice.googleapis.com/Asset/meta-schema#',
    '$id': 'intelligentassetservice.googleapis.com/Asset/generic-schema.json',
    'type': 'object',
    'properties': properties,
    'displayOrder': [
      'Title',
      'Category',
      'Description',
      'CreatedAt',
      'ModifiedAt',
      'Filename',
      'ContentType',
      'EventLeague',
      'Sport',
      'EventDate',
      'ViewCount',
      'DisableMLAnnotations',
      'CategoryList',
      'EventLocation',
      'EventSeasonType',
      'DoubleHeaderGameNumber',
      'StartOfMaterial',
    ],
    'required': [
      'Title',
      'Category',
      'Description',
      'CreatedAt',
      'ModifiedAt',
      'Filename',
      'EventLocation',
    ]
  };

  if (filteredFieldIndex != null) {
    jsonSchema['displayOrder'].splice(filteredFieldIndex, 1);
  }

  const apiMetadata: ApiMetadataSchema = {
    name: uri,
    description: 'This is schema',
    // ApiMetadataSchema has incorrect typing for jsonSchema
    jsonSchema: jsonSchema as unknown as Record<string, object>,
    createTime: '2015-04-14T00:00:08.410Z',
    updateTime: '2020-06-10T00:18:06.330Z',
    labels,
  };

  return covertToUiMetadataSchema(apiMetadata);
}
