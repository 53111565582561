import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';

/** To be used to show the total number of items at pagination as "many" */
export const UNKNOWN_LENGTH = Number.MAX_SAFE_INTEGER;

/**
 * Customized pagination label:
 * "CurrentPageFirstItem- CurrentPageLastItem of many" if it's not reached
 * the last page yet. "CurrentPageFirstItem- CurrentPageLastItem of
 * TotalNumberOfItems" if it has reached the last page.
 */
@Injectable()
export class PaginatorIntl extends MatPaginatorIntl {
  override getRangeLabel =
      (page: number, pageSize: number, length: number) => {
        const total = length === UNKNOWN_LENGTH ? 'many' : length;

        if (length === 0 || pageSize === 0) {
          return `0 of ${length}`;
        }

        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the
        // end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;

        return `${startIndex + 1} – ${endIndex} of ${total}`;
      };

  override itemsPerPageLabel = 'Page size: ';
}
