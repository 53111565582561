<div [class.loading]="tplState.isLoading" *ngIf="{
    isEditing: metadataService.isEditing$ | async,
    isLoading: isLoading$ | async
  } as tplState">

  <!-- Header with "Edit" button -->
  <div class="dialog-header" mat-dialog-title>
    <h1>{{(isCreating ? 'New ' : '') + 'Play Description'}}</h1>
    <button
        *ngIf="!tplState.isEditing"
        class="button-edit"
        mat-icon-button
        mam-firebase-ga="Start segment metadata editing"
        [mam-firebase-resource]="segment.name"
        (click)="metadataService.edit()"
        matTooltip="Edit play description">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <!-- Dialog content -->
  <div mat-dialog-content>

    <!-- Schema selector -->
    <mam-select-schema
        [schemas]="(schemas$ | async) ?? []"
        [placeholder]="tplState.isEditing ? 'Choose a schema' : 'No schema'"
        [resourceKey]="segment.name"
        [control]="metadataService.selectedSchema"
        [metadataFieldStyle]="true">
    </mam-select-schema>

    <!-- Metadata fields -->
    <mam-metadata-fields (createLogs)="create()"></mam-metadata-fields>
  </div>

  <!-- Dialog Actions -->
  <div mat-dialog-actions>

    <!-- Edit actions: Cancel+Save or Cancel+Create -->
    <ng-container *ngIf="tplState.isEditing; else readOnlyActions">
      <button
          mat-button
          mat-dialog-close
          class="button-cancel"
          [mam-firebase-ga]="'Cancel segment metadata ' + isCreating ? 'creating' : 'editing'"
          [mam-firebase-resource]="segment.name"
          [disabled]="tplState.isLoading">
        Cancel
      </button>
      <button
          *ngIf="!isCreating; else createButton"
          class="button-save"
          mat-button
          mam-firebase-ga="Update segment metadata"
          [mam-firebase-resource]="segment.name"
          [disabled]="!metadataService.isValid() || tplState.isLoading"
          (click)="update()">
        Save
      </button>
      <ng-template #createButton>
        <button
            class="button-create"
            mat-button
            mam-firebase-ga="Create new segment"
            [disabled]="!metadataService.isValid() || tplState.isLoading"
            (click)="create()">
          Create
        </button>
      </ng-template>
    </ng-container>

    <!-- Readonly actions: close-->
    <ng-template #readOnlyActions>
      <button
          mat-button
          mat-dialog-close
          class="button-close"
          mam-firebase-ga="Close play description dialog">
        Close
      </button>
    </ng-template>
  </div>
</div>
