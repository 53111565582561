import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil, tap } from 'rxjs/operators';

import { assertTruthy, castExists } from 'asserts/asserts';
import { SharedLink } from 'models';
import { SharedLinkClipBinService, SharedLinkType } from 'shared_clipbin/services/shared_link_clipbin.service';

import { AuthService } from '../auth/auth_service';
import { LOCATION_ORIGIN, SharedLinksService } from '../services/shared_links_service';
import { SnackBarService } from '../services/snackbar_service';

/** Dialog listing all shared links. */
@Component({
    selector: 'mam-shared-links-manager-dialog',
    templateUrl: './shared_links_manager_dialog.ng.html',
    styleUrls: ['./shared_links_manager_dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedLinksManagerDialog implements OnDestroy {
    static readonly dialogOptions = {
        hasBackdrop: true,
        maxWidth: 660,
        width: '90%',
        // User must save to confirm deleting links, so we prevent accidental
        // closing of the dialog and force them to Cancel or Save.
        disableClose: true
    };

    /**
     * Visible list of links. `undefined` means that no result has been fetched
     * from the backend yet, while empty array means that there is no link
     * matching the current query.
     */
    links?: SharedLink[];
    filteredLinks?: SharedLink[];

    /** Handles the type of links to show. */
    readonly sharedLinkTypes: SharedLinkType[] = [SharedLinkType.ALL, SharedLinkType.CLIPBINS, SharedLinkType.CLIPS];
    searchMode: SharedLinkType = SharedLinkType.ALL;

    /** Whether an API call to fetch links is in progress. */
    loading = false;

    /** List of link names to be revoked if we confirm the dialog. */
    revokeSet = new Set<string>();

    /** List of link names generated by clip bins. */
    linksFromClipbinsMap = new Set<string>();

    titleQuery = new UntypedFormControl();

    constructor(
        private readonly sharedLinks: SharedLinksService,
        private readonly snackbar: SnackBarService,
        private readonly sharedLinkClipBinService: SharedLinkClipBinService,
        private readonly authService: AuthService,
        private readonly cdr: ChangeDetectorRef,
        @Inject(LOCATION_ORIGIN) private readonly origin: string,
        dialogRef: MatDialogRef<SharedLinksManagerDialog, boolean>
    ) {
        // When the dialog is confirmed, we receive a set of link names to revoke.
        dialogRef.afterClosed().subscribe((confirmed) => {
            if (!confirmed || !this.revokeSet.size) return;

            const linkNames = [...this.revokeSet];
            this.sharedLinks.revokeAll(linkNames).subscribe((revokedCount) => {
                if (revokedCount !== linkNames.length) {
                    this.snackbar.error(`Failed to revoke ${linkNames.length - revokedCount} links.`);
                } else {
                    this.snackbar.message(`Successfully revoked ${revokedCount} links.`);
                }
            });
        });

        // Reset list of links when the query changes (debounced).
        this.titleQuery.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => {
                    this.debouncingQuery = true;
                }),
                debounceTime(500),
                startWith('')
            )
            .subscribe(() => {
                this.cdr.markForCheck();
                this.debouncingQuery = false;
                this.loadClipbinSharedLinkList();
                this.loadMore();
            });
    }

    /**
     * Whether the user just typed something in the search input which has not
     * yet been debounced.
     */
    private debouncingQuery = false;

    getLinkUrl(link: SharedLink) {
        return link.type == 'CLIPBIN' ? link.url : this.sharedLinks.getLinkUrl(link);
    }

    loadClipbinSharedLinkList() {
        return this.sharedLinkClipBinService
            .retrieveIASClipBinShareLinksByUser(this.authService.getUserEmail(), this.origin)
            .pipe(
                map((result) =>
                    result.map((item) => {
                        item.clipSharedLinks?.map((clip) => {
                            if (!this.linksFromClipbinsMap.has(clip.assetName)) {
                                this.linksFromClipbinsMap.add(clip.assetName);
                            }
                        });

                        return this.sharedLinkClipBinService.mapClipBinShareLinkToUIShareLink(item) || [];
                    })
                ),
                map((clips) => {
                    // Remove duplicates
                    const clipsList = new Set();
                    return clips.filter((item) => {
                        const duplicate = clipsList.has(item.name);
                        clipsList.add(item.name);
                        return !duplicate;
                    });
                })
            );
    }

    /**
     * Load more links into the existing list. If no `pageSize` is given (for
     * instance after the query changes), a new list is started.
     */
    loadMore(pageSize?: number) {
        if (this.loading) return;

        const resetList = pageSize == null;

        if (resetList) {
            this.nextPageToken = '';
            this.allLoaded = false;
            this.firstLoad = false;

            // Load enough rows to fill-up the dialog so that we can infinite scroll.
            pageSize = Math.floor(window.innerHeight / 60);
        }

        if (this.allLoaded) return;

        this.loading = true;

        const initialSharedLinkList = this.sharedLinks.search(
            this.titleQuery.value || '',
            castExists(pageSize),
            this.nextPageToken
        );
        const clipBinShareLinkList = this.loadClipbinSharedLinkList();
        const combinedClipBinsAndPlainSharedLinks = combineLatest([initialSharedLinkList, clipBinShareLinkList]).pipe(
            map(([initialList, clipBinList]) => {
                const initialClipSharedLinks = initialList
                    ? initialList.sharedLinks.filter((link) => !this.linksFromClipbinsMap.has(link.name))
                    : [];

                const initialToken = initialList ? initialList.nextPageToken : null;

                const result = this.firstLoad
                    ? { nextPageToken: initialToken, sharedLinks: initialClipSharedLinks }
                    : { nextPageToken: initialToken, sharedLinks: [...clipBinList, ...initialClipSharedLinks] };

                this.firstLoad = true;
                return result;
            })
        );

        combinedClipBinsAndPlainSharedLinks.pipe(takeUntil(this.destroyed$)).subscribe((response) => {
            this.cdr.markForCheck();
            this.loading = false;

            if (resetList) {
                this.links = [];
                this.filteredLinks = [];
            }

            if (!response) {
                this.snackbar.error('Failed to load shared links');
                return;
            }

            if (response.nextPageToken) {
                this.nextPageToken = response.nextPageToken;
            } else {
                this.allLoaded = true;
            }

            assertTruthy(this.links, 'SharedLinksManagerDialog.loadMore: Links should be defined');
            this.links.push(...response.sharedLinks);
            this.filteredLinks = [...this.links];
            this.changeSearchMode(this.searchMode);
        });
    }

    getMessage() {
        if (this.loading) {
            // Only show loading message the first time we fetch links and not when
            // filtering by search query.
            return this.links == null ? 'Loading shared links...' : '';
        }

        if (this.links?.length || this.debouncingQuery) return '';

        return this.titleQuery.value ? 'No results found.' : 'No videos have been shared.';
    }

    /** Whether all links have been loaded and are displayed. */
    private allLoaded = false;

    private firstLoad = false;

    private nextPageToken = '';

    private readonly destroyed$ = new ReplaySubject<void>(1);

    getSearchModeText(type: SharedLinkType) {
        switch (type) {
            case SharedLinkType.CLIPBINS:
                return 'Shared clip bins';
            case SharedLinkType.CLIPS:
                return 'Shared clips';
            case SharedLinkType.ALL:
                return 'All shared links';
            default:
                return 'All shared links';
        }
    }

    changeSearchMode(type: SharedLinkType) {
        this.searchMode = type;

        if (type === SharedLinkType.CLIPBINS) this.filteredLinks = this.links?.filter((el) => el.type === 'CLIPBIN');
        else if (type === SharedLinkType.CLIPS) this.filteredLinks = this.links?.filter((el) => el.type !== 'CLIPBIN');
        else this.filteredLinks = this.links;
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
