<h1 mat-dialog-title>
  {{data.title || (data.isMultiselect ? 'Select clip bins' : 'Select a clip bin') | titlecase}}
</h1>
<div mat-dialog-content>
  <mam-clip-bin-search-panel
      [isMultipleOptions]="data.isMultiselect"
      (selectedBinsChanged)="selectedBins = $event">
  </mam-clip-bin-search-panel>
</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel bin selection dialog">
    Cancel
  </button>
  <button
      [disabled]="!selectedBins.length"
      mat-button
      (click)="confirmBinSelection()"
      mam-firebase-ga="Confirm bin selection dialog">
    {{data.actionName || 'Confirm' | titlecase}}
  </button>
</div>
