import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {DateTime} from 'luxon';

import {assertTruthy} from 'asserts/asserts';

import {isErrorResponse} from '../error_service/error_response';
import {TzDatePipe} from '../pipes/tzdate_pipe';
import {Asset} from '../services/asset_service';
import {MediaCacheService} from '../services/media_cache_service';
import {SNACKBAR_LONG_DURATION, SnackBarService} from '../services/snackbar_service';
import {TimezoneService} from '../services/timezone_service';

/**
 * Extend on-prem file TTL dialog
 */
@Component({
  selector: 'mam-extend-ttl-dialog',
  templateUrl: './extend_ttl_dialog.ng.html',
  styleUrls: ['./export_asset_dialog.scss', './extend_ttl_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendTtlDialog {
  static getDialogOptions(data: ExtendTtlDialogInputData):
      MatDialogConfig<ExtendTtlDialogInputData> {
    return {
      data,
      maxWidth: 800,
      width: '60%',
    };
  }

  /** Cannot schedule past date. */
  readonly minDate = DateTime.local().toISO();
  readonly defaultDate = DateTime.fromISO(this.minDate).plus({days: 7}).toISO();

  readonly expiryDateControl =
      new FormControl(this.defaultDate, [Validators.required]);

  constructor(
      private readonly dialogRef: MatDialogRef<ExtendTtlDialog>,
      readonly timezone: TimezoneService,
      private readonly mediaCacheService: MediaCacheService,
      private readonly snackbar: SnackBarService,
      private readonly tzDatePipe: TzDatePipe,
      @Inject(MAT_DIALOG_DATA) public data: ExtendTtlDialogInputData,
  ) {}

  extendTtl() {
    if (this.data.silent) {
      this.dialogRef.close(this.expiryDateControl.value);
      return;
    }
    assertTruthy(this.expiryDateControl.value, 'Expiring date cannot be null');
    assertTruthy(this.data.asset, 'Extend asset cannot be undefined');
    this.mediaCacheService
        .extendOnpremTtl(
            this.data.asset, this.expiryDateControl.value,
            this.data.checkRawSource)
        .subscribe(response => {
          if (isErrorResponse(response)) {
            this.snackbar.error({
              message: 'Failed to extend on-prem file TTL.',
              details: response.message,
              doNotLog: true,
            });
            this.dialogRef.close(undefined);
            return;
          }
          this.dialogRef.close(this.expiryDateControl.value);
          const t = this.getFormattedDate(response.lifecycleInfo.scheduleTime);
          this.snackbar.message(
              `On-prem file TTL extended to ${t}`, undefined,
              SNACKBAR_LONG_DURATION);
        });
  }

  getTooltip() {
    if (this.expiryDateControl.invalid) return '';
    return 'To ' + this.getFormattedDate(this.expiryDateControl.value);
  }

  private getFormattedDate(ttl: string|null): string {
    if (!ttl) return '';
    return this.tzDatePipe.transform(ttl, 'MMM d, y, h:mm a') || '';
  }
}

/** Input data to this dialog */
export interface ExtendTtlDialogInputData {
  /**
   * The asset to extend its on-prem TTL. The data will not be provided when
   * doing bulk extension.
   */
  asset?: Asset;
  /**
   * If we are looking at the raw source. The data will not be provided when
   * doing bulk extension.
   */
  checkRawSource?: boolean;
  /** Trigger extendTtl method from the dialog or not. */
  silent?: boolean;
}
