<ng-container *ngIf="folderRunMap$ | async as folderRunMap">
  <table mat-table
      matSort
      multiTemplateDataRows
      class="cloud-ingest-table"
      [trackBy]="trackByRow"
      [matSortDisableClear]="true"
      [dataSource]="getDataSource(folderRunMap)"
      *ngIf="(progressbar.visible$ | async) === false">

    <!-- Folder Name Column -->
    <ng-container matColumnDef="folderName">
      <th *matHeaderCellDef
          mat-header-cell>
        Folder Name
      </th>
      <td
          class="main-column"
          mat-cell
          *matCellDef="let row"
          [title]="row.folder.name">
        <div class="icon-with-text">
          <mat-icon *ngIf="isRowEnabled(row)">folder</mat-icon>
          <mat-icon class="material-icons-outlined"
              *ngIf="!isRowEnabled(row)"
              matTooltip="This job is disabled">stop_circle</mat-icon>
          <span>{{getFolderName(row.folder.name)}}</span>
        </div>
      </td>
    </ng-container>

    <!-- Source Bucket Column -->
    <ng-container matColumnDef="sourceBucket">
      <th *matHeaderCellDef mat-header-cell>
        Source Bucket
      </th>
      <td mat-cell *matCellDef="let row">
        {{getBucket(row.folder.name)}}
      </td>
    </ng-container>

    <!-- Prefix Path Column -->
    <ng-container matColumnDef="prefixPath">
      <th *matHeaderCellDef mat-header-cell>
        Prefix Path
      </th>
      <td mat-cell *matCellDef="let row" [title]="row.folder.rootFolderPath">
        {{row.folder.rootFolderPath}}
      </td>
    </ng-container>

    <!-- Schedule Column -->
    <ng-container matColumnDef="schedule">
      <th *matHeaderCellDef mat-header-cell>
        Schedule
      </th>
      <td mat-cell *matCellDef="let row" [title]="row.folder.type">
        <ng-container [ngSwitch]="row.folder.type">
          <ng-container
              *ngSwitchCase="FolderTypeEnum.FOLDER_TYPE_CLOUD_MIGRATION">
            Once
          </ng-container>
          <ng-container *ngSwitchCase="FolderTypeEnum.FOLDER_TYPE_CLOUD_WATCH">
            Hourly
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <!-- Last Run Time Column -->
    <ng-container matColumnDef="lastRunTime">
      <th *matHeaderCellDef
          mat-header-cell>
        Last Run Time
      </th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.operations[0] as lastRun; else naTemplate">
          {{lastRun.startTime | date:'MMM d, y, h:mm a'}}
        </span>
        <ng-template #naTemplate>-</ng-template>
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell>
        Last run status
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="icon-with-text">
          <ng-container [ngSwitch]="row.operations[0]?.operationState">
            <ng-container *ngSwitchCase="OperationStateEnum.IN_PROGRESS">
              <mat-icon>timelapse</mat-icon>
              <span>In progress</span>
            </ng-container>
            <ng-container *ngSwitchCase="OperationStateEnum.PAUSED">
              <mat-icon>pause_circle</mat-icon>
              <span>Paused</span>
            </ng-container>
            <ng-container *ngSwitchCase="OperationStateEnum.SUCCEEDED">
              <mat-icon class="succeeded">check_circle</mat-icon>
              <span>Succeeded</span>
            </ng-container>
            <ng-container *ngSwitchCase="OperationStateEnum.FAILED">
              <mat-icon class="error">error</mat-icon>
              <span>Failed</span>
            </ng-container>
            <ng-container *ngSwitchCase="OperationStateEnum.ABORTED">
              <span>Aborted</span>
            </ng-container>
            <ng-container *ngSwitchCase="OperationStateEnum.QUEUED">
              <span>Queued</span>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span>Not started</span>
            </ng-container>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button
            matTooltip="Delete this job"
            aria-label="Delete job"
            (click)="$event.stopPropagation(); onDeleteClicked(row.folder)">
          <mat-icon>delete_outline</mat-icon>
        </button>
        <button mat-icon-button
            matTooltip="Enable this job"
            aria-label="Enable job"
            *ngIf="!isRowEnabled(row)"
            (click)="$event.stopPropagation(); onEnableClicked(row.folder)">
          <mat-icon>play_circle_outlined</mat-icon>
        </button>
        <button mat-icon-button
            matTooltip="Disable this job"
            aria-label="Disable job"
            *ngIf="isRowEnabled(row)"
            (click)="$event.stopPropagation(); onDisableClicked(row.folder)">
          <mat-icon class="material-icons-outlined">stop_circle</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Expand Column -->
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row;">
        <button mat-icon-button
            mam-firebase-ga="{{isRowExpanded(row) ? 'Expand' : 'Collapse'}} cloud ingest table row"
            [mam-firebase-resource]="row.id"
            [attr.aria-label]="isRowExpanded(row) ? 'Collapse row' : 'Expand row'">
          <mat-icon>
            {{isRowExpanded(row) ? 'expand_less' : 'expand_more'}}
          </mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Details Column (displayed when row is expanded) -->
    <ng-container matColumnDef="expandedDetails">
      <td mat-cell
          *matCellDef="let row;"
          [attr.colspan]="displayedColumns.length">
        <mam-cloud-ingest-details
            @expandDetails
            *ngIf="isRowExpanded(row)"
            [folder]="row.folder"
            [runs]="row.operations">
        </mam-cloud-ingest-details>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        [mam-firebase-ga]="isRowExpanded(row) ? 'Hide cloud ingest details' : 'Show  cloud ingest details'"
        (click)="$event.stopPropagation(); toggleRowExpanded(row)"
        [class.jobDisabled]="!isRowEnabled(row)"
        [class.expanded]="isRowExpanded(row)">
    </tr>

    <tr mat-row
        *matRowDef="let row; columns: ['expandedDetails'];"
        class="details"
        [hidden]="!isRowExpanded(row)">
    </tr>
  </table>

  <div class="empty-message"
      *ngIf="!getDataSource(folderRunMap).length && (progressbar.visible$ | async) === false">
    No result.
  </div>
</ng-container>
