import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';


/**
 * The dialog that provides move/copy clip functionality.
 *
 * Relies on components like `ClipbinSearchPanel` to display/interact with bin
 * list and `MoveClipButton` to perform the action.
 */
@Component({
  selector: 'mam-select-bins-dialog',
  templateUrl: './select_bins_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
    @import './shared/mixins';
    :host {
      max-width: 502px;
      width: 70vw;
      display: block;

        @include breakpoint-md-max {
          max-width: 95vw;
          width: 65vw;
          }

        @include breakpoint-sm-max {
          width: initial;
          max-width: initial;
        }
      }`
  ]
})
export class SelectBinsDialog {
  static getDialogOptions(data: SelectBinsDialogInputData):
      MatDialogConfig<SelectBinsDialogInputData> {
    return {
      data,
    };
  }
  selectedBins: string[] = [];

  constructor(
      readonly dialogRef: MatDialogRef<SelectBinsDialog>,
      @Inject(MAT_DIALOG_DATA) readonly data: SelectBinsDialogInputData,
  ) {}

  confirmBinSelection() {
    this.dialogRef.close(this.selectedBins);
  }
}

/** Input data to this dialog */
export interface SelectBinsDialogInputData {
  /** The dialog title. Default to `Select clip bins` or `Select a clip bin`. */
  title?: string;
  /** The title for primary button. Defaults to `Confirm`. */
  actionName?: string;
  /** If true the user can select multiple clip bins. */
  isMultiselect: boolean;
}

/** List of available clip bin names. */
export type SelectBinsDialogOutputData = string[];
