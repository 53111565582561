import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, catchError, forkJoin, map, mergeMap, Observable, of, ReplaySubject, Subject, takeUntil } from 'rxjs';

import { ErrorResponse } from 'error_service/error_response';
import { ClipBinsFoldersService, FolderItemRef } from 'firebase/clip_bins_folders.service';
import { Bin, BinService } from 'services/bin.service';
import { DisplayMode } from 'services/vod_search_service';

export interface ContentEvent {
  moved: boolean,
  deleted: boolean,
  id: string,
  name: string
}


@Component({
  selector: 'mam-folder-content',
  templateUrl: './folder-content.component.html',
  styleUrls: ['./folder-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderContentComponent implements AfterViewInit, OnDestroy {
  @Input() isAdmin: boolean = false;
  @Input() userEmail: string = '';

  @Input() folderId!: string;
  @Input() set displayMode(mode: DisplayMode) {
    this._displayMode = mode;
    this.cdr.detectChanges();
  }
  get displayMode() {
    return this._displayMode;
  }
  private _displayMode: DisplayMode = DisplayMode.GRID;
  currentFolder: FolderItemRef | undefined;
  hasContent = false;
  resultsLoading = true;
  seletonItems = Array.from({ length: 6 });
  contentEvent$ = new Subject<void>();

  contentRef$ = new BehaviorSubject<Array<FolderItemRef | Bin>>([]);

  get folderContent() {
    return this.contentRef$.getValue().filter((c) => (c as FolderItemRef).id) as FolderItemRef[];
  }

  get binContent() {
    return this.contentRef$.getValue().filter((c) => !(c as FolderItemRef).id) as Bin[];
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private clibinsFolderService: ClipBinsFoldersService,
    private clipBinsService: BinService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngAfterViewInit() {
    this.getFolderContent();
    this.setCurrentFolder();
  }

  getFolderContent() {
    this.clibinsFolderService.getFolderContent(this.folderId)
      .pipe(
        map((content: FolderItemRef[]) => content.map(i => this.fetchContent(i))),
        mergeMap((content) => (content.length ? forkJoin(content) : of([]))),
        catchError(() => of([])),
        takeUntil(this.destroyed$),
      )
      .subscribe((content) => {
        const filterResponses: Array<FolderItemRef | Bin> = content.filter((c) => !(c instanceof ErrorResponse)) as Array<FolderItemRef | Bin>;
        this.contentRef$.next(filterResponses);
        this.hasContent = filterResponses.length > 0;
        this.resultsLoading = false;
        this.cdr.markForCheck();
      });
  }

  setCurrentFolder() {
    this.clibinsFolderService.getFolder(this.folderId)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((folder) => {
        this.currentFolder = folder;
        this.cdr.markForCheck();
      });
  }

  onContentEvent(content: ContentEvent) {
    if (content['moved'] || content['deleted']) {
      this.contentRef$.next(this.contentRef$.getValue().filter((c) => {
        if ((c as FolderItemRef).id) {
          return (c as FolderItemRef).id !== content['id'];
        }
        return (c as Bin).name !== content['name'];
      }));
    }
    this.cdr.markForCheck();
  }


  fetchContent(folderItem: FolderItemRef): Observable<Bin | FolderItemRef | ErrorResponse> {
    if (folderItem.binRefName) {
      return this.clipBinsService.getBin(folderItem.binRefName);
    }
    return of(folderItem);
  }

  trackBy(index: number, item: FolderItemRef | Bin) {
    return (item as FolderItemRef).id ?? (item as Bin).name;
  }

  onFolderIconClick() {
    this.router.navigate(['.'], { queryParams: { ... this.route.snapshot.queryParams, mySearchMode: 'folders' } });
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
