<h1 class="title" mat-dialog-title>
  {{data.title | titlecase}}
</h1>
<div mat-dialog-content>
  <ng-container *ngIf="schemas$ | async as schemas">
    <mam-select-schema
        [showLabel]="false"
        [schemas]="schemas"
        [resourceKey]=""
        [control]="selectedSchema"
        [placeholder]="schemas.length > 0 ? 'Choose a schema' : 'No schema available'">
    </mam-select-schema>
  </ng-container>

  <ng-container *ngIf="displayValueInput$ | async">
    <mat-form-field appearance="fill"
    subscriptSizing="dynamic">
      <mat-label>{{ formatMetadataTitle() }}</mat-label>
      <input
          matInput
          [formControl]="metadataValue"
          autocomplete="off"
          (keydown)="$event.stopPropagation()"
          required>
    </mat-form-field>
  </ng-container>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button
      mam-firebase-ga="Open schema selection panel"
      mat-button
      [disabled]="!isValid()"
      class="primary"
      (click)="sync()"
      #primary>
    Sync
  </button>
</div>
