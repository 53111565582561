import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

import {castExists, checkExhaustive} from 'asserts/asserts';

import {Asset} from '../services/asset_service';

/**
 * Supported time format enum.
 */
export enum TimeFormatEnum {
  /** Always starts at zero at the beginning. */
  DURATION = 'Duration',
  /** The playing time in origin asset. */
  FROM_ORIGINAL = 'From original',
  /** The wall clock time. The type is only applicable to the live asset. */
  TIMECODE = 'Timecode',
}

/**
 * Time format selector.
 */
@Component({
  selector: 'mam-time-format-selection',
  templateUrl: './time_format_selection.ng.html',
  styleUrls: ['./time_format_selection.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeFormatSelection {
  /** Current asset (original or clip) being viewed in the player. */
  @Input()
  set asset(asset: Asset) {
    // Reset the available options.
    this.assetInternal = asset;
    this.formatOptions = this.buildFormatOptions(asset);
    this.formatChanged.emit(this.selectedFormat);
  }
  get asset() {
    return castExists(this.assetInternal);
  }

  /** Emits when select a time format option. */
  @Output() readonly formatChanged = new EventEmitter<TimeFormatEnum>();

  // TODO: Save user selection via PreferencesService.
  selectedFormat: TimeFormatEnum = TimeFormatEnum.DURATION;

  /** The available time format options. */
  formatOptions: TimeFormatEnum[] = [];

  readonly TimeFormatEnum = TimeFormatEnum;

  selectTimeFormat(format: TimeFormatEnum) {
    this.selectedFormat = format;
    this.formatChanged.emit(format);
  }

  getTooltip(format: TimeFormatEnum): string {
    switch (format) {
      case TimeFormatEnum.DURATION:
        return 'Always starts at zero.';
      case TimeFormatEnum.TIMECODE:
        return 'The wall clock time.';
      case TimeFormatEnum.FROM_ORIGINAL:
        return 'Clip time offset from original source.';
      default:
        checkExhaustive(format);
    }
  }

  private assetInternal?: Asset;

  private buildFormatOptions(asset: Asset): TimeFormatEnum[] {
    const options = [TimeFormatEnum.DURATION];
    if (asset.isLive || asset.startTimecode != null) {
      this.selectedFormat = TimeFormatEnum.TIMECODE;
      options.push(TimeFormatEnum.TIMECODE);
    }
    if (asset.original) {
      options.push(TimeFormatEnum.FROM_ORIGINAL);
    }
    return options;
  }
}
