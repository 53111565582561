// clang-format off

import {
  ApiAsset,
  ApiAssetMetadataStringMetadata,
  ApiAssetRendition,
  ApiCloudTransferJob,
  ApiCloudTransferOperation,
  ApiCompReelData,
  ApiCopyStateDetail,
  ApiEvent,
  ApiExportStatus,
  ApiFile,
  ApiFolder,
  ApiLifecycleInfo,
  ApiPFRStateInfo,
  ApiSearchVideosRequest,
  ApiSite,
  ApiStorageTransferInfo,
  ApiSuggestQueriesRequest,
  ApiTransferTask,
  ApiApprovalState as IasApprovalState,
} from 'api/ias/model/models';


// Re-map all used enum types
export const ApiApprovalStateStateEnum = IasApprovalState.StateEnum;
export const RenditionVersionEnum = ApiAssetRendition.VersionEnum;
export const ApiAssetStateEnum = ApiAsset.StateEnum;
export const ApiTransferJobScheduleTypeEnum = ApiCloudTransferJob.ScheduleTypeEnum;
export const ApiCloudTransferOperationStateEnum = ApiCloudTransferOperation.OperationStateEnum;
export const ApiCompReelStateEnum = ApiCompReelData.StateEnum;
export const Cloud2onpremEnum = ApiFile.Cloud2onpremEnum;
export const Onprem2cloudEnum = ApiFile.Onprem2cloudEnum;
export const FolderTypeEnum = ApiFolder.TypeEnum;
export const ApiLifecycleInfoLifecycleStateEnum = ApiLifecycleInfo.LifecycleStateEnum;
export const ApiSiteTypeEnum = ApiSite.SiteTypeEnum;
export const ApiStorageTransferInfoJobStateEnum = ApiStorageTransferInfo.JobStateEnum;
export const ApiPFRStateInfoStateEnum = ApiPFRStateInfo.StateEnum;
export const SearchRequestTypeEnum = ApiSearchVideosRequest.TypeEnum;
export const SuggestRequestTypeEnum = ApiSuggestQueriesRequest.TypeEnum;
export const ApiCopyStateDetailStateEnum = ApiCopyStateDetail.StateEnum;

export type ApiStorageTransferInfoJobState = ApiStorageTransferInfo.JobStateEnum;
export type Onprem2cloud = ApiFile.Onprem2cloudEnum;
export type ApiTransferTaskTransferType = ApiTransferTask.TransferTypeEnum;
export type ApiTransferTaskTransferState = ApiTransferTask.TransferStateEnum;
export type ApiCloudTransferJobScheduleType = ApiCloudTransferJob.ScheduleTypeEnum;
export type ApiApprovalState = IasApprovalState.StateEnum;
export type ApiPFRStateInfoState = ApiPFRStateInfo.StateEnum;
export type RenditionVersion = ApiAssetRendition.VersionEnum;
export type ApiAssetState = ApiAsset.StateEnum;
export type ApiEventMetadataStreamingState = ApiEvent.StreamingStateEnum;
export type SearchRequestType = ApiSearchVideosRequest.TypeEnum;
export type SuggestRequestType = ApiSuggestQueriesRequest.TypeEnum;
export type MetadataSource = ApiAssetMetadataStringMetadata.SourceEnum;
export type ApiCompReelState = ApiCompReelData.StateEnum;
export type ApiExportStatusExportState = ApiExportStatus.ExportStateEnum;
export type ApiCopyStateDetailState = ApiCopyStateDetail.StateEnum;

// BucketType and Granularity are missing from the api discovery spec
export type BucketType = 'BUCKET_TYPE_UNSPECIFIED' | 'VALUE' | 'RANGE' | 'DATE';
export const BucketTypeEnum = {
    BUCKET_TYPE_UNSPECIFIED: 'BUCKET_TYPE_UNSPECIFIED' as BucketType,
    VALUE: 'VALUE' as BucketType,
    RANGE: 'RANGE' as BucketType,
    DATE: 'DATE' as BucketType
};
export type Granularity = 'GRANULARITY_UNSPECIFIED' | 'YEAR' | 'MONTH' | 'DAY';
    export const GranularityEnum = {
        GRANULARITY_UNSPECIFIED: 'GRANULARITY_UNSPECIFIED' as Granularity,
        YEAR: 'YEAR' as Granularity,
        MONTH: 'MONTH' as Granularity,
        DAY: 'DAY' as Granularity
    };
