import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DisplayMode } from 'services/vod_search_service';

@Component({
	selector: 'mam-assets-skeleton',
	templateUrl: './assets-skeleton.component.html',
	styleUrl: './assets-skeleton.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetsSkeleton {
	@Input() display: DisplayMode = DisplayMode.GRID;
}
