import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {combineLatest, debounceTime, EMPTY, finalize, map, mergeMap, of, ReplaySubject, shareReplay, switchMap, takeUntil} from 'rxjs';

import {isErrorResponse} from '../error_service/error_response';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {AssetService, ListResponse, Original} from '../services/asset_service';
import {DialogService} from '../services/dialog_service';
import {Pagination, PaginationService} from '../services/pagination_service';
import {PaginatorIntl} from '../services/paginator-intl';
import {ProgressbarService} from '../services/progressbar_service';
import {SnackBarService} from '../services/snackbar_service';
import {TaskUsersService} from '../services/task_user_service';


const ALL_COLUMNS = [
  'title',
  'source',
  'lastModified',
  'undelete',
] as const;

/**
 * Asset deletion table component.
 */
@Component({
  selector: 'mam-asset-deletion-table',
  templateUrl: './asset_deletion_table.ng.html',
  styleUrls: ['./asset_deletion_table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: MatPaginatorIntl, useClass: PaginatorIntl}],
})
export class AssetDeletionTable implements OnInit, OnDestroy {

  @Output() readonly scrollTopNeeded = new EventEmitter();

  assets: Original[]|null = null;

  /** Record the undeleted asset to dim the matching row. */
  undeletedAssetSet = new Set<string>();

  /** Fixed page size of the asset deletion table. */
  readonly pageSize = 30;

  /** Form control for search input. */
  search = new FormControl<string>('');

  pagination: Pagination<ListResponse<Original>>;

  /** List of columns to render in the table. */
  displayedColumns = ALL_COLUMNS;

  /** Flag to show assets' source column. */
  readonly showAssetsSource = this.featureService.featureOn('show-user-information');

  constructor(
      readonly progressbar: ProgressbarService,
      private readonly cdr: ChangeDetectorRef,
      private readonly assetService: AssetService,
      private readonly dialogService: DialogService,
      private readonly snackbar: SnackBarService,
      private readonly paginationService: PaginationService,
      private readonly featureService: FeatureFlagService,
      protected readonly taskUserService: TaskUsersService
  ) {
    this.pagination = this.paginationService.getEmptyPagination(this.pageSize);

    this.search.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(300))
        .subscribe(query => {
          // Re-build the cache since the query is changed.
          this.pagination =
              this.paginationService.getEmptyPagination(this.pageSize);

          this.refreshDeletedAssets(query ?? '');
          this.scrollTopNeeded.emit();
        });
  }

  openUndeleteAssetDialog(asset: Original) {
    const confirmed$ = this.dialogService.showConfirmation({
      title: 'Undelete asset',
      question:
          'Its clips will not be restored. Are you sure you want to undelete the asset?',
      primaryButtonText: 'Undelete',
    });

    confirmed$
        .pipe(
            switchMap(result => {
              if (!result) return EMPTY;
              return this.assetService.undeleteAsset(asset);
            }),
            takeUntil(this.destroyed$))
        .subscribe(response => {
          if (isErrorResponse(response)) {
            this.snackbar.error({
              message: 'Failed to undelete asset.',
              details: response.message,
            });
          } else {
            this.snackbar.message('The asset has been undeleted.');
            this.undeletedAssetSet.add(asset.name);
            this.cdr.markForCheck();
          }
        });
  }

  ngOnInit() {
    // Trigger asset fetching.
    this.refreshDeletedAssets('');
  }

  changePage(event: PageEvent) {
    this.pagination.pageIndex = event.pageIndex;
    this.refreshDeletedAssets(this.search.value ?? '');
    this.scrollTopNeeded.emit();
  }

  trackByAsset(index: number, asset: Original) {
    return asset.name;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);

  /**
   * Get the deleted assets from the cache or API.
   */
  private refreshDeletedAssets(userQuery: string) {
    if (this.pagination.pageIndex < this.pagination.pagesCache.length) {
      this.assets =
          this.pagination.pagesCache[this.pagination.pageIndex].assets;
      this.cdr.markForCheck();
      return;
    }

    const nextPageToken =
        this.pagination.pagesCache[this.pagination.pageIndex - 1]
            ?.nextPageToken;
    this.progressbar.show();
    this.assetService.getDeletedAssets(userQuery, this.pageSize, nextPageToken)
        .pipe(
          mergeMap(
            originalResponse => {

              if (!this.showAssetsSource) return of (originalResponse);

              const response = (originalResponse as ListResponse<Original>);
              const originalAssets = response.assets;
              const nextPageToken = response.nextPageToken;

              if (!originalAssets || originalAssets.length == 0 ) return of (originalResponse);
              const completedItems = originalAssets.map(
                originalAsset => this.taskUserService
                  .completeDeletedAssetWithUser(originalAsset)
              );

              return combineLatest(completedItems).pipe(
                map(assets => {
                  return {assets,nextPageToken} as ListResponse<Original>;
                })
              );
            }),
          shareReplay({bufferSize: 1, refCount: false})
        )
        .pipe(takeUntil(this.destroyed$), finalize(() => {
                this.progressbar.hide();
              }))
        .subscribe(response => {
          this.progressbar.hide();
          this.cdr.markForCheck();

          if (isErrorResponse(response)) {
            this.snackbar.error({
              message: 'Failed to get deleted assets.',
              details: response.message,
            });
            this.assets = [];
            return;
          }

          this.assets = response.assets;
          this.pagination.pagesCache[this.pagination.pageIndex] = response;

          // This is the last page, calculate the total count.
          if (!response?.nextPageToken) {
            this.pagination.totalCount =
                this.pagination.pageIndex * this.pageSize + this.assets.length;
          }
        });
  }

  onSortByField(rows: Original[]) {
    this.assets = rows;
    this.cdr.detectChanges();
  }
}

