import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {delay, map} from 'rxjs/operators';

import {PublishRequest, PublishResponse, PubsubMessage} from 'api/pubsub/model/models';
import {assertTruthy} from 'asserts/asserts';

import {environment} from '../environments/environment';

import {PubsubApiClient} from './api_client.module';
import {Interface} from './utils_service';

/** Service to interact with Pubsub APIs */
@Injectable({providedIn: 'root'})
export class PubsubApiService {
  constructor(private readonly apiClient: PubsubApiClient) {}


  publish(message: SyncMetadataMessage): Observable<string|undefined> {
    assertTruthy(environment.pubsubApi, 'No pubsub in current environment');

    const encodedMessage: PubsubMessage = {data: btoa(JSON.stringify(message))};
    const requestBody: PublishRequest = {messages: [encodedMessage]};
    try {
      return this.apiClient
          .pubsubProjectsTopicsPublish({
            topic: environment.pubsubApi.topic,
            body: requestBody,
          })
          .pipe(map((res: PublishResponse) => {
            return res.messageIds?.[0];
          }));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }
}

/** SyncMetadataMessage represents the message types sent to pubsub topic. */
export interface SyncMetadataMessage {
  assetName: string;
  schemaName: string;
  foxSportsEventUri: string;
}

/** Fake PubsubApiService implementation for local development. */
@Injectable({providedIn: 'root'})
export class FakePubsubApiService implements Interface<PubsubApiService> {
  publish(message: SyncMetadataMessage): Observable<string> {
    return (message.foxSportsEventUri === '0') ?
      of('').pipe(
        delay(100),
        map(() => {
          const httpError = new HttpErrorResponse({
            status: 403,
          });

          // eslint-disable-next-line @typescript-eslint/no-throw-literal -- FIXME
          throw (httpError);
        })
      ) :
      of('1234').pipe(delay(100));
  }
}
