import { AfterViewInit, Directive, ElementRef, Inject, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';

export interface Scroller {

  scrollTop() : number

  scrollTo(destination : number) : void
}

export class ScrollerFactory {
  public createScroller(elementRef: ElementRef<Element>) : Scroller {
    return new WindowScroller(elementRef);
  }
}

class WindowScroller implements Scroller {
  constructor(private elementRef: ElementRef<Element>) {
  }

  public scrollTo(destination : number) {
    this.elementRef.nativeElement.scrollTop = destination;
  }

  public scrollTop() : number {
    return this.elementRef.nativeElement.scrollTop;
  }
}


@Directive({
  selector: '[restore-scroll]'
})
export class RestoreScrollDirective implements AfterViewInit, OnDestroy{
    private scrollLevels = new Map<number, number>();
    private lastId = 0;
    private restoredId: number | undefined;
    private scroller: Scroller;
    private readonly destroyed$ = new ReplaySubject<void>(1);

    constructor(private elementRef: ElementRef<Element>,
                private router: Router,
                @Inject(ScrollerFactory) private scrollerFactory: ScrollerFactory
                ) {
                  this.scroller = this.scrollerFactory.createScroller(this.elementRef);
                }

  ngAfterViewInit(): void {
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(event => {
      if (event instanceof NavigationStart) {
        // sometimes multiple navigations happen at once, navigationTrigger that matters is `popstate`
        this.scrollLevels.set(this.lastId, this.scroller.scrollTop());
        this.lastId = event.id;
        this.restoredId = event.restoredState?.navigationId;
      }

      if (event instanceof NavigationEnd) {
        const destScroll = this.restoredId === undefined ? 0 : (this.scrollLevels.get(this.restoredId) || 0);
        this.scroller.scrollTo(destScroll);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
