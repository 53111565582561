
import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';

import {mapOnError} from '../error_service/error_response';
import {LocalPath} from '../plugin/plugin_types';
import {Asset} from '../services/asset_service';
import {FileState, MediaCacheService} from '../services/media_cache_service';
import {SnackBarService} from '../services/snackbar_service';

/**
 * Reveal on-prem file path dialog.
 */
@Component({
  selector: 'mam-onprem-path-dialog',
  templateUrl: './onprem_path_dialog.ng.html',
  styleUrls: ['./onprem_path_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnPremPathDialog implements OnInit {
  static getDialogOptions(data: OnPremPathDialogInputData):
      MatDialogConfig<OnPremPathDialogInputData> {
    return {
      data,
      maxWidth: 800,
      width: '60%',
    };
  }

  localPath$: Observable<LocalPath|undefined> = of(undefined);

  constructor(
      private readonly mediaCache: MediaCacheService,
      private readonly snackbar: SnackBarService,
      @Inject(MAT_DIALOG_DATA) public data: OnPremPathDialogInputData,
  ) {}

  ngOnInit() {
    const {asset, checkRawSource} = this.data;

    this.localPath$ =
        this.mediaCache
            .locateOnPrem(
                asset, FileState.FILE_CLOUD_AND_ONPREM, {checkRawSource})
            .pipe(
                take(1),
                mapOnError(response => {
                  this.snackbar.error({
                    message: 'Failed to locate file on-prem.',
                    details: response.message,
                  });
                  return undefined;
                }),
            );
  }
}

/** Input data to OnPremPathDialog */
export interface OnPremPathDialogInputData {
  asset: Asset;
  // If we are looking at the raw source.
  checkRawSource: boolean;
}
