import {ApiCompReelInfo} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

import {CompReelData} from './comp-reel-data';


/** A UI model representing clip bins's comp reel info */
export class CompReelInfo implements RequiredRecursively<ApiCompReelInfo> {
  compReelData: Record<string, CompReelData> = {};

  constructor(apiInfo: ApiCompReelInfo = {}) {
    for (const [key, value] of Object.entries(apiInfo.compReelData ?? {})) {
      this.compReelData[key] = new CompReelData(value);
    }
  }
}
