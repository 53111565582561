<h1 mat-dialog-title class="dialog__title--size">Rename Clip</h1>
<div mat-dialog-content>
  <mat-form-field class="dialog__input--spacing dialog__input--size" appearance="outline" subscriptSizing="dynamic">
    <input
        placeholder="Name"
        matInput
        #input
        aria-label="Clip Rename"
        value='{{data.title}}'
        autocomplete="off"
        (keyup.enter)="checkInputBeforeClose(input.value)">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel clip rename"
      [mam-firebase-resource]="data.name">
    Cancel
  </button>
  <button
      mat-button
      [mat-dialog-close]="input.value"
      [disabled]="!input.value.trim()"
      class="primary"
      (click)="checkInputBeforeClose(input.value)"
      #primary
      mam-firebase-ga="Confirm clip rename"
      [mam-firebase-resource]="data.name">
    Save
  </button>
</div>
