<div [ngClass]="{'loading':isLoading}">
  <h1 mat-dialog-title>Move Clip Bin: {{this.data.title}}</h1>
  <div mat-dialog-content>
    <div class="panel-body">
      <div class="current-folder-container">
          @if(parentFolder) {
            <p>Current folder: {{parentFolder.displayName}}</p>
          } @else {
            <p>Current folder: root</p>
          }
      </div>
        <!-- Dark Overlay while waiting for reorder api -->
        <!-- <div [class.disabled-drag-and-drop]="disableDragAndDrop"></div> -->

        <!-- Folder selection dropdown -->
        <p>Move to:</p>
        <mat-form-field class="folder-select-form panel-padded" appearance="outline" subscriptSizing="dynamic">
          <mat-select
            panelClass="folder-panel"
            [formControl]="selectFolderControl"
            [compareWith]="compareFolders"
            mam-firebase-ga="Open folder selection"
            disableOptionCentering
            [placeholder]="(loading$ | async) ? 'Loading...' : 'Choose a folder '"
            (openedChange)="onSelectOpenedChanged($event)"
            hideSingleSelectionIndicator>

            <!-- Customized the display for selected item -->
            <mat-select-trigger
              *ngIf="folderSearchControl.value"
              [title]="folderSearchControl.value.title">
                {{folderSearchControl.value.title}}
            </mat-select-trigger>

            <!-- search box -->
            <mat-form-field class="folder-search-form" appearance="outline">
              <mat-icon matPrefix>search</mat-icon>
              <!-- The (keydown) prevents auto-closure of the dropdown -->
              <input
                #searchInput
                matInput
                [formControl]="folderSearchControl"
                placeholder="Search my folders"
                aria-label="Search my folders"
                autocomplete="off"
                (keydown)="$event.stopPropagation()" />
            </mat-form-field>

            <div
              infinite-scroll
              #binsScrollableView
              class="scrollable-options">
              <!-- Dropdown select options -->
              <mat-option
                *ngFor="let folder of (listedFolders$ | async); trackBy: trackId"
                mam-firebase-ga="Select folder"
                [mam-firebase-resource]="folder.displayName"
                [value]="folder">
                <ng-container *ngTemplateOutlet="folderSelection; context: {folder: folder}">
                </ng-container>
              </mat-option>

              <!-- Visual placeholder in case of zero bins at all or searched -->
              <mat-option class="no-folder-placeholder" *ngIf="(myFolders$ | async)?.length === 0">
                No  found.
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel clip bin move"
      [mam-firebase-resource]="data.name">
      Cancel
    </button>
    <button
      mat-button
      cdkFocusInitial
      [mat-dialog-close]="true"
      (keyup.enter)="dialogRef.close(true)"
      [disabled]="!selectFolderControl.value || selectFolderControl.value === parentFolder"
      class="danger"
      mam-firebase-ga="Confirm clip bin move"
      [mam-firebase-resource]="data.name">
      Move
    </button>
  </div>


  <ng-template #folderSelection let-folder="folder">
    <div *ngIf="folder" class="folder-option">
      <div class="folder-icon">
        <img src="../../images/folders_icon.svg" alt="Folder Option icon">
      </div>
      <div class="folder-titles">
        <span class="title" [attr.title]="folder.displayName">
          {{folder.displayName}}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>
  </ng-template>

</div>
