<ng-container *mamPluginModeOff>
  <div class="fox-mini-logo">
    <img src="images/fox_logo.png" alt="fox logo">
  </div>
</ng-container>

<div
    class="user-icon"
    [class.internal]="userIsInternal"
    title="User">
  <img
      [src]="userIconUrl"
      alt="User Icon"
      referrerpolicy="no-referrer"
      (error)="loadUserIconPlaceholder()">
</div>

<button
    class="toolbar-menu__button--position"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Mam options"
    mam-firebase-ga="Open toolbar menu">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #menu="matMenu" class="mat-pop">
  <div class="menu-site-selector" *ngIf="stateService.currentView$ | async as currentView">
    <button class="home-toolbar__menu-item--spacing"  mat-menu-item *ngIf="currentView !== HomeView.JOBS" (click)="openChangeRegion()">
      <span class="dropdown-title">Change Site : </span>
      <span *ngIf="mediaCache.state.selectedSite$ | async as selectedSite" class="site-dropdown">{{selectedSite.siteId |
          uppercase}}</span>
      <mat-icon class="arrow site-dropdown">arrow_drop_down</mat-icon>
    </button>
  </div>
  <button
    class="home-toolbar__menu-item--spacing"
    mat-menu-item mam-firebase-ga="Open clip or bin by URL"
    (click)="openNavigateToClipBin()">
    Open Clip Bin
  </button>
  <button
      class="home-toolbar__menu-item--spacing"
      *mamPluginModeOn
      mat-menu-item
      mam-firebase-ga="Reload page"
      (click)="pluginService.reload()">
    Reload
  </button>
  <button
      class="home-toolbar__menu-item--spacing"
      mat-menu-item
      mam-firebase-ga="Manage sharing links"
      (click)="openSharedLinksManagerDialog()">
    Manage Sharing Links
  </button>
  <button
      class="home-toolbar__menu-item--spacing"
      mat-menu-item
      mam-firebase-ga="Log out"
      (click)="logout()">
    Log Out
  </button>
</mat-menu>
