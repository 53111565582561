/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiFacetGroup } from './api-facet-group';
import { ApiSortByOption } from './api-sort-by-option';


/**
 * Request message for SearchVideos.
 */
export interface ApiSearchVideosRequest { 
    /**
     * Stores most recent facet selection state. Only facet groups with user\'s selection will be presented here. Selection state is either selected or unselected. Only ONE `facet_results` array is allowed in each FacetGroup element.
     */
    facetSelections?: Array<ApiFacetGroup>;
    /**
     * The number of results to be returned in this page. If it\'s 0, the server will decide the appropriate page_size.
     */
    pageSize?: number;
    /**
     * The continuation token to fetch the next page. If empty, it means it is fetching the first page.
     */
    pageToken?: string;
    /**
     * Required. The parent corpus to search. Form: `projects/{project_id}/locations/{location_id}/corpora/{corpus_id}\'
     */
    parent?: string;
    /**
     * Search query.
     */
    query?: string;
    /**
     * Specify sortby_options to rank search results by given UGA fields. If no sortby_options specified, search results will be ranked by default semantic relevance. Each SortByOption specifies a UGA field and their ranking options. Multiple SortByOptions will be handled in order: the first option acts as the primary sort key, the second option the secondary sort key, etc. Search result with no UGA values are always ranked after search results with UGA values. Only string and numeric UGA fields are supported for sorting. There cannot be duplicate UGA field keys in the options.
     */
    sortbyOptions?: Array<ApiSortByOption>;
    /**
     * Current behavior assumes SEARCH_VIDEOS_REQUEST_TYPE_SEGMENT if SEARCH_VIDEOS_REQUEST_TYPE_UNSPECIFIED is set.
     */
    type?: ApiSearchVideosRequest.TypeEnum;
}
export namespace ApiSearchVideosRequest {
    export type TypeEnum = 'SEARCH_VIDEOS_REQUEST_TYPE_UNSPECIFIED' | 'SEARCH_VIDEOS_REQUEST_TYPE_VIDEO' | 'SEARCH_VIDEOS_REQUEST_TYPE_SEGMENT';
    export const TypeEnum = {
        SEARCH_VIDEOS_REQUEST_TYPE_UNSPECIFIED: 'SEARCH_VIDEOS_REQUEST_TYPE_UNSPECIFIED' as TypeEnum,
        SEARCH_VIDEOS_REQUEST_TYPE_VIDEO: 'SEARCH_VIDEOS_REQUEST_TYPE_VIDEO' as TypeEnum,
        SEARCH_VIDEOS_REQUEST_TYPE_SEGMENT: 'SEARCH_VIDEOS_REQUEST_TYPE_SEGMENT' as TypeEnum
    };
}


