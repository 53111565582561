import {ApiExportInfo} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

import {ExportStatus} from './export-status';

/** A UI model representing clip's export information  */
export class ExportInfo implements RequiredRecursively<ApiExportInfo> {
  stateMap: Record<string, ExportStatus> = {};

  constructor(apiPfrInfo: ApiExportInfo = {}) {
    for (const [key, value] of Object.entries(apiPfrInfo.stateMap ?? {})) {
      this.stateMap[key] = new ExportStatus(value);
    }
  }
}
