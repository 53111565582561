import {Injectable} from '@angular/core';
import {map, Observable, throwError} from 'rxjs';

import {ApiCloudTransferJob, ApiStorageTransferInfo} from 'api/ias/model/models';
import {CloudTransferOperation, Folder} from 'models';

import {IasApiClient} from './api_client.module';

/**
 * Cloud transfer related APIs.
 */
@Injectable({providedIn: 'root'})
export class CloudIngestApiService {
  constructor(
      private readonly apiClient: IasApiClient,
  ) {}

  initiateCloudTransfer(site: string, cloudTransferJob: ApiCloudTransferJob):
      Observable<Folder> {
    try {
      return this.apiClient
          .sitesFoldersInitiateCloudTransfer({site, body: {cloudTransferJob}})
          .pipe(map(f => new Folder(f)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  /**
   * List the latest N transfer operations (pageSize) of the given transfer job
   * and its associated operation state.
   */
  listTransferOperations(jobId: string, pageSize: number):
      Observable<TransferOperationsListResponse|null> {
    try {
      return this.apiClient
          .sitesFoldersTransferOperationsList({
            parent: jobId,
            pageSize,
          })
          .pipe(map(resp => ({
                      nextPageToken: resp.nextPageToken,
                      transferOperations:
                          (resp.transferOperations ??
                           []).map(op => new CloudTransferOperation(op))
                    })));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  updateTransferInfo(
      folderName: string,
      jobState: ApiStorageTransferInfo.JobStateEnum): Observable<Folder|null> {
    try {
      return this.apiClient
          .sitesFoldersPatch({
            name: folderName,
            body: {storageTransferInfo: {jobState}},
            updateMask: 'storage_transfer_info',
          })
          .pipe(map(f => new Folder(f)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }
}

/** Response for TransferOperations list call. */
export interface TransferOperationsListResponse {
  transferOperations: CloudTransferOperation[];
  nextPageToken?: string;
}
