import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { ClipBinsFoldersService, FolderItemRef } from '../../firebase/clip_bins_folders.service';
import {SnackBarService} from '../../services/snackbar_service';

/** Clipbin deletion dialog */
@Component({
  selector: 'mam-delete-bin-dialog',
  templateUrl: './clipbin_folder_delete_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClipBinFolderDeleteDialog implements OnInit {
  static readonly dialogOptions = {
    hasBackdrop: true,
  };

  constructor(
      readonly dialogRef: MatDialogRef<ClipBinFolderDeleteDialog>,
      private readonly snackBar: SnackBarService,
      private readonly clipBinFolderService: ClipBinsFoldersService,
      @Inject(MAT_DIALOG_DATA) public data: FolderItemRef) {}

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe((deletionConfirmed) => {
      if (!deletionConfirmed) return;
      this.data.active = false;
      this.clipBinFolderService.updateFolder(this.data).subscribe({
        next: () => {
          //TODO: remove references in folder
          this.snackBar.message('Folder has been deleted successfully.');
        },
        error: (error) => {
          this.snackBar.error(
              'Folder could not be deleted.', undefined, error);
        }
      });
    });
  }
}
