import { Injectable, NgZone } from '@angular/core';

import { AuthService } from '../auth/auth_service';
import { environment } from '../environments/environment';
import { PluginService } from '../plugin/plugin_service';

import { FirebaseResolver } from './firebase_resolver';
import { PageContextResolver } from './page_context_resolver';

/**
 * Search-related parameters that cam be added to firebase logs.
 */
export declare interface SearchContext {
  term?: string;
  terms?: string;
  facets?: string;
  searchMode?: string;
}

export declare interface ProfileDetailsParams {
  /**   Email Id of logged user   */
  emailID?: string;
  /** User name of logged user */
  userName?: string;
  /** profile image of logged user */
  profileIcon?: string;
}


/** Event type that will trigger analytics logging. */

export enum AnalyticsEventType {
  // Standard html events (compatible with `addEventListener`)
  CLICK = 'click',
  KEY_DOWN = 'keydown',
  SCROLL = 'scroll',
  COPY = 'copy',
  MOUSE_MOVE = 'mousemove',
  // Custom events
  MOUSE_ENTER_LEAVE = 'mouse_enter_leave',
  NAVIGATION = 'navigation',
  SEARCH = 'search',
  API_CALL = 'api_call',
  LOG = 'log',
  TRACE = 'performance:trace',
  PUBSUB_MESSAGE = 'pubsub_message',
  SAVE_PREFERENCE = 'save_preference',
  PLUGIN_ACTION = `plugin_action`,
}

/**
 * Parameters that are automatically added to firebase logs. These should not
 * be set manually from the call site.
 */
export declare interface ImplicitContext {
  environment?: string;
  /** Whether the current user is a Googler. */
  internalUser?: boolean;
  /** Plugin version, or `undefined` from a desktop browser. */
  plugin?: string;
  /** @deprecated use `plugin` instead */
  isPlugin?: boolean;
}

/**
 * Optional parameters that can be added to firebase logs.
 */
export declare interface MamEventParams extends SearchContext {
  /** Category of event (required) */
  eventType: AnalyticsEventType;
  /** IAS resource associated with the event */
  resource?: string;
  /** Duration of the event */
  duration?: number;
  /** Array index */
  index?: number;
  /** URL query parameters */
  queryParams?: string;
  /** Stringified object literal */
  object?: string;
  /** Generic number value */
  number1?: number;
  /** Generic number value */
  number2?: number;
  /** Generic string value */
  string1?: string;
  /** Generic string value */
  string2?: string;
  /** Generic boolean value */
  boolean1?: boolean;

  /** @deprecated - use number1 instead */
  status?: number;
  /** @deprecated - use string1 instead */
  methodId?: string;
  /** @deprecated - use string2 instead */
  path?: string;
  /** Indicates that the request was sent from external IAS build */
  build?: 'ias-external';
}

/**
 * All parameters that may be added to firebase logs.
 */
export declare type AllEventParams = MamEventParams & ImplicitContext & ProfileDetailsParams;

/**
 * This token will be replaced with current page name at the time of event
 * logging.
 */
export const PAGE_CONTEXT_TOKEN = '%PAGE_CONTEXT%';

/**
 * A service for firebase analytics related tasks.
 */
@Injectable({ providedIn: 'root' })
export class FirebaseAnalyticsService {
  constructor(
    private readonly firebaseResolver: FirebaseResolver,
    private readonly pageContextResolver: PageContextResolver,
    private readonly authService: AuthService,
    private readonly ngZone: NgZone,
    private readonly pluginService: PluginService,
  ) { }

  /** Log analytics events with event parameters. */
  logEvent(eventName: string, eventParams: MamEventParams) {
    const allEventParams: AllEventParams = {
      ...eventParams,
      environment: environment.tag,
      internalUser: this.authService.isInternalUser(),
      isPlugin: this.authService.isPlugin(),
      plugin: this.pluginService.version,
      build: 'ias-external',
      emailID: this.authService.getUserEmail(),
      profileIcon: this.authService.getUserIcon(),
      userName: this.authService.getUserName(),
    };

    // Run this block outside of Angular to prevent e2e tests from timing out
    // waiting for all logging related async operations to resolve.
    this.ngZone.runOutsideAngular(() => {
      if (!this.firebaseResolver.logEvent(eventName, allEventParams)) {
        this.printEvent(eventName, allEventParams);
      }
    });
  }

  /** Log analytics event, add information about current search query. */
  logSearchEvent(
    eventName: string, eventParams?: Omit<MamEventParams, 'eventType'>) {
    this.logEvent(eventName, this.includeSearchContext(eventParams));
  }

  /**
   * Returns time in milliseconds since the document context was created.
   */
  getTimestamp() {
    return window.performance.now();
  }

  getActivePageName() {
    return this.pageContextResolver.getActivePageName();
  }

  private includeSearchContext(
    eventParams: Omit<MamEventParams, 'eventType'> = {}): MamEventParams {
    const searchContext = this.pageContextResolver.getSearchParams();

    // Override any search context parameter by those passed as arguments.
    return {
      eventType: AnalyticsEventType.SEARCH,
      ...searchContext,
      ...eventParams,
    };
  }

  /**
   * Logs events to the console (debug level), except for API calls which
   * have better support from the Network tab.
   */
  private printEvent(eventName: string, eventParams: AllEventParams) {
    if (eventParams.eventType !== AnalyticsEventType.API_CALL) {
      console.debug(`[Analytics] ${eventName}`, eventParams);
    }
  }
}

/** Used for unit tests to avoid checking if user is internal and logging. */
@Injectable()
export class FirebaseAnalyticsStub extends FirebaseAnalyticsService {
  override logEvent() {
    // Logs are hidden during tests.
  }
}
