import {Inject, Injectable, InjectionToken} from '@angular/core';
import {ActivatedRoute, NavigationBehaviorOptions, ParamMap, Router} from '@angular/router';
import {combineLatest, EMPTY, firstValueFrom, Observable, of, ReplaySubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, pairwise, shareReplay, skip, startWith, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {assertTruthy, assumeExhaustive, castExists} from 'asserts/asserts';
import {SharedLink} from 'models';

import {AuthService} from '../auth/auth_service';
import {mapOnError} from '../error_service/error_response';
import {LiveAssetService} from '../live/live_asset_service';
import {MetadataService} from '../right_panel/metadata_service';
import {Asset, AssetService} from '../services/asset_service';
import {QUERY_SEPARATOR, QuerySegment, SearchSegment, SearchService} from '../services/search_service';
import {convertApiSharedLinkToUiAsset, SharedLinksService} from '../services/shared_links_service';
import {StateService} from '../services/state_service';
import {UtilsService} from '../services/utils_service';
import {VodSearchService} from '../services/vod_search_service';

/** Injection token for reading the current URL. */
export const GET_CURRENT_URL = new InjectionToken<() => string>(
    'Current URL', {factory: () => () => window.location.href});

/** URL query parameters used for navigation between assets. */
export enum NavigationParam {
  /**
   * Indicates that we are navigating between assets of this `ContextType`, for
   * instance search results, or clips, ar recent assets.
   */
  TYPE = 'type',
  /**
   * Used to initialize the index of contextual items before being removed from
   * the URL. Useful when we open a specific search result, since deducting the
   * index from its asset name is not enough.
   */
  INITIAL_INDEX = 'initialIndex',
  /** Indicates the search query leading to this asset. */
  QUERY = 'query',
  /** Indicates the search facets used in combination with the search query. */
  FACETS = 'facets',
}

/** Recognized route params. */
enum RouteParam {
  /** Name of the clipbin. */
  CLIPBIN = 'clipbinname',
  /** Name of the asset. */
  ASSET_NAME = 'assetname',
  /** Encoded hash of the shared link name (for public sharing). */
  LINK_HASH = 'linkhash',
}

/**
 * Indicates which type of items we are navigating between. Values are written
 * in URL, any changes to them may break existing URLs.
 */
export enum ContextType {
  NONE = 'none',
  RECENT = 'recent',
  VOD_SEARCH = 'search',
  BIN = 'bin',
  LIVE_AIRED = 'aired',
  LIVE_AIRING = 'airing',
  CAMERAS = 'cameras',
  VOD_STAGING = 'vod-staging',
  LIVE_STAGING = 'live-staging',
}

/**
 * Special context types that do not affect the breadcrumb origin. Instead,
 * it will be based on the previous real context type, or inferred from the
 * current asset if there is no previous type.
 */
const NO_ORIGIN_TYPES = [ContextType.CAMERAS, ContextType.BIN] as const;

/**
 * Query parameter used for internal debug, allowing to navigate to a live
 * asset even though regular users would be redirected to its full-duration VoD
 * copy.
 */
const FORCE_LIVE_QUERY_PARAMETER = 'forceLive';

/** Describes available global app sections. */
export enum NavigationRoot {
  /** Live landing view. */
  LIVE_LANDING = 'live',
  /** Home view or search results view. */
  VOD_LANDING_OR_SEARCH = 'vod',
  /** Live Staging View */
  VOD_STAGING = 'vod-staging',
  /** Vod Staging View */
  LIVE_STAGING = 'live-staging',
}

/**
 * Contains information about the global app section we should go to from the
 * details view. i.e. VOD landing or Live landing.
 */
export interface NavigationOrigin {
  /** App section should we go to from the details view. */
  root: NavigationRoot;
  /**
   * Indicates if the root was guessed based on the asset state. Used to
   * determine correct breadcrumb root item.
   */
  inferred: boolean;
}

/** Dynamic navigation context. */
export interface Context {
  /** Current asset */
  asset: Asset|null;
  /** Query text and timings if any */
  querySegment?: QuerySegment;
  /** User Query text for search results */
  userQuery?: string;
  /** Current context type. */
  type?: ContextType;
  /** Primary asset in multi-camera event. Used for `cameras` context. */
  primaryCameraAsset?: Asset;
  /**
   * Indicates which app section should we go to from the details view.
   * i.e. VOD landing or Live landing.
   */
  origin?: NavigationOrigin;
  /** Optional link resource used when accessing a shared video. */
  link?: SharedLink;
}

/** Asset with additional presentation data for the details page */
export type DetailItem = Asset|SearchSegment;

/**
 * Empty navigation query params. Used to clean up navigation related query
 * params from the url.
 */
export const EMPTY_NAVIGATION_PARAMS = {
  [NavigationParam.TYPE]: undefined,
  [NavigationParam.INITIAL_INDEX]: undefined,
};

/** Empty context makes it easier to build context in tests. */
export const EMPTY_CONTEXT: Context = {
  asset: null,
  querySegment: undefined,
  primaryCameraAsset: undefined,
  link: undefined,
  type: ContextType.NONE,
  origin: {
    root: NavigationRoot.VOD_LANDING_OR_SEARCH,
    inferred: false,
  }
};

/** List of items to navigate within and their type. */
interface SourceItems {
  items: DetailItem[];
  type: ContextType;
}

/* List of items + index of the current one in context (if known). */
type ContextSource = Observable<{index?: number}&SourceItems>;

/** Provides helpers to navigate between items in the details page. */
@Injectable({providedIn: 'root'})
export class DetailsNavigationService {
  /** Whether it is possible to navigate to the previous asset */
  canGoPrevious$: Observable<boolean>;

  /** Whether it is possible to navigate to the next asset */
  canGoNext$: Observable<boolean>;

  /** Current context to be displayed by the Details component. */
  context$: Observable<Context>;

  /** Sources that trigger a new context. */
  private readonly sources$: ContextSource;

  constructor(
      private readonly route: ActivatedRoute,
      private readonly router: Router,
      private readonly assetService: AssetService,
      private readonly vodSearchService: VodSearchService,
      private readonly searchService: SearchService,
      private readonly liveService: LiveAssetService,
      private readonly sharedLinks: SharedLinksService,
      private readonly metadataService: MetadataService,
      private readonly stateService: StateService,
      private readonly utils: UtilsService,
      private readonly authService: AuthService,
      @Inject(GET_CURRENT_URL) private readonly getCurrentUrl: () => string,
  ) {
    const sourceItems$ = this.watchItems();

    // The current context sources are the list of items with their type,
    // and the index of the active item.
    this.sources$ = combineLatest([sourceItems$, this.index$])
                        .pipe(
                            map(([items, index]) => ({...items, index})),
                            // Debounce to avoid 2 events when `index` and
                            // `items` are updated at the same time.
                            debounceTime(0),
                        );

    this.canGoPrevious$ = this.getCanGoPrevious();
    this.canGoNext$ = this.getCanGoNext();
    this.context$ = this.watchContext();

    // Track previous context type
    sourceItems$
        .pipe(
            map(items => items.type), startWith(ContextType.NONE),
            filter(type => !this.utils.includes(NO_ORIGIN_TYPES, type)),
            pairwise())
        .subscribe(([previousType]) => {
          this.previousContextTypeInternal = previousType;
        });

    // When the list of items changes, reset the index since it was an index
    // from the previous list. The next index will be inferred from the position
    // of the current item in the new list.
    sourceItems$.subscribe(() => {
      this.index$.next(undefined);
    });
  }

  getCurrentClipBinName(): string|undefined {
    return this.getRouteParam(RouteParam.CLIPBIN);
  }

  watchRouteClipBinName(): Observable<string|undefined> {
    return this.innerRoute.paramMap.pipe(
        map((paramMap: ParamMap) => {
          return paramMap.get(RouteParam.CLIPBIN) || undefined;
        }),
        distinctUntilChanged());
  }

  watchRouteAssetName(): Observable<string|undefined> {
    return this.innerRoute.paramMap.pipe(
        map((paramMap: ParamMap) => {
          return paramMap.get(RouteParam.ASSET_NAME) || undefined;
        }),
        distinctUntilChanged());
  }

  /**
   * Used to capture the URL of an asset. The query parameters related to
   * navigation are removed as they could lead to opening a different asset than
   * the one from the URL, since their index position may have changed.
   */
  getAssetUrl() {
    const url = new URL(this.getCurrentUrl());
    // For instance, remove "&index=4" or `query=foo` from the current URL.
    for (const key of Object.values(NavigationParam)) {
      // Keep 'type' in the url to help reconstruct asset context.
      if (key === NavigationParam.TYPE) continue;
      url.searchParams.delete(key);
    }
    return url.toString();
  }

  getClipbinUrl() {
    // Removes the current "clip/assetname" part from the asset URL.
    return this.getAssetUrl().replace(/\/clip\/[^?&/]*/, '');
  }

  async getCurrentIndex() {
    const {index} = await firstValueFrom(this.sources$);
    assertTruthy(
        index != null,
        'DetailsNavigationService.getCurrentIndex expected index');

    return index;
  }

  /** Updates query param `index` to previous one */
  async previous() {
    await this.updateIndex(await this.getCurrentIndex() - 1);
  }

  /** Updates query param `index` to next one */
  async next() {
    await this.updateIndex(await this.getCurrentIndex() + 1);
  }

  /**
   * Updates the contextual type in URL which will trigger type$, itself
   * triggering items$ and emitting a new context.
   */
  updateType(type?: ContextType, replaceUrl?: boolean) {
    this.navigate([], {[NavigationParam.TYPE]: type}, {replaceUrl});
  }

  /** Navigates to 404 page replacing the current state in the history. */
  navigateTo404() {
    return this.router.navigate(['/404'], {
      replaceUrl: true,
      queryParamsHandling: 'preserve',
    });
  }

  /** Navigate based on the provided array of commands. */
  navigate(
      commands: string[],
      queryParams:
          Record<string, string|number|undefined> = EMPTY_NAVIGATION_PARAMS,
      options: NavigationBehaviorOptions = {}) {
    return this.router.navigate(
        commands, {queryParams, queryParamsHandling: 'merge', ...options});
  }

  getPreviousContextType() {
    return this.previousContextTypeInternal;
  }

  isSharedVideo() {
    return this.getRouteParam(RouteParam.LINK_HASH) != null;
  }

  /**
   * Searches for the given asset name in the current contextual list of items
   * and updates the navigation index accordingly.
   */
  async selectAsset(assetName: string) {
    const sources = await firstValueFrom(this.sources$);

    // In case of search, ignore asset change from the URL. Instead,
    // watchContext will look for an `initialIndex`.
    if (sources.type === ContextType.VOD_SEARCH) return;

    // Try to find the position of the asset name in the current list of items.
    const index = this.inferIndex(sources, assetName);
    if (index != null) {
      await this.updateIndex(index);
    }
  }

  /** Index of the current asset displayed. */
  private readonly index$ = new ReplaySubject<number|undefined>(1);

  private previousContextTypeInternal = ContextType.NONE;

  /**
   * Determine the index of the current asset name (from the URL) out of the
   * current list of contextual items, unless provided with `initialIndex` (in
   * the case of a search result)
   */
  private inferIndex(sources: SourceItems, assetName: string): number
      |undefined {
    const initialIndex =
        Number(this.getQueryParam(NavigationParam.INITIAL_INDEX));
    if (!Number.isNaN(initialIndex)) {
      return initialIndex;
    }

    // Special case where the asset in URL is an index. Used to open a clipbin
    // without knowing the name of its first clip.
    if (Number.isInteger(Number(assetName))) {
      const nameIndex = Number(assetName);
      return (sources.items[nameIndex]) ? nameIndex : undefined;
    }

    const indexOfCurrentAsset = sources.items.findIndex(
        item => this.getItemAsset(item).name === assetName);
    if (indexOfCurrentAsset !== -1) {
      return indexOfCurrentAsset;
    }

    return undefined;
  }

  /**
   * Observes changes to URL context for navigation.
   */
  private watchContext(): Observable<Context> {
    return this.sources$.pipe(
        // If we're loading a new page, we temporarily have a index
        // outside the current items boundaries, wait for items update
        // event.
        filter(({index, items}) => {
          return (index == null || !items.length || index !== items.length);
        }),
        switchMap(sources => {
          const {index, items, type} = sources;

          // If we opened a shared link, extract its context.
          const linkHash = this.getRouteParam(RouteParam.LINK_HASH);
          if (linkHash) {
            return this.getSharedLinkContext(linkHash);
          }

          const assetNameParam = this.getRouteParam(RouteParam.ASSET_NAME);

          // When index is undefined, try to infer it by finding the asset
          // position in the list of items, or by other means.
          if (index == null && assetNameParam) {
            const inferredIndex = this.inferIndex(sources, assetNameParam);
            if (inferredIndex != null) {
              this.index$.next(inferredIndex);
              return EMPTY;
            }
          }

          if (type === ContextType.BIN) {
            // If we just opened a clipbin, initially its list of clips is
            // empty, do nothing until we get clips which will emit new
            // items$.
            if (!items.length) return EMPTY;

            // If the list of clips is from a different clipbin than the one
            // in URL, do nothing, arrow navigation will be disabled.
            const firstAsset = this.getItemAsset(items[0]);
            if (firstAsset.original &&
                firstAsset.label !== this.getCurrentClipBinName()) {
              return EMPTY;
            }
          }

          const userQuery = this.convertUrlQuery(
              this.getQueryParam(NavigationParam.QUERY) || '');

          // We reach this case when we have no real context or the asset was
          // not found in context: simply load the asset in URL.
          if (index == null) {
            // From this point, an asset name in URL is expected. It was only
            // optional when we open a clipbin without a clip in the URL.
            if (!assetNameParam) {
              this.navigateTo404();
              return EMPTY;
            }

            // For staging assets we don't have items and need to re-fetch the
            // asset while keeping navigation context to allow the user to go
            // back to live / vod staging view
            if (type === ContextType.VOD_STAGING ||
                type === ContextType.LIVE_STAGING) {
              return this.fetchAsset(assetNameParam)
                  .pipe(map(asset => ({
                              ...EMPTY_CONTEXT,
                              type,
                              asset,
                              origin: this.getNavigationOrigin(asset, type)
                            })));
            }

            // Clear navigation params from the current URL.
            this.navigate([], EMPTY_NAVIGATION_PARAMS, {replaceUrl: true});

            return this.fetchAsset(assetNameParam)
                .pipe(map(
                    asset => ({
                      ...EMPTY_CONTEXT,
                      asset,
                      type,
                      userQuery,
                      origin: this.getNavigationOrigin(asset, ContextType.NONE)
                    })));
          }

          // If we have an invalid index with a real context, erase any
          // navigation from the URL which will trigger watchContext with
          // a type "none"
          if (!items[index]) {
            this.updateType(undefined, true);
            return EMPTY;
          }

          // We reach this main case when we have a valid index of a list of
          // items. Navigation to a previous or next item will be enabled.
          const item = items[index];
          const asset = this.getItemAsset(item);
          const querySegment = this.getItemSegment(item);
          const primaryCameraAsset = this.findPrimaryCameraAsset(type, items);

          const context: Context = {
            asset,
            querySegment,
            userQuery,
            type,
            primaryCameraAsset,
            origin: this.getNavigationOrigin(asset, type),
          };

          // Return the asset in context.
          return of(context);
        }),
        // If the asset is live with a full-duration copy (archived), swap the
        // asset in context so that the VoD copy is displayed instead.
        switchMap(context => {
          const {asset} = context;

          const archiveName = asset?.archiveName;

          // For debugging purposes, internal users can force viewing the live
          // asset in the Details view by adding a special query parameter to
          // the URL.
          const url = this.getCurrentUrl();
          const parsedUrl = new URL(url);
          const forceLive = this.authService.isInternalUser() &&
              parsedUrl.searchParams.get(FORCE_LIVE_QUERY_PARAMETER);

          if (forceLive || !archiveName || asset.original) return of(context);

          return this.assetService.getAsset(archiveName).pipe(map(archive => {
            return {
              ...context,
              asset: archive,
            };
          }));
        }),
        // Update the URL with contextual asset name (no side effect).
        tap(context => {
          const asset = context.asset;
          if (!asset || context.link) return;

          const path = !asset.original ?
              ['asset', asset.name] :
              ['clipbin', asset.label, 'clip', asset.name];
          this.navigate(
              path,
              {
                // Remove initialIndex from URL.
                [NavigationParam.INITIAL_INDEX]: undefined,
                // Ensure that the context type is in the URL, see b/246985305.
                [NavigationParam.TYPE]: context.type,
              },
              // Always replace history within the Details view so that
              // pressing "back" will return to the previous page.
              {replaceUrl: true},
          );
        }),
        shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  getSharedLinkContext(linkHash: string) {
    return this.sharedLinks.getPreviewFromHash(linkHash).pipe(map(link => {
      if (!link) return {...EMPTY_CONTEXT};
      const asset = convertApiSharedLinkToUiAsset(link);
      return {...EMPTY_CONTEXT, asset, link};
    }));
  }

  private getNavigationOrigin(
      asset: Asset|null, type: ContextType,
      previousType = this.getPreviousContextType()): NavigationOrigin {
    let inferredRoot = NavigationRoot.VOD_LANDING_OR_SEARCH;
    if (asset != null) {
      const isNotApproved = !asset.original && !asset.approved;
      // Prioritize Live Landing over Live Staging for live assets.
      inferredRoot = asset.isLive ?
        NavigationRoot.LIVE_LANDING :
        (
          isNotApproved ?
            NavigationRoot.VOD_STAGING :
            NavigationRoot.VOD_LANDING_OR_SEARCH
        );
    }

    if (this.utils.includes(NO_ORIGIN_TYPES, type)) {
      // These contexts are nested, so we check based on the previous type. For
      // instance if we come from a live search and select a camera or click on
      // a clip, the breadcrumb should allow to go back to live results. In the
      // absence of previous context, infer origin from the current asset,
      // either Home or Live landing.
      return this.getNavigationOrigin(asset, previousType, ContextType.NONE);
    }

    switch (type) {
      case ContextType.LIVE_AIRED:
      case ContextType.LIVE_AIRING:
        return {root: NavigationRoot.LIVE_LANDING, inferred: false};
      case ContextType.RECENT:
      case ContextType.VOD_SEARCH:
        return {root: NavigationRoot.VOD_LANDING_OR_SEARCH, inferred: false};
      case undefined:
      case ContextType.LIVE_STAGING:
        return {root: NavigationRoot.LIVE_STAGING, inferred: false};
      case ContextType.VOD_STAGING:
        return {root: NavigationRoot.VOD_STAGING, inferred: false};
      case ContextType.NONE:
        // Fallback to asset state.
        return {root: inferredRoot, inferred: Boolean(asset)};
      default:
        assumeExhaustive(type);
        return {root: NavigationRoot.VOD_LANDING_OR_SEARCH, inferred: false};
    }
  }

  private getItemAsset(item: DetailItem): Asset {
    return this.isSearchSegment(item) ? item.asset : item;
  }

  private getItemSegment(item: DetailItem): QuerySegment|undefined {
    return this.isSearchSegment(item) ? item : undefined;
  }

  private isSearchSegment(item: DetailItem): item is SearchSegment {
    return (item as SearchSegment)?.asset != null;
  }

  private async updateIndex(newIndex: number) {
    const confirmation =
        await firstValueFrom(this.metadataService.cancelWithConfirmation());
    if (!confirmation) return;

    let waitForNewItems = false;

    const {type, index} = await firstValueFrom(this.sources$);

    if (newIndex === index) return;

    // Update the search page number that this result index belongs to. This may
    // trigger the load of a new page of results if we navigate to the first
    // item of a new page (from the last item of the current page).
    if (type === ContextType.VOD_SEARCH) {
      waitForNewItems = this.changeServicePage(this.vodSearchService, newIndex);
    }

    if (type === ContextType.LIVE_AIRED || type === ContextType.LIVE_AIRING) {
      waitForNewItems = await this.changeLiveServicePage(type, newIndex);
    }

    if (waitForNewItems) {
      // Skip the current items to wait for the next emission.
      await firstValueFrom(this.sources$.pipe(skip(1)));
    }

    this.index$.next(newIndex);
  }

  /** Loads a new page of items if needed and return whether that was needed. */
  private changeServicePage(service: VodSearchService, newIndex: number) {
    const pageSize = service.pagination.pageSize;
    const pageIndex = Math.floor(newIndex / pageSize);
    const previousPageIndex = service.pageChange$.getValue();

    if (pageIndex !== previousPageIndex) {
      service.pageChange$.next(pageIndex);
    }

    // We only need to wait for new items if the new page is greater than the
    // current one, since we already have the previous pages of items in cache.
    return pageIndex > previousPageIndex;
  }

  /** Loads a new page of items if needed and return whether that was needed. */
  private async changeLiveServicePage(type: ContextType, newIndex: number) {
    const [pagination$, pageChange$] = type === ContextType.LIVE_AIRED ?
        [this.liveService.airedPagination$, this.liveService.airedPageIndex$] :
        [this.liveService.airingPagination$, this.liveService.airingPageIndex$];

    const pageSize = (await firstValueFrom(pagination$)).pageSize;
    const pageIndex = Math.floor(newIndex / pageSize);

    if (pageIndex !== pageChange$.getValue()) {
      pageChange$.next(pageIndex);
      return true;
    }

    return false;
  }

  private get innerRoute(): ActivatedRoute {
    return castExists(
        this.route.firstChild?.firstChild,
        '<mam-details> is expected to be an inner router-outlet.');
  }

  private getCanGoPrevious(): Observable<boolean> {
    return this.sources$.pipe(map(({index, items}) => {
      if (index == null || items.length < 2 || index <= 0) return false;
      return items[index - 1] != null;
    }));
  }

  private getCanGoNext(): Observable<boolean> {
    return this.sources$.pipe(map(({index, items, type}) => {
      // We cannot go next without an index or accessible next items (for
      // instance while loading a next page of items).
      if (index == null || index >= items.length) return false;

      // There is a next item already loaded
      if (index < items.length - 1) return true;

      switch (type) {
        case ContextType.NONE:
          return false;
        case ContextType.BIN:
          // No more visible clips in the persistent panel.
          return false;
        case ContextType.RECENT:
          // There are no more items so we cannot go next.
          return false;
        case ContextType.VOD_SEARCH:
          // If we have a next page token, we can go next by loading the
          // next page.
          return Boolean(this.vodSearchService.pagination.nextPageToken);
        case ContextType.LIVE_AIRED:
        case ContextType.LIVE_AIRING:
          // Live search has all results available in memory.
          return false;
        case ContextType.CAMERAS:
          // Event cameras mode does not support pagination.
          return false;
        case ContextType.VOD_STAGING:
        case ContextType.LIVE_STAGING:
          // Live / VoD Content Staging does not support pagination
          return false;
        default:
          assumeExhaustive(type);
          return false;
      }
    }));
  }

  /** Fetches asset from the backend. */
  private fetchAsset(assetName: string): Observable<Asset|null> {
    if (this.getCurrentClipBinName()) {
      return this.assetService.getClip(assetName).pipe(mapOnError(() => null));
    }
    return this.assetService.getAsset(assetName);
  }

  /** Observes `type` from query parameters */
  private watchRouteType() {
    return this.route.queryParamMap.pipe(
        map((params: ParamMap) => {
          if (this.getCurrentClipBinName()) return ContextType.BIN;
          if (!params.has(NavigationParam.TYPE)) return ContextType.NONE;
          const key = params.get(NavigationParam.TYPE) as ContextType;
          if (!Object.values(ContextType).includes(key)) {
            return ContextType.NONE;
          }
          return key;
        }),
        distinctUntilChanged(),
        shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  private getRouteParam(param: RouteParam): string|undefined {
    return this.innerRoute.snapshot?.params[param];
  }

  private getQueryParam(param: NavigationParam): string|undefined {
    return this.innerRoute.snapshot?.queryParams[param];
  }

  /** Gets assets for navigation depending on type */
  private watchItems(): Observable<SourceItems> {
    const type$ = this.watchRouteType();
    return type$.pipe(
        switchMap(type => {
          switch (type) {
            case ContextType.NONE:
            // Live / VoD Content Staging does not support item navigation
            case ContextType.LIVE_STAGING:
            case ContextType.VOD_STAGING:
              return of(null);
            case ContextType.BIN:
              return this.stateService.currentPersistentClips$;
            case ContextType.RECENT:
              return this.assetService.cachedRecents ?
                of(this.assetService.cachedRecents) :
                this.assetService.getRecents();
            case ContextType.VOD_SEARCH:
              return this.vodSearchService.cachedResults$;
            case ContextType.LIVE_AIRED:
              return this.liveService.allAiredAssets$;
            case ContextType.LIVE_AIRING:
              return this.liveService.allAiringAssets$;
            case ContextType.CAMERAS:
              return of(this.searchService.getCachedCameraAssets());
            default:
              assumeExhaustive(type);
              return of(null);
          }
        }),
        map(items => items || []),
        withLatestFrom(type$),
        map(([items, type]) => ({items, type})),
        shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  /**
   * Locate primary camera asset if the current asset is part of the
   * multi-camera event.
   */
  private findPrimaryCameraAsset(type: ContextType, items: DetailItem[]) {
    if (type !== ContextType.CAMERAS) return;

    return items.map(i => this.getItemAsset(i))
        .find(asset => asset.camera?.isBroadcast);
  }

  /**
   * Converts list of url queries to the user input query
   *
   * @param query e.g. "query1;query2;query3"
   * @returns e.g. "query1, query2, query3"
   */
  private convertUrlQuery(query: string) {
    // Regex finds all occurrences of ';'
    return query.replace(new RegExp(QUERY_SEPARATOR, 'g'), ' ');
  }
}
