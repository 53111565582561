<div class="video-preview">
  <mam-asset-thumbnail
      *ngIf="showThumbnail()"
      [time]="thumbTime"
      [asset]="asset"
      [class.invisible]="playheadLeft > 0"
      (thumbnailLoad)="onThumbnailLoaded($event)"
  ></mam-asset-thumbnail>

  <mam-video-player
      #videoPlayer
      [preload]="preload"
      [mam-firebase-ga]="'Preview seek on ' + PAGE_CONTEXT_TOKEN"
      [mam-firebase-resource]="asset?.name"
      [mam-firebase-type]="AnalyticsEventType.MOUSE_ENTER_LEAVE">
  </mam-video-player>

  <div
      class="playhead"
      *ngIf="playheadLeft > 0"
      [style.left.px]="playheadLeft"
  ></div>
  <div class="asset-duration" *ngIf="asset?.duration && isRecentAsset">{{asset?.duration | duration}}</div>
</div>
