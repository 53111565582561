import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {DateTime} from 'luxon';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from 'rxjs';
import {filter, map, take, takeUntil} from 'rxjs/operators';

import { ScrubbingService } from 'shared/scrubbing_service';

import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {FirebasePerformanceService, Trace, TraceName} from '../firebase/firebase_performance_service';
import {AssetPreviewTag} from '../player/asset_preview';
import {MetadataField} from '../services/asset_api_service';
import {AssetState, Original} from '../services/asset_service';
import {ProgressbarService} from '../services/progressbar_service';
import {ResizeObserverService} from '../services/resize_observer_service';
import {SearchInputService, SearchType} from '../services/search_input_service';

import {LiveAssetResultInfo, LiveAssetService, LiveSearchParams, SectionType} from './live_asset_service';
import {DayType, LiveNavigationService} from './live_navigation_service';

/**
 * Media breakpoints that affect live landing.
 */
export enum Breakpoint {
  SMALLEST = '(max-width: 720px)',
  SMALL = '(max-width: 960px)',
  MEDIUM = '(max-width: 1200px)',
  LARGE = '(max-width: 1600px)',
  LARGEST = '(max-width: 9999px)', // fallback
}

const UPCOMING_ALL_COLUMNS = ['time', 'title', 'teams', 'source', 'courtesy'];

// secondary pagination will show if more than this many rows
const SECONDARY_PAGINATION_MIN_ROWS = 2;

export enum AiringType {
  LIVE_NOW,
  AIRED_TODAY,
  AIRED_PAST,
}

type Dimensions = {
  rows: number;
  columns: number;
};

type AiringDimensions = Dimensions & {
  airingType: AiringType;
};

const getAiringDimensions = (airingType: AiringType, rows: number, columns: number): AiringDimensions => ({airingType, rows, columns});

const getBreakpointAiringDimensions = (breakpoint: Breakpoint, ...airingDimensions: Array<AiringDimensions>): [Breakpoint, Array<AiringDimensions>] => [breakpoint, airingDimensions];

// it is very important that the breakpoints here are ordered from largest to smallest
const SECTION_DIMENSIONS = new Map<Breakpoint, Array<AiringDimensions>>(
  [
    getBreakpointAiringDimensions(Breakpoint.LARGEST,
      getAiringDimensions(AiringType.LIVE_NOW, 3, 5),
      getAiringDimensions(AiringType.AIRED_TODAY, 1, 5),
      getAiringDimensions(AiringType.AIRED_PAST, 5, 5)),
    getBreakpointAiringDimensions(Breakpoint.LARGE,
      getAiringDimensions(AiringType.LIVE_NOW, 3, 4),
      getAiringDimensions(AiringType.AIRED_TODAY, 1, 4),
      getAiringDimensions(AiringType.AIRED_PAST, 5, 4)),
    getBreakpointAiringDimensions(Breakpoint.MEDIUM,
      getAiringDimensions(AiringType.LIVE_NOW, 3, 3),
      getAiringDimensions(AiringType.AIRED_TODAY, 1, 3),
      getAiringDimensions(AiringType.AIRED_PAST, 5, 3)),
    getBreakpointAiringDimensions(Breakpoint.SMALL,
      getAiringDimensions(AiringType.LIVE_NOW, 1, 5),
      getAiringDimensions(AiringType.AIRED_TODAY, 1, 5),
      getAiringDimensions(AiringType.AIRED_PAST, 5, 2)),
    getBreakpointAiringDimensions(Breakpoint.SMALLEST,
      getAiringDimensions(AiringType.AIRED_PAST, 5, 1)),
  ]
);

/**
 * Landing page for live assets.
 */
@Component({
  selector: 'mam-live-landing',
  templateUrl: './live_landing.ng.html',
  styleUrls: ['./live_landing.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveLanding implements OnDestroy {
  @ViewChild('scrollView') scrollView!: ElementRef<HTMLElement>;
  @ViewChild('airedAssets') airedAssets!: ElementRef<HTMLElement>;
  @ViewChild('airingAssets') airingAssets!: ElementRef<HTMLElement>;

  readonly AssetState = AssetState;
  readonly DayType = DayType;
  readonly DateTime = DateTime;
  readonly dateLabel$ = this.getDateLabel();
  readonly airedLabel$ = this.getLabelForAiredSection();
  readonly airedLabelMobile$ = this.getLabelForAiredSectionMobile();
  readonly MetadataField = MetadataField;
  readonly AssetPreviewTag = AssetPreviewTag;
  displayColumns = UPCOMING_ALL_COLUMNS;

  readonly AiringType = AiringType;

  readonly columnsMap$ = new BehaviorSubject<Record<AiringType, number>>({
    [AiringType.LIVE_NOW]: 0,
    [AiringType.AIRED_TODAY]: 0,
    [AiringType.AIRED_PAST]: 0,
  });
  readonly airedType$ = this.getAiredType();
  readonly airedColumns$ = this.getAiredColumns();
  readonly sectionHasSecondaryPagination$ = this.getSectionHasSecondaryPagination();

  secondaryAiredPaginationHidden$?: Observable<boolean>;
  secondaryAiringPaginationHidden$?: Observable<boolean>;


  /**
   * Observes changes to layout. Minimum large screen breakpoint is 780px and
   * set in variables.scss
   */
  layoutChanges?: Observable<BreakpointState>;

  constructor(
    readonly liveService: LiveAssetService,
    readonly progressbar: ProgressbarService,
    readonly navigation: LiveNavigationService,
    readonly resizeObserver: ResizeObserverService,
    searchInputService: SearchInputService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly cdr: ChangeDetectorRef,
    private readonly analyticsService: FirebaseAnalyticsService,
    readonly scrubbingService: ScrubbingService,
    performanceService: FirebasePerformanceService,
  ) {
    this.performanceTrace =
      performanceService.startTrace(TraceName.LIVE_LANDING_PAGE_READY);
    combineLatest(
      [this.liveService.airedPagination$, this.liveService.airingPagination$])
      .pipe(takeUntil(this.destroyed$), take(1))
      .subscribe(([aired, airing]) => {
        this.performanceTrace?.stopAfter(
          aired.pageAssets.length + airing.pageAssets.length);
      });

    analyticsService.logEvent('Visited live landing', {
      eventType: AnalyticsEventType.NAVIGATION,
      path: '/live',
      string2: '/live',
    });

    searchInputService.searchType$.next(SearchType.LIVE);

    const searchQuery$ = searchInputService.searchQuery$.pipe(
      filter(({searchType}) => searchType === SearchType.LIVE),
      map(({query}) => query));

    this.liveService.toggleAutoUpdates(true);
    combineLatest([searchQuery$, this.navigation.selectedDate$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([query, {date, type}]) => {
        this.scrollTop();

        this.liveService.updateAssets({date, dateType: type, query});
      });

    this.breakpointObserver.observe(Array.from(SECTION_DIMENSIONS.keys()))
    .pipe(takeUntil(this.destroyed$))
    .subscribe(({ breakpoints }) => {
      const orderedBreakpoints = Object.keys(breakpoints) as Array<Breakpoint>;

      const paginationSizes = new Map<AiringType, number>();
      const columnsMap = this.columnsMap$.getValue();

      // go through each breakpoint in order from largest to smallest
      for (const currBreakpoint of orderedBreakpoints) {
        // if the breakpoint is active, then override the pagination sizes that are defined for it
        if (breakpoints[currBreakpoint]) {
          const sectionDimensions = SECTION_DIMENSIONS.get(currBreakpoint) || [];
          for (const { airingType, rows, columns } of sectionDimensions) {
            // only the changes from the smallest size breakpoint will remain
            paginationSizes.set(airingType, rows * columns);
            columnsMap[airingType] = columns;
          }
        }
      }

      this.liveService.pageSizes$.next({
        airedCollapsed: paginationSizes.get(AiringType.AIRED_TODAY) || 0,
        airedExpanded: paginationSizes.get(AiringType.AIRED_PAST) || 0,
        airing: paginationSizes.get(AiringType.LIVE_NOW) || 0,
      });

      this.columnsMap$.next(columnsMap);

      cdr.markForCheck();
    });
  }

  trackAsset(index: number, value: Original) {
    return value.name;
  }

  onAiredPageChange(event: PageEvent) {
    this.performanceTrace?.abort();
    this.logPageChange(SectionType.AIRED, event);
    // TODO: Scroll to the top of the aired section
    this.liveService.airedPageIndex$.next(event.pageIndex);
  }
  onAiringPageChange(event: PageEvent) {
    this.performanceTrace?.abort();
    this.logPageChange(SectionType.AIRING, event);
    // TODO: Scroll to the top of the airing section
    this.liveService.airingPageIndex$.next(event.pageIndex);
  }

  getTeamsText(asset: Original) {
    const metadata = asset.assetMetadata.jsonMetadata;
    const homeTeam = metadata[MetadataField.HOME_TEAM];
    const awayTeam = metadata[MetadataField.AWAY_TEAM];
    if (!homeTeam && !awayTeam) return '';
    return `${homeTeam || '(unknown)'} - ${awayTeam || '(unknown)'}`;
  }

  onThumbnailLoaded() {
    this.performanceTrace?.maybeStop();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.liveService.toggleAutoUpdates(false);
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);
  private readonly performanceTrace?: Trace;

  /** Scrolls to the top of the page */
  private scrollTop() {
    this.scrollView?.nativeElement.scrollTo({top: 0, behavior: 'smooth'});
  }

  private getLabelForAiredSection() {
    // Update date in section title only when the result are already available.
    return this.getLabelWithContent((request) => `Aired on ${request?.date.toLocaleString(DateTime.DATE_FULL, { locale:"en" })}`);
  }

  private getLabelForAiredSectionMobile() {

    // Update date in section title only when the result are already available.
    return this.getLabelWithContent(() => `Aired`);
  }

  private getLabelWithContent(provider:AiredProvider) {
    return this.liveService.resultInfo$.pipe(map((resultInfo) => {
      if (isAiredAssetsResultsToday(resultInfo)) {
        return 'Aired Today';
      }
      return provider(resultInfo.request);
    }));
  }

  // Want to know which airing type is currently being fetched & rendered
  private getAiredType (): Observable<AiringType> {
    return this.liveService.resultInfo$.pipe(map(res => isAiredAssetsResultsToday(res) ? AiringType.AIRED_TODAY : AiringType.AIRED_PAST));
  }

  private getAiredColumns (): Observable<number> {
    return combineLatest([this.columnsMap$, this.airedType$]).pipe(
      map(([columnsMap, airingType]) => columnsMap[airingType]));
  }

  private logPageChange(
    section: SectionType, {pageIndex, previousPageIndex}: PageEvent) {
    const direction =
      pageIndex > (previousPageIndex ?? 0) ? 'Next' : 'Previous';
    this.analyticsService.logSearchEvent(
      `${direction} page for live ${section.toLowerCase()} section`,
      {number1: pageIndex});
  }

  private getDateLabel() {
    // Update section date only when the result are already available.
    return this.liveService.resultInfo$.pipe(
      map(({request}) => request.date.toLocaleString(DateTime.DATE_FULL, { locale: "en" })));
  }

  private getSectionHasSecondaryPagination() {
    return combineLatest([this.liveService.airedPagination$, this.liveService.airingPagination$, this.columnsMap$, this.airedType$])
    .pipe(map(([{ pageAssets: airedPageAssets }, { pageAssets: airingPageAssets }, columnsMap, airedType]) => {
      const pageAssets = {
        [AiringType.LIVE_NOW]: airingPageAssets,
        [AiringType.AIRED_TODAY]: airedType === AiringType.AIRED_TODAY ? airedPageAssets : [],
        [AiringType.AIRED_PAST]: airedType === AiringType.AIRED_PAST ? airedPageAssets : [],
      };

      const hasSecondaryPagination = (airingType: AiringType) =>
        columnsMap[airingType] === 0 ? false : pageAssets[airingType].length > columnsMap[airingType] * SECONDARY_PAGINATION_MIN_ROWS;

      return {
        [AiringType.LIVE_NOW]: hasSecondaryPagination(AiringType.LIVE_NOW),
        [AiringType.AIRED_TODAY]: hasSecondaryPagination(AiringType.AIRED_TODAY),
        [AiringType.AIRED_PAST]: hasSecondaryPagination(AiringType.AIRED_PAST),
      };
    }));
  }
}

type AiredProvider = (request?:LiveSearchParams) => string;

// true when aired assets are for today, not in the past
const isAiredAssetsResultsToday = ({ request: { dateType } }: LiveAssetResultInfo) => dateType === DayType.TODAY;

