<div class="keywords-container" *ngIf="asset">
  <div class="keywords-search-bar">
    <!-- Search bar -->
    <input
        #input
        aria-label="Video keywords search bar"
        [disabled]="asset.isLive"
        (keyup)="onKeyUpHandler($event)"
        placeholder="Find in video"
        autocomplete="off"/>
    <div class="search-controls">
      <button
          *ngIf="input.value"
          mam-firebase-ga="Clear keywords search bar"
          [mam-firebase-resource]="asset.name"
          aria-label="Clear search bar"
          [disabled]="asset.isLive"
          (click)="clearSearchBar()"
          [disableRipple]="true"
          matTooltip="Clear"
          mat-icon-button
          matSuffix>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="header-container">
    <div class="keywords-header">Keywords</div>
    <button
        *ngIf="(keywords$ | async)?.length"
        mam-firebase-ga="Clear all keywords"
        [mam-firebase-resource]="asset.name"
        (click)="clear()"
        mat-stroked-button
        matSuffix>
      Clear All
    </button>
    </div>

  <!-- Keywords chips -->
  <div class="chips-container">
    <ng-container *ngIf="!asset.isLive; else disabledLive">
      <ng-container *ngIf="(keywords$ | async)?.length; else noTags">
        <mat-chip-option
            *ngFor="let keyword of (keywords$ | async)"
            [class.inactive]="(selectedKeyword$ | async) && keyword !== (selectedKeyword$ | async)"
            (click)="selectKeyword(keyword)"
            [class.no-results]="hasNoResult(keyword)"
            [class.loading]="isLoading(keyword)"
            [disableRipple]="hasNoResult(keyword)"
            (removed)="remove(keyword)"
            [removable]="true"
            class="keywords-visible">
          <span>{{keyword}}</span>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #noTags>
  <mat-chip-option class="no-keywords keywords-visible" disabled>
    No keywords added
  </mat-chip-option>
</ng-template>

<ng-template #disabledLive>
  <div class="disabled-live">
    Video Keywords Search is disabled for Live videos.
  </div>
</ng-template>
