<mat-card appearance="outlined" class="mam-elevation-2">
  <div class="header">
    <div>Search techniques</div>
    <button
        mat-icon-button aria-label="Close"
        (click)="closePanel.emit()"
        mam-firebase-ga="Close search help">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="items" #itemContainer (copy)="onCopy()">
    <div class="item subtitle">
      Use ":" to search for something specific
    </div>

    <div class="item">
      <div><b>text: </b><em>keyword</em></div>
      <div>Search for text in video, for example, text:touchdown</div>
    </div>
    <div class="item">
      <div><b>person: </b><em>keyword</em></div>
      <div>Search for celebrities, for example, person:tom</div>
    </div>
    <div class="item">
      <div><b>speech: </b><em>keyword</em></div>
      <div>Search for speeches, for example, speech:goal</div>
    </div>
    <div class="item">
      <div><b>logo: </b><em>keyword</em></div>
      <div>Search for logos, for example, logo:ThisBrand</div>
    </div>
    <div class="item">
      <div><b>f: </b><em>keyword</em></div>
      <div>Search for video file names, for example, f:Final_game</div>
    </div>
    <div class="item">
      <div><b>id: </b><em>keyword</em></div>
      <div>Search for video id, for example, id:12345</div>
    </div>
    <div class="item">
      <div><b>title: </b><em>keyword</em></div>
      <div>Search for video title, for example, title:"game collections"</div>
    </div>

    <div class="item subtitle">
      Combine searches use "AND", "OR", "NOT"
    </div>

    <div class="item">
      <div><em>keyword</em><b> AND </b><em>keyword</em></div>
      <div>Search for video that matches multiple keywords, for example, cat AND dog</div>
    </div>
    <div class="item">
      <div><em>keyword</em><b> OR </b><em>keyword</em></div>
      <div>Search for video that matches one of the keywords, for example, cat OR dog</div>
    </div>
    <div class="item">
      <div><em>keyword</em><b> NOT </b><em>keyword</em></div>
      <div>Search for video that matches one keyword but not the other, for example, cat NOT dog</div>
    </div>

    <div class="item subtitle">
      Search within a range using ".."
    </div>

    <div class="item">
      <div><b>play_distance: </b><em>start number</em><b>..</b><em>end number</em></div>
      <div>Search for video that happens at certain place on the field, for example, play_distance:10..15</div>
    </div>
    <div class="item">
      <div><b>game_date: </b><em>start date</em><b>..</b><em>end date</em></div>
      <div>Search for video that was played during certain period of time, for example, game_date:2019/09/01..2019/09/02</div>
    </div>
  </div>
</mat-card>
