/******************************************************************************
 *          These two files must be kept in sync:                             *
 *          mamui repo: plugin/plugin_types.ts                                *
 *          premiere-plugin repo: src/shared/plugin_types.ts                  *
 *          Last update: 2022-09-14                                           *
 *                                                                            *
 ******************************************************************************/

/**
 * To add a new message type from MAM UI to the plugin, add a type to
 * `MamUiMessage` and define its iasType and payload structure.
 * If a response from the plugin is expected, add a type to `PluginMessage`.
 */

// ==================== MAM UI MESSAGES ======================

/** Message sent from MAM UI up to its plugin host. */
export declare type MamUiMessage = MamUiCheckAssetsMessage |
    MamUiImportMessage | MamUiAuthMessage | MamUiAuthClearMessage |
    MamUiEventPanelMessage | MamUiGetHostInfoMessage;

/** Properties shared by all MamUiMessage. */
declare interface MamUiBaseMessage {
  iasType: PluginAction;
  requestId?: string;
  payload?: unknown;
}

/** MAM UI message for CHECK_ASSETS. */
export declare interface MamUiCheckAssetsMessage extends MamUiBaseMessage {
  iasType: HostOtherAction.CHECK_ASSETS;
  payload: Array<{binTitle?: string, assetUrl: string}>;
}

/** MAM UI message for CHECK_BIN_CONTENT. */
export declare interface MamUiCheckBinContentMessage extends MamUiBaseMessage {
  iasType: HostOtherAction.CHECK_BIN_CONTENT;
  payload: {binTitle?: string};
}

/** MAM UI message for any ImportAction. */
export declare interface MamUiImportMessage extends MamUiBaseMessage {
  iasType: HostImportAction;
  payload: {segments: PathSegment[], skipDuplicateCheck?: boolean};
}

/** MAM UI message for any AppAuthAction except CLEAR_AUTH. */
export declare interface MamUiAuthMessage extends MamUiBaseMessage {
  iasType: Exclude<AppAuthAction, AppAuthAction.CLEAR_AUTH>;
  payload: Environment;
}

/** MAM UI message for CLEAR_AUTH. */
export declare interface MamUiAuthClearMessage extends MamUiBaseMessage {
  iasType: AppAuthAction.CLEAR_AUTH;
  payload?: undefined;
}

/** MAM UI message for UPDATE_EVENT_PANEL. */
export declare interface MamUiEventPanelMessage extends MamUiBaseMessage {
  iasType: HostOtherAction.UPDATE_EVENT_PANEL;
  payload: string;
}

/** MAM UI message for GET_HOST_INFO. */
export declare interface MamUiGetHostInfoMessage extends MamUiBaseMessage {
  iasType: AppAction.GET_HOST_INFO;
  payload: undefined;
}

/** Import actions executed by the host process. */
export enum HostImportAction {
  /**
   * Imports full file to Premiere Pro project and creates a clip from it based
   * on provided start and end times. If bin title is provided places
   * the file under the folder with the bin title name. Otherwise places the
   * file at the root. Make sure to provided bin title when importing clips.
   *
   * Used for live clips and non-PFR VoD clips.
   */
  IMPORT_SUBCLIPS = 'importSubclips',
  /**
   * Imports full file to Premiere Pro project. If bin title is provided places
   * the file under the folder with the bin title name. Otherwise places the
   * file at the root. Make sure to provided bin title when importing clips.
   *
   * Used for PFRs and full live / VoD assets.
   */
  IMPORT_ASSETS = 'importAssets',
  /**
   * @deprecated It is not supported with plugin v3.2.0 and upper. Needed for
   * compatibility with plugin v3.1.1 and lower.
   */
  IMPORT_CLIPS = 'importClips',
}

/** Other actions executed by the host process. */
export enum HostOtherAction {
  UPDATE_EVENT_PANEL = 'updateEventPanel',
  CHECK_ASSETS = 'checkAssets',
  CHECK_BIN_CONTENT = 'checkBinContent'
}

/** Any action supported by the host. */
export declare type HostAction = HostImportAction | HostOtherAction;

/** Auth actions executed by the plugin app (not communicated to the host). */
export enum AppAuthAction {
  INITIATE_AUTH = 'initiateAuth',
  REFRESH_AUTH = 'refreshAuth',
  RETRIEVE_AUTH = 'retrieveAuth',
  CLEAR_AUTH = 'clearAuth',
}

/** Other types of action that do not require Host interaction. */
export enum AppAction {
  GET_HOST_INFO = 'getHostInfo',
}

/** All actions supported by the plugin. */
export declare type PluginAction = HostAction | AppAuthAction | AppAction;

/** Environment properties used by the plugin. */
export declare interface Environment {
  projectId: string;
  scope?: string;
}

/** Asset or clip payload item used by ImportAction. */
export declare interface PathSegment {
  /**
   * Currently is set to absolute local file path in windows format.
   * TODO: Remove path once the plugin is updated to use localPath.
   */
  path: string;
  /** Absolute local file path in windows and unix formats. */
  localPath: LocalPath;
  /**
   * Used to set in-point of a clip within its original file.
   * Ignored when importing full files via ImportType.IMPORT_ASSETS.
   */
  startTime?: number;
  /**
   * Used to set out-point of a clip within its original file.
   * Ignored when importing full files via ImportType.IMPORT_ASSETS.
   */
  endTime?: number;
  /**
   * Wall-clock start of the file imported, as number of seconds since epoch.
   */
  wallClockStartTime?: number;
  /** Title of an asset or clip */
  title: string;
  /** Title of the clip bin. */
  bin?: string;
  /** Comment content displaying in PPro comment column. */
  commentText: string;
}

/** Describes payload provided by MAM UI to check if the file exists locally. */
export declare interface AssetLocation {
  /** Clip bin title. */
  binTitle: string;
  /** @deprecated Remove clipTitle when ias-plugin is using clip mediaPath. */
  clipTitle: string;
  /** Asset identifier. */
  assetKey: string;
  /** Absolute local file path in windows and unix formats. */
  clipOnpremPath: LocalPath;
  /** The absolute local file path including OS type adjustment. */
  path?: string;
}

/** Asset path in multiple OS formats provided by MAM UI. */
export declare interface LocalPath {
  /**
   * Path formatted in Windows style. E.g.
   * `I:\prod_cache\some_folder\great_vid.mxf`.
   */
  windows: string;
  /**
   * Path formatted in unix style. E.g.
   * `/volume1/LAB/prod_cache/some_folder/great_vid.mxf`
   */
  unix: string;
}


// ==================== PLUGIN MESSAGES ======================

/** Message sent from the plugin down to its MAM UI iframe. */
export declare type PluginMessage = PluginImportAssetsMessage |
    PluginCheckAssetsMessage | PluginAuthMessage | PluginGetHostInfoMessage;

/** Properties shared by all PluginMessage. */
declare interface PluginBaseMessage {
  pluginMessageType: PluginAction;
  requestId?: string;
  error?: string;
  payload: unknown;
}

/** Plugin response for IMPORT_ASSETS. */
export declare interface PluginImportAssetsMessage extends PluginBaseMessage {
  pluginMessageType: HostImportAction.IMPORT_ASSETS;
  payload: {
    successCount: number,
    duplicateCount: number,
    errorCount: number,
  };
}

/** Plugin response for CHECK_ASSETS. */
export declare interface PluginCheckAssetsMessage extends PluginBaseMessage {
  pluginMessageType: HostOtherAction.CHECK_ASSETS;
  /** Array mapping each clip URL input to whether it exists in Premiere. */
  payload: boolean[];
}

/** Plugin response for CHECK_BIN_CONTENT. */
export declare interface PluginCheckBinContentMessage extends
    PluginBaseMessage {
  pluginMessageType: HostOtherAction.CHECK_BIN_CONTENT;
  /** Array of all asset URLs inside given Premiere bin or root. */
  payload: string[];
}

/** Plugin response for any AppAuthAction. */
export declare interface PluginAuthMessage extends PluginBaseMessage {
  pluginMessageType: AppAuthAction;
  payload: {tokens?: RestrictedAuthTokens};
}

/** Plugin response for GET_HOST_INFO. */
export declare interface PluginGetHostInfoMessage extends PluginBaseMessage {
  pluginMessageType: AppAction.GET_HOST_INFO;
  payload: {
    pluginVersion: string,
    osInformation: string,
    hostEnvironment: {
      appName: string,
      appVersion: string,
    },
  };
}

/** Full auth tokens known by the plugin. */
export declare interface FullAuthTokens {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  expires_at?: number;
  token_type: string;
  scope: string;
}

/** Access token and its expiration only, shared with MAM UI. */
export declare interface RestrictedAuthTokens {
  access_token: string;
  expires_at: number;
}
