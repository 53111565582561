import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthService} from '../auth/auth_service';
import {ApiError} from '../error_service/api_error';
import {StatusCode} from '../error_service/status_code';
import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';

/** Adds more information to API response errors messages. */
@Injectable()
export class MamApiInterceptor implements HttpInterceptor {
  constructor(
      private readonly authService: AuthService,
      private readonly analyticsService: FirebaseAnalyticsService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    // Ignore any further network call if user is unauthorized.
    if (this.authService.isUnauthorized) return EMPTY;

    return next.handle(req).pipe(
        catchError((error: HttpErrorResponse|Error) => {
          // Ignore error if user became unauthorized during this call.
          if (this.authService.isUnauthorized) return EMPTY;

          if (!(error instanceof HttpErrorResponse)) {
            return throwError(() => error);
          }

          const apiError = new ApiError(error);

          // Intercept 401 errors and redirect to logout.
          if (error.status === StatusCode.UNAUTHORIZED) {
            this.authService.isUnauthorized = true;
            this.analyticsService.logEvent('automatic logout', {
              eventType: AnalyticsEventType.NAVIGATION,
            });
            this.authService.logout();
            return EMPTY;
          }

          return throwError(() => apiError);
        }));
  }
}
