import {Inject, Injectable, InjectionToken} from '@angular/core';

import {AuthService} from '../auth/auth_service';
import {environment} from '../environments/environment';
import {getEnvironmentParam} from '../environments/util';

import {FEATURE_CONFIG, FeatureConfig, FeatureName} from './feature_config';

/**
 * Injection token for configuring the feature flag service.
 */
export const FEATURE_FLAG_SERVICE_CONFIG = new InjectionToken<FeatureConfig>(
    'Feature flag configuration', {factory: () => FEATURE_CONFIG});

/** Injection token for reading the current URL. */
export const GET_CURRENT_URL = new InjectionToken<() => string>(
    'Current URL', {factory: () => () => window.location.href});

/** Service that checks whether a feature is enabled in the environment */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(
      @Inject(FEATURE_FLAG_SERVICE_CONFIG) private readonly featureConfig:
          FeatureConfig,
      @Inject(GET_CURRENT_URL) private readonly getCurrentUrl: () => string,
      private readonly authService: AuthService,
  ) {}

  /**
   * Checks if the feature is enabled in the current environment.
   */
  featureOn(featureName: FeatureName): boolean {
    if (!this.overrides) {
      this.overrides = this.buildOverrides();
    }

    if (this.overrides.has(featureName)) {
      return !!this.overrides.get(featureName);
    }

    const config = this.featureConfig[featureName];
    // If the feature config doesn't exist consider it enabled by default.
    if (!config) return true;
    return config.includes(environment.tag);
  }

  /**
   * Checks if the feature is disabled in the current environment.
   */
  featureOff(featureName: FeatureName) {
    return !this.featureOn(featureName);
  }

  /**
   * Internal users can override feature state by providing `feature` url
   * param.
   *
   * @example
   * "...?feature=featureA,-featureB"
   * // featureA will be enabled, featureB will be disabled.
   */
  private overrides?: Map<string, boolean>;

  private buildOverrides() {
    const map = new Map<string, boolean>();
    if (!this.authService.isInternalUser()) return map;

    const paramValue: string =
        getEnvironmentParam('feature', '', this.getCurrentUrl());
    if (!paramValue) return map;

    const features = paramValue.split(',');
    for (let feature of features) {
      feature = feature.trim();
      const mod = feature[0];
      switch (mod) {
        case '-':
          map.set(feature.slice(1), false);
          break;
        case '+':
          map.set(feature.slice(1), true);
          break;
        default:
          // Turn the feature on, if no mod is provided.
          map.set(feature, true);
      }
    }

    return map;
  }
}
