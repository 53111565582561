import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {delay} from 'rxjs/operators';

import {Site, SiteTransferStats} from 'models';

import {pseudoRandom} from './fake_api_utils';
import {SitesApiService} from './sites_api_service';

/** Fake sites. */
export const FAKE_SITES = ['svl - mock', 'sfo - mock', 'lax - mock'];

/** SitesApi to interact with IAS backend sites related APIs */
@Injectable({providedIn: 'root'})
export class FakeSitesApiService implements Omit<SitesApiService, 'client'> {
  list(pageSize: number) {
    const sites = [
      ...FAKE_SITES.map(s => new Site({siteId: s})),
      new Site({siteId: 'gcpSite - mock', siteType: 'SITE_TYPE_CLOUD_GCP'}),
    ].slice(0, pageSize);
    return of({sites}).pipe(delay(100));
  }

  fetchStats(site: string) {
    const rand = pseudoRandom('transfer_stats_' + site);
    const response = new SiteTransferStats({
      numCachefolders: 3,
      numWatchfolders: Math.round(rand * 20),
      transferDirectionStats: [
        // This stat (DIRECTION_DOWNLOAD) is used for all the stats except the
        // average transfer rate, which uses the DIRECTION_ALL instead.
        {
          direction: 'TRANSFER_DIRECTION_DOWNLOAD',
          transferStateStats: [

            {
              numTransfers: '12',
              state: 'TRANSFER_STATE_COMPLETED',
              transferVolumeMbytes:
                  String(rand * 400 * 1024) /* up to 400 GiB */,
              transferRateMbytes: 1,
            },
            {
              numTransfers: String(Math.round(rand * 45)),
              state: 'TRANSFER_STATE_PROCESSING',
              transferVolumeMbytes: String(100 * 1024) /* 100 GiB */,
              transferRateMbytes: 2,
            },
            {
              numTransfers:
                  String(Math.round(rand * 7) + (this.fetchStatsCallsCount++)),
              state: 'TRANSFER_STATE_ERROR',
              transferVolumeMbytes: String(2 * 1024) /* 2 GiB */,
              transferRateMbytes: 3,
            },
          ],
        },
        // This stat (DIRECTION_ALL) is used for the average transfer rate.
        {
          direction: 'TRANSFER_DIRECTION_ALL',
          transferStateStats: [
            {
              numTransfers: '21',
              state: 'TRANSFER_STATE_COMPLETED',
              transferVolumeMbytes: String(123 * 1024),
              transferRateMbytes: 120,
            },
          ],
        },
      ],
    });
    return of(response).pipe(delay(100));
  }

  private fetchStatsCallsCount = 0;
}
