import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {of, ReplaySubject} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';
import { PlayerFullscreenService } from 'services/player_fullscreen_service';

import {isErrorResponse} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {PlayerWithControls} from '../player/player_with_controls';
import {CutDownSegment, CutDownSegments} from '../player/timeline';
import {AssetCopy, AssetService, ClipMarking, Original} from '../services/asset_service';
import {ManifestService} from '../services/manifest.service';
import {SnackBarService} from '../services/snackbar_service';
import {BatchOperationService} from '../shared/batch_operation_service';

/** Clipbin creation dialog */
@Component({
  selector: 'mam-cut-down-dialog',
  templateUrl: './cut_down_dialog.ng.html',
  styleUrls: ['./cut_down_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CutDownDialog implements AfterViewInit, OnDestroy {
  static getDialogOptions(data: CutDownData): MatDialogConfig<CutDownData> {
    return {data};
  }

  @ViewChild(PlayerWithControls) playerWithControls!: PlayerWithControls;

  signer = (rawUrls: string[]) =>
      this.batchOperationService.batchSignUrls(rawUrls);

  constructor(
      private readonly assetService: AssetService,
      private readonly batchOperationService: BatchOperationService,
      private readonly snackBar: SnackBarService,
      private readonly errorService: ErrorService,
      private readonly cdr: ChangeDetectorRef,
      private readonly dialogRef: MatDialogRef<CutDownDialog>,
      private readonly manifestService: ManifestService,
      private readonly playerFullscreenService: PlayerFullscreenService,
      @Inject(MAT_DIALOG_DATA) readonly data: CutDownData,
  ) {}

  ngAfterViewInit() {
    this.initiateCutDown();
  }

  onPlayerError(message: string) {
    this.errorService.handle('Cut-down embedded player error: ' + message);
  }

  initiateCutDown() {
    const player = this.playerWithControls;

    // This will activate the cutdown UI with no clip initially previewed.
    let cutDownSegments: CutDownSegments = [];
    player.cutDownSegments = cutDownSegments;

    // Push existing cut-downs
    this.manifestService
        .getLiveAssetTimeline(this.data.asset, {maxRetryAttempts: 1})
        .pipe(
            switchMap(manifest => {
              if (isErrorResponse(manifest)) {
                return of({manifest, copies: [] as AssetCopy[]});
              }

              return this.assetService.listCopies(this.data.asset)
                  .pipe(map(copies => ({manifest, copies})));
            }),
            takeUntil(this.destroyed$))
        .subscribe(({copies, manifest}) => {
          this.cdr.markForCheck();
          if (isErrorResponse(copies)) {
            this.snackBar.error(
                'Failed to fetch existing cutdowns.', undefined,
                {'assetName': this.data.asset.name});
            return;
          }

          if (isErrorResponse(manifest)) {
            this.snackBar.error('Failed to fetch asset live stream manifest');
            return;
          }

          // Convert clip times (relative to original asset) into timeline
          // segments times (relative to the start of the timeline).
          const segments = copies.map(copy => {
            const clipSegment: CutDownSegment = {
              startTime: copy.startOffset,
              endTime: copy.isFullCopy ? manifest.durationInSeconds :
                                         copy.endOffset,
              type: 'cutdown',
            };
            return clipSegment;
          });

          cutDownSegments = [...cutDownSegments, ...segments];
          player.cutDownSegments = cutDownSegments;
        });

    // This call fetches all clips that has been made from the current stream,
    // one page at a time. Each time a page is received, these clips are
    // appended to the current list of cut-down segments.
    this.assetService.expandClipsFromAsset(this.data.asset.name)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(result => {
          this.cdr.markForCheck();
          if (!result) {
            this.snackBar.error(
                'Failed to fetch clips from the current video.', undefined,
                {'assetName': this.data.asset.name});
            return;
          }

          // Convert clip times (relative to original asset) into timeline
          // segments times (relative to the start of the timeline).
          const segments = result.assets.map(clip => {
            const clipSegment: CutDownSegment = {
              startTime: clip.startTime - player.getTimelineStart(),
              endTime: clip.endTime - player.getTimelineStart(),
              type: 'clip',
            };
            return clipSegment;
          });

          cutDownSegments = [...cutDownSegments, ...segments];
          player.cutDownSegments = cutDownSegments;
        });

      this.dialogRef.backdropClick().subscribe(() => {
        // Re-enable automatic fullscreen on screen rotation to landscape
        this.playerFullscreenService.automaticFullScreenEnabled = true;
      });

      // Disable automatic fullscreen on screen rotation to landscape
      this.playerFullscreenService.automaticFullScreenEnabled = false;
  }

  onCutDownConfirmed(clipMarking: ClipMarking) {
    assertTruthy(clipMarking.markOut > 0, 'Cut down without an end');
    this.dialogRef.close(clipMarking);
    this.playerFullscreenService.automaticFullScreenEnabled = true;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /** Handles on-destroy Subject, used for unsubscribing. */
  private readonly destroyed$ = new ReplaySubject<void>(1);
}

/** Data for CutDownDialog */
export interface CutDownData {
  asset: Original;
}
