<mat-card
    appearance="outlined"
    class="clipbin_card"
		[class.mam-elevation-1]="binsViewMode === 'grid'"
		[class.clipbin_card--list]="binsViewMode === 'list'"
    [routerLink]="['/clipbin', bin.name, 'clip', 0]"
    [queryParams]="{'type': 'bin'}"
    [class.active]="active"
    queryParamsHandling="merge"
    mam-firebase-ga="Open clip bin from its preview"
    [mam-firebase-resource]="bin.name">

  <div class="card-header-image">
    @if (binWithClips.clips?.length) {
      <mam-asset-thumbnail
          *ngFor="let asset of binWithClips?.clips | slice:0:3; trackBy: trackClip"
          [asset]="asset">
      </mam-asset-thumbnail>
    } @else {
      <img src="../../images/empty_bin.svg" alt="Empty Clip Bin">
    }
    <img *ngIf="bin.assetCount === '0'" src="../../images/empty_bin.svg" alt="Empty Clip Bin">
  </div>
  <div class="card-content">
    <div class="card-content-width">
      <div class="card-content-title" [attr.title]="bin.title">
        <div class="name-side-icon">
          <mat-icon role="img" aria-hidden="true"> subscriptions </mat-icon>
        </div>
        <p>{{ bin.title }}</p>
      </div>
      <div class="card-content-subtitle" [ngPlural]="+bin.assetCount">
				<ng-template ngPluralCase="=0">No clips</ng-template>
        <ng-template ngPluralCase="=1">1 clip</ng-template>
        <ng-template ngPluralCase="other">{{bin.assetCount}} clips</ng-template>
      </div>
    </div>
    @if(isMyFolder || isAdmin || (isMyFolder == undefined && (isAdmin || !showAllBins))){
    <button
        mat-icon-button
        class="bin-button main__icon--size"
        [matMenuTriggerFor]="menu"
        aria-label="Open Clip Bin Menu"
        (click)="$event.stopPropagation()"
        mam-firebase-ga="Open clip bin menu"
        [mam-firebase-resource]="bin.name">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu
        #menu="matMenu"
        class="clip-bin-options-menu">
      <button
        mat-menu-item
        [disabled]="bin.assetCount === '0' || !bin.assetCount"
        [matTooltip]="(bin.assetCount === '0' || !bin.assetCount) ? 'No clips to share': ''"
        matTooltipPosition="left"
        (click)="openShareBin()"
        mam-firebase-ga="Share clip bin dialog"
        [mam-firebase-resource]="bin.name">
        Share Clip Bin
      </button>
      <button
          mat-menu-item
          (click)="openRenameBin(bin.title, bin.name)"
          mam-firebase-ga="Open clip bin rename dialog"
          [mam-firebase-resource]="bin.name">
        Rename
      </button>
      <button
          mat-menu-item
          (click)="moveToFolder()"
          mam-firebase-ga="Open clip bin move dialog"
          [mam-firebase-resource]="bin.name">
        Move
      </button>
      <button
          mat-menu-item
          (click)="openDeleteBin(bin.name)"
          class="delete-button"
          mam-firebase-ga="Select clip bin deletion"
          [mam-firebase-resource]="bin.name">
        Delete clip bin
      </button>
    </mat-menu>
    }
  </div>
</mat-card>
