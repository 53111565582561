import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, combineLatest, take } from 'rxjs';

import { ClipBinsFoldersService, FolderItemRef } from 'firebase/clip_bins_folders.service';
import { Bin } from 'services/bin.service';
import { SnackBarService } from 'services/snackbar_service';


/** Clipbin deletion dialog */
@Component({
  selector: 'mam-move-bin-dialog',
  templateUrl: './move_bin_dialog.ng.html',
  styleUrls: ['./move_bin_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveBinDialog implements OnInit {
  static readonly dialogOptions = {
    hasBackdrop: true,
  };

  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

  @ViewChild(MatSelect) folderSelect!: MatSelect;

  /** FormControl for select dropdown */
  folderSearchControl = new UntypedFormControl();
  selectFolderControl = new UntypedFormControl();

  readonly loading$ = new BehaviorSubject(false);


  public folderItemRef: FolderItemRef | undefined;
  public myFolders$ = new BehaviorSubject<FolderItemRef[]>([]);
  public listedFolders$ = new BehaviorSubject<FolderItemRef[]>([]);

  public parentFolder: FolderItemRef | undefined;
  protected isLoading:boolean = true;

  constructor(
    readonly dialogRef: MatDialogRef<MoveBinDialog>,
    private readonly snackBar: SnackBarService,
    private readonly clipBinFolderService: ClipBinsFoldersService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: Bin) { }

  ngOnInit() {

    combineLatest([
      this.clipBinFolderService.getFolderRefByBinName(this.data.name),
      this.clipBinFolderService.getMyFolders(5000) //TODO: double check behaviors
    ]).pipe(
      take(1)
    ).subscribe(([folderItemRef, folders]) => {
      if (folderItemRef) {
        this.folderItemRef = folderItemRef[0];
        this.parentFolder = folders.find(folder => folder.id === this.folderItemRef?.parent);
        this.selectFolderControl.setValue(this.parentFolder);
      }
      this.myFolders$.next(folders); //cache API response
      this.listedFolders$.next([{ id: '', displayName: 'Root', refType: 'folder' } as FolderItemRef, ...folders]); //list manipulated by search
      this.isLoading = false;
      this.cdr.markForCheck();
    });

    this.dialogRef.afterClosed().subscribe((moveConfirmed) => {
      if (!moveConfirmed) return;
      const destination = this.selectFolderControl.value.id ?? '';
      const newParent = destination.length > 0 ? destination : ''; //Moves to root if no destination is selected

      if (this.folderItemRef) {
        this.folderItemRef.parent = newParent;
      } else {
        this.folderItemRef = {
          parent: newParent,
          displayName: this.data.title,
          queryableName: this.data.title.toLocaleLowerCase().replace(/\s/g, ''),
          binRefName: this.data.name,
          refType: 'clipbin',
          active: true
        } as FolderItemRef;
      }

      this.clipBinFolderService.updateFolder(this.folderItemRef, this.parentFolder)
        .pipe(
          take(1)
        )
        .subscribe({
          next: () => {
            this.snackBar.message(`Clip bin ${this.data.title} has been moved successfully to ${this.selectFolderControl.value?.displayName ?? `root`}.`);
          },
          error: (error: unknown) => {
            this.snackBar.error(
              `Clip bin ${this.data.title} could not be moved to  ${this.selectFolderControl.value?.displayName ?? `root`}.`, undefined, error);
          }
        });
    });

    this.folderSearchControl.valueChanges
      .subscribe((searchTerm) => {
        if (searchTerm) {
          this.listedFolders$.next(this.listedFolders$.value.filter((folder) => folder.displayName.toLowerCase().includes(searchTerm.toLowerCase())));
        } else {
          this.listedFolders$.next(this.myFolders$.value);
        }
      });

  }

  compareFolders(folder1?: FolderItemRef, folder2?: FolderItemRef) {
    return folder1?.id === folder2?.id && folder1?.displayName === folder2?.displayName &&
        folder1?.items === folder2?.items;
  }


  onSelectOpenedChanged(opened: boolean) {
    if (!opened) {
      // Clear the search input box
      if (this.folderSearchControl.value) {
        this.folderSearchControl.setValue('');
      }
    } else {
      this.searchInput?.nativeElement.focus();
    }
  }

  trackId(index: number, value: FolderItemRef) {
    return value.id;
  }
}
