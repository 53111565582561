import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {delay, tap} from 'rxjs/operators';

import { ApiFolder } from 'api/ias/model/api-folder';
import {FileResource, Folder} from 'models';

import {pseudoRandomArrayItem} from './fake_api_utils';
import {Cloud2onpremEnum, FolderTypeEnum, Onprem2cloud, Onprem2cloudEnum} from './ias_types';
import {MediaCacheApiService} from './media_cache_api_service';


/** Serves MediaCache related */
@Injectable({providedIn: 'root'})
export class FakeMediaCacheApiService implements
    Omit<MediaCacheApiService, 'folderClient'|'fileClient'> {
        getOrCreateFile(siteId: string, folderId: string, filename: string):
      Observable<FileResource> {
    const path = siteId + folderId + filename;
    const anythingButInProgress =
        Object.values(Cloud2onpremEnum)
            .filter(s => s !== Onprem2cloudEnum.IN_PROGRESS);
    let cloud2onprem = this.filesStates.get(path) ||
        pseudoRandomArrayItem(path, anythingButInProgress);
    
    let onprem2cloud = Onprem2cloudEnum.COMPLETE;

    if (filename=='SundarInterview.mp4') {
      if (folderId=='Export Folder 1') {
        onprem2cloud=Onprem2cloudEnum.COMPLETE;
        cloud2onprem=Cloud2onpremEnum.ASSET_TRANSFER_STATE_UNSPECIFIED;
      } else if (folderId=='Export Folder 2') {
        onprem2cloud=Onprem2cloudEnum.COMPLETE;
        cloud2onprem=Cloud2onpremEnum.COMPLETE;
      } else if (folderId=='Export Folder 3') {
        onprem2cloud=Onprem2cloudEnum.COMPLETE;
        cloud2onprem=Cloud2onpremEnum.FAILED;
      } else if (folderId=='Export Folder 4') {
        onprem2cloud=Onprem2cloudEnum.COMPLETE;
        cloud2onprem=Cloud2onpremEnum.IN_PROGRESS;
      } else if (folderId=='Export Folder 5') {
        onprem2cloud=Onprem2cloudEnum.PENDING;
        cloud2onprem=Cloud2onpremEnum.PENDING;
      }
    }

    return of(new FileResource({
             filename,
             onprem2cloud,
             cloud2onprem,
           }))
        .pipe(delay(300));
  }

  getFile(siteId: string, folderId: string, filename: string) {
    return this.getOrCreateFile(siteId, folderId, filename);
  }

  download(siteId: string, folderId: string, filename: string):
      Observable<FileResource> {
    const path = siteId + folderId + filename;
    return of(new FileResource({
             filename,
             onprem2cloud: Onprem2cloudEnum.COMPLETE,
             cloud2onprem: Onprem2cloudEnum.IN_PROGRESS,
           }))
        .pipe(
            delay(100),
            tap(() => {
              // After the fake call to download, the file has an onprem state
              // of IN_PROGRESS for 6 seconds, then COMPLETE.
              this.filesStates.set(path, Onprem2cloudEnum.IN_PROGRESS);
              setTimeout(() => {
                this.filesStates.set(path, Onprem2cloudEnum.COMPLETE);
              }, 6000);
            }),
        );
  }

  listFolders(siteId: string, folderType?: string) {
    if (folderType === FolderTypeEnum.FOLDER_TYPE_EXPORT) {
      return of({
        folders: [
          new Folder ({
            name: "projects/234973717435/locations/global/sites/dev/folders/Export Folder 1",
            folderId: "Export Folder 1",
            rootFolderPath: "/volume1/lab_share_01/dev/Export Folder 1",
            type: FolderTypeEnum.FOLDER_TYPE_EXPORT,
            createTime: "2023-06-16T01:09:35.692957575Z",
            state: ApiFolder.StateEnum.STATUS_ACTIVE
          }),
          new Folder ({
            name: "projects/234973717435/locations/global/sites/dev/folders/Export Folder 2",
            folderId: "Export Folder 2",
            rootFolderPath: "/volume1/lab_share_01/dev/Export Folder 2",
            type: FolderTypeEnum.FOLDER_TYPE_EXPORT,
            createTime: "2023-06-16T01:09:35.692957575Z",
            state: ApiFolder.StateEnum.STATUS_ACTIVE
          }),
          new Folder ({
            name: "projects/234973717435/locations/global/sites/dev/folders/Export Folder 3",
            folderId: "Export Folder 3",
            rootFolderPath: "/volume1/lab_share_01/dev/Export Folder 3",
            type: FolderTypeEnum.FOLDER_TYPE_EXPORT,
            createTime: "2023-06-16T01:09:35.692957575Z",
            state: ApiFolder.StateEnum.STATUS_ACTIVE
          }),
          new Folder ({
            name: "projects/234973717435/locations/global/sites/dev/folders/Export Folder 4",
            folderId: "Export Folder 4",
            rootFolderPath: "/volume1/lab_share_01/dev/Export Folder 4",
            type: FolderTypeEnum.FOLDER_TYPE_EXPORT,
            createTime: "2023-06-16T01:09:35.692957575Z",
            state: ApiFolder.StateEnum.STATUS_ACTIVE
          }),
          new Folder ({
            name: "projects/234973717435/locations/global/sites/dev/folders/Export Folder 5",
            folderId: "Export Folder 5",
            rootFolderPath: "/volume1/lab_share_01/dev/Export Folder 5",
            type: FolderTypeEnum.FOLDER_TYPE_EXPORT,
            createTime: "2023-06-16T01:09:35.692957575Z",
            state: ApiFolder.StateEnum.STATUS_ACTIVE
          })
        ],
      })
     .pipe(delay(200));
    }
    
    if (siteId !== 'gcpSite') {
      return of({
               folders: [new Folder({
                 folderId: 'fake-folder',
                 // When we select SFO, simulate the case where no cache
                 // folder is available.
                 type: siteId === 'sfo' ? FolderTypeEnum.FOLDER_TYPE_WATCH :
                                          FolderTypeEnum.FOLDER_TYPE_CACHE
               })],
             })
          .pipe(delay(200));
    }
    // Generate folder list for cloud ingest.
    return of({
      folders: [
        new Folder({
          name: 'folder-1',
          folderId: 'folder-1',
          rootFolderPath: 'mybucket/path/to/',
          // FOLDER_TYPE_CLOUD_WATCH for hourly sync between two
          // cloud sources
          type: FolderTypeEnum.FOLDER_TYPE_CLOUD_WATCH,
          storageTransferInfo: {
            jobState: 'ENABLED',
            transferJobId: 'folder-1',
          },
        }),
        new Folder({
          name: 'folder-2',
          folderId: 'folder-2',
          rootFolderPath: 'mybucket/path/to/',
          type: FolderTypeEnum.FOLDER_TYPE_CLOUD_WATCH,
          storageTransferInfo: {
            jobState: 'ENABLED',
            transferJobId: 'folder-2',
          },
        }),
        new Folder({
          name: 'folder-3-on-demand',
          folderId: 'folder-3-on-demand',
          rootFolderPath: 'mybucket/path/to/documents/workspace/',
          //  FOLDER_TYPE_CLOUD_MIGRATION for one time on-demand
          //  migration between two cloud sources
          type: FolderTypeEnum.FOLDER_TYPE_CLOUD_MIGRATION,
          storageTransferInfo: {
            jobState: 'DISABLED',
            transferJobId: 'folder-3-on-demand',
          },
        }),
        new Folder({
          name: 'folder-4',
          folderId: 'folder-4',
          rootFolderPath: 'mybucket/path/to/documents/workspace/',
          // FOLDER_TYPE_CLOUD_WATCH for hourly sync between two
          // cloud sources
          type: FolderTypeEnum.FOLDER_TYPE_CLOUD_WATCH,
          storageTransferInfo: {
            jobState: 'ENABLED',
            transferJobId: 'folder-4',
          },
        })
      ],
    })
    .pipe(delay(200));
  }

  locateFile(siteId: string, folderId: string, filename: string) {
    return of(new FileResource({
             filename,
             onprem2cloud: Onprem2cloudEnum.COMPLETE,
             cloud2onprem: Onprem2cloudEnum.COMPLETE,
             onpremLocation:
                 `fake-sites/${siteId}/folders/${folderId}/files/${filename}`,
           }))
        .pipe(delay(200));
  }

  removeOnpremFile() {
    return of({file: undefined});
  }

  updateFileTtl(name: string, file: FileResource) {
    return of(file).pipe(delay(200));
  }

  /** In-memory fake database of file states. */
  private readonly filesStates = new Map<string, Onprem2cloud>();
}
