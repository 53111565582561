import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

import {AuthBrowserService} from '../auth/auth_browser_service';
import {AuthPluginService} from '../auth/auth_plugin_service';
import {AuthService} from '../auth/auth_service';
import {ErrorService} from '../error_service/error_service';
import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {PluginService} from '../plugin/plugin_service';
import {SnackBarService} from '../services/snackbar_service';

/**
 * Component for public login page.
 */
@Component({
  selector: 'mam-login',
  templateUrl: './login.ng.html',
  styleUrls: ['./login.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Login {
  isUnauthenticated = false;

  /**
   * Whether external auth from the plugin has started, used to display
   * a message inviting the user to find that external page.
   */
  pluginAuthInitiated$ = new BehaviorSubject(false);

  constructor(
      private readonly errorService: ErrorService,
      private readonly authService: AuthService,
      private readonly cdr: ChangeDetectorRef,
      private readonly router: Router,
      private readonly analyticsService: FirebaseAnalyticsService,
      private readonly snackBar: SnackBarService,
      readonly pluginService: PluginService,
  ) {
    this.analyticsService.logEvent('Visited login', {
      eventType: AnalyticsEventType.NAVIGATION,
      path: '/login',
      string2: '/login',
    });

    // If the user is already logged-in when they visit the /login
    // page, redirect them to the home page.
    this.maybeRedirectToLanding();
  }

  private async maybeRedirectToLanding(): Promise<void> {
    try {
      const isLoggedIn = await this.authService.isLoggedIn();
      if (isLoggedIn) {
        await this.router.navigate(['/'], {queryParamsHandling: 'merge'});
        return;
      }
    } catch (error: unknown) {
      this.errorService.handle(error);
    }

    this.isUnauthenticated = true;
    this.cdr.markForCheck();
  }

  async signInWithGoogle() {
    const isLoggedIn = await this.authService.isLoggedIn();
    if (isLoggedIn) {
      // We get here when on login page and when already signed in. Reloading
      // the page would redirect to landing.
      this.authService.reloadPage();
      return;
    }

    const authService =
        this.authService as AuthBrowserService | AuthPluginService;

    // Case plugin
    if (authService instanceof AuthPluginService) {
      try {
        await this.initiatePluginAuth(authService);
      } catch (error) {
        this.pluginAuthInitiated$.next(false);
        this.snackBar.error({
          message: 'Sign-in failed, please try again.',
          details: String(error),
        });
        return;
      }
      this.analyticsService.logEvent('login', {
        eventType: AnalyticsEventType.NAVIGATION,
      });
      return;
    }

    // Case web browser
    const redirect = await authService.prepareOauth2Redirect();
    if (!redirect) {
      this.snackBar.error('Sign-in failed, please try again.');
      return;
    }
    this.analyticsService.logEvent('login', {
      eventType: AnalyticsEventType.NAVIGATION,
    });
    redirect();
  }

  private async initiatePluginAuth(authService: AuthPluginService) {
    this.pluginAuthInitiated$.next(true);

    // After 10s, show the sign-in button again in case the user closed
    // the external OAuth page without signing in or anything went wrong.
    setTimeout(() => {
      this.pluginAuthInitiated$.next(false);
    }, 10_000);

    // Open external OAuth page and wait for flow completion.
    await authService.initiateAuth();
    // It succeeded, redirect users to the landing page.
    await this.router.navigate(['/'], {queryParamsHandling: 'merge'});
  }
}
