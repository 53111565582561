/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiSpriteSheet } from './api-sprite-sheet';


/**
 * A rendition of this asset.
 */
export interface ApiAssetRendition { 
    spriteSheet?: ApiSpriteSheet;
    /**
     * The Cloud Storage URL (e.g. \"gs://mybucket/myfile.mp4\").
     */
    url?: string;
    /**
     * The version.
     */
    version?: ApiAssetRendition.VersionEnum;
}
export namespace ApiAssetRendition {
    export type VersionEnum = 'RENDITION_VERSION_UNKNOWN' | 'SMALL_LOW_FPS' | 'SMALL_MID_FPS' | 'SMALL_HIGH_FPS' | 'PREVIEW_SEEK' | 'PREVIEW_MAIN' | 'PREVIEW_MAIN_DASH' | 'SMALL_DYNAMIC_FPS' | 'LIVE_MAIN_DASH' | 'ORIGINAL' | 'LIVE_PREVIEW_DASH' | 'PREVIEW_MAIN_HLS' | 'LIVE_MAIN_HLS' | 'LIVE_PREVIEW_HLS';
    export const VersionEnum = {
        RENDITION_VERSION_UNKNOWN: 'RENDITION_VERSION_UNKNOWN' as VersionEnum,
        SMALL_LOW_FPS: 'SMALL_LOW_FPS' as VersionEnum,
        SMALL_MID_FPS: 'SMALL_MID_FPS' as VersionEnum,
        SMALL_HIGH_FPS: 'SMALL_HIGH_FPS' as VersionEnum,
        PREVIEW_SEEK: 'PREVIEW_SEEK' as VersionEnum,
        PREVIEW_MAIN: 'PREVIEW_MAIN' as VersionEnum,
        PREVIEW_MAIN_DASH: 'PREVIEW_MAIN_DASH' as VersionEnum,
        SMALL_DYNAMIC_FPS: 'SMALL_DYNAMIC_FPS' as VersionEnum,
        LIVE_MAIN_DASH: 'LIVE_MAIN_DASH' as VersionEnum,
        ORIGINAL: 'ORIGINAL' as VersionEnum,
        LIVE_PREVIEW_DASH: 'LIVE_PREVIEW_DASH' as VersionEnum,
        PREVIEW_MAIN_HLS: 'PREVIEW_MAIN_HLS' as VersionEnum,
        LIVE_MAIN_HLS: 'LIVE_MAIN_HLS' as VersionEnum,
        LIVE_PREVIEW_HLS: 'LIVE_PREVIEW_HLS' as VersionEnum
    };
}


