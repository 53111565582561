import {Inject, Injectable, InjectionToken} from '@angular/core';

/* Injection token indicating if used device is touchScreen. */
export const IS_TOUCH_INPUT =
    new InjectionToken<boolean>('device is touch screen', {
      providedIn: 'root',
      factory: () => false,
    });    

@Injectable({providedIn: 'root'})
export class DeviceInputService {
  constructor(@Inject(IS_TOUCH_INPUT) private readonly isTouch: () => boolean) {}

  isInputViaTouch() {
    return this.isTouch();
  }

  isTouchEvent(event: UIEvent): boolean {
    return /touch.*/.test(event.type);
  }
}
