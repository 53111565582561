
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';

import { Clip } from '../../services/asset_service';
import { DisplayMode } from '../../services/vod_search_service';


/**
 * Clickable clip bin card with up to 3 clips previews.
 */

@Component({
  selector: 'mam-clip-preview',
  templateUrl: './clip-preview.component.html',
  styleUrl: './clip-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipPreviewComponent implements OnDestroy {
  @Input() clip!: Clip;

	// toggles between grid and list view
	@Input() viewMode: DisplayMode = DisplayMode.GRID;

  //toggles between active and inactive ina selection
  @Input() active: boolean = false;

	/** True when layout switches to SMALL. */
	isSmallScreen = false;

	/** True when layout switches to MID. */
	isMidScreen = false;

	/** True when layout switches to Large. */
	isLargeScreen = false;

	/** True when layout switches to SMALL Landscape mode. */
	isSmallScreenLandScape = false;

	private readonly destroyed$ = new ReplaySubject<void>(1);

	constructor(private readonly dialog: MatDialog) {}

	ngOnDestroy() {
		// Unsubscribes all pending subscriptions.
		this.destroyed$.next();
		this.destroyed$.complete();
	}

  getDuration(timestamp:number):string{
    const _timestamp = Math.round(timestamp * 1000) / 1000;
    let formatted:string = '';
    const hours = Math.floor(_timestamp / 60 / 60);
    const minutes = Math.floor(_timestamp / 60) - (hours * 60);
    const seconds = Math.floor(_timestamp % 60);
    const miliseconds = Math.round((timestamp % 1) * 1000);
    formatted = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0') + ':' + miliseconds.toString().padStart(3, '0');

    return formatted;
  }
}
