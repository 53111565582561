<ng-container *ngIf="!filterDisabled; else disabled">
  <button
      mat-button
      mam-firebase-ga="Open transfer table filter"
      [mam-firebase-resource]="headerName"
      [matMenuTriggerFor]="menu"
      [disabled]="disableMenu"
      class="trigger-button">
    <span class="trigger-button-content">
      <span>{{getTitle()}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </span>
  </button>
  <mat-menu #menu="matMenu" class="mam-table-header-filter-menu">
    <button *ngFor="let option of options"
            mat-menu-item (click)="onToggle(option, $event)"
            mam-firebase-ga="Toggle transfer table filter"
            [mam-firebase-resource]="option.title">
      <ng-container *ngIf="multiSelect; else singleChoiceItem">
        <mat-checkbox color="primary" [checked]="option.selected">
          {{option.title}}
        </mat-checkbox>
      </ng-container>
      <ng-template #singleChoiceItem>
        <mat-icon [class.checked]="option.selected">done</mat-icon>
        <span class="filter-item-name">
          {{option.title}}
        </span>
      </ng-template>
    </button>
  </mat-menu>
</ng-container>
<ng-template #disabled>
  <span class="filter-disabled">{{headerName}}</span>
</ng-template>
