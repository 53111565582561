<h1 mat-dialog-title>Get link for {{data.asset.original && data.asset.original.duration.toFixed(0) !== data.asset.duration.toFixed(0) ? 'clip' : 'video'}}</h1>

<div mat-dialog-content class="dialog__get-link--spacing">
  <!-- URL and copy button -->
  <section class="url-area">
    <mat-form-field class="url-area__input--spacing" appearance="outline" subscriptSizing="dynamic">
      <input
          matInput
          readonly
          [value]="url+originalHash"
          placeholder="loading..."
          aria-label="URL of the shared video">
    </mat-form-field>
    <button
        [disabled]="!url"
        class="copy-url"
        mat-flat-button
        mam-firebase-ga="Copy Shared Link Url"
        [mam-firebase-resource]="data.asset.name"
        [cdkCopyToClipboard]="url+originalHash"
        (click)="snackbarClipboardCopy()">
      Copy
    </button>
  </section>

  <!-- Expiration time menu selection -->
  <button
      mat-button
      class="expiration-selector"
      [disabled]="!url || isUpdatingExpiration"
      [matMenuTriggerFor]="expirationsMenu"
      #menuTrigger="matMenuTrigger"
      mam-firebase-ga="Open expiration time picker"
      [mam-firebase-resource]="data.asset.name">
    <span>{{formatExpiration(selectedDaysOption)}}</span>
    <mat-icon class="arrow main__icon-arrow--size">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #expirationsMenu="matMenu">
    <button
        *ngFor="let daysOption of expirationDaysOptions"
        mat-menu-item
        (click)="selectDaysOption(daysOption)"
        mam-firebase-ga="Select expiration time"
        mam-firebase-resource="{{daysOption}}">
      <mat-icon [class.invisible]="selectedDaysOption !== daysOption">
        done
      </mat-icon>
      <span>{{formatExpiration(daysOption)}}</span>
    </button>
  </mat-menu>
</div>

<div mat-dialog-actions class="dialog-get-link__actions--position">
  <button
    mat-button
    [mat-dialog-close]="selectedDaysOption"
    class="primary dialog__button--position"
    mam-firebase-ga="Close link sharing dialog"
    [mam-firebase-resource]="data.asset.name">
    Done
  </button>
</div>
