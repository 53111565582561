<mat-card class="empty-bins">
	<img alt="Empty bins" src="images/empty_contents_logo.svg" />
	<div>
    @if(hasSearchText){
      <h1>Your search did not match any {{ assetsType }}.</h1>
      <p>Please try a different search or create new {{ assetsType }}.</p>
    }@else{
      <h1>You have no {{ assetsType }}.</h1>
      <ng-container *ngIf="helperText">
        <ng-content></ng-content>
      </ng-container>
    }


	</div>
</mat-card>
