<a mat-button
       matTooltip="{{clipBinName}}"
       matTooltipPosition="below"
       matTooltipClass="mobile-tooltip"
       [routerLink]="['/clipbin', clipBinName, 'clip', 0]"
       [queryParams]="{'type': 'bin'}"
       queryParamsHandling="merge"
       mam-firebase-ga="Open clip bin from export table"
       [mam-firebase-resource]="clipBinName"
       *ngIf="clipBinTitle$ | async as clipBinTitle"
       target="_blank">
         {{ clipBinTitle }}
</a>
