import {ApiTransferTask} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

import {FileResource} from './file-resource';
import {Folder} from './folder';

/** A UI type representing a transfer task */
export class TransferTask implements RequiredRecursively<ApiTransferTask> {
  tags = this.apiTask.tags ?? [];
  fileProgress = this.apiTask.fileProgress ?? [];
  files = (this.apiTask.files ?? []).map(f => new FileResource(f));
  folder = new Folder(this.apiTask.folder ?? {});
  manualOnpremDelete = this.apiTask.manualOnpremDelete ?? false;
  name = this.apiTask.name ?? '';
  user = '';
  retries = this.apiTask.retries ?? 0;
  taskId = this.apiTask.taskId ?? '';
  timeoutPeriod = this.apiTask.timeoutPeriod ?? 0;
  transferEndTime = this.apiTask.transferEndTime ?? '';
  transferModTime = this.apiTask.transferModTime ?? '';
  transferRate = this.apiTask.transferRate ?? 0;
  transferStartTime = this.apiTask.transferStartTime ?? '';
  transferState = this.apiTask.transferState ?? 'TRANSFER_STATE_UNSPECIFIED';
  transferType = this.apiTask.transferType ?? 'TRANSFER_DIRECTION_UNSPECIFIED';
  deletionType = this.apiTask.deletionType ?? 'DELETION_TYPE_UNSPECIFIED';

  constructor(private apiTask: ApiTransferTask = {}) {}
}
