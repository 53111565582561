<ng-container *ngIf="clipbinsOwnerTypes.length > 1; else singleOwnerType">
  <button
      class="select-bin-owner"
      mat-button
      [matMenuTriggerFor]="menuClipBinOwner">
    <span>{{getOwnerText(owner)}}</span>
    <mat-icon matSuffix class="arrow main__icon-arrow--size">arrow_drop_down</mat-icon>
  </button>
  <mat-menu
      #menuClipBinOwner="matMenu"
      class="clip-bin-owner-menu">
    <button
        *ngFor="let ownerType of clipbinsOwnerTypes"
        mat-menu-item
        (click)="ownerChange.emit(ownerType)"
        mam-firebase-ga="Select clipbins owner type"
        [mam-firebase-resource]="getOwnerText(ownerType)">
        <mat-icon [style.opacity]="ownerType === owner ? 1 : 0">
          done
        </mat-icon>
      <span>{{getOwnerText(ownerType)}}</span>
    </button>
  </mat-menu>
</ng-container>

<ng-template #singleOwnerType>
  <h1>{{getOwnerText(clipbinsOwnerTypes[0])}}</h1>
</ng-template>
