import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, ComponentRef, Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewContainerRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { take } from 'rxjs';

import { FeatureFlagService } from 'feature_flag/feature_flag_service';


@Directive({
  standalone: true,
  selector: '[mamTable]',
  hostDirectives: [
    {
      directive: CdkDropList,
      outputs: ['cdkDropListDropped']
    },
  ],
})
export class ManTableDirective implements OnInit {
  @Input() mamTable!: string[];

  readonly showColOption = this.featureService.featureOn('show-col-option');

  constructor(
    private cdkDropList: CdkDropList,
    private readonly featureService: FeatureFlagService){ }

  ngOnInit() {
    this.cdkDropList.orientation = "horizontal";
  }

  @HostListener('cdkDropListDropped', ['$event'])
  onDrop(event: CdkDragDrop<string[]>) {
    if (!this.showColOption) return;

    const ths = event.container.element.nativeElement.querySelectorAll('th');
    const currentEl = Array.from(ths).find((th) => th.cellIndex === event.currentIndex);
    moveItemInArray(this.mamTable, event.previousIndex, currentEl?.draggable ? event.currentIndex : event.previousIndex);
  }
}


@Directive({
  standalone: true,
  selector: '[mamCol]',
  hostDirectives: [
    {
      directive: CdkDrag,
    },
  ],
})
export class ManColDirective implements OnInit {
  @Input() mamMinWidth: number = 20;
  @Input() mamMaxWidth: number = 700;
  @Input() hideResizer: boolean = false;
  private th: HTMLTableColElement;
  private table!: HTMLTableElement;
  private tableMenu!: ComponentRef<TableMenuComponent>;
  private startX!: number;
  private startWidth!: number;
  private pressed!: boolean;
  @Output() mamResetCols = new EventEmitter();
  readonly showColOption = this.featureService.featureOn('show-col-option');

 constructor(
  private el: ElementRef<HTMLTableColElement>,
  private renderer: Renderer2,
  private vcr: ViewContainerRef,
  private cdkDrag: CdkDrag,
  private readonly featureService: FeatureFlagService){
    this.th = this.el.nativeElement;
  }

  ngOnInit() {
    //TODO: Remove this (this is a way to temporarily turn off the drag and drop)
    this.cdkDrag.disabled = true;
    this.th.draggable = false;
    if (!this.showColOption) return;

    this.cdkDrag.lockAxis = 'x';
    this.renderer.setStyle(this.th, 'width', this.th.getAttribute('mamCol'));
    // this.cdkDrag.disabled = !this.th.draggable;

    const tr = this.renderer.parentNode(this.th);
    const thead = this.renderer.parentNode(tr);
    this.table = this.renderer.parentNode(thead);

    const resizerBox = this.renderer.createElement('div');
    this.renderer.appendChild(this.th, resizerBox);
    this.renderer.addClass(resizerBox, 'resizer-box');
    const resizer = this.renderer.createElement('div');
    this.renderer.appendChild(resizerBox, resizer);
    this.renderer.addClass(resizer, 'resizer');

    if(this.hideResizer) {
      this.renderer.setStyle(resizerBox, 'visibility', 'hidden');
    }

    this.renderer.listen(resizerBox, 'mousedown', this.onMouseDown);
    this.renderer.listen(this.table, 'mousemove', this.onMouseMove);
    this.renderer.listen('document', 'mouseup', this.onMouseUp);
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (!this.showColOption) return;

    this.tableMenu = this.vcr.createComponent(TableMenuComponent);
    this.renderer.appendChild(this.th, this.tableMenu.location.nativeElement);

    const ths = this.table.querySelectorAll('th');
    this.tableMenu.instance?.autoSizeAllEvent.pipe(take(1)).subscribe(() => Array.from(ths, (th) => this.renderer.setStyle(th, 'width', th.getAttribute('mamCol'))));
    this.tableMenu.instance?.resetAllEvent.pipe(take(1)).subscribe(() => Array.from(ths, (th) => {
      this.renderer.setStyle(th, 'width', th.getAttribute('mamCol'));
      this.onResetCols();
    }));
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (!this.showColOption) return;
    this.tableMenu.location.nativeElement.remove();
  }

  onMouseDown = (event: MouseEvent) => {
    return;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.th.offsetWidth;
    this.cdkDrag.disabled = true;
    this.th.draggable = false;
  };

  onMouseMove = (event: MouseEvent) => {
    const offset = 35;
    if (this.pressed && event.buttons) {
      const width = this.startWidth + (event.pageX - this.startX - offset);
      if(width < this.mamMinWidth || width > this.mamMaxWidth) return;
      this.renderer.setStyle(this.th, 'width', `${width}px`);
    }
  };

  onMouseUp = () => {
    if (this.pressed) {
      this.pressed = false;
      this.cdkDrag.disabled = false;
    }
  };

  onResetCols() {
    this.mamResetCols.emit();
	}
}


/* Table Menu */
@Component({
  standalone: true,
  selector: 'mam-table-menu',
  imports: [MatMenuModule, MatButtonModule, MatIconModule, MatDividerModule],
  template: `
    <button mat-icon-button [matMenuTriggerFor]="colMenu" (click)="$event.stopPropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #colMenu="matMenu">
      <button mat-menu-item type="button" (click)="onAutoSizeAll($event)">
        <mat-icon>
          <svg width="18" height="18" fill="currentColor" viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg"><path d="M160-240h160v-480H160v480Zm240 0h160v-480H400v480Zm240 0h160v-480H640v480Zm-480 80q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Z"/></svg>
        </mat-icon>
        <span>Autosize All Columns</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item type="button" (click)="onResetAll($event)">
        <mat-icon>
          <svg width="18" height="18" fill="currentColor" viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg"><path d="M120-120v-720h80v720h-80Zm640 0v-720h80v720h-80ZM280-440v-80h80v80h-80Zm160 0v-80h80v80h-80Zm160 0v-80h80v80h-80Z"/></svg>
        </mat-icon>
        <span>Reset All Columns</span>
      </button>
    </mat-menu>
  `,
  styles: [`
      @use './shared/variables' as vars;

      :host {
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 10;
        transform: translateY(-50%);
      }

      button {
        text-wrap: nowrap;

        .mat-icon {
          color: vars.$gmat-gray-400;
        }
      }

      .mat-icon {
        font-size: 18px;
      }

      .mat-mdc-menu-item .mat-icon {
        margin-right: 6px;
      }

      ::ng-deep .mat-mdc-menu-panel {
        background-color: vars.$gmat-gray-800 !important;
      }

  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableMenuComponent {
  @Output() autoSizeAllEvent = new EventEmitter<MouseEvent>();
  @Output() resetAllEvent = new EventEmitter<MouseEvent>();

  onAutoSizeAll(event: MouseEvent) {
    this.autoSizeAllEvent.emit(event);
	}

  onResetAll(event: MouseEvent) {
    this.resetAllEvent.emit(event);
	}

}
