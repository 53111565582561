<h1 mat-dialog-title>
  <div class="clip-title">Export comp reel</div>
</h1>
<div mat-dialog-content>
  <ng-container *ngIf="state$ | async as state">
    <mat-form-field
        *featureFlagOn="'use-comp-reel-custom-name'"
        class="filename-field"
        floatLabel="always">
      <mat-label>File name</mat-label>
      <input
          matInput
          aria-label="File name entry"
          #fileNameInput
          [placeholder]="defaultFileName"
          [formControl]="filenameControl" />
      <span matSuffix>.mxf</span>
      <mat-hint class="invalid" *ngIf="filenameControl.invalid">{{getErrorMessage()}}</mat-hint>
    </mat-form-field>

    <table mat-table
        [trackBy]="trackFolder"
        [dataSource]="state.folders">

      <!-- Radio Button Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-radio-button color="primary"
              (click)="selectedFolder = row.name"
              [checked]="selectedFolder === row.name">
          </mat-radio-button>
        </td>
      </ng-container>

      <!-- Folder Name Column -->
      <ng-container matColumnDef="folderName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Folder</th>
        <td mat-cell *matCellDef="let row" [title]="row.name">
          <span [matTooltip]="row.folderId" matTooltipTouchGestures="on"
            matTooltipPosition="above" matTooltipClass="mobile-tooltip"
          >
            <mat-icon inline="true">folder</mat-icon>
            {{row.folderId}}
          </span>
        </td>
      </ng-container>

      <!-- Export Status Column -->
      <ng-container matColumnDef="exportStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Export Status</th>
        <ng-container *matCellDef="let row">
          <ng-container *ngIf="state?.compReelInfo?.compReelData?.[row.name]?.state | exportStatus as status">
            <td mat-cell [title]="status" class="export-column-status">
              <ng-container [ngSwitch]="status">
                <ng-container *ngSwitchCase="'Not started'">
                  <mat-icon
                    class="status-icon icon-not-started"
                    matTooltip="Not started"
                    matTooltipPosition="below"
                    matTooltipClass="mobile-tooltip">
                    do_not_disturb_on
                  </mat-icon>
                  <span class="status-text">Not started</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Failed'">
                  <mat-icon
                    class="status-icon icon-failed"
                    matTooltip="Failed"
                    matTooltipPosition="below"
                    matTooltipClass="mobile-tooltip">
                    error</mat-icon>
                  <span class="status-text">Failed</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Pending'">
                  <mat-icon
                    class="status-icon icon-pending"
                    matTooltip="Pending"
                    matTooltipPosition="below"
                    matTooltipClass="mobile-tooltip">
                    pending
                  </mat-icon>
                  <span class="status-text">Pending</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Completed'">
                  <mat-icon
                    class="status-icon icon-completed"
                    matTooltip="Completed"
                    matTooltipPosition="below"
                    matTooltipClass="mobile-tooltip">
                    check_circle
                  </mat-icon>
                  <span class="status-text">Completed</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Downloading'">
                  <mat-icon
                    class="status-icon icon-downloading"
                    matTooltip="Downloading"
                    matTooltipPosition="below"
                    matTooltipClass="mobile-tooltip">
                    downloading
                  </mat-icon>
                  <span class="status-text">Downloading</span>
                </ng-container>  
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- File Name Column -->
      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>File Name</th>
        <td mat-cell *matCellDef="let row" [title]="getFileName(row.name, state.compReelInfo)">
          {{getFileName(row.name, state.compReelInfo)}}
        </td>
      </ng-container>

      <!-- Last Modified Column -->
      <ng-container matColumnDef="lastExported">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Exported</th>
        <td mat-cell *matCellDef="let row" [title]="getLastExported(row.name, state.compReelInfo)">
          {{getLastExported(row.name, state.compReelInfo)}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="state.folders.length === 0" class="empty-message">
      No export folder available
    </div>
  </ng-container>

</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel exporting live clip">
    Cancel
  </button>
  <button
      mat-dialog-close
      mat-button
      #primary
      class="primary"
      aria-label="Export to selected folder(s)"
      mam-firebase-ga="Export live clip"
      [disabled]="!selectedFolder || filenameControl.invalid"
      (click)="exportCompReel()">
    Export
  </button>
</div>
