import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {assertTruthy, assumeExhaustiveAllowing, castExists} from 'asserts/asserts';

import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {DeviceInputService} from '../services/device_input_service';
import {ResizeObserverService} from '../services/resize_observer_service';
import {KeywordResult, QuerySegment, SearchMode} from '../services/search_service';
import {ShortcutEvent, ShortcutEvents} from '../services/state_service';
import {UtilsService} from '../services/utils_service';

import {BufferSegment} from './video_player';

/** Tracks the start and end marker on the clip timeline. */
export interface Marking {
  markIn: number;
  markOut: number;
}

/** Segment in the timeline that will be highlighted. */
export interface CutDownSegment {
  startTime: number;
  endTime: number;
  type: 'clip'|'cutdown';
}

/**
 * Segments in the timeline that will be highlighted. The presence of this
 * array, even if empty, will also change the colors of the timeline.
 */
export type CutDownSegments = CutDownSegment[];

/**
 * Video timeline that supports seek-on-hover, clip marking, clicks.
 */
@Component({
  selector: 'mam-timeline',
  templateUrl: './timeline.ng.html',
  styleUrls: ['./timeline.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Timeline implements AfterViewInit, OnDestroy {
  @Input()
  get duration(): number {
    return this.durationInternal;
  }
  set duration(value: number) {
    this.durationInternal = value;
    this.onTimelineSeek();
  }

  /**
   * Sets the main (non-seek) playhead time. No
   * playhead is shown if `undefined`.
   */
  @Input() mainTime?: number;

  /** Used to display a query chip above the timeline */
  @Input() querySegment?: QuerySegment;

  /**
   * Used with the insights panel to display a search layer above the timeline
   */
  @Input() keywordResults?: KeywordResult[];

  /** Receives the selected keyword from the insights panel */
  @Input() selectedKeyword?: string;

  /**
   * When hovering over a keyword segment above the timeline, only segments with
   * the same keyword will be highlighted, This keeps track of the previously
   * selected chip so when the mouse leaves the keyword segment, the timeline
   * will look like how it was before the hover.
   */
  previousKeyword?: string;

  /** Used to display query chip text */
  @Input() userQuery?: string;

  /** Sets semi-transparent horizontal segments. */
  @Input() bufferSegments: BufferSegment[] = [];

  /**
   * Whether the current video is a live stream. The timeline turns red and
   * hides the main playhead.
   */
  @Input() live = false;

  /**
   * When defined, durations will be rendered as absolute times offset from
   * this given start wallclock time. Timestamp in ms since epoch.
   */
  @Input() startTimestamp?: number;

  /** Whether keyboard shortcuts are disabled. */
  @Input() disabledShortcuts = false;

  /**
   * Optional list of segments to be highlighted in the timeline. When this
   * list is defined (even if empty), the timeline is in `cut-down` mode and
   * changes color.
   */
  @Input()
  get cutDownSegments(): CutDownSegments|undefined {
    return this.cutDownSegmentsInternal;
  }
  set cutDownSegments(segments: CutDownSegments|undefined) {
    this.cutDownSegmentsInternal = segments;
    if (!segments) {
      this.clearMarking();
    } else if (!this.clipMarking) {
      this.updateAndEmitMarking(0, this.duration);
    }
  }

  private disableMarkingInternal = false;

  /** Whether marking the timeline (in and out) is disabled. */
  @Input()
  get disabledMarking() {
    return this.disableMarkingInternal;
  }
  set disabledMarking(disableMarking) {
    this.disableMarkingInternal = disableMarking;

    if (disableMarking) {
      this.clearMarking();
    }
  }


  /** Emits the `time` where the timeline is clicked. */
  @Output() readonly clickTimeline = new EventEmitter<number>();

  /** Emits updated boundaries when marking a clip. */
  @Output() readonly marking = new EventEmitter<Marking>();

  /** Emits shortcut events. */
  @Output() readonly shortcutEvent = new EventEmitter<ShortcutEvent>();

  /**
   * Emits the `time` where the timeline is moused over, or `undefined` if the
   * seeking is finished.
   */
  @Output() readonly seekTimeline = new EventEmitter<number|undefined>();

  @ViewChild('timeline') timeline!: ElementRef<HTMLElement>;

  /** Keyboard current being moused-over. */
  hoveredKeyword?: string;

  /** Left end of the clip marking */
  get markInTime(): number|undefined {
    if (!this.clipMarking) return;
    return Math.min(this.clipMarking.edgeTime1, this.clipMarking.edgeTime2);
  }

  /** Right end of the clip marking */
  get markOutTime(): number|undefined {
    if (!this.clipMarking) return;
    return Math.max(this.clipMarking.edgeTime1, this.clipMarking.edgeTime2);
  }

  constructor(
      private readonly analyticsService: FirebaseAnalyticsService,
      private readonly cdr: ChangeDetectorRef,
      private readonly resizeObserver: ResizeObserverService,
      private readonly utils: UtilsService,
      private readonly deviceInput: DeviceInputService,
  ) {}

  /** Clicking the timeline sets the main time. */
  @HostListener('mouseup', ['$event'])
  mouseUp(event: UIEvent) {
    this.finishMarkingAndSeek(event);
  }

  @HostListener('touchend', ['$event'])
  touchEnd(event: UIEvent) {
    this.finishMarkingAndSeek(event);
    this.endSeek();
  }

  /** Hovering the timeline sets the seek time. */
  @HostListener('mousemove', ['$event'])
  @HostListener('touchmove', ['$event'])
  mouseMove(event: UIEvent) {
    this.lastUiMoveEvent = event;
    this.onTimelineSeek();
  }

  /** Begins drag-and-drop for clip marking. */
  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  mouseDown(event: UIEvent) {
    if ((event as MouseEvent).buttons === 0) return;

    const pos = this.getOffsetX(event);
    this.mouseDownStartedAt = performance.now();
    this.mouseDownTime = this.positionToTime(pos);
  }

  /** When hovering stops, seeking stops. */
  @HostListener('mouseleave')
  mouseLeave() {
    this.endSeek();
  }

  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (this.disabledShortcuts) return;

    const key = this.utils.getKeyboardShortcut(event);
    if (!key) return;

    switch (key) {
      case 'i':
        this.markIn();
        break;

      case 'o':
        this.markOut();
        break;

      case 'shift+i':
        this.goToMarkIn();
        break;
      case 'shift+o':
        this.goToMarkOut();
        break;
      case 'm':
        if (this.clipMarking) this.emitShortcutEvent({ key: 'a', intent: ShortcutEvents.CREATE_CLIP_SEGMENT, targetTab: 'play-feed' });
        break;

      default:
        return;
    }

    this.analyticsService.logEvent('Keyboard shortcut for Timeline', {
      string1: key,
      string2: event.code,
      eventType: AnalyticsEventType.KEY_DOWN,
    });
  }

  /** Number of pixels on each side of the timeline. */
  gap = 20;

  // We use getter/setter to notify changes to the parent
  seekTime?: number = undefined;

  ngAfterViewInit() {
    this.resizeObserver.observe(this.timeline.nativeElement)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((timelineRect) => {
          this.timelineWidth = timelineRect.width;
          this.cdr.markForCheck();
        });
  }

  private endSeek() {
    this.lastUiMoveEvent = undefined;
    this.seekTime = undefined;
    this.seekTimeline.emit(this.seekTime);
  }

  private finishMarkingAndSeek(event: UIEvent) {
    if (this.isMarking) {
      const duration = performance.now() - castExists(this.mouseDownStartedAt);
      this.analyticsService.logEvent(
          `Clip mark drag ${this.handleDragged ?? 'to create clip'}`, {
            eventType: AnalyticsEventType.MOUSE_MOVE,
            duration: Math.round(duration),
          });
      this.endMarking();
      return;
    }

    const pos = this.getOffsetX(event, true);
    const time = this.positionToTime(pos);
    if (Number.isNaN(time)) return;
    this.clickTimeline.emit(time);

  }

  private emitShortcutEvent(event: ShortcutEvent) {
    this.shortcutEvent.emit(event);
  }

  /**
   * Converts a time into an horizontal position.
   *
   *             timeline block
   * |------------------------------------------|
   * | gap          timeline                gap |
   * |     [==============================]     |
   * |__________________________________________|
   *       <------- (in) time range ------>
   *       <----- (out) position range --->
   */
  timeToPosition(time: number) {
    if (!this.timelineWidth) return Number.NaN;

    const ratio = time / this.duration;
    const positionFromTimeline = ratio * this.timelineWidth;
    return positionFromTimeline + this.gap;
  }

  /**
   * Converts an horizontal position into a time.
   *
   *             timeline block
   * |------------------------------------------|
   * | gap          timeline                gap |
   * |     [==============================]     |
   * |__________________________________________|
   *  <---------- (in) position range ---------->
   *  crop <----- (out) time range ------> crop
   */
  positionToTime(position: number) {
    if (!this.timelineWidth) return Number.NaN;

    // Any position on the left gap is cropped to 0
    position = Math.max(0, position - this.gap);

    // Any position on the right gap is cropped to timelineWidth
    position = Math.min(this.timelineWidth, position);

    const ratio = position / this.timelineWidth;
    return ratio * this.duration;
  }

  durationToWidth(startTime: number, endTime: number): number {
    return this.timeToPosition(endTime) - this.timeToPosition(startTime);
  }

  trackSegment(index: number, segment: BufferSegment) {
    return segment.startTime;
  }

  trackSearchSegment(index: number) {
    return index;
  }

  clearMarking() {
    this.clipMarking = undefined;
    this.marking.emit(undefined);
  }

  showQueryChip() {
    return this.querySegment?.searchMode === SearchMode.SEGMENT &&
        this.duration > 0 && this.userQuery && !this.keywordResults?.length;
  }

  selectQueryChip(querySegment: QuerySegment) {
    if (this.disabledMarking) return;
    this.updateAndEmitMarking(querySegment.startTime, querySegment.endTime);
  }

  startLeftHandleDrag(event: UIEvent) {
    event.preventDefault();
    this.handleDragged = 'left';
  }

  startRightHandleDrag(event: UIEvent) {
    event.preventDefault();
    this.handleDragged = 'right';
  }

  startBothHandlesDrag(event: UIEvent) {
    event.preventDefault();
    this.handleDragged = 'both';
  }

  /**
   * Optional keyword highlighted in the timeline. If undefined, all keywords
   * will be highlighted.
   */
  getActiveKeyword() {
    return this.hoveredKeyword || this.selectedKeyword;
  }

  // ==== Private properties

  private readonly SNAP_DISTANCE = 5;

  private timelineWidth = 0;

  /** Currently marking a clip by dragging the mouse */
  private isMarking = false;

  /** Timeline time from which the user started dragging. */
  private mouseDownTime?: number;

  /** Indicates when in application timing did the user start dragging. */
  private mouseDownStartedAt?: number;

  private handleDragged?: 'left'|'right'|'both';

  private durationInternal = 0;

  /**
   * Memorizes the last mouse move event (`undefined` if the cursor is not over
   * the timeline), so that seek events can be emitted while the timeline grows
   * under the playhead, during a live stream.
   */
  private lastUiMoveEvent?: UIEvent;

  /**
   * Visual clip area during marking drag-and-drop. It contains two edge times
   * that can be in any order depending if we drag left to right or the
   * opposite direction.
   */
  private clipMarking?: {
    edgeTime1: number,
    edgeTime2: number,
  };

  private cutDownSegmentsInternal?: CutDownSegments;


  /** Reorders marking edges and clears marking if left and right are equal. */
  private endMarking() {
    if (this.disabledMarking) return;
    if (!this.isMarking) return;

    this.isMarking = false;
    this.handleDragged = undefined;

    if (this.clipMarking) {
      // Re-order edges to simplify handle dragging logic
      [this.clipMarking.edgeTime1, this.clipMarking.edgeTime2] = [
        Math.min(this.clipMarking.edgeTime1, this.clipMarking.edgeTime2),
        Math.max(this.clipMarking.edgeTime1, this.clipMarking.edgeTime2)
      ];

      // If the marking is empty, erase it.
      if (this.clipMarking.edgeTime1 === this.clipMarking.edgeTime2) {
        this.clearMarking();
      }
    }
  }

  /** Similar to `event.offsetX` but is relative to the event currentTarget. */
  private getOffsetX(event: UIEvent, snapToHandles = false): number {
    const currentTarget = event.currentTarget as HTMLElement | null;
    let pos = 0;

    if (this.deviceInput.isTouchEvent(event)) {
      const touchEvent = event as TouchEvent;

      pos = currentTarget
        ? (touchEvent.changedTouches[0].clientX - currentTarget.getBoundingClientRect().left)
        : touchEvent.changedTouches[0].screenX;
    } else {
      const mouseEvent = event as MouseEvent;
      pos = currentTarget
        ? (mouseEvent.clientX - currentTarget.getBoundingClientRect().left)
        : mouseEvent.offsetX;
    }

    if (snapToHandles && !this.isMarking) {
      // Snap to mark-in handle
      if (this.markInTime) {
        const markInPos = this.timeToPosition(this.markInTime);
        if (Math.abs(pos - markInPos) < this.SNAP_DISTANCE) {
          return markInPos;
        }
      }

      // Snap to mark-out handle
      if (this.markOutTime) {
        const markOutPos = this.timeToPosition(this.markOutTime);
        if (Math.abs(pos - markOutPos) < this.SNAP_DISTANCE) {
          return markOutPos;
        }
      }

      // Snap to timeline beginning
      if (pos - this.gap < this.SNAP_DISTANCE) {
        return this.gap;
      }
    }

    return pos;
  }

  /**
   * Called when the mouse is moved over the timeline while a button is
   * pressed, which will start drawing or update the clip markings.
   */
  private onDragging(event: UIEvent, time: number) {
    if (this.disabledMarking) return;
    this.isMarking = true;

    // The first time we move the mouse with the button pressed, start
    // creating a clip area.
    if (this.mouseDownTime != null && !this.handleDragged) {
      this.clipMarking = {
        edgeTime1: this.mouseDownTime,
        edgeTime2: time,
      };
      this.mouseDownTime = undefined;
    }
    // Otherwise, extend the existing current marking.
    else {
      const marking = this.clipMarking;

      // If we pressed the mouse outside of the timeline and then moved over it,
      // we will not have a marking here.
      if (!marking) return;

      switch (this.handleDragged) {
        case 'left':
          marking.edgeTime1 = Math.min(time, marking.edgeTime2);
          break;
        case 'right':
          marking.edgeTime2 = Math.max(time, marking.edgeTime1);
          break;
        case 'both': {
          const diff = (this.deviceInput.isTouchEvent(event))
            ? (event as TouchEvent).touches[0].screenX
            : (event as MouseEvent).movementX;
          const edgePos1 = this.timeToPosition(marking.edgeTime1);
          const edgePos2 = this.timeToPosition(marking.edgeTime2);
          const clipDuration = marking.edgeTime2 - marking.edgeTime1;

          // If marking reached left end, block movement on it
          if (edgePos1 + diff < this.gap) {
            marking.edgeTime1 = 0;
            marking.edgeTime2 = clipDuration;
          }
          // If marking reached right end, block movement on it
          else if (edgePos2 + diff > this.timelineWidth + this.gap) {
            marking.edgeTime2 = this.duration;
            marking.edgeTime1 = this.duration - clipDuration;
          }
          // Otherwise, move both edges together.
          else {
            marking.edgeTime1 = this.positionToTime(edgePos1 + diff);
            marking.edgeTime2 = this.positionToTime(edgePos2 + diff);
          }

          break;
        }
        default:
          assumeExhaustiveAllowing<undefined>(this.handleDragged);
          marking.edgeTime2 = time;
      }
    }

    assertTruthy(
        this.markInTime != null && this.markOutTime != null,
        'Timeline.onDragging incorrect markings');

    this.marking.emit({
      markIn: this.markInTime,
      markOut: this.markOutTime,
    });
  }

  /**
   * Updates the left edge of the current marking, or starts a new one if there
   * was none, from the main playhead position.
   */
  private markIn() {
    if (this.disabledMarking) return;

    if (this.mainTime == null) return;
    this.isMarking = true;

    const edgeTime1 = this.mainTime;
    let edgeTime2 = this.markOutTime ?? 0;
    if (edgeTime2 <= edgeTime1) {
      edgeTime2 = this.duration;
    }

    this.updateAndEmitMarking(edgeTime1, edgeTime2);
  }

  /**
   * Updates the right edge of the current marking, or starts a new one if there
   * was none, from the main playhead position.
   */
  private markOut() {
    if (this.disabledMarking) return;

    if (this.mainTime == null) return;
    this.isMarking = true;

    const edgeTime2 = this.mainTime;
    let edgeTime1 = this.markInTime ?? 0;
    if (edgeTime1 >= edgeTime2) {
      edgeTime1 = 0;
    }

    this.updateAndEmitMarking(edgeTime1, edgeTime2);
  }

  /**
   * Moves cursor to Mark in.
   */
  private goToMarkOut() {
    if (!this.markOutTime) return;
    this.clickTimeline.emit(this.markOutTime);
  }

  /**
   * Moves cursor to Mark out.
   */
  private goToMarkIn() {
    if (!this.markInTime) return;
    this.clickTimeline.emit(this.markInTime);
  }

  /** Updates the marked area visually, and emits a marking event. */
  private updateAndEmitMarking(edgeTime1: number, edgeTime2: number) {
    if (this.disabledMarking) return;

    this.clipMarking = {edgeTime1, edgeTime2};
    this.endMarking();
    this.marking.emit({
      markIn: castExists(this.markInTime),
      markOut: castExists(this.markOutTime),
    });
  }

  /**
   * Calculates the time matching the playhead and emit events. Called either
   * when the user moves its cursor over the timeline, or when the timeline
   * duration changes while the cursor is over it (during a live stream).
   */
  private onTimelineSeek() {
    if (this.lastUiMoveEvent == null) return;
    const pos = this.getOffsetX(this.lastUiMoveEvent, true);
    const time = this.positionToTime(pos);

    if (Number.isNaN(time)) return;
    this.seekTime = time;
    this.seekTimeline.emit(time);

    if (this.disabledMarking) return;

    // If seeking with the mouse button pressed ("dragging"),
    // we will start drawing or update a clip marking (the red area).
    if ( (this.deviceInput.isTouchEvent(this.lastUiMoveEvent) && (this.lastUiMoveEvent as TouchEvent).changedTouches.length > 0)
      || (this.lastUiMoveEvent as MouseEvent).buttons > 0) {
      this.onDragging(this.lastUiMoveEvent, time);
    }
    // Otherwise, stop updating the marking so that it stays in place. The call
    // to endMarking is usually done from the mouseUp listener, but we ensure it
    // is called here too in case the button has been released outside of this
    // component.
    else {
      this.endMarking();
    }
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
    To prevent the seek-playhead timer from being cut off,
    we need to determine if the mouse is near the beginning or end of the timeline.
    If so, the timer should remain stationary while only the bar moves.
    The timer has a width of 76 pixels, with the center point (38 pixels) aligned with the seek bar.
    This ensures that the timer remains centered on the bar as it moves along the timeline.
    If the bar's position is less than 38 pixels from the left edge of the timeline,
    the timer is positioned to the left of the bar using a negative offset calculated from timeToPosition.
    If the bar's position is greater than the timeline width minus 38 pixels,
    the timer is positioned to the right of the bar using a positive offset calculated by subtracting
    the timeToPosition from the timeline width and then adding 38 pixels.
   */
  getTimerPosition(seekTime: number){
    const timer = this.timeToPosition(seekTime);
    const offset = -38;
    const toRightOrDefault =(timer > this.timelineWidth ? (offset + this.timelineWidth-(timer)) : offset);

    return timer < 38
      ? -timer
      : toRightOrDefault;
  }
}
