/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiSuggestQueriesResponseQuerySuggestion } from './api-suggest-queries-response-query-suggestion';


/**
 * Response message for getting query suggestions.
 */
export interface ApiSuggestQueriesResponse { 
    /**
     * The type of request that triggered this response.
     */
    requestType?: ApiSuggestQueriesResponse.RequestTypeEnum;
    /**
     * The returned suggested queries.
     */
    suggestedQueries?: Array<ApiSuggestQueriesResponseQuerySuggestion>;
}
export namespace ApiSuggestQueriesResponse {
    export type RequestTypeEnum = 'SUGGEST_QUERIES_REQUEST_TYPE_UNSPECIFIED' | 'SUGGEST_QUERIES_REQUEST_TYPE_VIDEO' | 'SUGGEST_QUERIES_REQUEST_TYPE_SEGMENT' | 'SUGGEST_QUERIES_REQUEST_TYPE_LIVE_VIDEO';
    export const RequestTypeEnum = {
        SUGGEST_QUERIES_REQUEST_TYPE_UNSPECIFIED: 'SUGGEST_QUERIES_REQUEST_TYPE_UNSPECIFIED' as RequestTypeEnum,
        SUGGEST_QUERIES_REQUEST_TYPE_VIDEO: 'SUGGEST_QUERIES_REQUEST_TYPE_VIDEO' as RequestTypeEnum,
        SUGGEST_QUERIES_REQUEST_TYPE_SEGMENT: 'SUGGEST_QUERIES_REQUEST_TYPE_SEGMENT' as RequestTypeEnum,
        SUGGEST_QUERIES_REQUEST_TYPE_LIVE_VIDEO: 'SUGGEST_QUERIES_REQUEST_TYPE_LIVE_VIDEO' as RequestTypeEnum
    };
}


