import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

/** Table header with multi-select filter. */
@Component({
  selector: 'mam-multiselect-table-header',
  templateUrl: './multiselect_table_header.ng.html',
  styleUrls: ['./multiselect_table_header.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation -- TODO: Can styling goals be achieved without this? 
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectTableHeader {
  /** If true shows plain text header without filter menu. Default: false. */
  @Input() filterDisabled = false;

  /** String that will be shown as title. */
  @Input() headerName = '';

  /** Filter options that will be shown in dropdown menu. */
  @Input() options: MultiSelectOptions = [];

  /**
   * Determines if the filter is multi-choice or single-choice.
   * Default: false (single-choice).
   */
  @Input() multiSelect = false;

  /**  Whether to disable the drop down button. */
  @Input() disableMenu = false;

  /** Emits when any filter option is clicked. */
  @Output() readonly toggle = new EventEmitter<MultiSelectOption>();

  /** Callback for when any filter option is clicked. */
  onToggle(option: MultiSelectOption, $event: MouseEvent) {
    if (this.multiSelect) {
      $event.stopPropagation();  // Prevents menu from closing.
      $event.preventDefault();   // Prevents checkbox from receiving the click.
    }
    this.toggle.emit(option);
  }

  /**
   * Determines table header title based on selected options.
   */
  getTitle() {
    const selectedOptionText =
        this.options.filter(opt => opt.value !== undefined && opt.selected)
            .map(opt => opt.title)
            .join(',');
    return selectedOptionText || this.headerName;
  }
}

/** Array of multi-select filter options. */
export type MultiSelectOptions<T = unknown> = Array<MultiSelectOption<T>>;

/** Multi-select filter option. */
export interface MultiSelectOption<T = unknown> {
  title: string;
  // Option that clears the filter aka 'any' should have no value (undefined).
  value?: T;
  selected?: boolean;
}
