import { Injectable } from '@angular/core';
import { firstValueFrom, forkJoin } from 'rxjs';

@Injectable({ providedIn: null })
export abstract class PlaybackAuthorizationService {
    protected constructor(
    ) {
    }

    public async getAuthorizedUris(originalUris: string[]): Promise<string[]> {
        await this.prepareForAuthorizationBatch();

        return await firstValueFrom(
            forkJoin(
                originalUris.map(u => this.getAuthorizedUri(u))
            )
        );
    }

    public async getAuthorizedUri(originalUri: string, linkName?: string): Promise<string> {
        return (this.isAuthorizationRequired(originalUri) && (!this.hasAuthorization(originalUri)) ) ?
            (await this.getAuthorizedUriCore(originalUri, linkName)) :
            originalUri;
    }

    protected abstract prepareForAuthorizationBatch(): Promise<void>;

    protected abstract getAuthorizedUriCore(originalUri: string, linkName?: string): Promise<string>;

    protected abstract hasAuthorization(uri: string): boolean;

    private isAuthorizationRequired(targetUri: string): boolean {
        // Demo streams are public and should not be signed.
        // TODO-HLS: Should this be '/livestream-demo/'?
        return !targetUri.includes('livestream-demo');
    }
}