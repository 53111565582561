<div
    class="container"
    roundedEdge="end">
  <button
      [class.active]="isLandingDisplayed()"
      (click)="selectView.emit(HomeView.LANDING)"
      mam-firebase-ga="Toggle landing view">
    <mat-icon>home</mat-icon>
    <div class="label" *ngIf="!isSmallScreenLandScape">Home</div>
  </button>
  <button
      [class.active]="isLiveDisplayed()"
      (click)="selectView.emit(HomeView.LIVE)"
      mam-firebase-ga="Toggle live view">
    <mat-icon>youtube_live</mat-icon>
    <div class="label" *ngIf="!isSmallScreenLandScape">Live</div>
  </button>
  <button
      [class.active]="isJobsDisplayed()"
      (click)="selectView.emit(HomeView.JOBS)"
      mam-firebase-ga="Toggle jobs view">
    <mat-icon>sync_alt</mat-icon>
    <div
        *ngIf="failedTaskCount"
        class="notification-badge">
    </div>
    <div class="label" *ngIf="!isSmallScreenLandScape">Jobs</div>
    </button>
    <button
        [class.active]="isStagingDisplayed()"
        (click)="selectView.emit(HomeView.STAGING)"
        mam-firebase-ga="Toggle staging view">
      <mat-icon class="material-icons-outlined">edit</mat-icon>
      <div class="label" *ngIf="!isSmallScreenLandScape">Staging</div>
  </button>
  <button
      *ngIf="showAdminPageFF && authService.isAdmin"
      [class.active]="isAdminDisplayed()"
      (click)="selectView.emit(HomeView.ADMIN)"
      mam-firebase-ga="Toggle admin view">
    <mat-icon class="material-icons-outlined">admin_panel_settings</mat-icon>
    <div class="label" *ngIf="!isSmallScreenLandScape">Admin</div>
  </button>
</div>
