import {Inject, Injectable, InjectionToken} from '@angular/core';
import {ReplaySubject} from 'rxjs';

import {ErrorService} from '../error_service/error_service';


/** Suggestions are stored separately for different search modes. */
export enum SuggestionStorageType {
  VOD_SEGMENT_SEARCH = 'mamui_suggestions_history',
  VOD_VIDEO_SEARCH = 'mamui_video_suggestions_history',
  LIVE_VIDEO_SEARCH = 'mamui_live_video_suggestions_history',
}
/**
 * Injection token for using a storage singleton that will contain a lit of
 * recent queries.
 */
export const STORAGE = new InjectionToken<Storage>(
    'Storage of recent search queries', {factory: () => window.localStorage});

// We limit to 1k cached queries, or very roughly < 0.1MB.
const MAX_HISTORY_COUNT = 1000;

/** Service to handle suggestions from previously searched queries. */
@Injectable({providedIn: 'root'})
export class SearchSuggestionsHistory {
  readonly all$ = new ReplaySubject<string[]>(1);

  constructor(
      @Inject(STORAGE) private readonly storage: Storage,
      private readonly errorService: ErrorService) {}

  add(query: string, type: SuggestionStorageType) {
    this.addAll([query], type);
  }

  addAll(queries: string[], type: SuggestionStorageType) {
    let cached = this.getAll(type);
    for (const query of queries) {
      // Remove query from cache if is exist.
      cached = cached.filter(q => q !== query);
      // Add query to cache as the first item.
      cached.unshift(query);
    }

    // Ensure a max cap for cached queries.
    if (cached.length > MAX_HISTORY_COUNT) {
      cached.splice(MAX_HISTORY_COUNT);
    }

    this.storage.setItem(type, JSON.stringify(cached));
    this.all$.next(cached);
  }

  remove(query: string, type: SuggestionStorageType) {
    let queries = this.getAll(type);
    queries = queries.filter(q => q !== query);

    this.storage.setItem(type, JSON.stringify(queries));
    this.all$.next(queries);
  }

  getAll(type: SuggestionStorageType): string[] {
    const stringified = this.storage.getItem(type);

    if (!stringified) return [];
    try {
      const queries = JSON.parse(stringified) as string[];
      return queries;
    } catch {
      this.errorService.handle(
          `Failed to parse queries history: ${stringified}`);
      return [];
    }
  }

  /**
   * Given a prefix and a list of queries, will return all the queries that
   * contain at least one word starting with the prefix (up to a maximum number
   * of `count`).
   */
  filterMatching(prefix: string, queries: string[], count = Number.POSITIVE_INFINITY):
      string[] {
    const matches: string[] = [];

    const prefixes = prefix.trim().toLowerCase().split(' ');
    for (const query of queries) {
      // TODO: Obtain prefix match information from suggest API.
      const words = query.split(' ');

      const hasAnyMatch =
          words.some(word => prefixes.some(prefix => this.match(word, prefix)));

      // If one of the words matches the prefix, add the query.
      if (hasAnyMatch) {
        matches.push(query);
        // Once we have enough matches, stop.
        if (matches.length === count) break;
      }
    }

    return matches;
  }

  private match(word: string, prefix: string) {
    return word.toLowerCase().startsWith(prefix);
  }
}
