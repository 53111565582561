/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiAssetRendition } from './api-asset-rendition';
import { ApiMediaMetadata } from './api-media-metadata';
import { ApiApprovalState } from './api-approval-state';
import { ApiVideoFormat } from './api-video-format';
import { ApiAssetCopyStats } from './api-asset-copy-stats';
import { ApiAssetUserRenditions } from './api-asset-user-renditions';
import { ApiAssetStateDetail } from './api-asset-state-detail';
import { ApiCutdowns } from './api-cutdowns';
import { ApiEventMetadata } from './api-event-metadata';
import { ApiGCSStorageLocation } from './api-gcs-storage-location';
import { ApiSnippet } from './api-snippet';


/**
 * Asset is a file in MAM. It can be of different types. E.g. a Master/Mezanine file is an asset stored in Cloud Storage with asset type VIDEO an editable proxy file is an asset stored in Cloud Storage with asset type VIDEO and has parent (name of the parent)
 */
export interface ApiAsset { 
    approvalState?: ApiApprovalState;
    assetMetadata?: ApiMediaMetadata;
    copyStats?: ApiAssetCopyStats;
    /**
     * Output only. The timestamp that this asset was created
     */
    readonly createTime?: string;
    cutdowns?: ApiCutdowns;
    /**
     * Segment\'s end_time, in ms. Applicable for clips.
     */
    endTime?: string;
    eventMetadata?: ApiEventMetadata;
    /**
     * Timestamp in UTC of when this resource is considered expired. This is *always* provided on output, regardless of what was sent on input.
     */
    expireTime?: string;
    gcsLocation?: ApiGCSStorageLocation;
    /**
     * Labels for this asset. Must pre-exist as a label.
     */
    labels?: Array<string>;
    /**
     * Output only. For live events, the uploaded hi-res file on Cloud Storage. This will always be a `gs:///` formatted URL.
     */
    readonly liveGcsUrl?: string;
    /**
     * Output only. Unique asset name.
     */
    readonly name?: string;
    /**
     * Original asset (E.g. the original file from which a proxy was generated).
     */
    original?: string;
    /**
     * Output only. This asset\'s renditions.
     */
    readonly renditions?: Array<ApiAssetRendition>;
    snippet?: ApiSnippet;
    /**
     * Segment\'s start_time, in ms. Applicable for clips.
     */
    startTime?: string;
    /**
     * Output only. Deprecated: Use state_detail instead. Asset state
     */
    readonly state?: ApiAsset.StateEnum;
    stateDetail?: ApiAssetStateDetail;
    /**
     * Input only. The TTL for this resource.
     */
    ttl?: string;
    /**
     * Output only. Time when the asset was last updated.
     */
    readonly updateTime?: string;
    userRenditions?: ApiAssetUserRenditions;
    videoFormat?: ApiVideoFormat;
}
export namespace ApiAsset {
    export type StateEnum = 'STATE_UNSPECIFIED' | 'STATE_READY' | 'STATE_PROCESSING' | 'STATE_ERROR' | 'STATE_PENDING_MEDIA' | 'STATE_SCHEDULED' | 'STATE_STREAMING' | 'STATE_STREAMING_STOPPED' | 'STATE_STREAMING_ERROR' | 'STATE_DELETED';
    export const StateEnum = {
        STATE_UNSPECIFIED: 'STATE_UNSPECIFIED' as StateEnum,
        STATE_READY: 'STATE_READY' as StateEnum,
        STATE_PROCESSING: 'STATE_PROCESSING' as StateEnum,
        STATE_ERROR: 'STATE_ERROR' as StateEnum,
        STATE_PENDING_MEDIA: 'STATE_PENDING_MEDIA' as StateEnum,
        STATE_SCHEDULED: 'STATE_SCHEDULED' as StateEnum,
        STATE_STREAMING: 'STATE_STREAMING' as StateEnum,
        STATE_STREAMING_STOPPED: 'STATE_STREAMING_STOPPED' as StateEnum,
        STATE_STREAMING_ERROR: 'STATE_STREAMING_ERROR' as StateEnum,
        STATE_DELETED: 'STATE_DELETED' as StateEnum
    };
}


