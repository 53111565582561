<h1 mat-dialog-title>Create New Folder</h1>
<div mat-dialog-content>
  <br>
  <form [formGroup]="form">
    <mat-form-field class="name-field" appearance="outline" floatLabel="always">
      <input
          matInput
          required
          placeholder="Folder name"
          autocomplete="off"
          (keyup.enter)="submit()"
          formControlName="folderName" />
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>
  <button
      mam-firebase-ga="Cancel cloud ingest creation"
      mat-button
      mat-dialog-close>
    Cancel
  </button>

  <button
      mat-button
      class="primary"
      mam-firebase-ga="Confirm cloud ingest creation"
      mat-dialog-close
      [disabled]="!form.valid"
      (click)="submit()">
    Create
  </button>
</div>
