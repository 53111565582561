<div
    class="timeline-block"
    #timelineBlock
    [class.cut-down]="cutDownSegments != null"
    [style.paddingLeft.px]="gap"
    [style.paddingRight.px]="gap">
  <div *ngIf="keywordResults?.length">
    <div *ngFor="let layer of keywordResults">
      <div *ngFor="let segment of layer.segments; trackBy: trackSearchSegment">
        <div
            class="within-search-chip"
            [class.inactive]="getActiveKeyword() && layer.keyword !== getActiveKeyword()"
            [class.small]="durationToWidth(segment.startTime, segment.endTime) < 24"
            [style.left.px]="timeToPosition(segment.startTime)"
            [style.width.px]="durationToWidth(segment.startTime, segment.endTime)"
            (mouseup)="$event.stopPropagation()"
            (mouseenter)="hoveredKeyword = layer.keyword"
            (mouseleave)="hoveredKeyword = undefined"
            (click)="selectQueryChip(segment)"
            matTooltip="{{layer.keyword}}">
        </div>
      </div>
    </div>
  </div>
  <div
     class="query-chip"
     *ngIf="showQueryChip()"
     [attr.title]="userQuery"
     [class.small]="durationToWidth(querySegment!.startTime, querySegment!.endTime) < 24"
     [style.left.px]="timeToPosition(querySegment!.startTime)"
     [style.width.px]="durationToWidth(querySegment!.startTime, querySegment!.endTime)"
     (mouseup)="$event.stopPropagation()"
     (click)="selectQueryChip(querySegment!)">
    <span>{{userQuery}}</span>
  </div>
  <div
      class="main-playhead"
      *ngIf="!live && mainTime != null"
      [style.left.px]="timeToPosition(mainTime)">
  </div>
  <div
      class="seek-playhead"
      *ngIf="duration > 0 && seekTime != null"
      [style.left.px]="timeToPosition(seekTime)"
      >
      <div class="seek-playhead-timer"
      [style.left.px]="getTimerPosition(seekTime)"
      >{{seekTime | durationOrTime:startTimestamp:'HH:mm:ss'}}</div>
  </div>
  <div
      class="marking-background"
      *ngIf="markInTime != null && markOutTime != null"
      [style.left.px]="timeToPosition(markInTime)"
      [style.width.px]="durationToWidth(markInTime, markOutTime)"
      (mousedown)="startBothHandlesDrag($event)">
  </div>
  <div
      class="marking-handle left-handle"
      *ngIf="markInTime != null"
      [style.left.px]="timeToPosition(markInTime)"
      (mousedown)="startLeftHandleDrag($event)"
      (touchstart)="startLeftHandleDrag($event)">
  </div>
  <div
      class="marking-handle right-handle"
      *ngIf="markOutTime != null"
      [style.left.px]="timeToPosition(markOutTime)"
      (mousedown)="startRightHandleDrag($event)"
      (touchstart)="startRightHandleDrag($event)">
  </div>
  <button
      mat-icon-button
      aria-label="Clear clip marking"
      title="Clear"
      class="marking-clear"
      *ngIf="markOutTime != null && !cutDownSegments"
      mam-firebase-ga="Clear clip marking"
      [style.left.px]="timeToPosition(markOutTime)"
      (mouseup)="$event.stopPropagation()"
      (click)="clearMarking()">
    <mat-icon>clear</mat-icon>
  </button>

  <div class="timeline" #timeline [class.live]="live">
    <div
        class="marking-segment"
        *ngIf="markInTime != null && markOutTime != null"
        [style.left.px]="timeToPosition(markInTime) - gap"
        [style.width.px]="durationToWidth(markInTime, markOutTime)">
    </div>
    <!-- Highligh clips in pale red and cut-downs in yellow -->
    <div
        class="cut-down-segment"
        [class.cutdown]="segment.type === 'cutdown'"
        *ngFor="let segment of cutDownSegments;"
        [style.left.px]="timeToPosition(segment.startTime) - gap"
        [style.width.px]="durationToWidth(segment.startTime, segment.endTime)">
    </div>
    <div
        class="buffer-segment"
        *ngFor="let segment of bufferSegments; trackBy: trackSegment"
        [style.left.px]="timeToPosition(segment.startTime) - gap"
        [style.width.px]="durationToWidth(segment.startTime, segment.endTime)">
    </div>
  </div>
</div>
