import {ErrorHandler, NgModule} from '@angular/core';

import {environment} from '../environments/environment';

import {STACKDRIVER_CONFIG, StackdriverReporter} from './stackdriver_reporter';

const stackdriverConfig = {
  key: environment.stackDriverErrorReportingApiKey,
  projectId: environment.projectId,
  service: 'default',
  version: 'v1',
};

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: StackdriverReporter,
    },
    {
      provide: STACKDRIVER_CONFIG,
      // `STACKDRIVER_CONFIG` is optional and only provided if we have a key and
      // we are not in development.
      useValue:
          environment.stackDriverErrorReportingApiKey && environment.compiled ?
          stackdriverConfig :
          undefined,
    },
  ],
})
export class ErrorServiceModule {
}
