import {ApiAssetRendition} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

import {SpriteSheet} from './sprite-sheet';

/** A UI type representing an asset rendtion */
export class AssetRendition implements RequiredRecursively<ApiAssetRendition> {
  spriteSheet = new SpriteSheet(this.apiRendition.spriteSheet);
  url = this.apiRendition.url ?? '';
  version = this.apiRendition.version ?? 'RENDITION_VERSION_UNKNOWN';

  constructor(private apiRendition: ApiAssetRendition = {}) {}
}
