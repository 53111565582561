<ng-container *ngIf="assets">
  <div class="actions-search-bar-container">
    <div class="search-bar-container"
         [class.loading]="progressbar.visible$ | async">
      <mat-form-field appearance="outline"
                      class="search-bar">
        <mat-icon matPrefix>search</mat-icon>
        <input type="text"
               matInput
               placeholder="Search assets"
               aria-label="Search assets"
               autocomplete="off"
               [formControl]="search">
        <button *ngIf="search.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                mam-firebase-ga="Clear asset search query in asset deletion table"
                (click)="search.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-paginator [disabled]="progressbar.visible$ | async"
                     [length]="pagination.totalCount"
                     [pageSize]="pageSize"
                     [pageIndex]="pagination.pageIndex"
                     (page)="changePage($event)"
                     class="main__mat-paginator--border"
                     hidePageSize>
      </mat-paginator>
    </div>
  </div>

  <table mat-table
         [trackBy]="trackByAsset"
         [dataSource]="assets">

    <!-- Name Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        Title
      </th>
      <td class="main-column" mat-cell *matCellDef="let asset" [title]="asset.title">
        <div class="icon-with-text">
          <mam-asset-info-icon-button [asset]="asset"
                                      icon="insert_drive_file"
                                      [showIconForExternalUsers]="true">
          </mam-asset-info-icon-button>
          <span matTooltipClass="mobile-tooltip"
                matTooltip="{{asset.title}}"
                matTooltipPosition="below">
                {{asset.title}}
          </span>
        </div>
      </td>
    </ng-container>

    <!-- Deleted by Column -->
    <ng-container matColumnDef="source">
      <th
        mat-header-cell
        *matHeaderCellDef
        [hidden]="!showAssetsSource">
          <mam-sort-col-btn
            [rows]="assets"
            [key]="'source'"
            colName="Deleted by"
            (mamSortByField)="onSortByField($event)">
            Deleted by
          </mam-sort-col-btn>
      </th>
      <td
        mat-cell
        *matCellDef="let asset"
        [title]="asset.source">
        {{asset.source}}
      </td>
    </ng-container>

    <!-- Last Modified Column -->
    <ng-container matColumnDef="lastModified">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <mam-sort-col-btn
          [rows]="assets"
          [key]="'updateTime'"
          colName="Last Modified"
          (mamSortByField)="onSortByField($event)">
          Last Modified
        </mam-sort-col-btn>
      </th>
      <td mat-cell *matCellDef="let asset">
        <span matTooltipClass="mobile-tooltip"
              matTooltip="{{asset.updateTime | date:'MMM d, y, h:mm a'}}"
              matTooltipPosition="below">
              {{asset.updateTime | date:'MMM d, y, h:mm a'}}
        </span>
      </td>
    </ng-container>

    <!-- Undelete Column -->
    <ng-container matColumnDef="undelete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let asset; let index = index">
        <button mat-flat-button aria-label="Undelete"
                mam-firebase-ga="Undelete the deleted asset"
                *ngIf="!undeletedAssetSet.has(asset.name)"
                [disabled]="progressbar.visible$ | async"
                (click)="openUndeleteAssetDialog(asset)"
                [ngClass]="'asset-table-button'">
                Undelete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let asset; columns: displayedColumns;"
        [class.undeleted]="undeletedAssetSet.has(asset.name)">
    </tr>
  </table>

  <div *ngIf="!assets?.length" class="empty-message">
    No deleted assets
  </div>

</ng-container>
