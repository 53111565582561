import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {delay} from 'rxjs/operators';

import {ApiAnnotatedSegment} from 'api/ias/model/models';
import {AnnotatedSegment, Metadata} from 'models';

import {makeFakeApiError} from '../error_service/api_error';

import {AnnotatedSegmentsApiService, ListFilter} from './annotated_segments_api_service';
import {getAllFakeAssets} from './asset_api_fake_service';
import {makeFakePage, pseudoRandom} from './fake_api_utils';
import {Interface} from './utils_service';

/** Assume that a fake live stream is 1h long. */
const LIVE_STREAMS_DURATION = 3600;

/**
 * Serves annotated segments with Fake data
 */
@Injectable({providedIn: 'root'})
export class FakeAnnotatedSegmentsApiService implements
    Interface<AnnotatedSegmentsApiService> {
  list(
      assetName: string,
      pageSize: number,
      pageToken?: string,
      filters?: ListFilter,
  ) {
    const listAnnotatedSegment = {
      annotatedSegments: [] as AnnotatedSegment[],
      nextPageToken: ''
    };
    if (assetName.includes('no-segments')) {
      return of(listAnnotatedSegment);
    }

    // Set annotatedSegments to AnnotatedSegment[]
    const allItems = buildAnnotatedSegmentsList(assetName).filter(segment => {
      if (filters?.minimumEndOffset != null) {
        return toSeconds(segment.endOffset) > filters.minimumEndOffset;
      }
      if (filters?.maximumStartOffset != null) {
        return toSeconds(segment.startOffset) <= filters.maximumStartOffset;
      }
      if (filters?.minimumEndTime != null) {
        return segment.endTime > filters.minimumEndTime;
      }
      if (filters?.maximumStartTime != null) {
        return segment.startTime <= filters.maximumStartTime;
      }
      return true;
    });
    const {pageOfItems, nextPageToken} =
        makeFakePage(allItems, pageSize, pageToken);
    listAnnotatedSegment.annotatedSegments = pageOfItems;
    listAnnotatedSegment.nextPageToken = nextPageToken || '';

    return of(listAnnotatedSegment).pipe(delay(200));
  }

  create(): Observable<AnnotatedSegment> {
    return throwError(() => 
        makeFakeApiError('Fake segment creation is not implemented'));
  }

  delete(): Observable<unknown> {
    return throwError(() =>
        makeFakeApiError('Fake segment deletion is not implemented'));
  }

  updateMetadata(): Observable<AnnotatedSegment> {
    return throwError(() => makeFakeApiError(
        'Updating metadata is not implemented for fake segments'));
  }
}

/** Generates a list of annotated segments. */
function buildAnnotatedSegmentsList(assetName: string): AnnotatedSegment[] {
  const annotatedSegments: AnnotatedSegment[] = [];
  let time = 0;
  const keywords = ['PASS TO', 'TACKLES', 'INCOMPLETE PASS TO'];

  const asset = getAllFakeAssets().find(asset => asset.name === assetName);
  const duration = asset?.duration || LIVE_STREAMS_DURATION;

  for (let i = 0; time < duration; i++) {
    const customizedAnnotatedSegment = new AnnotatedSegment();
    const seed = `segment-${i}-${duration}-${assetName}`;

    const randomNumber = pseudoRandom(seed);
    const randomNumber2 = pseudoRandom(seed + seed);
    customizedAnnotatedSegment.name = seed;
    customizedAnnotatedSegment.startOffset = `${time}s`;
    customizedAnnotatedSegment.startTime = timeToISOString(time);
    time = time + (randomNumber * 10);
    customizedAnnotatedSegment.endOffset = `${time}s`;
    customizedAnnotatedSegment.endTime = timeToISOString(time);
    const randomKeyword = keywords[Math.floor(randomNumber * keywords.length)];
    const quarter = Math.floor(time * 4 / duration) + 1;

    const player1 = randomPlayer(seed);
    const player2 = randomPlayer(seed + seed);

    const gameLogs =
        [`${player1} ${randomKeyword} ${player2} (${player1}|${player2})`];

    customizedAnnotatedSegment.segmentMetadata = new Metadata({
      jsonMetadata: {
        // Simulate segments with no description 30% of the time.
        'GameLogs': randomNumber < 0.3 ? undefined : gameLogs,
        // Simulate segments with no quarter 60% of the time.
        'Quarter': randomNumber2 < 0.6 ? undefined : quarter,
      },
      createTime: '',
      updateTime: '',
    });
    annotatedSegments.push(customizedAnnotatedSegment);
  }

  return annotatedSegments;
}

/**
 * Returns an ISO date time given a number of seconds since 1/1/70 PST.
 */
function timeToISOString(seconds: number) {
  const epochPst = new Date('1970-01-01T08:00:00Z').getTime();
  return new Date(seconds * 1000 + epochPst).toISOString();
}

/**
 * Helper function for generating a random player for annotated segment values
 */
function randomPlayer(seed: string) {
  const players = [
    '8-L.JACKSON',
    '21-J.PEPPERS',
    '88-H.BRYANT',
    '41-M.FARLEY',
    '23-A.MAULET',
    '89-M.ANDREWS',
  ];
  return players[Math.floor(pseudoRandom(seed) * players.length)];
}

/** Converts a string offset `"123.4s"` to a number `123.4` */
function toSeconds(offset: string) {
  return Number(offset.split('s')[0]);
}

/**
 * Helper function to be used in local development and unit test
 */
export function makeFakeAnnotatedSegment(
    segment: Partial<ApiAnnotatedSegment> = {}): AnnotatedSegment {
  const segmentData = new AnnotatedSegment({
    name: 'fakeAnnotatedSegment-id',
    startOffset: '0',
    endOffset: '100',
    startTime: timeToISOString(0),
    endTime: timeToISOString(100),
    createTime: String(new Date(2020, 3, 25).getTime()),
    updateTime: String(new Date(2020, 3, 25).getTime()),
    ...segment
  });

  return segmentData;
}
