import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSliderModule} from '@angular/material/slider';
import {MatTooltipModule} from '@angular/material/tooltip';

import { LiveAssetService } from 'live/live_asset_service';

import {FirebaseModule} from '../firebase/firebase_module';
import {PipesModule} from '../pipes/pipes_module';
import {SharedModule} from '../shared/shared_module';

import {AssetPreview} from './asset_preview';
import {AssetThumbnail} from './asset_thumbnail';
import {LiveAssetContentPreview} from './live_asset_content_preview';
import {PlayerOverlayControls} from './player_overlay_controls';
import {PlayerWithControls} from './player_with_controls';
import {SeekablePreview} from './seekable_preview';
import {ShakaPlayer} from './shaka_player';
import {TimeFormatSelection} from './time_format_selection';
import {Timeline} from './timeline';
import {VideoPlayer} from './video_player';
import {VolumeButton} from './volume_button';

@NgModule({
  imports: [
    CommonModule,
    FirebaseModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatTooltipModule,
    PipesModule,
    SharedModule,
  ],
  declarations: [
    ShakaPlayer,
    Timeline,
    VideoPlayer,
    VolumeButton,
    AssetPreview,
    LiveAssetContentPreview,
    SeekablePreview,
    AssetThumbnail,
    PlayerWithControls,
    TimeFormatSelection,
    PlayerOverlayControls,
  ],
  exports: [
    AssetPreview,
    SeekablePreview,
    AssetThumbnail,
    PlayerWithControls,
  ],
  providers: [
    LiveAssetService
  ]
})
export class PlayerModule {
}
