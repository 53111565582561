import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PlayerEventService {
  observeQuerySelector (querySelector: string): Observable<Element | null> {
    return new Observable<Element | null>((observer) => {
      const mutation = new MutationObserver(() => {
        observer.next(document.querySelector(querySelector));
      });
      mutation.observe(document, {
        subtree: true,
        childList: true,
      });
    
      const unsubscribe = () => {
        mutation.disconnect();
      };
      return unsubscribe;
    }).pipe(distinctUntilChanged());
  }
}
