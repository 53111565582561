import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {ReplaySubject} from 'rxjs';

import {isErrorResponse} from '../error_service/error_response';
import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {Asset} from '../services/asset_service';
import {SearchType} from '../services/search_input_service';
import {KeywordResult, SearchMode, SearchSegment, SearchService} from '../services/search_service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';

const CHIPS_PER_KEYWORD = 5;

/**
 * Component for Insights panel.
 */
@Component({
  selector: 'mam-insights-panel',
  templateUrl: './insights_panel.ng.html',
  styleUrls: ['./insights_panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsPanel implements AfterViewInit, OnDestroy {
  @Input()
  get asset(): Asset|undefined {
    return this.stateService.currentInsightAsset$.value;
  }
  set asset(asset: Asset|undefined) {
    const shouldRefreshResults = asset?.name !== this.asset?.name;
    this.stateService.currentInsightAsset$.next(asset);

    if (shouldRefreshResults) {
      this.refreshResults();
    }
  }

  @ViewChild('input') readonly input!: ElementRef<HTMLInputElement>;

  /**
   * Set to keep track of all loading queries.
   * These queries will have a special CSS class before becoming either active
   * or inactive.
   */
  loadingKeywords = new Set<string>();

  /** List of keywords that the user has searched. */
  keywords$ = this.stateService.currentKeywords$;

  /** Optional active keyword to highligh its result chips. */
  selectedKeyword$ = this.stateService.currentSelectedKeyword$;

  /** Search segments matching the keyword inputs. */
  keywordResults$ = this.stateService.currentKeywordResults$;

  constructor(
      private readonly searchService: SearchService,
      private readonly snackBar: SnackBarService,
      private readonly cdr: ChangeDetectorRef,
      private readonly stateService: StateService,
      private readonly analyticsService: FirebaseAnalyticsService,
  ) {}

  ngAfterViewInit() {
    this.focusSearchInput();
  }

  focusSearchInput() {
    this.input?.nativeElement.focus();
  }

  onKeyUpHandler(event: KeyboardEvent) {
    if (event.key === "Enter") {
      const target = event.target as HTMLInputElement;
      this.add(target.value);
    }
  }

  add(keyword: string) {
    const trimmed = keyword.trim();
    if (!trimmed) return;

    const keywords = this.keywords$.value;

    // If the keyword has already been searched for, select it.
    if (keywords.includes(trimmed)) {
      this.selectKeyword(trimmed, true);
    } else {
      const newKeywords = [...keywords, trimmed];
      this.keywords$.next(newKeywords);
      this.searchKeyword(trimmed, true);
    }

    this.clearSearchBar();
  }

  searchKeyword(keyword: string, snackbarIfEmptyResults = false) {
    const asset = this.asset;
    if (!asset || asset.isLive) return;

    const gcsUrl = asset.original?.gcsLocationUrl ?? asset.gcsLocationUrl;

    // When in a clip, fetch more results than will be displayed per keyword,
    // since we will discard those not within the clip times.
    // TODO: Make a time-based VCMS search filtering instead to ensure that the
    // results are within clip bounds.
    const clipPageSize = Math.max(25, CHIPS_PER_KEYWORD);
    const pageSize = asset.original ? clipPageSize : CHIPS_PER_KEYWORD;

    const query = `(f: ${gcsUrl}) AND (${keyword})`;
    const searchMode = SearchMode.SEGMENT;
    const searchType = SearchType.VOD;

    this.analyticsService.logEvent('Insights keyword', {
      eventType: AnalyticsEventType.SEARCH,
      term: keyword,
      terms: query,
      searchMode,
    });

    this.loadingKeywords.add(keyword);
    this.searchService
        .search({
          query,
          searchMode,
          searchType,
          pageSize,
          facetSelections: [],
        })
        .subscribe(response => {
          this.cdr.markForCheck();
          this.loadingKeywords.delete(keyword);
          if (isErrorResponse(response)) {
            this.snackBar.error({
              message: `Failed to search for "${keyword}".`,
              details: response.message,
              doNotLog: true,
            });
            return;
          }

          if (response.videoSegments.length === 0) {
            // Disable snackbar messages when navigating to new asset in case
            // of multiple snackbar collision.
            if (snackbarIfEmptyResults) {
              this.snackBar.message(
                  `No results for "${keyword}" in the current video.`);
            }
            return;
          }

          // Constricts segments to the timeframe of the asset if it is a clip.
          const segments =
              response.videoSegments
                  .reduce(
                      (filtered: SearchSegment[], vs: SearchSegment) => {
                        // Check its within the clip start/end time
                        if (vs.startTime < asset.endTime &&
                            vs.endTime > asset.startTime) {
                          const segment = {...vs};
                          // Clip video segment start/end time to be within the
                          // clip
                          segment.startTime =
                              Math.max(segment.startTime, asset.startTime);
                          segment.endTime =
                              Math.min(segment.endTime, asset.endTime);

                          // If the asset is a clip we need to shift the segment
                          // times
                          if (asset.original) {
                            segment.startTime =
                                segment.startTime - asset.startTime;
                            segment.endTime = segment.endTime - asset.startTime;
                          }

                          filtered.push(segment);
                        }
                        return filtered;
                      },
                      [])
                  .slice(0, CHIPS_PER_KEYWORD);

          const previousResults = this.keywordResults$.value;
          const newResult: KeywordResult = {
            keyword,
            segments,
          };
          this.keywordResults$.next([...previousResults, newResult]);
        });
  }

  hasNoResult(keyword: string) {
    return !this.keywordResults$.value.find(it => it.keyword === keyword)
                ?.segments.length;
  }

  isLoading(keyword: string) {
    return this.loadingKeywords.has(keyword);
  }

  selectKeyword(keyword: string, force = false) {
    if (this.hasNoResult(keyword) || this.isLoading(keyword)) return;

    if (this.selectedKeyword$.value === keyword && !force) {
      this.selectedKeyword$.next('');
    } else {
      this.selectedKeyword$.next(keyword);
    }
  }

  remove(keyword: string) {
    this.selectedKeyword$.next('');

    const keywords = this.keywords$.value;

    const newKeywords = keywords.filter(queries => queries !== keyword);
    this.keywords$.next(newKeywords);

    const filteredResults =
        this.keywordResults$.value.filter(layers => layers.keyword !== keyword);
    this.stateService.currentKeywordResults$.next(filteredResults);
  }

  clearSearchBar() {
    this.input.nativeElement.value = '';
  }

  /**
   * Clears all searched keywords and their results.
   */
  clear() {
    this.keywords$.next([]);
    this.clearKeywordResults();
  }

  /** Clears keyword results, preserve the input keywords. */
  clearKeywordResults() {
    this.loadingKeywords.clear();
    this.stateService.currentKeywordResults$.next([]);
  }

  /**
   * Called when current asset is changed. The searchKeyword is recalled for
   * each keyword with the new asset and display updated results to the
   * timeline.
   */
  refreshResults() {
    this.clearKeywordResults();

    if (!this.asset || this.asset.isLive) return;

    const keywords = this.keywords$.value;
    if (!keywords.length) return;

    for (const keyword of keywords) {
      this.searchKeyword(keyword);
    }
  }

  /** Handles on-destroy Subject, used for unsubscribing. */
  private readonly destroyed$ = new ReplaySubject<void>(1);

  ngOnDestroy() {
    // Clear selected keyword when leaving the insights tab.
    this.stateService.currentSelectedKeyword$.next(undefined);

    // Unsubscribes all pending subscriptions.
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
