import {HttpClient} from '@angular/common/http';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, firstValueFrom, fromEvent, Observable, ReplaySubject, Subject} from 'rxjs';
import {filter, map, switchMap, take, takeUntil} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';
import {environment} from 'environments/environment';
import {AudioTrackService} from 'services/audio_track_service';
import {PlaybackAuthorizationService} from 'services/playback-authorization/playback-authorization.service';
import { StateService } from 'services/state_service';

import { ErrorService } from '../error_service/error_service';
import { FeatureFlagService } from '../feature_flag/feature_flag_service';

import {VideoPlayer} from './video_player';

/** How many seconds before the end of the video we allow to seek. */
const DURATION_BACKOFF = 0.1;

/** Wrapper on a shaka-player that shares VideoPlayer API. */
@Component({
    selector: 'mam-shaka-player',
    templateUrl: './shaka_player.ng.html',
    styleUrls: ['./shaka_player.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShakaPlayer extends VideoPlayer implements AfterViewInit, OnDestroy {
    /** Setting this to true will prevent shaka from downloading audio stream. */
    @Input() disableAudio = false;

    /**
     * Default audio track index to select once the manifest is loaded. If
     * unspecified, we default to the third track (index 2) as currently
     * considered the most likely to have content by IAS and its customer.
     */
    @Input() defaultTrackIndex = 2;

    /** Actual shaka player instance */
    instance!: shaka.Player;

    /** List of selectable track variants. */
    tracks?: shaka.extern.Track[];

    /** Indicates if the video player is ready for streaming  the loaded manifest */
    readyForStreaming = false;

    /** Subject used to set the default audio track once both video and audio resources are available */
    private playSubject$ = new Subject<void>();
    private defaultAudioTrackSubject$ = new Subject<shaka.extern.Track[]>();
    private shouldSyncAudioSubject$ = new BehaviorSubject<boolean>(true);

    constructor(
        private readonly cdr: ChangeDetectorRef,
        protected override readonly errorService: ErrorService,
        protected override readonly http: HttpClient,
        private readonly ngZone: NgZone,
        private readonly playbackAuthorizationService: PlaybackAuthorizationService,
        private readonly audioTrackService: AudioTrackService,
        readonly stateService: StateService,
        private readonly featureService: FeatureFlagService
    ) {
        super(http, errorService);

        // Set the default track once the track list is defined
        audioTrackService.audioTracksLoaded$
            .pipe(takeUntil(this.destroyed))
            .subscribe((trackList: shaka.extern.Track[]) => {
                this.tracks = trackList;
                this.selectDefaultTrack();
                this.defaultAudioTrackSubject$.next(this.tracks);
            });

        if (this.audioTrackService.isAudioSyncEnabled()) {
            // Sync the shaka player audio track with the selected audio track
            combineLatest([
                this.defaultAudioTrackSubject$,
                this.playSubject$.pipe(map(() => 'play')),
                this.shouldSyncAudioSubject$
            ])
                .pipe(
                    takeUntil(this.destroyed),
                    filter(([audioTracks, play, shouldSyncAudio]) => !!play && !!audioTracks && shouldSyncAudio),
                    map(([audioTracks]) => audioTracks)
                )
                .subscribe((audioTracks) => {
                    this.shouldSyncAudioSubject$.next(false);
                    const desiredAudioTrack = audioTracks.find((tr) => tr.active);
                    const currentAudioTrack = this.instance.getVariantTracks().find((tr) => tr.active);

                    if (currentAudioTrack?.label !== desiredAudioTrack?.label) {
                        this.selectVariantTrack(desiredAudioTrack);
                    }
                });
        }

        this.patchSeek();
    }

    override ngAfterViewInit() {
        super.ngAfterViewInit();

        // In production, only log errors.
        if (environment.isProd) {
            shaka.log.setLevel(shaka.log.Level.ERROR);
        }

        this.ngZone.runOutsideAngular(() => {
            this.instance = new shaka.Player();
            this.instance.attach(this.video);
            this.instance.addEventListener('error', this.onError);
            this.instance.addEventListener('streaming', this.onStreamingEvent);
            this.configurePlayer();
        });

        this.patchDelayedTick();
    }

    override ngOnDestroy() {
        this.instance.removeEventListener('error', this.onError);
        this.instance.removeEventListener('streaming', this.onStreamingEvent);

        this.instance.destroy();
        super.ngOnDestroy();
    }

    async loadManifest(manifestUri: string, startTime?: number) {
        this.reset();
        try {
            const targetUri = await this.playbackAuthorizationService.getAuthorizedUri(manifestUri);
            await this.instance.load(targetUri, startTime);
            this.onManifestLoaded();
        } catch (error: unknown) {
            // shakaPlayer.load can reject with a ShakaError.
            this.onError(error as shaka.util.ShakaError);
        }
    }

    private onManifestLoaded() {
        if (!this.disableAudio) {
            this.audioTrackService.initAudioTracks(this.instance);
        }

        this.shouldSyncAudioSubject$.next(true);
        this.cdr.markForCheck();
        this.seekCalled$.next();
    }

    private onStreamingEvent() {
        this.readyForStreaming = true;
    }

    /**
     * Selects the 3rd track if it exists, otherwise the first track if it
     * exists.
     */
    selectDefaultTrack() {
        if (!this.tracks?.length) return;
        const trackIndex = this.tracks.length <= this.defaultTrackIndex ? 0 : this.defaultTrackIndex;

        this.selectVariantTrack(this.tracks[trackIndex]);
        // stores the default audio trackon stateService
        this.stateService.currentAssetTrack$.next({ trackIndex: trackIndex, track: this.tracks[trackIndex] });
    }

    changeAudioTrack(track?: shaka.extern.Track) {
        this.shouldSyncAudioSubject$.next(false);
        this.selectVariantTrack(track);
        // stores the current audio track on stateService
        const trackIndex = this.tracks?.findIndex((tr) => tr.active) ?? undefined;
        this.stateService.currentAssetTrack$.next({ trackIndex: trackIndex, track: track });
    }

    selectVariantTrack(track?: shaka.extern.Track) {
        if (!track) {
            return;
        }
        this.instance.selectVariantTrack(track, true, 0);
        this.tracks = this.audioTrackService.sort(this.instance.getVariantTracks());
    }

    getCurrentTrackIndex() {
        return this.tracks?.findIndex((track) => track.active) ?? -1;
    }

    override async play() {
        await super.play();
        this.playSubject$.next();
    }

    trickPlay(rate: number) {
        this.configurePlayer(rate < 0);
        try {
            this.instance.trickPlay(rate);
        } catch {
            // `trickPlay` errors when player is not loaded.
        }
    }

    async unload() {
        return this.instance.unload();
    }

    setPlaybackRate(rate: number) {
        this.video.playbackRate = rate;
    }

    getPlaybackRate() {
        return this.video.playbackRate;
    }

    override async seek(time: number) {
        // Prevent seeking at the very end of the video, which causes the buffer
        // to be emptied. Instead we guarantee a minimum backoff.
        const targetTime = this.duration ? Math.min(time, this.duration - DURATION_BACKOFF) : time;

        await super.seek(targetTime);
        this.seekCalled$.next();
    }

    override pause() {
        // Cancel trick play and reset streaming config when we pause to avoid
        // issues toggling playback during trick play.
        try {
            this.instance.cancelTrickPlay();
        } catch {
            // `cancelTrickPlay` errors when player is not loaded.
        }
        this.configurePlayer();
        super.pause();
    }

    /**
     * Returns the current live edge determined by the end of the seekable range.
     *
     * @see google3/third_party/javascript/shaka_player/ui/presentation_time.js?l=39
     */
    getLiveEdgeTime() {
        assertTruthy(this.isManifestLive(), '`getLiveEdgeTime` called without a live manifest.');
        return this.instance.seekRange().end;
    }

    /** Returns the absolute start of the live stream in ms since epoch. */
    getLiveStartTimestamp(): number {
        assertTruthy(this.isManifestLive(), '`getLiveStartTimestamp` called without a live manifest.');
        const date = this.instance.getPresentationStartTimeAsDate();
        return date.getTime();
    }

    /** Whether the current manifest is `dynamic`. */
    isManifestLive(): boolean {
        return this.instance.isLive();
    }

    /** Sign GCS segment URLs before they are requested. */
    addSegmentSigningHook(signer: (rawUrls: string[]) => Observable<Array<string | null>>) {
        const networkEngine = this.instance.getNetworkingEngine();
        networkEngine.registerRequestFilter(async (type, request) => {
            if (type !== shaka.net.NetworkingEngine.RequestType.SEGMENT) return;

            // Demo streams are public and should not be signed.
            if (request.uris.some((uri) => uri.includes('livestream-demo'))) return;

            // Media CDN playback URLs are already PATH signed.
            if (
                request.uris.some((uri) => {
                    return uri.includes('playback');
                })
            )
                return;

            // Ensure that inputs are in the format gs://uri.
            const rawUrls = request.uris.map((uri) => uri.replace('https://storage.googleapis.com/', 'gs://'));

            const signed$ = signer(rawUrls);

            try {
                const uris = await firstValueFrom(signed$);
                request.uris = uris.map((uri) => uri || '');
            } catch {
                shaka.log.error('Failed to sign segments.');
            }
        });
    }

    private readonly seekCalled$ = new ReplaySubject<void>(1);

    protected override reset() {
        super.reset();
        this.tracks = [];
        this.readyForStreaming = false;
    }

    /**
     * Every time the player seeks (including when loading a new manifest), this
     * verifies that the first received segment includes the time seeked,
     * otherwise moves the playhead to its start. Sometimes, the first segment
     * starts slightly after the requested time, and the player was getting stuck.
     */
    private patchSeek() {
        this.seekCalled$
            .pipe(
                takeUntil(this.destroyed),
                // Wait for the first (non-empty) segment.
                switchMap(() => {
                    return fromEvent(this.video, 'progress').pipe(
                        filter(() => this.video.buffered.length > 0),
                        take(1)
                    );
                })
            )
            .subscribe(() => {
                // Check whether the buffer includes the current time, and if it
                // doesn't, seeks to the start of that first segment. Buffer may have
                // a higher precision than currentTime so we ceil it up to the ms.
                const bufferStart = Math.ceil(this.video.buffered.start(0) * 1000) / 1000;
                if (bufferStart > this.currentTime) {
                    this.seek(bufferStart);
                }
            });
    }

    // Property and not method for scope binding in addEventListener.
    private readonly onError = (error: shaka.util.ShakaError | Error) => {
        // When we quickly switch videos and the current load is interrupted, we
        // don't need to report the error as it is an expected use case.
        // https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html#.Code
        if ((error as shaka.util.ShakaError).code === 7000) {
            shaka.log.warning('[Shaka] LOAD_INTERRUPTED');
        } else {
            this.errorService.handle(error);
        }
    };

    private configurePlayer(backwardPlayback?: boolean) {
        const manifestLoadMaxAttempts = this.featureService.featureOn('use-decreased-manifest-load-attempts') ? 3 : 100;
        this.instance.configure({
            // None of IAS tracks have a language, causing Shaka Player to log a
            // warning about selecting an arbitrary default. Since we select the first
            // track after loading the manifest, this prevents the warning log.
            preferredAudioLanguage: 'none',
            abr: {
                // Allows us to switch variant (audio track) manually.
                enabled: false,
                useNetworkInformation: false
            },
            streaming: {
                // Buffer 20s of content ahead of playhead when moving forward, 1s
                // backward (using 0 sometines causes the player to stop).
                bufferingGoal: backwardPlayback ? 1 : 20,
                // Clear past buffer when it is more than 600s before playhead.
                bufferBehind: 600,
                // Wait until 0.5s of content is buffered before playing forward. Do not
                // wait when playing backward.
                rebufferingGoal: backwardPlayback ? 0 : 0.5,
                // Prevent seeking too close to the end of the video.
                durationBackoff: DURATION_BACKOFF
            },
            manifest: {
                disableAudio: this.disableAudio,
                retryParameters: {
                    // The maximum number of requests before we fail.
                    maxAttempts: manifestLoadMaxAttempts,
                    // The base delay in ms between retries.
                    baseDelay: 1000,
                    // The multiplicative backoff factor between retries.
                    backoffFactor: 1.1,
                    // The fuzz factor to apply to each retry delay.
                    fuzzFactor: 0.5
                },
                dash: {
                    manifestPreprocessor: (mpdXml: XMLDocument) => {
                        this.patchManifest(mpdXml);
                    }
                }
            }
        });
    }

    /**
     * Converts each <Representation> node of an <AdaptationSet> into its own
     * <AdaptationSet> with a single <Representation> of a different fake language
     * since in the case of IAS, each audio track is different in content and
     * should be treated as independent. Without this, the multiple adaptations
     * are considered duplicates and only one is selectable in the UI
     */
    private patchManifest(mpdXml: XMLDocument) {
        mpdXml.querySelectorAll('Period').forEach((period) => {
            const adaptationSet = period.querySelector('AdaptationSet[mimeType^="audio"]');
            if (!adaptationSet) return;
            adaptationSet.querySelectorAll('Representation').forEach((representation, index) => {
                const adaptationSetClone = adaptationSet.cloneNode() as Element;
                adaptationSetClone.setAttribute('lang', `${index}`);
                adaptationSetClone.appendChild(representation);
                period.appendChild(adaptationSetClone);
            });
            adaptationSet.remove();
        });
    }

    /**
     * Patches shaka player's `shaka.util.DelayedTick` utility class to execute
     * outside of Angular which reduces the amount of change detection cycles.
     *
     * For `shaka.util.DelayedTick` details see
     * https://shaka-player-demo.appspot.com/docs/api/lib_util_delayed_tick.js.html
     */
    private patchDelayedTick() {
        if (shaka.util.Timer.zoneAware) return;

        const ngZone = this.ngZone;
        const original = shaka.util.Timer.prototype.tickAfter;
        shaka.util.Timer.prototype.tickAfter = function (...args) {
            return ngZone.runOutsideAngular(() => original.apply(this, args));
        };
        shaka.util.Timer.zoneAware = true;
    }
}
