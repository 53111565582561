<div class="header-container">
  <h1 mat-dialog-title class="header-title">Add clip to clipbins</h1>
  <button
      mat-flat-button
      [disabled]="!input.value.trim() || !selectedBins.length"
      matTooltip="Add to {{selectedBins.length}} clip bin{{selectedBins.length > 1 ? 's' : ''}}"
      mam-firebase-ga="Confirm add clip dialog"
      class="header-add-button"
      (click)="createClips(input.value)">
      ADD
  </button>
  <button
    mam-firebase-ga="Cancel add clip dialog"
    mat-icon-button
    mat-dialog-close
    class="header-close-button">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content
     class="dialog-new-bins__content--spacing"
     cdkTrapFocus
     [cdkTrapFocusAutoCapture]="true">
    <label>New clip name</label>
    <mat-form-field
      class="dialog-new-bins__form--spacing"
    appearance="outline" subscriptSizing="dynamic">
    <input
      class="dialog-new-bins__input--spacing"
        matInput
        cdkFocusInitial
        #input
        autocomplete="off"
        aria-label="Clip name"
        [value]="defaultClipTitle"
        (keydown)="onKeyboardEventHandler($event, input.value)"
        placeHolder="Enter clip name">
  </mat-form-field>

  <mam-clip-bin-search-panel
      [defaultBinNameSelected]="defaultBinNameSelected"
      (selectedBinsChanged)="selectedBins = $event"
      [isMultipleOptions]="true">
  </mam-clip-bin-search-panel>
</div>

<div mat-dialog-actions class="footer-actions">
<button
    mam-firebase-ga="Cancel add clip dialog"
    mat-button
    mat-dialog-close
    class="cancel">
  Cancel
</button>

<button
    mat-button
    [disabled]="!input.value.trim() || !selectedBins.length"
    matTooltip="Add to {{selectedBins.length}} clip bin{{selectedBins.length > 1 ? 's' : ''}}"
    mam-firebase-ga="Confirm add clip dialog"
    class="primary"
    (click)="createClips(input.value)"
    #primary>
  Add
</button>
</div>
