import { Injectable } from '@angular/core';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { delay, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';

import { PlayerFullscreenService } from '../player_fullscreen_service';

import { PlayerEventService } from './player_event_service';

const DETAILS_PERSIST_DELAY = 2000;

@Injectable()
export class PlayerDetailsService {
  // should the regular player controls be shown (i.e. timeline) + the details toolbar in fullscreen
  public isControlsShown$: Observable<boolean>;
  // should the top title in fullscreen be shown
  public isAssetTitleShown$: Observable<boolean>;
  // should additional player controls be shown (i.e. skip forward)
  public isAdditionalPlayerControlsShown$: Observable<boolean>;

  // enable details temporarily
  private pulseDetails$ = new Subject<void>();
  // set details to be enabled or disabled
  private setDetails$ = new Subject<boolean>();

  constructor(
    private readonly playerFullscreenService: PlayerFullscreenService,
    private readonly playerEventService: PlayerEventService,
    ) {
    this.isAdditionalPlayerControlsShown$ = merge(
      this.pulseDetails$.pipe(map((): 'pulse' => 'pulse')),
      this.setDetails$.pipe(map((enabled): 'enabled'|'disabled' => enabled ? 'enabled' : 'disabled')),
    ).pipe(
      switchMap(action => {
        switch(action) {
          case 'enabled':
            return of(true);
          case 'disabled':
            return of(false);
          case 'pulse':
            return merge(of(true), of(false).pipe(delay(DETAILS_PERSIST_DELAY)));
        }
      }),
      startWith(false),
    );

    this.isControlsShown$ = combineLatest([
      this.isAdditionalPlayerControlsShown$,
      this.playerFullscreenService.isFullScreen$]).pipe(
        map(([isAdditionalPlayerControlsShown, isFullScreen]) => !isFullScreen || isAdditionalPlayerControlsShown)
      );
    
    this.isAssetTitleShown$ = combineLatest([
      this.isAdditionalPlayerControlsShown$,
      this.playerFullscreenService.isFullScreen$]).pipe(
        map(([isAdditionalPlayerControlsShown, isFullScreen]) => isFullScreen && isAdditionalPlayerControlsShown)
      );

    this.observeFullScreen();
    this.observeOverlays();
  }

  private observeFullScreen() {
    this.playerFullscreenService.isFullScreen$.subscribe(isFullScreen => {
      if (isFullScreen) {
        this.temporarilyEnableDetails();
      }
    });
  }

  /*
    Technically this is tracking ANY opened overlay, not necessarily an overlay that was opened by the specific player this service is injected into
    It is difficult to track which player the overlay belongs to as they are thrown into a portal far outside of the player dom element
    A full fledged solution would require the player to wait for an overlay to be opened from one of the player controls, and also
    wait for it to be closed, and appropriately communicate with this service when that happens
    In practice, there is only ever one player_with_controls rendered at a time
  */
  private observeOverlays() {
    this.playerEventService.observeQuerySelector('.cdk-overlay-pane').pipe(
      withLatestFrom(this.playerFullscreenService.isFullScreen$
    ))
    .subscribe(([element, isFullScreen]) => {
      if (!isFullScreen) {
        return;
      }

      if (element) {
        this.setDetails(true);
      } else {
        this.temporarilyEnableDetails();
      }
    });
  }

  public temporarilyEnableDetails() {
    this.pulseDetails$.next();
  }

  public setDetails(enabled: boolean) {
    this.setDetails$.next(enabled);
  }
}