import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {BehaviorSubject, EMPTY, from, Observable, of, ReplaySubject} from 'rxjs';
import {concatMap, filter, finalize, map, reduce, switchMap, takeUntil} from 'rxjs/operators';

import {CloudTransferOperation, Folder} from 'models';

import {isErrorResponse} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {CloudIngestService} from '../services/cloud_ingest_service';
import {DialogService} from '../services/dialog_service';
import {ApiStorageTransferInfoJobStateEnum, FolderTypeEnum, ApiCloudTransferOperationStateEnum as OperationStateEnum} from '../services/ias_types';
import {ProgressbarService} from '../services/progressbar_service';
import {SnackBarService} from '../services/snackbar_service';
import {UtilsService} from '../services/utils_service';

/** Media breakpoints affecting the visibility of columns. */
export enum BreakPoint {
  COMPACT = '(max-width: 1280px)',
  HIDE_INTELLIGENCE = '(max-width: 1080px)',
  HIDE_SIZE_AND_MODIFIED = '(max-width: 780px)',
}

const ALL_COLUMNS = [
  'folderName',
  'sourceBucket',
  'prefixPath',
  'schedule',
  'lastRunTime',
  'status',
  'actions',
  'expand',
] as const;

const RUN_SIZE = 5;

/** Used for details panel animation, should be above its total height. */
const DETAILS_MAX_BOUNDARY = 600;

/** Folder and its corresponding runs are presented as a map */
interface FolderRunsValueType {
  operations: CloudTransferOperation[];
  folder: Folder;
}
type FolderRuns = Map<string, FolderRunsValueType>;

/**
 * Cloud ingest table
 */
@Component({
  selector: 'mam-cloud-ingest-table',
  templateUrl: './cloud_ingest_table.ng.html',
  styleUrls: ['./cloud_ingest_table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger(
      'expandDetails',
      [transition(
          ':enter',
          [
            style({opacity: 0, maxHeight: 0}),
            animate(
                '150ms',
                style({opacity: 1, maxHeight: `${DETAILS_MAX_BOUNDARY}px`})),
          ])])]
})
export class CloudIngestTable implements OnDestroy {
  displayedColumns = ALL_COLUMNS;

  readonly FolderTypeEnum = FolderTypeEnum;

  /** A map pairs with folderId and its operation */
  readonly folderRunMap$: Observable<FolderRuns>;

  /** Emits any time a job is enabled, disabled, or deleted. */
  folderUpdated$ = new BehaviorSubject<void>(undefined);

  /** Expose status enumeration to template. */
  readonly OperationStateEnum = OperationStateEnum;

  constructor(
      private readonly cloudIngestService: CloudIngestService,
      private readonly dialogService: DialogService,
      private readonly snackBar: SnackBarService,
      private readonly utils: UtilsService,
      private readonly errorService: ErrorService,
      readonly progressbar: ProgressbarService,
  ) {
    this.progressbar.show();

    this.folderRunMap$ = this.folderUpdated$.pipe(
        takeUntil(this.destroyed$),
        switchMap(() => this.generateFolderRunMap()));
  }

  /**
   * Mapping the folder and it's operations. Do not record in the map if the
   * folder doesn't have operations. Hiding the progressbar when the mapping is
   * done.
   */
  generateFolderRunMap() {
    this.progressbar.show();

    return this.cloudIngestService.listFolders().pipe(
        this.errorService.catchError(), switchMap(res => {
          if (isErrorResponse(res)) {
            this.snackBar.error(res.message);
            return of(new Map<string, FolderRunsValueType>());
          }

          if (!res || !res.folders) {
            return of(new Map<string, FolderRunsValueType>());
          }

          return from(res.folders)
              .pipe(
                  filter(
                      folder => folder.storageTransferInfo.jobState !==
                          ApiStorageTransferInfoJobStateEnum
                              .JOB_STATE_UNSPECIFIED),
                  // Regarding the input, get its list of operation and maintain
                  // the order.
                  concatMap(folder => {
                    return this.cloudIngestService
                        .listTransferOperations(folder.name, RUN_SIZE)
                        .pipe(map(
                            operations => ({
                              folder,
                              operations: operations?.transferOperations || []
                            })));
                  }),
                  reduce(
                      (folderRunMap, {folder, operations}) => {
                        return folderRunMap.set(
                            folder.folderId, {folder, operations});
                      },
                      new Map<string, FolderRunsValueType>()),
              );
        }),
        finalize(() => {
          this.progressbar.hide();
        }),
        takeUntil(this.destroyed$));
  }

  getDataSource(folderRunMap: FolderRuns): FolderRunsValueType[] {
    return [...folderRunMap.values()];
  }

  onDeleteClicked(folder: Folder) {
    const confirmed$ = this.dialogService.showConfirmation({
      title: 'Delete Cloud Ingest Job',
      question: 'Do you want to delete this cloud ingest job?',
      primaryButtonText: 'Delete',
    });

    confirmed$
        .pipe(switchMap(res => {
          if (!res) return EMPTY;
          return this.cloudIngestService.deleteTransferJob(folder.name);
        }))
        .subscribe(folder => {
          if (!folder) {
            this.snackBar.error('Delete the asset failed.');
            return;
          }
          this.snackBar.message('The asset has been deleted.');
          this.folderUpdated$.next();
        });
  }

  onEnableClicked(folder: Folder) {
    const confirmed$ = this.dialogService.showConfirmation({
      title: 'Enable Cloud Ingest Job',
      question: 'Do you want to enable this cloud ingest job?',
      primaryButtonText: 'Enable',
    });
    confirmed$
        .pipe(switchMap(res => {
          if (!res) return EMPTY;
          return this.cloudIngestService.enableTransferJob(folder.name);
        }))
        .subscribe(folder => {
          if (!folder) {
            this.snackBar.error('Enable the asset failed.');
            return;
          }
          this.snackBar.message('The asset has been enabled.');
          this.folderUpdated$.next();
        });
  }

  onDisableClicked(folder: Folder) {
    const confirmed$ = this.dialogService.showConfirmation({
      title: 'Disable Cloud Ingest Job',
      question: 'Do you want to disable this cloud ingest job?',
      primaryButtonText: 'Disable',
    });

    confirmed$
        .pipe(switchMap(res => {
          if (!res) return EMPTY;
          return this.cloudIngestService.disableTransferJob(folder.name);
        }))
        .subscribe(folder => {
          if (!folder) {
            this.snackBar.error('Disabled the asset failed.');
            return;
          }
          this.snackBar.message('The asset has been disabled.');
          this.folderUpdated$.next();
        });
  }

  getFolderName(name: string) {
    return this.utils.lastPart(name);
  }

  getBucket(name: string) {
    return this.getFolderName(name).split('-')[0];
  }

  toggleRowExpanded(row: FolderRunsValueType) {
    if (this.expandedRows.has(row.folder.name)) {
      this.expandedRows.delete(row.folder.name);
    } else {
      this.expandedRows.add(row.folder.name);
    }
  }

  isRowExpanded(row: FolderRunsValueType) {
    return this.expandedRows.has(row.folder.name);
  }

  isRowEnabled(row: FolderRunsValueType) {
    return row.folder.storageTransferInfo?.jobState ===
        ApiStorageTransferInfoJobStateEnum.ENABLED;
  }

  trackByRow(index: number, row: FolderRunsValueType) {
    return row.folder.folderId;
  }

  /** List of rows that have been expanded to reveal their details. */
  private readonly expandedRows = new Set<string>();

  private readonly destroyed$ = new ReplaySubject<void>(1);

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
