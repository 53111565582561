import {NgModule} from '@angular/core';

import {ClipBinsFoldersService} from './clip_bins_folders.service';
import {FirebaseAnalyticsDirective} from './firebase_analytics_directive';
import {FirebaseResolver} from './firebase_resolver';
import {PageContextResolver} from './page_context_resolver';

/** Firebase functionality factories. */
@NgModule({
  declarations: [
    FirebaseAnalyticsDirective,
  ],
  exports: [
    FirebaseAnalyticsDirective,
  ],
  providers: [
    FirebaseResolver,
    PageContextResolver,
    ClipBinsFoldersService
  ],
})
export class FirebaseModule {
}
