<div #wrapper *ngIf="{
  asset: asset$ | async,
  fileAndState: fileAndState$ | async,
  hideStatus: hideStatus$ | async
} as tplState">
  <ng-container *ngIf="tplState.asset as asset">
    <div class="container"
        aria-label="Asset state"
        [style.justify-content]="displayLocationText ? 'space-between' : 'center'"
        (click)="$event.stopPropagation()">
      <!-- Optional full text -->
      <div class="location-text" *ngIf="displayLocationText">
        <div
            class="text-label"
            [attr.title]="formatFileLocation(tplState.fileAndState?.state, asset)">
          {{formatFileLocation(tplState.fileAndState?.state, asset)}}
        </div>
        <div class="ttl"
            *ngIf="ttl"
            [class.expiring]="isExpiring">
          Expiring on {{ttl | tzdate: 'MMM d, y, h:mm a'}}
        </div>
      </div>

      <!-- Button / icon -->
      <ng-container
          *ngIf="!tplState.hideStatus &&
              tplState.fileAndState?.state as fileState; else pendingStatusIcon;">
        <button
            *ngIf="getImportType(asset, fileState); else locationStatus;"
            mat-icon-button
            class="import-marker-button"
            (click)="import(asset, fileState); $event.stopPropagation();"
            [matTooltip]="getImportTooltip(fileState)"
            aria-label="Import to Premiere"
            mam-firebase-ga="Import to Premiere"
            [mam-firebase-resource]="asset.name">
          <mat-icon class="material-icons-outlined">save_alt</mat-icon>

          <mat-icon
              class="material-icons-outlined done-icon"
              *ngIf="isInPremiere">
            check_circle
          </mat-icon>
        </button>

        <ng-template #locationStatus>
          <div class="transfer-state transfer-state-{{fileState}}" [ngSwitch]="fileState">
            <ng-container *ngSwitchCase="FileState.FILE_CLOUD_ONLY">
              <!-- Download to current site. -->
              <button
                  mat-icon-button
                  [matTooltip]="(tooltip$ | async) || ''"
                  (click)="$event.stopPropagation(); download()"
                  class="gmat-button"
                  [mam-firebase-ga]="'Download asset to on-prem from ' + PAGE_CONTEXT_TOKEN"
                  [mam-firebase-resource]="asset.name">
                <mat-icon class="material-icons-outlined">
                  cloud_download
                </mat-icon>
              </button>
            </ng-container>

            <mat-spinner
                *ngSwitchCase="FileState.FILE_DOWNLOADING"
                diameter="20"
                [matTooltip]="formatFileLocation(fileState, asset)">
            </mat-spinner>

            <mat-spinner
                *ngSwitchCase="FileState.FILE_UPLOADING"
                diameter="20"
                [matTooltip]="formatFileLocation(fileState, asset)">
            </mat-spinner>

            <mat-spinner
                *ngSwitchCase="FileState.DELETING"
                diameter="20"
                [matTooltip]="formatFileLocation(fileState, asset)">
            </mat-spinner>

            <img
                *ngSwitchCase="FileState.SUBCLIP_CLOUD_AND_ONPREM"
                [matTooltip]="formatFileLocation(fileState, asset)"
                [alt]="formatFileLocation(fileState, asset)"
                src="images/asset_incloud_and_onprem.svg">

            <mat-icon
                *ngSwitchCase="FileState.SUBCLIP_ONPREM_ONLY"
                [matTooltip]="formatFileLocation(fileState, asset)"
                class="material-icons-outlined">
              dns
            </mat-icon>

            <img
                *ngSwitchCase="FileState.FILE_CLOUD_AND_ONPREM"
                [matTooltip]="formatFileLocation(fileState, asset)"
                [alt]="formatFileLocation(fileState, asset)"
                src="images/asset_incloud_and_onprem.svg">

            <mat-icon
                *ngSwitchCase="FileState.FILE_ONPREM_ONLY"
                [matTooltip]="formatFileLocation(fileState, asset)"
                class="material-icons-outlined">
              dns
            </mat-icon>

            <button
                *ngSwitchCase="FileState.FAILED_PFR_DOWNLOAD"
                mat-icon-button
                (click)="$event.stopPropagation(); download()"
                class="gmat-button"
                mam-firebase-ga="Retry PFR download"
                [mam-firebase-resource]="asset.name"
                matTooltip="Download failed">
              <mat-icon class="material-icons-outlined">error</mat-icon>
            </button>

            <button
                *ngSwitchCase="FileState.FAILED_PFR_RESTORE"
                mat-icon-button
                (click)="$event.stopPropagation(); download()"
                class="gmat-button"
                mam-firebase-ga="Retry PFR restore"
                [mam-firebase-resource]="asset.name"
                [matTooltip]="formatFileLocation(fileState, asset)">
              <mat-icon class="material-icons-outlined">error</mat-icon>
            </button>

            <mat-icon
                *ngSwitchCase="FileState.FILE_UNKNOWN"
                class="material-icons-outlined"
                [matTooltip]="formatFileLocation(fileState, asset)">
              not_listed_location
            </mat-icon>

            <ng-container *ngSwitchDefault>
              <!-- Download retry is only allowed for VoDs. -->
              <ng-container *ngIf="!asset.isLive; else otherLiveError">
                <button
                    [matTooltip]="getErrorMessage(fileState)"
                    [attr.aria-label]="getErrorMessage(fileState)"
                    mat-icon-button
                    (click)="$event.stopPropagation(); download()"
                    class="gmat-button"
                    mam-firebase-ga="Retry download"
                    [mam-firebase-resource]="asset.name">
                  <mat-icon class="material-icons-outlined">error</mat-icon>
                </button>
              </ng-container>
              <ng-template #otherLiveError>
                <mat-icon
                    [matTooltip]="getErrorMessage(fileState)"
                    class="material-icons-outlined">
                  error
                </mat-icon>
              </ng-template>
            </ng-container>
          </div>
        </ng-template>

        <mam-onprem-operation
            *ngIf="displayOnpremOperation(fileState)"
            [asset]="asset"
            [checkRawSource]="checkRawSource">
        </mam-onprem-operation>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #pendingStatusIcon>
  <mat-icon class="material-icons-outlined pending">pending</mat-icon>
</ng-template>
