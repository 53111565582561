<ng-container *ngIf="numOfBuckets > 0">
  <button
          mat-button
          class="facet-group-button"
          [matMenuTriggerFor]="menu"
          [class.active]="menuStatus === MenuStatus.OPENED"
          [class.selected]="getSelectedBucketsNum() > 0"
          (menuOpened)="onMenuOpen()"
          (menuClosed)="onMenuClose()"
          mam-firebase-ga="Open search facet menu"
          [mam-firebase-resource]="facetGroup.displayedName">
    <span class="facet-group-button__title">{{ getDisplayText() }}</span>
    <mat-icon class="arrow main__icon-arrow--size">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="menu-offset-down facet-group-menu">
    <div *ngIf="enableFilteringFF" class="filter-options-container">
      <mat-icon matPrefix class="filter-options-icon">search</mat-icon>
      <input
          matInput
          type="text"
          class="filter-options-input"
          placeholder="Filter options"
          (click)="$event.stopPropagation()"
          [formControl]="filterOptionsControl">
    </div>

    <ng-container *ngFor="let buckets of [prioritizedBuckets, alphabeticalBuckets]">
      <hr
          *ngIf="buckets !== prioritizedBuckets && prioritizedBuckets.length && alphabeticalBuckets.length && enableFavoritesFF"
          class="options-separator">

      <ng-container *ngFor="let facetBucket of buckets">
        <div class="option-container">

          <mat-checkbox
              #matCheckbox
              [aria-label]="facetBucket.value"
              [value]="facetBucket | json"
              [checked]="facetBucket.isSelected"
              [matTooltip]="facetBucket.value"
              [matTooltipDisabled]="tooltipDisabled(facetBucket)"
              color="primary"
              (click)="lastClickedBucket=facetBucket; $event.stopPropagation()"
              (change)="onChange($event)"
              mam-firebase-ga="Toggle search facet bucket"
              class="search-facet__checkbox--spacing {{enableFavoritesFF ? '' : 'search-facet-checkbox-without-favorites'}}"
              [mam-firebase-resource]="facetBucket.value">
              {{facetBucket.value}}
          </mat-checkbox>

          <mat-icon
              *ngIf="enableFavoritesFF"
              class="material-icons-outlined option-icon {{facetBucket.isFavorite ? 'option-favorite-icon' : 'option-non-favorite-icon'}} {{lastClickedBucket===facetBucket ? 'last-clicked' : ''}}"
              (click)="facetBucket.isFavorite = !facetBucket.isFavorite; $event.stopPropagation();">
            {{facetBucket.isFavorite ? 'star' : 'grade'}}
          </mat-icon>

        </div>
      </ng-container>

    </ng-container>
  </mat-menu>
</ng-container>
