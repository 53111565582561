import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';

import { PlayerFullscreenService } from 'services/player_fullscreen_service';

/**
 * Root component for the MAM Angular app.
 */
@Component({
  selector: 'mam-app',
  templateUrl: './app.ng.html',
  styleUrls: ['./app.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class App {
  @HostBinding('class.is-full-screen') isFullScreen = false;

  constructor(
    iconRegistry: MatIconRegistry, 
    private readonly playerFullscreenService: PlayerFullscreenService
    ) {
    iconRegistry.setDefaultFontSetClass('material-icons-extended');
    // destroy not needed because this is the entire app
    this.playerFullscreenService.isFullScreen$.subscribe(isFullScreen => {
      this.isFullScreen = isFullScreen;
    });
  }
}
