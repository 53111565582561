/**
 * Cloud Pub/Sub API
 * Provides reliable, many-to-many, asynchronous messaging between applications. 
 *
 * The version of the OpenAPI document: v1beta2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AcknowledgeRequest } from '../model/acknowledge-request';
// @ts-ignore
import { ListSubscriptionsResponse } from '../model/list-subscriptions-response';
// @ts-ignore
import { ListTopicSubscriptionsResponse } from '../model/list-topic-subscriptions-response';
// @ts-ignore
import { ListTopicsResponse } from '../model/list-topics-response';
// @ts-ignore
import { ModifyAckDeadlineRequest } from '../model/modify-ack-deadline-request';
// @ts-ignore
import { ModifyPushConfigRequest } from '../model/modify-push-config-request';
// @ts-ignore
import { Policy } from '../model/policy';
// @ts-ignore
import { PublishRequest } from '../model/publish-request';
// @ts-ignore
import { PublishResponse } from '../model/publish-response';
// @ts-ignore
import { PullRequest } from '../model/pull-request';
// @ts-ignore
import { PullResponse } from '../model/pull-response';
// @ts-ignore
import { SetIamPolicyRequest } from '../model/set-iam-policy-request';
// @ts-ignore
import { Subscription } from '../model/subscription';
// @ts-ignore
import { TestIamPermissionsRequest } from '../model/test-iam-permissions-request';
// @ts-ignore
import { TestIamPermissionsResponse } from '../model/test-iam-permissions-response';
// @ts-ignore
import { Topic } from '../model/topic';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface PubsubProjectsSubscriptionsAcknowledgeRequestParams {
    /** The subscription whose message is being acknowledged. */
    subscription: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    body?: AcknowledgeRequest;
}

export interface PubsubProjectsSubscriptionsCreateRequestParams {
    /** The name of the subscription. It must have the format &#x60;\&quot;projects/{project}/subscriptions/{subscription}\&quot;&#x60;. &#x60;{subscription}&#x60; must start with a letter, and contain only letters (&#x60;[A-Za-z]&#x60;), numbers (&#x60;[0-9]&#x60;), dashes (&#x60;-&#x60;), underscores (&#x60;_&#x60;), periods (&#x60;.&#x60;), tildes (&#x60;~&#x60;), plus (&#x60;+&#x60;) or percent signs (&#x60;%&#x60;). It must be between 3 and 255 characters in length, and it must not start with &#x60;\&quot;goog\&quot;&#x60;. */
    name: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    body?: Subscription;
}

export interface PubsubProjectsSubscriptionsDeleteRequestParams {
    /** The subscription to delete. */
    subscription: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
}

export interface PubsubProjectsSubscriptionsGetRequestParams {
    /** The name of the subscription to get. */
    subscription: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
}

export interface PubsubProjectsSubscriptionsGetIamPolicyRequestParams {
    /** REQUIRED: The resource for which the policy is being requested. See [Resource names](https://cloud.google.com/apis/design/resource_names) for the appropriate value for this field. */
    resource: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    /** Optional. The maximum policy version that will be used to format the policy. Valid values are 0, 1, and 3. Requests specifying an invalid value will be rejected. Requests for policies with any conditional role bindings must specify version 3. Policies with no conditional role bindings may specify any valid value or leave the field unset. The policy in the response might use the policy version that you specified, or it might use a lower policy version. For example, if you specify version 3, but the policy has no conditional role bindings, the response uses version 1. To learn which resources support conditions in their IAM policies, see the [IAM documentation](https://cloud.google.com/iam/help/conditions/resource-policies). */
    optionsRequestedPolicyVersion?: number;
}

export interface PubsubProjectsSubscriptionsListRequestParams {
    /** The name of the cloud project that subscriptions belong to. */
    project: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    /** Maximum number of subscriptions to return. */
    pageSize?: number;
    /** The value returned by the last &#x60;ListSubscriptionsResponse&#x60;; indicates that this is a continuation of a prior &#x60;ListSubscriptions&#x60; call, and that the system should return the next page of data. */
    pageToken?: string;
}

export interface PubsubProjectsSubscriptionsModifyAckDeadlineRequestParams {
    /** The name of the subscription. */
    subscription: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    body?: ModifyAckDeadlineRequest;
}

export interface PubsubProjectsSubscriptionsModifyPushConfigRequestParams {
    /** The name of the subscription. */
    subscription: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    body?: ModifyPushConfigRequest;
}

export interface PubsubProjectsSubscriptionsPullRequestParams {
    /** The subscription from which messages should be pulled. */
    subscription: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    body?: PullRequest;
}

export interface PubsubProjectsSubscriptionsSetIamPolicyRequestParams {
    /** REQUIRED: The resource for which the policy is being specified. See [Resource names](https://cloud.google.com/apis/design/resource_names) for the appropriate value for this field. */
    resource: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    body?: SetIamPolicyRequest;
}

export interface PubsubProjectsSubscriptionsTestIamPermissionsRequestParams {
    /** REQUIRED: The resource for which the policy detail is being requested. See [Resource names](https://cloud.google.com/apis/design/resource_names) for the appropriate value for this field. */
    resource: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    body?: TestIamPermissionsRequest;
}

export interface PubsubProjectsTopicsCreateRequestParams {
    /** The name of the topic. It must have the format &#x60;\&quot;projects/{project}/topics/{topic}\&quot;&#x60;. &#x60;{topic}&#x60; must start with a letter, and contain only letters (&#x60;[A-Za-z]&#x60;), numbers (&#x60;[0-9]&#x60;), dashes (&#x60;-&#x60;), underscores (&#x60;_&#x60;), periods (&#x60;.&#x60;), tildes (&#x60;~&#x60;), plus (&#x60;+&#x60;) or percent signs (&#x60;%&#x60;). It must be between 3 and 255 characters in length, and it must not start with &#x60;\&quot;goog\&quot;&#x60;. */
    name: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    body?: Topic;
}

export interface PubsubProjectsTopicsDeleteRequestParams {
    /** Name of the topic to delete. */
    topic: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
}

export interface PubsubProjectsTopicsGetRequestParams {
    /** The name of the topic to get. */
    topic: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
}

export interface PubsubProjectsTopicsGetIamPolicyRequestParams {
    /** REQUIRED: The resource for which the policy is being requested. See [Resource names](https://cloud.google.com/apis/design/resource_names) for the appropriate value for this field. */
    resource: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    /** Optional. The maximum policy version that will be used to format the policy. Valid values are 0, 1, and 3. Requests specifying an invalid value will be rejected. Requests for policies with any conditional role bindings must specify version 3. Policies with no conditional role bindings may specify any valid value or leave the field unset. The policy in the response might use the policy version that you specified, or it might use a lower policy version. For example, if you specify version 3, but the policy has no conditional role bindings, the response uses version 1. To learn which resources support conditions in their IAM policies, see the [IAM documentation](https://cloud.google.com/iam/help/conditions/resource-policies). */
    optionsRequestedPolicyVersion?: number;
}

export interface PubsubProjectsTopicsListRequestParams {
    /** The name of the cloud project that topics belong to. */
    project: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    /** Maximum number of topics to return. */
    pageSize?: number;
    /** The value returned by the last &#x60;ListTopicsResponse&#x60;; indicates that this is a continuation of a prior &#x60;ListTopics&#x60; call, and that the system should return the next page of data. */
    pageToken?: string;
}

export interface PubsubProjectsTopicsPublishRequestParams {
    /** The messages in the request will be published on this topic. */
    topic: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    body?: PublishRequest;
}

export interface PubsubProjectsTopicsSetIamPolicyRequestParams {
    /** REQUIRED: The resource for which the policy is being specified. See [Resource names](https://cloud.google.com/apis/design/resource_names) for the appropriate value for this field. */
    resource: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    body?: SetIamPolicyRequest;
}

export interface PubsubProjectsTopicsSubscriptionsListRequestParams {
    /** The name of the topic that subscriptions are attached to. */
    topic: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    /** Maximum number of subscription names to return. */
    pageSize?: number;
    /** The value returned by the last &#x60;ListTopicSubscriptionsResponse&#x60;; indicates that this is a continuation of a prior &#x60;ListTopicSubscriptions&#x60; call, and that the system should return the next page of data. */
    pageToken?: string;
}

export interface PubsubProjectsTopicsTestIamPermissionsRequestParams {
    /** REQUIRED: The resource for which the policy detail is being requested. See [Resource names](https://cloud.google.com/apis/design/resource_names) for the appropriate value for this field. */
    resource: string;
    /** OAuth access token. */
    accessToken?: string;
    /** Data format for response. */
    alt?: 'json' | 'media' | 'proto';
    /** JSONP */
    callback?: string;
    /** Selector specifying which fields to include in a partial response. */
    fields?: string;
    /** API key. Your API key identifies your project and provides you with API access, quota, and reports. Required unless you provide an OAuth 2.0 token. */
    key?: string;
    /** OAuth 2.0 token for the current user. */
    oauthToken?: string;
    /** Returns response with indentations and line breaks. */
    prettyPrint?: boolean;
    /** Available to use for quota purposes for server-side applications. Can be any arbitrary string assigned to a user, but should not exceed 40 characters. */
    quotaUser?: string;
    /** Upload protocol for media (e.g. \&quot;raw\&quot;, \&quot;multipart\&quot;). */
    uploadProtocol?: string;
    /** Legacy upload protocol for media (e.g. \&quot;media\&quot;, \&quot;multipart\&quot;). */
    uploadType?: string;
    /** V1 error format. */
    $xgafv?: '1' | '2';
    body?: TestIamPermissionsRequest;
}


@Injectable()
export class ProjectsService {

    protected basePath = 'https://pubsub.googleapis.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Acknowledges the messages associated with the &#x60;ack_ids&#x60; in the &#x60;AcknowledgeRequest&#x60;. The Pub/Sub system can remove the relevant messages from the subscription. Acknowledging a message whose ack deadline has expired may succeed, but such a message may be redelivered later. Acknowledging a message more than once will not result in an error.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsSubscriptionsAcknowledge(requestParameters: PubsubProjectsSubscriptionsAcknowledgeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<object>;
    public pubsubProjectsSubscriptionsAcknowledge(requestParameters: PubsubProjectsSubscriptionsAcknowledgeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<object>>;
    public pubsubProjectsSubscriptionsAcknowledge(requestParameters: PubsubProjectsSubscriptionsAcknowledgeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<object>>;
    public pubsubProjectsSubscriptionsAcknowledge(requestParameters: PubsubProjectsSubscriptionsAcknowledgeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const subscription = requestParameters.subscription;
        if (subscription === null || subscription === undefined) {
            throw new Error('Required parameter subscription was null or undefined when calling pubsubProjectsSubscriptionsAcknowledge.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const body = requestParameters.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "subscription", value: subscription, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}:acknowledge`;
        return this.httpClient.request<object>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a subscription to a given topic. If the subscription already exists, returns &#x60;ALREADY_EXISTS&#x60;. If the corresponding topic doesn\&#39;t exist, returns &#x60;NOT_FOUND&#x60;. If the name is not provided in the request, the server will assign a random name for this subscription on the same project as the topic. Note that for REST API requests, you must specify a name.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsSubscriptionsCreate(requestParameters: PubsubProjectsSubscriptionsCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<Subscription>;
    public pubsubProjectsSubscriptionsCreate(requestParameters: PubsubProjectsSubscriptionsCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<Subscription>>;
    public pubsubProjectsSubscriptionsCreate(requestParameters: PubsubProjectsSubscriptionsCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<Subscription>>;
    public pubsubProjectsSubscriptionsCreate(requestParameters: PubsubProjectsSubscriptionsCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const name = requestParameters.name;
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling pubsubProjectsSubscriptionsCreate.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const body = requestParameters.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "name", value: name, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Subscription>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an existing subscription. All pending messages in the subscription are immediately dropped. Calls to &#x60;Pull&#x60; after deletion will return &#x60;NOT_FOUND&#x60;. After a subscription is deleted, a new one may be created with the same name, but the new one has no association with the old subscription, or its topic unless the same topic is specified.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsSubscriptionsDelete(requestParameters: PubsubProjectsSubscriptionsDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<object>;
    public pubsubProjectsSubscriptionsDelete(requestParameters: PubsubProjectsSubscriptionsDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<object>>;
    public pubsubProjectsSubscriptionsDelete(requestParameters: PubsubProjectsSubscriptionsDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<object>>;
    public pubsubProjectsSubscriptionsDelete(requestParameters: PubsubProjectsSubscriptionsDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const subscription = requestParameters.subscription;
        if (subscription === null || subscription === undefined) {
            throw new Error('Required parameter subscription was null or undefined when calling pubsubProjectsSubscriptionsDelete.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "subscription", value: subscription, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<object>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the configuration details of a subscription.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsSubscriptionsGet(requestParameters: PubsubProjectsSubscriptionsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<Subscription>;
    public pubsubProjectsSubscriptionsGet(requestParameters: PubsubProjectsSubscriptionsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<Subscription>>;
    public pubsubProjectsSubscriptionsGet(requestParameters: PubsubProjectsSubscriptionsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<Subscription>>;
    public pubsubProjectsSubscriptionsGet(requestParameters: PubsubProjectsSubscriptionsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const subscription = requestParameters.subscription;
        if (subscription === null || subscription === undefined) {
            throw new Error('Required parameter subscription was null or undefined when calling pubsubProjectsSubscriptionsGet.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "subscription", value: subscription, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Subscription>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the access control policy for a resource. Returns an empty policy if the resource exists and does not have a policy set.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsSubscriptionsGetIamPolicy(requestParameters: PubsubProjectsSubscriptionsGetIamPolicyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<Policy>;
    public pubsubProjectsSubscriptionsGetIamPolicy(requestParameters: PubsubProjectsSubscriptionsGetIamPolicyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<Policy>>;
    public pubsubProjectsSubscriptionsGetIamPolicy(requestParameters: PubsubProjectsSubscriptionsGetIamPolicyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<Policy>>;
    public pubsubProjectsSubscriptionsGetIamPolicy(requestParameters: PubsubProjectsSubscriptionsGetIamPolicyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const resource = requestParameters.resource;
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling pubsubProjectsSubscriptionsGetIamPolicy.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const optionsRequestedPolicyVersion = requestParameters.optionsRequestedPolicyVersion;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }
        if (optionsRequestedPolicyVersion !== undefined && optionsRequestedPolicyVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>optionsRequestedPolicyVersion, 'options.requestedPolicyVersion');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "resource", value: resource, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}:getIamPolicy`;
        return this.httpClient.request<Policy>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lists matching subscriptions.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsSubscriptionsList(requestParameters: PubsubProjectsSubscriptionsListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<ListSubscriptionsResponse>;
    public pubsubProjectsSubscriptionsList(requestParameters: PubsubProjectsSubscriptionsListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<ListSubscriptionsResponse>>;
    public pubsubProjectsSubscriptionsList(requestParameters: PubsubProjectsSubscriptionsListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<ListSubscriptionsResponse>>;
    public pubsubProjectsSubscriptionsList(requestParameters: PubsubProjectsSubscriptionsListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const project = requestParameters.project;
        if (project === null || project === undefined) {
            throw new Error('Required parameter project was null or undefined when calling pubsubProjectsSubscriptionsList.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const pageSize = requestParameters.pageSize;
        const pageToken = requestParameters.pageToken;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'pageSize');
        }
        if (pageToken !== undefined && pageToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageToken, 'pageToken');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "project", value: project, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/subscriptions`;
        return this.httpClient.request<ListSubscriptionsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifies the ack deadline for a specific message. This method is useful to indicate that more time is needed to process a message by the subscriber, or to make the message available for redelivery if the processing was interrupted. Note that this does not modify the subscription-level &#x60;ackDeadlineSeconds&#x60; used for subsequent messages.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsSubscriptionsModifyAckDeadline(requestParameters: PubsubProjectsSubscriptionsModifyAckDeadlineRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<object>;
    public pubsubProjectsSubscriptionsModifyAckDeadline(requestParameters: PubsubProjectsSubscriptionsModifyAckDeadlineRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<object>>;
    public pubsubProjectsSubscriptionsModifyAckDeadline(requestParameters: PubsubProjectsSubscriptionsModifyAckDeadlineRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<object>>;
    public pubsubProjectsSubscriptionsModifyAckDeadline(requestParameters: PubsubProjectsSubscriptionsModifyAckDeadlineRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const subscription = requestParameters.subscription;
        if (subscription === null || subscription === undefined) {
            throw new Error('Required parameter subscription was null or undefined when calling pubsubProjectsSubscriptionsModifyAckDeadline.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const body = requestParameters.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "subscription", value: subscription, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}:modifyAckDeadline`;
        return this.httpClient.request<object>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifies the &#x60;PushConfig&#x60; for a specified subscription. This may be used to change a push subscription to a pull one (signified by an empty &#x60;PushConfig&#x60;) or vice versa, or change the endpoint URL and other attributes of a push subscription. Messages will accumulate for delivery continuously through the call regardless of changes to the &#x60;PushConfig&#x60;.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsSubscriptionsModifyPushConfig(requestParameters: PubsubProjectsSubscriptionsModifyPushConfigRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<object>;
    public pubsubProjectsSubscriptionsModifyPushConfig(requestParameters: PubsubProjectsSubscriptionsModifyPushConfigRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<object>>;
    public pubsubProjectsSubscriptionsModifyPushConfig(requestParameters: PubsubProjectsSubscriptionsModifyPushConfigRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<object>>;
    public pubsubProjectsSubscriptionsModifyPushConfig(requestParameters: PubsubProjectsSubscriptionsModifyPushConfigRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const subscription = requestParameters.subscription;
        if (subscription === null || subscription === undefined) {
            throw new Error('Required parameter subscription was null or undefined when calling pubsubProjectsSubscriptionsModifyPushConfig.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const body = requestParameters.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "subscription", value: subscription, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}:modifyPushConfig`;
        return this.httpClient.request<object>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Pulls messages from the server. Returns an empty list if there are no messages available in the backlog. The server may return &#x60;UNAVAILABLE&#x60; if there are too many concurrent pull requests pending for the given subscription.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsSubscriptionsPull(requestParameters: PubsubProjectsSubscriptionsPullRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<PullResponse>;
    public pubsubProjectsSubscriptionsPull(requestParameters: PubsubProjectsSubscriptionsPullRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<PullResponse>>;
    public pubsubProjectsSubscriptionsPull(requestParameters: PubsubProjectsSubscriptionsPullRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<PullResponse>>;
    public pubsubProjectsSubscriptionsPull(requestParameters: PubsubProjectsSubscriptionsPullRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const subscription = requestParameters.subscription;
        if (subscription === null || subscription === undefined) {
            throw new Error('Required parameter subscription was null or undefined when calling pubsubProjectsSubscriptionsPull.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const body = requestParameters.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "subscription", value: subscription, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}:pull`;
        return this.httpClient.request<PullResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the access control policy on the specified resource. Replaces any existing policy. Can return &#x60;NOT_FOUND&#x60;, &#x60;INVALID_ARGUMENT&#x60;, and &#x60;PERMISSION_DENIED&#x60; errors.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsSubscriptionsSetIamPolicy(requestParameters: PubsubProjectsSubscriptionsSetIamPolicyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<Policy>;
    public pubsubProjectsSubscriptionsSetIamPolicy(requestParameters: PubsubProjectsSubscriptionsSetIamPolicyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<Policy>>;
    public pubsubProjectsSubscriptionsSetIamPolicy(requestParameters: PubsubProjectsSubscriptionsSetIamPolicyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<Policy>>;
    public pubsubProjectsSubscriptionsSetIamPolicy(requestParameters: PubsubProjectsSubscriptionsSetIamPolicyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const resource = requestParameters.resource;
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling pubsubProjectsSubscriptionsSetIamPolicy.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const body = requestParameters.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "resource", value: resource, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}:setIamPolicy`;
        return this.httpClient.request<Policy>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns permissions that a caller has on the specified resource. If the resource does not exist, this will return an empty set of permissions, not a &#x60;NOT_FOUND&#x60; error. Note: This operation is designed to be used for building permission-aware UIs and command-line tools, not for authorization checking. This operation may \&quot;fail open\&quot; without warning.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsSubscriptionsTestIamPermissions(requestParameters: PubsubProjectsSubscriptionsTestIamPermissionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<TestIamPermissionsResponse>;
    public pubsubProjectsSubscriptionsTestIamPermissions(requestParameters: PubsubProjectsSubscriptionsTestIamPermissionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<TestIamPermissionsResponse>>;
    public pubsubProjectsSubscriptionsTestIamPermissions(requestParameters: PubsubProjectsSubscriptionsTestIamPermissionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<TestIamPermissionsResponse>>;
    public pubsubProjectsSubscriptionsTestIamPermissions(requestParameters: PubsubProjectsSubscriptionsTestIamPermissionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const resource = requestParameters.resource;
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling pubsubProjectsSubscriptionsTestIamPermissions.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const body = requestParameters.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "resource", value: resource, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}:testIamPermissions`;
        return this.httpClient.request<TestIamPermissionsResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates the given topic with the given name.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsTopicsCreate(requestParameters: PubsubProjectsTopicsCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<Topic>;
    public pubsubProjectsTopicsCreate(requestParameters: PubsubProjectsTopicsCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<Topic>>;
    public pubsubProjectsTopicsCreate(requestParameters: PubsubProjectsTopicsCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<Topic>>;
    public pubsubProjectsTopicsCreate(requestParameters: PubsubProjectsTopicsCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const name = requestParameters.name;
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling pubsubProjectsTopicsCreate.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const body = requestParameters.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "name", value: name, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Topic>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the topic with the given name. Returns &#x60;NOT_FOUND&#x60; if the topic does not exist. After a topic is deleted, a new topic may be created with the same name; this is an entirely new topic with none of the old configuration or subscriptions. Existing subscriptions to this topic are not deleted, but their &#x60;topic&#x60; field is set to &#x60;_deleted-topic_&#x60;.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsTopicsDelete(requestParameters: PubsubProjectsTopicsDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<object>;
    public pubsubProjectsTopicsDelete(requestParameters: PubsubProjectsTopicsDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<object>>;
    public pubsubProjectsTopicsDelete(requestParameters: PubsubProjectsTopicsDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<object>>;
    public pubsubProjectsTopicsDelete(requestParameters: PubsubProjectsTopicsDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const topic = requestParameters.topic;
        if (topic === null || topic === undefined) {
            throw new Error('Required parameter topic was null or undefined when calling pubsubProjectsTopicsDelete.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "topic", value: topic, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<object>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the configuration of a topic.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsTopicsGet(requestParameters: PubsubProjectsTopicsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<Topic>;
    public pubsubProjectsTopicsGet(requestParameters: PubsubProjectsTopicsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<Topic>>;
    public pubsubProjectsTopicsGet(requestParameters: PubsubProjectsTopicsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<Topic>>;
    public pubsubProjectsTopicsGet(requestParameters: PubsubProjectsTopicsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const topic = requestParameters.topic;
        if (topic === null || topic === undefined) {
            throw new Error('Required parameter topic was null or undefined when calling pubsubProjectsTopicsGet.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "topic", value: topic, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Topic>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the access control policy for a resource. Returns an empty policy if the resource exists and does not have a policy set.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsTopicsGetIamPolicy(requestParameters: PubsubProjectsTopicsGetIamPolicyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<Policy>;
    public pubsubProjectsTopicsGetIamPolicy(requestParameters: PubsubProjectsTopicsGetIamPolicyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<Policy>>;
    public pubsubProjectsTopicsGetIamPolicy(requestParameters: PubsubProjectsTopicsGetIamPolicyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<Policy>>;
    public pubsubProjectsTopicsGetIamPolicy(requestParameters: PubsubProjectsTopicsGetIamPolicyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const resource = requestParameters.resource;
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling pubsubProjectsTopicsGetIamPolicy.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const optionsRequestedPolicyVersion = requestParameters.optionsRequestedPolicyVersion;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }
        if (optionsRequestedPolicyVersion !== undefined && optionsRequestedPolicyVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>optionsRequestedPolicyVersion, 'options.requestedPolicyVersion');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "resource", value: resource, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}:getIamPolicy`;
        return this.httpClient.request<Policy>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lists matching topics.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsTopicsList(requestParameters: PubsubProjectsTopicsListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<ListTopicsResponse>;
    public pubsubProjectsTopicsList(requestParameters: PubsubProjectsTopicsListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<ListTopicsResponse>>;
    public pubsubProjectsTopicsList(requestParameters: PubsubProjectsTopicsListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<ListTopicsResponse>>;
    public pubsubProjectsTopicsList(requestParameters: PubsubProjectsTopicsListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const project = requestParameters.project;
        if (project === null || project === undefined) {
            throw new Error('Required parameter project was null or undefined when calling pubsubProjectsTopicsList.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const pageSize = requestParameters.pageSize;
        const pageToken = requestParameters.pageToken;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'pageSize');
        }
        if (pageToken !== undefined && pageToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageToken, 'pageToken');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "project", value: project, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/topics`;
        return this.httpClient.request<ListTopicsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds one or more messages to the topic. Returns &#x60;NOT_FOUND&#x60; if the topic does not exist. The message payload must not be empty; it must contain either a non-empty data field, or at least one attribute.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsTopicsPublish(requestParameters: PubsubProjectsTopicsPublishRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<PublishResponse>;
    public pubsubProjectsTopicsPublish(requestParameters: PubsubProjectsTopicsPublishRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<PublishResponse>>;
    public pubsubProjectsTopicsPublish(requestParameters: PubsubProjectsTopicsPublishRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<PublishResponse>>;
    public pubsubProjectsTopicsPublish(requestParameters: PubsubProjectsTopicsPublishRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const topic = requestParameters.topic;
        if (topic === null || topic === undefined) {
            throw new Error('Required parameter topic was null or undefined when calling pubsubProjectsTopicsPublish.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const body = requestParameters.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "topic", value: topic, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}:publish`;
        return this.httpClient.request<PublishResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the access control policy on the specified resource. Replaces any existing policy. Can return &#x60;NOT_FOUND&#x60;, &#x60;INVALID_ARGUMENT&#x60;, and &#x60;PERMISSION_DENIED&#x60; errors.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsTopicsSetIamPolicy(requestParameters: PubsubProjectsTopicsSetIamPolicyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<Policy>;
    public pubsubProjectsTopicsSetIamPolicy(requestParameters: PubsubProjectsTopicsSetIamPolicyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<Policy>>;
    public pubsubProjectsTopicsSetIamPolicy(requestParameters: PubsubProjectsTopicsSetIamPolicyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<Policy>>;
    public pubsubProjectsTopicsSetIamPolicy(requestParameters: PubsubProjectsTopicsSetIamPolicyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const resource = requestParameters.resource;
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling pubsubProjectsTopicsSetIamPolicy.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const body = requestParameters.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "resource", value: resource, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}:setIamPolicy`;
        return this.httpClient.request<Policy>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lists the name of the subscriptions for this topic.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsTopicsSubscriptionsList(requestParameters: PubsubProjectsTopicsSubscriptionsListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<ListTopicSubscriptionsResponse>;
    public pubsubProjectsTopicsSubscriptionsList(requestParameters: PubsubProjectsTopicsSubscriptionsListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<ListTopicSubscriptionsResponse>>;
    public pubsubProjectsTopicsSubscriptionsList(requestParameters: PubsubProjectsTopicsSubscriptionsListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<ListTopicSubscriptionsResponse>>;
    public pubsubProjectsTopicsSubscriptionsList(requestParameters: PubsubProjectsTopicsSubscriptionsListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const topic = requestParameters.topic;
        if (topic === null || topic === undefined) {
            throw new Error('Required parameter topic was null or undefined when calling pubsubProjectsTopicsSubscriptionsList.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const pageSize = requestParameters.pageSize;
        const pageToken = requestParameters.pageToken;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'pageSize');
        }
        if (pageToken !== undefined && pageToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageToken, 'pageToken');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "topic", value: topic, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/subscriptions`;
        return this.httpClient.request<ListTopicSubscriptionsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns permissions that a caller has on the specified resource. If the resource does not exist, this will return an empty set of permissions, not a &#x60;NOT_FOUND&#x60; error. Note: This operation is designed to be used for building permission-aware UIs and command-line tools, not for authorization checking. This operation may \&quot;fail open\&quot; without warning.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pubsubProjectsTopicsTestIamPermissions(requestParameters: PubsubProjectsTopicsTestIamPermissionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<TestIamPermissionsResponse>;
    public pubsubProjectsTopicsTestIamPermissions(requestParameters: PubsubProjectsTopicsTestIamPermissionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpResponse<TestIamPermissionsResponse>>;
    public pubsubProjectsTopicsTestIamPermissions(requestParameters: PubsubProjectsTopicsTestIamPermissionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<HttpEvent<TestIamPermissionsResponse>>;
    public pubsubProjectsTopicsTestIamPermissions(requestParameters: PubsubProjectsTopicsTestIamPermissionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<any> {
        const resource = requestParameters.resource;
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling pubsubProjectsTopicsTestIamPermissions.');
        }
        const accessToken = requestParameters.accessToken;
        const alt = requestParameters.alt;
        const callback = requestParameters.callback;
        const fields = requestParameters.fields;
        const key = requestParameters.key;
        const oauthToken = requestParameters.oauthToken;
        const prettyPrint = requestParameters.prettyPrint;
        const quotaUser = requestParameters.quotaUser;
        const uploadProtocol = requestParameters.uploadProtocol;
        const uploadType = requestParameters.uploadType;
        const $xgafv = requestParameters.$xgafv;
        const body = requestParameters.body;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accessToken !== undefined && accessToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accessToken, 'access_token');
        }
        if (alt !== undefined && alt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alt, 'alt');
        }
        if (callback !== undefined && callback !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>callback, 'callback');
        }
        if (fields !== undefined && fields !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fields, 'fields');
        }
        if (key !== undefined && key !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>key, 'key');
        }
        if (oauthToken !== undefined && oauthToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>oauthToken, 'oauth_token');
        }
        if (prettyPrint !== undefined && prettyPrint !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>prettyPrint, 'prettyPrint');
        }
        if (quotaUser !== undefined && quotaUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>quotaUser, 'quotaUser');
        }
        if (uploadProtocol !== undefined && uploadProtocol !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadProtocol, 'upload_protocol');
        }
        if (uploadType !== undefined && uploadType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadType, 'uploadType');
        }
        if ($xgafv !== undefined && $xgafv !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$xgafv, '$.xgafv');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Oauth2c) required
        localVarCredential = this.configuration.lookupCredential('Oauth2c');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (Oauth2) required
        localVarCredential = this.configuration.lookupCredential('Oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1beta2/${this.configuration.encodeParam({name: "resource", value: resource, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}:testIamPermissions`;
        return this.httpClient.request<TestIamPermissionsResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
