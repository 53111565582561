import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Observable, of, ReplaySubject} from 'rxjs';
import {concatMap, filter, map, mergeMap, scan, shareReplay, switchMap, take, takeUntil} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';
import {FileResource, Folder} from 'models';

import {ErrorResponse, isErrorResponse} from '../error_service/error_response';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {FirebaseFirestoreDataService, IASEvent, IASEventType} from '../firebase/firebase_firestore_data_service';
import {TzDatePipe} from '../pipes/tzdate_pipe';
import {Asset, AssetService, Clip, ClipOperationStatus, Original} from '../services/asset_service';
import {MediaCacheService} from '../services/media_cache_service';
import {SnackBarService} from '../services/snackbar_service';
import {TimezoneService} from '../services/timezone_service';
import {DEFAULT_CONCURRENT_REQUEST_NUMBER, UtilsService} from '../services/utils_service';

const DISPLAYED_COLUMNS = [
  'select',
  'folderName',
  'exportStatus',
  'lastExported',
] as const;

/** The folder name and the asset status in the folder. */
interface StateMap {
  [key: string]: ExportInfo;
}

interface ExportInfo {
  status: ClipOperationStatus|string;
  lastExportTime: string;
}

/** Export dialog for any asset (VoD or live, original or clip). */
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- FIXME
@Component({
  selector: 'mam-export-asset-dialog',
  templateUrl: './export_asset_dialog.ng.html',
  styleUrls: ['./export_asset_dialog.scss'],
})
export class ExportAssetDialog implements OnDestroy {
  static getDialogOptions(data: ExportAssetDialogInputData):
      MatDialogConfig<ExportAssetDialogInputData> {
    return {
      data,
      maxWidth: 800,
      width: '60%',
      autoFocus: false,
      disableClose: true,
    };
  }

  displayedColumns = this.displayColumns(this.data.assets);

  selectedFolder?: Folder;

  foldersForExport$ =
      this.mediaCache.state.foldersForExport$.pipe(map(folders => {
        return folders.sort(
            (a, b) => a.name.localeCompare(b.name, undefined, {numeric: true}));
      }));

  /** Provides folder-indexed export status when the input is a single asset. */
  stateMap$?: Observable<StateMap>;

  dialogTitle = this.data.assets.length === 1 ?
      `Export ${this.data.assets[0].title} to` :
      `Export selected assets to`;

  constructor(
      readonly utils: UtilsService,
      private readonly dialogRef:
          MatDialogRef<ExportAssetDialog, ExportAssetDialogOutputData>,
      private readonly assetService: AssetService,
      private readonly mediaCache: MediaCacheService,
      private readonly snackBar: SnackBarService,
      private readonly tzDatePipe: TzDatePipe,
      private readonly dataService: FirebaseFirestoreDataService,
      private readonly featureService: FeatureFlagService,
      private readonly timezone: TimezoneService,
      @Inject(MAT_DIALOG_DATA) readonly data: ExportAssetDialogInputData,
  ) {
    // Update asset export states only when a single asset is exported.
    const singleAsset = this.getSingleAsset();
    if (singleAsset) {
      this.stateMap$ = this.mediaCache.state.mediaCacheUpdate$.pipe(
          switchMap(() => {
            // Not need to refresh original assets, we will get the latest
            // status by calling MediaCache API
            if (!singleAsset.original) return of(singleAsset);
            return this.assetService.getClip(singleAsset.name);
          }),
          filter((asset): asset is Asset => !isErrorResponse(asset)),
          switchMap(asset => this.getStateMap(asset)),
          takeUntil(this.destroyed$),
          shareReplay({bufferSize: 1, refCount: true}),
      );
    }
  }

  /**
   * Trigger the export live clip API in the folder selection for-loop.
   */
  exportAssets() {
    const selectedFolder = this.selectedFolder;
    assertTruthy(selectedFolder, 'Missing selected folder');

    const folderName = selectedFolder.name;
    const folderId = selectedFolder.folderId;

    const exportDone$ = new ReplaySubject<void>(1);
    this.dialogRef.close(exportDone$);

    this.utils
        .batchApiCalls(
            this.data.assets,
            (asset):
                Observable<Clip|FileResource|ErrorResponse> => {
                  // Live clip export
                  if (asset.isLive) {
                    if (!asset.original) {
                      return of(new ErrorResponse(
                          'Live source export is not supported'));
                    }
                    if (this.featureService.featureOn('store-user-information')) {
                      this.dataService.createIASEvent(
                        this.formatIASEvent(asset,folderId,IASEventType.LIVE_CLIP_EXPORT));
                    }
                    return this.assetService.exportClip(asset.name, folderName);
                  }

                  // VoD clip export
                  if (asset.original) {
                    return this.mediaCache.state.selectedSite$.pipe(
                        take(1), switchMap(({siteId}) => {
                          if (this.featureService.featureOn('store-user-information')) {
                            this.dataService.createIASEvent(
                              this.formatIASEvent(asset,folderId,IASEventType.VOD_CLIP_EXPORT));
                          }

                          return this.mediaCache.downloadClip(
                              siteId, folderId, asset.name);
                        }));
                  }

                  // VoD original export
                  return this.mediaCache.state.selectedSite$.pipe(
                      take(1), switchMap(({siteId}) => {
                        const fileName =
                            this.utils.lastPart(asset.gcsLocationUrl);
                        if (this.featureService.featureOn('store-user-information')) {
                          this.dataService.createIASEvent(
                            this.formatIASEvent(asset,folderId,IASEventType.VOD_ORIGINAL_EXPORT));
                        }
                        return this.mediaCache.downloadOriginal(
                            siteId, folderId, fileName);
                      }));
                })
        .subscribe(responses => {
          exportDone$.next();
          exportDone$.complete();

          // Case single-asset export
          const singleAsset = this.getSingleAsset();
          if (singleAsset) {
            if (isErrorResponse(responses[0])) {
              this.snackBar.error({
                message:
                    `Failed to export ${singleAsset.title} to ${folderId}.`,
                details: responses[0].message,
                doNotLog: true,
              });
              this.dialogRef.close(undefined);
              return;
            }

            // Trigger state updates in case the dialog was re-opened
            // before the API call completed.
            this.mediaCache.state.manualUpdate$.next(undefined);

            this.snackBar.message(
                `Exporting ${singleAsset.title} to ${folderId}.`);
            return;
          }

          // Case multi-assets export
          const assets = this.data.assets;
          const errorCount = responses.reduce(
              (total, r) => total + (isErrorResponse(r) ? 1 : 0), 0);

          // No error, show success snackbar
          if (errorCount === 0) {

            this.snackBar.message(
                `Exporting ${assets.length} asset(s) to ${folderId}.`);
            return;
          }

          // Any error, show error count and the first error message.
          const firstError =
              responses.find(r => isErrorResponse(r)) as ErrorResponse;
          const message =
              `Failed to export ${errorCount} asset(s) to ${folderId}.`;
          this.snackBar.error({
            message,
            details: firstError.message,
            doNotLog: true,
          });
        });
  }

  getFolderSize(folder: Folder) {
    return folder.totalFileStorageGbytes * (1000 ** 3);
  }

  getSingleAsset(): Asset|undefined {
    return this.data.assets.length === 1 ? this.data.assets[0] : undefined;
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getStateMap(asset: Asset): Observable<StateMap> {
    if (asset.original) {
      return this.foldersForExport$.pipe(map(folders => {
        return folders.reduce<StateMap>((stateMap, folder) => {
          stateMap[folder.name] = {
            status: this.getClipStatus(folder, asset),
            lastExportTime: this.getLastExported(asset, folder.name),
          };

          return stateMap;
        }, {});
      }));
    }

    return this.foldersForExport$.pipe(
        // From one emission of an array to one emission per item.
        concatMap(folders => folders),
        // Get full asset file status in each folder. Clip export status is
        // revealed by the attached `exportInfo`.
        // However, we can only know the full asset's export status
        // by making an extra API call to know its on-prem status.
        mergeMap(
            folder => this.getFileStatusAndUpdateTime(folder, asset),
            DEFAULT_CONCURRENT_REQUEST_NUMBER),
        // Aggregate response to the present-ready state map.
        scan(
            (acc, response) => {
              const {status, lastExportTime, folder} = response;
              acc[folder.name] = {status, lastExportTime};
              return acc;
            },
            {} as StateMap),
    );
  }

  private getClipStatus(folder: Folder, clip: Clip): ClipOperationStatus {
    const folderName = folder.name;
    // Live clip
    if (clip.isLive) {
      const exportState = clip.exportInfo?.stateMap?.[folderName]?.exportState;
      return this.assetService.formatExportFolderStatus(exportState);
    }

    // VoD clip
    const pfrState = clip.pfrInfo.stateMap[folderName]?.state;
    return this.assetService.formatExportFolderStatus(pfrState);
  }

  private getFileStatusAndUpdateTime(folder: Folder, asset: Original):
      Observable<{status: string; lastExportTime: string, folder: Folder}> {
    return this.mediaCache.state.selectedSite$.pipe(
        take(1),
        switchMap(site => {
          // User plans to download asset to the export folder, so we should
          // call getOrCreate API which is called inside `getFileAndState` to
          // get ready.
          return this.mediaCache.getFileAndState(
              site.siteId, folder.folderId, asset);
        }),
        map(fileAndState => {
          const {onpremFile, state} = fileAndState;
          let lastExportTime = '-';
          if (onpremFile && !isErrorResponse(onpremFile) &&
              onpremFile.updateTime) {
            lastExportTime = this.tzDatePipe.transform(
                                 onpremFile.updateTime, 'MMM d, y, h:mm a') ||
                '-';
          }
          return {
            status: this.mediaCache.formatFileLocation(state),
            lastExportTime,
            folder,
          };
        }),
    );
  }

  private getLastExported(clip: Clip, folderName: string): string {
    const exportTime = clip.exportInfo?.stateMap[folderName]?.updateTime;
    if (exportTime) {
      return this.tzDatePipe.transform(exportTime, 'MMM d, y, h:mm a') || '-';
    }
    return '-';
  }

  private displayColumns(assets: Asset[]) {
    if (assets.length !== 1) return ['select', 'folderName'];
    return DISPLAYED_COLUMNS;
  }

  private formatIASEvent(asset: Asset, folderId: string, type: string): IASEvent {

    const createTime  = new Date().toISOString();

    return { type: type,
      assetTitle: asset.title,
      assetName: asset.name,
      state: asset.state,
      createTime: createTime,
      folderId: folderId,
      formattedCreateDate: this.timezone.formatTimeZoneStringToYyyyMMdd(createTime)
    } as IASEvent;
  }
}

/** Input data to this dialog */
export interface ExportAssetDialogInputData {
  assets: Asset[];
}

/** Emits once the export call is complete. */
export type ExportAssetDialogOutputData = Observable<void>|'';
