import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DateTime} from 'luxon';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

import {FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {TimezoneService} from '../services/timezone_service';

/** Amount of days we can navigate back relative to current date. */
export const MAX_PAST_DAYS = 30;

/** Amount of days we can navigate forward relative to current date. */
export const MAX_FUTURE_DAYS = 30;

/** Url param name for selected date in Live Landing . */
export const LIVE_LANDING_DATE_PARAM = 'llDate';

/**
 * A service that provides calendar-like day-by-day navigation for the live
 * view.
 */
@Injectable()
export class LiveNavigationService {
  private readonly selectedDateInternal$ =
      new BehaviorSubject<DateInfo|undefined>(undefined);

  readonly selectedDate$ = this.selectedDateInternal$.pipe(
      filter((date): date is DateInfo => !!date),
      distinctUntilChanged(
          (a, b) => a.date.equals(b.date) && a.type === b.type));

  get selectedDate() {
    return this.selectedDateInternal$.value;
  }

  /** Emits true if the next day can be selected. */
  readonly canGoNext$: Observable<boolean>;

  /** Emits true if the previous day can be selected. */
  readonly canGoPrevious$: Observable<boolean>;

  /** Emits false if today is already selected. */
  readonly canGoToday$: Observable<boolean>;

  constructor(
      private readonly timezone: TimezoneService,
      private readonly analytics: FirebaseAnalyticsService,
      private readonly router: Router,
      route: ActivatedRoute,
  ) {
    // Listen to url param changes
    route.queryParamMap
        .pipe(map(params => {
          const dateString = params.get(LIVE_LANDING_DATE_PARAM);
          if (!dateString) {
            return {date: this.timezone.getTodayDate(), type: DayType.TODAY};
          }

          // Format date in pre-defined timezone at 12:00am.
          const date = this.timezone.parseFromIso(dateString).startOf('day');

          if (!this.canGoToDate(date) ||
              date.equals(this.timezone.getTodayDate())) {
            // Remove incorrect date and today date from url
            this.today();
            return;
          }

          return {date, type: this.getDayType(date)};
        }))
        .subscribe(dateInfo => {
          this.selectedDateInternal$.next(dateInfo);
        });

    this.canGoNext$ = this.canGoNext();

    this.canGoPrevious$ = this.canGoPrevious();

    this.canGoToday$ = this.canGoToday();
  }

  /** Select today's date. */
  today(userAction = false) {
    const newDate = this.timezone.getTodayDate();
    this.selectDay(newDate);
    if (userAction) {
      this.logEvent('today', newDate);
    }
  }

  nextDay() {
    if (!this.selectedDate) return;
    const newDate = this.selectedDate.date.plus({days: 1});
    this.selectDay(newDate);
    this.logEvent('next', newDate);
  }

  previousDay() {
    if (!this.selectedDate) return;
    const newDate = this.selectedDate.date.minus({days: 1});
    this.selectDay(newDate);
    this.logEvent('previous', newDate);
  }

  /** Get day type with relation to today. */
  getDayType(date: DateTime): DayType {
    const diff = date.diff(this.timezone.getTodayDate(), 'day').days;
    if (diff === 0) {
      return DayType.TODAY;
    }
    if (diff > 0) {
      return DayType.FUTURE;
    }
    return DayType.PAST;
  }

  private canGoNext(): Observable<boolean> {
    return this.selectedDate$.pipe(
        map(({date}) => this.canGoToDate(date.plus({days: 1}))));
  }

  private canGoPrevious(): Observable<boolean> {
    return this.selectedDate$.pipe(
        map(({date}) => this.canGoToDate(date.minus({days: 1}))));
  }

  private canGoToday(): Observable<boolean> {
    return this.selectedDate$.pipe(map(({type}) => type !== DayType.TODAY));
  }

  private canGoToDate(date: DateTime) {
    if (!date.isValid) return false;

    const diff = date.diff(this.timezone.getTodayDate(), 'day').days;
    return diff > 0 ? diff <= MAX_FUTURE_DAYS : Math.abs(diff) <= MAX_PAST_DAYS;
  }

  private async selectDay(date: DateTime|null) {
    if (date) {
      if (!this.canGoToDate(date)) return;
      if (this.selectedDate && date.equals(this.selectedDate.date)) {
        // Reset query param first to re-trigger emission of the same date.
        await this.selectDay(null);
      }

      if (date.equals(this.timezone.getTodayDate())) {
        // Do not store today date in the url.
        date = null;
      }
    }

    return this.router.navigate([], {
      queryParams: {[LIVE_LANDING_DATE_PARAM]: date?.toISODate()},
      queryParamsHandling: 'merge',
    });
  }

  private logEvent(buttonName: string, date: DateTime) {
    this.analytics.logSearchEvent(
        `Navigate to ${buttonName} date for live landing`,
        {resource: date.toISODate() ?? undefined});
  }
}

/** Type of day relative to today. */
export enum DayType {
  TODAY = 'TODAY',
  PAST = 'PAST',
  FUTURE = 'FUTURE',
}

/** Contains date and day type information. */
export interface DateInfo {
  date: DateTime;
  type: DayType;
}
