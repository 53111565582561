<h1 mat-dialog-title>Schedule TTL</h1>
<div mat-dialog-content>
  <p>
    Schedule the on-prem file TTL for MediaCache auto clean-up.
  </p>
  <mat-form-field appearance="fill" class="expire-datepicker-form-field">
    <mat-label>Choose expiry date</mat-label>
    <input matInput [min]="minDate" [matDatepicker]="datepicker" [formControl]="expiryDateControl">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel extending TTL"
      [mam-firebase-resource]="data.asset?.name ?? 'Bulk assets'">
    Cancel
  </button>
  <button
      mat-button
      #primary
      class="primary"
      [disabled]="expiryDateControl.invalid"
      [matTooltip]="getTooltip()"
      mam-firebase-ga="Schedule TTL"
      [mam-firebase-resource]="data.asset?.name ?? 'Bulk assets'"
      (click)="extendTtl()">
    Confirm
  </button>
</div>
