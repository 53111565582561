import {ApiFolder} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

/** UI model for MediaCache folder resource */
export class Folder implements RequiredRecursively<ApiFolder> {
  name = this.apiFolder.name ?? '';
  createTime = this.apiFolder.createTime ?? '';
  customMetadata = this.apiFolder.customMetadata ?? {};
  fileCount = this.apiFolder.fileCount ?? 0;
  fileTtlDays = this.apiFolder.fileTtlDays ?? 0;
  folderId = this.apiFolder.folderId ?? '';
  onPremScanInfo = {
    scanState:
        this.apiFolder.onPremScanInfo?.scanState ?? 'SCAN_STATE_UNSPECIFIED',
    updateTime: this.apiFolder.onPremScanInfo?.updateTime ?? '',
  };
  rootFolderPath = this.apiFolder.rootFolderPath ?? '';
  state = this.apiFolder.state ?? ApiFolder.StateEnum.STATE_UNSPECIFIED;
  storageTransferInfo = {
    jobState:
        this.apiFolder.storageTransferInfo?.jobState ?? 'JOB_STATE_UNSPECIFIED',
    transferJobId: this.apiFolder.storageTransferInfo?.transferJobId ?? '',
  };
  totalFileStorageGbytes = this.apiFolder.totalFileStorageGbytes ?? 0;
  type = this.apiFolder.type ?? ApiFolder.TypeEnum.FOLDER_TYPE_UNSPECIFIED;
  isVirtual = this.apiFolder.isVirtual ?? false;

  constructor(private apiFolder: ApiFolder = {}) {}
}
