import {Injectable, InjectionToken} from '@angular/core';
import {Router} from '@angular/router';
import {DateTime} from 'luxon';
import {BehaviorSubject, Subject} from 'rxjs';
import {skip, takeUntil} from 'rxjs/operators';

import {assertTruthy, castExists} from 'asserts/asserts';

import {ApiError} from '../error_service/api_error';
import {ErrorService} from '../error_service/error_service';
import {StatusCode} from '../error_service/status_code';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {FirebaseFirestoreDataService, IASEventType} from '../firebase/firebase_firestore_data_service';

import {ResumableUploadService} from './resumable_upload_service';
import {LOCAL_UPLOAD_ROUTE_PATH} from './routing_path_service';
import {SnackBarService} from './snackbar_service';

/** Use this to exclude uploads which are started one week ago. */
const OLDEST_UPLOAD_START_TIME = DateTime.local().minus({days: 7}).toMillis();

export const STORAGE_FACTORY_METHOD = () => window.localStorage;

/**
 * Injection token for using a storage singleton that contain a list of saved
 * upload payloads.
 */
export const STORAGE = new InjectionToken<Storage>(
  'Storage of saved upload payloads', {factory: STORAGE_FACTORY_METHOD});

/** This is the key of the local upload payloads saved to Local Storage. */
export const MAM_LOCAL_UPLOAD = 'mam-local-upload';

/**
 * This is used to manage all uploads.
 */
@Injectable({providedIn: 'root'})
export class LocalUploadsTrackingService {
  /**
   * The LocalUpload that have been created so far. They are
   * used for LocalUploadTable display.
   */
  readonly localUploads$ = new BehaviorSubject<LocalUpload[]>([]);

  constructor(
      private readonly storage: Storage,
      private readonly resumableUploadService: ResumableUploadService,
      private readonly snackBar: SnackBarService,
      private readonly errorService: ErrorService,
      private readonly router: Router,
      private readonly dataService: FirebaseFirestoreDataService,
      private readonly featureService: FeatureFlagService,
  ) {
    const savedUploadPayloads = this.getUploadPayloads();
    const storedLocalUploads: LocalUpload[] = [];
    for (const savedUploadPayload of savedUploadPayloads) {
      if (savedUploadPayload.startTime > OLDEST_UPLOAD_START_TIME) {
        if (savedUploadPayload.status === LocalUploadStatus.UPLOADING) {
          // Change to PAUSED for display purpose.
          savedUploadPayload.status = LocalUploadStatus.PAUSED;
        }
        const savedUpload = new LocalUpload(
            this.resumableUploadService,
            this.errorService,
            undefined,
            savedUploadPayload,
            this.dataService,
            this.featureService.featureOn('show-user-information')
        );
        // Skip the initial status emits so that the snackbar won't get
        // displayed.
        savedUpload.status$.pipe(skip(1)).subscribe((status) => {
          this.snackbarAndSave(status, savedUpload);
        });
        storedLocalUploads.push(savedUpload);
      } else {
        // Remove expired uploads.
        this.removeUploadPayload(savedUploadPayload.fileName);
      }
    }
    storedLocalUploads.sort((a, b) => b.startTime - a.startTime);

    this.localUploads$.next(storedLocalUploads);
  }

  /**
   * Cancel current upload.
   * Remove the LocalUpload from the current display and payloads in Local
   * Storage.
   */
  remove(upload: LocalUpload) {
    upload.cancel();
    const uploads =
        this.localUploads$.value.filter(uploadItem => uploadItem !== upload);
    this.localUploads$.next(uploads);
    this.removeUploadPayload(upload.fileName);
  }

  /**
   * Loop through files selected by users from their device storage,
   * and upload every file.
   * If the file is already selected before and currently displayed in the table
   * view, check its status,
   * - scenario 1: if it is under PAUSED or ERROR state, try resume or retry
   * the current upload. (If the file has changed according to its size, then
   * start the upload from beginning.)
   * - scenario 2: if it is under UPLOADING, ignore it.
   * -  scenario 3: if it is COMPLETED, do consider it as a new upload, if the
   * file still exists in GCS bucket, will mark this new upload as ERROR; if the
   * file is deleted from GCS bucket, this upload will proceed. NOTICE: in this
   * scenario, in local storage, we ONLY store the latest upload associated
   * with this file name.
   *
   * If the file is a new file and not displayed in the current table view,
   * consider this as a new upload.
   */
  uploadFiles(selectedFiles: File[]) {
    // Use the helper function to find the existing PAUSED or ERROR uploads
    // which matches the selected files.
    const validPausedUploads = findMatchedPausedOrErrorUploads(
        this.localUploads$.value, selectedFiles);

    // Use the helper function to find new files to upload.
    const newFiles =
        findNewFilesToUpload(this.localUploads$.value, selectedFiles);
    const newUploads: LocalUpload[] = [];
    for (const file of newFiles) {
      const upload = new LocalUpload(
          this.resumableUploadService,
          this.errorService,
          file,
          undefined,
          this.dataService,
          this.featureService.featureOn('show-user-information')
      );
      upload.status$.subscribe((status) => {
        this.snackbarAndSave(status, upload);
        if (this.featureService.featureOn('store-user-information')) {
          // We need to store IAS Event even the status is not completed.
          this.storeIASEvent(upload).then( () => {
            this.dataService?.retrieveIASEventForLocalUpload(upload.fileName)
            .subscribe( events => {
              if (events.length) {
                upload.user$.next(events[0].username ?? '');
              }
            });
            return;
            }
          );
        }
      });
      newUploads.unshift(upload);
    }
    this.localUploads$.next([...newUploads, ...this.localUploads$.value]);

    for (const upload of [...newUploads, ...validPausedUploads]) {
      upload.start();
    }

    this.displayCounts(validPausedUploads, newUploads);
  }

  /**
   * Retry or resume one upload.
   * If file name doesn't match with current upload, display error.
   * If file has changed, start the upload from beginning.
   */
  retryOrResumeOneUpload(selectFile: File, currentUpload: LocalUpload) {
    if (selectFile.name !== currentUpload.fileName) {
      this.snackBar.error('Please select the same file.');
      return;
    }

    currentUpload.file = selectFile;
    if (selectFile.size !== currentUpload.fileSize) {
      // Update to the new size.
      currentUpload.fileSize = selectFile.size;
      // File has changed. Start the upload from beginning.
      currentUpload.sessionUri = '';
    }
    currentUpload.start();
  }

  /**
   * This is a side effect to display the snackbar and save uploads
   * into local storage.
   * TODO: Consider better handling of side effects.
   */
  private snackbarAndSave(status: LocalUploadStatus, cur: LocalUpload) {
    const isLocalUploadView =
        this.router.url?.includes(LOCAL_UPLOAD_ROUTE_PATH);
    if (isLocalUploadView) {
      // No need to add action in snackbar.
      if (status === LocalUploadStatus.COMPLETED) {
        this.snackBar.message(`${cur.fileName} upload completed`);
      } else if (status === LocalUploadStatus.ERROR) {
        // Directly displays the error message.
        this.snackBar.error(
            `${cur.fileName} upload failed: ${cur.errorMessage}`);
      }
    } else {
      if (status === LocalUploadStatus.COMPLETED) {
        this.snackBar.message(`${cur.fileName} upload completed`, 'TRACK')
            .onAction()
            .subscribe(() => {
              this.navigateToLocalUploadView();
            });
      } else if (status === LocalUploadStatus.ERROR) {
        this.snackBar.error(`${cur.fileName} upload failed`, 'DETAILS')
            .onAction()
            .subscribe(() => {
              this.navigateToLocalUploadView();
            });
      }
    }

    this.saveToLocalStorage(cur);
  }

  private async storeIASEvent(cur: LocalUpload) {
      const iasEvent = {
        type: IASEventType.LOCAL_UPLOAD,
        filename: cur.fileName,
        createTime: new Date().toISOString(),
      };
      await this.dataService.createIASEvent(iasEvent);
  }

  private displayCounts(
      validPausedUploads: LocalUpload[], newUploads: LocalUpload[]) {
    const count = validPausedUploads.length + newUploads.length;
    if (count > 0) {
      let message;
      if (count === 1 && validPausedUploads.length === 1) {
        message = `${validPausedUploads[0].fileName} upload started`;
      } else if (count === 1 && newUploads.length === 1) {
        message = `${newUploads[0].fileName} upload started`;
      } else {
        message = `${count} uploads started`;
      }
      this.snackBar.message(message, 'TRACK').onAction().subscribe(() => {
        this.navigateToLocalUploadView();
      });
    } else {
      this.snackBar.message(
          'No uploads started. Please check your files if there are duplicates with ongoing files.');
    }
  }

  private navigateToLocalUploadView() {
    return this.router.navigate([LOCAL_UPLOAD_ROUTE_PATH], {
      queryParamsHandling: 'preserve',
    });
  }

  /** Save the local upload to window local storage. */
  private saveToLocalStorage(localUpload: LocalUpload) {
    const toBeSaved: SavedUploadPayload = {
      sessionUri: localUpload.sessionUri,
      fileName: localUpload.fileName,
      fileSize: localUpload.fileSize,
      startTime: localUpload.startTime,
      progress: localUpload.progress$.value,
      status: localUpload.status$.value,
    };
    this.saveUploadPayload(toBeSaved);
  }

  /**
   * Read the window's local storage and return the one entry with the key
   * MAM_LOCAL_UPLOAD.
   */
  private getUploadPayloads(): SavedUploadPayload[] {
    const payloadStr = this.storage.getItem(MAM_LOCAL_UPLOAD) || '[]';
    let savedUploadPayloads: SavedUploadPayload[] = [];
    try {
      savedUploadPayloads = JSON.parse(payloadStr) as SavedUploadPayload[];
    } catch {
      this.errorService.handle(new Error(
          `"${MAM_LOCAL_UPLOAD}" payload cannot be parsed: ${payloadStr}`));
      // Reset the local storage to empty array.
      this.storage.setItem(
          MAM_LOCAL_UPLOAD, JSON.stringify(savedUploadPayloads));
    }
    return savedUploadPayloads;
  }

  /**
   * Local Storage will save current uploads in a format of array.
   * Local Storage should maintain the latest upload status of a selected file
   * based on the file name.
   */
  private saveUploadPayload(toBeSaved: SavedUploadPayload) {
    const savedUploadPayloads = this.getUploadPayloads();

    const index = savedUploadPayloads.findIndex(
        (payload) => payload.fileName === toBeSaved.fileName);
    if (index >= 0) {
      // Update current payload.
      savedUploadPayloads[index] = toBeSaved;
    } else {
      savedUploadPayloads.push(toBeSaved);
    }
    this.storage.setItem(MAM_LOCAL_UPLOAD, JSON.stringify(savedUploadPayloads));
  }

  /**
   * Remove one upload based on the file name from saved payloads in Local
   * Storage.
   */
  private removeUploadPayload(fileName: string) {
    const savedUploadPayloads = this.getUploadPayloads();
    const result =
        savedUploadPayloads.filter(payload => payload.fileName !== fileName);
    this.storage.setItem(MAM_LOCAL_UPLOAD, JSON.stringify(result));
  }
}

/**
 * This is a helper function to check if selected files matches any
 * existing PAUSED or ERROR uploads.
 * If matches, set the "file" property for the upload to resume or retry later.
 * If matches but notice the file size is changed, delete the property
 * "sessionUri" for the upload to start from beginning.
 * If not matches, do nothing.
 */
function findMatchedPausedOrErrorUploads(
    currentDisplayedUploads: LocalUpload[], selectedFiles: File[]) {
  const validPausedUploads: LocalUpload[] = [];

  for (const upload of currentDisplayedUploads) {
    if (upload.status$.value !== LocalUploadStatus.PAUSED &&
        upload.status$.value !== LocalUploadStatus.ERROR) {
      // Only process PAUSED or ERROR upload.
      continue;
    }
    for (const file of selectedFiles) {
      if (file.name !== upload.fileName) {
        // Skip if selectedFile doesn't match the PAUSED upload.
        continue;
      }
      upload.file = file;
      if (file.size !== upload.fileSize) {
        // Make the upload start from beginning.
        upload.sessionUri = '';
      }
      validPausedUploads.push(upload);
    }
  }
  return validPausedUploads;
}

/**
 * This is a helper function to exclude the files from existing uploads.
 *  Files are in existing uploads which are in UPLOADING or ERROR or PAUSED
 * status should be excluded. Other status or the ones with no matched existing
 * uploads should return to generate a new upload.
 */
function findNewFilesToUpload(
    currentDisplayedUploads: LocalUpload[], selectedFiles: File[]) {
  const filesToUploadFrombeginning: File[] = [];
  for (const file of selectedFiles) {
    // exclude the ones in current displayed uploads,
    // which are in UPLOADING or ERROR or PAUSED status if the file name
    // matches. For upload which is in COMPLETED status, see uploadFiles()
    // comments scenario 3.
    const alreadyIn = currentDisplayedUploads.find(
        upload => file.name === upload.fileName &&
            (upload.status$.value !== LocalUploadStatus.COMPLETED));

    if (!alreadyIn) {
      filesToUploadFrombeginning.push(file);
    }
  }

  return filesToUploadFrombeginning;
}

/**
 * LocalUpload means an upload to the GCS bucket.
 * It can be added to the local upload table.
 */
export class LocalUpload {
  /**
   * The file associated with the upload.
   * For paused upload, the file can be undefined initially,
   * but must be assigned a value when upload resumes.
   */
  file?: File;

  /** Used to resume for PAUSED or ERROR upload. */
  sessionUri?: string;

  /** Used to display on the table. */
  readonly fileName: string = '';

  /** Used to display on the table. Can be overrided during retry and resume. */
  fileSize = 0;

  /** Used to display the startTime of the upload. */
  readonly startTime: number = 0;

  /** Emit to cancel the current upload. */
  readonly cancelled$ = new Subject<void>();

  /** Emit to update the current upload progress. */
  readonly progress$ = new BehaviorSubject(0);

  /** Emit to update the current upload status. */
  readonly status$ = new BehaviorSubject(LocalUploadStatus.UPLOADING);

  /** Emit to update username who triggered this local upload */
  readonly user$ = new BehaviorSubject('');

  /** Display the useful error message to users when upload failed. */
  errorMessage? = '';

  constructor(
      private readonly resumableUploadService: ResumableUploadService,
      private readonly errorService: ErrorService,
      file?: File,
      readonly savedUploadPayload?: SavedUploadPayload,
      private readonly dataService?: FirebaseFirestoreDataService,
      private readonly isUserShown = false
  ) {
    assertTruthy(
        file || savedUploadPayload,
        'Both file and savedUploadPayload cannot be undefined');

    if (savedUploadPayload) {
      // For saved upload, the items from local storage is the source of
      // truth.
      const {
        fileName,
        fileSize,
        progress,
        status,
        sessionUri,
        startTime,
        errorMessage,
      } = savedUploadPayload;
      this.progress$.next(progress);
      this.status$.next(status);
      this.fileName = fileName;
      this.fileSize = fileSize;
      this.startTime = startTime;
      this.sessionUri = sessionUri;
      this.errorMessage = errorMessage;
    } else if (file) {
      // For new upload, the selected file is the source or truth.
      this.file = file;
      this.fileName = file.name;
      this.fileSize = file.size;
      this.startTime = Date.now();
    }

    if (isUserShown){

      this.dataService?.retrieveIASEventForLocalUpload(this.fileName)
        .subscribe( events => {
            if (events.length) {
              this.user$.next(events[0].username ?? '');
            }
          }
        );
    }
  }

  /**
   * Cancel current upload.
   * TODO: Should delete the session URI.
   */
  cancel() {
    this.cancelled$.next();
  }

  /**
   * Start the current upload by calling LocalUploadService.
   * If the upload is resumed from PAUSED upload, resume the upload from where
   * it left. If the upload is new, start the upload from beginning.
   * Upon subscription, update corresponding subjects.
   * Save current upload into LocalStorage.
   */
  start() {
    this.resumableUploadService.upload(castExists(this.file), this.sessionUri)
        .pipe(takeUntil(this.cancelled$))
        .subscribe({
          next: ({isComplete, startOffset, sessionUri}) => {
            if (isComplete) {
              this.status$.next(LocalUploadStatus.COMPLETED);
            } else {
              this.status$.next(LocalUploadStatus.UPLOADING);
            }
            this.progress$.next(
                Math.floor((startOffset / this.fileSize) * 100));
            this.sessionUri = sessionUri;
          },
          error: (err: Error) => {
            this.errorMessage = (err instanceof ApiError && err.status === StatusCode.CONFLICT) ?
              UploadError.CONFLICT :
              UploadError.SERVER_ERROR;
            this.status$.next(LocalUploadStatus.ERROR);
            this.errorService.handle(err);
          }
        });
  }
}

/** Status of a local upload */
export enum LocalUploadStatus {
  PAUSED,
  UPLOADING,
  COMPLETED,
  ERROR,
}

/** Error types and message for local upload. */
enum UploadError {
  /** Any Server errors including 5** and session URI is not returned. */
  SERVER_ERROR = 'Server error',
  /** This indicates that file already exists in GCS bucket. */
  CONFLICT = 'File already exists in GCS bucket',
}

/** Upload payload saved in window Local Storage. */
export interface SavedUploadPayload {
  /**
   * The previous session Uri of resumable upload.
   * Can be undefined if status is in error.
   */
  sessionUri?: string;

  /** The name of the file. */
  fileName: string;

  /** The total size of the file. */
  fileSize: number;

  /** The progress of saved upload.  */
  progress: number;

  /** The status of saved upload.  */
  status: LocalUploadStatus;

  /** The error message of saved upload if status is in error. */
  errorMessage?: string;

  /** The time when the upload started originally. */
  startTime: number;
}
