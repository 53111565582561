import {Injectable} from '@angular/core';
import {map, Observable, throwError} from 'rxjs';

import {ApiFile, ApiFolder, ApiFoldersListFoldersResponse} from 'api/ias/model/models';
import {FileResource, Folder} from 'models';

import {environment} from '../environments/environment';

import {IasApiClient} from './api_client.module';

import FolderTypeEnum = ApiFolder.TypeEnum;

/** No more than this number of export folders will be loaded. */
const MAX_EXPORT_FOLDERS_PAGE_SIZE = 200;

/** IAS backend media cache related APIs. */
@Injectable({providedIn: 'root'})
export class MediaCacheApiService {
  constructor(
      private readonly apiClient: IasApiClient,
  ) {}

  /**
   * Fetches a file resource based on the given filename. If it does not exist,
   * creates it and check its GCS location based on the `gcsLocation` parameter,
   * and if there is no file at this cloud location, the file fails to be
   * created and returns a `FAILED_PRECONDITION` error. In case of a live stream
   * not ended yet, `gcsLocation` will be empty and this call will error out.
   * The `onpremLocation` property will be `null`, see `locateFile`.
   */
  getOrCreateFile(
      siteId: string, folderId: string, filename: string,
      gcsLocation: string): Observable<FileResource> {
    if (!filename) {
      return throwError(
          () => new Error('Empty filename given to files:getOrCreate'));
    }
    try {
      // We use `getOrCreate` instead of `get` so that the backend can create
      // the file metadata if it has not been queried before.
      return this.apiClient
          .sitesFoldersFilesGetOrCreate({
            name: buildFilePath(siteId, folderId, filename),
            body: {gcsLocation},
          })
          .pipe(map(f => new FileResource(f)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  /**
   * Fetches a file resource. If the file resource was not created yet, it will
   * fail with a NOT_FOUND error. The `onpremLocation` property will be `null`,
   * see `locateFile`.
   */
  getFile(siteId: string, folderId: string, filename: string):
      Observable<FileResource> {
    if (!filename) {
      return throwError(() => new Error('Empty filename given to files:get'));
    }
    try {
      const name = buildFilePath(siteId, folderId, filename);
      return this.apiClient.sitesFoldersFilesGet({name}).pipe(
          map(f => new FileResource(f)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  /** Queues the download of a file from cloud to on-prem. */
  download(siteId: string, folderId: string, filename: string):
      Observable<FileResource> {
    if (!filename) {
      return throwError(() => new Error('Empty filename given to files:download'));
    }
    try {
      const name = buildFilePath(siteId, folderId, filename);
      return this.apiClient.sitesFoldersFilesDownload({name}).pipe(
          map(f => new FileResource(f)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  /** Lists folders available for a given site, up to a maximum of 200 */
  listFolders(siteId: string, folderType?: FolderTypeEnum, single = false) {
    const pageSize = single ? 1 : MAX_EXPORT_FOLDERS_PAGE_SIZE;
    let filter = '';

    if (folderType) {
      // The API requires the enum number.
      const typeEnum = Object.values(FolderTypeEnum).indexOf(folderType);
      filter = `folderType=${typeEnum}`;
    }

    try {
      const parent = getSitePath(siteId);
      return this.apiClient.sitesFoldersList({parent, filter, pageSize})
          .pipe(map(convertToUiFolderListResponse));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  /** Fetches a file resource with its `onpremLocation` property set.  */
  locateFile(siteId: string, folderId: string, filename: string):
      Observable<FileResource> {
    if (!filename) {
      return throwError(() => new Error('Empty filename given to files:locate'));
    }
    try {
      const name = buildFilePath(siteId, folderId, filename);
      return this.apiClient.sitesFoldersFilesLocate({name}).pipe(
          map(f => new FileResource(f)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  /** Deletes an onprem file asynchronously */
  removeOnpremFile(name: string) {
    try {
      return this.apiClient.sitesFoldersFilesRemove({name}).pipe(
          map(resp => ({file: resp.file && new FileResource(resp.file)})));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  /** Update on-prem file TTL */
  updateFileTtl(name: string, file: ApiFile): Observable<FileResource> {
    try {
      return this.apiClient
          .sitesFoldersFilesPatch({
            name,
            body: file,
            updateMask: 'lifecycleInfo',
          })
          .pipe(map(f => new FileResource(f)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }
}

function getSitePath(siteId: string) {
  return `${environment.mamApi.parent}/sites/${siteId}`;
}

/** Returns the file path with the site and folder. */
export function buildFilePath(
    siteId: string, folderId: string, filename: string) {
  // ESF (part of onePlatform) converts http requests to RPC. We encode the
  // filename to avoid from losing correct filename.
  const encodedFileName = encodeURIComponent(filename);

  return `${getSitePath(siteId)}/folders/${folderId}/files/${encodedFileName}`;
}

function convertToUiFolderListResponse(response: ApiFoldersListFoldersResponse):
    FoldersListFoldersResponse {
  return {
    folders: (response.folders ?? []).map(f => new Folder(f)),
    nextPageToken: response.nextPageToken
  };
}

/** ListFolders response */
export interface FoldersListFoldersResponse {
  folders: Folder[];
  nextPageToken?: string;
}
