import {inject, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';

import {AdminLanding} from '../admin/admin_landing';
import {Details} from '../details/details';
import {DetailsModule} from '../details/details_module';
import {Home} from '../home/home';
import {HomeModule} from '../home/home_module';
import {Landing} from '../landing/landing';
import {LandingModule} from '../landing/landing_module';
import {LiveLanding} from '../live/live_landing';
import {Login} from '../login/login';
import {LoginModule} from '../login/login_module';
import {AuthAdminGuard, AuthGuard} from '../services/auth.guard';
import {SharedLinkClipBin} from '../shared_clipbin/shared_link_clipbin';
import {SharedLink} from '../shared_link/shared_link';
import {Static} from '../static/static';
import {StaticModule} from '../static/static_module';
import {TransferLanding} from '../transfer_monitor/transfer_landing';
import {TransferMonitorModule} from '../transfer_monitor/transfer_monitor_module';

declare interface TransferLandingRouteData {
  transferView: 'jobs'|'staging';
}

const routes: Routes = [
  {path: 'login', component: Login},
  // Redirect the deprecated "details" URLs to "assets" URLs for backward
  // compatibility.
  {
    path: 'details/:assetname',
    redirectTo: 'asset/:assetname',
  },
  {path: 'unauthorized', component: Static, data: {'type': 'unauthorized'}},
  {path: 'error', component: Static, data: {'type': 'error'}},
  // Details of a shared video link, publicly available.
  {
    path: 'shared/:linkhash',
    component: SharedLink,
    data: {'type': 'shared'},
    children: [
      {
        path: '',
        component: Details,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'shared-clipbin/:clipbinname/clip/:linkhash',
    component: SharedLinkClipBin,
    data: {'type': 'shared'},
    children: [
      {
        path: '',
        component: Details,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'shared-clipbin/:clipbinname',
    redirectTo: 'shared-clipbin/:clipbinname/clip/0',
  },
  // Home page
  {
    path: '',
    component: Home,
    canActivate: [(r: ActivatedRouteSnapshot, s: RouterStateSnapshot) => inject(AuthGuard).canActivate(r, s)],
    children: [
      // Landing View
      {
        path: '',
        component: Landing,
        pathMatch: 'full',
      },
      // Landing View
      {
        path: 'folders/:folderId',
        component: Landing,
        pathMatch: 'full',
      },
      // Details of a full asset
      {
        path: 'asset/:assetname',
        component: Details,
        pathMatch: 'full',
      },
      // Details of a clip
      {
        path: 'clipbin/:clipbinname/clip/:assetname',
        component: Details,
        pathMatch: 'full',
      },
      // Details of a clipbin, will default to its first clip
      {
        path: 'clipbin/:clipbinname',
        redirectTo: 'clipbin/:clipbinname/clip/0',
      },
      // Alternative syntax for the details of a clipbin
      {
        path: 'clipbin/:clipbinname/clip',
        redirectTo: 'clipbin/:clipbinname/clip/0',
      },
      // Live View
      {
        path: 'live',
        component: LiveLanding,
        pathMatch: 'full',
      },
      // Job Monitor View
      {
        path: 'jobs',
        children: [
          {
            path: '',
            redirectTo: 'transfers',
            pathMatch: 'full',
          },
          {
            path: ':type',
            component: TransferLanding,
          },
        ],
        data: {'transferView': 'jobs'} as TransferLandingRouteData
      },
      // Staging Monitor View
      {
        path: 'staging',
        children: [
          {
            path: '',
            redirectTo: 'live',
            pathMatch: 'full',
          },
          {
            path: ':type',
            component: TransferLanding,
          },
        ],
        data: {'transferView': 'staging'} as TransferLandingRouteData
      },
      // Admin View
      {
        path: 'admin',
        canActivate: [() => inject(AuthAdminGuard).canActivate()],
        children: [
          {
            path: '',
            redirectTo: 'users',
            pathMatch: 'full',
          },
          {
            path: ':type',
            component: AdminLanding,
          },
        ],
      },
    ]
  },
  // Maintenance page
  {path: 'maintenance', component: Static, data: {'type': 'maintenance'}},
  // Any other URL redirects to 404 page
  {path: '**', component: Static, data: {'type': 'not_found'}},
];

@NgModule({
  imports: [
    HomeModule,
    LandingModule,
    DetailsModule,
    TransferMonitorModule,
    LoginModule,
    StaticModule,
    RouterModule.forRoot(routes),
  ],
  providers: [
    AuthAdminGuard,
    AuthGuard,
  ],
  exports: [RouterModule],
})
export class RoutingModule {
}
