import {ApiSnippet} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

/** A UI type representing an asset snippet (asset general information) */
export class Snippet implements RequiredRecursively<ApiSnippet> {
  captureTime = this.apiSnippet.captureTime ?? '';
  description = this.apiSnippet.description ?? '';
  duration = this.apiSnippet.duration ?? '';
  eventTime = this.apiSnippet.eventTime ?? [];
  startTimecode = this.apiSnippet.startTimecode ?? '';
  title = this.apiSnippet.title ?? '';

  constructor(private apiSnippet: ApiSnippet = {}) {}
}
