
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import {firstValueFrom} from 'rxjs';

import {environment} from '../environments/environment';
import {ErrorService} from '../error_service/error_service';
import {PluginService} from '../plugin/plugin_service';

interface Profile {
  email?: string;
  picture?: string;
  name?: string;
}

/**
 * Remaining duration of an access token in milliseconds below which a new token
 * should be generated using the current refresh token.
 */
const MIN_ACCESS_TOKEN_TIME_REMAINING_SEC = 30 * 1000;

/**
 * Used to store in the current session the URL that a user tried to access.
 * If they were not logged in, they will be redirected to an OAuth page before
 * they can access their requested URL.
 */
const REQUESTED_URL_KEY = 'requested-url';

const PROFILE_STORAGE_KEY = 'ias_mam_profile';

// List of email domains which represent internal users. Default is an empty array.
const INTERNAL_USER_EMAILS: string[] = [];

/** Injection token for function that reloads the current url. */
export const RELOAD_PAGE = new InjectionToken<() => void>('Reload page', {
  factory: () => (resetCurrentUrl = false) => {
    if (resetCurrentUrl) {
      window.location.href = '';
      return;
    }
    window.location.reload();
  }
});

/**
 * AuthService to take care of the authentication and authorization using Google
 * OAuth2 client supported by GAPI.
 */
@Injectable({providedIn: 'root'})
export abstract class AuthService {
  isUnauthorized = false;

  isAdmin = false;

  /**
   * Timestamp in milliseconds when the user was last authorized.
   * `0` indicates not authorized, or not yet.
   */
  authorizedTime = 0;

  constructor(
    /** Hard reloads the current url. */
    @Inject(RELOAD_PAGE) readonly reloadPage:
      (resetCurrentUrl?: boolean) => void,
    protected readonly pluginService: PluginService,
    protected readonly errorService: ErrorService,
    protected readonly http: HttpClient,
  ) {
  }

  abstract isPlugin(): boolean;

  getUserIcon(): string {
    return this.getUserProfile()?.picture || '';
  }

  /** Enables extra features for internal users. */
  isInternalUser(): boolean | undefined {
    const email = this.getUserProfile()?.email;
    if (!email) return undefined;
    return INTERNAL_USER_EMAILS.some(domain => email.endsWith(domain));
  }

  /** Returns the current access token, which may be expired. */
  abstract getAccessToken(): string;
  protected abstract refreshAuthIfTokenExpired(minTimeRemaining: number):
    Promise<void>;
  protected abstract isLoggedInInternal(): Promise<boolean>;

  /**
   * Returns the current access token if it is still valid, otherwise refresh
   * it first if expired.
   */
  async getActiveAccessToken() {
    // If a token refresh in ongoing, wait until it completes
    if (this.refreshingAuthTokenIfExpired) {
      await this.refreshingAuthTokenIfExpired;
    }

    // Initiate token refresh (if needed)
    this.refreshingAuthTokenIfExpired =
      this.refreshAuthIfTokenExpired(MIN_ACCESS_TOKEN_TIME_REMAINING_SEC).finally(() => {
        // Once token is refreshed (or failed), allow the next request to retry.
        this.refreshingAuthTokenIfExpired = undefined;
      });

    // Wait for token refresh before returning access token.
    await this.refreshingAuthTokenIfExpired;

    return this.getAccessToken();
  }

  private refreshing = false;

  saveRequestedUrl(url: string | null) {
    if (!url) {
      window.sessionStorage.removeItem(REQUESTED_URL_KEY);
    } else {
      window.sessionStorage.setItem(REQUESTED_URL_KEY, url);
    }
  }

  loadRequestedUrl() {
    return window.sessionStorage.getItem(REQUESTED_URL_KEY);
  }

  getUserEmail(): string {
    return this.getUserProfile()?.email?.toLowerCase() || '';
  }

  getUserName(): string {
    return this.getUserProfile()?.name || '';
  }

  /**
   * Logs out from Google and reloads the page to login screen.
   *
   * Note: By default reloads current url instead of going to '/login' or to ''
   * which makes AuthGuard to store current url in Session storage before
   * redirecting to the login page. This works only for routes that are
   * protected by the AuthGuard. For unprotected routes or if remembering the
   * current url is not needed set `resetCurrentUrl` to `true`.
   */
  async logout(resetCurrentUrl = false): Promise<void> {
    if (this.isLoggingOut) return;

    // Force checking for authorization status upon next log-in.
    this.authorizedTime = 0;
    if (this.doNotUseOAuth()) return;

    // If user was already logged in, we may have entered an infinite
    // loop so we abort here.
    if (!(await this.isLoggedIn())) {
      this.errorService.handle(
        'Attempt to log-out user that was not logged-in');
      return;
    }

    try {
      this.clearAuthStorage();
    } catch (error) {
      this.errorService.handle('Error during log-out: ' + String(error));
    }

    this.isLoggingOut = true;

    this.reloadPage(resetCurrentUrl);
  }

  /** Checks if it is already login. */
  async isLoggedIn() {
    if (this.doNotUseOAuth()) return true;
    return this.isLoggedInInternal();
  }

  protected encodePayload(payload: object): string {
    return btoa(JSON.stringify(payload));
  }

  protected decodePayload<T extends object>(value: string): T | null {
    if (!value) return null;

    try {
      const decoded = atob(value);
      return JSON.parse(decoded) as T;
    } catch (error) {
      this.errorService.handle(`Failed to decode payload: ${error} | ${value}`);
      this.clearAuthStorage();
      return null;
    }
  }

  protected clearAuthStorage() {
    localStorage.removeItem(PROFILE_STORAGE_KEY);
  }

  protected async loadUserProfile(accessToken: string) {
    try {
      const discovery = await firstValueFrom(this.http.get('https://accounts.google.com/.well-known/openid-configuration')) as Record<string, string>;
      const profile = await firstValueFrom(this.http.get(discovery['userinfo_endpoint'], {
        responseType: 'json',
        headers: {'Authorization': `Bearer ${accessToken}`},
      })) as Profile;

      localStorage.setItem(PROFILE_STORAGE_KEY, this.encodePayload(profile));
    } catch (error) {
      this.errorService.handle(`Failed to fetch user profile: ${error}`);
    }
  }
  /**
   * Allows ignoring any incoming logout requests while the logout is in
   * progress.
   */
  private isLoggingOut = false;

  /**
   * Used to queue multiple calls to `refreshAuthIfTokenExpired` while one in
   * being processed.
   */
  private refreshingAuthTokenIfExpired?: Promise<unknown>;

  private doNotUseOAuth() {
    return !environment.clientId;
  }

  private getUserProfile() {
    const profileString = localStorage.getItem(PROFILE_STORAGE_KEY);
    if (!profileString) return null;

    return this.decodePayload<Profile>(profileString);
  }

  async readAdminStatus() {
    const email = this.getUserEmail();
    const url = `/users/isAdmin?email=${encodeURIComponent(email)}`;
    return firstValueFrom(this.http.get<boolean>(url))
        .then(response => this.isAdmin = response)
        .catch(error => this.errorService.handle(`Can't determine user's admin status: ${error}`));
  }
}
