<button
    *ngIf="segment || isVideoShareable() || isVideoDeletable()"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    class="gmat-button"
    aria-label="Open asset extra actions"
    (click)="$event.stopPropagation()"
    mam-firebase-ga="Open asset extra actions menu"
    [mam-firebase-resource]="asset.name">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" class="asset-extra-actions-menu">
  <!-- Add clip (VoD only) -->
  <div
      *ngIf="!asset.isLive"
      [matTooltip]="videoDisabledMessage"
      [matTooltipDisabled]="!isVideoDeleted()">
    <button
        [disabled]="isVideoDeleted()"
        mat-menu-item
        [mam-firebase-ga]="getFirebaseGa()"
        [mam-firebase-resource]="asset.name"
        (click)="openAddClipDialog()">
      <mat-icon>subscriptions</mat-icon>
      {{segment?.searchMode === SearchMode.SEGMENT ? 'Add enhanced clip' :
      'Add full title' }}
    </button>
  </div>

  <!-- Export (Origin VoD asset only) -->
  <div
      *ngIf="isVideoExportable()"
      [matTooltip]="videoDisabledMessage"
      [matTooltipDisabled]="!isVideoDeleted()">
    <button
        [disabled]="isVideoDeleted()"
        mat-menu-item
        [mam-firebase-ga]="getFirebaseGa()"
        [mam-firebase-resource]="asset.name"
        (click)="openExportAssetDialog()">
      <mat-icon>arrow_outward</mat-icon>
      Export
    </button>
  </div>

  <!-- Share video -->
  <div
      [matTooltip]="videoDisabledMessage"
      [matTooltipDisabled]="!isVideoDeleted()">
    <button
        *ngIf="isVideoShareable()"
        [disabled]="isVideoDeleted()"
        mat-menu-item
        mam-firebase-ga="Share video from extra actions"
        [mam-firebase-resource]="asset.name"
        (click)="shareVideo()">
      <mat-icon>share</mat-icon>Share {{asset.original && asset.original.duration.toFixed(0) !== asset.duration.toFixed(0) ? "clip" : "video"}}
    </button>
  </div>

  <!-- Delete asset  -->
  <div
      [matTooltip]="videoDisabledMessage"
      [matTooltipDisabled]="!isVideoDeleted()">
    <button
        [disabled]="isVideoDeleted()"
        *ngIf="isVideoDeletable()"
        class="delete-button"
        [class.disabled]="isVideoDeleted()"
        mat-menu-item
        mam-firebase-ga="Delete asset"
        [mam-firebase-resource]="asset.name"
        (click)="openDeleteAssetDialog()">
      <mat-icon>delete</mat-icon>Delete asset
    </button>
  </div>
</mat-menu>
