import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {FeatureFlagModule} from '../feature_flag/feature_flag_module';
import {FirebaseModule} from '../firebase/firebase_module';
import {PipesModule} from '../pipes/pipes_module';
import {PlayerModule} from '../player/player_module';
import {RightPanelModule} from '../right_panel/right_panel_module';
import {SharedModule} from '../shared/shared_module';

import {Details} from './details';
import {DetailsBreadcrumb} from './details_breadcrumb';
import {MultiCameraView} from './multi_camera_view';

@NgModule({
  declarations: [
    Details,
    DetailsBreadcrumb,
    MultiCameraView,
  ],
  imports: [
    ClipboardModule,    CommonModule,        FeatureFlagModule,
    FirebaseModule,     MatCardModule,       MatOptionModule,
    MatButtonModule,    MatChipsModule,      MatDividerModule,
    MatFormFieldModule, MatIconModule,       MatInputModule,
    MatMenuModule,      MatOptionModule,     MatSelectModule,
    MatToolbarModule,   MatTooltipModule,    PipesModule,
    PlayerModule,       ReactiveFormsModule, RightPanelModule,
    RouterModule,       SharedModule,
  ],
  exports: [
    Details,
    DetailsBreadcrumb,
  ]
})
export class DetailsModule {
}
