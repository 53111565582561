import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';

import {ApiMetadataSchema} from 'api/ias/model/models';
import {castExists} from 'asserts/asserts';

import {environment} from '../environments/environment';
import {ErrorService} from '../error_service/error_service';

import {IasApiClient} from './api_client.module';

/**
 * SchemaApiService to interact with IAS backend schema related APIs
 */
@Injectable({providedIn: 'root'})
export class SchemaApiService {
  constructor(
      private readonly apiClient: IasApiClient,
      private readonly errorService: ErrorService,
  ) {}

  getSchema(uri: string): Observable<MetadataSchema> {
    try {
      return this.apiClient.metadataSchemasGet({name: uri})
          .pipe(map(
              schema => covertToUiMetadataSchema(schema, this.errorService)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  list(pageSize: number, pageToken = ''): Observable<MetadataSchema[]> {
    try {
      return this.apiClient
          .metadataSchemasList(
              {parent: environment.mamApi.parent, pageSize, pageToken})
          .pipe(
              map(response => (response.metadataSchemas ?? [])
                                  .map(
                                      apiSchema => covertToUiMetadataSchema(
                                          apiSchema, this.errorService))));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }
}

/** Converts asset media metadata schema to client-friendly representation. */
export function covertToUiMetadataSchema(
    apiSchema: ApiMetadataSchema, errorService?: ErrorService): MetadataSchema {
  const jsonSchema: JsonSchema = apiSchema.jsonSchema ?? {};

  const requiredProperties = new Set(jsonSchema.required || []);
  const displayOrders = new Map(
      jsonSchema.displayOrder?.map((value, index) => ([value, index])) || []);
  const apiProperties: Record<string, Property> = jsonSchema.properties || {};

  const properties: MetadataProperties = {};
  const supportedPropertyTypes =
      new Set([...Object.values(PropertyType), API_ARRAY_TYPE]);

  // When no displayOrder is provided, all fields will be listed alphabetically.
  const alphabeticalKeys = Object.keys(apiProperties).sort((propA, propB) => {
    return propA.localeCompare(propB);
  });

  for (const [key, apiProperty] of Object.entries(apiProperties)) {
    const required = requiredProperties.has(key);

    // The field will be hidden if displayOrder is -1
    let displayOrder = -1;

    // Use the order provided by displayOrders if it exists.
    if (displayOrders.has(key)) {
      displayOrder = castExists(displayOrders.get(key));
    }
    // When displayOrders is empty, use the alphabetical order
    else if (!displayOrders.size) {
      displayOrder = alphabeticalKeys.indexOf(key);
    }
    // When a field is required but was not in displayOrders, we force display
    // after the fields present in displayOrders. This should not happen and
    // is added as a failsafe.
    else if (required) {
      displayOrder = displayOrders.size + alphabeticalKeys.indexOf(key);
    }

    let type = apiProperty.format || apiProperty.type;
    let apiEnum = getApiEnum(apiProperty);
    let isArray = false;

    if (!supportedPropertyTypes.has(type)) {
      errorService?.handle(`Unsupported metadata property type "${
          type}". Property ${key}, schema: ${apiSchema.name}`);
      type = PropertyType.STRING;
    } else if (type === API_ARRAY_TYPE) {
      isArray = true;
      type = apiProperty.items?.format || apiProperty.items?.type ||
          PropertyType.STRING;
      apiEnum = getApiEnum(apiProperty.items);

      if (type === API_ARRAY_TYPE) {
        errorService?.handle(
            `Metadata Items of type "array" are not supported. Property ${
                key}, schema: ${apiSchema.name}`);
        continue;
      } else if (!supportedPropertyTypes.has(type)) {
        errorService?.handle(`Unsupported metadata array property type "${
            type}". Property ${key}, schema: ${apiSchema.name}`);
        type = PropertyType.STRING;
      }
    }

    // If non-empty, the UI will display a dropdown selector for these options
    // rather than an input.
    let options: string[]|undefined = undefined;

    // If the API provides enum of numbers, convert it to string for the UI
    // since it does not make a difference visually.
    if (apiEnum) {
      options = apiEnum?.map(opt => String(opt));
    }
    // Boolean values are represented as a dropdown choice between TRUE and
    // FALSE.
    else if (type === PropertyType.BOOLEAN) {
      options = ['TRUE', 'FALSE'];
    }

    // If the value is not required, let users select empty value. In case of
    // array, the value can be removed from the array so this isn't necessary.
    if (options && !required && !isArray) {
      options.unshift('');
    }

    const propertyType = type as PropertyType;
    const defaultValue =
        processValue(apiProperty.default, propertyType, isArray, options);

    properties[key] = {
      id: key,
      title: apiProperty.title,
      type: propertyType,
      defaultValue,
      options,
      isArray,
      displayOrder,
      required,
      visible: displayOrder >= 0,
    };
  }

  const apiScope = apiSchema.labels?.['scope'] as SchemaScope;
  const apiSubScope = apiSchema.labels?.['sub_scope'] as SchemaSubScope;

  return {
    name: apiSchema.name ?? '',
    title: jsonSchema.title || '',
    description: apiSchema.description ?? '',
    createTime: Date.parse(apiSchema.createTime ?? ''),
    updateTime: apiSchema.updateTime ? Date.parse(apiSchema.updateTime) :
                                       undefined,
    properties,
    displayOrder: jsonSchema.displayOrder ?? [],
    scope: KNOWN_SCHEMA_SCOPES.includes(apiScope) ? apiScope : undefined,
    subScope: KNOWN_SCHEMA_SUB_SCOPES.includes(apiSubScope) ? apiSubScope :
                                                              undefined,
    hidden: apiSchema.labels?.['hidden']?.toLowerCase() === 'true',
    eventDescription: apiSchema?.segmentMetadata?.jsonMetadata?.event_description
  };
}

/** Converts value to client value. Filters out values not present in options */
function processValue(
    value: unknown, type: PropertyType, isArray: boolean,
    options?: string[]): string|string[]|undefined {
  if (value == null) return undefined;

  // For array fields process each item individually.
  if (isArray) {
    if (!Array.isArray(value)) return undefined;
    return value.map(item => processValue(item, type, false, options) as string)
        .filter(value => value != null);
  }

  let processedValue = '';
  if (type === PropertyType.BOOLEAN) {
    // Booleans are represented as upper-cased strings (e.g. line 121).
    processedValue = value ? 'TRUE' : 'FALSE';
  } else {
    // Convert other types (e.g. numbers) to string.
    processedValue = String(value);
  }

  // If options are provided then verify that they contain default value.
  return (!options || options.includes(processedValue)) ? processedValue :
                                                          undefined;
}

/**
 * Extracts allowed enum values from either metadata property or from `items`
 * section.
 */
function getApiEnum(value?: EnumDefinition): unknown[]|undefined {
  if (!value?.const && !value?.enum) return undefined;

  if (value.const) {
    if (!value.enum) return [value.const];
    // Check if enum values intersect with const value.
    return value.enum.includes(value.const) ? [value.const] : [];
  }

  return value.enum;
}

const API_ARRAY_TYPE = 'array';

const KNOWN_SCHEMA_SCOPES = ['asset', 'annotated_segment'] as const;
type SchemaScope = typeof KNOWN_SCHEMA_SCOPES[number];

const KNOWN_SCHEMA_SUB_SCOPES = ['cutdown'] as const;
type SchemaSubScope = typeof KNOWN_SCHEMA_SUB_SCOPES[number];

/** Asset media metadata schema. */
export interface MetadataSchema {
  name: string;
  title: string;
  description: string;
  properties: MetadataProperties;
  createTime: number;
  updateTime?: number;
  displayOrder: string[];
  scope?: SchemaScope;
  subScope?: SchemaSubScope;
  hidden?: boolean;
  eventDescription?: string;
}

/** Defines properties of asset media metadata fields. */
export type MetadataProperties = Record<string, MetadataProperty>;

/** Defines properties of a given asset media metadata field. */
export interface MetadataProperty {
  id: string;
  title: string;
  type: PropertyType;
  /** When true indicates that the property contains an array of values. */
  isArray: boolean;
  displayOrder: number;
  visible: boolean;
  required: boolean;
  /** Possible set of values to select from. */
  options?: string[];
  defaultValue?: string|string[];
}

/**
 * Better typing for `ApiMetadataSchema.jsonSchema` which have a generic
 * `ApiClientObjectMap<any>`. Must be kept up to date with BE definition.
 * `declare` to avoid closure property renaming.
 */
declare interface JsonSchema {
  title?: string;
  required?: string[];
  displayOrder?: string[];
  properties?: Record<string, Property>;
}

type ApiPropertyValue = string|number|boolean|string[]|number[]|boolean[];

/**
 * Better typing for `ApiMetadataSchema.jsonSchema.properties`. Must be kept up
 * to date with BE definition. `declare` to avoid closure property renaming.
 */
declare interface Property {
  title: string;
  type: string;
  format?: string;
  enum?: string[]|number[];
  const ?: string|number;
  default?: ApiPropertyValue;
  items?: {
    type: string,
    format?: string,
    enum?: string[]|number[],
    const ?: string|number;
  };
}

/** Describes possible predefined options for a metadata field. */
declare interface EnumDefinition {
  enum?: unknown[];
  const ?: unknown;
}

/** Contains all supported types of asset media metadata fields. */
export enum PropertyType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATE_TIME = 'date-time',
  NUMBER = 'number',
  INTEGER = 'integer',
}
