import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {finalize, first, map, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';

import {MediaCacheService} from '../services/media_cache_service';
import {SnackBarService} from '../services/snackbar_service';
import {TransferRow, TransferRowFilter, TransferRowFilterChange, TransferRowSort, TransferService} from '../services/transfer_service';

/** Fixed page size of the transfers table. */
export const PAGE_SIZE = 30;

/**
 * Transfer Monitor page. Controls task transfer table.
 */
@Component({
  selector: 'mam-transfer-monitor',
  templateUrl: './transfer_monitor.ng.html',
  styleUrls: ['./transfer_monitor.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferMonitor implements OnInit, OnDestroy {
  /** Used for pagination of the transfers table. */
  readonly currentPage$ = new BehaviorSubject({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  });

  currentSort: TransferRowSort = {active: 'modifiedTime', direction: 'desc'};
  currentFilter: TransferRowFilter = {};

  /** Page of rows and total size from current search. */
  readonly searchResponse$ = this.getSearchResponse();

  readonly selectableSites$ = this.mediaCache.state.selectableSites$;

  constructor(
      private readonly cdr: ChangeDetectorRef,
      private readonly snackbar: SnackBarService,
      private readonly mediaCache: MediaCacheService,
      readonly transferService: TransferService,
  ) {}

  ngOnInit() {
    this.transferService.transferSelectedSite$.pipe(takeUntil(this.destroyed))
        .subscribe(() => {
          this.updateTable();
          this.cdr.markForCheck();
        });

    // Refresh table every time stats are updated.
    this.transferService.stats$.pipe(takeUntil(this.destroyed))
        .subscribe(() => {
          this.refreshCurrentPage();
        });
  }

  retryTask(taskName: string) {
    this.transferService.retryTask(taskName).subscribe((task) => {
      if (!task) {
        this.snackbar.error('Failed to retry task');
      }
      // Refetch current page, even if retry failed.
      this.refreshCurrentPage();
    });
  }

  /** Resets the table to first page with new sort order.  */
  onSort(sort: Sort) {
    this.currentSort = sort as TransferRowSort;
    this.updateTable();
  }

  /** Resets the table to first page, applies new filters. */
  onFilter({type, value}: TransferRowFilterChange) {
    if (this.currentFilter[type] === value) {
      return;
    }

    this.currentFilter = {
      ...this.currentFilter,
      [type]: value,
    };
    this.updateTable();
  }

  /** Sets page which this will trigger an API call and redraw the table. */
  private updateTable(pageIndex = 0) {
    this.currentPage$.next({
      pageIndex,
      pageSize: this.currentPage$.value.pageSize,
    });
  }

  /** Resets page which this will trigger an API call and redraw the table. */
  private refreshCurrentPage() {
    this.updateTable(this.currentPage$.value.pageIndex);
  }

  /**
   * Observe changes to the selected site and the current page, and emits the
   * list of tasks for the current page, as well as their total size.
   */
  private getSearchResponse():
      Observable<{rows: TransferRow[], totalSize: number}> {
    return this.currentPage$.pipe(
        tap(() => {
          this.transferService.processing$.next(true);
        }),
        switchMap(page => {
          return this.transferService.transferSelectedSite$.pipe(
              first(),
              map(site => ({page, site})),
          );
        }),
        switchMap(({page, site}) => {
          return this.transferService.searchTasks(
              site.siteId,
              page.pageIndex,
              page.pageSize,
              this.currentFilter,
              this.currentSort,
          );
        }),
        map(response => {
          if (!response) {
            this.snackbar.error('Failed to get transfer tasks');
            response = {rows: [], totalSize: 0};
          }
          return response || {rows: [], totalSize: 0};
        }),
        tap(() => {
          this.transferService.processing$.next(false);
        }),
        finalize(() => {
          this.transferService.processing$.next(false);
        }),
        shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  private readonly destroyed = new ReplaySubject<void>(1);
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
