import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {skip, take} from 'rxjs/operators';

import {ClipBinSelection} from '../right_panel/clip_bin_selection';
import {AssetService, Clip} from '../services/asset_service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';

interface TrimClipDialogData {
  clip: Clip,
  prevClip?: Clip,
  nextClip?: Clip,
  comp: ClipBinSelection,
}

/** Clip trimming dialog */
@Component({
  selector: 'mam-trim-clip-dialog',
  templateUrl: './trim_clip_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrimClipDialog implements OnInit {
  constructor(
      readonly dialogRef: MatDialogRef<TrimClipDialog, boolean>,
      private readonly snackBar: SnackBarService,
      private readonly assetService: AssetService,
      private readonly stateService: StateService,
      @Inject(MAT_DIALOG_DATA) public data: TrimClipDialogData) {}

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(confirmed => {
      if (!confirmed) {
        return;
      }

      const clipMarking = this.stateService.clipMarking$.getValue();
      if (!clipMarking) {
        return;
      }

      const {clip, prevClip, nextClip, comp} = this.data;
      const {original, label, title, name} = clip;

      comp.trimEnabled = false;
      comp.cdr.detectChanges();

      this.assetService.createClipSilent(original, label, clipMarking.markIn, clipMarking.markOut, title)
        .pipe(take(1))
        .subscribe({
          next: (newClip) => {
            this.assetService.deleteClipSilent(name)
              .pipe(take(1))
              .subscribe({
                next: () => {
                  this.assetService.reorderClip(newClip, prevClip?.name, nextClip?.name)
                    .pipe(take(1))
                    .subscribe((reorderedClip) => {
                      this.snackBar.message('Clip trimmed successfully.');
                      if (!reorderedClip) {
                        this.snackBar.error('Failed to properly order new clip in clips bin.');
                      }

                      comp.clips$.pipe(skip(1), take(1)).subscribe(() => {
                        comp.cdr.detectChanges();
                        const cardIndex = comp.clipCardList.map(e => e.nativeElement.id).indexOf(newClip.name);
                        const card = comp.clipCardList.get(cardIndex);
                        card?.nativeElement.click();
                      });

                      comp.refreshClips();
                    });
                },
                error: (error) => {
                  this.assetService.deleteClipSilent(newClip.name).subscribe();
                  this.snackBar.error('Clip could not be trimmed.', undefined, error);
                }
            });
          },
          error: (error) => {
            this.snackBar.error('Clip could not be trimmed.', undefined, error);
          }
        });
    });
  }
}
