import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {AssetService} from '../services/asset_service';
import {Bin} from '../services/bin.service';
import {SnackBarService} from '../services/snackbar_service';
import { SharedLinkClipBinService } from '../shared_clipbin/services/shared_link_clipbin.service';

/** Clip deletion dialog */
@Component({
  selector: 'mam-delete-clip-dialog',
  templateUrl: './delete_clip_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteClipDialog implements OnInit {
  constructor(
      readonly dialogRef: MatDialogRef<DeleteClipDialog, boolean>,
      private readonly snackBar: SnackBarService,
      private readonly assetService: AssetService,
      private readonly sharedLinkClipBinService: SharedLinkClipBinService,
      @Inject(MAT_DIALOG_DATA) public data: {name: string, bin: Bin}) {}

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(confirmed => {
      if (!confirmed) return;
      this.assetService.deleteClip(this.data.name).subscribe({
        next: () => {
          this.createOrUpdateClipBinSharedLink(this.data.bin);
          this.snackBar.message('Clip deleted successfully.');
        },
        error: (error) => {
          this.snackBar.error('Clip could not be deleted.', undefined, error);
        }
      });
    });
  }

  private createOrUpdateClipBinSharedLink(clipbin: Bin) {
    this.sharedLinkClipBinService.createOrUpdateClipBinSharedLink(clipbin);
  }
}
