import {Pipe, PipeTransform} from '@angular/core';

import {DurationPipe} from './duration_pipe';
import {TzDatePipe} from './tzdate_pipe';

/**
 * Formats a number of seconds either as a duration such as hh:mm:ss, or as
 * an absolute time when an absolute start time is provided.
 * 
 * @see tzDatePipe for how to configure absolute times.
 * Example:
 *   {{ 77 | durationOrTime }}
 *   formats to: '01:17'
 *   {{ 77 | durationOrTime:1620848400000:'hh:mm:ss a' }}
 *   formats to: '12:41:17 PM'
 */
@Pipe({name: 'durationOrTime'})
export class DurationOrTimePipe implements PipeTransform {
  constructor(
      private readonly durationPipe: DurationPipe,
      private readonly tzDatePipe: TzDatePipe,
  ) {}

  /**
   * @param value offset in seconds.
   * @param timeStart timestamp (in ms since epoch) from which `value` is an
   *     offset to format it as a time.
   */
  transform(
      value: number|undefined, timeStart?: number, timeFormat?: string,
      timeLocale?: string): string|null {
    if (timeStart == null || value == null) {
      return this.durationPipe.transform(value);
    }

    const time = value * 1000 + timeStart;
    return this.tzDatePipe.transform(time, timeFormat, timeLocale);
  }
}
