import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DateTime} from 'luxon';
import {map} from 'rxjs/operators';

import {LiveNavigationService} from './live_navigation_service';

/** Toolbar contents for the Live view. */
@Component({
  selector: 'mam-live-toolbar-content',
  templateUrl: './live_toolbar_content.ng.html',
  styleUrls: ['./live_toolbar_content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveToolbarContent {
  readonly selectedDateLabel$ = this.navigation.selectedDate$.pipe(
      map(({date}) => date.toLocaleString(DateTime.DATE_FULL, {locale: "en"})));

  constructor(readonly navigation: LiveNavigationService) {}
}
