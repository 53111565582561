import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {firstValueFrom, ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {hasAdminRightsMissing, isErrorResponse} from '../error_service/error_response';
import {Asset} from '../services/asset_service';
import {DialogService} from '../services/dialog_service';
import {MediaCacheService} from '../services/media_cache_service';
import {SnackBarService} from '../services/snackbar_service';

import {ExtendTtlDialog, ExtendTtlDialogInputData} from './extend_ttl_dialog';
import {OnPremPathDialog, OnPremPathDialogInputData} from './onprem_path_dialog';

/**
 * Contains available on-prem operations including seeing on-prem path, on-prem
 * purge, and TTL extension.
 */
@Component({
  selector: 'mam-onprem-operation',
  templateUrl: './onprem_operation.ng.html',
  styleUrls: ['./onprem_operation.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnpremOperation implements OnDestroy {
  @Input() asset?: Asset;

  /** Whether it is operating on a raw source file. */
  @Input() checkRawSource = false;

  constructor(
      private readonly dialogService: DialogService,
      private readonly dialog: MatDialog,
      private readonly mediaCache: MediaCacheService,
      private readonly snackBar: SnackBarService,
  ) {}

  openOnPremPathDialog(asset: Asset) {
    this.dialog.open<OnPremPathDialog, OnPremPathDialogInputData>(
        OnPremPathDialog,
        OnPremPathDialog.getDialogOptions(
            {asset, checkRawSource: this.checkRawSource}));
  }

  openExtendTtlDialog(asset: Asset) {
    const dialogRef =
        this.dialog.open<ExtendTtlDialog, ExtendTtlDialogInputData>(
            ExtendTtlDialog,
            ExtendTtlDialog.getDialogOptions(
                {asset, checkRawSource: this.checkRawSource}));
    dialogRef.afterClosed()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(response => {
          // If the extendTtl API didn't success, do not request update.
          if (response) this.mediaCache.state.manualUpdate$.next(undefined);
        });
  }

  async openDeleteOnpremFileDialog(asset: Asset) {
    const confirmed = await firstValueFrom(this.dialogService.showConfirmation({
      title: 'Delete on-prem file',
      question:
          'The file will be deleted from the premises. Are you sure you want to continue?',
      primaryButtonText: 'Delete',
    }));

    if (!confirmed) return;

    const removeFileResponse = await firstValueFrom(
        this.mediaCache.purgeAsset(asset, false, this.checkRawSource));

    if (isErrorResponse(removeFileResponse)) {
      if (hasAdminRightsMissing([removeFileResponse])) {
        this.snackBar.error(`On-prem deletion is reserved for administrators.`);
        return;
      }

      this.snackBar.error({
        message: 'Failed to delete the on-prem file.',
        details: removeFileResponse.message,
        doNotLog: true,
      });
    } else {
      this.snackBar.message('The deletion task is in progress.');
      this.mediaCache.state.manualUpdate$.next(undefined);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);
}
