import {Inject, Injectable, InjectionToken} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';

import {ApiFilesRemoveOnpremFileResponse, ApiLifecycleInfo} from 'api/ias/model/models';
import {assertTruthy, assumeExhaustiveAllowing, checkExhaustive} from 'asserts/asserts';
import {FileResource, PfrStateInfo} from 'models';

import {AuthService} from '../auth/auth_service';
import {environment} from '../environments/environment';
import {ErrorResponse, isErrorResponse, mapOnError, mapOnSuccess} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {StatusCode} from '../error_service/status_code';
import {PluginService} from '../plugin/plugin_service';
import {HostImportAction, LocalPath, PathSegment} from '../plugin/plugin_types';
import {ApiLifecycleInfoLifecycleStateEnum, ApiPFRStateInfoStateEnum, Cloud2onpremEnum, Onprem2cloudEnum} from '../services/ias_types';

import {convertToGsUri, LIVE_ASSET_LOCATION_METADATA_FIELDS} from './asset_api_service';
import {Asset, AssetService, Clip, Original} from './asset_service';
import {ClipApiService} from './clip_api_service';
import {buildFilePath, MediaCacheApiService} from './media_cache_api_service';
import {MediaCacheStateService, Path} from './media_cache_state_service';
import {UtilsService} from './utils_service';

/** Possible cloud and on-prem state of a file. */
export enum FileState {
  /** Asset is only in cloud storage and hasn't been downloaded. */
  FILE_CLOUD_ONLY = 'cloud_only',
  /** Asset is downloading from cloud to on-prem. */
  FILE_DOWNLOADING = 'downloading',
  /** Asset is uploading from on-prem to cloud. */
  FILE_UPLOADING = 'uploading',
  /** Asset is in cloud storage and on-prem. */
  FILE_CLOUD_AND_ONPREM = 'cloud_and_onprem',
  /** Live asset is on-prem and not in cloud. */
  FILE_ONPREM_ONLY = 'onprem_only',
  /** Asset is a clip, its original source is on-prem only. */
  SUBCLIP_ONPREM_ONLY = 'subclip_onprem_only',
  /** Asset is a clip, its original source is in cloud storage and on-prem */
  SUBCLIP_CLOUD_AND_ONPREM = 'subclip_cloud_and_onprem',
  /** The on-prem file is under deletion process. */
  DELETING = 'deleting',
  /** PFR clip failed to download. */
  FAILED_PFR_DOWNLOAD = 'failed_pfr_download',
  /** PFR process failed. */
  FAILED_PFR_RESTORE = 'failed_pfr_restore',
  /** Information about this file is not available yet. */
  FILE_UNKNOWN = 'unknown',
}

/** Combine the on-prem file and its status information. */
export interface FileAndState {
  onpremFile?: FileResource|ErrorResponse;
  state: FileState|ErrorResponse;
}

/** Injection token for reading the current URL location. */
export const URL_LOCATION = new InjectionToken<Location>(
    'Current location', {factory: () => window.location});


/**
 * Per discussion(b/217742258), when a clip duration longer than 50% of its
 * original asset duration, download the full asset rather than PFR.
 */
const DURATION_OPTIMIZATION_RATIO = 0.5;

/** Timeout for the `IMPORT_ASSETS` request to the plugin.  */
const IMPORT_ASSETS_TIMEOUT_MS = 60 * 60_000;

/** Necessary properties to import a file with PremierePluginService. */
export interface ImportInput {
  asset: Asset;
  binTitle: string;
  fileState: FileState|ErrorResponse;
  localPath: LocalPath|ErrorResponse;
}

/** UI service to interact with MediaCache APIs. */
@Injectable({providedIn: 'root'})
export class MediaCacheService {
  constructor(
      readonly state: MediaCacheStateService,
      @Inject(URL_LOCATION) private readonly urlLocation: Location,
      private readonly assetService: AssetService,
      private readonly clipApi: ClipApiService,
      private readonly mediaCacheApi: MediaCacheApiService,
      private readonly utils: UtilsService,
      private readonly errorService: ErrorService,
      private readonly pluginService: PluginService,
      private readonly authService: AuthService,
  ) {}

  /**
   * Returns `true` when the MediaCache API failed due to the File resource not
   * existing. This are often not treated and logged as errors since the UI may
   * decide to make the API call without having to verify file existence first.
   */
  isFileExistError(status?: StatusCode) {
    return status && [
      StatusCode.NOT_FOUND,
      StatusCode.PRECONDITION_FAILED,
      StatusCode.BAD_REQUEST,
    ].includes(status);
  }

  /**
   * Returns the onprem File resource (if it exists) and its state, most
   * relevant for this asset. This may be the file and state of the parent
   * original file in case of an optimized clip.
   */
  getFileAndState(siteId: string, folderId: string, asset: Asset, options: {
    checkRawSource?: boolean
  } = {}): Observable<FileAndState> {
    if (!siteId) {
      return of({state: new ErrorResponse('Missing site data')});
    }

    if (!folderId) {
      return of({state: new ErrorResponse('Missing cache folder')});
    }

    const original = asset.original || asset;
    const originalFilename =
        this.getOriginalFilename(original, options.checkRawSource);

    if (!originalFilename) {
      return of({state: new ErrorResponse('Missing source filename')});
    }

    // Check mezzanine asset.
    if (options.checkRawSource) {
      const gcsUrl = this.getRawSourceGsUri(asset);
      return this
          .getOnpremFile(siteId, folderId, originalFilename, gcsUrl || '')
          .pipe(
              map(onpremFile =>
                      ({onpremFile, state: this.formatFileState(onpremFile)})));
    }

    // Start by looking at the original asset location.
    return this
        .getOnpremFile(
            siteId, folderId, originalFilename, original.gcsLocationUrl)
        .pipe(
            switchMap(onpremFile => {
              const originalState = this.formatFileState(onpremFile);

              // Case original asset, use its file.
              if (!asset.original) {
                return of({onpremFile, state: originalState});
              }

              // Case clip with original on-prem, use the original file and show
              // state as subclip.
              if (!isErrorResponse(onpremFile) &&
                  onpremFile.cloud2onprem === 'COMPLETE') {
                // Case original is both in cloud and onprem.
                if (onpremFile.onprem2cloud === 'COMPLETE') {
                  return of({
                    onpremFile,
                    state: FileState.SUBCLIP_CLOUD_AND_ONPREM,
                  });
                }
                // Case original is only onprem.
                return of({
                  onpremFile,
                  state: FileState.SUBCLIP_ONPREM_ONLY,
                });
              }

              // Case long optimized clip and parent not onprem, use the
              // original file.
              if (!this.isPfrClip(asset)) {
                return of({onpremFile, state: originalState});
              }

              const parentOnpremFile = onpremFile;
              return this.pfrStateToFileAndState(siteId, folderId, asset)
                  .pipe(map(({onpremFile, state}) => {
                    // Case PFR complete, use PFR file
                    if (state === FileState.FILE_CLOUD_AND_ONPREM) {
                      return {onpremFile, state};
                    }

                    // Case PFR incomplete and original downloading, show a
                    // spinner and use original file.
                    else if (originalState === FileState.FILE_DOWNLOADING) {
                      return {
                        onpremFile: parentOnpremFile,
                        state: FileState.FILE_DOWNLOADING,
                      };
                    }

                    // Case PFR and original both incomplete, use PFR file.
                    return {onpremFile, state};
                  }));
            }),
        );
  }

  /**
   * If import is available, returns whether direct file import or subclippping
   * will be executed. Returns `null` if import is not available.
   */
  getImportType(asset: Asset, fileState: FileState|ErrorResponse):
      HostImportAction|null {
    // Import is only available from the plugin.
    if (!this.authService.isPlugin()) return null;

    if (isErrorResponse(fileState) ||
        ![FileState.FILE_CLOUD_AND_ONPREM,
          FileState.FILE_ONPREM_ONLY,
          FileState.FILE_UPLOADING,
          FileState.SUBCLIP_CLOUD_AND_ONPREM,
          FileState.SUBCLIP_ONPREM_ONLY,
    ].includes(fileState)) {
      // File is not available for import.
      return null;
    }

    // Live/VoD original assets. Use full file import.
    // eslint-disable-next-line deprecation/deprecation -- IMPORT_CLIPS is still used by plugins < 3.2.0
    if (!asset.original) return HostImportAction.IMPORT_CLIPS;

    // Live clips. Download full parent asset and use Premiere Pro sub-clipping.
    if (asset.isLive) return HostImportAction.IMPORT_SUBCLIPS;

    // *** VoD clips ***

    // Check if clip's parent asset is present on prem.
    if ([
          FileState.SUBCLIP_CLOUD_AND_ONPREM,
          FileState.SUBCLIP_ONPREM_ONLY,
        ].includes(fileState)) {
      // Import full parent asset and use Premiere Pro sub-clipping.
      return HostImportAction.IMPORT_SUBCLIPS;
    }

    // PFR VoD clips. Import full file.
    // eslint-disable-next-line deprecation/deprecation -- IMPORT_CLIPS is still used by plugins < 3.2.0
    return HostImportAction.IMPORT_CLIPS;
  }

  formatFileLocation(fileState: FileState|ErrorResponse) {
    switch (fileState) {
      case FileState.FILE_CLOUD_AND_ONPREM:
        return 'Cloud storage and on-prem';

      case FileState.SUBCLIP_ONPREM_ONLY:
        return 'Parent is on-prem only';

      case FileState.SUBCLIP_CLOUD_AND_ONPREM:
        return 'Parent in Cloud Storage and on-prem';

      case FileState.FILE_CLOUD_ONLY:
        return 'Cloud storage';

      case FileState.FILE_DOWNLOADING:
        return 'Downloading to premises';

      case FileState.FILE_UPLOADING:
        return 'Uploading to Cloud Storage';

      case FileState.FAILED_PFR_DOWNLOAD:
        return 'Download failed';

      case FileState.FAILED_PFR_RESTORE:
        return 'Restore failed';

      case FileState.FILE_ONPREM_ONLY:
        return 'On-prem only';

      case FileState.DELETING:
        return 'Deleting on-prem file';

      case FileState.FILE_UNKNOWN:
        return 'Unknown file location';

      default:
        assumeExhaustiveAllowing<ErrorResponse>(fileState);
        if (isErrorResponse(fileState)) return fileState.message || 'Invalid';
        return 'Unknown';
    }
  }

  /**
   * Download any asset. May optimize the download of a clip by downloading the
   * original video instead of doing PFR if it is long enough. In case of a live
   * asset or clip, the full original file is always downloaded.
   */
  downloadAsset(
      asset: Asset, siteId: string, folderId: string,
      checkRawSource = false): Observable<FileResource|Clip|ErrorResponse> {
    const isPfrClip = this.isPfrClip(asset);

    const pfrEnabled = !checkRawSource && isPfrClip;

    // *** Execute PFR (VoD only) ***
    if (pfrEnabled) {
      return this.downloadClip(siteId, folderId, asset.name);
    }

    // *** Download full asset ***
    const assetGcsUrl =
        checkRawSource ? this.getRawSourceGsUri(asset) : asset.gcsLocationUrl;

    let assetGcsUrl$ = of(assetGcsUrl);

    if (!assetGcsUrl && asset.isLive) {
      // A live original asset that is not ended should not allow download. This
      // condition should not be reachable and will help detect regressions.
      if (!asset.original) {
        return of(new ErrorResponse('Live source cannot be located.'));
      }
      // A live clip of an ended asset may not have its `sourceAsset.liveGcsUrl`
      // property set due to a BE limitation being worked on.
      assetGcsUrl$ = this.assetService.getAsset(asset.original.name)
                         .pipe(map(asset => asset?.gcsLocationUrl));
    }

    return assetGcsUrl$.pipe(switchMap(assetGcsUrl => {
      if (!assetGcsUrl) {
        return of(new ErrorResponse('Source file cannot be located.'));
      }
      return this.downloadOriginal(siteId, folderId, assetGcsUrl);
    }));
  }

  /** Downloads a full video file to any folder. */
  downloadOriginal(siteId: string, folderId: string, gcsLocationUrl: string):
      Observable<FileResource|ErrorResponse> {
    const filename = this.utils.lastPart(gcsLocationUrl);
    return this.getOnpremFile(siteId, folderId, filename, gcsLocationUrl)
        .pipe(
            switchMap(
                () => this.mediaCacheApi.download(siteId, folderId, filename)),
            this.errorService.catchError(),
        );
  }

  /** Triggers PFR to crop and download a clip from a file. */
  downloadClip(siteId: string, folderId: string, clipName: string):
      Observable<Clip|ErrorResponse> {
    const downloadFolder = this.getFolderPath(siteId, folderId);
    return this.clipApi.download(clipName, downloadFolder)
        .pipe(this.errorService.catchError());
  }

  /**
   * Returns local absolute path in windows and unix formats that can be used by
   * Premiere import functionality.
   * Throws an error message if video cannot be located.
   */
  locateOnPrem(
      asset: Asset|ErrorResponse, fileState: FileState|ErrorResponse,
      options: {checkRawSource?: boolean} = {}):
      Observable<LocalPath|ErrorResponse> {
    if (isErrorResponse(asset)) return of(asset);
    if (isErrorResponse(fileState)) return of(fileState);

    // In case of subclip, we locate the parent asset.
    const assetToLocate = (asset.original &&
                           [
                             FileState.SUBCLIP_CLOUD_AND_ONPREM,
                             FileState.SUBCLIP_ONPREM_ONLY,
                           ].includes(fileState)) ?
        asset.original :
        asset;

    return this.state.filePath$.pipe(
        take(1),
        switchMap(path => {
          return of(assetToLocate)
              .pipe(
                  switchMap(assetToLocate => {
                    return this
                        .getOnpremLocation(
                            assetToLocate, options.checkRawSource)
                        .pipe(map(onPremUnixPathResponse => {
                          if (isErrorResponse(onPremUnixPathResponse)) {
                            return new ErrorResponse(
                                `Failed to locate asset file on-prem. ${
                                    onPremUnixPathResponse.message}`);
                          }
                          // Apply unix path conversion.
                          return this.unixPathToLocal(
                              onPremUnixPathResponse, path);
                        }));
                  }),
              );
        }),
    );
  }

  extendOnpremTtl(
      asset: Asset, expiringDateIso: string,
      checkRawSource?: boolean): Observable<FileResource|ErrorResponse> {
    if (asset.original && !this.isPfrClip(asset)) {
      return of(new ErrorResponse(
          `Long clips do not use PFR and cannot have their TTL extended. Please extend parent asset instead.`));
    }

    return this.getFilePathAndName(asset, checkRawSource)
        .pipe(switchMap(({filePath, filename}) => {
          const {siteId, folderId} = filePath;
          const path = buildFilePath(siteId, folderId, filename);
          return this.updateFileTtl(path, {scheduleTime: expiringDateIso});
        }));
  }

  /**
   * Purges asset file from on-prem.
   *
   * Will trigger `manualUpdate$` emission unless `silent` param is set to
   * `true`
   */
  purgeAsset(asset: Asset, silent: boolean, checkRawSource?: boolean) {
    if (asset.original && !this.isPfrClip(asset)) {
      // This should never be possible to happen, if this error gets logged we
      // have a regression and need to handle it.
      return of(new ErrorResponse(
          `Long clips do not use PFR and cannot be purged. Please purge parent asset instead.`));
    }

    return this.getFilePathAndName(asset, checkRawSource)
        .pipe(
            switchMap(({filePath, filename}) => {
              const {siteId, folderId} = filePath;
              const fullFileName = buildFilePath(siteId, folderId, filename);
              return this.removeOnpremFile(fullFileName);
            }),
            tap(() => {
              if (silent) return;
              this.state.manualUpdate$.next(undefined);
            }),
        );
  }

  /**
   * Purges the on-prem file then trigger asset deletion, even if the file
   * purge failed as it shouldn't prevent from deleting the asset and may
   * already have been deleted, or will eventually be purged by TTL.
   */
  purgeAndDelete(asset: Original, skipPurge: boolean) {
    const maybePurge$: Observable<ErrorResponse|unknown> =
        skipPurge ? this.purgeAsset(asset, true) : of(null);

    return maybePurge$.pipe(switchMap((response) => {
      // Even if purge failed, we go on and delete the asset resource.
      if (isErrorResponse(response)) {
        this.errorService.handle(
            `Failed to purge asset prior to deleting it: ${asset.name}`);
      }
      return this.assetService.deleteAsset(asset);
    }));
  }

  /** Batch purges assets files from on-prem. */
  purgeAssets(assets: Original[]):
      Observable<Array<ErrorResponse|ApiFilesRemoveOnpremFileResponse>> {
    return this.utils
        .batchApiCalls(
            assets, asset => this.purgeAsset(asset, true),
            {abortIfNonAdmin: true})
        .pipe(tap(() => this.state.manualUpdate$.next(undefined)));
  }

  /** Batch extend on-prem files TTL. */
  extendAssetsTtl(assets: Original[], expiringDateIso: string) {
    return this.utils
        .batchApiCalls(
            assets, asset => this.extendOnpremTtl(asset, expiringDateIso),
            {abortIfNonAdmin: true})
        .pipe(tap(() => this.state.manualUpdate$.next(undefined)));
  }

  /**
   * Imports an asset/clip from MAM to Premiere Pro. Returns the segment to
   * import or `undefined` if import is not possible.
   */
  importAsset(input: ImportInput): PathSegment|ErrorResponse {
    const segment = this.prepareAssetForImport(input);
    const importType = this.getImportType(input.asset, input.fileState);

    if (importType && !isErrorResponse(segment)) {
      this.pluginService.dispatch({
        iasType: importType,
        payload: {segments: [segment]},
      });
    }

    return segment;
  }

  /**
   * Imports multiple assets/clips from MAM to Premiere Pro. Returns a map
   * of whether each input asset was successfully imported by Premiere.
   */
  importAssets(inputs: ImportInput[]) {
    const segments = inputs
                         .map(input => {
                           return this.prepareAssetForImport(input);
                         })
                         .filter((input): input is PathSegment => {
                           return input != null && !isErrorResponse(input);
                         });

    return this.pluginService
        .request(
            {
              iasType: HostImportAction.IMPORT_ASSETS,
              payload: {segments},
            },
            IMPORT_ASSETS_TIMEOUT_MS)
        .pipe(
            mapOnSuccess(message => message.payload),
        );
  }

  getAssetUrl(asset: Asset): string {
    const encodedAssetName = encodeURIComponent(asset.name);
    const assetUri = asset.original ?
        `/clipbin/${encodeURIComponent(asset.label)}/clip/${encodedAssetName}` :
        `/asset/${encodedAssetName}`;
    const url = new URL(assetUri, this.urlLocation.origin);
    return url.toString();
  }

  getFolderName(siteId: string, folderId: string) {
    const parent = environment.mamApi.parent;
    return `${parent}/sites/${siteId}/folders/${folderId}`;
  }

  /**
   * The original asset will be used for status when the clip has a duration >=
   * 50% of the original video and is VoD. A clip that is not optimized is also
   * known as a PFR clip.
   */
  isPfrClip(asset: Asset): boolean {
    if (!asset.original) return false;
    if (asset.isLive) return false;
    return asset.duration / asset.original.duration <
        DURATION_OPTIMIZATION_RATIO;
  }

  private removeOnpremFile(name: string):
      Observable<ApiFilesRemoveOnpremFileResponse|ErrorResponse> {
    return this.mediaCacheApi.removeOnpremFile(name).pipe(
        // File purge may be initiated even if the file does not exist.
        this.errorService.catchError([StatusCode.NOT_FOUND]));
  }

  /**
   * Returns the filename and its path of the target file. This may be the
   * parent's file in case of an optimized clip (not PFR).
   */
  private getFilePathAndName(asset: Asset, checkRawSource = false):
      Observable<{filePath: Path, filename: string}> {
    return this.state.filePath$.pipe(
        switchMap(
            filePath => this.getFilename(
                                filePath.siteId, filePath.folderId, asset,
                                checkRawSource)
                            .pipe(map(filename => ({filePath, filename})))),
        take(1),
    );
  }

  private updateFileTtl(name: string, lifecycleInfo: ApiLifecycleInfo):
      Observable<FileResource|ErrorResponse> {
    const file = ({lifecycleInfo});
    return this.mediaCacheApi.updateFileTtl(name, file)
        .pipe(this.errorService.catchError());
  }

  private getOnpremFile(
      siteId: string, folderId: string, filename: string,
      gcsLocation: string): Observable<FileResource|ErrorResponse> {
    if (!filename) {
      return of(new ErrorResponse('Missing asset filename'));
    }

    return this.mediaCacheApi
        .getOrCreateFile(siteId, folderId, filename, gcsLocation)
        .pipe(
            this.errorService.retryShort([StatusCode.NOT_FOUND]),
            this.errorService.catchError([StatusCode.NOT_FOUND]),
            mapOnError(error => {
              // If the File resource did not exist and could not be created
              // because we don't have a GCS location (such as a live stream not
              // ended and not picked up by MediaCache, either because it is too
              // soon, or because we are not on its origin site), return an
              // empty resource that will be shown as a `FILE_UNKNOWN` state.
              if (!gcsLocation) {
                return new FileResource();
              }

              return error;
            }),
        );
  }

  /**
   * Returns the absolute file path on prem, which requires a special
   * `locateFile` API call. This may be the file from the parent asset if the
   * clip is live or optimized (not PFR).
   */
  private getOnpremLocation(asset: Asset, checkRawSource = false):
      Observable<string|ErrorResponse> {
    return this.getFilePathAndName(asset, checkRawSource)
        .pipe(switchMap(res => {
          const filename = res.filename;

          if (!filename) {
            return of(new ErrorResponse('Missing asset filename'));
          }

          const {siteId, folderId} = res.filePath;
          return this.mediaCacheApi.locateFile(siteId, folderId, filename)
              .pipe(
                  switchMap(file => {
                    if (file.cloud2onprem !== Cloud2onpremEnum.COMPLETE) {
                      return throwError(() =>
                          new Error(`File is not on-prem, name: ${file.name}`));
                    }
                    return of(file.onpremLocation);
                  }),
                  this.errorService.catchError(),
              );
        }));
  }

  /** Converts an import input to a payload ready to be sent to Premiere. */
  private prepareAssetForImport(input: ImportInput): PathSegment|ErrorResponse {
    // TODO: For live assets to improve error messages we need to
    // know what site the workstation running the plugin is located on.

    const {asset, binTitle, fileState, localPath} = input;

    // Import should not be called if fileState check has errored out.
    if (isErrorResponse(fileState)) return fileState;

    const importType = this.getImportType(asset, fileState);
    if (!importType) return new ErrorResponse('Asset is not importable');

    if (isErrorResponse(localPath)) return localPath;

    // We need the wall-clock start of the imported file. In case of subclip,
    // asset.origin carries the original wall-clock. In case of a original asset
    // or PFR clip, it is the clip wall-clock start, which takes into account
    // the clip offset from its original asset wall-clock. We don't need to
    // provide a wall-clock for live streams as their media frames contain it.
    const wallClockAsset =
        importType === HostImportAction.IMPORT_SUBCLIPS && asset.original ?
        asset.original :
        asset;
    const segment: PathSegment = {
      path: localPath.windows,
      localPath,
      title: asset.title,
      bin: undefined,
      startTime: 0,
      endTime: 0,
      wallClockStartTime: this.getVodWallClockStartTime(wallClockAsset),
      commentText: this.getAssetUrl(asset),
    };

    // Extra properties for clips (including PFR)
    if (asset.original) {
      if (!binTitle) {
        return new ErrorResponse(`Cannot import a clip without a clipbin`);
      }
      segment.bin = binTitle;
    }

    // Extra properties for subclips
    if (importType === HostImportAction.IMPORT_SUBCLIPS) {
      segment.startTime = asset.startTime;
      segment.endTime = asset.endTime;
    }

    return segment;
  }

  private formatFileState(mediaCacheFile: FileResource|ErrorResponse): FileState
      |ErrorResponse {
    // If we failed to create the File resource but had a GCS location, show the
    // error message.
    if (isErrorResponse(mediaCacheFile)) return mediaCacheFile;

    // File is being uploaded from on-prem to cloud. This should only be
    // possible from a live stream after it stops streaming.
    if (mediaCacheFile.onprem2cloud === Onprem2cloudEnum.IN_PROGRESS) {
      return FileState.FILE_UPLOADING;
    }

    // File failed to upload to GCS.
    if (mediaCacheFile.onprem2cloud === Onprem2cloudEnum.FAILED) {
      return new ErrorResponse('Upload to Cloud Storage failed');
    }

    // In other cases, we also look at the on-prem status.
    switch (mediaCacheFile.cloud2onprem) {
      case 'COMPLETE': {
        // Check if the asset is under deletion process
        const {lifecycleState} = mediaCacheFile.lifecycleInfo;
        if (lifecycleState === ApiLifecycleInfoLifecycleStateEnum.PROCESSING) {
          return FileState.DELETING;
        }
        if (mediaCacheFile.onprem2cloud !== Onprem2cloudEnum.COMPLETE) {
          return FileState.FILE_ONPREM_ONLY;
        }
        return FileState.FILE_CLOUD_AND_ONPREM;
      }
      case 'IN_PROGRESS':
        return FileState.FILE_DOWNLOADING;
      case 'FAILED':
        return new ErrorResponse('Download to the premises failed');
      // Onprem status unknown (pending or unspecified), look at cloud status.
      case 'PENDING':
      case 'ASSET_TRANSFER_STATE_UNSPECIFIED':
        if (mediaCacheFile.onprem2cloud === Onprem2cloudEnum.COMPLETE) {
          return FileState.FILE_CLOUD_ONLY;
        }
        // Status is unknown both onprem and in GCS, which happens when the
        // File resource was not created yet in the current site.
        return FileState.FILE_UNKNOWN;
      default:
        checkExhaustive(mediaCacheFile.cloud2onprem);
    }
  }

  /**
   * Converts a unix path to a `LocalPath` (Windows + Unix).
   *
   * Example of unix path:
   * /volume1/LAB/prod_cache/some-folder/1624420745-melt.mxf
   */
  private unixPathToLocal(unixPath: string, path: Path): LocalPath
      |ErrorResponse {
    const winDrive = path.folderMetadata[FolderMetadata.WINDOWS_DRIVE_PREFIX];
    const unixPrefix = path.folderMetadata[FolderMetadata.UNIX_PATH_PREFIX];
    if (!winDrive || !unixPrefix) {
      return this.errorService.handleAndBuildErrorResponse(
          `Windows drive prefix, or Unix prefix is not provided by ${
              path.folderId}.`);
    }

    if (!unixPath.includes(unixPrefix)) {
      return this.errorService.handleAndBuildErrorResponse(
          `The file path doesn't contain the correct Unix prefix. File path: ${
              unixPath}, expected Unix prefix: ${unixPrefix}`);
    }

    return {
      windows: unixPath.replace(unixPrefix, winDrive).replace(/\//g, '\\'),
      unix: unixPath,
    };
  }

  /**
   * Converts PFR state to the proper File state.
   */
  private pfrStateToFileAndState(siteId: string, folderId: string, clip: Clip):
      Observable<FileAndState> {
    return this.getPfrFilename(siteId, folderId, clip)
        .pipe(switchMap(({filename, clip}) => {
          const folderPath = this.getFolderPath(siteId, folderId);
          const pfrInfo =
              clip.pfrInfo.stateMap[folderPath] as PfrStateInfo | undefined;
          const {state, outputGcsUri} = pfrInfo || {};

          // No PFR state available, cannot check onprem file.
          if (!state || !outputGcsUri) {
            return of({state: FileState.FILE_CLOUD_ONLY});
          }

          // We don't pass `outputGcsUri` to `getOnpremFile` as we the file
          // is not guaranteed to be on GCS yet in case of PFR, and it is not
          // the UI responsibility to create the File resource in that case.
          const onpremFile$ =
              this.getOnpremFile(siteId, folderId, filename, '');

          let fileState: FileState|ErrorResponse;

          switch (state) {
            case ApiPFRStateInfoStateEnum.STATE_UNSPECIFIED:
              fileState = FileState.FILE_CLOUD_ONLY;
              break;

            case ApiPFRStateInfoStateEnum.PENDING:
              fileState = FileState.FILE_DOWNLOADING;
              break;

            case ApiPFRStateInfoStateEnum.DOWNLOAD_FAILED:
              fileState = FileState.FAILED_PFR_DOWNLOAD;
              break;

            case ApiPFRStateInfoStateEnum.RESTORE_FAILED:
              fileState = FileState.FAILED_PFR_RESTORE;
              break;

            case ApiPFRStateInfoStateEnum.DOWNLOAD_COMPLETED:
              if (!outputGcsUri) {
                fileState = new ErrorResponse('PFR download is incomplete');
                break;
              }
              // PFR has done its task. Showing the file state in MediaCache.
              return onpremFile$.pipe(map(
                  onpremFile =>
                      ({onpremFile, state: this.formatFileState(onpremFile)})));

            case ApiPFRStateInfoStateEnum.FILE_GENERATED:
              if (!outputGcsUri) {
                fileState = new ErrorResponse('PFR is incomplete');
                break;
              }
              // After generating partial file, the file will be
              // downloaded to onprem from cloud in the near future. In
              // the gap, UI still shows downloading icon.
              return onpremFile$.pipe(map(onpremFile => {
                const fileState = this.formatFileState(onpremFile);
                if (fileState === FileState.FILE_CLOUD_ONLY) {
                  return {onpremFile, state: FileState.FILE_DOWNLOADING};
                }
                return {onpremFile, state: fileState};
              }));

            default:
              fileState = new ErrorResponse('PFR is invalid');
          }

          return onpremFile$.pipe(
              map(onpremFile => ({onpremFile, state: fileState})));
        }));
  }

  /**
   * Retrieves live asset source filename.
   * - Example: `cutdown-test.mxf`
   */
  private getLiveFilename(asset: Asset) {
    if (!asset.isLive) {
      throw new Error('getLiveFilename is for live assets');
    }

    // If the source was uploaded to GCS, use this URL to extract the filename.
    if (asset.gcsLocationUrl) {
      return this.utils.lastPart(asset.gcsLocationUrl);
    }

    // Otherwise, read the filename as the last part of the EVS metadata, in
    // priority from `SecondaryHiResFilePath` if it exists (in case of a source
    // coming from Doha).
    const jsonMetadata = asset.assetMetadata.jsonMetadata;
    for (const locationField of LIVE_ASSET_LOCATION_METADATA_FIELDS) {
      let fieldValue: string|string[]|undefined = jsonMetadata[locationField];
      // `SecondaryHiResFilePath` is an array with a single value.
      if (Array.isArray(fieldValue)) {
        fieldValue = fieldValue[0];
      }
      if (fieldValue) {
        return this.utils.lastPart(fieldValue);
      }
    }

    return '';
  }

  /**
   * Retrieves original asset source filename.
   * - Example: `video.mxf`
   */
  private getOriginalFilename(asset: Asset, checkRawSource = false): string {
    const original = asset.original || asset;

    // Case raw source, example: `my-raw-source.mxf`
    if (checkRawSource) {
      return this.utils.lastPart(this.getRawSourceGsUri(original));
    }

    // Case live growing source file
    if (original.isLive) {
      return this.getLiveFilename(original);
    }

    // Case original VoD file, example: `my-video.mxf`
    return this.utils.lastPart(asset.gcsLocationUrl);
  }

  /**
   * Extracts the base filename used to lookup the file state on mediaCache.
   * This may be the filename of the parent asset if the clip is optimized (non
   * PFR). Always fetches a fresh instance of the clip resource to ensure an
   * up to date pfrInfo, and return that fresh resource with the filename.
   */
  private getPfrFilename(siteId: string, folderId: string, clip: Clip):
      Observable<{filename: string; clip: Clip}> {
    // Case PFR file, example: `My_clip-_PFR_clip_onprem_59441d.mxf`
    // We always get a fresh resource as the current one may be obsolete, or
    // obtained through a list call which will not contain pfrInfo when the clip
    // was copied.
    assertTruthy(clip.original, 'PFR clips cannot be Original assets');
    return this.assetService.getClip(clip.name).pipe(
        mapOnError(() => clip), map(clip => {
          const folderName = this.getFolderName(siteId, folderId);
          const pfrGcsUri = clip.pfrInfo.stateMap[folderName]?.outputGcsUri;
          return {filename: this.utils.lastPart(pfrGcsUri), clip};
        }));
  }

  /**
   * Extracts the base filename used to lookup the file state on mediaCache.
   * This may be the filename of the parent asset if the clip is optimized (non
   * PFR).
   * - Example: `base-name.mxf`
   */
  private getFilename(
      siteId: string, folderId: string, asset: Asset,
      checkRawSource = false): Observable<string> {
    // Case original VoD file, example: `my-video.mxf`
    if (!this.isPfrClip(asset)) {
      return of(this.getOriginalFilename(asset, checkRawSource));
    }

    // `isPfrClip` returned true, the asset is a clip.
    const clip = asset as Clip;
    return this.getPfrFilename(siteId, folderId, clip)
        .pipe(map(({filename}) => filename));
  }

  private isMezzanine(asset: Asset): asset is(Original)&{rawSourceUrl: string} {
    return !asset.original && !!asset.rawSourceUrl;
  }

  /**
   * Returns the `gs://` uri of a raw source signed URL.
   * Example: 'gs://my-bucket/my-raw-source.mxf'
   */
  private getRawSourceGsUri(asset: Asset): string|undefined {
    if (!this.isMezzanine(asset)) return undefined;
    return convertToGsUri(asset.rawSourceUrl);
  }

  /**
   * Return the wall clock time in seconds since epoch for VoD assets if they
   * have one, otherwise return `undefined`. Always return `undefined` for live
   * assets.
   */
  private getVodWallClockStartTime(asset: Asset): number|undefined {
    // Premiere Pro does not need wall-clock for live assets.
    if (asset.isLive) return undefined;

    const startTimeMs = this.assetService.getWallclockStartTimestamp(
        asset, null, /* ignoreTimezone */ true);
    return startTimeMs ? startTimeMs / 1000 : undefined;
  }

  /** Returns the folder path with the site. */
  getFolderPath(siteId: string, folderId: string) {
    const parent = environment.mamApi.parent;
    return `${parent}/sites/${siteId}/folders/${folderId}`;
  }
}

/** Folder level metadata properties. */
export enum FolderMetadata {
  EVS_PATH_PREFIX = 'EVS_PATH_PREFIX',
  WINDOWS_DRIVE_PREFIX = 'WINDOWS_DRIVE_PREFIX',
  UNIX_PATH_PREFIX = 'UNIX_PATH_PREFIX',
}
