import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {FirebaseModule} from '../firebase/firebase_module';

import {ConfirmDialog} from './confirm_dialog';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FirebaseModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
  ],
  declarations: [
    ConfirmDialog,
  ],
  exports: [
    ConfirmDialog,
  ],
})
export class ConfirmDialogModule {
}
