<h1 mat-dialog-title>Delete Clip Bins</h1>
<div mat-dialog-content>
  <p>
    This will delete all {{data.bins.size}} bins in this selection, are you sure you want to continue?
  </p>
</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
  >
    Cancel
  </button>
  <button
      mat-button
      cdkFocusInitial
      [mat-dialog-close]="true"
      (keyup.enter)="dialogRef.close(true)"
      class="danger"
    >
    Delete
  </button>
</div>
