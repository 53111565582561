import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

/** Directive to be used in other components to achieve infinite scrolling */
@Directive({
  selector: '[infinite-scroll]',
})
export class InfiniteScroll {
  /** The ratio of scrollHeight to trigger the event */
  @Input() ratioOfScrollHeight = 0.7;

  /**
   * Event gets emitted when scrolling near the bottom
   */
  @Output() readonly nearBottom = new EventEmitter<void>();

  @HostListener('scroll', ['$event.currentTarget'])
  scroll(targetElement: HTMLElement) {
    if (targetElement.offsetHeight + targetElement.scrollTop >=
        targetElement.scrollHeight * this.ratioOfScrollHeight) {
      this.nearBottom.emit();
    }
  }
}
