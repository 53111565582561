import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { ClipBinsFoldersService, FolderItemRef } from '../../firebase/clip_bins_folders.service';
import {SnackBarService} from '../../services/snackbar_service';

/** Clipbin rename dialog */
@Component({
  selector: 'mam-update-folder-dialog',
  templateUrl: './clipbin_folder_update_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
    @import './shared/mixins';
    :host {
      max-width: 502px;
      width: 70vw;
      display: block;

        @include breakpoint-md-max {
          max-width: 95vw;
          width: 65vw;
          }

        @include breakpoint-sm-max {
          width: initial;
          max-width: initial;
        }
      }`
  ]
})
export class ClipBinFolderUpdateDialog implements AfterViewInit {
  @ViewChild('input') input!: ElementRef;

  static readonly dialogOptions = {hasBackdrop: true};

  constructor(
      readonly dialogRef: MatDialogRef<ClipBinFolderUpdateDialog>,
      private readonly snackBar: SnackBarService,
      private readonly clipBinFolderService: ClipBinsFoldersService,
      @Inject(MAT_DIALOG_DATA) public data: FolderItemRef) {
    dialogRef.afterClosed().subscribe((renamedTitle?: string) => {
      if (!renamedTitle?.trim() || renamedTitle === this.data.displayName) return;
      this.data.displayName = renamedTitle;
      this.data.queryableName = renamedTitle.toLowerCase().replace(/\s/g, "");
      this.clipBinFolderService.updateFolder(this.data).subscribe({
        next: () => {
          this.snackBar.message('Folder has been updated successfully.');
        },
        error: (error) => {
          this.snackBar.error(
              'Folder could not be updated.', undefined, error);
        }
      });
    });
  }

  ngAfterViewInit() {
    // Select input on next tick to avoid an
    // `ExpressionChangedAfterItHasBeenCheckedError`, see
    // https://angular.io/errors/NG0100.
    setTimeout(() => {
      this.input.nativeElement.select();
    });
  }
}
