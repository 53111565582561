<ng-container *ngIf="isUnauthenticated">
  <img class="background" alt="Artistic background" src="images/login_background.jpg">
 
  <div class="content">
    <div class="fox-logo"><img src="images/fox_sports_logo.png" alt="fox logo"></div>
    <div class="ias-logo">
      <img src="images/ias_logo_medium.svg" alt="ias logo">
    </div>
    <h1>Welcome to Fox Sports</h1>
    <h1>Intelligent Asset Service</h1>

    <h3>Use your Fox account to get started</h3>

    <div
        *ngIf="(pluginAuthInitiated$ | async) === false; else pluginAuthInitiatedMessage"
        class="login-button"
        (click)="signInWithGoogle()">
      <img class="google-logo" src="images/googleg_standard_clr_36px.svg" alt="google logo">
      <div>Sign in with Google</div>
    </div>

    <ng-template #pluginAuthInitiatedMessage>
      <div class="external-auth-message">
        <div>Please sign-in from the external web page.</div>
        <div>You will be able to try again in a few seconds.</div>
      </div>
    </ng-template>
  </div>
</ng-container>
