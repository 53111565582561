<div #scrollableView class="landing-scrollableView">
	<div class="centered-content">
		<!-- Recent Videos Section -->
		<h1>Recent videos</h1>
		<section class="recent" [class.recent--empty]="!isAssetsLoading && !recentAssets.length" [class.overflow-hidden]="scrubbingService.scrubbing$ | async">
			<!-- Loading assets -->
			<ng-container *ngIf="isAssetsLoading; else displayAssets">
				<mam-assets-skeleton *ngFor="let asset of assetsLoading"></mam-assets-skeleton>
			</ng-container>

			<!-- Assets -->
			<ng-template #displayAssets>
				<ng-container *ngIf="recentAssets.length; else noAssets">
					<mam-recent-asset
						*ngFor="let asset of recentAssets; let index = index; trackBy: trackName"
						[asset]="asset"
						[routerLink]="['/asset', asset.name]"
						[queryParams]="{'type': 'recent'}"
						queryParamsHandling="merge"
						mam-firebase-ga="Open recent video"
						[mam-firebase-resource]="asset.name"
						[isClipbinsAvailable]="cachedBins && cachedBins.length>0"
					></mam-recent-asset>
				</ng-container>
			</ng-template>
		</section>

		<!-- Clip Bins Section -->
		<mam-clip-bin-section></mam-clip-bin-section>
	</div>
</div>

<ng-template #noAssets>
	<mam-empty-assets-card assetsType="Recent videos" [helperText]="true">
		<p>
			Currently, there are no recent videos available to you.
		</p>
	</mam-empty-assets-card>
</ng-template>
