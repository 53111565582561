import {GoogleRpcStatus} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

/** UI model for RPC status */
export class RpcStatus implements RequiredRecursively<GoogleRpcStatus> {
  code = this.apiRpcStatus.code ?? 0;
  details = this.apiRpcStatus.details ?? [];
  message = this.apiRpcStatus.message ?? '';

  constructor(private apiRpcStatus: GoogleRpcStatus = {}) {}
}
