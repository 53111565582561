import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {DateRange as DatePickerDateRange, MatDateRangePicker} from '@angular/material/datepicker';
import {DateTime} from 'luxon';

import {castExists} from 'asserts/asserts';

import {DATE_RANGE_FORMAT, QUERY_SEPARATOR} from '../services/search_service';

/**
 * Component for the search date range picker
 */
@Component({
  selector: 'mam-search-date-range-picker',
  templateUrl: './search_date_range_picker.ng.html',
  styleUrls: ['./search_date_range_picker.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchDateRangePicker {
  /** Input date range in format YYYY-MM-DD;YYYY-MM-DD. */
  @Input()
  get dateRangeQuery() {
    return this.dateRangeQueryInternal;
  }
  set dateRangeQuery(rangeQuery: string) {
    this.dateRangeQueryInternal = rangeQuery;

    if (!rangeQuery) {
      this.startDateControl.setValue('');
      this.endDateControl.setValue('');
    } else {
      const dates = this.dateRangeQuery.split(QUERY_SEPARATOR);
      // Parse with luxon to ensure that the date as string is parsed in the
      // device timezone, and will be formatted back as the same string.
      const start = DateTime.fromFormat(dates[0], DATE_RANGE_FORMAT);
      const end = DateTime.fromFormat(dates[1], DATE_RANGE_FORMAT);
      this.startDateControl.setValue(start);
      this.endDateControl.setValue(end);
    }
  }

  /**
   * Emits changes to the date range with the same format as the input
   * YYYY-MM-DD;YYYY-MM-DD.
   */
  @Output() readonly dateRangeQueryChange = new EventEmitter<string>();

  @ViewChild('picker')
  readonly datePicker!: MatDateRangePicker<DatePickerDateRange<DateTime>>;

  readonly startDateControl = new UntypedFormControl('');

  readonly endDateControl = new UntypedFormControl('');

  /** Search API does not support dates below epoch (1/1/1970). */
  readonly minDate = new Date(1970, 0, 1);

  /** Prevents searching later than 12/31 of next year. */
  readonly maxDate = new Date(new Date().getFullYear() + 1, 11, 31);

  /**
   * Emits the start date and end date to parent component. If only one
   * is provided, copy it to both values.
   */
  onRangeChange() {
    clearTimeout(this.rangeChangeTimer);

    // Ignore invalid inputs, preserve current results.
    if (this.startDateControl.status === 'INVALID' ||
        this.endDateControl.status === 'INVALID') {
      return;
    }

    let startDate = this.startDateControl.value as DateTime | null;
    let endDate = this.endDateControl.value as DateTime | null;

    if (!startDate && !endDate) {
      this.clearDateRange();
      return;
    }

    if (!startDate) {
      startDate = endDate;
    } else if (!endDate) {
      endDate = startDate;
    }

    // Emit an update on next tick. This is so that when a range is selected
    // from the calendar UI, only one event with both values is emitted, as
    // both `dateChange` events will be triggered from each input.
    this.rangeChangeTimer = window.setTimeout(() => {
      const queryRange =
          `${this.formatDate(startDate)}${QUERY_SEPARATOR}${this.formatDate(endDate)}`;
      this.dateRangeQueryChange.emit(queryRange);
    });
  }

  /** Clears the selected date range when clicking on the clear button */
  clearDateRange() {
    this.dateRangeQueryChange.emit(undefined);
    this.datePicker.close();

    // Force reset the inputs in case we typed a non-valid date in the input and
    // then clicked on the "clear" button in the calendar.
    this.startDateControl.setValue('');
    this.endDateControl.setValue('');
  }

  private dateRangeQueryInternal = '';

  private rangeChangeTimer = -1;

  /** Converts a date to YYYY-MM-DD format, or empty string if invalid. */
  private formatDate(date: DateTime|null): string {
    const parsed = castExists(date);
    return parsed.isValid ? parsed.toFormat(DATE_RANGE_FORMAT) : '';
  }
}
