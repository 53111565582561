<!-- Play by Play -->
<div class="play-feed-container">

  <div class="search-bar-container">
    <!-- Refresh button -->
    <button mat-icon-button
        class="refresh-button"
        aria-label="Refresh the list"
        matTooltip="Refresh the list"
        mam-firebase-ga="Refresh table in play by play"
        [hidden]="!showRefreshButton"
        (click)="refreshTable()">
      <mat-icon>refresh</mat-icon>
    </button>
    <!-- Refresh button placeholder; will be removed when refresh button will be tested/accepted -->
    <div [hidden]="showRefreshButton" style="width: 20px;">
    </div>
    <!-- Play by Play Search bar -->
    <div class="play-feed-search-bar" (click)="focusSearchInput()">
      <input type="text"
          #searchBar
          placeholder="Search play feed"
          [formControl]="searchInputControl"
          (keydown)="onSearchKeydown($event)">
      <div class="search-controls" *ngIf="searchInputControl.value">
        <div>
          {{activeSearchMatchIndex + 1}}/{{searchMatchTotal}}
        </div>
        <button mat-icon-button
            disableRipple
            aria-label="Prev Play Feed Search Match"
            mam-firebase-ga='Prev Play Feed Search Match'
            class="small-button"
            [disabled]="!searchMatchTotal"
            (click)="prevSearchMatch();$event.stopPropagation()">
          <mat-icon>expand_less</mat-icon>
        </button>
        <button mat-icon-button
            disableRipple
            aria-label="Next Play Feed Search Match"
            mam-firebase-ga='Next Play Feed Search Match'
            class="small-button"
            [disabled]="!searchMatchTotal"
            (click)="nextSearchMatch();$event.stopPropagation()">
          <mat-icon>expand_more</mat-icon>
        </button>
        <button mat-icon-button
            disableRipple
            aria-label="Clear Play Feed Search"
            mam-firebase-ga='Clear Play Feed Search'
            (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div
        class="add-segment-container"
        matTooltip="Mark in and out points on the asset timeline to proceed."
        [matTooltipDisabled]="!!(stateService.clipMarking$ | async)">
      <button
          (click)="createSegment()"
          [disabled]="(stateService.clipMarking$ | async) == null"
          mam-firebase-ga="Create segment"
          mat-stroked-button
          matSuffix>
        Add
      </button>
    </div>
  </div>

  <!-- Play by Play headers -->
  <div class="play-feed-header">
    <div class="time-header">Time</div>
    <div class="description-header">Play info</div>
  </div>
  <mat-divider></mat-divider>

  <!-- Play by Play body -->
  <div class="panel-scrollview" #scrollView (mousewheel)="onMouseWheel()">
    <ng-container *ngIf="displaySegments$ | async as displaySegments">
      <ng-container *ngIf="displaySegments.length; else noData">
        <!-- TODO: Consider using cdkVirtualFor for virtual scroll -->
        <ng-container
            class="play-feed-rows"
            *ngFor="let segment of displaySegments; let i = index; last as isLast; trackBy: trackBySegment">
          <div
              class="play-feed-row"
              [class.closest]="isClosest(segment)"
              [class.current]="isCurrent(segment)"
              mam-firebase-ga="Update player time from play feed"
              [mam-firebase-resource]="segment.name"
              (click)="handleClick({'segmentStartOffset': segment.startOffset})">
            <div class="time-data" [innerHTML]="segment.timeHtml">
            </div>
            <div class="description-container">
              <div class="description-data">
                <div
                    class="play-period-data"
                    *ngIf="segment.playPeriod"
                    [innerHtml]="segment.playPeriodHtml">
                </div>
                <div
                    class="play-description-data"
                    [class.no-description]="!segment.description"
                    [title]="segment.description"
                    [innerHTML]="segment.descriptionHtml || 'No Play Description Available'">
                </div>
              </div>
              <div class="hover-action-container">
                <div class="hover-actions">
                  <button
                      class="create-clip"
                      mat-icon-button
                      aria-label="Add clip"
                      matTooltip="Add clip"
                      mam-firebase-ga="Add clip from play feed segment"
                      [mam-firebase-resource]="segment.name"
                      (click)="openAddClipDialog(segment); $event.stopPropagation()">
                    <mat-icon>add_box</mat-icon>
                  </button>
                  <button
                      class="view-description"
                      mat-icon-button
                      aria-label="Open description"
                      matTooltip="View play description details"
                      mam-firebase-ga="Open play feed segment description"
                      [mam-firebase-resource]="segment.name"
                      (click)="openDescriptionDialog(segment); $event.stopPropagation()">
                    <mat-icon>info_outlined</mat-icon>
                  </button>
                  <button
                      class="delete-description"
                      mat-icon-button
                      aria-label="Delete play description"
                      matTooltip="Delete play description"
                      mam-firebase-ga="Delete play feed segment"
                      [mam-firebase-resource]="segment.name"
                      (click)="deleteSegment(segment); $event.stopPropagation()">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <mat-divider *ngIf="!isLast"></mat-divider>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- No data was retrieved by Annotated Segments API -->
<ng-template #noData>
  <div class="no-play-feed">
    No data available. This may update shortly.
  </div>
</ng-template>
