/**
 * Generates a number from 0 to 1 that appears random but is deterministic
 * based on the given seed string.
 */
export function pseudoRandom(seed: string) {
  let n = 11 * seed.length;
  for (let i = 0; i < seed.length; i++) {
    const c = seed.codePointAt(i) || 1;
    n += c + c ** 2 + c ** 3;
  }
  return ((n * 99) % 100) / 100;
}

/**
 * Returns an item from the given array that appears random but is deterministic
 * based on the given seed string.
 */
export function pseudoRandomArrayItem<T>(seed: string, array: ArrayLike<T>): T {
  const index = Math.floor(pseudoRandom(seed) * array.length);
  return array[index];
}

/**
 * Return a function that can be called repeatedly to get pseudo random numbers
 * from 0 to 1.
 */
export function createPseudoRandomGenerator(seed: string) {
  let previous = pseudoRandom(seed) * 1000 + 1;  // To avoid 0 as seed.
  return () => {
    previous = previous * 48_271 % 2_147_483_647;
    return previous / 2_147_483_647;
  };
}


/**
 * Returns a single page out of the given list of all items with a
 * `nextPageToken` that allows to get the following page. When no more items
 * are available, `nextPageToken` will be undefined.
 */
export function makeFakePage<T>(
    allItems: readonly T[], pageSize: number,
    pageToken?: string): {pageOfItems: T[], nextPageToken: string|undefined} {
  const sliceStartIndex = Number(pageToken || 0);
  const sliceEndIndex = sliceStartIndex + pageSize;
  const pageOfItems = allItems.slice(sliceStartIndex, sliceEndIndex);

  let nextPageToken: string|undefined = undefined;
  // If the slice ended before the end of the array (sliceEndIndex is
  // exclusive), there are elements still to be returned, so we generate a next
  // page token.
  if (sliceEndIndex < allItems.length) {
    nextPageToken = String(sliceEndIndex);
  }

  return {pageOfItems, nextPageToken};
}
