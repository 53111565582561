<ng-container *ngIf="stateService.currentView$ | async as currentView">
  <!-- Search toolbar -->
  <mat-toolbar class="upper-toolbar">
    <a class="title" (click)="backToLanding()">
      <span class="product-icon">
        <img src="images/ias_logo_medium.svg" alt="IAS logo">
      </span>
      <h1>Intelligent Asset Service</h1>
    </a>

    <!-- Sites selector -->
    <div class="sites-selector-container" *ngIf="currentView !== HomeView.JOBS">
      <ng-container
          *ngIf="mediaCache.state.selectedSite$ | async as selectedSite">
        <button
            mat-button
            mam-firebase-ga="Open site picker"
            [mam-firebase-resource]="selectedSite.name"
            [matMenuTriggerFor]="sitesMenu"
            [title]="selectedSite.siteId"
            class="site-select">
          <mat-icon class="domain-icon">domain</mat-icon>
          <div class="button-wrapper">
            <span class="domain-icon-mobile">{{selectedSite.siteId | uppercase}}</span>
            <mat-icon class="arrow">arrow_drop_down</mat-icon>
          </div>
        </button>
        <mat-menu #sitesMenu="matMenu" class="menu-offset-down">
          <button
              class="home__menu-item--spacing"
              *ngFor="let site of selectableSites$ | async"
              mat-menu-item
              (click)="mediaCache.state.selectSite(site)"
              mam-firebase-ga="Select a site"
              [mam-firebase-resource]="site.name">
            <mat-icon>domain</mat-icon>
            <span>{{site.siteId | uppercase}}</span>
          </button>
        </mat-menu>
      </ng-container>
    </div>

    <!--Adding +New icon to upper toolbar of JOBS and STAGING TABS for mobile view-->
    <div class="mobile-upper-toolbar" *ngIf="(currentView === HomeView.JOBS || currentView === HomeView.STAGING)">
      <button mat-stroked-button class="fab-newupper" *ngIf="createActions.length === 1"
      mam-firebase-ga="Open create clip bin dialog from home" (click)="openCreateBin()">
        <mat-icon>add</mat-icon>
        <span>New</span>
      </button>
      <button mat-stroked-button class="fab-newupper" *ngIf="createActions.length > 1"
      mam-firebase-ga="Choose creation job from home" [matMenuTriggerFor]="creationMenu">
        <mat-icon>add</mat-icon>
        <span>New</span>
    </button>
    <mat-menu #creationMenu="matMenu" class="menu-offset-down" backdropClass="add-pop-up">
      <button *ngFor="let action of createActions" mat-menu-item
        [mam-firebase-ga]="'Trigger new ' + action + ' from home'" mam-firebase-resource="action"
        (click)="executeCreateAction(action)">
        <input *ngIf="action === 'Local Upload'" #uploadFilesInput accept="video/,.mxf,.xml" hidden type="file"
          multiple (change)="uploadFiles()">
        <span>{{action}}</span>
      </button>
    </mat-menu>
    </div>

    <div class="center">
      <ng-container *ngIf="
      currentView !== HomeView.JOBS
      && currentView !== HomeView.STAGING
      && currentView !== HomeView.ADMIN ">
        <mam-search-input #searchInput [suggestions]="searchInputService.suggestions$ | async"
          [searchMode]="(searchInputService.searchMode$ | async)!"
          [searchType]="(searchInputService.searchType$ | async)!" [chips]="(searchInputService.chips$ | async) || []"
          (suggestionSelected)="suggestionSelected($event)" (searchModeChanged)="changeSearchMode($event)"
          (chipRemoved)="removeChip($event)" (chipEdited)="editChip($event)" (clear)="clearSearchQuery()"
          (input)="updatePartialQuery()" (remove)="removeFromHistory($event)" (search)="search($event)">
        </mam-search-input>
        <button  mat-icon-button class="toggle-search-help" aria-label="Search help" matTooltip="Search help"
          mam-firebase-ga="Toggle search help" (click)="isSearchHelpDisplayed = !isSearchHelpDisplayed">
          <mat-icon>help_outline</mat-icon>
        </button>
      </ng-container>
    </div>

    <div class="right">
      <mam-toolbar-menu></mam-toolbar-menu>
    </div>

    <!-- Adding Progress bar to upper toolbar of JOBS and STAGING TABS for mobile view -->
    <mat-progress-bar
        class="progress-bar-staging-and-jobs-mobile"
        *ngIf="(progressbar.visible$ | async) && (currentView === HomeView.JOBS ||currentView === HomeView.STAGING || currentView === HomeView.ADMIN)"
        @fadeProgressBar
        mode="indeterminate">
    </mat-progress-bar>
  </mat-toolbar>

  <!-- Lower toolbar -->
  <!--Hiding Lower toolbar of JOBS and STAGING TABS for mobile view-->
  <ng-container>
  <mat-toolbar [ngClass]="!(currentView === HomeView.JOBS ||currentView === HomeView.STAGING) ? 'lower-toolbar' : 'lower-toolbar lower-toolbar-mobile'" [class.search]="isSearchPageDisplayed">
    <button mat-stroked-button class="fab-new" *ngIf="createActions.length === 1"
      mam-firebase-ga="Open create clip bin dialog from home" (click)="openCreateBin()">
      <mat-icon>add</mat-icon>
      <span>New</span>
    </button>

    <button
        mat-stroked-button
        class="fab-new"
        *ngIf="createActions.length > 1"
        mam-firebase-ga="Choose creation job from home"
        [matMenuTriggerFor]="creationMenu">
      <mat-icon>add</mat-icon>
      <span>New</span>
    </button>
    <mat-menu #creationMenu="matMenu" class="menu-offset-down" backdropClass="add-pop-up">
      <button
        *ngFor="let action of createActions"
        mat-menu-item
        class="home__action-item--spacing"
        [mam-firebase-ga]="'Trigger new ' + action + ' from home'" mam-firebase-resource="action"
        (click)="executeCreateAction(action)">
        <input *ngIf="action === 'Local Upload'" #uploadFilesInput accept="video/*,.mxf,.xml" hidden type="file"
          multiple (change)="uploadFiles()">
        <span>{{action}}</span>
      </button>
    </mat-menu>

    <!-- Home/Live pages' toolbars -->
    <ng-container *ngIf="{
      isHome: currentView === HomeView.LANDING || currentView === HomeView.SEARCH_RESULTS,
      isLive: currentView === HomeView.LIVE,
      hasSearchResponse: hasSearchResponse$ | async,
    } as state">
      <!-- Date picker and facets -->
      <ng-container *ngIf="(state.isHome && state.hasSearchResponse) || state.isLive">
        <ng-container *ngIf="{
          homeFacetGroups: facetGroups$ | async,
          liveSiteFacetGroup: siteFacetGroup$ | async,
        } as facets">
          <mat-divider *ngIf="!state.isLive" [vertical]="true"></mat-divider>

          <!-- TODO: Move facets to a separate component -->
          <div
              [ngClass]="['facet-groups-container', state.isLive ? 'live-toolbar' : '']"
              *ngIf="facets.homeFacetGroups || state.isLive"
              #facetGroupsContainer>

            <!-- Scroll left button (if necessary)-->
            <button
                class="prev-button scroll-button scroll-left"
                [class.invisible]="!showFacetsScrollLeft"
                [class.hidden]="!showFacetsScrollLeft && !showFacetsScrollRight"
                aria-hidden="true"
                (click)="scrollLeft()"
                mat-icon-button
                tabindex="-1"
                mam-firebase-ga="Scroll search facets left">
              <mat-icon>chevron_left</mat-icon>
            </button>

            <!-- Scrollable facets container -->
            <div
                class="facet-groups"
                (click)="$event.stopPropagation()"
                (scroll)="onScroll()" #facetScrollView>

              <!-- Home toolbar elements -->
              <ng-container *ngIf="state.isHome">
                <!-- All facets but date range picker -->
                <mam-search-facet-group *ngFor="let facetGroup of facets.homeFacetGroups"
                    #facetGroup
                    [facetGroup]="facetGroup"
                    [facetQuery]="(facetQuery$ | async) ?? ''"
                    (menuClosed)="onMenuClose($event)">
                </mam-search-facet-group>

                <!-- Date range picker -->
                <mam-search-date-range-picker
                    [dateRangeQuery]="(dateRangeQuery$ | async) ?? ''"
                    (dateRangeQueryChange)="onDateRangeQueryChange($event)">
                </mam-search-date-range-picker>
              </ng-container>

              <!-- Live toolbar elements -->
              <ng-container *ngIf="state.isLive">
                <mam-live-toolbar-content></mam-live-toolbar-content>

                <ng-container *ngIf="showSiteFilter">
                  <mat-divider [vertical]="true"></mat-divider>

                  <mam-search-facet-group
                      *ngIf="facets.liveSiteFacetGroup"
                      class="live-filter"
                      #facetGroup
                      [facetGroup]="facets.liveSiteFacetGroup"
                      (menuClosed)="onLiveMenuClose($event)">
                  </mam-search-facet-group>
                </ng-container>
              </ng-container>

            </div>

            <!-- Scroll right button (if necessary)-->
            <button class="next-button scroll-button"
                [class.invisible]="!showFacetsScrollRight"
                [class.hidden]="!showFacetsScrollLeft && !showFacetsScrollRight"
                aria-hidden="true"
                mat-icon-button
                (click)="scrollRight()"
                tabindex="-1"
                mam-firebase-ga="Scroll search facets right">
              <mat-icon>chevron_right</mat-icon>
            </button>

            <!-- Clear all facets button (if necessary)-->
            <button mat-button
                *ngIf="isClearAllFacetsVisible"
                class="clear-all-facets"
                (click)="clearAllFacetsAndNavigate()"
                mam-firebase-ga="Clear all search facets from toolbar">
                <mat-icon
                  class="clear-facets-icon"
                  matTooltip="CLEAR ALL"
                  matTooltipPosition="below">
                  close
                </mat-icon>
                <span>CLEAR ALL</span>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Details breadcrumb -->
    <mam-details-breadcrumb *ngIf="currentView === HomeView.DETAILS">
    </mam-details-breadcrumb>

    <mat-progress-bar
        *ngIf="progressbar.visible$ | async"
        @fadeProgressBar
        mode="indeterminate">
    </mat-progress-bar>
  </mat-toolbar>
  </ng-container>

  <div class="content">
    <!-- Side Navbar -->
    <div class="sidenav">
      <mam-home-sidenav
        [currentView]="currentView"
        [failedTaskCount]="(failedTaskCount$ | async) || 0"
        [sites]="(selectableSites$ | async) || []"
        (selectView)="selectView($event)">
      </mam-home-sidenav>
    </div>

    <main [ngClass]="{'background-image':(currentView === HomeView.LIVE)}" [class.staging-view]="isStagingView" restore-scroll>
      <div class="main-block">
        <!-- Current view (except for search results) -->
        <ng-container *ngIf="currentView !== HomeView.SEARCH_RESULTS">
          <router-outlet></router-outlet>
        </ng-container>

        <!-- Search results -->
        <mam-search-results
            (facetsClear)="clearAllFacetsAndNavigate()"
            *ngIf="currentView === HomeView.SEARCH_RESULTS">
        </mam-search-results>
      </div>

      <!-- Persistent Panel -->
      <mam-persistent-panel
          *ngIf="
            currentView === HomeView.DETAILS ||
            currentView === HomeView.SEARCH_RESULTS ||
            currentView === HomeView.STAGING"
          [class.has-border]="currentView !== HomeView.DETAILS">
      </mam-persistent-panel>

      <!-- Lists assets currently being processed. Hidden in details view to not
        overlap the "Add clip to multiple clipbins" menu. Left aligned in the Transfer
      page to not cover the metadata panel's "approve" button. -->
      <mam-processing-status
          *ngIf="currentView === HomeView.JOBS">
      </mam-processing-status>

      <!-- Displays a scrollable tooltip help -->
      <mam-search-help
          *ngIf="isSearchHelpDisplayed"
          @slideHelp
          (closePanel)="isSearchHelpDisplayed = false">
      </mam-search-help>
    </main>
  </div>
</ng-container>

<div class="maintenance-warning" *ngIf="isMaintenance">
  Under maintenance, only internal users allowed.
</div>
