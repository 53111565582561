/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GoogleRpcStatus } from './google-rpc-status';


/**
 * PFRStateInfo represents the state of the partialFile generation and on-prem download process.
 */
export interface ApiPFRStateInfo { 
    /**
     * Output only. PFR Create Time.
     */
    readonly createTime?: string;
    errorDetails?: GoogleRpcStatus;
    /**
     * Output only. Output Cloud Storage url with the format gs://{bucket}/{object}.
     */
    readonly outputGcsUri?: string;
    /**
     * State of PFR and download.
     */
    state?: ApiPFRStateInfo.StateEnum;
    /**
     * Output only. PFR Update Time.
     */
    readonly updateTime?: string;
}
export namespace ApiPFRStateInfo {
    export type StateEnum = 'STATE_UNSPECIFIED' | 'PENDING' | 'RESTORE_FAILED' | 'FILE_GENERATED' | 'DOWNLOAD_FAILED' | 'DOWNLOAD_COMPLETED';
    export const StateEnum = {
        STATE_UNSPECIFIED: 'STATE_UNSPECIFIED' as StateEnum,
        PENDING: 'PENDING' as StateEnum,
        RESTORE_FAILED: 'RESTORE_FAILED' as StateEnum,
        FILE_GENERATED: 'FILE_GENERATED' as StateEnum,
        DOWNLOAD_FAILED: 'DOWNLOAD_FAILED' as StateEnum,
        DOWNLOAD_COMPLETED: 'DOWNLOAD_COMPLETED' as StateEnum
    };
}


