import {Injectable} from '@angular/core';
import {AbstractControl, FormControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {DateTime} from 'luxon';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, map, switchMap, take, tap} from 'rxjs/operators';

import {Metadata} from 'models';

import {assertArray, assertExists, assertString, assertTruthy, checkExhaustive} from '../asserts/asserts';
import {ErrorResponse, isErrorResponse, mapOnSuccess} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {NEW_ASSET_COPY_NAME} from '../services/asset_api_service';
import {Asset, AssetCopy, AssetService, Original} from '../services/asset_service';
import {DialogService} from '../services/dialog_service';
import {MetadataSource} from '../services/ias_types';
import {DisplaySegment, PlayFeedService} from '../services/play_feed_service';
import {MetadataProperty, MetadataSchema, PropertyType} from '../services/schema_api_service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';
import {TimezoneService} from '../services/timezone_service';
import {UtilsService} from '../services/utils_service';

/**
 * Provides api to control MetadataFields component. Requires MetadataFields to
 * be present.
 *
 * MetadataService is tested via MetadataPanel component in
 * `./metadata_panel_test.ts`
 */
@Injectable({providedIn: 'root'})
export class MetadataService {
  private readonly isEditingInternal$ = new BehaviorSubject(false);

  /** Emits current mode of metadata panel: read-only (false) or edit (true). */
  readonly isEditing$: Observable<boolean> = this.isEditingInternal$;

  readonly isSaving$ = new BehaviorSubject(false);

  /** Items whose metadata is displayed / edited. */
  private readonly items$ = new BehaviorSubject<Item[]>([]);

  /**
   * Contains dynamically generated form controls corresponding to the current
   * metadata field list. `FormArray` was chosen over `FormGroup` because it is
   * currently better suited for the case when we need to remove and re-create
   * all form controls. For instance it has a `clear` method which FormGroup
   * lacks.
   */
  readonly fieldFormArray = new UntypedFormArray([]);

  /**
   * Top level form group that hosts the metadata field array.
   * Needed, because FormArray is not intended to be used outside of FormGroup:
   * https://github.com/angular/angular/issues/30264
   */
  readonly metadataFormGroup =
      new UntypedFormGroup({'fields': this.fieldFormArray});

  /** Dropdown selection. */
  readonly selectedSchema = new FormControl<MetadataSchema|undefined>(
      undefined, [Validators.required]);

  private readonly fieldsChangedInternal$ = new Subject<void>();

  readonly fieldsChanged$: Observable<void> = this.fieldsChangedInternal$;

  /** An ordered list of all visible metadata fields. */
  fields: MetadataField[] = [];

  constructor(
      private readonly stateService: StateService,
      private readonly snackBar: SnackBarService,
      private readonly assetService: AssetService,
      private readonly errorService: ErrorService,
      private readonly timezone: TimezoneService,
      private readonly utils: UtilsService,
      private readonly dialogService: DialogService,
      private readonly playFeedService: PlayFeedService,
  ) {
    // Get schema shared by all current items or undefined.
    this.items$
        .pipe(
            // Ignore emission if it's the same items and isEditing === true.
            // This can happen when saving result of batch edit and assetChanged
            // events firing multiple times. Same items will be set when save
            // has finished and parent view reacts to asset/segment changed
            // events.
            distinctUntilChanged(
                (a, b) => this.areSameItems(a, b) && this.isEditing()),
            switchMap(items => {
              if (!items.length) return of(undefined);
              let sharedSchema: string|undefined =
                  items[0].getMetadata().schema;
              // Verify that all items share the same schema.
              for (const item of items) {
                if (item.getMetadata().schema !== sharedSchema) {
                  sharedSchema = undefined;
                  break;
                }
              }
              if (!sharedSchema) return of(undefined);
              this.stateService.isPanelLoading$.next(true);
              return this.assetService.getSchema(sharedSchema);

            }))
        .subscribe(schema => {
          this.stateService.isPanelLoading$.next(false);
          if (schema === null) {
            this.snackBar.error('Failed to load metadata schema');
          }

          // Reset saving status as it is no longer relevant for new items.
          this.isSaving$.next(false);
          this.itemSchema = schema ?? undefined;
          // Set selected schema + trigger field rebuilding.
          this.selectedSchema.setValue(schema);
        });

    // Rebuild fields when the user changes the schema.
    this.selectedSchema.valueChanges.subscribe(schema => {
      this.rebuildFields(this.items$.value, schema);
    });

    this.isEditing$.pipe(distinctUntilChanged()).subscribe((isEditing) => {
      // Synchronize schema selector state with edit mode.
      if (isEditing) {
        this.selectedSchema.enable({emitEvent: false});
      } else {
        this.selectedSchema.disable({emitEvent: false});
        // Marking selectedSchema to reset control's state and prevent it
        // from returning is dirty=true. This is needed because
        // selectedSchema control persists even when the items change unlike
        // fieldFormArray that gets rebuilt.
        this.selectedSchema.markAsPristine();
        this.selectedSchema.markAsTouched();
      }
    });

    // Currently clips are not updated when assetChanged is
    // emitted, so items$ will not be re-emitted and are not updated in-place by
    // AssetApiService, we need to update them manually here. Remove this code
    // when clip cache is invalidated on assetChanged and
    // DetailsNavigationService re-emits context on clip change.
    this.assetService.assetsChanged$.subscribe(({updates}) => {
      const items = this.items$.value;
      if (!items.length || !updates) return;
      let hasChanges = false;
      for (const item of items) {
        if (!(item instanceof AssetItem) || !item.value.original) continue;

        const changed = updates.get(item.value.original.name);
        if (!changed) continue;
        hasChanges = true;
        item.value.original = changed;
        item.value.assetMetadata = changed.assetMetadata;
      }

      if (hasChanges) {
        this.items$.next(items);
      }
    });
  }

  setItems(container: ItemsContainer) {
    if (container.type === SupportItemTypes.ASSET) {
      this.items$.next(container.items.map(
          item => this.createItem({item, type: container.type})));
      return;
    }

    // Unfortunately we have to repeat exactly the same logic here to allow TS
    // catch up on type restrictions.
    if (container.type === SupportItemTypes.SEGMENT) {
      this.items$.next(container.items.map(
          item => this.createItem({item, type: container.type})));
      return;
    }

    if (container.type === SupportItemTypes.CUTDOWN) {
      this.items$.next(container.items.map(
          item => this.createItem(
              {item, parent: container.parent, type: container.type})));
      return;
    }

    checkExhaustive(container);
  }

  /** Cancels metadata editing if it was in progress. */
  cancel() {
    this.isEditingInternal$.next(false);
    // We need to rebuild fields to discard unsaved user changes.
    this.rebuildFields(this.items$.value, this.itemSchema);
  }

  /** Initiates metadata editing.  */
  edit() {
    this.isEditingInternal$.next(true);
    // We do rebuild so that pre-processing like pre-setting asset title kicks
    // in. Otherwise this can be replaced with
    // this.fieldsChangedInternal$.next();
    this.rebuildFields(this.items$.value, this.itemSchema);
  }

  /**
   * Save the schema and metadata key-value pairs for all provided items.
   */
  save() {
    if (!this.isEditing() || this.isSaving$.value) return;

    const initialItems = [...this.items$.value] as Item[];
    const schema = this.selectedSchema.value;

    if (!this.isValid() || !initialItems.length || !schema) {
      this.snackBar.error('Invalid metadata could not be saved.');
      return;
    }

    const formValues = this.getFormValueObject();

    // Allow navigating away without confirmation at this point.
    this.isSaving$.next(true);

    this.stateService.isPanelLoading$.next(true);
    this.utils
        .batchApiCalls(
            initialItems,
            item => {
              const merged = this.mergeMetadata(item, schema, formValues);
              return item.update(schema, merged);
            })
        .subscribe(responses => {
          this.stateService.isPanelLoading$.next(false);
          const errors: string[] = [];

          for (const response of responses) {
            if (isErrorResponse(response)) {
              errors.push(response.message);
            }
          }

          const firstError = errors.find(e => !!e);
          if (errors.length > 0) {
            const errorMsg = `Failed to update metadata`;
            const message = responses.length === 1 ?
                errorMsg :
                `${errorMsg} (${errors.length})`;
            this.snackBar.error({message, details: firstError, doNotLog: true});
            return;
          }

          this.snackBar.message('Metadata updated successfully');

          // User has navigated away or changed active items
          if (!this.isSaving$.value) return;

          this.isSaving$.next(false);

          // Keep editing mode when there are errors.
          if (errors.length > 0) return;

          // Store selected schema as "current" schema which we'll go to if for
          // example the user selects another schema and then clicks cancel.
          this.itemSchema = schema;
          this.isEditingInternal$.next(false);
        });
  }

  /**
   * If metadata is being edited and the user has already made changes then it
   * shows confirmation dialog and emits the result. Otherwise emits true.
   */
  cancelWithConfirmation(): Observable<boolean> {
    return combineLatest([this.isEditing$, this.isSaving$])
        .pipe(
            take(1),
            switchMap(([isEditing, isSaving]) => {
              // Can proceed without confirmation if there are no editing.
              if (!isEditing) return of(undefined);
              // If save is in progress, prevent switching
              if (isSaving) {
                this.snackBar.message('Updating metadata, please wait..');
                return of(false);
              }
              // Skip confirmation if user didn't change anything.
              if (!this.isDirty()) return of(true);

              return this.dialogService
                  .showConfirmation({
                    title: 'Cancel edit',
                    question: 'Unsaved changes will be discarded. Proceed?',
                    primaryButtonText: 'Proceed',
                  })
                  .pipe(map(Boolean));
            }),
            tap(result => {
              if (result) {
                this.cancel();
              }
            }),
            map(result => {
              // undefined means that cancel wasn't even necessary.
              if (result === undefined) return true;
              return result;
            }),
        );
  }

  /** Indicates if schema or any metadata fields were changed by the user. */
  isDirty() {
    return this.metadataFormGroup.dirty || this.selectedSchema.dirty;
  }

  /** Indicates if schema and metadata fields have valid values. */
  isValid() {
    return this.metadataFormGroup.valid && this.selectedSchema.valid;
  }

  isEditing() {
    return this.isEditingInternal$.value;
  }

  /** Adds new item to an array field. */
  addArrayItem(fieldIndex: number) {
    const control = this.fieldFormArray.at(fieldIndex);
    assertTruthy(
        control instanceof UntypedFormArray,
        'MetadataService.addArrayItem not FormArray');
    const type = this.fields[fieldIndex].property.type ?? DEFAULT_TYPE;
    const childControl = this.createItemFormControl('', type);

    // Remove once newly created controls for array fields
    // are focused.
    // Newly created controls will not be validated until the user clicks on
    // them. Marking the control as touched to force immediate validation.
    childControl.markAsTouched();
    // Mark parent control as changed by the user to remove "mixed content"
    // indicator.
    control.markAsDirty();

    // Validators will fire here. Required validator relies on `control.dirty`.
    // So make sure control's state is set beforehand.
    control.push(childControl);
  }

  /** Removes an item from an array field. */
  removeArrayItem(fieldIndex: number, itemIndex: number) {
    const control = this.fieldFormArray.at(fieldIndex);
    assertTruthy(
        control instanceof UntypedFormArray,
        'MetadataService.removeArrayItem not FormArray');

    // Mark parent control as changed by the user to remove "mixed content"
    // indicator.
    control.markAsDirty();

    // Validators will fire here. Required validator relies on `control.dirty`.
    // So make sure control's state is set beforehand.
    control.removeAt(itemIndex);
  }

  /**
   * Merges metadata from the item with metadata entered by the user.
   */
  getMergedMetadata(container: ItemContainer) {
    const item = this.createItem(container);
    const schema = this.selectedSchema.value;

    if (!this.isValid() || !schema) return null;

    const formValues = this.getFormValueObject();
    return this.mergeMetadata(item, schema, formValues);
  }

  resetState() {
    const hasUnsavedChanges =
        this.isEditing() && this.isDirty() && !this.isSaving$.value;
    this.setItems({items: [], type: SupportItemTypes.ASSET});
    this.cancel();
    if (hasUnsavedChanges) {
      this.snackBar.message('Changes to metadata have been discarded.');
    }
  }

  /** Initial common schema of the current */
  private itemSchema: MetadataSchema|undefined = undefined;

  /** Item type check should be enforced before executing this method. */
  private createItem(value: ItemContainer): AssetItem|SegmentItem|CutdownItem {
    switch (value.type) {
      case SupportItemTypes.ASSET:
        return new AssetItem(value.item, this.assetService, this.utils);
      case SupportItemTypes.SEGMENT:
        return new SegmentItem(value.item, this.playFeedService);
      case SupportItemTypes.CUTDOWN:
        return new CutdownItem(value.item, value.parent);
      default:
        checkExhaustive(value);
    }
  }

  /**
   * Builds metadata object based on the form values. Ignores fields that had
   * mixed content and were not changed by user.
   */
  private getFormValueObject() {
    const formValueObject: Record<string, unknown> = {};

    for (let i = 0; i < this.fields.length; i++) {
      const field = this.fields[i];
      const property = field.property;
      const control = this.fieldFormArray.at(i);
      // Skip unchanged mixed content fields.
      if (!control.dirty && field.isMixed) continue;
      const value = control.value;
      const apiValue =
          this.convertToApiValue(value, property.type, property.isArray);
      if (apiValue === INVALID_VALUE_TOKEN) {
        this.errorService.handle(`Invalid value for field ${
            property.id} has bypassed the validation`);
      }
      formValueObject[property.id] = apiValue;
    }

    return formValueObject;
  }

  /**
   * Assembles metadata object based on existing metadata properties and values
   * provided by the user. If the metadata had some values that are not
   * described by the current schema they will be lost.
   */
  private mergeMetadata(
      item: Item, schema: MetadataSchema, formValues: Record<string, unknown>) {
    const merged: Record<string, unknown> = {};
    const jsonMetadata = item.getMetadata().jsonMetadata;

    // Iterate over schema properties to make sure properties that are
    // not displayed are preserved.
    for (const property of Object.values(schema.properties)) {
      const id = property.id;
      // If the value was not modified by user make hardcoded changes like
      // presetting title and pre-filling single enum fields.
      if (Object.prototype.hasOwnProperty.call(formValues, id)) {
        merged[id] = formValues[id];
      } else {
        const value = this.preProcessApiValue(item, property, jsonMetadata[id]);
        if (property.visible) {
          merged[id] = value;
        } else {
          // For properties that are not shown in the form do best effort
          // validity check and carry value over if it is valid.
          const apiValue =
              this.convertToApiValue(value, property.type, property.isArray);
          if (apiValue !== INVALID_VALUE_TOKEN) {
            merged[id] = apiValue;
          }
        }
      }
    }
    return merged;
  }

  /**
   * Builds reactive form fields based on the provided metadata values and
   * selected metadata schema.
   */
  private rebuildFields(items: Item[], schema: MetadataSchema|null|undefined) {
    this.selectedSchema.setValue(schema, {emitEvent: false});
    this.fields = schema && items.length ? this.getFields(items, schema) : [];
    this.fieldFormArray.clear({emitEvent: false});

    for (const field of this.fields) {
      const control = this.createFormControl(field);
      this.fieldFormArray.push(control, {emitEvent: false});
    }

    // Highlight errors right away.
    this.fieldFormArray.markAllAsTouched();

    // Remove `dirty` state when the group is reset so that we don't ask
    // for user confirmation to cancel the form with no changes.
    this.metadataFormGroup.markAsPristine();

    // For new items for confirmation message when the user is trying to
    // navigate elsewhere.
    if (items.some(item => item.isNew)) {
      this.metadataFormGroup.markAsDirty();
    }

    this.fieldsChangedInternal$.next();
  }

  /** Builds fields based on the metadata and the schema. */
  private getFields(items: Item[], schema: MetadataSchema): MetadataField[] {
    const isEditing = this.isEditingInternal$.value;
    return Object.values(schema.properties)
        .filter(p => p.visible)
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(property => {
          const values = items.map(item => {
            const value = item.getMetadata().jsonMetadata[property.id];
            // Pre-set some values via preProcessApiValue in edit mode only.
            // We don't want to pre-set them during view mode to not mislead the
            // user into thinking that these values are actually set (persisted)
            // on the metadata. Otherwise, for example, they may search assets
            // by one of these values and would be surprised by some assets
            // missing from the results.
            return !isEditing ? value :
                                this.preProcessApiValue(item, property, value);
          });
          const valueInfo = this.generateFieldValue(property, values);
          return {...valueInfo, property};
        });
  }

  /**
   * Applies pre-defined transformations for certain metadata properties (e.g.
   * setting asset title if it is empty).
   */
  private preProcessApiValue(
      item: Item, property: MetadataProperty, value: unknown) {
    if (!value) {
      // When there is no value check if default value is provided.
      const defaultValue = item.provideDefaultMetadataFieldValue(property);
      if (defaultValue != null) return defaultValue;
    }

    // Pre-select single enum values.
    // Empty option is prepended only for non-required, non-array fields.
    const isSingleEnum = property.required || property.isArray ?
        property.options?.length === 1 :
        property.options?.length === 2;
    if (isSingleEnum) {
      // Target non-empty option, which is either the only option or 2nd out
      // of the 2, meaning it always goes last.
      assertExists(property.options);
      const enumValue = property.options[property.options.length - 1];

      // Apply value conversion because enums can contain non-api values, e.g.
      // "FALSE" for a boolean field.
      return this.convertToDefaultApiValue(property, enumValue);
    }

    // When no value exists, pre-set the property to default value if available.
    if (property.defaultValue && !value) {
      // Apply value conversion because default can contain non-api value, e.g.
      // "FALSE" for a boolean field.
      return this.convertToDefaultApiValue(property, property.defaultValue);
    }

    return value;
  }

  private convertToDefaultApiValue(
      property: MetadataProperty, value: string|string[]) {
    if (property.isArray && !Array.isArray(value)) {
      value = [value];
    }
    return this.convertToApiValue(value, property.type, property.isArray);
  }

  /**
   * Constructs a value information object for given metadata property unified
   * across all provided items.
   *
   * @param property metadata property the values correspond to
   * @param propertyValues Array where each entry represents a certain items's
   *     value for given property.
   */
  private generateFieldValue(
      property: MetadataProperty, propertyValues: unknown[]): FieldValueInfo {
    assertTruthy(
        propertyValues.length > 0,
        'At least one metadata field value should be provided');

    const itemCount = propertyValues.length;
    const hasInvalid = propertyValues.some(
        value => !this.isValidApiValue(
            value, property.type, property.required, property.isArray));

    //  If at least one item has invalid value then drop this value for all
    //  items. Don't show mixed content as we can't retain current values
    //  if the user doesn't change the field.
    if (hasInvalid) return {value: undefined, invalid: true};

    //------------Single item mode------------
    if (itemCount === 1) {
      let value = propertyValues[0];
      if (property.isArray && propertyValues[0]) {
        assertArray(value, 'MetadataService.generateFieldValue expected array');
        // Remove duplicates from array fields.
        value = [...new Set(value)];
      }
      return {value, isMixed: false};
    }

    //------------Bulk item mode------------

    // Non-array fields.
    if (!property.isArray) {
      const firstValue = propertyValues[0];
      const isMixed = propertyValues.some(value => value !== firstValue);
      return {isMixed, value: isMixed ? undefined : firstValue};
    }

    // If any present value wasn't an array or was empty but required it
    // would be caught by `isInvalid` check at the start of the method.
    const arrayValues: unknown[][] = propertyValues.map(value => {
      value = value || [];
      assertArray(
          value, 'MetadataService.generateFieldValue expected array in map');
      return value;
    });

    const emptyValueCount = arrayValues.filter(value => !value.length).length;
    if (emptyValueCount > 0) {
      const isMixed = emptyValueCount < itemCount;
      return {isMixed, value: undefined};
    }

    // 2 arrays are considered identical if the have the same items in the
    // same order. If all array values are identical we show them. Otherwise
    // we show mixed content and an array of common items. Common item is an
    // item present in all array values.

    // For each item record its position in all arrays.
    const itemValueIndexes = new Map<unknown, number[]>();
    for (const array of arrayValues) {
      // Remove duplicates
      const unique = [...new Set(array)];
      for (const [i, element] of unique.entries()) {
        const indexes = itemValueIndexes.get(element) || [];
        indexes.push(i);
        itemValueIndexes.set(element, indexes);
      }
    }
    // Indicates that all items have the same order in all arrays.
    let sameOrder = true;
    const commonItems: unknown[] = [];
    for (const [item, indexes] of itemValueIndexes.entries()) {
      if (indexes.length === itemCount) {
        commonItems.push(item);
      }
      // Check if all occurrences of the item had the same position.
      sameOrder = sameOrder && indexes.every(i => i === indexes[0]);
    }

    const isMixed = commonItems.length !== itemValueIndexes.size || !sameOrder;
    return {isMixed, value: commonItems};
  }

  /** Creates reactive form control based on provided details. */
  private createFormControl(field: MetadataField): AbstractControl {
    const {type, isArray, required} = field.property;
    const validators = [this.getTypeValidator(type, isArray)];
    if (required) {
      validators.push(this.getRequiredValidator(field));
    }
    const convertedValue = this.convertFromApiValue(field.value, type, isArray);

    // Create regular form control for non-array fields.
    if (!isArray) return new UntypedFormControl(convertedValue, validators);

    // Create FormArray control that contains controls for each item in the
    // value array.
    return new UntypedFormArray(
        (convertedValue as unknown[])
            .map(itemValue => this.createItemFormControl(itemValue, type)),
        validators);
  }

  /**
   * Creates reactive form control representing a single item inside a metadata
   * property of type array (`isArray` = true).
   */
  private createItemFormControl(value: unknown, type: PropertyType) {
    // Disallow empty item fields as they may cause api validation failure.
    const validators = [this.getTypeValidator(type), Validators.required];
    const convertedValue = this.convertFromApiValue(value, type);
    return new UntypedFormControl(convertedValue, validators);
  }

  /** Parses given metadata property value based on the property description. */
  private convertFromApiValue(
      value: unknown, type: PropertyType, isArray = false): unknown {
    if (isArray) {
      let arrayValue = value as unknown[];
      if (!Array.isArray(arrayValue)) {
        arrayValue = (arrayValue ? [arrayValue] : []);
      }
      // Recursively call convertFromApiValue for each item.
      return arrayValue.map(item => this.convertFromApiValue(item, type));
    }

    switch (type) {
      // For now all fields apart from arrays are edited as text.
      case PropertyType.STRING:
      case PropertyType.NUMBER:
      case PropertyType.INTEGER:
        return String(value ?? '');
      // Booleans are rendered as TRUE or FALSE.
      case PropertyType.BOOLEAN:
        if (value == null) return '';
        return value ? 'TRUE' : 'FALSE';
      case PropertyType.DATE:
      case PropertyType.DATE_TIME:
        return this.convertFromApiDate(value, type);
      default:
        checkExhaustive(type);
    }
  }

  /**
   * Custom validator that verifies that form field value or item value is valid
   * for the given metadata property type.
   */
  private getTypeValidator(type: PropertyType, isArray = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors|null => {
      const apiValue = this.convertToApiValue(control.value, type, isArray);
      if (apiValue !== INVALID_VALUE_TOKEN) return null;
      return {[type]: {value: control.value}};
    };
  }

  /**
   * Custom validator for the form fields that are tied to required metadata
   * properties. If user has made changes, verifies that the value is present,
   * otherwise verifies that the pre-existing metadata property value is valid.
   */
  private getRequiredValidator(field: MetadataField): ValidatorFn {
    return (control: AbstractControl): ValidationErrors|null => {
      const apiValue = this.convertToApiValue(
          control.value, field.property.type, field.property.isArray);
      // Has value.
      if (apiValue !== undefined) return null;

      // Check if pre-existing values are valid. Used in bulk edit when the
      // unified value can be empty for a required field but all edited assets
      // actually have a valid value and user didn't touch this field yet.
      if (!control.dirty && !field.invalid) return null;

      return {'required': {value: apiValue}};
    };
  }

  /**
   * Converts values entered by the user to values that are accepted by the API.
   * For example if the user entered '2020-01-01' in the date field we convert
   * this date to the ISO format.
   *
   * Returns `INVALID_VALUE_TOKEN` if failed to convert which is useful for
   * field validation.
   */
  private convertToApiValue(
      value: unknown, type: PropertyType, isArray = false): unknown {
    // Allow fields with no value set. Required fields will be verified with
    // a dedicated "required" validator.
    if (value === '') return undefined;

    if (isArray) {
      if (!Array.isArray(value)) return INVALID_VALUE_TOKEN;
      // Recursively call convertToApiValue for each item. Invalid item values
      // should be caught by type validator set up on item level. Empty items
      // are filtered out.
      const convertedArray =
          value.map(item => this.convertToApiValue(item, type))
              .filter(v => v != null);
      // Don't save empty array.
      if (!convertedArray.length) return undefined;
      return convertedArray;
    }

    switch (type) {
      case PropertyType.STRING:
        return value;
      case PropertyType.BOOLEAN: {
        if (typeof value !== 'string') return INVALID_VALUE_TOKEN;
        // Booleans are selected as the uppercase string "TRUE" or "FALSE".
        const boolString = value.trim().toLocaleLowerCase();
        if (boolString === 'true') return true;
        if (boolString === 'false') return false;
        return INVALID_VALUE_TOKEN;
      }
      case PropertyType.NUMBER: {
        if (typeof value !== 'string') return INVALID_VALUE_TOKEN;
        // We currently use text field to deal with number values.
        const trimmed = value.trim();
        if (!trimmed) return INVALID_VALUE_TOKEN;
        const numericValue = Number(trimmed);
        return Number.isNaN(numericValue) ? INVALID_VALUE_TOKEN : numericValue;
      }
      case PropertyType.INTEGER: {
        // We currently use text field to deal with integer values.
        const asNumber = this.convertToApiValue(value, PropertyType.NUMBER);
        // An integer is a valid number with no decimal part.
        if (asNumber === INVALID_VALUE_TOKEN) return INVALID_VALUE_TOKEN;
        return Math.trunc(asNumber as number) !== asNumber ?
            INVALID_VALUE_TOKEN :
            asNumber;
      }
      case PropertyType.DATE:
      case PropertyType.DATE_TIME:
        if (typeof value !== 'string') return INVALID_VALUE_TOKEN;
        // Dates are expected to be entered in ISO format.
        return this.convertToApiDate(value, type) ?? INVALID_VALUE_TOKEN;
      default:
        checkExhaustive(type);
    }
  }

  /**
   * Validates asset metadata value for correctness. Useful when switching
   * schemas during to determine if asset value is valid for the new schema.
   */
  private isValidApiValue(
      value: unknown, type: PropertyType, required: boolean,
      isArray = false): unknown {
    if (value === undefined) return !required;

    if (isArray) {
      if (!Array.isArray(value)) return false;
      // Consider empty array to be invalid for required fields.
      if (!value.length) return !required;
      // Recursively call validateApiValue for each item.
      return value.every(item => this.isValidApiValue(item, type, true));
    }

    switch (type) {
      case PropertyType.STRING:
        // Consider empty string to be invalid for required fields.
        return typeof value === 'string' && (Boolean(value) || !required);
      case PropertyType.BOOLEAN: {
        return typeof value === 'boolean';
      }
      case PropertyType.NUMBER: {
        return Number.isFinite(value);
      }
      case PropertyType.INTEGER: {
        return Number.isInteger(value);
      }
      case PropertyType.DATE:
      case PropertyType.DATE_TIME: {
        if (typeof value !== 'string') return false;
        // Dates are expected to be entered in ISO format.
        const date = DateTime.fromISO(value);
        return date.isValid;
      }
      default:
        checkExhaustive(type);
    }
  }

  /**
   * Parses given date string in pre-configured timezone and converts it to UTC
   * ISO date string. For date only values timezone is ignored.
   *
   * @see TimezoneService for more information on pre-configured timezone.
   */
  private convertToApiDate(
      value: string, type: PropertyType.DATE|PropertyType.DATE_TIME) {
    const date = type === PropertyType.DATE_TIME ?
        this.timezone.parseFromIso(value) :
        DateTime.fromISO(value);

    if (!date.isValid) return null;
    return type === PropertyType.DATE_TIME ? date.toUTC().toISO() :
                                             date.toISODate();
  }

  /**
   * Parses given date/date-time api value and formats it to ISO in
   * pre-configured timezone. For date only values timezone is ignored.
   *
   * @see TimezoneService for more information on pre-configured timezone.
   */
  private convertFromApiDate(
      value: unknown, type: PropertyType.DATE|PropertyType.DATE_TIME) {
    if (!value) return '';
    assertString(
        value,
        `MetadataService.convertFromApiDate expected string for ${type}`);

    const date = type === PropertyType.DATE_TIME ?
        this.timezone.parseFromIso(value) :
        DateTime.fromISO(value);

    if (!date.isValid) return value;
    return type === PropertyType.DATE_TIME ? date.toISO() : date.toISODate();
  }

  private areSameItems(items1: Item[], items2: Item[]) {
    if (items1.length !== items2.length) return false;

    const names1 = new Set(items1.map(item => item.name));
    return items2.every(item => names1.has(item.name));
  }
}

/**
 * Contains field definition from the schema and value from the source items.
 */
export interface MetadataField extends FieldValueInfo {
  source?: MetadataSource;
  property: MetadataProperty;
}

/** Contains extended information about the value used in bulk edit. */
interface FieldValueInfo {
  /** Unified metadata field api value based on edited asset values. */
  value: unknown;
  /** Indicates that the field value differs between provided assets. */
  isMixed?: boolean;
  /**
   * Indicates that metadata property value for at least one of the provided
   * items fails "type" or "required" validation.
   */
  invalid?: boolean;
}

/** Immutable token that signals that the value is invalid. */
const INVALID_VALUE_TOKEN = {
  invalid: true
} as const;

/** Fallback metadata property type. */
const DEFAULT_TYPE = PropertyType.STRING;

/** Item type supported by MetadataService. */
export const enum SupportItemTypes {
  ASSET = 'asset',
  SEGMENT = 'segment',
  CUTDOWN = 'cutdown',
}

/**
 * Container that wraps different objects containing metadata and acts as an
 * adaptor to allow MetadataService to interact with them.
 */
interface Item {
  /** Item's unique id. */
  readonly name: string;
  /**
   * Indicates if this is a new item, not persisted on the BE side.  If set to
   * `true` then the metadata form will be marked as dirty and promt
   * confirmation from the user if they try to navigate away.
   */
  readonly isNew?: boolean;
  /** Provides item's metadata. */
  getMetadata(): Metadata;
  /**
   * Hook that allows providing item-specific default metadata field value on
   * edit. E.g. asset's `Title` needs to be pre-set to file name.
   */
  provideDefaultMetadataFieldValue(property: MetadataProperty): unknown;
  /** Persists the item. */
  update(schema: MetadataSchema, metadata: Record<string, unknown>):
      Observable<null|ErrorResponse>;
  /**
   * Actual item value that is wrapped by the Item container, e.g. Original,
   * DisplaySegment or AssetCutdown.
   */
  value: unknown;
}

class AssetItem implements Item {
  readonly name: string;
  readonly value: Asset;

  constructor(
      asset: Asset, private readonly assetService: AssetService,
      private readonly utils: UtilsService) {
    this.value = asset;
    this.name = asset.name;
  }

  getMetadata(): Metadata {
    return this.value.assetMetadata;
  }

  provideDefaultMetadataFieldValue(property: MetadataProperty) {
    if (property.id.toLocaleLowerCase() === 'title') {
      return this.extractTitleFromLocation();
    }

    return null;
  }

  update(schema: MetadataSchema, metadata: Record<string, unknown>) {
    const original = this.value.original ?? this.value;
    return this.assetService.updateMetadata(original, schema.name, metadata)
        .pipe(mapOnSuccess(() => null));
  }

  /**
   * Extract the filename without extension from gcsLocationUrl.
   * gcsLocationURL format is gs://path.../fileName.ext.
   *
   * @example
   * extractTitleFromLocation('gs://one/two/three/great_vid.mxf') // 'great vid'
   */
  private extractTitleFromLocation() {
    const locationUrl = this.value.gcsLocationUrl;
    const shortName = this.utils.lastPart(locationUrl).replace(/_/g, ' ');
    const dotIndex = shortName.lastIndexOf('.');
    return dotIndex > 0 ? shortName.slice(0, dotIndex) : shortName;
  }
}

class SegmentItem implements Item {
  readonly name: string;
  readonly value: DisplaySegment;

  constructor(
      segment: DisplaySegment,
      private readonly playFeedService: PlayFeedService,
  ) {
    this.value = segment;
    this.name = segment.name;
  }

  getMetadata() {
    return this.value.metadata;
  }

  provideDefaultMetadataFieldValue(): unknown {
    // There is no segment-specific metadata pre-processing at the moment.
    return null;
  }

  update(schema: MetadataSchema, metadata: Record<string, unknown>) {
    return this.playFeedService.updateMetadata(this.name, schema, metadata)
        .pipe(mapOnSuccess(() => null));
  }
}

class CutdownItem implements Item {
  readonly name: string;
  readonly value: AssetCopy;
  readonly isNew: boolean;

  constructor(
      cutdown: AssetCopy,
      readonly parent: Original,
  ) {
    this.value = cutdown;
    this.isNew = cutdown.name === NEW_ASSET_COPY_NAME;
    this.name = cutdown.name;
  }

  getMetadata(): Metadata {
    return this.value.metadata;
  }

  provideDefaultMetadataFieldValue() {
    // There is no cut-down specific metadata pre-processing at the moment.
    return null;
  }

  update() {
    // CutdownItem implements needs to provide update() implementation so that
    // MetadataService.save works for cutdowns but as of now mam-cut-down-panel
    // is the one that handles cut-down saving and thus there are no need to
    // provide the implementation for this method at the moment.
    return of(new ErrorResponse(
        'No Implementation needed. Otherwise check CutDownPanel.save'));
  }
}

interface AssetContainer {
  item: Asset;
  type: SupportItemTypes.ASSET;
}

interface SegmentContainer {
  item: DisplaySegment;
  type: SupportItemTypes.SEGMENT;
}

interface CutdownContainer {
  item: AssetCopy;
  type: SupportItemTypes.CUTDOWN;
  parent: Original;
}

type ItemContainer = AssetContainer|SegmentContainer|CutdownContainer;

interface AssetsContainer {
  items: Asset[];
  type: SupportItemTypes.ASSET;
}

interface SegmentsContainer {
  items: DisplaySegment[];
  type: SupportItemTypes.SEGMENT;
}

interface CutdownsContainer {
  items: AssetCopy[];
  parent: Original;
  type: SupportItemTypes.CUTDOWN;
}

type ItemsContainer = AssetsContainer|SegmentsContainer|CutdownsContainer;
