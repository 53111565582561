<mat-card
  *ngIf="clip"
    appearance="outlined"
    class="clip_card"
		[class.mam-elevation-1]="viewMode === 'grid'"
		[class.clip_card--list]="viewMode === 'list'"
    [class.active]="active"
    queryParamsHandling="merge"
    mam-firebase-ga="Open clip from its preview"
    [routerLink]="['/clipbin', clip.label, 'clip', clip.name]"
    [queryParams]="{'type': 'bin'}"
    queryParamsHandling="merge"
    [mam-firebase-resource]="clip.title">
  <div class="card-header-image">
    <mam-asset-thumbnail [asset]="clip"></mam-asset-thumbnail>
  </div>
  <div class="card-content">
    <div class="card-content-width">
      <div class="card-content-title" [attr.title]="clip.title">
        {{ clip.title }}
      </div>
      <div class="card-content-subtitle">
        Duration: {{getDuration(clip.duration)}}
      </div>
    </div>
  </div>
</mat-card>
