
import {Injectable} from '@angular/core';

import {UNKNOWN_LENGTH} from './paginator-intl';

/**
 * Service for pagination handling.
 */
@Injectable({providedIn: 'root'})
export class PaginationService {
  getEmptyPagination<T>(pageSize: number): Pagination<T> {
    return {
      pageIndex: 0,
      pageSize,
      pagesCache: [],
      totalCount: UNKNOWN_LENGTH,
      nextPageToken: undefined,
    };
  }
}

/** State of the current pagination */
export interface Pagination<T> {
  /** Current page being displayed */
  pageIndex: number;
  /** How many items per page */
  pageSize: number;
  /** Keep previous pages of results in memory */
  pagesCache: T[];
  /** Count of how many results are currently known */
  totalCount: number;
  /** Used to request the next page of results */
  nextPageToken?: string;
}
