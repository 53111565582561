import {Injectable, NgZone} from '@angular/core';
import {EMPTY, fromEventPattern, Observable} from 'rxjs';

import {assertTruthy} from 'asserts/asserts';

import {ErrorService} from '../error_service/error_service';

import {SnackBarService} from './snackbar_service';

/**
 * Service that detects resize events on an element. Heavily "inspired" by
 * http://google3/cloud/console/web/logs/common/resize_observer/resize_observer_service.ts
 */
@Injectable({providedIn: 'root'})
export class ResizeObserverService {
  constructor(
      private readonly ngZone: NgZone, errorService: ErrorService,
      snackBar: SnackBarService) {
    if (!window.ResizeObserver) {
      errorService.handle(`ResizeObserver : ${navigator.userAgent}`);
      snackBar.error({
        message:
            'Your navigator is not fully supported and may present visual defects.',
        doNotLog: true,
      });
    }
  }

  /**
   * Watches for size changes of an element.
   */
  observe(element: Element): Observable<DOMRectReadOnly> {
    if (!window.ResizeObserver) return EMPTY;

    // Called when an observable is subscribed to.
    const addHandler = (resizeHandler: Function) => {
      // The first time an observer is attached, initialize the set.
      if (!this.activeObservers.has(element)) {
        this.attachObserver(element);
        this.activeObservers.set(element, new Set());
      }

      this.activeObservers.get(element)?.add(resizeHandler);
    };

    // Called when a subscription to an observable is unsubscribed.
    const removeHandler = (resizeHandler: Function) => {
      this.activeObservers.get(element)?.delete(resizeHandler);

      // When the last observer is removed, delete the set and unobserve the
      // element.
      if (!this.activeObservers.get(element)?.size) {
        this.activeObservers.delete(element);
        this.resizeObserver?.unobserve(element);
      }
    };

    return fromEventPattern(addHandler, removeHandler);
  }

  /** Singleton ResizeObserver instance. */
  private resizeObserver?: ResizeObserver;

  /**
   * - key: element observed.
   * - value: set of callbacks executed when the element is resized.
   */
  private readonly activeObservers = new Map<Element, Set<Function>>();

  /**
   * Attaches a resize observer to an element.
   */
  private attachObserver(element: Element): void {
    if (!this.resizeObserver) {
      this.resizeObserver = new window.ResizeObserver((entries) => {
        // BUG(b/170260273): ZoneJS provided by Angular excludes ResizeObserver
        // patch, so we need to propagate the zone manually.
        this.ngZone.run(() => {
          for (const {target, contentRect} of entries) {
            this.handleObservation(target, contentRect);
          }
        });
      });
    }
    this.resizeObserver.observe(element);
  }

  /**
   * Handles observations of resize events and notifies subscribers to the
   * event.
   */
  private handleObservation(element: Element, rect: DOMRectReadOnly) {
    const handlers = this.activeObservers.get(element);
    assertTruthy(handlers, 'handlers should exist');
    for (const handler of handlers) {
      handler.call(undefined, rect);
    }
  }
}
