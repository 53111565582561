import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {combineLatest, Observable, of} from 'rxjs';
import {map, mergeMap, shareReplay} from 'rxjs/operators';

import {CompReelData, Folder} from 'models';

import {ErrorResponse, mapOnSuccess} from '../error_service/error_response';
import {Bin} from '../services/bin.service';
import {ApiCompReelState} from '../services/ias_types';

import {ExportItemResponse, ExportMonitorService, ExportsResponse} from './export_monitor_service';

/**
 * Comp-reel export monitor service.
 */
@Injectable({providedIn: 'root'})
export class ExportMonitorCompReelService extends ExportMonitorService {
  getState(info: CompReelData): ApiCompReelState {
    return info.state;
  }

  getStateMap(item: Bin) {
    return item.compReelInfo?.compReelData;
  }

  listExportItems(
      userQuery: string, date: DateTime, pageSize: number,
      pageToken?: string): Observable<ExportsResponse|ErrorResponse> {
    return this.binService
        .listExportCompReels(userQuery, pageSize, pageToken, date)
        .pipe(
            mapOnSuccess(resp => {
              const {bins, nextPageToken} = resp;

              // eslint-disable-next-line unicorn/no-array-reduce -- FIXME
              const items = bins.reduce<ExportItemResponse[]>((acc, bin) => {
                if (!bin.compReelInfo) return acc;

                const compReelData = bin.compReelInfo.compReelData;
                const folderPaths = Object.keys(compReelData);

                folderPaths.forEach(fp => {
                  const info = compReelData[fp];

                  // Note: info.updateTime is in UTC.
                  // The info.updateTime might not be the selected date since
                  // it's the behavior of status map and the bug from BE
                  // (b/b/254074543).
                  if (this.isSameDate(info.updateTime, date.toISO())) {
                    acc.push({
                      fileName: info.filepath || '-',
                      exportFolder: this.utils.lastPart(fp),
                      clipBinTitle : bin.title,
                      title: bin.title,
                      updateTime: info.updateTime,
                      status: this.assetService.formatExportFolderStatus(
                          info.state),
                      folderPath: fp,
                      clipBinName : bin.name,
                      name: bin.name,
                      errorMessage: info.errorDetails.message,
                    });
                  }
                });

                return acc;
              }, []);

              return {items, nextPageToken};
            }),
        )
      .pipe(
        mergeMap(
          originalResponse => {
            const response = (originalResponse as ExportsResponse);
            const originalItems = response.items;
            const nextPageToken = response.nextPageToken;

            if (!originalItems || originalItems.length == 0 ) return of (originalResponse);
            const completedItems = originalItems.map(
              originalItem => this.taskUserService
                .completeCompReelWithUser(originalItem)
            );

            return combineLatest(completedItems).pipe(
              map(items => {
                return {items,nextPageToken} as ExportsResponse;
              })
            );
          }),
        shareReplay({bufferSize: 1, refCount: false})
      );

  }

  retryExport(
      siteId: string, folder: Folder, itemName: string,
      scratchFolderName?: string): Observable<Bin|ErrorResponse> {
    return this.binService
        .generateCompReel(itemName, folder.name, undefined, scratchFolderName)
        .pipe(mapOnSuccess(response => {
          // If the compReelInfo is not attached after firing generateCompReel
          // API, treat it as an error case.
          if (!response.compReelInfo) {
            return new ErrorResponse('Failed to generate the comp-reel.');
          }
          return response;
        }));
  }
}
