import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';

import { PlayerDetailsService } from '../services/player_details_service';

enum OverlayControlEventType {
  PlayPause,
  Skip,
}

class OverlayControlEvent {
  type!: OverlayControlEventType;
  skipSeconds?: number;
}

@Component({
  selector: 'mam-player-overlay-controls',
  templateUrl: './player_overlay_controls.ng.html',
  styleUrls: ['./player_overlay_controls.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
class PlayerOverlayControls {
  @Input() isPaused!: boolean;
  @Output() overlayControlEvent: EventEmitter<OverlayControlEvent> = new EventEmitter();

  constructor(
    readonly playerDetailsService: PlayerDetailsService,
    readonly elementRef: ElementRef,
  ) {}

  togglePlayPause() {
    this.overlayControlEvent.emit({
      type: OverlayControlEventType.PlayPause
    });
  }

  movePlayheadSeconds(seconds: number) {
    this.overlayControlEvent.emit({
      type: OverlayControlEventType.Skip,
      skipSeconds: seconds
    });
  }

}

export {OverlayControlEvent,OverlayControlEventType, PlayerOverlayControls};
