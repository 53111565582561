import {ApiPFRInfo} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

import {PfrStateInfo} from './pfr-state-info';

/** A UI type representing clip's pfr info */
export class PfrInfo implements RequiredRecursively<ApiPFRInfo> {
  stateMap: Record<string, PfrStateInfo> = {};

  constructor(apiPfrInfo: ApiPFRInfo = {}) {
    for (const [key, value] of Object.entries(apiPfrInfo.stateMap ?? {})) {
      this.stateMap[key] = new PfrStateInfo(value);
    }
  }
}
