import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';

import {ConfirmDialog, ConfirmDialogData} from '../confirm_dialog/confirm_dialog';

/**
 * Provides access to common dialogs likes confirmation dialog.
 */
@Injectable({providedIn: 'root'})
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}

  /**
   * Shows confirmation dialog.
   *
   * @returns observable emitting `undefined` if the user did not confirm the
   *     action, otherwise an object (which may contain the extra choice if this
   *     was an option).
   */
  showConfirmation(config: ConfirmDialogData):
      Observable<{extraChoice?: boolean}|undefined> {
    const dialogRef = this.dialog.open(ConfirmDialog, {data: config});
    return dialogRef.afterClosed();
  }

  /** Shows a message dialog with a single primary 'Ok' button. */
  showMessage(config: ConfirmDialogData): Observable<void> {
    const data: ConfirmDialogData = {
      hideCancelButton: true,
      primaryButtonText: 'Ok',
      ...config,
    };

    const dialogRef = this.dialog.open(ConfirmDialog, {data});
    return dialogRef.afterClosed();
  }
}
