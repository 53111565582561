<!-- Internal users -->
<button
    *ngIf="internal"
    mat-icon-button
    [cdkCopyToClipboard]="getAssetInfo()"
    (click)="$event.stopPropagation(); showAssetInfoCopiedMessage()"
    [matTooltip]="tooltip">
  <mat-icon *ngIf="!isSpinner; else spinner">
    {{icon}}
  </mat-icon>
</button>

<!-- External users -->
<ng-container *ngIf="!internal && showIconForExternalUsers">
  <mat-icon
      *ngIf="!isSpinner; else spinner"
      [matTooltip]="tooltip"
      matTooltipClass="close-from-top">
    {{icon}}
  </mat-icon>
</ng-container>

<ng-template #spinner>
  <mat-spinner
      diameter="20"
      [matTooltip]="internal ? '' : tooltip">
  </mat-spinner>
</ng-template>
