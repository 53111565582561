import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';

import {ErrorService} from '../error_service/error_service';

import {DialogService} from './dialog_service';

/** Used for short messages that are easy to read. */
export const SNACKBAR_SHORT_DURATION = 3000;

/**
 * Used for longer messages or errors and provide enough time to be
 * acknowledged.
 */
export const SNACKBAR_LONG_DURATION = 5000;

/** Default action of the snackbar message. */
const DISMISS = 'DISMISS';

/** Custom snackbars for messages and errors */
@Injectable({providedIn: 'root'})
export class SnackBarService {
  constructor(
      private readonly errorService: ErrorService,
      private readonly matSnackBar: MatSnackBar,
      private readonly dialogService: DialogService,
  ) {}

  /** Displays a notification to the left that can be dismissed. */
  message(message: string, action = DISMISS, duration?: number) {
    const defaultDuration =
        action === DISMISS ? SNACKBAR_SHORT_DURATION : SNACKBAR_LONG_DURATION;

    return this.matSnackBar.open(message, action, {
      panelClass: 'message',
      horizontalPosition: 'left',
      // Use long duration in case the snackbar has an action available.
      duration: duration || defaultDuration,
    });
  }

  /** Displays an error snackbar in the middle of the screen. */
  error(message: string, action?: string, extra?: unknown):
      MatSnackBarRef<TextOnlySnackBar>;
  /**
   * Displays an error snackbar in the middle of the screen with advance
   * configuration.
   */
  error(config: AdvancedErrorSnackbarConfig): MatSnackBarRef<TextOnlySnackBar>;
  error(
      msgOrConfig: AdvancedErrorSnackbarConfig|string, actionText?: string,
      extraError?: unknown): MatSnackBarRef<TextOnlySnackBar> {
    if (typeof msgOrConfig === 'string') {
      return this.error({message: msgOrConfig, actionText, extraError});
    }
    const config = msgOrConfig;

    // Log error to Pantheon.
    if (!config.doNotLog) {
      const errorMessage =
          this.composeLoggedMessage(config.message, config.extraError);
      this.errorService.handle(new Error(errorMessage));
    }

    // Set action button text if provided. Otherwise set button text to
    // 'DETAILS' if additional error message (config.details) is provided.
    const action = config.actionText ?? (config.details && 'DETAILS');

    // Use long duration in case the snackbar has an action available.
    const duration = config.durationMs ??
        (action ? SNACKBAR_LONG_DURATION : SNACKBAR_SHORT_DURATION);

    // Show dialog.
    const ref = this.matSnackBar.open(config.message, action, {
      panelClass: 'error',
      duration,
    });

    const details = config.details;
    if (!details) return ref;

    // When extra message is provide a dialog that displays its content on
    // snackbar action.
    ref.onAction().subscribe(() => {
      this.dialogService.showMessage(
          {title: 'Error Details', question: details});
    });

    return ref;
  }

  private composeLoggedMessage(message: string, extraError: unknown) {
    const errorMessage = `[SNACKBAR] ${message}`;
    // If extra information is provided, add it to the message logged to
    // Pantheon. If it was an error, only send its name and message.
    if (!extraError) return errorMessage;

    if (extraError instanceof Error) {
      return `${errorMessage} | ${extraError.name}: ${extraError.message}`;
    }

    return `${errorMessage} | ${JSON.stringify(extraError)}`;
  }
}

/** Configuration for error snack bar. */
export interface AdvancedErrorSnackbarConfig {
  /** Error message to display  */
  message: string;
  /**
   * Additional error message. If present then the snackbar will have an action
   * that opens a dialog with its contents.
   */
  details?: string;
  /** Defaults to 'DETAILS' when extra message is available. */
  actionText?: string;
  /**
   * If extra information is provided, add it to the message logged to
   * Pantheon. If it was an error, only send its name and message.
   */
  extraError?: unknown;
  /** When true will not log to Pantheon. Defaults to `false`. */
  doNotLog?: boolean;
  /** Duration in milliseconds. If present will override default duration. */
  durationMs?: number;
}
