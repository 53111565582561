import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, startWith, switchMap, withLatestFrom} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';

import {isErrorResponse} from '../error_service/error_response';
import {AssetPreviewTag} from '../player/asset_preview';
import {Original} from '../services/asset_service';
import {SearchService} from '../services/search_service';
import {UtilsService} from '../services/utils_service';

import {ContextType, DetailsNavigationService} from './details_navigation_service';

const ASSET_UPDATE_INTERVAL_MS = 30 * 1000;
/**
 * Shows all assets that represent different camera angles for the same event.
 */
@Component({
  selector: 'mam-multi-camera-view',
  templateUrl: './multi_camera_view.ng.html',
  styleUrls: ['./multi_camera_view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiCameraView implements OnInit {
  /** Assets that belong to the multi-camera event. */
  @Input() initialAssets!: Original[];

  /** Signal to the parent component to hide this component. */
  @Output() readonly closePanel = new EventEmitter<void>();

  assets$?: Observable<Original[]>;

  readonly AssetPreviewTag = AssetPreviewTag;

  readonly ContextType = ContextType;

  constructor(
      private readonly searchService: SearchService,
      private readonly util: UtilsService,
      private readonly detailsNavigation: DetailsNavigationService,
  ) {}

  ngOnInit() {
    assertTruthy(
        this.initialAssets,
        `mam-multi-camera-view: 'initialAssets' is required`);

    // Immediately show provided initial assets but at the same time issue
    // another request without dropping the cache in case initial assets are
    // outdated. Refresh periodically ignoring the cache at a faster rate as
    // live asset state can change at any time.
    this.assets$ =
        this.util.timer(ASSET_UPDATE_INTERVAL_MS)
            .pipe(
                withLatestFrom(this.detailsNavigation.context$),
                switchMap(([iteration, context]) => {
                  assertTruthy(
                      context.asset,
                      'context should have an asset if we reached camera view');
                  assertTruthy(
                      context.asset.camera?.correlationId,
                      'context asset should be part of multi-camera event');

                  return this.searchService.getCameraAssets(
                      context.asset.camera.correlationId, iteration > 0);
                }),
                filter(
                    (resp): resp is Original[] =>
                        !isErrorResponse(resp) && resp.length > 0),
                startWith(this.initialAssets));
  }

  trackAsset(index: number, value: Original) {
    return value.name;
  }
}
