import {LayoutModule} from '@angular/cdk/layout';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';

import {AdminModule} from '../admin/admin_module';
import {DetailsModule} from '../details/details_module';
import {FeatureFlagModule} from '../feature_flag/feature_flag_module';
import {FirebaseModule} from '../firebase/firebase_module';
import {LandingModule} from '../landing/landing_module';
import {LiveModule} from '../live/live_module';
import {PipesModule} from '../pipes/pipes_module';
import {RightPanelModule} from '../right_panel/right_panel_module';
import {ServicesModule} from '../services/services.module';
import {SharedModule} from '../shared/shared_module';
import {TransferMonitorModule} from '../transfer_monitor/transfer_monitor_module';

import {Home} from './home';
import {HomeSidenav} from './home_sidenav';
import {SearchDateRangePicker} from './search_date_range_picker';
import {SearchHelp} from './search_help';
import {SearchInput} from './search_input';

@NgModule({
  declarations: [
    Home,
    HomeSidenav,
    SearchHelp,
    SearchInput,
    SearchDateRangePicker,
  ],
  imports: [
    AdminModule,
    BrowserAnimationsModule,
    CommonModule,
    DetailsModule,
    FeatureFlagModule,
    FirebaseModule,
    FormsModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatOptionModule,
    LandingModule,
    LayoutModule,
    LiveModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatProgressBarModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    PipesModule,
    ReactiveFormsModule,
    RightPanelModule,
    RouterModule,
    ServicesModule,
    SharedModule,
    TransferMonitorModule,
  ],
  exports: [
    SearchInput,
  ],
})
export class HomeModule {
}
