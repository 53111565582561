import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  Query,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QueryFieldFilterConstraint,
  updateDoc,
  writeBatch
} from '@firebase/firestore';
import { Analytics, AnalyticsCallOptions, getAnalytics, logEvent } from 'firebase/analytics';
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { FirebasePerformance, getPerformance, trace } from 'firebase/performance';
import { collection as rxfireCollection } from 'rxfire/firestore';
import { from, map, Observable, of, take } from 'rxjs';

import { AuthService } from '../auth/auth_service';
import { environment } from '../environments/environment';
import { ErrorService } from '../error_service/error_service';

import { AllEventParams } from './firebase_analytics_service';

export declare interface IASDataObject {
  createTime: string;
  formattedCreateDate?: string;
  username?: string;
  emailID?: string;
}

/** Injection token for configuring Firebase services. */
export const FIREBASE_SERVICE_CONFIG =
  new InjectionToken<FirebaseOptions | undefined>(
    'Firebase services configuration',
    { factory: () => environment.firebaseConfig });

/** Injection token for current environment type: prod/non-prod */
export const IS_PROD = new InjectionToken<boolean>(
  'Is Prod Environment', { factory: () => environment.isProd });

/** Allows for lazy Firebase service initialization. */
@Injectable()
export class FirebaseResolver {
  constructor(
    private readonly errorService: ErrorService,
    private readonly authService: AuthService,
    @Inject(IS_PROD) private readonly isProd: boolean,
    @Optional() @Inject(FIREBASE_SERVICE_CONFIG) private readonly config?:
      FirebaseOptions,
  ) { }

  getAnalytics() {
    if (!this.analyticsResolveAttempted) {
      this.analyticsResolveAttempted = true;
      const firebase = this.initializeFirebase();
      if (!firebase) return;

      try {
        this.analyticsInternal = getAnalytics(firebase);
      } catch {
        this.errorService.handle(
          new Error('Firebase Analytics failed to start.'));
      }
    }

    return this.analyticsInternal;
  }

  getPerformance() {
    if (!this.performanceResolveAttempted) {
      this.performanceResolveAttempted = true;
      const firebase = this.initializeFirebase();
      if (firebase) {
        try {
          this.performanceInternal = getPerformance(firebase);
        } catch {
          this.errorService.handle(
            new Error('Firebase Performance failed to start.'));
        }
      }
    }

    return this.performanceInternal;
  }

  getFirestore() {
    if (!this.firestoreResolveAttempted) {
      this.firestoreResolveAttempted = true;
      const firebase = this.initializeFirebase();
      if (firebase) {
        try {
          this.firestoreInternal = getFirestore(firebase, 'ias-data');
        } catch {
          this.errorService.handle(
            new Error('Firebase Firestore failed to start.'));
        }
      }
    }
    return this.firestoreInternal;
  }

  logEvent(
    eventName: string, params: AllEventParams,
    options?: AnalyticsCallOptions) {
    const analytics = this.getAnalytics();
    if (!analytics) return false;
    logEvent(analytics, eventName, params, options);
    return true;
  }

  trace(traceName: string) {
    const perf = this.getPerformance();
    if (!perf) return undefined;

    return trace(perf, traceName);
  }

  async createFirestoreDoc(
    collectionPath: string, data: DocumentData): Promise<boolean | DocumentReference> {
    const firestore = this.getFirestore() as Firestore;
    if (!firestore) return false;
    return await addDoc(collection(firestore, collectionPath), data);
  }

  async updateFirestoreDoc(collectionPath: string, id: string, data: DocumentData) {
    const firestore = this.getFirestore() as Firestore;
    if (!id) this.createFirestoreDoc(collectionPath, data);
    if (!firestore) return;

    const ref = doc(firestore, `${collectionPath}/${id}`);
    await updateDoc(ref, data);
  }

  getDocument(collectionPath: string, docId: string): Observable<DocumentSnapshot> {
    const firestore = this.getFirestore() as Firestore;
    if (!firestore) return of({} as DocumentSnapshot);

    return from(getDoc(doc(collection(firestore, collectionPath), docId)));
  }

  queryCollection(collectionPath: string,
    constraints: QueryConstraint[], limitSize: number): Observable<QueryDocumentSnapshot[]> {
    const firestore = this.getFirestore() as Firestore;
    if (!firestore) return of([]);
    if (constraints.length <= 0) return of([]);

    const docsRef = collection(firestore, collectionPath);
    let compositeQuery: Query = query(docsRef,
      constraints[0], limit(limitSize));
    constraints.forEach((constraint, index) => {
      if (index > 0) {
        compositeQuery = query(compositeQuery, constraint);
      }
    }
    );
    return rxfireCollection(compositeQuery);
  }

  countQuery(collectionPath: string,
    constraints: QueryConstraint[], limitSize: number): Observable<number> {
    const firestore = this.getFirestore() as Firestore;
    if (!firestore) return of(0);

    const docsRef = collection(firestore, collectionPath);
    let compositeQuery: Query = query(docsRef,
      constraints[0], limit(limitSize));
    constraints.forEach((constraint, index) => {
      if (index > 0) {
        compositeQuery = query(compositeQuery, constraint);
      }
    }
    );
    return from(getCountFromServer(compositeQuery))
      .pipe(
        take(1),
        map((count) => (count).data().count)
      );
  }

  queryCollectionWithoutLimitSize(collectionPath: string,
    constraints: QueryFieldFilterConstraint[],): Observable<QueryDocumentSnapshot[]> {
    const firestore = this.getFirestore() as Firestore;
    if (!firestore) return of([]);
    if (constraints.length <= 0) return of([]);

    const docsRef = collection(firestore, collectionPath);
    let compositeQuery: Query = query(docsRef,
      constraints[0],);

    constraints.forEach((constraint, index) => {
      if (index > 0) {
        compositeQuery = query(compositeQuery, constraint);
      }
    }
    );
    return rxfireCollection(compositeQuery);
  }

  async updatePartialValueBatchMode(collectionPath: string, constraints: QueryFieldFilterConstraint[], newValue: object) {
    const firestore = this.getFirestore() as Firestore;
    const docsRef = collection(firestore, collectionPath);

    let compositeQuery: Query = query(docsRef,
      constraints[0]);
    constraints.forEach((constraint, index) => {
      if (index > 0) {
        compositeQuery = query(compositeQuery, constraint);
      }
    }
    );

    const querySnapshot = await getDocs(compositeQuery);
    const batch = writeBatch(firestore);
    querySnapshot.forEach((doc) => {
      batch.update(doc.ref, newValue);
    });
    await batch.commit();
  }

  /**
   * Initializes firebase app if:
   * 1) Firebase app wasn't initialized yet;
   * 2) Firebase services are enabled by environment (config provided);
   * 3) Firebase scripts loaded.
   *
   * @returns whether firebase is enabled.
   */
  private initializeFirebase() {
    if (this.firebase !== undefined) return this.firebase;

    // Do not enable firebase for internal users in prod.
    if (this.authService.isInternalUser() && this.isProd) {
      return null;
    }

    // The firebase lib and a config are available, initialize it.
    if (this.config) {
      this.firebase = initializeApp(this.config);
      return this.firebase;
    }

    // Firebase is not available.
    return null;
  }

  private performanceInternal?: FirebasePerformance;
  private performanceResolveAttempted = false;
  private analyticsInternal?: Analytics;
  private analyticsResolveAttempted = false;
  private firestoreInternal?: Firestore;
  private firestoreResolveAttempted = false;
  private firebase?: FirebaseApp;
}
