import {Pipe, PipeTransform} from '@angular/core';

const SECOND_MS = 1000;

const TIME_INTERVALS_IN_SECONDS: Array<[string, number]> = [
  ['year', 365 * 24 * 60 * 60],
  ['month', 30 * 24 * 60 * 60],
  ['week', 7 * 24 * 60 * 60],
  ['day', 24 * 60 * 60],
  ['hour', 60 * 60],
  ['minute', 60],
];

/**
 * Displays an amount in milliseconds as `n minutes/hours/weeks/etc ago`.
 *
 * Usage:
 *   value | timeAgo
 * 
 * @example
 *   ```html
 *   {{ 3700000 | timeAgo }}
 *   ```
 *   displays to: `1 hour ago`
 */
@Pipe({name: 'timeAgo'})
export class TimeAgoPipe implements PipeTransform {
  transform(amountMs: number|null): string {
    if (amountMs == null || !Number.isFinite(amountMs)) {
      return '';
    }
    const amountSec = amountMs / SECOND_MS;
    for (const [unitName, unitIntervalSec] of TIME_INTERVALS_IN_SECONDS) {
      if (amountSec < unitIntervalSec) {
        continue;
      }
      const unitAmount = Math.floor(amountSec / unitIntervalSec);
      return `${unitAmount} ${unitName}${unitAmount === 1 ? '' : 's'} ago`;
    }
    if (amountSec < 30) return 'just now';
    return `${Math.floor(amountSec)} seconds ago`;
  }
}
