<h1 mat-dialog-title>Manage sharing links</h1>

<h2>View all your shared links and revoke link access.</h2>

<div mat-dialog-content>
    <button class="search_mode" mat-button mat-flat-button [matMenuTriggerFor]="menuClipBinOwner">
        <span class="search_mode__text">{{ getSearchModeText(searchMode) }}</span>
        <mat-icon matSuffix class="arrow main__icon-arrow--size">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menuClipBinOwner="matMenu" class="clip-bin-owner-menu">
        <button
            *ngFor="let type of sharedLinkTypes"
            mat-menu-item
            (click)="changeSearchMode(type)"
            mam-firebase-ga="Select clipbins owner type"
            [mam-firebase-resource]="getSearchModeText(type)"
        >
            <mat-icon [style.opacity]="searchMode === type ? 1 : 0"> done </mat-icon>
            <span>{{getSearchModeText(type)}}</span>
        </button>
    </mat-menu>

    <div class="search">
        <mat-form-field
            class="dialog__input dialog__input-search--spacing dialog__input--size"
            appearance="outline"
            subscriptSizing="dynamic"
        >
            <mat-icon matPrefix>search</mat-icon>
            <input
                type="text"
                matInput
                placeholder="Search"
                autocomplete="off"
                c12
                [formControl]="titleQuery"
                aria-label="Search links"
            />
        </mat-form-field>
    </div>

    <div class="rows" infinite-scroll (nearBottom)="loadMore(20)">
        <div
            class="row"
            *ngFor="let link of filteredLinks"
            [class.clipbin_row]="link.type === 'CLIPBIN'"
            [class.to-be-revoked]="revokeSet.has(link.name)"
        >
            <mat-icon class="material-icons-outlined link-type">
                {{link.type === 'CLIPBIN' ? "subscriptions" : "smart_display"}}
            </mat-icon>
            <span [title]="link.title">{{link.title}}</span>
            <button
                class="link"
                mat-icon-button
                mam-firebase-ga="Copy shared link to clipboard"
                [mam-firebase-resource]="link.name"
                [cdkCopyToClipboard]="getLinkUrl(link)"
                aria-label="Copy video URL to clipboard"
                matTooltip="Copy video URL to clipboard"
                [matTooltipShowDelay]="800"
            >
                <mat-icon>link</mat-icon>
            </button>
            <button
                class="delete"
                mat-icon-button
                mam-firebase-ga="Revoke shared link"
                [mam-firebase-resource]="link.name"
                aria-label="Revoke link"
                matTooltip="Revoke link"
                [matTooltipShowDelay]="800"
                (click)="revokeSet.add(link.name)"
            >
                <mat-icon>delete_forever</mat-icon>
            </button>
        </div>
    </div>
    <div class="message">{{getMessage()}}</div>
</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close mam-firebase-ga="Cancel changes to shared links">Cancel</button>
    <button mat-button [mat-dialog-close]="true" class="primary" mam-firebase-ga="Confirm changes to shared links">
        Save
    </button>
</div>
