import {HttpClient} from '@angular/common/http';
import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {animationFrameScheduler, EMPTY, fromEvent, interval, merge, Observable, ReplaySubject, timer} from 'rxjs';
import {filter, map, share, switchMap, takeUntil} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';

import {ErrorService} from '../error_service/error_service';

/** One continuous portion of a video buffer */
export interface BufferSegment {
  startTime: number;
  endTime: number;
}

/** Wrapper of a video element with extended API */
@Component({
  selector: 'mam-video-player',
  templateUrl: './video_player.ng.html',
  styleUrls: ['./video_player.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayer implements AfterViewInit, OnDestroy {
  @Input()
  get src(): string {
    return this.videoSrc;
  }
  set src(url: string) {
    this.reset();
    this.videoSrc = url;
    // When "this.src" is updated after view init, we update the video "src"
    if (this.video) {
      this.video.src = url;
    }
  }

  @Input()
  get currentTime() {
    return this.video.currentTime || 0;
  }
  set currentTime(time: number) {
    // Use precision to millisecond
    this.video.currentTime = Math.round(time * 1000) / 1000;
  }

  /**
   * Sets the `preload` attribute of the <video> element.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#attr-preload
   */
  @Input() preload: 'auto'|'metadata'|'none' = 'auto';

  @Output() readonly bufferUpdate = new EventEmitter<BufferSegment[]>();

  @Output() readonly timeUpdate = new EventEmitter<number>();

  @ViewChild('video') videoRef!: ElementRef<HTMLVideoElement>;

  /** Video duration, or NaN if not available */
  get duration() {
    return this.video?.duration || Number.NaN;
  }

  get paused() {
    if (!this.video) return true;
    return this.video.paused;
  }

  get muted() {
    if (!this.video) return false;
    return this.video.muted || !this.video.volume;
  }

  get volume() {
    if (!this.video) return -1;
    return this.video.volume;
  }

  // `videoRef` is a ViewChild and will be defined once `ngAfterViewInit`
  // is called, so it is safe to use it directly without checking for undefined
  // within the other methods.
  get video(): HTMLVideoElement {
    return this.videoRef?.nativeElement;
  }

  constructor(
      protected readonly http: HttpClient,
      protected readonly errorService: ErrorService,
  ) {}

  ngAfterViewInit() {
    // Display video inline.
    this.video.playsInline = true;    

    // In case "this.src" property was set before view init, we make sure the
    // video element has the proper "src" now.
    if (this.src) {
      this.video.src = this.src;
    }

    // No need to generate buffer events if no one is listening. This line
    // checks that there is an observer to bufferUpdate in the template:
    // <mam-video-player (bufferUpdate)="doSomething()">.
    if (this.bufferUpdate.observed) {
      this.hookBufferUpdates();
    }

    if (this.timeUpdate.observed) {
      this.hookTimeUpdates();
    }
  }

  /**
   * Every time the video emits a `progress` event, or `seeking` event while not
   * buffered, we emit buffer updates with the latest values at high rate
   * (window.requestAnimationFrame) for 1 second. This ensures smooth visual
   * updates while not wasting resources idle.
   */
  hookBufferUpdates() {
    const stopAfterOneSecond = takeUntil(timer(1000));
    const frameIntervalForOneSecond =
        this.frameInterval.pipe(stopAfterOneSecond);

    const progressEvents = fromEvent(this.video, 'progress');
    const unbufferedSeekingEvents =
        fromEvent(this.video, 'seeking').pipe(filter(() => !this.isBuffered()));

    merge(progressEvents, unbufferedSeekingEvents)
        .pipe(
            switchMap(() => frameIntervalForOneSecond),
            map(() => this.getBufferSegments()),
            takeUntil(this.destroyed),
            )
        .subscribe(segments => {
          this.bufferUpdate.emit(segments);
        });
  }

  /**
   * Emits continuously at fast interval while the video is playing. We do not
   * listen to the HTML standard `timeupdate` event as it is not emitted often
   * enough for smooth animation of a playhead.
   */
  hookTimeUpdates() {
    merge(fromEvent(this.video, 'play'), fromEvent(this.video, 'pause'))
        .pipe(
            switchMap(() => this.paused ? EMPTY : this.frameInterval),
            map(() => this.currentTime),
            takeUntil(this.destroyed),
            )
        .subscribe(currentTime => {
          this.emitTimeUpdate(currentTime);
        });
  }

  getBufferSegments() {
    const segments: BufferSegment[] = [];
    for (let i = 0; i < this.video.buffered.length; i++) {
      segments.push({
        startTime: this.video.buffered.start(i),
        endTime: this.video.buffered.end(i),
      });
    }
    return segments;
  }

  /**
   * Only sets time if video isn't already seeking (improves performances).
   */
  fastSeek(time: number) {
    clearTimeout(this.fastSeekDebouncer);

    if (!this.video.seeking) {
      // If the video wasn't seeking, do a regular seek.
      this.seek(time);
      return;
    }

    // If we can't fastSeek now, debounce a new attempt in 100ms.
    // Eventually the video will seek to the last value that has been
    // requested.
    this.fastSeekDebouncer = window.setTimeout(() => {
      this.fastSeek(time);
    }, 100);
  }

  /** Sets video `currentTime` */
  async seek(time: number): Promise<unknown> {
    clearTimeout(this.fastSeekDebouncer);
    // Wrap a "seeked" event to a promise so this method can resolve once seek
    // is complete.
    const seekedPromise = new Promise(resolve => {
      this.video.addEventListener('seeked', resolve, {once: true});
    });
    assertTruthy(!Number.isNaN(time), 'PlayerWithControls.seek to NaN');
    this.currentTime = time;
    this.emitTimeUpdate(this.currentTime);
    return seekedPromise;
  }

  /** True if the given time is in video buffer */
  isBuffered(time = this.currentTime) {
    const buffered = this.video.buffered;
    for (let i = 0; i < buffered.length; i++) {
      if (time >= buffered.start(i) && time <= buffered.end(i)) {
        return true;
      }
    }
    return false;
  }

  /** Downloads video file and sets it as source */
  loadVideo(url: string): Observable<string> {
    return this.http.get(url, {responseType: 'blob'})
        .pipe(
            map(blob => blob.slice(0, blob.size, 'video/mp4')),
            map(blob => URL.createObjectURL(blob)),
        );
  }

  /** Starts playing the video. */
  async play() {
    try {
      await this.video.play();
    } catch (error: unknown) {
      // Ignore error if we pause while play() promise had not completed.
      // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
      if (error instanceof Error &&
          error.message.includes('The play() request was interrupted')) {
        return;
      }

      throw error;
    }
  }

  /**
   * Starts playing the video. If it is prevented by the browser due to its
   * autoplay policy, starts playing the video muted.
   */
  async playMutedIfNecessary() {
    try {
      await this.play();
    } catch (error: unknown) {
      // TODO-HLS: Remove after troubleshooting
      console.error(error);

      // Chrome may throw an error when calling video.play() with audio if the
      // user did not interact with the document first (https://goo.gl/xX8pDD).
      if (error instanceof DOMException && error.name === 'NotAllowedError') {
        this.errorService.handle(error);
        this.mute();
        await this.play();
      }
    }
  }

  pause() {
    this.video.pause();
  }

  mute() {
    this.video.muted = true;
  }

  unmute() {
    this.video.muted = false;
  }

  changeVolume(volumeValue: number) {
    if (volumeValue === 0) {
      this.mute();
    } else {
      this.unmute();
    }
    this.video.volume = volumeValue;
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.src);
    // Unsubscribes all pending subscriptions.
    this.destroyed.next();
    this.destroyed.complete();
  }

  // ====== Private properties and methods

  /** Called when loading a different video. */
  protected reset() {
    URL.revokeObjectURL(this.src);
  }

  protected emitTimeUpdate(time: number) {
    this.timeUpdate.emit(time);
  }

  /** Emits every time `window.requestAnimationFrame` would fire. */
  private readonly frameInterval =
      interval(0, animationFrameScheduler).pipe(share());

  private videoSrc = '';

  /** Handle on-destroy Subject, used to unsubscribe. */
  protected readonly destroyed = new ReplaySubject<void>(1);

  private fastSeekDebouncer = -1;
}