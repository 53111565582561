import {Injectable} from '@angular/core';
import {fromEventPattern, Observable} from 'rxjs';

import {assertTruthy} from 'asserts/asserts';

import {ErrorService} from '../error_service/error_service';


/**
 * Observes changes in target elements' intersection with document's top level
 * viewport using
 * [IntersectionObserver](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API).
 *
 * Most common use-case is to detect if the element is visible inside scrollable
 * area.
 *
 * This service is a clone of ResizeObserverService:
 * services/resize_observer_service.ts
 */
@Injectable({providedIn: 'root'})
export class IntersectionObserverService {
  constructor(errorService: ErrorService) {
    if (!window.IntersectionObserver) {
      errorService.handle(`No IntersectionObserver : ${navigator.userAgent}`);
    }
  }

  /**
   * Observes changes in target element's intersection with document's top level
   * viewport.
   *
   * @returns observable that emits boolean indicating if the target element
   *     intersects with the document's viewport.
   */
  observe(element: Element): Observable<boolean> {
    // Called when an observable is subscribed to.
    const addHandler = (intersectionHandler: Function) => {
      // The first time an observer is attached, initialize the set.
      if (!this.activeObservers.has(element)) {
        this.attachObserver(element);
        this.activeObservers.set(element, new Set());
      }

      this.activeObservers.get(element)?.add(intersectionHandler);
    };

    // Called when a subscription to an observable is unsubscribed.
    const removeHandler = (intersectionHandler: Function) => {
      this.activeObservers.get(element)?.delete(intersectionHandler);

      // When the last observer is removed, delete the set and unobserve the
      // element.
      if (!this.activeObservers.get(element)?.size) {
        this.activeObservers.delete(element);
        this.intersectionObserver?.unobserve(element);
      }
    };

    return fromEventPattern(addHandler, removeHandler);
  }

  /** Singleton IntersectionObserver instance. */
  private intersectionObserver?: IntersectionObserver;

  /**
   * - key: element observed.
   * - value: set of callbacks executed when the element's intersection with
   *   viewport changes.
   */
  private readonly activeObservers = new Map<Element, Set<Function>>();

  /**
   * Attaches a intersection observer to an element.
   */
  private attachObserver(element: Element): void {
    if (!this.intersectionObserver) {
      this.intersectionObserver = new window.IntersectionObserver((entries) => {
        for (const {target, isIntersecting} of entries) {
          this.handleObservation(target, isIntersecting);
        }
      });
    }
    this.intersectionObserver.observe(element);
  }

  /**
   * Handles observations of intersection events and notifies subscribers to the
   * event.
   */
  private handleObservation(element: Element, isIntersecting: boolean) {
    const handlers = this.activeObservers.get(element);
    assertTruthy(handlers, 'handlers should exist');
    for (const handler of handlers) {
      handler.call(undefined, isIntersecting);
    }
  }
}
