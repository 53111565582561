import {Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';
import {first, map} from 'rxjs/operators';

import {ApiTransferTask} from '../api/ias/model/api-transfer-task';
import {FirebaseFirestoreDataService} from '../firebase/firebase_firestore_data_service';
import {FileResource, TransferTask} from '../models';
import {ExportItemResponse} from '../transfer_monitor/export_monitor_service';

import {Original} from './asset_service';

import TransferTypeEnum = ApiTransferTask.TransferTypeEnum;

enum SourceType {
  CLOUD_INGEST = 'cloud_ingest',
  LOCAL_UPLOADS = 'local-uploads',
  MEDIACACHE = 'mediacache',
  DEFAULT = 'default'
}

const SOURCE_MAPPINGS: Record<SourceType, string> = {
  [SourceType.CLOUD_INGEST]: 'Cloud Ingest',
  [SourceType.LOCAL_UPLOADS]: 'Local Upload',
  [SourceType.MEDIACACHE]: 'MEDIACACHE',
  [SourceType.DEFAULT]: 'On-prem Download'
};

const DEFAULT_EXPORT_USERNAME = 'User Export';
const DEFAULT_DELETE_ASSET_USERNAME = 'User Delete';

@Injectable({
    providedIn: 'root'
})
export class TaskUsersService {
    constructor(private readonly dataService: FirebaseFirestoreDataService) {
    }

    completeTransferTaskWithUser(transferTask: TransferTask): Observable<TransferTask> {

      if (!transferTask.files.length){
        transferTask.user = SOURCE_MAPPINGS[SourceType.DEFAULT];
        return of(transferTask);
      }

      const file = transferTask.files[0];
      const filename = file.filename;
      return this.dataService.retrieveIASEventForTransfer(filename)
        .pipe(first(),
          map(events => {
            const user = events[0]?.username; // Safe if events[0] might be undefined
            transferTask.user =
              user ?? this.inferUsernameFromTransferFileAndType(file, transferTask.transferType);
            return transferTask;
          }));
    }

    private inferUsernameFromTransferFileAndType(file: FileResource, transferType: TransferTypeEnum ):string {

      if (file.filename.includes("/From_VIA/")) return 'IPD VIA';

      if (transferType === ApiTransferTask.TransferTypeEnum.TRANSFER_DIRECTION_UPLOAD)
        return this.createSourceLabelWithSiteId(SourceType.MEDIACACHE, file.name, '/sites/(?<word>[^/]+)/');

      const filenameParts = file.filename.split('/');
      if (filenameParts.length > 1) {
        const rootFolder = filenameParts[0].toLowerCase();
        const source: SourceType = (this.isValidSourceType(rootFolder))? rootFolder as SourceType : SourceType.DEFAULT;

        return (source === SourceType.MEDIACACHE)
          ? this.createSourceLabelWithSiteId(source, file.filename, 'mediacache/(?<word>[^/]+)/')
          : SOURCE_MAPPINGS[source];
      }
      return SOURCE_MAPPINGS[SourceType.DEFAULT];
    }

  completeVodExportWithUser(item: ExportItemResponse): Observable<ExportItemResponse> {

    const assetName =item.name;
    const folderId = item.exportFolder;

    return this.dataService.retrieveIASEventForVodClipExport(assetName,folderId)
      .pipe(first(),
        map(events => {
          item.user = events[0]?.username ?? DEFAULT_EXPORT_USERNAME;
          return item;
        }));
  }

  completeLiveExportWithUser(item: ExportItemResponse): Observable<ExportItemResponse> {

    const assetName =item.name;
    const folderId = item.exportFolder;

    return this.dataService.retrieveIASEventForLiveClipExport(assetName,folderId)
      .pipe(first(),
        map(events => {
          item.user = events[0]?.username ?? DEFAULT_EXPORT_USERNAME;
          return item;
        }));
  }

  completeCompReelWithUser(item: ExportItemResponse): Observable<ExportItemResponse> {

    const filename =item.fileName;
    const folderId = item.folderPath;

    return this.dataService.retrieveIASEventForCompReelExport(filename,folderId)
      .pipe(first(),
        map(events => {
          item.user = events[0]?.username ?? DEFAULT_EXPORT_USERNAME;
          return item;
        }));
  }

  completeDeletedAssetWithUser(asset: Original): Observable<Original> {

    const assetName =asset.name;
    const assetTitle = asset.title;

    return this.dataService.retrieveIASEventForDeleteAsset(assetName,assetTitle)
      .pipe(first(),
        map(events => {
          asset.source = events[0]?.username ?? DEFAULT_DELETE_ASSET_USERNAME;
          return asset;
        }));
  }

  private isValidSourceType(value: string): boolean {
    return Object.values(SourceType).includes(value as SourceType);
  }

  private createSourceLabelWithSiteId(source: SourceType, path: string, regExp: string){
    const regexMediaCacheSites = new RegExp(regExp);
    const mediaCacheSite = path.match(regexMediaCacheSites)?.groups?.['word'] ?? '';
    return (mediaCacheSite)
      ? mediaCacheSite.toUpperCase()
      : SOURCE_MAPPINGS[source];
  }
}
