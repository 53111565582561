import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { BatchOperationService } from 'shared/batch_operation_service';

import { PlaybackAuthorizationService } from './playback-authorization.service';

@Injectable({ providedIn: 'root' })
export class QueryStringPlaybackAuthorizationService extends PlaybackAuthorizationService {
    public constructor(
        private readonly batchOperationService: BatchOperationService
    ) {
        super();
    }

    protected override prepareForAuthorizationBatch(): Promise<void> {
        // Nothing to do.
        return Promise.resolve();
    }

    protected override async getAuthorizedUriCore(originalUri: string, linkName?: string): Promise<string> {
        return (await firstValueFrom(this.batchOperationService.batchSignUrl(originalUri, linkName))) ?? '';
    }

    protected override hasAuthorization(uri: string): boolean {
        return new URL(uri).searchParams.has('Signature');
    }
}