
import {ApiAnnotatedSegment} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

import {Metadata} from './metadata';

/**
 * Intermediate model for annotated segments between ApiAnnotatedSegment and
 * DisplaySegment.
 *
 * Exists because currently api model leaks to PlayFeedService.
 * Consider tranformning ApiAnnotatedSegment to DisplaySegment in
 * AnnotatedSegmentsApiService and removing this model.
 */
export class AnnotatedSegment implements
    RequiredRecursively<ApiAnnotatedSegment> {
  createTime = this.apiSegment.createTime ?? '';
  endOffset = this.apiSegment.endOffset ?? '';
  endTime = this.apiSegment.endTime ?? '';
  name = this.apiSegment.name ?? '';
  segmentMetadata = new Metadata(this.apiSegment.segmentMetadata);
  startOffset = this.apiSegment.startOffset ?? '';
  startTime = this.apiSegment.startTime ?? '';
  updateTime = this.apiSegment.updateTime ?? '';

  constructor(private apiSegment: ApiAnnotatedSegment = {}) {}
}
