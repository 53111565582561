import {map, tap} from 'rxjs/operators';

import {ApiError} from './api_error';
import {StatusCode} from './status_code';

/**
 * Error wrapper, propagated to components and higher level services instead of
 * raw api error.
 */
export class ErrorResponse {
  /** User-friendly error message. */
  readonly message: string;

  /** Api error status if available. */
  readonly status?: StatusCode;

  constructor(error?: string|ApiError, status?: StatusCode) {
    if (typeof error === 'string') {
      this.message = error;
      this.status = status;
    } else if (error instanceof ApiError) {
      this.message = error.innerErrorMessage;
      this.status = error.status;
    } else {
      this.message = '';
    }
  }
}

/** Checks if the response is an error. */
export function isErrorResponse(x: unknown): x is ErrorResponse {
  return x instanceof ErrorResponse;
}

/**
 * Checks if the response indicates that the user has no admin rights needed to
 * perform certain actions like deleting or approving assets.
 */
export function hasAdminRightsMissing(responses: unknown[]) {
  return responses.some(
      r => isErrorResponse(r) && r.status === StatusCode.FORBIDDEN);
}

/**
 * Similar to rxjs tap but executes provided function only when the value is
 * instance of `ErrorResponse`.
 */
export function tapOnError<T>(next: (error: ErrorResponse) => void) {
  return tap((x: T) => {
    if (isErrorResponse(x)) {
      next(x);
    }
  });
}

/**
 * Similar to rxjs map, but executes provided mapper function only when the
 * value is instance of ErrorResponse.
 */
export function
mapOnError<T, MapResult, Result = T extends ErrorResponse ? MapResult : T>(
    next: (error: ErrorResponse) => MapResult) {
  return map((x: T) => (isErrorResponse(x) ? next(x) : x) as unknown as Result);
}

/**
 * Similar to rxjs map, but executes provided mapper function only when the
 * value is not an instance of ErrorResponse.
 */
export function
mapOnSuccess<T, MapResult, MapInput = T extends ErrorResponse ? never : T>(
    next: (x: MapInput) => MapResult) {
  return map((x: T) => {
    if (isErrorResponse(x)) return x;
    return next(x as unknown as MapInput) as ErrorResponse | MapResult;
  });
}
