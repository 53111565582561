/** Type of query expression argument. */
export type QueryArg = string|number|boolean;

/**
 * Utility class that provides basic methods to construct search query.
 */
export abstract class BaseQueryExpressions<PROPERTY extends string = string> {
  /**
   * Wraps each provided sub query in braces and joins them with `OR`.
   * 
   * @example
   * or(['a','b']); // '(a) OR (b)'
   */
  or(subQueries: string[]) {
    return this.join(subQueries, 'OR');
  }

  /**
   * Wraps each provided sub query in braces and joins them with `AND`.
   * 
   * @example
   * and(['a','b']); // '(a) AND (b)'
   */
  and(subQueries: string[]) {
    return this.join(subQueries, 'AND');
  }

  /**
   * Negates provided sub query.
   * 
   * @example
   * not('a'); // 'NOT (a)'
   */
  not(subQuery: string) {
    if (!subQuery) return '';
    return `NOT (${subQuery})`;
  }

  /**
   * Creates a query where matching items can have any of provided values by
   * specified key.
   * 
   * @example
   * // Concrete expression depends on the backed type (e.g VCMS or GCMA).
   * anyOf('test', ['a', 'b']) // (test `is` a) OR (test `is` b)
   */
  anyOf(key: PROPERTY, values: QueryArg[]) {
    return this.or(values.map(value => this.is(key, value)));
  }

  protected abstract is(key: PROPERTY, value: QueryArg): string;

  private join(subQueries: string[], operator: string) {
    const filtered = subQueries.map(p => p.trim()).filter(p => !!p);
    if (filtered.length === 1) return filtered[0];

    return filtered.map(part => `(${part})`).join(` ${operator} `);
  }
}
