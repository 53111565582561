import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {filter, map, switchMap, take, takeUntil} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';

import {Original} from '../services/asset_service';

import {MetadataAction} from './asset_metadata_panel';
import {MetadataService} from './metadata_service';
import {StagingService} from './staging_service';

const enum ExtraActions {
  OPEN_DETAILS_VIEW = 'OPEN_DETAILS_VIEW',
}

/**
 * Asset metadata panel used in content staging view.
 */
@Component({
  selector: 'mam-staging-metadata-panel',
  templateUrl: './staging_metadata_panel.ng.html',
  styleUrls: ['./staging_metadata_panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StagingMetadataPanel implements OnDestroy {
  assets: Original[] = [];

  readonly extraActions: MetadataAction[] = [{
    id: ExtraActions.OPEN_DETAILS_VIEW,
    label: 'Asset Details',
    description: 'Navigate to details view from metadata panel',
    text: 'Open details view',
    icon: 'movie'
  }];


  constructor(
      readonly stagingService: StagingService,
      readonly metadataService: MetadataService,
      private readonly cdr: ChangeDetectorRef,
  ) {
    // Monitor active assets and enable edit mode if needed,
    this.stagingService.activeItems$.pipe(takeUntil(this.destroyed$))
        .subscribe(activeItems => {
          this.cdr.markForCheck();
          this.assets = activeItems?.assets ?? [];

          // Enable editing mode if it was requested.
          if (this.assets.length && activeItems?.startEditing &&
              !this.metadataService.isEditing()) {
            this.metadataService.edit();
          }
        });

    // Monitor when editing is finished to hide metadata panel after bulk edit.
    this.stagingService.activeItems$
        .pipe(
            switchMap(activeItems => {
              // Monitor single isEditing === false emission
              return this.metadataService.isEditing$.pipe(
                  filter(isEditing => !isEditing),
                  map(() => activeItems),
                  take(1),
              );
            }),
            takeUntil(this.destroyed$))
        .subscribe(active => {
          if ((active?.assets?.length ?? 0) > 1) {
            this.stagingService.setActive(undefined);
          }
        });
  }

  getApprovalDisabledTooltip(asset: Original): string {
    if (!asset.assetMetadata.schema) {
      return 'An asset without a schema cannot be approved';
    }
    if (asset.hasError) {
      return 'An asset in error state cannot be approved';
    }
    return '';
  }

  approve(asset: Original) {
    this.stagingService.approve([asset]);
  }


  onExtraAction(assets: Original[], {id}: MetadataAction) {
    assertTruthy(
        assets.length === 1,
        'Extra actions are expected only when a single asset is active ');

    if (id === ExtraActions.OPEN_DETAILS_VIEW) {
      // If the asset is live and archived, navigate to the VoD archive instead.
      const asset = assets[0];
      this.stagingService.navigateToDetailsView(
          asset.archiveName || asset.name);
      return;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);
}
