import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';

import {BaseQueryExpressions, QueryArg} from './base_query_expressions';

type GcmaProperty = 'title'|'gcsObject'|'state'|'approvalState'|
    'pfrExportFolder'|'pfrCreateTime'|'liveExportFolder'|'compReelExportFolder'|
    'compReelCreateTime'|'createdByUser'|'liveExportCreateTime'|
    'compReelUpdateTime'|'pfrUpdateTime'|'liveExportUpdateTime';

/**
 * Utility service that provides convenience methods to construct GCMA search
 * query.
 */
@Injectable({providedIn: 'root'})
export class GcmaQueryExpressions extends BaseQueryExpressions<GcmaProperty> {
  /**
   * Creates a key-value query.
   * 
   * @example
   * is('AssetState', 'STATE_READY') // AssetState=STATE_READY
   */
  override is(key: GcmaProperty, value: QueryArg) {
    if (typeof value === 'string') value = value.trim();
    if (value === '') return '';

    if (this.needsQuotes(key)) return `${key}="${value}"`;
    return `${key}=${value}`;
  }

  /**
   * Creates a key-value query.
   * 
   * @example
   * isNot('AssetState', 'STATE_READY') // AssetState!=STATE_READY
   */
  isNot(key: GcmaProperty, value: QueryArg) {
    if (typeof value === 'string') value = value.trim();
    if (value === '') return '';

    if (this.needsQuotes(key)) return `${key}!="${value}"`;
    return `${key}!=${value}`;
  }

  /**
   * Creates a partial match query.
   * 
   * @example
   * includes('title', 'great') // title:"great"
   */
  includes(key: GcmaProperty, value: QueryArg) {
    if (typeof value === 'string') {
      value = this.sanitize(value.trim());
    }

    if (value === '') return '';

    return `${key}:"${value}"`;
  }

  /**
   * Creates a query that ensures the time fall between 00:00:00.000 -
   * 23:59:59.999` on a certain date using ISO formate (in UTC).
   *
   * @returns
   * // Assume `date` is 2022-10-17 PDT (UTC-7)
   * withinDate('compReelUpdatedTime', date);
   *
   * (compReelUpdatedTime<"2022-10-17T23:59:59.999-07:00") AND
   * (compReelUpdatedTime>"2022-10-17T00:00:00.000-07:00")
   */
  withinDate(field: GcmaProperty, value: DateTime) {
    const dayStart = value.startOf('day').toISO();
    const dayEnd = value.endOf('day').toISO();
    return this.and([`${field}>"${dayStart}"`, `${field}<"${dayEnd}"`]);
  }

  private sanitize(value: string) {
    return value.replace(/"/g, '');
  }

  /**
   * Check the query key will carry an enumerate value or not. If not, the value
   * needs to be wrapped by quotes.
   */
  private needsQuotes(key: string) {
    return [
      'pfrExportFolder', 'liveExportFolder', 'compReelExportFolder'
    ].includes(key);
  }
}
