import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError, expand, map, switchMap} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';

import {ApiError} from '../error_service/api_error';
import {ErrorService} from '../error_service/error_service';
import {StatusCode} from '../error_service/status_code';

import {AssetApiService} from './asset_api_service';

const CHUNK_SIZE = 32 * 1024 * 1024;  // 32MB

/** Resumable upload status. */
export interface ResumableUploadResponse {
  /** Indicate the upload is completed. */
  isComplete: boolean;
  /** Indicate the next byte of a chunk to start upload. */
  startOffset: number;
  /** Indicate the current session Uri of this upload. */
  sessionUri: string;
}

/**
 * When doing retry the session URI related request,
 * exclude these errors for retry.
 */
const EXCLUDED_STATUS_CODE_FOR_RETRY = [
  /** Session URI is invalid after a week. */
  StatusCode.NOT_FOUND,
  /** Session URI is invalid within a week. */
  StatusCode.GONE,
  /** Incomplete upload, not an error for resumable upload. */
  StatusCode.PERMANENT_REDIRECT,
];

/**
 * Ressumable Upload service implements the Cloud Storage
 * Resuamble Upload approach.
 * https://cloud.google.com/storage/docs/resumable-uploads.
 */
@Injectable({providedIn: 'root'})
export class ResumableUploadService {
  constructor(
      private readonly http: HttpClient,
      private readonly assetApiService: AssetApiService,
      private readonly errorService: ErrorService,
  ) {}

  /**
   * If session URI is provided, try use it to resume the existing upload by
   * using the session URI. If the session URI is expired, returning 401/404
   * error, try start the upload from beginnig.
   *
   * If the session URI is not provided, start the upload from beginning by
   * generating the signed upload url and initate the resumable upload.
   * Exception may be thrown during this process.
   */
  upload(file: File, sessionUri?: string): Observable<ResumableUploadResponse> {
    const uploadFromBeginning$ = this.generateUploadUri(file).pipe(
        switchMap(uploadUrl => this.createResumableUpload(file, uploadUrl)),
        switchMap(sessionUri => this.readAndUploadChunk(file, {
          isComplete: false,
          sessionUri,
          startOffset: 0,
        })));

    if (!sessionUri) {
      // Start the upload from beginning.
      return uploadFromBeginning$;
    }

    // Check the session URI status to either resume or restart.
    return this.checkStatus(sessionUri, file.size)
        .pipe(
            switchMap((resp) => this.readAndUploadChunk(file, resp)),
            catchError(() => {
              // If session URI is expired (HTTP STATUS 401,404), inititae a
              // new upload with a new session URI automatically.
              return uploadFromBeginning$;
            }));
  }

  /**
   * Read chunks of a file.
   * This is public to help the unit test easy to mock.
   */
  readChunk(file: File, offset: number) {
    return new Observable<ArrayBuffer>((subscribe) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.readyState === FileReader.DONE) {
          subscribe.next(reader.result as ArrayBuffer);
          subscribe.complete();
        } else {
          subscribe.error('could not read file');
        }
      };
      reader.onabort = () => {
        subscribe.error('file reading is aborted');
      };
      // eslint-disable-next-line unicorn/prefer-blob-reading-methods -- FIXME
      reader.readAsArrayBuffer(file.slice(offset, offset + CHUNK_SIZE));
    });
  }

  /**
   * Check the status of the session URI of a resumable upload.
   * Pass Content-Range with * to indicate this PUT request is only checking
   * status.
   * https://cloud.google.com/storage/docs/performing-resumable-uploads#status-check
   */
  private checkStatus(sessionUri: string, totalSize: number):
      Observable<ResumableUploadResponse> {
    return this.http
        .put(sessionUri, undefined, {
          headers: {
            'Content-Range': `bytes */${totalSize}`,
          },
          observe: 'response',
        })
        .pipe(
            map(() => {
              return {
                isComplete: true,
                startOffset: totalSize,
                sessionUri,
              };
            }),
            // Do not retry if the session URI expires.
            // https://cloud.google.com/storage/docs/resumable-uploads#session-uris
            this.errorService.retryLong(EXCLUDED_STATUS_CODE_FOR_RETRY),
            catchError(
                error =>
                    handleResumableUploadResponse(error, sessionUri, true)),
        );
  }

  /** Generate the signed upload url by calling IAS backend. */
  private generateUploadUri(file: File): Observable<string> {
    return this.assetApiService
        .generateUploadUri(file.name, file.type)
        // Do not retry on permission issue or if the file already exists in
        // GCS bucket.
        .pipe(this.errorService.retryLong(
            [StatusCode.FORBIDDEN, StatusCode.CONFLICT]));
  }

  /**
   * Use the generated upload URI from backend to call
   * GCS directly. This will return the session URI from the response header.
   * The session URI will be used for actual uploading.
   */
  private createResumableUpload(file: File, uploadUrl: string):
      Observable<string> {
    return this.http
        .post<never>(uploadUrl, undefined, {
          observe: 'response',
          headers: {
            'Content-Type': file.type,
            'x-goog-resumable': 'start',
          }
        })
        .pipe(
            map(response => {
              // The session URI for the resumable upload operation.
              // https://cloud.google.com/storage/docs/xml-api/reference-headers#location
              const location = response.headers.get('location');
              if (!location) throw new Error('no session URI returned');

              return location;
            }),
        );
  }

  /**
   * Make the PUT request with the session URI to
   * upload the files by chunks.
   */
  private readAndUploadChunk(
      file: File, resumableUpload: ResumableUploadResponse):
      Observable<ResumableUploadResponse> {
    return of<ResumableUploadResponse>(resumableUpload)
        // By using 'expand' to recursively calling until the file is completely
        // uploaded.
        .pipe(expand((response: ResumableUploadResponse) => {
          if (response.isComplete) {
            return EMPTY;
          }

          return this.readChunk(file, response.startOffset)
              .pipe(switchMap(
                  chunk => this.uploadChunk(
                      chunk, response.startOffset, response.sessionUri, file)));
        }));
  }

  /**
   * Perform actual upload by using the session URI.
   * https://cloud.google.com/storage/docs/performing-resumable-uploads#chunked-upload
   * https://cloud.google.com/storage/docs/performing-resumable-uploads#resume-upload
   */
  private uploadChunk(
      chunk: ArrayBuffer, offset: number, sessionUri: string,
      file: File): Observable<ResumableUploadResponse> {
    let headers = new HttpHeaders();
    if (chunk.byteLength > 0) {
      // Example: Content-Range: bytes 0-188012/188013
      // https://cloud.google.com/storage/docs/xml-api/reference-headers#contentrange
      headers = headers.set(
          'Content-Range',
          `bytes ${offset}-${offset + chunk.byteLength - 1}/${file.size}`);
    }
    return this.http
        .put(sessionUri, chunk, {
          headers,
          observe: 'response',
        })
        .pipe(
            map(() => {
              return {
                isComplete: true,
                startOffset: file.size,
                sessionUri,
              };
            }),
            // Do not retry if the session URI expires.
            // https://cloud.google.com/storage/docs/resumable-uploads#session-uris
            this.errorService.retryLong(EXCLUDED_STATUS_CODE_FOR_RETRY),
            catchError(
                error =>
                    handleResumableUploadResponse(error, sessionUri, false)));
  }
}

function handleResumableUploadResponse(
    err: ApiError, sessionUri: string, allowEmptyRangeHeader: boolean) {
  // This err is intercepted, check the status from message.
  // 308 means incomplete upload.
  if (err.status !== StatusCode.PERMANENT_REDIRECT) {
    return throwError(() => err);
  }
  // Header range indicates how many bytes are updated successfully.
  // https://cloud.google.com/storage/docs/xml-api/reference-headers#range.
  // If this is from CheckStatus call, the range header could be null, in this
  // case the upload will start from beginning.
  const range = err.headers.get('range');
  if (!allowEmptyRangeHeader) {
    assertTruthy(range);
  }
  const newOffset = !range ? 0 : Number(range.split('-')[1]) + 1;
  return of<ResumableUploadResponse>({
    isComplete: false,
    startOffset: newOffset,
    sessionUri,
  });
}
