import {ApiSpriteSheet} from 'api/ias/model/api-sprite-sheet';
import {RequiredRecursively} from 'services/utils_service';

/** A UI model representing a sprite sheet */
export class SpriteSheet implements RequiredRecursively<ApiSpriteSheet> {
  columnCount = this.apiSpriteSheet.columnCount ?? 0;
  filePrefix = this.apiSpriteSheet.filePrefix ?? '';
  interval = this.apiSpriteSheet.interval ?? '';
  quality = this.apiSpriteSheet.quality ?? 0;
  rowCount = this.apiSpriteSheet.rowCount ?? 0;
  spriteHeightPixels = this.apiSpriteSheet.spriteHeightPixels ?? 0;
  spriteWidthPixels = this.apiSpriteSheet.spriteWidthPixels ?? 0;
  totalCount = this.apiSpriteSheet.totalCount ?? 0;

  constructor(private apiSpriteSheet: ApiSpriteSheet = {}) {}
}
