import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdminUsersService } from './admin_users_service';

interface UpdateGoogleWorkspaceEvent {
  email: string;
  hasGoogleWorkspace: string;
}

@Component({
  selector: 'mam-admin-user-table-google-workspace-cell',
  templateUrl: './admin_user_table_google_workspace_cell.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUserTableGoogleWorkspaceCell implements OnInit {
  @Input() email: string = '';
  @Output() updateGoogleWorkspaceEvent = new EventEmitter<UpdateGoogleWorkspaceEvent>();
  hasGoogleWorkspace$: Observable<string> | undefined;

  constructor(private readonly adminUsersService: AdminUsersService) { }

  ngOnInit() {
    const userEmail = this.email;
    this.hasGoogleWorkspace$ = this.adminUsersService.userHasGoogleWorkspace(userEmail)
      .pipe(map(response => {
        const hasWorkspace = response ? 'Yes' : 'No';
        this.updateGoogleWorkspaceEvent.emit({email: userEmail  ,hasGoogleWorkspace: hasWorkspace});
        return hasWorkspace;
      }));
  }
}
