/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiStorageTransferInfo } from './api-storage-transfer-info';
import { ApiOnPremScanInfo } from './api-on-prem-scan-info';


/**
 * Information about a watched folder
 */
export interface ApiFolder { 
    /**
     * The timestamp that this folder was created
     */
    createTime?: string;
    /**
     * Additional string metadata
     */
    customMetadata?: { [key: string]: string; };
    /**
     * number of file in the folder
     */
    fileCount?: number;
    /**
     * Onprem TTL days for all the files within the folder.
     */
    fileTtlDays?: number;
    /**
     * The user generated folder id.
     */
    folderId?: string;
    /**
     * Indicates whether folder is virtual (not physically present on site)
     */
    isVirtual?: boolean;
    /**
     * Name of the folder: projects/{project}/locations/{location}/sites/{site}/folders/{folder}
     */
    name?: string;
    onPremScanInfo?: ApiOnPremScanInfo;
    /**
     * Root path of the watched folder.
     */
    rootFolderPath?: string;
    /**
     * Output only. Status of this folder
     */
    readonly state?: ApiFolder.StateEnum;
    storageTransferInfo?: ApiStorageTransferInfo;
    /**
     * total FileStorage in GB
     */
    totalFileStorageGbytes?: number;
    /**
     * Type of this folder
     */
    type?: ApiFolder.TypeEnum;
}
export namespace ApiFolder {
    export type StateEnum = 'STATE_UNSPECIFIED' | 'STATUS_ACTIVE' | 'STATUS_READ_ONLY' | 'STATUS_DELETED' | 'STATUS_INVALID_CONFIG' | 'STATUS_METADATA_ONLY';
    export const StateEnum = {
        STATE_UNSPECIFIED: 'STATE_UNSPECIFIED' as StateEnum,
        STATUS_ACTIVE: 'STATUS_ACTIVE' as StateEnum,
        STATUS_READ_ONLY: 'STATUS_READ_ONLY' as StateEnum,
        STATUS_DELETED: 'STATUS_DELETED' as StateEnum,
        STATUS_INVALID_CONFIG: 'STATUS_INVALID_CONFIG' as StateEnum,
        STATUS_METADATA_ONLY: 'STATUS_METADATA_ONLY' as StateEnum
    };
    export type TypeEnum = 'FOLDER_TYPE_UNSPECIFIED' | 'FOLDER_TYPE_WATCH' | 'FOLDER_TYPE_CACHE' | 'FOLDER_TYPE_EXPORT' | 'FOLDER_TYPE_CLOUD_WATCH' | 'FOLDER_TYPE_CLOUD_MIGRATION';
    export const TypeEnum = {
        FOLDER_TYPE_UNSPECIFIED: 'FOLDER_TYPE_UNSPECIFIED' as TypeEnum,
        FOLDER_TYPE_WATCH: 'FOLDER_TYPE_WATCH' as TypeEnum,
        FOLDER_TYPE_CACHE: 'FOLDER_TYPE_CACHE' as TypeEnum,
        FOLDER_TYPE_EXPORT: 'FOLDER_TYPE_EXPORT' as TypeEnum,
        FOLDER_TYPE_CLOUD_WATCH: 'FOLDER_TYPE_CLOUD_WATCH' as TypeEnum,
        FOLDER_TYPE_CLOUD_MIGRATION: 'FOLDER_TYPE_CLOUD_MIGRATION' as TypeEnum
    };
}


