import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AuthService} from 'auth/auth_service';


export enum UserAccess {
  USER = 'User',
  ADMIN = 'Admin',
}

export enum GoogleWorkspaceAccess {
  YES = 'Yes',
  NO = 'No',
  UNDEFINED = ''
}

export interface IasUser {
  oktaStatus: string;
  lastLogin?: string;
  lastLoginDate?: DateTime;
  email: string;
  displayName: string;
  access: UserAccess;
  googleWorkspaceAccess: GoogleWorkspaceAccess;
}

const USERS_API_URL = '/users';

@Injectable({providedIn: 'root'})
export class AdminUsersService {

  constructor(
      private readonly httpClient: HttpClient,
      private readonly authService: AuthService,
  ) {
  }

  searchUsers(): Observable<IasUser[]> {
    const accessToken = this.authService.getAccessToken();
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    };
    return this.httpClient.get<IasUser[]>(USERS_API_URL, {headers});
  }

  loadGoogleWorkspaceUsers(): Observable<IasUser[]> {
    const accessToken = this.authService.getAccessToken();
    const headers = {
      'Authorization': `Bearer ${accessToken}`
    };
    return this.httpClient.get<IasUser[]>(USERS_API_URL+"/google-workspace-info", {headers});
  }

  userHasGoogleWorkspace(email: string): Observable<boolean> {
    return this.loadGoogleWorkspaceUsers().pipe(
      map((arr) => arr.some(obj => obj.email.toLowerCase() === email.toLowerCase())));
  }
}
