// taze: shaka from //google/cloud/video/intelligent_asset_service/apps/mamui:shaka_dts

import {Location} from '@angular/common';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BehaviorSubject, combineLatest, EMPTY, Observable, of, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, takeUntil, withLatestFrom} from 'rxjs/operators';

import {assertExists, castExists} from 'asserts/asserts';


import {mapOnError} from '../error_service/error_response';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {FirebasePerformanceService, Trace, TraceName} from '../firebase/firebase_performance_service';
import {PlayerUpdate, PlayerWithControls} from '../player/player_with_controls';
import {Asset, AssetState, ClipMarking, Original} from '../services/asset_service';
import { FullscreenElementPriorityPresets, PlayerFullscreenService } from '../services/player_fullscreen_service';
import {RenditionService} from '../services/rendition/rendition.service';
import {SearchInputService, SearchType} from '../services/search_input_service';
import {KeywordResult, QuerySegment, SearchService} from '../services/search_service';
import {SnackBarService} from '../services/snackbar_service';
import {ShortcutEvent, StateService} from '../services/state_service';
import {UtilsService} from '../services/utils_service';
import {AddClipDialog, AddClipDialogInputData, AddClipDialogOutputData} from '../shared/add_clip_dialog';
import {BatchOperationService} from '../shared/batch_operation_service';

import {ContextType, DetailsNavigationService, NavigationRoot} from './details_navigation_service';

/**
 * Component for details page.
 */
@Component({
  selector: 'mam-details',
  templateUrl: './details.ng.html',
  styleUrls: ['./details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Details implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild('mainSection') mainSection!: ElementRef<HTMLElement>;
  @ViewChild(PlayerWithControls) fullPlayer!: PlayerWithControls;

  /** Current asset (original or clip) being viewed in the player.  */
  get asset(): Asset|undefined {
    return this.stateService.currentAsset$.value;
  }

  /** Segment shown above the timeline to highlight a search result. */
  querySegment?: QuerySegment;

  /** Formatted query text to display in the `querySegment`. */
  userQuery?: string;

  /** Whether the camera grid view is shown instead of main player. */
  cameraGridShown = false;

  /** Whether the camera grid view is shown instead of main player. */
  currentPlayerTime?: number;

  /**
   * Whether a clip is ongoing (either the dialog is still open, or the API
   * call has not completed yet).
   */
  readonly clipCreationInProgress$ = new BehaviorSubject(false);

  readonly AnalyticsEventType = AnalyticsEventType;

  /** Whether clip creation is allowed. */
  readonly disabledAddClip$ = this.isAddClipsDisabled();

  /**
   * Only pass keyword results to the player when the insight tab is selected,
   * so that they are not visible otherwise.
   */
  readonly keywordResults$: Observable<KeywordResult[]|undefined> =
      this.stateService.currentPersistentTab$.pipe(switchMap(tab => {
        if (tab !== 'insights') return of(undefined);
        return this.stateService.currentKeywordResults$;
      }));

  /**
   * If the current asset is a part of multi-camera event then this will return
   * all assets (representing different camera angles) related to the event.
   */
  readonly cameraViewAssets$: Observable<Original[]> =
      this.featureFlag.featureOff('use-multi-camera-view') ?
      EMPTY :
      this.stateService.currentAsset$.pipe(
          switchMap(asset => {
            // Ignore VOD assets, clips and live assets that have no correlation
            // id.
            if (!asset || !asset.isLive || !asset.camera?.correlationId ||
                asset.original) {
              return of([]);
            }
            return this.searchService
                .getCameraAssets(asset.camera.correlationId)
                .pipe(startWith([]));
          }),
          mapOnError(() => []), shareReplay({bufferSize: 1, refCount: true}));

  /** Indicates if camera view toggle should be enabled. */
  readonly canShowCameraView$: Observable<boolean> =
      // To immediately switch to false when the asset is changed
      // cameraViewAssets always starts with [] when asset is changed.
      // Switch to true when/if camera assets are available and not empty.
      this.cameraViewAssets$.pipe(map(assets => !!assets?.length));

  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    const key = this.utils.getKeyboardShortcut(event);
    if (!key) return;

    switch (key) {
      case 'n':
        this.openNextAssetIfPossible();
        break;

      case 'p':
        this.openPreviousAssetIfPossible();
        break;

      default:
        return;
    }

    this.analyticsService.logEvent('Keyboard shortcut for Details', {
      resource: this.asset?.name,
      string1: event.key,
      string2: event.code,
      eventType: AnalyticsEventType.KEY_DOWN,
    });
  }

  ngOnInit() {
    // When the clipbin in URL changes and it is different from the persistent
    // panel bin (which happens when we open a clipbin from Landing), update
    // the persistent panel to show the clips of this bin, which will also
    // enable details navigation through these clips.
    this.detailsNavigation.watchRouteClipBinName()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(clipbinName => {
          if (!clipbinName) return;

          // Start by clearing the previous clip bin if it was different from
          // the new one.
          if (this.stateService.persistentBinName$.value !== clipbinName) {
            this.stateService.persistentBinName$.next(undefined);
          }

          // Load current clipbin and set it to the persistent panel. This is
          // useful even if the clipbin is the same resource as its title may
          // have been updated.
          this.stateService.persistentBinName$.next(clipbinName);
        });

    // Only emit when an asset is updated in URL directly without having
    // been sent via a context change before, for instance when we click
    // on a clip or when no context was received yet.
    this.detailsNavigation.watchRouteAssetName()
        .pipe(
            filter(
                (assetName): assetName is string =>
                    !!assetName && assetName !== this.asset?.name),
            takeUntil(this.destroyed$))
        .subscribe(assetName => {
          this.detailsNavigation.selectAsset(assetName);
        });

    // Main subscription to the current service context, which will update
    // the video displayed in details.
    this.detailsNavigation.context$
        .pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged(
                (a, b) => a.asset?.name === b.asset?.name &&
                    a.querySegment === b.querySegment),
            switchMap(async (context) => {
              this.searchInputService.searchType$.next(
                  context.origin?.root === NavigationRoot.LIVE_LANDING ?
                      SearchType.LIVE :
                      SearchType.VOD);

              // Playback error cases
              let errorReason = '';

              if (!context.asset) {
                errorReason = 'Asset not found.';
              }

              if (context.asset?.state === AssetState.SCHEDULED) {
                errorReason = 'Live stream is not available yet';
              }

              if (context.asset) {
                const manifest = await this.getManifestUrl(context.asset);
                if (!manifest) {
                  errorReason = 'Video is not available.';
                }
              }

              return { context, errorReason };
            })
        )
        .subscribe(({ context, errorReason }) => {
          if (errorReason) {
            this.cdr.markForCheck();
            this.snackBar.error(errorReason);
            this.querySegment = undefined;
            this.userQuery = undefined;
            this.stateService.currentAsset$.next(undefined);
            return;
          }

          // Set default audio track from shared link if any.
          const trackIndex =
              Number(context.link?.additionalProperties?.['trackIndex']);
          if (Number.isFinite(trackIndex)) {
            this.fullPlayer.defaultTrackIndex = trackIndex;
          }

          // Show players and chip (ngIf="asset$" on top of the template)
          // http://google3/google/cloud/video/intelligent_asset_service/apps/mamui/details/details.ng.html;l=1
          this.querySegment = context.querySegment;
          this.userQuery = context.userQuery;

          const asset = context.asset;
          assertExists(asset);
          this.stateService.currentAsset$.next(asset);

          this.cdr.detectChanges();

          this.analyticsService.logEvent('Asset details', {
            eventType: AnalyticsEventType.NAVIGATION,
            resource: asset.name,
          });

          // Hide camera view.
          this.toggleMultiCameraView(false);
        });

    // Update player time when a segment in Play Feed Panel is clicked
    this.stateService.playFeedClickTime$.pipe(takeUntil(this.destroyed$))
        .subscribe(time => {
          this.fullPlayer.movePlayheadAndSeek(time);
        });
  }

  ngAfterViewInit() {
    // Consider page loaded when the first frame of the main video is shown.
    combineLatest([this.stateService.currentPlayerTime$, this.fullPlayer.onNextCanPlay()])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(async ([time]) => {
          this.performanceTrace?.stop({
            attributes: {
              assetDuration: castExists(this.asset).duration,
              videoDuration: this.fullPlayer.getVideoDuration(),
            },
          });

          if (!time || time < 0) {
            return;
          }
          await this.fullPlayer.movePlayheadAndSeek(time);
          this.stateService.currentPlayerTime$.next(0);
        });

    // Set `cameras` context for multi-camera events when there is no other
    // context available. This is possible because we always load related camera
    // assets in the background.
    this.cameraViewAssets$
        .pipe(
            takeUntil(this.destroyed$),
            withLatestFrom(this.detailsNavigation.context$))
        .subscribe(([assets, {asset, type}]) => {
          if (type && type !== ContextType.NONE || !assets.length) return;

          const index = assets.findIndex(a => a.name === asset?.name);
          if (index === -1) return;

          this.detailsNavigation.updateType(ContextType.CAMERAS, true);
        });

    // Actions to be done only one time when the Details view is displayed and
    // the first context is received.
    this.detailsNavigation.context$.pipe(take(1), takeUntil(this.destroyed$))
        .subscribe(context => {
          // Set up Shaka Player batch URL signing, which will act differently
          // if viewing a shared video.
          this.fullPlayer.addSegmentSigningHook(
              rawUrls => this.batchOperationService.batchSignUrls(
                  rawUrls, context.link?.name));
        });

    this.playerFullscreenService.registerElement(this.mainSection.nativeElement, FullscreenElementPriorityPresets.details);

  }

  constructor(
      private readonly dialog: MatDialog,
      readonly detailsNavigation: DetailsNavigationService,
      private readonly batchOperationService: BatchOperationService,
      private readonly cdr: ChangeDetectorRef,
      private readonly location: Location,
      private readonly router: Router,
      private readonly snackBar: SnackBarService,
      private readonly analyticsService: FirebaseAnalyticsService,
      private readonly searchInputService: SearchInputService,
      private readonly searchService: SearchService,
      private readonly utils: UtilsService,
      private readonly featureFlag: FeatureFlagService,
      readonly stateService: StateService,
      performanceService: FirebasePerformanceService,
      private readonly renditionService: RenditionService,
      readonly playerFullscreenService: PlayerFullscreenService
  ) {
    // Empty state asset when first entering the Details view. It will be
    // updated once a context is received.
    this.stateService.currentAsset$.next(undefined);

    this.analyticsService.logEvent('Visited details', {
      eventType: AnalyticsEventType.NAVIGATION,
      path: '/details',
    });

    this.performanceTrace =
        performanceService.startTrace(TraceName.DETAILS_PAGE_READY);
    this.router.events
        .pipe(
            performanceService.recordInitialPageLoad(this.performanceTrace),
            takeUntil(this.destroyed$))
        .subscribe();
  }

  onPlayerError(message: string) {
    this.snackBar.error(message);
    this.location.back();
  }

  onPlayerUpdate(event: PlayerUpdate) {
    this.stateService.playerUpdate$.next(event);
  }

  handleShortcutEvent(event: ShortcutEvent) {
    if (event.targetView) {
      this.stateService.currentView$.next(event.targetView);
    }
    if (event.targetTab) {
      this.stateService.currentPersistentTab$.next(event.targetTab);
    }
    this.stateService.shortcutEvent$.next(event);
  }

  onMarkingChanged(marking?: ClipMarking) {
    this.stateService.clipMarking$.next(marking);
  }

  /**
   * Returns `true` if the current asset is not a shared video. Used for
   * displaying elements in the UI that are shown only in the private MAM UI and
   * hidden when accessing a shared link.
   */
  isSharedVideo() {
    return this.detailsNavigation.isSharedVideo();
  }

  toggleMultiCameraView(display = !this.cameraGridShown) {
    if (display) {
      this.fullPlayer.pause();
    }

    this.cameraGridShown = display;
  }

  async openAddClipDialog(clipMarking?: ClipMarking) {
    if (this.clipCreationInProgress$.value) return;

    const asset = castExists(this.asset);
    this.clipCreationInProgress$.next(true);

    this.dialog
        .open<AddClipDialog, AddClipDialogInputData, AddClipDialogOutputData>(
            AddClipDialog, AddClipDialog.getDialogOptions({asset, clipMarking}, '550px'))
        .afterClosed()
        .subscribe(success$ => {
          (success$ || of(false)).subscribe(success => {
            if (success) {
              this.fullPlayer.clearMarking();
            }

            this.clipCreationInProgress$.next(false);
          });
        });
  }

  /**
   * Generates the extra properties that will be saved with a new shared link.
   * In particular, adds the current audio track index to retrieve it later from
   * the SharedLink public page.
   */
  getSharingAdditionalProperties(): Record<string, string>|undefined {
    const trackIndex = this.fullPlayer?.getCurrentTrackIndex() ?? -1;
    if (trackIndex === -1) return undefined;
    return {'trackIndex': String(trackIndex)};
  }

  /** Handles on-destroy Subject, used for unsubscribing. */
  private readonly destroyed$ = new ReplaySubject<void>(1);

  private readonly performanceTrace?: Trace;

  private getManifestUrl(asset: Asset) {
    return this.renditionService.getManifestUrl(asset);
  }

  private openNextAssetIfPossible() {
    this.detailsNavigation.canGoNext$.pipe(take(1)).subscribe((nextAllowed) => {
      if (nextAllowed) {
        this.detailsNavigation.next();
      }
    });
  }

  private openPreviousAssetIfPossible() {
    this.detailsNavigation.canGoPrevious$.pipe(take(1)).subscribe(
        (prevAllowed) => {
          if (prevAllowed) {
            this.detailsNavigation.previous();
          }
        });
  }

  private isAddClipsDisabled() {
    // Clip addition buttons are disabled if one is currently being created, if
    // the clipbin-selection overlay panel is displayed, or if no clipbin is
    // selected from the persistent panel.
    return combineLatest([
             this.clipCreationInProgress$,
             this.stateService.persistentBinName$,
           ])
        .pipe(map(([clipCreationInProgress, currentPersistentBin]) => {
          return clipCreationInProgress || !currentPersistentBin;
        }));
  }

  ngOnDestroy() {
    this.stateService.currentAsset$.next(undefined);
    // Clear the video insights asset to force re-query the next time Details
    // is opened, for instance from a search result.
    this.stateService.currentInsightAsset$.next(undefined);
    // Unsubscribes all pending subscriptions.
    this.destroyed$.next();
    this.destroyed$.complete();
    this.playerFullscreenService.deregisterElement(FullscreenElementPriorityPresets.details.key);
  }
}
