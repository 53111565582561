<mat-card
    appearance="outlined"
    class="mam-elevation-2"
    *ngIf="items.length > 0"
    @slideUp>
  <div class="summary row">
    <div>{{getHeaderMessage(items.length)}}</div>
    <!-- The value of `expanded` for `mam-firebase-ga` is changed just before
      the event is logged -->
    <button
        mat-icon-button
        aria-label="Toggle list"
        [mam-firebase-ga]="expanded ? 'Expand processing asset list' : 'Fold processing asset list'"
        (click)="expanded = !expanded">
      <mat-icon>{{expanded ? 'expand_more' : 'expand_less'}}</mat-icon>
    </button>
  </div>
  <div class="items">
    <div class="item row" *ngFor="let item of items">
      <div [attr.title]="item">{{item}}</div>
      <mat-spinner diameter="20"></mat-spinner>
    </div>
  </div>
</mat-card>
