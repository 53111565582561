<h1 mat-dialog-title class="mat-dialog-title">Change Site</h1>
<ng-container *ngIf="mediaCache.state.selectedSite$ | async as selectedSite">
  <button
    mat-button
    mam-firebase-ga="Open site picker"
    [mam-firebase-resource]="selectedSite.name"
    [matMenuTriggerFor]="sitesMenu"
    [title]="selectedSite.siteId"
    class="site-select">
  <mat-icon class="overlay-domain-icon">domain</mat-icon>
    <ng-container>
      <span class="domain-name">{{selectedSite.siteId | uppercase}}</span>
      <mat-icon class="arrow overlay-arrow">arrow_drop_down</mat-icon>
    </ng-container>
  </button>
  <mat-menu #sitesMenu="matMenu" class="menu-offset-down">
    <button
        *ngFor="let site of selectableSites$ | async"
        mat-menu-item
        (click)="mediaCache.state.selectSite(site)"
        mam-firebase-ga="Select a site"
        [mam-firebase-resource]="site.name">
      <mat-icon>domain</mat-icon>
      <span>{{site.siteId | uppercase}}</span>
    </button>
  </mat-menu>
</ng-container>
<div mat-dialog-actions>
    <button mat-button class="primary" #primary (click)="closeDialog()">
        Close
    </button>
</div>
