import {ErrorHandler, Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {Subject} from 'rxjs';

import {StackdriverConfig, StackdriverErrorReporter, WindowObject} from './stackdriver';

/**
 * Injection token for configuring the stackdriver error reporter.
 */
export const STACKDRIVER_CONFIG = new InjectionToken<StackdriverConfig>(
    'Stackdriver error reporter configuration');

/**
 * Custom ErrorHandler which sends exceptions up to Stackdriver error reporting
 * service. We extend the base `ErrorHandler` so that we can call
 * `super.handleError` which has better logging than a simple `console.error`.
 */
@Injectable({providedIn: 'root'})
export class StackdriverReporter extends ErrorHandler {
  private readonly reportableErrors = new Subject<Error>();

  constructor(@Optional() @Inject(STACKDRIVER_CONFIG)
              private readonly stackdriverConfig?: StackdriverConfig) {
    super();
    if (this.stackdriverConfig?.key) {
      this.initializeReporter(this.stackdriverConfig);
    }
  }

  override handleError(error: Error) {
    // The base handler logs errors to the console and preserves context
    // such as HTML template line numbers.
    super.handleError(error);

    if (this.stackdriverConfig?.key) {
      this.reportError(error);
    }
  }

  private reportError(error: Error) {
    this.reportableErrors.next(error);
  }

  private getStackdriver(): StackdriverErrorReporter {
    return new (window as {} as WindowObject).StackdriverErrorReporter();
  }

  /**
   * Initializes the stackdriver error reporter. This function needs only to be
   * executed a single time.
   */
  private initializeReporter(config: StackdriverConfig) {
    const reporter = this.getStackdriver();
    reporter.start(config);
    this.reportableErrors.subscribe(error => {
      reporter.report(error);
    });
  }
}
