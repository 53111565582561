/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiFile } from './api-file';
import { ApiFolder } from './api-folder';


/**
 * Defines a transfer task
 */
export interface ApiTransferTask { 
    /**
     * upload/download or tool names are set as tags
     */
    tags?: Array<string>;
    /**
     * Deletion task type
     */
    deletionType?: ApiTransferTask.DeletionTypeEnum;
    /**
     * percentage complete of each file (% bytes transferred) in same order as in files field
     */
    fileProgress?: Array<number>;
    /**
     * File (from the same folder) that to be transferred
     */
    files?: Array<ApiFile>;
    folder?: ApiFolder;
    /**
     * Deprecated: this field will be deprecated after internal cleanup whether the delete task is trigger manually or by lifecycleHandler
     */
    manualOnpremDelete?: boolean;
    /**
     * Name of the transfertask: projects/{project}/locations/{location}/sites/{site}/transfertasks/{transfertask}
     */
    name?: string;
    /**
     * retry count
     */
    retries?: number;
    /**
     * User defined task name
     */
    taskId?: string;
    /**
     * timeout period
     */
    timeoutPeriod?: number;
    /**
     * transfer task end(complete) time, only valid after transfer is complete
     */
    transferEndTime?: string;
    /**
     * last update to the task (or creation time initially)
     */
    transferModTime?: string;
    /**
     * Output only. transfer rate (MiB / second), only valid after transfer is complete
     */
    readonly transferRate?: number;
    /**
     * transfer task start time, only valid after transfer has started
     */
    transferStartTime?: string;
    /**
     * Transfer status
     */
    transferState?: ApiTransferTask.TransferStateEnum;
    /**
     * transfer direction
     */
    transferType?: ApiTransferTask.TransferTypeEnum;
}
export namespace ApiTransferTask {
    export type DeletionTypeEnum = 'DELETION_TYPE_UNSPECIFIED' | 'LIFECYCLE' | 'MANUAL' | 'CLOUD_STORAGE_FILE_DELETION';
    export const DeletionTypeEnum = {
        DELETION_TYPE_UNSPECIFIED: 'DELETION_TYPE_UNSPECIFIED' as DeletionTypeEnum,
        LIFECYCLE: 'LIFECYCLE' as DeletionTypeEnum,
        MANUAL: 'MANUAL' as DeletionTypeEnum,
        CLOUD_STORAGE_FILE_DELETION: 'CLOUD_STORAGE_FILE_DELETION' as DeletionTypeEnum
    };
    export type TransferStateEnum = 'TRANSFER_STATE_UNSPECIFIED' | 'TRANSFER_STATE_PENDING' | 'TRANSFER_STATE_PROCESSING' | 'TRANSFER_STATE_COMPLETED' | 'TRANSFER_STATE_ERROR';
    export const TransferStateEnum = {
        TRANSFER_STATE_UNSPECIFIED: 'TRANSFER_STATE_UNSPECIFIED' as TransferStateEnum,
        TRANSFER_STATE_PENDING: 'TRANSFER_STATE_PENDING' as TransferStateEnum,
        TRANSFER_STATE_PROCESSING: 'TRANSFER_STATE_PROCESSING' as TransferStateEnum,
        TRANSFER_STATE_COMPLETED: 'TRANSFER_STATE_COMPLETED' as TransferStateEnum,
        TRANSFER_STATE_ERROR: 'TRANSFER_STATE_ERROR' as TransferStateEnum
    };
    export type TransferTypeEnum = 'TRANSFER_DIRECTION_UNSPECIFIED' | 'TRANSFER_DIRECTION_UPLOAD' | 'TRANSFER_DIRECTION_DOWNLOAD' | 'TRANSFER_DIRECTION_RELOCATE' | 'TRANSFER_DIRECTION_ONPREM_DELETE';
    export const TransferTypeEnum = {
        TRANSFER_DIRECTION_UNSPECIFIED: 'TRANSFER_DIRECTION_UNSPECIFIED' as TransferTypeEnum,
        TRANSFER_DIRECTION_UPLOAD: 'TRANSFER_DIRECTION_UPLOAD' as TransferTypeEnum,
        TRANSFER_DIRECTION_DOWNLOAD: 'TRANSFER_DIRECTION_DOWNLOAD' as TransferTypeEnum,
        TRANSFER_DIRECTION_RELOCATE: 'TRANSFER_DIRECTION_RELOCATE' as TransferTypeEnum,
        TRANSFER_DIRECTION_ONPREM_DELETE: 'TRANSFER_DIRECTION_ONPREM_DELETE' as TransferTypeEnum
    };
}


