<div mat-dialog-content>
  <mam-player-with-controls
      [asset]="data.asset"
      [compact]="true"
      [cutDownMode]="true"
      [signer]="signer"
      (confirmCutDown)="onCutDownConfirmed($event)"
      (playerError)="onPlayerError($event)">
  </mam-player-with-controls>
</div>
