import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnDestroy, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Sort} from '@angular/material/sort';
import {DateTime} from 'luxon';
import {BehaviorSubject, of, ReplaySubject} from 'rxjs';
import {debounceTime, finalize, map, switchMap, take, takeUntil} from 'rxjs/operators';

import {FirebaseFirestoreDataService} from '../firebase/firebase_firestore_data_service';
import {ProgressbarService} from '../services/progressbar_service';
import {SnackBarService} from '../services/snackbar_service';
import {MultiSelectOption, MultiSelectOptions} from '../transfer_monitor/multiselect_table_header';

import {AdminUsersService, GoogleWorkspaceAccess, IasUser, UserAccess} from './admin_users_service';

interface PageEvent {
  pageSize: number,
  pageIndex: number,
}

interface UpdateGoogleWorkspaceEvent {
  email: string;
  hasGoogleWorkspace: string;
}

const LAST_LOGIN_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

// 'IN' supports up to 30 comparison values.
const USERS_CHUNK_SIZE = 30;

const DEFAULT_PAGE_SIZE = 30;

/** Page size options for list view. */
const PAGE_SIZE_OPTIONS = [DEFAULT_PAGE_SIZE, 50, 100, 200];

const ALL_COLUMNS = [
  'displayName',
  'email',
  'oktaStatus',
  'lastLogin',
  'access',
  'googleWorkspaceAccess',
] as const;

type Column = typeof ALL_COLUMNS[number];

const DEFAULT_SORT: Sort = {
  active: 'displayName',
  direction: 'asc',
};

@Component({
  selector: 'mam-admin-users-table',
  templateUrl: './admin_users_table.ng.html',
  styleUrls: ['./admin_users_table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUsersTable implements OnDestroy {
  @HostBinding('class.loading') loading = false;

  @Output() readonly scrollTopNeeded = new EventEmitter();

  readonly search = new FormControl<string>('');

  readonly displayedColumns = ALL_COLUMNS;

  readonly PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  readonly currentPage$ = new BehaviorSubject<PageEvent>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  readonly searchResponse$ = this.getSearchResponse();

  private allUsers: IasUser[] = [];
  filteredUsers: IasUser[] = [];

  protected readonly destroyed$ = new ReplaySubject<void>(1);

  /** Options for "Access" column filter. */
  accessFilterOptions: MultiSelectOptions<UserAccess> = [
    {title: 'Any Access', selected: true},
    {title: 'Admin', value: UserAccess.ADMIN},
    {title: 'User', value: UserAccess.USER},
  ];

  /** Options for "Workspace" column filter. */
  googleWorkspaceAccessFilterOptions: MultiSelectOptions<GoogleWorkspaceAccess> = [
    {title: 'Any Workspace', selected: true},
    {title: 'Yes', value: GoogleWorkspaceAccess.YES},
    {title: 'No', value: GoogleWorkspaceAccess.NO},
  ];

  /** Options for okta status column filter. */
  oktaStatusFilterOptions: MultiSelectOptions<string> = [
    {title: 'Any OKTA Status', selected: true},
  ];

  activeSort = DEFAULT_SORT;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly adminUsersService: AdminUsersService,
    private readonly snackbar: SnackBarService,
    private readonly progressbar: ProgressbarService,
    private readonly dataService: FirebaseFirestoreDataService,
  ) {
    this.readUsers();
    this.listenSearchQueryChanges();
  }

  private getSearchResponse() {
    return this.currentPage$.pipe(
      switchMap(page => {
        const portionStart = page.pageIndex * page.pageSize;
        const portionEnd = portionStart + page.pageSize;
        const portion = this.filteredUsers.slice(portionStart, portionEnd);
        return of(portion);
      }),
    );
  }

  private readUsers() {
    this.progressbar.show();
    this.adminUsersService.searchUsers().pipe(
        take(1),
        map(response => {
          if (!response) {
            this.snackbar.error('Failed to get users');
          }

          this.allUsers = preprocessUsers(response);
          this.sortUsers(this.allUsers);
          this.readLastLoginData();
          this.fillOktaStatusFilterOptions();
          this.refreshTable();
        }),
        finalize(() => {
          this.progressbar.hide();
        }),
    ).subscribe();
  }

  private readLastLoginData() {
    for (let i = 0; i < this.allUsers.length; i += USERS_CHUNK_SIZE) {
        const chunk = this.allUsers.slice(i, i + USERS_CHUNK_SIZE);
        this.readLastLoginDataForPortion(chunk);
    }
  }

  private readLastLoginDataForPortion(users: IasUser[]) {
    const emailUserMap = new Map(users.map(u => [u.email, u]));
    const emails = users.map(u => u.email);
    this.dataService.retrieveIASEventsForLoginAction(emails)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(events => {
      for (const event of events) {
        if (event.emailID) {
          const user = emailUserMap.get(event.emailID);
          if (user) {
            const eventLoginDate = DateTime.fromISO(event.createTime);
            if (!user.lastLoginDate || user.lastLoginDate < eventLoginDate) {
              user.lastLoginDate = eventLoginDate;
              user.lastLogin = eventLoginDate.toFormat(LAST_LOGIN_DATE_FORMAT);
            }
          }
        }
      }
      this.cdr.markForCheck();
    });
  }

  private fillOktaStatusFilterOptions() {
    const oktaStatuses = new Set<string>();
    this.allUsers.filter(u => u.oktaStatus).forEach(u => oktaStatuses.add(u.oktaStatus));
    oktaStatuses.forEach(s => this.oktaStatusFilterOptions.push({title: s, value: s}));
  }

  private listenSearchQueryChanges() {
    this.search.valueChanges.pipe(
        takeUntil(this.destroyed$),
        debounceTime(300),
    ).subscribe(() => this.refreshTable());
  }

  onAccessFilterChanged(option: MultiSelectOption) {
    this.accessFilterOptions.forEach(opt => opt.selected = opt.title === option.title);
    this.refreshTable();
  }

  onGoogleWorkspaceAccessFilterChanged(option: MultiSelectOption) {
    this.googleWorkspaceAccessFilterOptions.forEach(opt => opt.selected = opt.title === option.title);
    this.refreshTable();
  }

  onOktaStatusFilterChanged(option: MultiSelectOption) {
    this.oktaStatusFilterOptions.forEach(opt => opt.selected = opt.title === option.title);
    this.refreshTable();
  }

  changePage({pageIndex, pageSize}: PageEvent) {
    this.scrollTopNeeded.emit();
    this.currentPage$.next({pageIndex, pageSize});
  }

  private changePageIndex(pageIndex: number) {
    const current = this.currentPage$.value;
    const pageSize = current.pageSize;
    this.changePage({pageIndex, pageSize});
  }

  private refreshTable() {
    this.filterUsers();
    this.sortUsers(this.filteredUsers);
    this.changePageIndex(0);
  }

  private filterUsers() {
    let filteredUsers = this.allUsers.slice(0);

    const searchQuery = this.search.getRawValue();
    if (searchQuery) {
      filteredUsers = filteredUsers.filter(u => userMatchesQuery(u, searchQuery));
    }

    const selectedAccess = this.accessFilterOptions.find(opt => opt.selected)?.value;
    if (selectedAccess) {
      filteredUsers = filteredUsers.filter(u => userMatchesAccess(u, selectedAccess));
    }

    const selectedGoogleWorkspaceAccess = this.googleWorkspaceAccessFilterOptions.find(opt => opt.selected)?.value;
    if (selectedGoogleWorkspaceAccess) {
      filteredUsers = filteredUsers.filter(u => userMatchesGoogleWorkspaceAccess(u, selectedGoogleWorkspaceAccess));
    }

    const selectedOktaStatus = this.oktaStatusFilterOptions.find(opt => opt.selected)?.value;
    if (selectedOktaStatus) {
      filteredUsers = filteredUsers.filter(u => userMatchesOktaStatus(u, selectedOktaStatus));
    }

    this.filteredUsers = filteredUsers;
  }

  private sortUsers(users: IasUser[]) {
    const property = this.activeSort.active as Column;
    const asc = this.activeSort.direction === 'asc';
    users.sort((a, b) => ((a[property] || '') < (b[property] || '') ? (asc ? -1 : 1) : (asc ? 1 : -1)));
  }

  updateGoogleWorkspaceOnUser(event:UpdateGoogleWorkspaceEvent){
    const foundUser = this.allUsers.find(user => user.email === event.email);

    if (foundUser) {
      //TODO modify response with enum directly from child
      foundUser.googleWorkspaceAccess = event.hasGoogleWorkspace as GoogleWorkspaceAccess;
    }
  }

  onSortChanged(sort: Sort) {
    this.activeSort = sort;
    this.sortUsers(this.filteredUsers);
    this.changePageIndex(0);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  protected readonly GoogleWorkspaceAccess = GoogleWorkspaceAccess;
}

function preprocessUsers(users: IasUser[]) {
  return filterOutDuplicates(users);
}

function filterOutDuplicates(users: IasUser[]): IasUser[] {
  const result: IasUser[] = [];
  for (const user of users) {
    const originalUser = result.filter(u => u.email === user.email);
    if (originalUser.length) {
      mergeUsers(originalUser[0], user);
    } else {
      result.push(user);
    }
  }
  return result;
}

function mergeUsers(original: IasUser, duplicate: IasUser) {
  if (duplicate.access === UserAccess.ADMIN) {
    original.access = UserAccess.ADMIN;
  }
}

function userMatchesQuery(user: IasUser, query: string): boolean {
  query = query.toLowerCase();
  const displayName = user.displayName.toLowerCase();
  const email = user.email.toLowerCase();
  return displayName.includes(query) || email.includes(query);
}

function userMatchesAccess(user: IasUser, access: UserAccess): unknown {
  return user.access === access;
}

function userMatchesGoogleWorkspaceAccess(user: IasUser, access: GoogleWorkspaceAccess): unknown {
  return user.googleWorkspaceAccess === access;
}

function userMatchesOktaStatus(user: IasUser, oktaStatus: string): unknown {
  return user.oktaStatus === oktaStatus;
}
