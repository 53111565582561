import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';

import {TimezoneService} from '../services/timezone_service';

/**
 * Date pipe that formats date base on pre-configured timezone and is compatibe
 * with Angular DatePipe.
 *
 * Key differences from DatePipe:
 * - the pipe name is `tzdate` instead of `date`;
 * - automatically calculates timezone offset based on pre-configured timezone;
 * - does not have `timezone` parameter;
 * - understands luxon DateTime ;
 * 
 * @example
 * {{ dateObj | tzdate }}               // output is 'Jun 15, 2015'
 * {{ dateObj | tzdate:'medium' }}      // output is 'Jun 15, 2015, 9:43:11 PM'
 * {{ dateObj | tzdate:'shortTime' }}   // output is '9:43 PM'
 * {{ dateObj | tzdate:'mm:ss' }}       // output is '43:11'
 * @see DatePipe for more examples
 */
@Pipe({name: 'tzdate'})
export class TzDatePipe implements PipeTransform {
  constructor(
      private readonly timezone: TimezoneService,
      private readonly datePipe: DatePipe,
  ) {}

  /**
   * @param value The date expression: luxon `DateTime` object, a `Date` object,
   * a number (milliseconds since UTC epoch), or an ISO string
   * (https://www.w3.org/TR/NOTE-datetime).
   * @param format The date/time components to include, using predefined options
   * or a custom format string.
   * @param locale A locale code for the locale format rules to use.
   * When not supplied, uses the value of `LOCALE_ID`, which is `en-US` by
   * default. See [Setting your app
   * locale](guide/i18n#setting-up-the-locale-of-your-app).
   * @returns A date string in the desired format.
   */
  transform(
      value: Date|DateTime|number|string|null|undefined, format?: string,
      locale?: string): string|null {
    if (value == null) {
      return null;
    }
    if (typeof value === 'number') {
      if (Number.isNaN(value)) return null;
      value = DateTime.fromMillis(value);
    } else if (typeof value === 'string') {
      value = DateTime.fromISO(value);
    } else if (value instanceof Date) {
      value = DateTime.fromJSDate(value);
    }
    // Timezone offset can vary and depends on concrete date. (e.g. PDT vs PST).
    const offsetInMinutes = this.timezone.convert(value).offset;

    return this.datePipe.transform(
        value.toJSDate(), format, this.getOffsetString(offsetInMinutes),
        locale);
  }

  /**
   * Returns timezone offset in the format that is understood by Angular.
   * This allows TzDatePipe to use the same exct formatting options as DatePipe.
   * 
   * @example
   * 270 => '+0430'
   * -500 => '-0820'
   */
  private getOffsetString(offsetInMinutes: number) {
    const sign = offsetInMinutes < 0 ? '-' : '+';
    offsetInMinutes = Math.abs(offsetInMinutes);

    const hours = this.padZero(Math.floor(offsetInMinutes / 60));
    const minutes = this.padZero(offsetInMinutes % 60);

    return `${sign}${hours}${minutes}`;
  }

  /**
   * Returns number as string. Prepends one zero if the number is single digit:
   * 
   * @example
   * 55 => '55', 5 => '05', 0 => '00'
   * @param value Non-negative integer
   */
  private padZero(value: number) {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
