import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** Helper methods to handle scrubbing behavior. */
@Injectable({providedIn: 'root'})
export class ScrubbingService {

  private scrubbingSubject$ = new BehaviorSubject<boolean>(false);

  scrubbing$ = this.scrubbingSubject$.asObservable();

  startScrubbingEvent() {
    this.scrubbingSubject$.next(true);
  }
  endScrubbingEvent() {
    this.scrubbingSubject$.next(false);
  }
}