import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';

import {FeatureFlagService} from '../feature_flag/feature_flag_service';


const ALL_ADMIN_TABS = [
  'users',
  'metrics',
] as const;

export type Tab = typeof ALL_ADMIN_TABS[number];

@Component({
  selector: 'mam-admin-landing',
  templateUrl: './admin_landing.ng.html',
  styleUrls: ['./admin_landing.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminLanding implements OnDestroy {
  selectedTabIndex = 0;

  readonly tabs: Tab[] = [];

  /** Flag to show metrics tab. */
  readonly showMetricsTabFF =
      this.featureService.featureOn('show-admin-metrics-tab');

  constructor(
      private readonly cdr: ChangeDetectorRef,
      private readonly route: ActivatedRoute,
      private readonly router: Router,
      private readonly featureService: FeatureFlagService,
  ) {
    this.tabs.push(...ALL_ADMIN_TABS);

    // Observe URL params to switch between tabs.
    this.route.paramMap.pipe(
        takeUntil(this.destroyed$),
        map(params => params.get('type'))
    ).subscribe(type => {
        this.cdr.markForCheck();

        const index = this.tabs.indexOf(type as Tab);
        if (index < 0) {
          this.router.navigate(['/404'], {skipLocationChange: true});
          return;
        }

        this.selectedTabIndex = index;
      });
  }

  changeTab(index: number) {
    assertTruthy(
        index >= 0 && index < this.tabs.length,
        `Tab index ${index} is out of bounds.`);
    const type = this.tabs[index];
    this.router.navigate([`../${type}`], {
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);
}
