<ng-container *ngIf="{
  displayMode: (vodSearchService.displayMode$ | async)!,
  results: results$ | async,
  searchMode: searchMode$ | async
} as tplState">
  <div
      class="search-results"
      [class.view-mode-grid]="isGrid(tplState.displayMode)"
      [class.view-mode-list]="isList(tplState.displayMode)"
      [class.loading]="progressbar.visible$ | async"
      #searchResultsEl>
    <ng-container
        *ngIf="
        tplState.results &&
        tplState.searchMode &&
        getSelectionInfo(tplState.results.segments, vodSearchService.selectedSegmentNames) as selectionInfo">
      <div class="results" #scrollView>
          <!-- Search result header -->
          <ng-container
              *ngIf="tplState.results.segments.length > 0 || vodSearchService.pagination.pageIndex > 0">
            <div class="asset-results-bar">
              <div class="asset-results-header">
                <span>
                  {{formatInputPlaceholder(tplState.searchMode)}}
                </span>
                <button
                    *ngIf="tplState.searchMode === SearchMode.VIDEO"
                    mat-icon-button
                    class="display-mode-toggle"
                    (click)="this.toggleViewMode(tplState.displayMode)"
                    [matTooltip]="'Switch to ' + (isGrid(tplState.displayMode) ? 'list view': 'grid view')"
                    [mam-firebase-ga]="'Toggle ' + (isGrid(tplState.displayMode) ? 'list view': 'grid view') +' for search results'">
                  <mat-icon class="material-icons-outlined">
                    {{isGrid(tplState.displayMode) ? 'list' : 'view_module'}}
                  </mat-icon>
                </button>
              </div>
              <div class="filler"></div>
              <!-- Paginator for grid view -->
              <ng-container *ngIf="isGrid(tplState.displayMode)">
                <ng-container
                    *ngTemplateOutlet="paginator; context: {showPageSelector: false}">
                </ng-container>
              </ng-container>
            </div>
            <!-- List view header -->
            <div
                *ngIf="isList(tplState.displayMode)"
                class="search-bar-container">
              <div class="video-actions">

                <!-- Select/De-select all. -->
                <mat-checkbox
                    color="primary"
                    (change)="selectionInfo.areAll
                                      ? select([])
                                      : select(selectionInfo.selectableItems)"
                    [checked]="selectionInfo.areAll"
                    [indeterminate]="selectionInfo.indeterminate"
                    [aria-label]="tableUtils.allCheckboxLabel(selectionInfo)"
                    [matTooltip]="tableUtils.allCheckboxLabel(selectionInfo)"
                    matTooltipPosition="above"
                    mam-firebase-ga="Toggle selection for all segments in search results">
                </mat-checkbox>

                <!-- Actions for selected assets. -->
                <ng-container *ngIf="selectionInfo.areAny">
                  <!-- Add clips -->
                  <button
                      mat-icon-button
                      (click)="addClipsToBins(selectionInfo.selectedItems)"
                      class="add-selected-to-bins main__icon--size"
                      aria-label="Add selected assets to clip bins"
                      matTooltip="Add selected assets to clip bins"
                      matTooltipPosition="above"
                      mam-firebase-ga="Add selected assets to clip bins from search results">
                    <mat-icon class="material-icons-outlined">add_box
                    </mat-icon>
                  </button>

                  <!-- Edit assets metadata -->
                  <button
                      mat-icon-button
                      (click)="edit(selectionInfo.selectedItems)"
                      class="edit-selected main__icon--size"
                      aria-label="Edit selected assets"
                      matTooltip="Edit selected assets"
                      matTooltipPosition="above"
                      mam-firebase-ga="Bulk assets edit in search results">
                    <mat-icon class="material-icons-outlined">edit</mat-icon>
                  </button>

                  <!-- Export to Folder -->
                  <button
                      mat-icon-button
                      class="main__icon--size"
                      (click)="exportOriginalAssets(selectionInfo.selectedItems)"
                      aria-label="Export selected assets"
                      matTooltip="Export selected assets"
                      matTooltipPosition="above"
                      mam-firebase-ga="Export selected full assets in search results">
                    <mat-icon>arrow_outward</mat-icon>
                  </button>
                  <!-- TTL Extension -->
                  <button
                      mat-icon-button
                      (click)="extendAssetsTtl(selectionInfo.selectedItems)"
                      class="extend-ttl-selected main__icon--size"
                      aria-label="Extend TTL on selected assets"
                      matTooltip="Extend TTL on selected assets"
                      matTooltipPosition="above"
                      mam-firebase-ga="Extend TTL on selected assets in search results">
                    <mat-icon class="material-icons-outlined">more_time</mat-icon>
                  </button>
                  <!-- Purge -->
                  <button
                      mat-icon-button
                      (click)="purgeAssets(selectionInfo.selectedItems)"
                      class="purge-selected main__icon--size"
                      aria-label="Delete on-prem files"
                      matTooltip="Delete on-prem files"
                      matTooltipPosition="above"
                      mam-firebase-ga="Purge search results selection">
                    <mat-icon class="material-icons-outlined">folder_delete</mat-icon>
                  </button>
                  <!-- Delete -->
                  <button
                      mat-icon-button
                      (click)="deleteAssets(selectionInfo.selectedItems)"
                      class="delete-selected main__icon--size"
                      aria-label="Delete selected assets"
                      matTooltip="Delete selected assets"
                      matTooltipPosition="above"
                      mam-firebase-ga="Delete selected assets from search results">
                  <mat-icon class="material-icons-outlined">delete</mat-icon>
                </button>
                </ng-container>
              </div>
              <!-- Paginator for List view -->
              <ng-container
                  *ngTemplateOutlet="paginator; context: {showPageSelector: true}">
              </ng-container>
            </div>
          </ng-container>

          <!-- Container for Grid/List view-->
          <!-- TODO: Split Grid and list view into separate components -->
          <div
              @slideUpIn
              *ngIf="tplState.results.segments.length > 0; else displayEmptyContents">

            <!-- GRID VIEW -->
            <div *ngIf="isGrid(tplState.displayMode); else listView"
                class="asset-results">
              <!-- TODO: Update with shared/mam-asset-preview -->
              <mat-card
                  appearance="outlined"
                  *ngFor="let segment of tplState.results.segments; let index = index; trackBy: trackSegment"
                  class="mam-elevation-2"
                  [class.no-rendition]="!segment.asset.renditions.length"
                  [class.deleted]="segment.asset.isDeleted ||
                      segment.paddedDuration <= 0"
                  [routerLink]="['/asset', segment.asset.name]"
                  [queryParams]="{'type': 'search', 'initialIndex': vodSearchService.getAbsoluteIndex(index)}"
                  queryParamsHandling="merge"
                  mam-firebase-ga="Open VoD asset from search results"
                  [mam-firebase-resource]="segment.asset.name">
                <mam-seekable-preview
                    [previewUrl]="(segment.asset | bestSeekUrl:segment.duration | async)!"
                    [start]="segment.paddedStartTime"
                    [end]="segment.paddedEndTime"
                    [asset]="segment.asset"
                    [thumbTime]="getThumbTime(segment)"
                    (thumbnailLoad)="onThumbnailLoaded()">
                </mam-seekable-preview>
                <div class="timeline"
                    *ngIf="tplState.searchMode === SearchMode.SEGMENT">
                  <span
                      class="result-area"
                      [style.left]="segment.redAreaLeft * 100 + '%'"
                      [style.width]="segment.redAreaWidth * 100 + '%'">
                  </span>
                </div>
                <div class="info" *ngIf="segment.asset.name">
                  <div class="info-details" #infoLeft>
                    <div class="title" [attr.title]="segment.asset.title">
                      {{segment.asset.title}}
                    </div>
                    <div class="date">{{segment.asset.eventTime | tzdate}}</div>
                    <div class="duration" *ngIf="segment.duration">
                      {{segment.duration | duration}}
                    </div>
                  </div>
                  <div class="actions" *ngIf="!segment.asset.isDeleted">
                    <!-- Import status icon is only shown for full videos. -->
                    <mam-asset-location-status
                        *ngIf="tplState.searchMode === SearchMode.VIDEO"
                        [asset]="segment.asset">
                    </mam-asset-location-status>

                    <mam-asset-extra-actions
                        *ngIf="!segment.asset.isDeleted"
                        [asset]="segment.asset"
                        [paddedSegment]="segment"
                        [anchor]="infoLeft"
                        [enableFullMenu]="tplState.searchMode === SearchMode.VIDEO">
                    </mam-asset-extra-actions>
                  </div>
                </div>
              </mat-card>
            </div>

            <!-- LIST VIEW -->
            <ng-template #listView>
              <table
                  class="search-result__table--spacing"
                  mat-table
                  [dataSource]="tplState.results.segments"
                  [trackBy]="trackSegment">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <!-- Expand checkbox clickable area by also listening to td.click -->
                  <td
                      class="search-result__table--spacing-first-column"
                      mat-cell
                      *matCellDef="let segment"
                      (click)="$event.stopPropagation();toggleSelection(segment, tplState.results.segments, $event.shiftKey)">
                    <mat-checkbox
                        color="primary"
                        [checked]="isSelected(segment)"
                        [aria-label]="tableUtils.checkboxLabel(segment, vodSearchService.selectedSegmentNames)">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <!-- Title Column -->
                <ng-container matColumnDef="title">
                  <th
                      mat-header-cell
                      *matHeaderCellDef>
                    Title
                  </th>
                  <td
                      mat-cell
                      *matCellDef="let segment"
                      [title]="segment.asset.title"
                      (click)="toggleSelectionOnShift(segment, tplState.results.segments, $event)"
                      class="main-column">
                    <div class="icon-with-text">
                      <mam-asset-info-icon-button
                          [asset]="segment.asset"
                          icon="insert_drive_file"
                          [showIconForExternalUsers]="true">
                      </mam-asset-info-icon-button>
                      <span matTooltip="{{segment.asset.title}}"
                            matTooltipClass="mobile-tooltip"
                            matTooltipPosition="below">
                            {{segment.asset.title}}
                      </span>
                    </div>
                  </td>
                </ng-container>

                <!-- Duration Column -->
                <ng-container matColumnDef="duration">
                  <th
                      mat-header-cell
                      *matHeaderCellDef>
                    Duration
                  </th>
                  <td
                      mat-cell
                      *matCellDef="let segment">
                    {{segment.asset.duration | durationOrTime}}
                  </td>
                </ng-container>

                <!-- Content Type Column -->
                <ng-container matColumnDef="content-type">
                  <th
                      mat-header-cell
                      *matHeaderCellDef>
                    Content Type
                  </th>
                  <td
                      mat-cell
                      *matCellDef="let segment">
                    {{getFormattedContentType(segment.asset)}}
                  </td>
                </ng-container>

                <!-- Event Time Column -->
                <ng-container matColumnDef="event-time">
                  <th
                      mat-header-cell
                      *matHeaderCellDef>
                    Event Time
                  </th>
                  <!-- e.g. Monday, June 15, 2015 at 21:03:01 GMT+01:00  -->
                  <td mat-cell *matCellDef="let segment"
                      [title]="(segment.asset.eventTime | tzdate:'EEEE, MMMM d, y, HH:mm:ss zzzz') || 'No date available'">
                    <!-- e.g. Oct 25, 2021, 14:11-->
                    {{segment.asset.eventTime | tzdate:'MMM d, y, HH:mm'}}
                  </td>
                </ng-container>

                <!-- Last Modified Column -->
                <ng-container matColumnDef="last-modified">
                  <th
                      mat-header-cell
                      *matHeaderCellDef>
                    Last Modified
                  </th>
                  <!-- e.g. Monday, June 15, 2015 at 21:03:01 GMT+01:00  -->
                  <td mat-cell *matCellDef="let segment"
                      [title]="(segment.asset.updateTime | tzdate:'EEEE, MMMM d, y, HH:mm:ss zzzz') || 'No date available'">
                    <!-- e.g. Oct 25, 2021, 14:11-->
                    {{segment.asset.updateTime | tzdate:'MMM d, y, HH:mm'}}
                  </td>
                </ng-container>

                <!-- Location Column -->
                <ng-container matColumnDef="location">
                  <th
                    mat-header-cell
                    *matHeaderCellDef>
                      Storage
                  </th>
                  <td mat-cell *matCellDef="let segment">
                    <mam-asset-location-status
                        *ngIf="tplState.searchMode === SearchMode.VIDEO &&
                               !segment.asset.isDeleted"
                        [asset]="segment.asset">
                    </mam-asset-location-status>
                  </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let segment">
                    <mam-asset-extra-actions
                        *ngIf="!segment.asset.isDeleted"
                        [asset]="segment.asset"
                        [paddedSegment]="segment"
                        [enableFullMenu]="tplState.searchMode === SearchMode.VIDEO">
                    </mam-asset-extra-actions>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <tr mat-row
                    *matRowDef="let segment; let index = index; columns: displayedColumns;"
                    [class.deleted]="segment.asset.isDeleted"
                    [routerLink]="['/asset', segment.asset.name]"
                    [queryParams]="{'type': 'search', 'initialIndex': vodSearchService.getAbsoluteIndex(index)}"
                    queryParamsHandling="merge"
                    mam-firebase-ga="Open VoD asset from search result list"
                    [mam-firebase-resource]="segment.name"
                    [class.selected]="isSelected(segment)">
                </tr>
              </table>
            </ng-template>

            <!-- Second Paginator -->
            <div class="filler"></div>
            <div id="search-second-paginator">
              <ng-container *ngIf="(vodSearchService.pagination.totalCount/vodSearchService.pagination.pageSize > 1)">
                <ng-container *ngTemplateOutlet="paginator; context: {showPageSelector: false}">
                </ng-container>
              </ng-container>
            </div>
          </div>

          <ng-template #displayEmptyContents>
            <div class="empty-contents">
              <div class="image-container">
                <img alt="Empty Search Results"
                    src="images/empty_contents_logo.svg">
              </div>
              <ng-container
                  *ngIf="tplState.results.isInitialFacetsResponse; else noResults">
                <h1>Loading results...</h1>
                <h2>Please wait</h2>
              </ng-container>
              <ng-template #noResults>
                <h1>No results found</h1>
                <h2>Please try modify your search queries</h2>
              </ng-template>
              <button
                  mat-button
                  mam-firebase-ga="Clear all facets from no results"
                  *ngIf="showClearFacetsButton$ | async"
                  class="gmat-button"
                  (click)="clearAllFacets()"
                  mam-firebase-ga="Clear all search facets from search results">
                Clear all filters
              </button>
            </div>
          </ng-template>
      </div>

      <ng-template #paginator let-showPageSelector="showPageSelector">
        <mat-paginator
            class="main__mat-paginator--border"
            [ngClass]="['mat-paginator', (selectionInfo.indeterminate || selectionInfo.areAll) ? 'hide-paginator' : '']"
            [length]="vodSearchService.pagination.totalCount"
            [pageSize]="vodSearchService.pagination.pageSize"
            [pageIndex]="vodSearchService.pagination.pageIndex"
            (page)="onPageChange($event)"
            [pageSizeOptions]="PAGE_SIZE_OPTIONS"
            [hidePageSize]="!showPageSelector">
        </mat-paginator>
      </ng-template>
    </ng-container>
  </div>

