import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';

/** Event fired when volume is changed. */
export interface VolumeEvent {
  /** Volume between 0 and 1. */
  value: number;
  /** Whether the volume was updated through dragging the slider or not. */
  sliding?: boolean;
}

/**
 * Component for Mute/Unmute Button and Volume Slider.
 */
@Component({
  selector: 'mam-volume-button',
  templateUrl: './volume_button.ng.html',
  styleUrls: ['./volume_button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VolumeButton {
  @Input() volumeSliderHidden = true;

  @Input() volume = 1;

  /** Whether keyboard shortcuts are disabled. */
  @Input() disabledShortcuts = false;

  @Output() readonly volumeSliderHiddenChange = new EventEmitter<boolean>();

  @Output() readonly volumeChange = new EventEmitter<VolumeEvent>();

  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (this.disabledShortcuts) return;

    const target = event.target as HTMLElement | null;
    // Ignore keyboard shortcuts while user is typing on any input.
    if (target?.tagName === 'INPUT' || target?.tagName === 'TEXTAREA') {
      return;
    }

    switch (event.key) {
      case 'g':
        this.toggleMuteUnmute();
        break;

      case 'ArrowUp':
        this.changeVideoVolumeBy5(1);
        break;

      case 'ArrowDown':
        this.changeVideoVolumeBy5(-1);
        break;

      default:
        return;
    }

    this.analyticsService.logEvent('Keyboard shortcut for volume', {
      string1: event.key,
      string2: event.code,
      eventType: AnalyticsEventType.KEY_DOWN,
    });
  }

  constructor(private readonly analyticsService: FirebaseAnalyticsService) {}

  /**
   * Toggles whether to display the Volume Slider. Volume Slider is only visible
   * onHover over Mute/Unmute or if volume slider is touched.
   */
  toggleVolumeSlider(hideSlider: boolean) {
    if (!this.forceVolumeSliderDisplayed) {
      this.volumeSliderHidden = hideSlider;
      this.volumeSliderHiddenChange.emit(hideSlider);
    }
  }

  toggleMuteUnmute() {
    this.forceVolumeSliderDisplayed = false;
    const value = this.volume === 0 ? this.prevVolumeValue : 0;
    this.changeVideoVolume(value, false);
  }

  slidingVideoVolume(value: number) {
    this.forceVolumeSliderDisplayed = true;
    this.volumeChange.emit({value, sliding: true});
  }

  changeVideoVolume(value = 1, displayVolumeSlider = true) {
    this.forceVolumeSliderDisplayed = displayVolumeSlider;
    // if we slide the thumb to 0 we want to preserve prevVolumeValue
    if (this.volume !== 0) {
      this.prevVolumeValue = this.volume;
    }
    this.volumeChange.emit({value, sliding: false});
  }

  changeVideoVolumeBy5(direction: number) {
    const newVolume = direction > 0 ? this.volume + .05 : this.volume - .05;
    this.changeVideoVolume(
        Math.max(0, Math.min(1, this.roundDecimal(newVolume))), false);
  }

  // Rounds decimal to 2 decimal places
  roundDecimal(decimal: number) {
    const result = Math.round(decimal * 100) / 100;
    return result;
  }

  private forceVolumeSliderDisplayed = false;

  private prevVolumeValue = 1;
}
