import { FullscreenElementPriority } from './fullscreen_element_priority';

const PLAYER_PRIORITY = 0;
const DETAILS_PRIORITY = PLAYER_PRIORITY + 1;

const presets = {
  player: { key: 'player_with_controls', priority: PLAYER_PRIORITY } as FullscreenElementPriority,
  details: { key: 'details', priority: DETAILS_PRIORITY } as FullscreenElementPriority,
};

export default presets;
