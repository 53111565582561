<div class="icon-with-text">
  <span matTooltipClass="mobile-tooltip"
        matTooltip="{{email}}"
        matTooltipPosition="below"
        *ngIf="hasGoogleWorkspace$ | async as hasGoogleWorkspace; else noValue">
    {{ hasGoogleWorkspace }}
  </span>
  <ng-template #noValue>
    <span>
    Loading ...
    </span>
  </ng-template>
</div>
