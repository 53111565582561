import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DateTime} from 'luxon';
import {combineLatest, firstValueFrom, Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, map, startWith, switchMap} from 'rxjs/operators';

import {assertTruthy} from '../asserts/asserts';
import {mapOnError} from '../error_service/error_response';
import {LIVE_LANDING_DATE_PARAM} from '../live/live_navigation_service';
import {Asset, AssetState} from '../services/asset_service';
import {Bin, BinService} from '../services/bin.service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';
import {TimezoneService} from '../services/timezone_service';
import {LIVE_STAGING_DATE_PARAM} from '../transfer_monitor/live_staging_table';
import {CONTENT_STAGING_TABS} from '../transfer_monitor/transfer_landing';

import {Context, ContextType, DetailsNavigationService, NavigationParam, NavigationRoot} from './details_navigation_service';

interface Labels {
  root: string;
  asset: Asset|null;
  clipbin: Bin|null;
  cameraLabel: string;
  primaryCameraAsset?: Asset;
}

/**
 * Breadcrumb toolbar used in Details view that indicates the asset currently
 * displayed and provides a button to navigate to the corresponding view.
 */
@Component({
  selector: 'mam-details-breadcrumb',
  templateUrl: './details_breadcrumb.ng.html',
  styleUrls: ['./details_breadcrumb.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsBreadcrumb {
  /** All values that are displayed in the breadcrumb. */
  readonly labels$: Observable<Labels>;  
  // accordion functionality for smaller screens
  activeButton: number = 2;
  
  constructor(
      private readonly detailsNavigation: DetailsNavigationService,
      private readonly binService: BinService,
      private readonly snackbar: SnackBarService,
      private readonly stateService: StateService,
      private readonly timezone: TimezoneService,
      private readonly route: ActivatedRoute,
  ) {
    this.labels$ = this.getLabels();
  }

  getAssetUrl() {
    return this.detailsNavigation.getAssetUrl();
  }

  getClipbinUrl() {
    return this.detailsNavigation.getClipbinUrl();
  }

  originalOrClip(labels: Labels) {
    return labels.clipbin ? 'Clip' : 'Asset';
  }

  showValueCopiedSnackbar(item: string) {
    this.snackbar.message(`${item} copied to clipboard`);
  }

  async onRootLabelClick(): Promise<void> {
    const context = await firstValueFrom(this.context$);
    const {asset} = context;

    if (context.origin?.root === NavigationRoot.LIVE_LANDING) {
      const date = this.getLiveDate(context);
      await this.detailsNavigation.navigate(
          ['live'], {[LIVE_LANDING_DATE_PARAM]: date?.toISODate() ?? undefined});
      return;
    }

    if (context.origin?.root === NavigationRoot.LIVE_STAGING) {
      const date = this.getLiveDate(context);
      await this.detailsNavigation.navigate(
          ['staging', 'live'], {[LIVE_STAGING_DATE_PARAM]: date?.toISODate() ?? undefined});
      return;
    }

    if (context.origin?.root === NavigationRoot.VOD_STAGING) {
      const tabId: typeof CONTENT_STAGING_TABS[number] =
          (asset && !asset.original && asset.hasError) ? 'vod-error' : 'vod';
      await this.detailsNavigation.navigate(['staging', tabId]);
      return;
    }

    await this.detailsNavigation.navigate(['']);
  }

  onClipbinLabelClick(bin: Bin) {
    this.stateService.persistentBinName$.next(bin.name);
  }

  async onPrimaryCameraAssetClick(primaryAsset: Asset) {
    const previousContext = this.detailsNavigation.getPreviousContextType();
    const contextType = previousContext !== ContextType.NONE ?
        previousContext :
        ContextType.CAMERAS;

    // Jump to primary asset, enabling previous navigation context if available.
    // Otherwise switch to `cameras` context.
    await this.detailsNavigation.navigate(
        ['/asset', primaryAsset.name], {[NavigationParam.TYPE]: contextType});
  }

  /** Tracks the current asset and optional search query in effect. */
  private readonly context$: Observable<Context> =
      this.detailsNavigation.context$;

  /** Text of the left-most button such as "Home", "Live", and search terms. */
  private getRootLabel(): Observable<string> {
    return this.context$.pipe(
        filter((context): context is Context&{asset: Asset} => !!context.asset),
        map(context => {
          assertTruthy(
              context.origin,
              'DetailsBreadcrumb.getRootLabel: missing context origin');
          const navigationOrigin = context.origin;

          // Live.
          if (navigationOrigin.root === NavigationRoot.LIVE_LANDING) {
            const date = this.getLiveDate(context);

            const liveLabel = date ?
                `Live on ${date.toLocaleString(DateTime.DATE_MED, { locale: "en"})}` :
                'Live';
            if (!context.userQuery) return liveLabel;
            return `${liveLabel} search results: ${context.userQuery}`;
          }

          // VOD search.
          if (context.userQuery) {
            return `VOD search results: ${context.userQuery}`;
          }

          // VoD Staging
          if (navigationOrigin.root === NavigationRoot.VOD_STAGING) {
            if (!context.asset.original && context.asset.hasError) {
              return 'VOD Errors';
            }
            return 'VOD Staging';
          }

          // Live Staging
          if (navigationOrigin.root === NavigationRoot.LIVE_STAGING) {
            const date = this.getLiveDate(context);
            return date ?
                `Live Staging on ${date.toLocaleString(DateTime.DATE_MED, { locale: "en"})}` :
                'Live Staging';
          }

          // Landing.
          return 'Home';
        }));
  }

  private getLabels(): Observable<Labels> {
    const clipbin$: Observable<Bin|null> = this.getClipbin();
    const rootLabel$: Observable<string> = this.getRootLabel();

    return combineLatest([this.context$, rootLabel$, clipbin$])
        .pipe(map(([context, root, clipbin]) => {
          return {
            root,
            asset: context.asset,
            clipbin,
            cameraLabel: this.getCameraLabel(context.asset),
            primaryCameraAsset: context.primaryCameraAsset,
          };
        }));
  }

  /**
   * Clipbin of the current asset in context if any.
   */
  private getClipbin(): Observable<Bin|null> {
    return this.context$.pipe(
        map(context => {
          if (!context.asset?.original) return null;
          return context.asset.label;
        }),
        distinctUntilChanged(),
        switchMap(binName => {
          if (!binName) return of(null);
          // start with `null` to avoid displaying the previous clipbin title
          // while the current one is being fetched.
          return this.binService.getBin(binName).pipe(
              mapOnError(() => null), startWith(null));
        }),
    );
  }

  private getCameraLabel(asset: Asset|null) {
    if (!asset?.camera?.label) return '';

    return asset.camera.isBroadcast ? 'primary' : asset.camera.label;
  }

  private getLiveDate({asset, origin}: Context): DateTime|undefined {
    // For clips or airing live asset return no date.
    if (!asset || asset.original || asset.state === AssetState.AIRING) {
      return undefined;
    }

    let date: DateTime|undefined = undefined;
    if (origin && !origin.inferred) {
      // For Live Staging and Live Landing origins get the date from url.
      const urlParamName = origin.root === NavigationRoot.LIVE_STAGING ?
          LIVE_STAGING_DATE_PARAM :
          (
            origin.root === NavigationRoot.LIVE_LANDING ?
              LIVE_LANDING_DATE_PARAM :
              undefined
          );
      if (urlParamName) {
        const urlParams = this.route.snapshot.queryParamMap;
        const urlDate = urlParams.get(urlParamName);
        if (urlDate) {
          date = this.convertToDate(urlDate);
        }
      }
    }

    // Infer date from asset if url look up didn't produce results.
    if (!date) {
      if (!asset.eventStartTime) return undefined;
      date = this.timezone.convert(DateTime.fromMillis(asset.eventStartTime))
                 .startOf('day');
    }

    // Don't mention date if it is "today".
    if (!date || !date.isValid || date.equals(this.timezone.getTodayDate())) {
      return undefined;
    }

    return date;
  }

  private convertToDate(isoDateString: string) {
    return this.timezone.parseFromIso(isoDateString).startOf('day');
  }
}
