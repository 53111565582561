/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Information about a file\'s lifecycle status.
 */
export interface ApiLifecycleInfo { 
    /**
     * File resource lifecycle status.
     */
    lifecycleState?: ApiLifecycleInfo.LifecycleStateEnum;
    /**
     * Schedule time is overridden manually or by lifecycleHandler.
     */
    manualOverride?: boolean;
    /**
     * Time when lifecycle cleanup is scheduled.
     */
    scheduleTime?: string;
}
export namespace ApiLifecycleInfo {
    export type LifecycleStateEnum = 'LIFECYCLE_STATE_UNSPECIFIED' | 'INACTIVE' | 'ACTIVE' | 'PROCESSING' | 'SUCCEEDED' | 'FAILED';
    export const LifecycleStateEnum = {
        LIFECYCLE_STATE_UNSPECIFIED: 'LIFECYCLE_STATE_UNSPECIFIED' as LifecycleStateEnum,
        INACTIVE: 'INACTIVE' as LifecycleStateEnum,
        ACTIVE: 'ACTIVE' as LifecycleStateEnum,
        PROCESSING: 'PROCESSING' as LifecycleStateEnum,
        SUCCEEDED: 'SUCCEEDED' as LifecycleStateEnum,
        FAILED: 'FAILED' as LifecycleStateEnum
    };
}


