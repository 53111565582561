import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {BehaviorSubject, combineLatest, from, Observable, of, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, filter, finalize, map, mergeMap, shareReplay, startWith, switchMap, tap, toArray} from 'rxjs/operators';

import {checkExhaustive} from 'asserts/asserts';

import {ErrorResponse, isErrorResponse, tapOnError} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {VcmsQueryExpressions} from '../query_expressions/vcms_query_expressions';
import {MetadataField} from '../services/asset_api_service';
import {AssetService, AssetState, Original} from '../services/asset_service';
import {ApiAssetState, BucketTypeEnum} from '../services/ias_types';
import {MediaCacheService} from '../services/media_cache_service';
import {ProgressbarService} from '../services/progressbar_service';
import {FacetGroup, SearchFacetService, SelectedFacetGroup} from '../services/search_facet_service';
import {SearchMode, SearchQueryParams, SearchResponse, SearchSegment, SearchService, SearchType} from '../services/search_service';
import {SnackBarService} from '../services/snackbar_service';
import {DEFAULT_CONCURRENT_REQUEST_NUMBER, UtilsService} from '../services/utils_service';

import {DayType} from './live_navigation_service';

/**
 * Default page size for a `AIRED` section when it is expanded - day type is
 * `PAST`.
 */
export const EXPANDED_AIRED_PAGE_SIZE_DEFAULT = 25;

/**
 * Default page size for a `AIRED` section when it is collapsed - day type is
 * `TODAY`.
 */
export const COLLAPSED_AIRED_PAGE_SIZE_DEFAULT = 5;

/** Default page size for the `AIRING` section */
export const AIRING_PAGE_SIZE_DEFAULT = 15;

const CACHE_DURATION_MS = 30 * 1000;

/** Contains information about section page sizes. */
export interface PageSizes {
  /** Page size for `AIRED` section when day type is `TODAY`. */
  airedCollapsed: number;
  /** Page size for `AIRED` section when day type is `PAST`. */
  airedExpanded: number;
  /** Page size for `AIRING` section. */
  airing: number;
}

/** Data service for Live view. */
@Injectable()
export class LiveAssetService {
  /** Emits when a facet is selected. */
  readonly facetQuery$ = new BehaviorSubject('');

  readonly facetSelections$ = this.getFacetSelections();

  readonly siteFacetGroup$ = new BehaviorSubject(this.createEmptySiteFacetGroup());

  /** Triggers search requests. */
  private readonly assetDataRequest$ = new ReplaySubject<LiveRequestParams>(1);

  /** Emits asset data collected for the latest data request. */
  private readonly assetData$: Observable<LiveAssetResult> =
      this.getAssetData();

  /** Emits when the data satisfying last request is collected. */
  readonly resultInfo$: Observable<LiveAssetResultInfo> = this.getResultInfo();

  /**
   * Triggers page size changes for `AIRED` and `AIRING` sections.
   */
  readonly pageSizes$ = new BehaviorSubject<PageSizes>({
    airedCollapsed: COLLAPSED_AIRED_PAGE_SIZE_DEFAULT,
    airedExpanded: EXPANDED_AIRED_PAGE_SIZE_DEFAULT,
    airing: AIRING_PAGE_SIZE_DEFAULT,
  });

  /** Triggers page changes for Aired on X section. */
  readonly airedPageIndex$ = new BehaviorSubject(0);

  /** Emits pagination for Aired on X section. */
  readonly airedPagination$ = this.getPagination(SectionType.AIRED);

  /**
   * Emits unpaginated results for Aired on X section.
   * Start with empty array to unblock details navigation service on page
   * refresh.
   */
  readonly allAiredAssets$: Observable<Original[]> =
      this.assetData$.pipe(map(data => data.aired), startWith([]));

  /** Triggers page changes for Live Now section. */
  readonly airingPageIndex$ = new BehaviorSubject<number>(0);

  /** Emits pagination for Live Now. */
  readonly airingPagination$ = this.getPagination(SectionType.AIRING);

  /**
   * Emits unpaginated results for Live Now section.
   * Start with empty array to unblock details navigation service on page
   * refresh.
   */
  readonly allAiringAssets$: Observable<Original[]> =
      this.assetData$.pipe(map(data => data.airing), startWith([]));

  /** Emits results for Scheduled section. */
  readonly scheduledAssets$ = this.getScheduledAssets();

  constructor(
      private readonly utils: UtilsService,
      private readonly progressbar: ProgressbarService,
      private readonly searchService: SearchService,
      private readonly queryExpressions: VcmsQueryExpressions,
      private readonly featureService: FeatureFlagService,
      private readonly errorService: ErrorService,
      private readonly snackBar: SnackBarService,
      private readonly assetService: AssetService,
      private readonly mediaCache: MediaCacheService,
      private readonly searchFacetService: SearchFacetService,
  ) {
    // Reset pagination for all sections when selected date changed and the new
    // data has been fetched.
    this.assetData$
        .pipe(distinctUntilChanged(
            (a, b) => a.request.date.equals(b.request.date) &&
                a.request.query === b.request.query))
        .subscribe(() => {
          this.resetPagination();
        });

    this.listenSitesToInitializeSiteFacet();
  }

  updateAssets(params: LiveRequestParams) {
    this.assetDataRequest$.next(params);
  }

  /** Resets pagination for all paginated sections. */
  resetPagination() {
    this.airingPageIndex$.next(0);
    this.airedPageIndex$.next(0);
  }

  /**
   * Enables/disables auto-updates. If enabled the service will re-issue last
   * known request every {@link CACHE_DURATION_MS}.
   */
  toggleAutoUpdates(enable: boolean) {
    this.enableAutoUpdates = enable;
  }

  /** Asset data cached based on request params (date and query). */
  private readonly cache = new Map<string, LiveAssetResult>();

  /** When true will automatically re-issue last processed request.  */
  private enableAutoUpdates = false;

  private getAssetData(): Observable<LiveAssetResult> {
    return this.assetDataRequest$
        .pipe(switchMap(
            // Pipe through timer to enable auto-updates.
            request =>
                this.utils.timer(CACHE_DURATION_MS)
                    .pipe(
                        // We don't want the service to permanently
                        // re-issue requests if the current view is not live
                        // landing. The requests are re-issued because
                        // there is a permanent subscription to
                        // assetData$ in the constructor. Also subscription from
                        // dns would trigger auto-updates.
                        filter(i => i === 0 || this.enableAutoUpdates),
                        map(iteration => {
                          // Consider all triggers except for the first
                          // one to be auto-updates.
                          const autoUpdate = iteration > 0;
                          const autoRequest: LiveRequestParams = {
                            ...request,
                            silent: request.silent || autoUpdate,
                            force: request.force || autoUpdate
                          };
                          return autoRequest;
                        }))))
        .pipe(
            tap(request => {
              // Show loader unless the request is coming from auto-update.
              if (!request.silent) {
                this.progressbar.show();
              }
            }),
            switchMap(request => {
              if (request.force) {
                this.cache.clear();
              }

              const cacheKey = this.getCacheKey(request);
              const cached = this.cache.get(cacheKey);
              if (cached) {
                // Auto-updates can be debounced so we need to check the cache
                // age manually.
                if (this.getTimestamp() - cached.timestamp <
                        CACHE_DURATION_MS &&
                    // If we stay on, say June 15th, while the clock
                    // transitioned to 16th, we still show 15th as today. When
                    // user navigates to 16th and then back, we would now show
                    // 15th as yesterday, which means we should not reuse the
                    // same cache for 15th as now it is different day type.
                    cached.dateType === request.dateType) {
                  return of({...cached, request});
                }
                this.cache.delete(cacheKey);
              }

              return this.searchAssets(request).pipe(tap(data => {
                if (!data.error) this.cache.set(cacheKey, data);
              }));
            }),
            tap(() => {
              this.progressbar.hide();
            }),
            // When assets are changed, in particular when a live asset is
            // deleted, we patch all cached responses. We then re-emit the
            // current response with its updates so that Angular refreshes the
            // view.
            switchMap(response => {
              return this.assetService.assetsChanged$.pipe(
                  map(({updates}) => {
                    let hasUpdates = false;
                    // The current response is one of the cached values so it
                    // is not needed in the following array, but we ensure its
                    // presence in case caching is eventually removed.
                    const responses =
                        [...new Set([...this.cache.values(), response])];
                    for (const response of responses) {
                      const groups = [response.aired, response.airing];
                      for (const group of groups) {
                        for (const [i, asset] of group.entries()) {
                          const updated = updates?.get(asset.name);
                          if (!updated) continue;
                          group[i] = updated;
                          hasUpdates = true;
                        }
                      }
                    }
                    return {response, hasUpdates};
                  }),
                  filter(({hasUpdates}) => hasUpdates),
                  map(({response}) => response),
                  // Make sure we emit right away without waiting for any
                  // assetsChanged$ emission.
                  startWith(response),
              );
            }),
            shareReplay({bufferSize: 1, refCount: true}),
            finalize(() => {
              this.progressbar.hide();
            }),
        );
  }

  private getResultInfo() {
    return this.assetData$.pipe(map(data => {
      const result: LiveAssetResultInfo = {
        request: data.request,
        empty:
            !data.aired.length && !data.airing.length && !data.scheduled.length,
        error: data.error,
      };
      return result;
    }));
  }

  private getPagination(section: SectionType): Observable<Pagination> {
    if (section === SectionType.SCHEDULED) {
      throw new Error('Pagination for scheduled assets is not supported');
    }
    const isAired = section === SectionType.AIRED;
    const pageIndex$ = isAired ? this.airedPageIndex$ : this.airingPageIndex$;
    return combineLatest([pageIndex$, this.pageSizes$, this.assetData$, this.facetSelections$])
        .pipe(
            map(([pageIndex, pageSizes, data, facetSelections]) => {
              const assets = this.filterAssets(isAired ? data.aired : data.airing, facetSelections);
              const pageSize =
                  this.getPageSize(data.dateType, section, pageSizes);
              const pageAssets = assets.slice(
                  pageIndex * pageSize, (pageIndex + 1) * pageSize);
              const pagination: Pagination = {
                pageAssets,
                allAssets: assets,
                pageCount: Math.ceil(assets.length / pageSize),
                pageIndex,
                pageSize,
              };
              return pagination;
            }),
        );
  }

  private getPageSize(
      dateType: DayType, section: SectionType, pageSizes: PageSizes) {
    if (section === SectionType.AIRING) return pageSizes.airing;

    return dateType === DayType.TODAY ? pageSizes.airedCollapsed :
                                        pageSizes.airedExpanded;
  }

  private searchAssets(request: LiveRequestParams):
      Observable<LiveAssetResult> {
    const dateType = request.dateType;
    const airedStatuses: ApiAssetState[] =
        ['STATE_STREAMING_STOPPED', 'STATE_PROCESSING', 'STATE_READY'];
    const airingStatuses: ApiAssetState[] =
        ['STATE_STREAMING', 'STATE_STREAMING_ERROR'];
    const upcomingStatuses: ApiAssetState[] = ['STATE_SCHEDULED'];

    let query = '';

    switch (dateType) {
      case DayType.PAST:
      case DayType.FUTURE: {
        const states =
            dateType === DayType.PAST ? airedStatuses : upcomingStatuses;
        query = this.queryExpressions.and([
          // Query entered by the user.
          request.query ?? '',
          // Get aired or upcoming assets.
          this.queryExpressions.anyOf('AssetState', states),
          // Constrain by selected date.
          this.queryExpressions.withinDate('EventStartTime', request.date),
        ]);
        break;
      }
      case DayType.TODAY:
        query = this.queryExpressions.and([
          // Query entered by the user.
          request.query ?? '',
          // For today we display all sections: aired, airing and upcoming.
          this.queryExpressions.or([
            // Get aired and upcoming assets constrained by selected date.
            this.queryExpressions.and([
              this.queryExpressions.anyOf(
                  'AssetState', [...airedStatuses, ...upcomingStatuses]),
              this.queryExpressions.withinDate('EventStartTime', request.date),
            ]),
            // Get airing assets without date constraints.
            this.queryExpressions.anyOf('AssetState', airingStatuses),
          ]),
        ]);
        break;
      default:
        checkExhaustive(dateType);
    }

    if (this.featureService.featureOn('use-multi-camera-view')) {
      // Filter out any non-broadcast feeds.
      query = this.queryExpressions.and(
          [query, this.queryExpressions.is('IsBroadcast', true)]);
    }

    const searchRequest: SearchQueryParams = {
      query,
      facetSelections: [],
      searchMode: SearchMode.VIDEO,
      searchType: SearchType.LIVE,
    };

    return this.searchService.searchAll(searchRequest)
        .pipe(
            tapOnError(error => {
              let snackbarErrorMessage = 'Search request failed';
              if (!error.message) {
                snackbarErrorMessage += `, please try again`;
              }
              this.snackBar.error({
                message: snackbarErrorMessage,
                details: error.message,
                doNotLog: true,
              });
            }),
            // Swap archived live assets with the VoD archive.
            switchMap(response => {
              if (isErrorResponse(response)) return of(response);
              return this.swapArchives(response);
            }),
            map(response => this.convertToUiResult(request, response)),
        );
  }

  /**
   * For each live asset of the response that has an archive name, swap it with
   * the archive VoD.
   */
  private swapArchives(response: SearchResponse) {
    return from(response.videoSegments)
        .pipe(
            mergeMap(
                videoSegment => {
                  const asset = videoSegment.asset;

                  // Asset is not archived, nothing to do.
                  if (!asset.archiveName) return of(videoSegment);

                  return this.assetService.getAsset(asset.archiveName, true)
                      .pipe(map(archive => {
                        // If the archive cannot be fetched, continue
                        // showing the live asset but with an error
                        // state.
                        if (!archive) {
                          videoSegment.asset.hasError = true;
                          videoSegment.asset.errorReason =
                              'Failed to fetch VoD archive.';
                          return videoSegment;
                        }

                        // Swap the live asset with its VoD archive.
                        const segmentWithArchive: SearchSegment = {
                          ...videoSegment,
                          asset: archive,
                        };
                        return segmentWithArchive;
                      }));
                },
                DEFAULT_CONCURRENT_REQUEST_NUMBER),
            toArray(),
            map(videoSegments => ({...response, videoSegments})),
        );
  }

  private convertToUiResult(
      request: LiveRequestParams,
      response: SearchResponse|ErrorResponse): LiveAssetResult {
    if (isErrorResponse(response)) {
      return {
        request,
        dateType: request.dateType,
        timestamp: 0,
        aired: [],
        airing: [],
        scheduled: [],
        error: true,
      };
    }
    const assets = response.videoSegments.map(vs => vs.asset);
    const aired: Original[] = [];
    const airing: Original[] = [];
    const scheduled: Original[] = [];
    let missingAssetCount = 0;

    for (const asset of assets) {
      if (!asset.name) {
        missingAssetCount++;
        continue;
      }
      switch (asset.state) {
        case AssetState.VOD:
        case AssetState.ENDED:
        case AssetState.PROCESSING:
          aired.push(asset);
          break;
        case AssetState.PENDING:
        case AssetState.AIRING:
          airing.push(asset);
          break;
        case AssetState.SCHEDULED:
          scheduled.push(asset);
          break;
        default:
          checkExhaustive(asset.state);
      }
    }

    const result: LiveAssetResult = {
      request,
      dateType: request.dateType,
      timestamp: this.getTimestamp(),
      // aired assets may include VoD archives with no eventStartTime.
      aired: aired.sort((a, b) => {
        // VoD archives come before Live assets.
        if (!a.isLive && b.isLive) return -1;
        if (a.isLive && !b.isLive) return 1;
        // Live assets are ordered by their eventEndTime.
        if (a.isLive && b.isLive) return a.eventEndTime - b.eventStartTime;
        // VoD archives are ordered by their startTimecode.
        return (a.startTimecode || 0) - (b.startTimecode || 0);
      }),
      airing: airing.sort((a, b) => {
        // Prioritize AIRING over PENDING
        if (a.state !== b.state) {
          return a.state === AssetState.AIRING ? -1 : 1;
        }
        return a.eventStartTime - b.eventStartTime;
      }),
      scheduled: scheduled.sort((a, b) => a.eventStartTime - b.eventStartTime),
      error: false,
    };

    if (missingAssetCount > 0 && !request.silent) {
      this.errorService.handle(`${missingAssetCount}/${
          assets.length} empty assets from a live landing search`);
    }

    return result;
  }

  private getTimestamp() {
    return DateTime.utc().valueOf();
  }

  private getCacheKey(request: LiveSearchParams) {
    return `${request.date.toMillis()}|${request.query}`;
  }

  private filterAssets(assets: Original[], facetSelections: SelectedFacetGroup[]): Original[] {
    const sites = this.getSiteFacetValues(facetSelections);
    if (!sites.length) {
      return assets;
    }

    return assets.filter(a => sites.includes(a.assetMetadata.jsonMetadata[MetadataField.SITE]));
  }

  /** Listens sites for Site facet. */
  private listenSitesToInitializeSiteFacet() {
    combineLatest([this.mediaCache.state.selectableSites$, this.facetSelections$])
      .subscribe(([selectableSites, facetSelections]) => {
        if (selectableSites) {
          const selectedSites = this.getSiteFacetValues(facetSelections);
          const siteFacetGroup = this.siteFacetGroup$.value;
          siteFacetGroup.facetBuckets = selectableSites.map(s => ({count: 0, value: s.siteId.toUpperCase()}));
          siteFacetGroup.facetBuckets.forEach(fb => fb.isSelected = selectedSites.includes(fb.value));
          this.siteFacetGroup$.next({...siteFacetGroup});
        }
      });
  }

  private getScheduledAssets(): Observable<Original[]> {
    return combineLatest([this.assetData$, this.facetSelections$])
        .pipe(map(([data, facetSelections]) => this.filterAssets(data.scheduled, facetSelections)));
  }

  private getFacetSelections() {
    return this.facetQuery$.pipe(
        map(facetQuery => this.searchFacetService.buildFacetSelections(facetQuery)),
    );
  }

  private getSiteFacetValues(facetGroups: SelectedFacetGroup[]): string[] {
    const siteFacet = facetGroups.filter(fs => fs.facet === 'AssetsSite');
    if (!siteFacet.length) {
      return [];
    }
    return siteFacet[0].facetResults.buckets.filter(b => b.selected).map(b => b.bucketValue);
  }

  private createEmptySiteFacetGroup(): FacetGroup {
    return {
      displayedName: 'Site',
      type: BucketTypeEnum.VALUE,
      facet: 'AssetsSite',
      facetBuckets: [],
    };
  }
}

/** Contains search params decorated with UI specific logic  */
export interface LiveRequestParams extends LiveSearchParams {
  /** Keep pagination, don't show progress bar. */
  silent?: boolean;
  /** If true then the cache will be dropped. */
  force?: boolean;
}

/** Contains params to execute live asset search with. */
export interface LiveSearchParams {
  /** Date for which to get the asset data. */
  date: DateTime;
  /**
   * Indicates if this request is for past, current or future date.
   *
   * Keeping this inside of the request allows us to issue auto-updates of the
   * same type as original request even when the clock does midnight transition.
   */
  dateType: DayType;
  /** Search input query. */
  query?: string;
}

/** Information for all live assets for a certain date. */
export interface LiveAssetResult {
  /** Timestamp for when this object was created for caching purposes. */
  timestamp: number;
  /** Request that was used to fetch this data. */
  request: LiveSearchParams;
  /** Type of day this result corresponds to relative to today. */
  dateType: DayType;
  /** All scheduled assets for the certain date. */
  scheduled: Original[];
  /** All aired assets for the certain date. */
  aired: Original[];
  /** All airing assets for the certain date. */
  airing: Original[];
  /** Indicates if there was an error while fetching the results. */
  error: boolean;
}

/** Exposes general information about last processed request. */
export interface LiveAssetResultInfo {
  /** Request parameters. */
  request: LiveSearchParams;
  /** True if no assets were fetched. */
  empty: boolean;
  /** Indicates if there was an error while fetching the results. */
  error?: boolean;
}

/** State of the current pagination for certain section. */
export interface Pagination {
  /** Current page being displayed */
  pageIndex: number;
  /** How many items per page */
  pageSize: number;
  /** Total number of pages */
  pageCount: number;
  /** Assets results for the current page. */
  pageAssets: Original[];
  /** Asset results across all pages. */
  allAssets: Original[];
}

/** Live asset display section. */
export enum SectionType {
  AIRED = 'AIRED',
  AIRING = 'AIRING',
  SCHEDULED = 'SCHEDULED',
}
