import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {combineLatest, of, ReplaySubject} from 'rxjs';
import {switchMap, take, takeUntil} from 'rxjs/operators';

import {assertTruthy, checkExhaustive} from 'asserts/asserts';
import {FeatureFlagService} from 'feature_flag/feature_flag_service';

import {ErrorResponse, isErrorResponse} from '../error_service/error_response';
import {ClipOperationStatus, ExportType} from '../services/asset_service';
import {MediaCacheService} from '../services/media_cache_service';
import {PaginatorIntl} from '../services/paginator-intl';
import {SnackBarService} from '../services/snackbar_service';
import {TransferService} from '../services/transfer_service';
import {UtilsService} from '../services/utils_service';

import {ExportMonitorCompReelService} from './export_monitor_comp_reel_service';
import {ExportMonitorLiveService} from './export_monitor_live_service';
import {EXPORT_COLUMNS, ExportItemResponse, ExportMonitorService} from './export_monitor_service';
import {ExportMonitorVodService} from './export_monitor_vod_service';

/**
 * Shared Export Monitor component.
 */
@Component({
  selector: 'mam-export-monitor',
  templateUrl: './export_monitor.ng.html',
  styleUrls: ['./export_monitor.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: MatPaginatorIntl, useClass: PaginatorIntl}],
})
export class ExportMonitor implements OnInit, OnDestroy {
  @Input() exportType!: ExportType;

  @Output() readonly scrollTopNeeded = new EventEmitter();

  displayedColumns = EXPORT_COLUMNS;

  readonly selectableSites$ = this.mediaCache.state.selectableSites$;
  exportService!: ExportMonitorService;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly exportMonitorCompReelService: ExportMonitorCompReelService,
    private readonly exportMonitorLiveService: ExportMonitorLiveService,
    private readonly exportMonitorVodService: ExportMonitorVodService,
    private readonly mediaCache: MediaCacheService,
    private readonly snackbar: SnackBarService,
    readonly transferService: TransferService,
    private readonly featureService: FeatureFlagService,
    readonly utils: UtilsService
  ) {
    if (this.featureService.featureOff('show-user-information')) {
      this.displayedColumns = EXPORT_COLUMNS.filter(column => column !== 'clipBin' && column !== 'user');
    }
   }

  ngOnInit() {
    assertTruthy(
        this.exportType, `mam-export-monitor: 'exportType' is required.`);

    switch (this.exportType) {
      case 'VoD':
        this.exportService = this.exportMonitorVodService;
        break;
      case 'Live':
        this.exportService = this.exportMonitorLiveService;
        break;
      case 'CompReel':
        this.exportService = this.exportMonitorCompReelService;
        break;
      default:
        checkExhaustive(this.exportType);
    }

    this.exportService.items$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.scrollTopNeeded.emit();
    });

    this.exportService.loadItems().pipe(takeUntil(this.destroyed$)).subscribe();
    this.cdr.markForCheck();
  }

  isRowError(status: ClipOperationStatus) {
    return status === 'Failed';
  }

  trackByItem(index: number, item: ExportItemResponse) {
    return item.fileName;
  }

  isRowRetried(row: ExportItemResponse) {
    return this.retriedRows.has(row.fileName);
  }

  retryOnClick(row: ExportItemResponse) {
    this.retriedRows.add(row.fileName);

    combineLatest([
      this.transferService.siteAndExportFolders$,
      this.mediaCache.state.scratchFolder$
    ])
        .pipe(
            take(1),
            switchMap(([{site, folders}, scratchFolder]) => {
              const exportFolder =
                  folders.find(folder => folder.name === row.folderPath);

              if (!exportFolder) {
                return of(new ErrorResponse('Failed to find the folder'));
              }
              return this.exportService.retryExport(
                  site.siteId, exportFolder, row.name, scratchFolder?.name);
            }),
            )
        .subscribe(response => {
          if (isErrorResponse(response)) {
            this.snackbar.error({
              message: `Failed to export ${row.title} to ${row.folderPath}.`,
              details: response.message,
              doNotLog: true,
            });
            return;
          }
          this.snackbar.message(`Exporting ${row.title}.`);
        });
  }

  onSortByField(rows: ExportItemResponse[]) {
    this.exportService.items.next(rows);

  }

  ngOnDestroy() {
    // Clean up the service data for the next component.
    this.exportService.reset();

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /** List of rows that we clicked "Retry" on, which disable the button. */
  private readonly retriedRows = new Set<string>();

  private readonly destroyed$ = new ReplaySubject<void>(1);
}
