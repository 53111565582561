import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, merge, Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, finalize, map, shareReplay, startWith, switchMap, tap} from 'rxjs/operators';

import {isErrorResponse, mapOnError, tapOnError} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';

import {AssetService} from './asset_service';
import {Pagination, PaginationService} from './pagination_service';
import {ProgressbarService} from './progressbar_service';
import {SearchFacetService, SelectedFacetGroup} from './search_facet_service';
import {SearchInputService, SearchQuery, SearchType} from './search_input_service';
import {SearchMode, SearchResponse, SearchSegment, SearchService} from './search_service';
import {SnackBarService} from './snackbar_service';

/** Number of results (cards) per page */
export const GRID_PAGE_SIZE = 24;

/** Number of results (rows) in the table per page */
export const DEFAULT_LIST_PAGE_SIZE = 100;

/** Describes ways to display search results. */
export const enum DisplayMode {
  /** Grid of asset preview cards. */
  GRID = 'grid',
  /** Table view. */
  LIST = 'list'
}

/** Serves assets */
@Injectable({providedIn: 'root'})
export class VodSearchService {
  /** Emits when a facet is selected. */
  readonly facetQuery$ = new BehaviorSubject('');

  /** The date range query from the url */
  readonly dateRangeQuery$ = new BehaviorSubject<string>('');

  /** Emits when query, facets or search mode changes. */
  readonly searchChanged$: Observable<unknown>;

  readonly pageChange$ = new BehaviorSubject(0);

  readonly pageSize$ = new BehaviorSubject(GRID_PAGE_SIZE);

  readonly searchResponse$: Observable<SearchResponse|null>;

  readonly facetSelections$: Observable<SelectedFacetGroup[]>;

  /**
   * Results across all cached pages that have been received for the current
   * query so far. They are used for navigation in the details page.
   */
  readonly cachedResults$ = new BehaviorSubject<SearchSegment[]>([]);

  pagination: Pagination<SearchResponse>;

  /**
   * Indicates whether results should be rendered as a grid or as a table.
   *
   * Kept in VodSearchService so that the value persists when the component is
   * destroyed.
   */
  readonly displayMode$ = new BehaviorSubject<DisplayMode>(DisplayMode.GRID);
  /**
   * Search response of an empty query that is used to get the list of initial
   * facets displayed in the home page.
   */
  initialFacetsResponse$: Observable<SearchResponse|null>;

  /**
   * Collection of selected segment/asset names.
   *
   * Kept in VodSearchService so that the value persists when the component is
   * destroyed.
   */
  readonly selectedSegmentNames = new Set<string>();

  constructor(
      private readonly searchService: SearchService,
      private readonly searchFacetService: SearchFacetService,
      private readonly progressbar: ProgressbarService,
      private readonly errorService: ErrorService,
      private readonly snackbar: SnackBarService,
      private readonly paginationService: PaginationService,
      private readonly assetService: AssetService,
      searchInputService: SearchInputService,
  ) {
    this.pagination = this.paginationService.getEmptyPagination(GRID_PAGE_SIZE);

    searchInputService.searchQuery$.subscribe(({searchMode}) => {
      if (this.displayMode$.value === DisplayMode.GRID) return;
      if (searchMode !== SearchMode.SEGMENT) return;
      // Segment search is not supported in list view so UI will be switched to
      // grid view when the search is done. Reset page size to the one used for
      // grid.
      // New page size will be picked up while reacting to searchMode$ change.
      this.pageSize$.next(GRID_PAGE_SIZE);
    });

    this.searchQuery$ = searchInputService.searchQuery$.pipe(
        filter(({searchType}) => searchType === SearchType.VOD));

    this.searchChanged$ = this.getSearchChanged();
    this.facetSelections$ = this.getFacetSelections();
    this.searchResponse$ = this.getSearchResponse();

    // Reset pagination when query, facets, page size or search mode changes.
    merge(this.searchChanged$, this.pageSize$).subscribe(() => {
      this.resetPagination();
    });

    // Update current page index on pageChange events
    this.pageChange$.subscribe(pageIndex => {
      this.pagination.pageIndex = pageIndex;
      this.selectedSegmentNames.clear();
    });

    // Update pagination when a new request completes
    this.searchResponse$.subscribe((response) => {
      this.updatePagination(response);
      this.updateSelectedAssets(response);
    });

    this.initialFacetsResponse$ =
        this.searchService
            .search({
              query: '',
              pageToken: undefined,
              pageSize: 1,
              facetSelections: [],
              searchMode: SearchMode.VIDEO,
              searchType: SearchType.VOD,
            })
            .pipe(
                this.errorService.retryLong(),
                map(response => {
                  if (isErrorResponse(response)) {
                    this.snackbar.error('Failed to search initial facets.');
                    return null;
                  }

                  return {
                    ...response,
                    // We are only interested by initial facets, discard initial
                    // segments to not display them in the search result pages
                    // while an actual search is ongoing.
                    videoSegments: [],
                    isInitialFacetsResponse: true,
                  };
                }),
                shareReplay({bufferSize: 1, refCount: false}),
            );
  }

  /** Converts a raw segment to a padded segment */
  withPadding(segments: SearchSegment[] = []): PaddedSegment[] {
    const paddedSegments: PaddedSegment[] = [];
    for (const segment of segments) {
      const duration = segment.endTime - segment.startTime;

      const paddedStartTime =
          Math.max(segment.startTime - this.TIME_PADDING, 0);
      const paddedEndTime = Math.min(
          segment.endTime + this.TIME_PADDING,
          segment.asset.duration || Number.POSITIVE_INFINITY);
      const paddedDuration = paddedEndTime - paddedStartTime;

      // The paddedDuration is negative when the segment is out of the
      // boundaries of its video duration. (b/266570845)
      if (paddedDuration <= 0) {
        this.errorService.handle(
            `${segment.name} has negative paddedDuration.`);
      }

      const redAreaLeft =
          (segment.startTime - paddedStartTime) / paddedDuration;
      const redAreaWidth = duration / paddedDuration;

      // Note: The segment has negative paddedDuration will be displayed in
      // search-result.ng.html as a deleted asset.
      paddedSegments.push({
        ...segment,
        duration,
        paddedStartTime,
        paddedEndTime,
        paddedDuration,
        redAreaLeft,
        redAreaWidth,
      });
    }

    return paddedSegments;
  }

  /**
   * Converts an index relative to the current page of results, to an index
   * global to all results cached so far.
   */
  getAbsoluteIndex(relativeIndex: number) {
    return relativeIndex + this.pagination.pageSize * this.pagination.pageIndex;
  }

  private getSearchResponse(): Observable<SearchResponse|null> {
    return combineLatest([
             this.searchQuery$,
             this.pageChange$,
             this.facetSelections$,
           ])
        .pipe(
            switchMap(([{query, searchMode}, pageIndex, facetSelections]) => {
              facetSelections = this.filterOutLiveFacets(facetSelections);

              if (pageIndex < this.pagination.pagesCache.length) {
                // This page has been visited before
                return of(this.pagination.pagesCache[pageIndex]);
              }
              // When the query and facet is cleared (and user is back to the
              // landing page), the search response will return
              // initialFacetsResponse$ so that next time we start a search, it
              // starts with the initialFacetsResponse$ value and does not
              // display the previous results.
              if (!query && !facetSelections.length) {
                return this.initialFacetsResponse$;
              }
              this.progressbar.show();

              // Make actual search call
              return this.searchService
                  .search({
                    query,
                    pageToken: this.pagination.nextPageToken,
                    pageSize: this.pagination.pageSize,
                    facetSelections,
                    searchMode,
                    searchType: SearchType.VOD,
                  })
                  .pipe(
                      tapOnError(error => {
                        let snackbarErrorMessage = 'Search request failed';
                        if (!error.message) {
                          snackbarErrorMessage += `, please try again`;
                        }
                        this.snackbar.error({
                          message: snackbarErrorMessage,
                          details: error.message,
                          doNotLog: true,
                        });
                      }),
                      mapOnError(() => {
                        return {
                          searchMode,
                          videoSegments: [],
                        } as SearchResponse;
                      }));
            }),
            tap(() => {
              this.progressbar.hide();
            }),
            // Re-emit patched response after any changes are detected.
            switchMap(response => {
              if (!response) return of(null);

              return this.assetService.assetsChanged$.pipe(
                  map(({updates}) => {
                    let hasUpdates = false;
                    for (const segment of response.videoSegments) {
                      const updated = updates?.get(segment.asset.name);
                      if (!updated) continue;
                      segment.asset = updated;
                      hasUpdates = true;
                    }
                    return {response, hasUpdates};
                  }),
                  filter(({hasUpdates}) => hasUpdates),
                  map(({response}) => response),
                  // Make sure we emit right away without waiting for any
                  // assetsChanged$ emission.
                  startWith(response),
              );
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
            // Ensure that we hide loading if search is aborted (for instance
            // by returning to the home page before results are received).
            finalize(() => {
              this.progressbar.hide();
            }),
        );
  }

  private resetPagination() {
    this.cachedResults$.next([]);
    this.pagination =
        this.paginationService.getEmptyPagination(this.pageSize$.value);
  }

  /**
   * Updates status related to pagination calculation
   */
  private updatePagination(response: SearchResponse|null) {
    if (!response) return;

    const {videoSegments, nextPageToken} = response;

    this.pagination.nextPageToken = nextPageToken;

    const index = this.pagination.pageIndex;

    // There is empty search results or page is cached, no need to update
    // pagination status.
    if (!videoSegments.length || this.pagination.pagesCache[index]) return;

    if (this.pagination.pagesCache.length !== index) {
      this.errorService.handle(
          'A search ran with an obsolete nextPageToken, see b/155239365');
    }

    // Save new page results in cache.
    this.pagination.pagesCache[index] = response;

    // Combine all cached results into one list of assets for details
    // navigation.
    const cachedResults: SearchSegment[] =
        this.pagination.pagesCache.reduce<SearchSegment[]>((segments, result) => {
          segments.push(...result.videoSegments);
          return segments;
        }, []);
    this.cachedResults$.next(cachedResults);

    // We reached the last page, calculate the total number of results
    if (!nextPageToken) {
      const pagesCachedCount = this.pagination.pagesCache.length;
      const countUntilNow = (pagesCachedCount - 1) * this.pagination.pageSize;
      this.pagination.totalCount = countUntilNow + videoSegments.length;
    }
  }

  private updateSelectedAssets(response: SearchResponse|null) {
    // Unselect deleted assets.
    for (const segment of response?.videoSegments || []) {
      if (segment.asset.isDeleted) {
        this.selectedSegmentNames.delete(segment.name);
      }
    }
  }

  /**
   * Constructs a string to detect whether the search pattern changes. The
   * return value is not supposed to be read, so marks it as "unknown".
   */
  private getSearchChanged(): Observable<unknown> {
    return combineLatest([
             this.searchQuery$,
             this.facetQuery$,
             this.dateRangeQuery$,
           ])
        .pipe(
            map(([{query, searchMode}, facetQuery, dateRangeQuery]) => {
              return `${query} ${facetQuery} ${searchMode} ${dateRangeQuery}`
                  .trim();
            }),
            distinctUntilChanged(),
        );
  }

  private getFacetSelections() {
    // If date range changes, builds up the new facet selection array, and sends
    // to the backend since backend treats the date range picker as a facet.
    return combineLatest([
             this.dateRangeQuery$,
             this.facetQuery$,
           ])
        .pipe(
            map(([dateRangeQuery, facetQuery]) => {
              return this.searchFacetService.buildFacetSelections(
                  facetQuery, dateRangeQuery);
            }),
        );
  }

  /**
   * Filters out live facets.
   * Without this workaround home search produces error after live search.
   * For sure it will be needed to find better solution to handle this.
   */
  private filterOutLiveFacets(facetGroups: SelectedFacetGroup[]) {
    return facetGroups.filter(fg => fg.facet !== 'AssetsSite');
  }

  /**
   * Seconds added before and after a segment result
   */
  private readonly TIME_PADDING = 10;

  /** Emits when search input query or search mode are changed. */
  readonly searchQuery$: Observable<SearchQuery>;
}

/** Extends a seekable segment with display information */
export interface PaddedSegment extends SearchSegment {
  duration: number;
  /** Rendered result start (white area) */
  paddedStartTime: number;
  /** Rendered result end (white area) */
  paddedEndTime: number;
  /** Rendered result duration (white area) */
  paddedDuration: number;
  /** Position of the red area start (ratio from 0 to 1) */
  redAreaLeft: number;
  /** Length of the red area (ratio from 0 to 1) */
  redAreaWidth: number;
}
