<div class=live-toolbar-content>
  <button
      mat-flat-button
      class="today"
      [disabled]="(navigation.canGoToday$ | async) !== true"
      (click)="navigation.today(true)">
    Today
  </button>
  <button
      class="previous"
      matTooltip="Previous day"
      [matTooltipShowDelay]="500"
      mat-icon-button
      [disabled]="(navigation.canGoPrevious$ | async) !== true"
      (click)="navigation.previousDay()">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button
      class="next"
      matTooltip="Next day"
      [matTooltipShowDelay]="500"
      mat-icon-button
      [disabled]="(navigation.canGoNext$ | async) !== true"
      (click)="navigation.nextDay()">
    <mat-icon>chevron_right</mat-icon>
  </button>
  <span class="date-label">{{selectedDateLabel$ | async}}</span>
</div>
