import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, shareReplay, switchMap, take} from 'rxjs/operators';

import {Folder, Site} from 'models';

import {environment} from '../environments/environment';
import {ErrorResponse} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';

import {CloudIngestApiService, TransferOperationsListResponse} from './cloud_ingest_api_service';
import {ApiSiteTypeEnum, ApiStorageTransferInfoJobStateEnum, ApiCloudTransferJobScheduleType as ScheduleType} from './ias_types';
import {FoldersListFoldersResponse, MediaCacheApiService} from './media_cache_api_service';
import {MediaCacheService} from './media_cache_service';

/**
 * GcsData contains source bucket configurations needed by Storage Transfer
 * Service (STS).
 */
export interface GcsData {
  bucket: string;
  path: string;
}

/** Cloud Ingest Service */
@Injectable({providedIn: 'root'})
export class CloudIngestService {
  private readonly gcpSite$: Observable<Site|undefined>;

  constructor(
      private readonly mediaCacheApi: MediaCacheApiService,
      private readonly mediaCache: MediaCacheService,
      private readonly cloudIngestApi: CloudIngestApiService,
      private readonly errorService: ErrorService,
  ) {
    this.gcpSite$ = this.mediaCache.state.sites$.pipe(
        map(sites => (sites || [])
                         .find(
                             site => site.siteType ===
                                 ApiSiteTypeEnum.SITE_TYPE_CLOUD_GCP)),
        shareReplay({bufferSize: 1, refCount: false}),
    );
  }

  create(gcsData: GcsData, scheduleType: ScheduleType):
      Observable<Folder|ErrorResponse> {
    const param = {gcsData, scheduleType};

    return this.gcpSite$.pipe(
        take(1), switchMap(site => {
          if (!site) return throwError(() => `No GCP site found.`);
          return this.cloudIngestApi
              .initiateCloudTransfer(getSitePath(site.siteId), param)
              .pipe(this.errorService.catchError());
        }));
  }

  listFolders(): Observable<FoldersListFoldersResponse> {
    return this.gcpSite$.pipe(
        take(1), switchMap(site => {
          if (!site) return throwError(() => `No GCP site found.`);
          return this.mediaCacheApi.listFolders(site.siteId);
        }));
  }

  listTransferOperations(jobId: string, pageSize: number):
      Observable<TransferOperationsListResponse|null> {
    return this.cloudIngestApi.listTransferOperations(jobId, pageSize)
        .pipe(catchError(error => {
          this.errorService.handle(error);
          return of(null);
        }));
  }

  enableTransferJob(folderName: string): Observable<Folder|null> {
    return this.cloudIngestApi
        .updateTransferInfo(
            folderName, ApiStorageTransferInfoJobStateEnum.ENABLED)
        .pipe(this.errorService.retryLong(), catchError(error => {
                this.errorService.handle(error);
                return of(null);
              }));
  }

  disableTransferJob(folderName: string): Observable<Folder|null> {
    return this.cloudIngestApi
        .updateTransferInfo(
            folderName, ApiStorageTransferInfoJobStateEnum.DISABLED)
        .pipe(this.errorService.retryLong(), catchError(error => {
                this.errorService.handle(error);
                return of(null);
              }));
  }

  deleteTransferJob(folderName: string): Observable<Folder|null> {
    return this.cloudIngestApi
        .updateTransferInfo(
            folderName, ApiStorageTransferInfoJobStateEnum.DELETED)
        .pipe(this.errorService.retryLong(), catchError(error => {
                this.errorService.handle(error);
                return of(null);
              }));
  }
}


function getSitePath(siteId: string) {
  const parent = environment.mamApi.parent;
  return `${parent}/sites/${siteId}`;
}
