<!-- Single image spritesheet-->
<img
    alt="Thumbnail"
    *ngIf="singleImageBase64; else multiImagesSheet"
    [src]="singleImageBase64"/>

<!-- Multiple images spritesheet-->
<ng-template #multiImagesSheet>
  <div [ngStyle]="style"></div>
</ng-template>
