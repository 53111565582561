import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';

import {Metadata} from 'models';

import {castExists} from '../asserts/asserts';
import {isErrorResponse} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {NEW_ASSET_COPY_NAME} from '../services/asset_api_service';
import {AssetCopy, AssetService, Original} from '../services/asset_service';
import {ApiCopyStateDetailStateEnum} from '../services/ias_types';
import {MetadataSchema} from '../services/schema_api_service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';
import {BatchOperationService} from '../shared/batch_operation_service';

import {MetadataService, SupportItemTypes} from './metadata_service';
import {StagingService} from './staging_service';

/**
 * Right side panel that displays live asset cut-down details.
 */
@Component({
  selector: 'mam-cut-down-panel',
  templateUrl: './cut_down_panel.ng.html',
  styleUrls: ['./cut_down_panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CutDownPanel implements OnDestroy {
  /**
   * Cut-down to display.
   * Bulk cut-down edit is not supported initially.
   */
  cutdown?: AssetCopy;

  parent?: Original;

  /**
   * Fake asset created from current cutdown to provide to the player component.
   */
  fakePreviewAsset?: Original;

  readonly signer: (rawUrls: string[]) => Observable<Array<string|null>>;

  readonly schemas$: Observable<MetadataSchema[]>;

  readonly ApiCopyStateDetailStateEnum = ApiCopyStateDetailStateEnum;

  constructor(
      readonly metadataService: MetadataService,
      readonly stagingService: StagingService,
      readonly stateService: StateService,
      private readonly cdr: ChangeDetectorRef,
      private readonly assetService: AssetService,
      private readonly snackBar: SnackBarService,
      private readonly errorService: ErrorService,
      batchOperationService: BatchOperationService,

  ) {
    this.stagingService.activeItems$.pipe(takeUntil(this.destroyed$))
        .subscribe(active => {
          if (!active?.cutdownParent || !active.cutdowns.length) return;
          this.cdr.markForCheck();

          this.cutdown = active.cutdowns[0];
          this.parent = active.cutdownParent;
          this.fakePreviewAsset = {
            ...this.parent,
            name: this.cutdown.name,
            startTime: this.cutdown.startOffset,
            endTime: this.cutdown.endOffset,
            duration: this.cutdown.endOffset - this.cutdown.startOffset,
          };

          metadataService.setItems({
            items: [this.cutdown],
            parent: this.parent,
            type: SupportItemTypes.CUTDOWN
          });

          // Make sure new cutdown is always open in edit mode.
          if (active.startEditing) {
            this.metadataService.edit();
          }
        });

    // Provide schemas for schema selector component.
    this.schemas$ = this.assetService.listFilteredSchemas('asset_or_undefined')
                        .pipe(
                            tap(schemas => {
                              if (!schemas) {
                                this.snackBar.error('Failed to load schemas');
                              }
                            }),
                            map(schemas => {
                              if (!schemas) return [];
                              return schemas;
                            }));

    this.signer = (rawUrls: string[]) =>
        batchOperationService.batchSignUrls(rawUrls);
  }

  cancel() {
    const parent = castExists(
        this.parent, 'CutDownPanel->cancel: Parent asset is expected');
    const cutdown =
        castExists(this.cutdown, 'CutDownPanel->cancel: cutdown is expected');
    this.metadataService.cancel();

    if (this.isNew(cutdown)) {
      this.stagingService.cutdownChanged$.next(
          {name: cutdown.name, newValue: null, parent});
    }
  }

  save() {
    const parent =
        castExists(this.parent, 'CutDownPanel->save: Parent asset is expected');
    const cutdown =
        castExists(this.cutdown, 'CutDownPanel->save: cutdown is expected');

    // Retrieve updated metadata.
    const metadata = this.metadataService.getMergedMetadata(
        {item: cutdown, parent, type: SupportItemTypes.CUTDOWN});

    if (!metadata) {
      this.snackBar.error('Unable to save cutdown');
      return;
    }

    const isNew = this.isNew(cutdown);

    // Create a new cutdown object so that the existing cutdown is not modified
    // until the save is successful. Without this "cancel" functionality would
    // be broken in MetadataService.
    const cloned: AssetCopy = {
      ...cutdown,
      // When this is a new (not-persisted) cut-down - switch to parent's schema
      // and merge cutdown metadata with parent metadata. Otherwise stick to
      // cutdown metadata to avoid conflicts in case parent asset schema is
      // changed.
      metadata: new Metadata({
        schema: isNew ? parent.assetMetadata.schema :
                        this.metadataService.selectedSchema.value?.name,
        jsonMetadata: isNew ?
            {...parent.assetMetadata.jsonMetadata, ...metadata} :
            metadata,
      }),
    };

    this.stateService.isPanelLoading$.next(true);

    const persist$ = isNew ?
        this.assetService.createCopy(parent, cloned) :
        this.assetService.updateCopyMetadata(cloned, cloned.metadata);

    persist$.subscribe(persistedCutdown => {
      this.cdr.markForCheck();
      this.stateService.isPanelLoading$.next(false);
      if (isErrorResponse(persistedCutdown)) {
        this.snackBar.error({
          message: 'Failed to save cutdown',
          details: persistedCutdown.message
        });
        return;
      }

      this.snackBar.message('Cutdown saved successfully');

      // Set updated metadata to original cutdown when the save is
      // successful. This allows to reduce flash of old content in
      // metadata fields.
      cutdown.metadata = persistedCutdown.metadata;
      // Switch editing mode off.
      this.metadataService.cancel();

      this.stagingService.cutdownChanged$.next(
          {name: cutdown.name, newValue: persistedCutdown, parent});
    });
  }

  onPlayerError(message: string) {
    this.errorService.handle(`Cutdown player error: ${message}. Cutdown id: ${
        this.fakePreviewAsset?.name}`);
  }

  isNew(cutdown: AssetCopy) {
    return cutdown.name === NEW_ASSET_COPY_NAME;
  }

  ngOnDestroy() {
    this.metadataService.resetState();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);
}
