import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { AuthService } from 'auth/auth_service';

import { PlaybackAuthorizationService } from './playback-authorization.service';

interface SignedPathResponse {
    signedUrl: string
}

/** Route in which no auth token will be added to http requests. */
const PUBLIC_PATH = '/shared';

@Injectable({ providedIn: 'root' })
export class PathPlaybackAuthorizationService extends PlaybackAuthorizationService {
    public constructor(
        private readonly httpClient: HttpClient,
        private readonly authService: AuthService
    ) {
        super();
    }

    protected override prepareForAuthorizationBatch(): Promise<void> {
        // Nothing to do.
        return Promise.resolve();
    }
    
    protected override async getAuthorizedUriCore(originalUri: string): Promise<string> {
        
        let headers = {};
        // Add the authentication bearer token for non-public URL
        if (!location.href.startsWith(location.origin + PUBLIC_PATH)) {
            const accessToken = await this.authService.getActiveAccessToken();
            headers = {
                'Authorization': 'Bearer ' + accessToken
            };
        }
  
        const responseData = await firstValueFrom(
            // TODO-HLS: Create typed API client for BFF
            this.httpClient.get<SignedPathResponse>(
                '/signedPath',
                {
                    headers,
                    params: {
                        target: originalUri
                    }
                }
            )
        );

        return responseData.signedUrl;
    }

    protected override hasAuthorization(uri: string): boolean {
        return new URL(uri).pathname.includes('&Signature=');
    }
}