import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import {assertTruthy} from 'asserts/asserts';

import {Original} from '../services/asset_service';

/**
 * Component for a recent asset.
 */
@Component({
  selector: 'mam-recent-asset',
  templateUrl: './recent-asset.ng.html',
  styleUrls: ['./recent-asset.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentAsset implements OnInit {
  @Input() asset!: Original;
  @Input() isSmallScreen= false;
  @Input() isClipbinsAvailable = false;

  isRecentAsset: boolean = false;

  ngOnInit() {
    assertTruthy(this.asset, '<mam-recent-asset> requires property "asset"');
    
    if(this.asset){
      this.isRecentAsset = true;
    }
  }
}
