<ng-container *ngIf="{
  isEditing: metadataService.isEditing$ | async
} as tplState">
  <ng-container
      *ngIf="assets.length > 0 && tplState.isEditing != null">
    <!-- Metadata panel -->
    <mam-asset-metadata-panel
        [assets]="assets"
        [playerVisible]="true"
        [schemaSelection]="true"
        [showSyncButton]="true"
        [extraActions]="extraActions"
        (extraActionTriggered)="onExtraAction(assets, $event)">
    </mam-asset-metadata-panel>

    <!-- Approve button -->
    <div
        *ngIf="assets.length === 1 && assets[0] as activeAsset"
        class="approve-container"
        [matTooltip]="getApprovalDisabledTooltip(activeAsset)"
        [matTooltipDisabled]="!getApprovalDisabledTooltip(activeAsset) || tplState.isEditing">
      <button
          *ngIf="!activeAsset.approved"
          [disabled]="tplState.isEditing || !this.stagingService.canApprove(activeAsset)"
          class="approve-button"
          mat-button
          mam-firebase-ga="Approve asset from metadata panel"
          (click)="approve(activeAsset)">
        <mat-icon>task_alt</mat-icon>
        Approve
      </button>
    </div>
  </ng-container>
</ng-container>
