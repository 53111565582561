import {Pipe, PipeTransform} from '@angular/core';

/**
 * Splits camelCase words into space-separated words.
 * Usage:
 *   value | spaced
 * Example:
 *   {{ 'helloWorld' | spaced }}
 *   formats to: 'hello World'
 */
@Pipe({name: 'spaced'})
export class SpacedPipe implements PipeTransform {
  transform(value: string): string {
    let out = '';
    for (const char of value) {
      if (char !== char.toLowerCase()) {
        out += ' ';
      }
      if (char !== '_') {
        out += char;
      }
    }
    return out.trim();
  }
}
