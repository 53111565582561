import {Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';

import {castExists} from 'asserts/asserts';

import {AnalyticsEventType, FirebaseAnalyticsService, MamEventParams, PAGE_CONTEXT_TOKEN} from './firebase_analytics_service';

/* eslint-disable @angular-eslint/no-input-rename */

/**
 * Minimum time required between a mouse enter and mouse leave to consider a
 * mouse_enter_leave intended and log it to firebase.
 */
const ENTER_LEAVE_THRESHOLD_MS = 1000;

/**
 * A directive for tracking user interaction with DOM elements via firebase
 * analytics.
 *
 * @example
 * ```html
 * <button mam-firebase-ga="Button was clicked">
 * </button>
 * ```
 * @example
 * ```html
 * <div
 *     mam-firebase-ga="Element was clicked"
 *     [mam-firebase-type]="AnalyticsEventType.CLICK">
 * </div>
 * ```
 * @example
 * ```html
 * <video>
 *     mam-firebase-ga="Mouse moved over the video for at least 2 seconds"
 *     [mam-firebase-type]="AnalyticsEventType.MOUSE_ENTER_LEAVE"
 *     [mam-firebase-resource]="videoName"
 *     [mam-firebase-threshold]="2000">
 * </video>
 * ```
 */
@Directive({
  selector: '[mam-firebase-ga]',
})
export class FirebaseAnalyticsDirective implements OnInit, OnDestroy {
  /** Name of the analytics event. e.g. "Create new bin". */
  @Input('mam-firebase-ga') firebaseEventName = '';

  /** Event type that triggers logging. Defaults to click. */
  @Input('mam-firebase-type') eventType: AnalyticsEventType = AnalyticsEventType.CLICK;

  /** Sets threshold in ms for high frequency event. Defaults to 1000ms. */
  @Input('mam-firebase-threshold') thresholdMs = 1000;

  /** Resource name such as asset or clip that is contextual to this event. */
  @Input('mam-firebase-resource') resource?: string;

  /** Optional index when logging a specific item from a list. */
  @Input('mam-firebase-index') index?: number;

  constructor(
      private readonly analyticsService: FirebaseAnalyticsService,
      private readonly renderer: Renderer2,
      private readonly elementRef: ElementRef,
  ) {}

  ngOnInit() {
    this.addEventListener(this.eventType);

    // Store active page name. We do this during initialization since by the
    // time we log the event, which may be during an ngOnDestroy event, the
    // current page may have already changed.
    this.activePageName = this.analyticsService.getActivePageName();
  }

  ngOnDestroy() {
    /**
     * Component event bindings are prioritized over directive's.
     * As a result when tracking actions like 'dialog close' in search help
     * or 'navigate back' in details, this directive will be destroyed before it
     * gets a chance to log the action. As a workaround we queue binding
     * removal after current event entry is processed.
     */
    window.setTimeout(() => {
      this.removeEventListener();
    }, 0);
  }

  /** Main event listener */
  private unlisten?: () => void;

  /**
   * In case of MOUSE_ENTER_LEAVE event, a secondary listener is used to
   * track `mouseleave` event in addition to `mouseenter`.
   */
  private unlistenMouseLeave?: () => void;

  private logEvent(eventType: AnalyticsEventType, params: MamEventParams) {
    params.eventType = eventType;
    if (this.resource) {
      params.resource = this.resource;
    }

    if (this.index) {
      params.index = this.index;
    }

    const formattedName =
        this.firebaseEventName.replace(PAGE_CONTEXT_TOKEN, this.activePageName);
    this.analyticsService.logEvent(formattedName, params);
  }

  private addEventListener(event: AnalyticsEventType|string) {
    if (!this.isSupportedEvent(event)) {
      throw new Error(`Unsupported analytics event: ${event}`);
    }

    // Special handling of `MOUSE_ENTER_LEAVE`.
    if (event === AnalyticsEventType.MOUSE_ENTER_LEAVE) {
      // Register `mouseenter` event start.
      this.unlisten = this.renderer.listen(
          this.elementRef.nativeElement, 'mouseenter', () => {
            // Record when the `mouseenter` occurred to calculate the full
            // seek duration once we log the event.
            this.enterTime = this.analyticsService.getTimestamp();
            // Register `mouseleave` event end.
            this.unlistenMouseLeave = this.renderer.listen(
                this.elementRef.nativeElement, 'mouseleave', () => {
                  castExists(this.unlistenMouseLeave)();
                  this.logMouseEnterLeaveEvent();
                });
          });
      return;
    }

    // Regular html event, directly listen to it.
    this.unlisten =
        this.renderer.listen(this.elementRef.nativeElement, event, () => {
          this.logEvent(event, {eventType: event});
        });
  }

  /** Indicates the last time a `mouseenter` event occurred, otherwise `-1`. */
  private enterTime = -1;

  private activePageName!: string;

  /**
   * Logs a mouse enter/leave event if the seek duration was above a threshold,
   * to avoid logging events when the seeking was not purposeful.
   */
  private logMouseEnterLeaveEvent() {
    if (this.enterTime === -1) return;

    const duration =
        Math.round(this.analyticsService.getTimestamp() - this.enterTime);
    if (duration >= ENTER_LEAVE_THRESHOLD_MS) {
      this.logEvent(AnalyticsEventType.MOUSE_ENTER_LEAVE, {
        eventType: AnalyticsEventType.MOUSE_ENTER_LEAVE,
        duration,
      });
    }

    // A new `mouseenter` will be necessary before we can log this event again.
    this.enterTime = -1;
  }

  private isSupportedEvent(event: string): event is AnalyticsEventType {
    return Object.values<string>(AnalyticsEventType).includes(event);
  }

  private removeEventListener() {
    if (this.unlisten) {
      this.unlisten();
    }
    if (this.unlistenMouseLeave) {
      this.logMouseEnterLeaveEvent();
      this.unlistenMouseLeave();
    }
  }
}
