<h1 mat-dialog-title>
  <div class="asset-title">{{dialogTitle}}</div>
</h1>
<div mat-dialog-content>
  <ng-container *ngIf="foldersForExport$ | async as folders">
    <table mat-table [dataSource]="folders">

      <!-- Radio Button Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-radio-button color="primary"
              (click)="selectedFolder = row"
              [checked]="selectedFolder && selectedFolder.folderId === row.folderId">
          </mat-radio-button>
        </td>
      </ng-container>

      <!-- Folder Name Column -->
      <ng-container matColumnDef="folderName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row" [title]="utils.lastPart(row.name)">
          <mat-icon inline="true">folder</mat-icon>
          <span matTooltip="{{utils.lastPart(row.name)}}" matTooltipPosition="below" matTooltipClass="mobile-tooltip">{{utils.lastPart(row.name)}}</span>
        </td>
      </ng-container>

      <!-- Export Status Column -->
      <ng-container matColumnDef="exportStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Export Status</th>
        <td mat-cell *matCellDef="let row" class="export-column-status">
          <span *ngIf="stateMap$ | async as stateMap">
            <span *ngIf="stateMap[row.name]?.status" [ngSwitch]="stateMap[row.name].status">
              <!-- Asset Export -->
              <ng-container *ngSwitchCase="'Cloud storage'">
                <mat-icon class="status-icon icon-cloud-storage" matTooltip="Cloud storage" matTooltipPosition="below" matTooltipClass="mobile-tooltip">cloud</mat-icon>
                <span class="status-text">Cloud storage</span>
              </ng-container>
              <ng-container *ngSwitchCase="'Cloud storage and on-prem'">
                <img class="status-icon img-cloud-and-on-prem" matTooltip="Cloud storage and on-prem" matTooltipPosition="below" alt="Cloud storage and on-prem" src="images/asset_incloud_and_onprem.svg">
                <span class="status-text">Cloud storage and on-prem</span>
              </ng-container>
              <ng-container *ngSwitchCase="'Downloading to premises'">
                <mat-icon class="status-icon icon-downloading-to-premises" matTooltip="Downloading to premises" matTooltipPosition="below" matTooltipClass="mobile-tooltip">downloading</mat-icon>
                <span class="status-text">Downloading to premises</span>
              </ng-container>
              <!-- <ng-container *ngSwitchCase="'Download to the premises failed'">
                <mat-icon class="status-icon icon-failed" matTooltip="Download to the premises failed" matTooltipPosition="below" matTooltipClass="mobile-tooltip">error</mat-icon>
                <span class="status-text">Download to the premises failed</span>
              </ng-container> -->

              <!-- Clip bin Export -->
              <ng-container *ngSwitchCase="'Not started'">
                  <mat-icon class="status-icon icon-not-started" matTooltip="Not started" matTooltipPosition="below" matTooltipClass="mobile-tooltip">do_not_disturb_on</mat-icon>
                  <span class="status-text" >Not started</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Failed'">
                  <mat-icon class="status-icon icon-failed" matTooltip="Failed" matTooltipPosition="below" matTooltipClass="mobile-tooltip">error</mat-icon>
                  <span class="status-text">Failed</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Pending'">
                  <mat-icon class="status-icon icon-pending" matTooltip="Pending" matTooltipPosition="below" matTooltipClass="mobile-tooltip">pending</mat-icon>
                  <span class="status-text">Pending</span>
                </ng-container>
                <ng-container *ngSwitchCase="'Completed'">
                  <mat-icon class="status-icon icon-completed" matTooltip="Completed" matTooltipPosition="below" matTooltipClass="mobile-tooltip">check_circle</mat-icon>
                  <span class="status-text">Completed</span>
                </ng-container>
                <ng-container *ngSwitch="'Downloading'">
                  <mat-icon class="status-icon icon-downloading" matTooltip="Downloading" matTooltipPosition="below" matTooltipClass="mobile-tooltip">downloading</mat-icon>
                  <span class="status-text">Downloading</span>
                </ng-container>
                <!-- <ng-container *ngSwitchDefault>
                  <span>{{stateMap[row.name].status}}</span>
                </ng-container> -->
              </span>
          </span>
        </td>
      </ng-container>

      <!-- Last Exported Column -->
      <ng-container matColumnDef="lastExported">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Exported</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="stateMap$ | async as stateMap">
            <span *ngIf="stateMap[row.name]" matTooltip="{{stateMap[row.name].lastExportTime}}" 
            matTooltipPosition="below" matTooltipClass="mobile-tooltip">{{stateMap[row.name].lastExportTime}}</span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="folders.length === 0" class="empty-message">
      No export folder available.
    </div>
  </ng-container>

</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel exporting asset"
      [mam-firebase-resource]="getSingleAsset()?.name">
    Cancel
  </button>
  <button
      mat-button
      #primary
      class="primary"
      aria-label="Export to selected folder"
      mam-firebase-ga="Export asset"
      [mam-firebase-resource]="getSingleAsset()?.name"
      [disabled]="!selectedFolder"
      (click)="exportAssets()">
    Export
  </button>
</div>
