import {Injectable} from '@angular/core';
import {DateTime, Duration} from 'luxon';
import {BehaviorSubject, EMPTY, merge, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {catchError, delay, distinctUntilChanged, expand, filter, map, shareReplay, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';

import {ApiAnnotatedSegment} from 'api/ias/model/models';
import {checkExhaustive} from 'asserts/asserts';
import {AnnotatedSegment, Metadata} from 'models';


import {environment} from '../environments/environment';
import {isErrorResponse} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';

import {AnnotatedSegmentsApiService, ListFilter} from './annotated_segments_api_service';
import {ResourceChange} from './api_client.module';
import {Asset, AssetService, AssetState} from './asset_service';
import {LiveAssetManifestInfo, ManifestService} from './manifest.service';
import {MetadataSchema} from './schema_api_service';
import {TimezoneService} from './timezone_service';
import {UtilsService} from './utils_service';

/** Page size for each loadAllSegment recursive call */
const PAGE_SIZE = 100;

/**
 * Default metadata field to map to segment description if the schema is not
 * provided or has no displayOrder.
 */
const DEFAULT_DESCRIPTION_FIELD = 'GameLogs';

/** Default schema used by customer for annotated segment metadata */
const DEFAULT_SEGMENT_SCHEMA_NAME =
    `${environment.mamApi.parent}/metadataSchemas/meta-schema-logger`;

/** Asset and its manifest info if it is live. */
interface AssetInfo {
  asset: Asset;
  info?: LiveAssetManifestInfo;
}

/** Serves annotated segments */
@Injectable({providedIn: 'root'})
export class PlayFeedService {
  /** Segments visible in the panel. */
  readonly displaySegments$ = new BehaviorSubject<DisplaySegment[]>([]);

  readonly currentAsset$ = new BehaviorSubject<Asset|undefined>(undefined);

  private readonly segmentChangedInternal$ =
      new Subject<ResourceChange<DisplaySegment>>();

  /** Emits whenever a segment is created, updated or deleted via the api. */
  readonly segmentChanged$ = this.segmentChangedInternal$.pipe(delay(0));

  constructor(
      private readonly annotatedSegmentApiService: AnnotatedSegmentsApiService,
      private readonly manifestService: ManifestService,
      private readonly errorService: ErrorService,
      private readonly utils: UtilsService,
      private readonly timezone: TimezoneService,
      private readonly featureFlag: FeatureFlagService,
      assetService: AssetService,
  ) {
    // Clear all previous segments when the current asset change.
    merge(this.refreshSegments$, this.distinctAsset$).subscribe(() => {
      this.displaySegmentsSet.clear();
      this.displaySegments$.next([]);
    });

    // Load segments for the current asset.
    merge(
        this.refreshSegments$.pipe(switchMap(() => this.assetInfoChanged$)),
        this.assetInfoChanged$)
        .pipe(
            switchMap(assetInfo => {
              // Start by loading all segments created for this asset so far,
              // page by page.
              const initialSegments$ = this.expandAllSegments(assetInfo);

              // For airing original live streams, also periodically fetch and
              // add any new segments.
              const asset = assetInfo.asset;
              const newSegments$ = !asset.original &&
                      asset.state === AssetState.AIRING &&
                      this.featureFlag.featureOn(
                          'use-airing-annotated-segments-loading') ?
                  this.periodicallyLoadLatestSegments(assetInfo) :
                  EMPTY;

              return merge(initialSegments$, newSegments$);
            }),
            switchMap(apiSegments => {
              return assetService.listFilteredSchemas('annotated_segment')
                  .pipe(map(schemas => ({apiSegments, schemas})));
            }),
            withLatestFrom(this.displaySegments$),
            withLatestFrom(this.assetInfoChanged$),
            )
        .subscribe(([[{apiSegments, schemas}, displaySegments], assetInfo]) => {
          // Filter out api segments that are already displayed.
          const newApiSegments: AnnotatedSegment[] = [];
          for (const apiSegment of apiSegments || []) {
            const isNew = !this.displaySegmentsSet.has(apiSegment.name);
            if (isNew) {
              this.displaySegmentsSet.add(apiSegment.name);
              newApiSegments.push(apiSegment);
            }
          }

          // Convert api segments to display segments
          const newSegments = newApiSegments.map(segment => {
            const schema = schemas?.find(
                schema => schema.name === segment.segmentMetadata.schema);
            return this.convertToDisplaySegment(segment, assetInfo, schema);
          });

          // Emit the list of all display segments loaded so far, ordered by
          // start time.
          const allSegments =
            this.sortSegmentsByOffset([...displaySegments, ...newSegments]);
          this.displaySegments$.next(allSegments);
        });

    // Reflect segment changes done by the user.
    this.segmentChanged$.subscribe(change => {
      const existing = this.displaySegments$.value;
      let updated: DisplaySegment[] = [];

      switch (change.type) {
        case 'DELETE':
          updated = existing.filter(seg => seg.name !== change.name);
          break;
        case 'CREATE':
          updated = [...existing, change.item];
          break;
        case 'UPDATE':
          updated = existing.map(segment => {
            if (segment.name === change.item.name) return change.item;
            return segment;
          });
          break;
        default:
          checkExhaustive(change);
      }

      this.displaySegments$.next(this.sortSegmentsByOffset(updated));
    });
  }

  /**
   * Loads all segments available and emit the list of all segments loaded so
   * far each time a new page is fetched.
   */
  private expandAllSegments(assetInfo: AssetInfo):
      Observable<AnnotatedSegment[]|null> {
    const asset = assetInfo.asset;
    const originalName = asset.original?.name || asset.name;

    const clipFilters = this.buildClipFilters(assetInfo);

    return this.annotatedSegmentApiService
        .list(originalName, PAGE_SIZE, '', clipFilters)
        .pipe(
            // Keep calling `list` while there is a next page token.
            expand(response => {
              if (!response.nextPageToken) return EMPTY;

              return this.annotatedSegmentApiService.list(
                  originalName,
                  PAGE_SIZE,
                  response.nextPageToken,
                  clipFilters,
              );
            }),
            map(response => response.annotatedSegments),
            this.errorService.retryLong(),
            catchError(error => {
              this.errorService.handle(error);
              return of(null);
            }),
        );
  }

  /**
   * Builds a list filters that excludes segments fully outside of the current
   * clip times.
   */
  private buildClipFilters(assetInfo: AssetInfo): ListFilter {
    const clip = assetInfo.asset;
    if (!clip.original) return {};

    const filters: ListFilter = {};

    if (clip.startTime) {
      if (!assetInfo.asset.isLive) {
        filters.minimumEndOffset = clip.startTime;
      } else {
        filters.minimumEndTime = this.offsetToTime(clip.startTime, assetInfo);
      }
    }
    if (clip.endTime) {
      if (!assetInfo.asset.isLive) {
        filters.maximumStartOffset = clip.endTime;
      } else {
        filters.maximumStartTime = this.offsetToTime(clip.endTime, assetInfo);
      }
    }
    return filters;
  }

  /**
   * Fetches all segments with a start time later than the latest segment that
   * we have so far.
   */
  periodicallyLoadLatestSegments(assetInfo: AssetInfo):
      Observable<AnnotatedSegment[]|null> {
    return this.utils.timer(2000, 2000)
        .pipe(
            withLatestFrom(this.displaySegments$),
            switchMap(([, displaySegments]) => {
              const asset = assetInfo.asset;
              const lastSegmentEnd =
                  displaySegments[displaySegments.length - 1]?.endOffset;

              const filters: ListFilter = {
                minimumEndTime: this.offsetToTime(lastSegmentEnd, assetInfo)
              };

              return this.annotatedSegmentApiService.list(
                  asset.name, PAGE_SIZE, '', filters);
            }),
            map(response => response.annotatedSegments),
            catchError(error => {
              this.errorService.handle(error);
              return of(null);
            }),
        );
  }

  create(segment: DisplaySegment, schema: MetadataSchema) {
    return this.assetInfoChanged$.pipe(
        take(1), switchMap(assetInfo => {
          const apiSegment: ApiAnnotatedSegment = {
            startOffset: `${this.roundToMillisecond(segment.startOffset)}s`,
            endOffset: `${this.roundToMillisecond(segment.endOffset)}s`,
            startTime: this.offsetToTime(segment.startOffset, assetInfo),
            endTime: this.offsetToTime(segment.endOffset, assetInfo),
            segmentMetadata: segment.metadata,
          };

          // Annotated segments are tied to original asset
          const originalName =
              (assetInfo.asset.original ?? assetInfo.asset).name;

          return this.annotatedSegmentApiService
              .create(originalName, apiSegment)
              .pipe(
                  withLatestFrom(this.assetInfoChanged$),
                  map(([resp, assetInfo]) => this.convertToDisplaySegment(
                          resp, assetInfo, schema)),
                  tap(item => {
                    this.segmentChangedInternal$.next({type: 'CREATE', item});
                  }),
                  this.errorService.catchError(),
              );
        }));
  }

  updateMetadata(
      name: string, schema: MetadataSchema, metadata: Record<string, unknown>) {
    return this.assetInfoChanged$.pipe(switchMap(assetInfo => {
      const apiMetadata =
          new Metadata({schema: schema.name, jsonMetadata: metadata});

      return this.annotatedSegmentApiService.updateMetadata(name, apiMetadata)
          .pipe(
              map(seg => this.convertToDisplaySegment(seg, assetInfo, schema)),
              tap(item => this.segmentChangedInternal$.next(
                      {type: 'UPDATE', item})),
              this.errorService.catchError(),
          );
    }));
  }

  delete(name: string) {
    return this.annotatedSegmentApiService.delete(name).pipe(
        map(() => null),
        tap(() =>
                this.segmentChangedInternal$.next({type: 'DELETE', name})),
        this.errorService.catchError(),
    );
  }

  getDefaultSchemaName(): string{
    return DEFAULT_SEGMENT_SCHEMA_NAME;
  }

  /** Triggers re-fetching of the segment list. */
  refreshSegments() {
    this.refreshSegments$.next();
  }

  private sortSegmentsByOffset(segments: DisplaySegment[]) {
    return [...segments].sort((a, b) => a.startOffset - b.startOffset);
  }

  private readonly displaySegmentsSet = new Set<string>();

  private readonly refreshSegments$ = new ReplaySubject<void>(1);

  /**
   * Transforms `currentAsset$` to only emit when the asset name or state
   * changes.
   */
  private readonly distinctAsset$ = this.currentAsset$.pipe(
    distinctUntilChanged((a, b) => {
      return a?.name === b?.name && a?.state === b?.state;
    }));

  /** Transforms `distinctAsset$` by fetching its live manifest if any. */
  private readonly assetInfoChanged$: Observable<AssetInfo> = this.watchAssetInfo();

  /**
   * Types of play periods that may be found in Annotated Segments
   */
  private readonly playingPeriods = [
    'Inning',   // Baseball, Cricket
    'Quarter',  // Basketball, American Football
    'Half',     // Basketball, American Football, Football
    'Round',    // Baseball
    'Set',      // Volleyball, Tennis
    'Period',   // Floorball, Ice Hockey
    'End',      // Curling
  ] as const;

  private watchAssetInfo() {
    return this.distinctAsset$.pipe(
        // Ignore undefined assets to keep the previous segments in memory
        // in case the same asset is re-opened in persistent panel.
        filter(Boolean),
        switchMap(asset => {
          if (!asset?.isLive) return of({asset});
          return this.manifestService.getLiveAssetTimeline(asset).pipe(
              map(info => {
                if (isErrorResponse(info)) return null;
                return {asset, info};
              }));
        }),
        // Do not emit in case of error: the feed will stay empty.
        filter(Boolean),
        shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  private convertToDisplaySegment(
      apiSegment: AnnotatedSegment, assetInfo: AssetInfo,
      schema?: MetadataSchema): DisplaySegment {
    const asset = assetInfo.asset;
    const jsonMetadata = apiSegment.segmentMetadata.jsonMetadata;
    const playPeriodType = this.findPlayPeriodType(jsonMetadata);

    let startOffset = this.roundToMillisecond(apiSegment.startOffset);
    // TODO: Some apiSegment may be instantaneous and not have
    // an endOffset. Verify the value received in this situation.
    let endOffset =
        this.roundToMillisecond(apiSegment.endOffset || apiSegment.startOffset);

    // Live segments may not have offsets. In that case they are inferred from
    // the wall-clock times.
    if (asset.isLive && !startOffset && !endOffset && assetInfo.info) {
      const startTime = this.timezone.parseFromIso(apiSegment.startTime);
      const endTime = this.timezone.parseFromIso(apiSegment.endTime);
      const startDiff =
          startTime.diff(DateTime.fromJSDate(assetInfo.info.start));
      const endDiff = endTime.diff(DateTime.fromJSDate(assetInfo.info.start));
      startOffset = startDiff.toMillis() / 1000;
      endOffset = endDiff.toMillis() / 1000;
    }

    const startLabel = this.getLabel(asset, apiSegment.startTime, startOffset);
    const endLabel = this.getLabel(asset, apiSegment.endTime, endOffset);

    const time =
        startLabel === endLabel ? startLabel : `${startLabel} ${endLabel}`;

    const description = this.getDescription(apiSegment, schema) ?? schema?.eventDescription;
    const descriptionHtml = this.utils.htmlEscape(
        description.join('\n'), /* convertNewLinesToBr= */ true);
    const playPeriod =
        playPeriodType && `${playPeriodType} ${jsonMetadata[playPeriodType]}`;

    if (this.featureFlag.featureOn('play-feed-improvements')) {
      startOffset =  this.calcStartOffset(asset, startOffset, endOffset, apiSegment.startTime);
      endOffset =  this.calcEndOffset(startOffset, endOffset, apiSegment);
    }

    return {
      time,
      // Escaped to be future-proof as its value is auto generated.
      timeHtml: this.utils.htmlEscape(time),
      description: description.join(', ') ?? schema?.eventDescription,
      descriptionHtml,
      playPeriod,
      playPeriodHtml: playPeriod && this.utils.htmlEscape(playPeriod),
      startOffset,
      endOffset,
      name: apiSegment.name,
      metadata: apiSegment.segmentMetadata
    };
  }

  private getDescription(
      apiSegment: AnnotatedSegment, schema?: MetadataSchema) {
    const fieldName = schema?.displayOrder.length ? schema.displayOrder[0] :
                                                    DEFAULT_DESCRIPTION_FIELD;
    const value: unknown = apiSegment.segmentMetadata.jsonMetadata[fieldName] ?? apiSegment?.segmentMetadata?.jsonMetadata?.['event_description'];

    if (value == null) return [];

    if (Array.isArray(value)) return value.map(String);

    return [String(value)];
  }

  private getLabel(asset: Asset, isoTime: string, offset: number) {
    // If a wall-clock is provided by the segment, use it to format the label
    // in the pre-configured timezone.
    const dateTime = this.timezone.parseFromIso(isoTime);
    if (dateTime.isValid) return dateTime.toFormat('HH:mm:ss');

    // Subtract clip start from start/end offset so that a segment starting at
    // the beginning/ending of a clip would show the label "00:00:00". Hide
    // negative values in case the segment starts before the beginning of a
    // clip.
    const duration = Math.max(0, offset - asset.startTime);
    return Duration.fromMillis(duration * 1000).toFormat('hh:mm:ss');
  }

  // Formats value in seconds, to the nearest milliseconds.
  private roundToMillisecond(offset: string|number) {
    if (typeof offset === 'string') {
      offset = Number(offset.replace('s', ''));
    }

    return Math.floor(offset * 1000) / 1000;
  }

  /**
   * Play Period types will be different for each type of sport and therefore we
   * can't have a hardcoded type.
   *
   * The expected Key/Values of the jsonMetadata passed in is unknown because it
   * will be different for each video. Therefore, the map type is unknown.
   */
  private findPlayPeriodType(jsonMetadata: Record<string, unknown>): string
      |undefined {
    return this.playingPeriods.find(period => jsonMetadata[period]);
  }

  private offsetToTime(offset: number, assetInfo: AssetInfo) {
    // Case VoD with timecode information
    if (!assetInfo.asset.isLive && assetInfo.asset.startTimecode) {
      return DateTime.fromMillis(assetInfo.asset.startTimecode)
          .plus({seconds: offset})
          .toUTC()
          .toISO() ?? undefined;
    }

    // Case Live with a valid manifest
    if (assetInfo.asset.isLive && assetInfo.info) {
      return DateTime.fromJSDate(assetInfo.info.start)
          .plus({seconds: offset})
          .toUTC()
          .toISO() ?? undefined;
    }

    // Other cases, no wall-clock time can be determined.
    return undefined;
  }

  private calcStartOffset(asset: Asset, startOffset: number, endOffset: number,
      startTime: string): number {
    if (startOffset || endOffset || !asset.startTimecode) {
      return startOffset;
    }

    const parsed = this.timezone.parseFromIso(startTime);
    const segmentStartSecs = parsed.hour * 3600 + parsed.minute * 60 + parsed.second;
    const assetStartSecs = (asset.startTimecode / 1000);

    return segmentStartSecs - assetStartSecs +
        (segmentStartSecs < assetStartSecs ? 24 * 60 * 60 : 0);
  }

  private calcEndOffset(startOffset: number, endOffset: number,
      apiSegment: AnnotatedSegment): number {
    if (endOffset) {
      return endOffset;
    }

    const startTime = this.timezone.parseFromIso(apiSegment.startTime);
    const endTime = this.timezone.parseFromIso(apiSegment.endTime);
    const diff = endTime.diff(startTime).toMillis() / 1000;
    return startOffset + diff + (diff < 0 ? 24 * 60 * 60 : 0);
  }
}

/**
 * Used to simplify the APIAnnotatedSegment type to something useful for the UI
 * specifically
 */
export interface DisplaySegment {
  /** Time label in form "[startTime] [endTime]" */
  time: string;
  /** Time label in form "[startTime] [endTime]" in html format */
  timeHtml?: string;
  /** Segment description gathered from metadata */
  description: string;
  /** Segment description gathered from metadata in html format */
  descriptionHtml?: string;
  /** Play period (e.g. "Quarter 1") */
  playPeriod?: string;
  /** Play period (e.g. "Quarter 1"), in html format */
  playPeriodHtml?: string;
  /** Start time offset of the segment */
  startOffset: number;
  /** End time offset of the segment */
  endOffset: number;
  /** Annotated Segment unique name */
  name: string;
  /** Segment metadata */
  metadata: Metadata;
}

/** Helper method to create fake display segment. */
export function makeFakeDisplaySegment(extras: Partial<DisplaySegment> = {}):
    DisplaySegment {
  const time = '00:00:25 - 00:01:40';
  const description = 'fake segment';
  const descriptionHtml = extras.description ?? description;
  const timeHtml = extras.time ?? time;

  return {
    name: 'fake-segment',
    description,
    descriptionHtml,
    startOffset: 25,
    endOffset: 100,
    time,
    timeHtml,
    metadata: new Metadata({jsonMetadata: {'GameLogs': [description]}}),
    ...extras,
  };
}
