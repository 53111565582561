import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {SharedLinksPatchRequestParams} from 'api/ias/api/projects.service';
import {ApiSignedUrl} from 'api/ias/model/models';
import {SharedLink, SignedUrl} from 'models';

import {environment} from '../environments/environment';
import {ErrorService} from '../error_service/error_service';
import {Asset} from '../services/asset_service';

import {IasApiClient} from './api_client.module';
import {RequiredRecursively} from './utils_service';

/** IAS backend shared links related APIs. */
@Injectable({providedIn: 'root'})
export class SharedLinksApiService {
  constructor(
      private readonly apiClient: IasApiClient,
      private readonly errorService: ErrorService,
  ) {}

  /** Gets a single link. May be called without authentication. */
  preview(linkName: string): Observable<SharedLink|null> {
    try {
      return this.apiClient.sharedLinksPreview({name: linkName})
          .pipe(
              map(l => new SharedLink(l)), this.errorService.retryLong(),
              catchError(error => {
                this.errorService.handle(error);
                return of(null);
              }));
    } catch (error: unknown) {
      this.errorService.handle(error);
      return of(null);
    }
  }

  /**
   * Search links with title matching the given query (or all if empty string).
   * Caller must be authenticated.
   */
  list(titleQuery: string, pageSize: number, pageToken?: string) {
    try {
      return this.apiClient
          .sharedLinksList({
            parent: environment.mamApi.parent,
            pageSize,
            pageToken,
            filter: `title:"${titleQuery}"`,
          })
          .pipe(
              map(resp => ({
                    nextPageToken: resp.nextPageToken,
                    sharedLinks:
                        (resp.sharedLinks ?? []).map(l => new SharedLink(l)),
                  })),
              this.errorService.retryLong(),
              catchError(error => {
                this.errorService.handle(error);
                return of(null);
              }),
          );
    } catch (error: unknown) {
      this.errorService.handle(error);
      return of(null);
    }
  }

  /** Create a new link to the given asset. Caller must be authenticated. */
  create(
      asset: Asset, ttl: string,
      additionalProperties?: Record<string, string>): Observable<SharedLink> {
    try {
      return this.apiClient
          .sharedLinksCreate({
            parent: environment.mamApi.parent,
            body: {
              title: asset.title,
              downloadable: !asset.original && !asset.isLive,
              ttl,
              clip: asset.original ? asset.name : undefined,
              asset: asset.original ? undefined : asset.name,
              additionalProperties,
            }
          })
          .pipe(map(l => new SharedLink(l)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  /** Create a new link to the given full clip video. Caller must be authenticated. */
  createFullClip(
    asset: Asset, ttl: string,
    additionalProperties?: Record<string, string>): Observable<SharedLink|null> {
    try {
      return this.apiClient.sharedLinksCreate({
          parent: environment.mamApi.parent,
          body: {
            title: asset.title,
            downloadable: true,
            ttl,
            clip: undefined,
            asset: asset.original?.name,
            additionalProperties,
          }
        }).pipe(
          map(l => new SharedLink(l)),
          catchError(error => {
            this.errorService.handle(error);
            return of(null);
          }));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  /** Updates Time to Live of the given link. Caller must be authenticated. */
  updateExpiration(linkName: string, ttl: string): Observable<SharedLink|null> {
    return this.patch({
      name: linkName,
      body: {ttl},
      updateMask: 'ttl',
    });
  }

  signUrls(linkName: string, urls: string[]): Observable<SignUrlResponse|null> {
    try {
      return this.apiClient.sharedLinksSign({name: linkName, body: {urls}})
          .pipe(
              map(resp => ({
                    signedUrls:
                        (resp.signedUrls ?? []).map(u => new SignedUrl(u))
                  })),
              catchError(error => {
                this.errorService.handle(error);
                return of(null);
              }),
          );
    } catch (error: unknown) {
      this.errorService.handle(error);
      return of(null);
    }
  }

  delete(linkName: string) {
    try {
      return this.apiClient.sharedLinksDelete({name: linkName})
          .pipe(
              catchError(error => {
                this.errorService.handle(error);
                return of(null);
              }),
          );
    } catch (error: unknown) {
      this.errorService.handle(error);
      return of(null);
    }
  }

  private patch(params: SharedLinksPatchRequestParams):
      Observable<SharedLink|null> {
    try {
      return this.apiClient.sharedLinksPatch(params).pipe(
          map(l => new SharedLink(l)),
          catchError(error => {
            this.errorService.handle(error);
            return of(null);
          }),
      );
    } catch (error: unknown) {
      this.errorService.handle(error);
      return of(null);
    }
  }
}

/** Response for signUrls api call */
export interface SignUrlResponse {
  signedUrls: Array<RequiredRecursively<ApiSignedUrl>>;
}
