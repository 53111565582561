<div
  class="persistent-panel-container"
  #container>
  <mat-progress-bar
        *ngIf="stateService.isPanelLoading$ | async"
        mode="indeterminate">
    </mat-progress-bar>

  <div
      class="resize-handle"
      [class.disabled]="!expanded"
      cdkDragLockAxis="x"
      cdkDrag
      [cdkDragFreeDragPosition]="freeDragPosition"
      (cdkDragMoved)="resizePanel($event)"
      (cdkDragEnded)="saveAndRefreshPanelSize()"></div>

  <!-- Expand/collapse panel floating button -->
  <button
      class="collapse-button"
      mat-icon-button
      [disabled]="metadataService.isEditing$ | async"
      (click)="this.stateService.togglePersistentPanel$.next(!expanded)"
      [mam-firebase-ga]="getCollapseMessage()"
      [attr.aria-label]="getCollapseMessage()"
      [matTooltip]="getCollapseMessage()"
      matTooltipShowDelay="800">
    <mat-icon>{{expanded ? 'expand_less': 'expand_more'}}</mat-icon>
  </button>


    <mat-tab-group
        animationDuration="0ms"
        [disablePagination]="true"
        color="accent"
        [selectedIndex]="selectedTabIndex"
        (selectedIndexChange)="selectTab($event)"
        [mat-stretch-tabs]="isMobilePlatform()"
        mat-align-tabs="end"
        >

      <!-- Play by Play Feed -->
      <ng-container *ngIf="displayedTabs.includes('play-feed')">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon matTooltip="Play by Play Feed">
              dynamic_feed
            </mat-icon>
          </ng-template>
          <ng-template matTabContent>
            <mam-play-feed-panel></mam-play-feed-panel>
          </ng-template>
        </mat-tab>
      </ng-container>

      <!-- Insights -->
      <ng-container *ngIf="displayedTabs.includes('insights')">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon
                matTooltip="Video Insights"
                class="material-icons-outlined flip-icon">
              wb_incandescent
            </mat-icon>
          </ng-template>
          <ng-template matTabContent>
            <mam-insights-panel
                [asset]="(stateService.currentAsset$ | async)!">
            </mam-insights-panel>
          </ng-template>
        </mat-tab>
      </ng-container>

      <!-- Metadata -->
      <mat-tab *ngIf="displayedTabs.includes('metadata')">
        <ng-template mat-tab-label>
          <mat-icon
              matTooltip="Metadata"
              class="material-icons-outlined">
            info
          </mat-icon>
        </ng-template>
        <ng-template matTabContent>
          <mam-asset-metadata-panel
              *ngIf="stateService.currentAsset$ | async as currentAsset"
              [assets]="[currentAsset]">
          </mam-asset-metadata-panel>
        </ng-template>
      </mat-tab>

      <!-- Related Views -->
      <ng-container *ngIf="displayedTabs.includes('related-views')">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon
                matTooltip="Related Views"
                class="material-icons-outlined">
              videocam
            </mat-icon>
          </ng-template>
          <ng-template matTabContent>
          </ng-template>
        </mat-tab>
      </ng-container>

      <!-- Staging Metadata -->
      <ng-container *ngIf="displayedTabs.includes('staging')">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon
                *ngIf="hidePersistentPanel"
                [matTooltip]="getAssetsMetadataTooltip()"
                class="material-icons-outlined">
              info
            </mat-icon>
          </ng-template>
          <ng-template matTabContent>
            <mam-staging-panel></mam-staging-panel>
          </ng-template>
        </mat-tab>
      </ng-container>

      <!-- Clip Bins -->
      <mat-tab *ngIf="displayedTabs.includes('clipbins')">
        <ng-template mat-tab-label>
          <mat-icon
              matTooltip="Clips and Clip Bins"
              class="material-icons-outlined">
            subscriptions
          </mat-icon>
        </ng-template>
        <mam-clip-bin-selection
            (clipMoveRequested)="openClipMoveDialog($event)">
        </mam-clip-bin-selection>
      </mat-tab>
    </mat-tab-group>
  </div>

