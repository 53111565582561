import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';

import {FirebaseModule} from '../firebase/firebase_module';
import {TransferMonitorModule} from '../transfer_monitor/transfer_monitor_module';

import {AdminLanding} from './admin_landing';
import {AdminMetricsList} from './admin_metrics_list';
import {AdminUserTableGoogleWorkspaceCell} from './admin_user_table_google_workspace_cell';
import {AdminUsersTable} from './admin_users_table';



@NgModule({
    imports: [
        CommonModule,
        MatPaginatorModule,
        MatOptionModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatOptionModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TransferMonitorModule,
        FirebaseModule
    ],
  declarations: [
    AdminLanding,
    AdminMetricsList,
    AdminUsersTable,
    AdminUserTableGoogleWorkspaceCell
  ],
  exports: [
    AdminLanding,
  ],
})
export class AdminModule {
}
