import {Inject, Injectable, InjectionToken} from '@angular/core';

import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';

/**
 * Injection token for using a storage singleton that will contain a lit of
 * recent queries.
 */
export const STORAGE = new InjectionToken<Storage>(
    'Local Storage', {factory: () => window.localStorage});

type PreferenceKey = 'user_volume'|'user_panel_width'|'vod_staging_page_size'|
    'vod_staging_error_page_size'|'search_list_page_size'|'user_site';

const PREFIX = 'mamui_';

/** Store of user preferences for future retrieval. */
@Injectable({providedIn: 'root'})
export class PreferencesService {
  constructor(
      @Inject(STORAGE) private readonly storage: Storage,
      private readonly analyticsService: FirebaseAnalyticsService,
  ) {}

  save(key: PreferenceKey, value: string) {
    const previousValue = this.load(key);
    if (previousValue === value) return;

    this.storage.setItem(PREFIX + key, value);

    // Log it to firebase.
    this.analyticsService.logEvent('Saved user preference', {
      eventType: AnalyticsEventType.SAVE_PREFERENCE,
      string1: key,
      string2: value,
    });
  }

  load(key: PreferenceKey): string|null {
    return this.storage.getItem(PREFIX + key);
  }

  clear() {
    this.storage.clear();
  }
}
