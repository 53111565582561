import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {delay} from 'rxjs/operators';

import {ApiSuggestQueriesResponse} from 'api/ias/model/models';
import {QuerySuggestion} from 'models';

import {getDefaultFakeLiveAssets, getFakeVodOriginals} from './asset_api_fake_service';
import {Asset, AssetState, Original} from './asset_service';
import {pseudoRandom} from './fake_api_utils';
import {ApiAssetStateEnum} from './ias_types';
import {SearchApiService, SearchQueryParams, SearchType} from './search_api.service';
import {FacetBucket, FacetGroup, SelectedFacetGroup} from './search_facet_service';
import {SearchMode, SearchParams, SearchResponse, SearchSegment} from './search_service';
import {Interface, RequiredRecursively} from './utils_service';

const EMPTY_SEARCH_RESULTS = 'test empty';
const SECOND_RESULT_ERROR = 'test error 2';
const FIRST_PAGE_TOKEN = 'token_1';
const SECOND_PAGE_TOKEN = 'token_2';
const LAST_PAGE_SIZE = 10;
const EVENT_ID = 'fake search query';
let previousQuery = '';

/** Serves assets */
@Injectable({providedIn: 'root'})
export class FakeSearchApiService implements Interface<SearchApiService> {
  search(searchParams: SearchParams): Observable<SearchResponse> {
    const response: SearchResponse = {
      videoSegments: [],
      facetGroups: [],
      searchMode: searchParams.searchMode,
    };

    // Return empty search results
    if (searchParams.query.includes(EMPTY_SEARCH_RESULTS)) {
      return of(response).pipe(delay(10));
    }

    // Camera assets case.
    const correlationIdMatch =
        searchParams.query.match(/CorrelationId: ([a-z0-9]+)/);
    if (correlationIdMatch) {
      const videoSegments = getDefaultFakeLiveAssets()
                                .filter(
                                    asset => asset.camera?.correlationId ===
                                        correlationIdMatch[1])
                                .map(asset => createSegment({asset}));
      return of({...response, videoSegments}).pipe(delay(2000));
    }


    // Generate three pages of results
    let numOfSegments = searchParams.pageSize;
    if (searchParams.query === previousQuery) {
      switch (searchParams.pageToken) {
        case FIRST_PAGE_TOKEN:
          response.nextPageToken = SECOND_PAGE_TOKEN;
          break;
        case SECOND_PAGE_TOKEN:
          numOfSegments = LAST_PAGE_SIZE;
          response.nextPageToken = undefined;
          break;
        default:
          response.nextPageToken = FIRST_PAGE_TOKEN;
          break;
      }
    } else {
      response.nextPageToken = FIRST_PAGE_TOKEN;
      previousQuery = searchParams.query;
    }

    response.videoSegments = makeFakeVideoResults(numOfSegments, searchParams);

    response.facetGroups = makeFakeFacetGroups(searchParams.facetSelections);

    // Debug case that simulates a 2nd result broken.
    if (searchParams.query.includes(SECOND_RESULT_ERROR)) {
      Object.assign(response.videoSegments[1].asset, {
        renditions: [],
        title: '',
        name: '',
        startTime: 0,
        endTime: 0,
        duration: 0,
        thumbnail: '',
      });
    }

    return of(response).pipe(delay(1500));
  }

  searchAll(searchParams: SearchQueryParams): Observable<SearchResponse> {
    const response: SearchResponse = {
      videoSegments: [],
      facetGroups: [],
      searchMode: searchParams.searchMode,
    };

    // Return empty search results
    if (searchParams.query.includes(EMPTY_SEARCH_RESULTS)) {
      return of(response).pipe(delay(300));
    }

    if (searchParams.searchType === SearchType.VOD) {
      response.videoSegments = makeFakeVideoResults(1000, searchParams);
    } else {
      // Use simple checks to figure what states should be used to generate
      // fake live assets.
      const states: AssetState[] = [];
      if (searchParams.query.includes(ApiAssetStateEnum.STATE_SCHEDULED)) {
        states.push(AssetState.SCHEDULED);
      }
      if (searchParams.query.includes(
              `(${ApiAssetStateEnum.STATE_STREAMING})`)) {
        states.push(AssetState.AIRING, AssetState.PENDING);
      }
      if (searchParams.query.includes(
              ApiAssetStateEnum.STATE_STREAMING_STOPPED)) {
        states.push(AssetState.ENDED, AssetState.PROCESSING, AssetState.VOD);
      }
      response.videoSegments =
          getDefaultFakeLiveAssets()
              .filter(
                  // Keep only assets with states mentioned in the query.
                  asset => states.includes(asset.state) &&
                      // Keep single-camera assets or broadcast feeds for
                      // multi-camera assets.
                      (!asset.camera || asset.camera.isBroadcast))
              .map(asset => createSegment({asset, query: searchParams.query}));
    }
    response.facetGroups = makeFakeFacetGroups(searchParams.facetSelections);
    return of(response).pipe(delay(1500));
  }

  suggest(
      prefix: string, searchMode: SearchMode, searchType: SearchType,
      resultSize: number) {
    const fakeSuggestions = searchType === SearchType.LIVE ?
        fakeLiveSuggestions :
        (
          searchMode === SearchMode.VIDEO ?
            fakeVideoSuggestions :
            fakeSegmentSuggestions
        );
    const found = fakeSuggestions.filter(it => it.startsWith(prefix))
                      .slice(0, resultSize);
    const result: RequiredRecursively<ApiSuggestQueriesResponse> = {
      suggestedQueries: found.map(text => {
        return new QuerySuggestion({
          text,
          searchOperatorSuggestion: text.includes(':'),
        });
      }),
      requestType: searchMode === SearchMode.VIDEO ?
          'SUGGEST_QUERIES_REQUEST_TYPE_VIDEO' :
          'SUGGEST_QUERIES_REQUEST_TYPE_SEGMENT'
    };
    return of(result).pipe(delay(400));
  }
}

const fakeSegmentSuggestions = [
  'player:',
  'sport:',
  'top',
  'touchdown',
  '(touch down)',
  'trace',
];

const fakeVideoSuggestions = [
  'archived',
  'basketball',
  'logo',
  'los angeles',
  'catch',
  '(dirty feed)',
];

const fakeLiveSuggestions = [
  '(online)',
  'streaming',
  'broadcast',
  'today',
  'scheduled',
  'play offs',
];

/** Fake facet response for local development and unit tests */
export function makeFakeFacetGroups(facetSelections?: SelectedFacetGroup[]):
    FacetGroup[] {
  const facetGroups: FacetGroup[] = [
    {
      facet: 'customized_label',
      displayedName: 'customized label',
      type: 'VALUE',
      facetBuckets: [
        {count: 56, value: 'cheerleader', isSelected: false},
        {count: 45, value: 'pass', isSelected: false},
        {count: 31, value: 'catch', isSelected: false},
        {count: 23, value: 'touchdown run', isSelected: false},
        {count: 9, value: 'fans', isSelected: false},
        {count: 4, value: 'touchdown catch', isSelected: false},
      ]
    },
    {
      facet: 'shot_type',
      displayedName: 'shot type',
      type: 'VALUE',
      facetBuckets: [{count: 5, value: 'Full Shot', isSelected: false}]
    },
    {
      facet: 'season',
      displayedName: 'season',
      type: 'VALUE',
      facetBuckets: []
    },
    {
      facet: 'team',
      displayedName: 'team',
      type: 'VALUE',
      facetBuckets: []
    },
    {
      facet: 'player',
      displayedName: 'player',
      type: 'VALUE',
      facetBuckets: [],
    },
    {
      facet: 'date',
      displayedName: 'date By year',
      type: 'DATE',
      facetBuckets: [
        {count: 10, value: '2019', granularity: 'YEAR', isSelected: false},
        {count: 10, value: '2020', granularity: 'YEAR', isSelected: false},
      ]
    },
    {
      facet: 'date',
      displayedName: 'date By month',
      type: 'DATE',
      facetBuckets: [
        {count: 10, value: '2019-1', granularity: 'MONTH', isSelected: false},
        {count: 10, value: '2019-2', granularity: 'MONTH', isSelected: false},
        {count: 10, value: '2020-6', granularity: 'MONTH', isSelected: false},
        {count: 10, value: '2020-8', granularity: 'MONTH', isSelected: false},
      ]
    },
  ];
  // No facet group is selected, return static facet groups
  return (!facetSelections || facetSelections.length === 0) ?
    facetGroups :
    // Some facet groups selected, make dynamic facet groups
    makeDynamicFacetGroups(facetSelections, facetGroups);
}

/**
 * Make dynamic facet groups in the fake backend:
 * Based on the facetSelections, these selected groups will not change, will
 * generate a random group from the unselected groups and append to the new
 * facet groups, make it 'dynamic'.
 */
function makeDynamicFacetGroups(
    facetSelections: SelectedFacetGroup[],
    facetGroups: FacetGroup[]): FacetGroup[] {
  // Put the facet selections to the map, key is the facet, value is another
  // map, which has bucketValue as key, selected or not as value.
  const map = new Map<string, Map<string, boolean>>();
  for (const facetSelection of facetSelections) {
    const facet = facetSelection.facet;
    if (!map.has(facet)) {
      map.set(facet, new Map());
    }
    const buckets = facetSelection.facetResults.buckets;
    for (const bucket of buckets) {
      const bucketValue = bucket.bucketValue;
      map.get(facet)?.set(bucketValue, bucket.selected);
    }
  }
  const dynamicFacetGroups = getSelectedFacetGroups(map, facetGroups);
  // Select a random unselected group, put to the dynamic facet groups
  const unselectedFacetGroups =
      facetGroups.filter(facetGroup => !map.has(facetGroup.facet));
  const seed = facetSelections.map(f => f.facet).join('');
  const index = Math.floor(pseudoRandom(seed) * unselectedFacetGroups.length);
  dynamicFacetGroups.push(unselectedFacetGroups[index]);
  return dynamicFacetGroups;
}

/** Make selected facet groups based on the map and facetGroups */
function getSelectedFacetGroups(
    map: Map<string, Map<string, boolean>>,
    facetGroups: FacetGroup[]): FacetGroup[] {
  const selectedGroups =
      facetGroups.filter(facetGroup => map.has(facetGroup.facet))
          .map((facetGroup) => {
            const buckets =
                facetGroup.facetBuckets.map((bucket: FacetBucket) => {
                  return {
                    ...bucket,
                    isSelected: map.get(facetGroup.facet)?.get(bucket.value) ||
                        bucket.isSelected
                  };
                });
            facetGroup.facetBuckets = buckets;
            return facetGroup;
          });
  return selectedGroups;
}

/** Generate Fake video results: full video results or segment results */
function makeFakeVideoResults(
    numOfSegments: number, searchParams: SearchQueryParams): SearchSegment[] {
  const videoResults: SearchSegment[] = [];
  for (let i = 0; i < numOfSegments; i++) {
    const seed = `${Object.values(searchParams)}:${i}/${numOfSegments}`;

    // Get a random asset from our list of fake ones
    const fakeAssets = getFakeVodOriginals();
    const index = Math.floor(pseudoRandom(`asset${seed}`) * fakeAssets.length);
    const asset: Original = {...fakeAssets[index % fakeAssets.length]};

    // Start anywhere within this asset duration
    const startTime = searchParams.searchMode === SearchMode.SEGMENT ?
        pseudoRandom(`start${seed}`) * asset.duration :
        0;

    // Make the result at most 25% of this asset length
    const maxDuration = pseudoRandom(`max${seed}`) * (asset.duration / 4);
    const endTime = searchParams.searchMode === SearchMode.SEGMENT ?
        Math.min(
            asset.duration,
            startTime + (pseudoRandom(`end${seed}`) * maxDuration + 1)) :
        asset.duration;

    videoResults.push({
      asset,
      startTime,
      endTime,
      query: searchParams.query,
      eventId: EVENT_ID,
      name: `${asset.name}:${startTime}:${endTime}`,
      searchMode: SearchMode.SEGMENT,
    });
  }
  return videoResults;
}

/** Creates a search segment from an asset. */
export function createSegment(partial: Partial<SearchSegment>&
                              {asset: Asset}): SearchSegment {
  return {
    startTime: 0,
    endTime: 0,
    eventId: EVENT_ID,
    query: '',
    ...partial,
    name: `${partial.asset.name}:${partial.startTime ?? 0}:${
        partial.endTime ?? 0}`,
    searchMode: SearchMode.SEGMENT,
  };
}
