<h1 mat-dialog-title>Create a transfer job</h1>
<div mat-dialog-content>
  <form [formGroup]="cloudIngestFormGroup">
    <h4>Choose a source</h4>
    <mat-form-field class="bucket-field" appearance="outline" floatLabel="always">
      <mat-label>Bucket</mat-label>
      <span matPrefix>gs://</span>
      <input
          matInput
          required
          placeholder="my-bucket-name"
          autocomplete="off"
          formControlName="bucketControl" />
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Folder (Optional)</mat-label>
      <input
          matInput
          placeholder="/to/my/folder/"
          autocomplete="off"
          formControlName="filePathControl" />
    </mat-form-field>

    <h4>Schedule</h4>
    <mat-radio-group class="schedule-radio-group"
        aria-label="Cloud ingestion schedule type"
        formControlName="scheduleControl">
      <mat-radio-button aria-label="Run once"
          color="primary"
          [value]="scheduleType.ON_DEMAND">
          Run once
      </mat-radio-button>
      <mat-radio-button aria-label="Run hourly"
          color="primary"
          [value]="scheduleType.AUTO">
          Run hourly
      </mat-radio-button>
    </mat-radio-group>
  </form>
</div>

<div mat-dialog-actions>
  <button
      mam-firebase-ga="Cancel cloud ingest creation"
      mat-button
      mat-dialog-close>
    Cancel
  </button>

  <button
      mat-button
      class="primary"
      mam-firebase-ga="Confirm cloud ingest creation"
      mat-dialog-close
      [disabled]="!cloudIngestFormGroup.valid"
      (click)="submit()">
    Create
  </button>
</div>
