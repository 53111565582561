import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

import {checkExhaustive} from 'asserts/asserts';

import {ClipbinsOwner} from '../services/bin_api.service';

/**
 * Dropdown allowing to switch between user clipbins and all clipbins.
 */
@Component({
  selector: 'mam-clip-bin-owner-selector',
  templateUrl: './clip_bin_owner_selector.ng.html',
  styleUrls: ['./clip_bin_owner_selector.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipBinOwnerSelector {
  readonly clipbinsOwnerTypes: ClipbinsOwner[] = [
    ClipbinsOwner.USER,
    ClipbinsOwner.ALL,
  ];

  @Input() owner = ClipbinsOwner.USER;

  @Output() readonly ownerChange = new EventEmitter();

  getOwnerText(clipbinsOwner: ClipbinsOwner) {
    switch (clipbinsOwner) {
      case ClipbinsOwner.USER:
        return 'My clip bins';
      case ClipbinsOwner.ALL:
        return 'All clip bins';
      default:
        checkExhaustive(clipbinsOwner);
    }
  }
}
