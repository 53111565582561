import {Injectable} from '@angular/core';
import {DocumentData, DocumentReference} from '@firebase/firestore';
import {take} from 'rxjs/operators';

import {assertExists} from 'asserts/asserts';

import {ErrorService} from '../error_service/error_service';
import {FirebaseFirestoreDataService} from '../firebase/firebase_firestore_data_service';

import {BucketType, BucketTypeEnum, Granularity} from './ias_types';

const GROUP_SEPARATOR = ';';
const OPTIONS_TYPE_SEPARATOR = '|';
const OPTION_SEPARATOR = ' ';

/**
 * Provides facets conversion utilities from and to search queries.
 */
@Injectable({providedIn: 'root'})
export class SearchFacetService {

  facetsFavoriteOptionsData?: Map<string, DocumentData>;

  constructor(
      private readonly errorService: ErrorService,
      private readonly dataService: FirebaseFirestoreDataService,
  ) {
    this.readFacetsFavoriteOptions();
  }

  /**
   * Updates the selections state from a facet event and returns a search query
   * for this full facet selections state.
   */
  makeQuery(facetChangeEvent: FacetChangeEvent) {
    // Update the selection state and make up query based on it
    this.updateState(facetChangeEvent);
    return this.computeQuery();
  }

  /**
   * Based on the facets query string, build facet selections array, which has
   * the same structure as what we pass to the backend.
   */
  buildFacetSelections(facetQuery: string, dateRangeQuery?: string) {
    this.selectionsState.clear();
    const facetSelections: SelectedFacetGroup[] = [];

    if (dateRangeQuery) {
      const dateResult = this.buildDateRangeSelection(dateRangeQuery);
      facetSelections.push({'facet': 'EventDate', 'facetResults': dateResult});
    }

    if (!facetQuery) {
      return facetSelections;
    }

    const facetGroups = facetQuery.split(GROUP_SEPARATOR);
    for (const facetGroup of facetGroups) {
      if (!facetGroup) {
        continue;
      }
      const {facet, selectedOptions, unselectedOptions} =
          this.splitAndSaveFacetQuery(facetGroup);
      const buckets: SelectedFacetBucket[] =
          selectedOptions
              .map((selectedOption) => {
                return {bucketValue: selectedOption, selected: true};
              })
              .concat(unselectedOptions.map((unselectedOption) => {
                return {bucketValue: unselectedOption, selected: false};
              }));
      const facetResults:
          SelectedFacetResult = {bucketType: BucketTypeEnum.VALUE, buckets};
      facetSelections.push({'facet': facet, 'facetResults': facetResults});
    }
    return facetSelections;
  }

  /**
   * Generates a RANGE facet result from the selected dates.
   */
  private buildDateRangeSelection(dateRangeQuery: string): SelectedFacetResult {
    const buckets: SelectedFacetBucket[] =
        [{bucketValue: dateRangeQuery, selected: true}];
    const facetResults:
        SelectedFacetResult = {bucketType: BucketTypeEnum.RANGE, buckets};
    return facetResults;
  }

  /** Updates `selectionsState`. */
  private updateState(facetChangeEvent: FacetChangeEvent) {
    const {checked, facetGroup, facetBucket} = facetChangeEvent;
    const {facet, facetBuckets} = facetGroup;
    const {value} = facetBucket;

    const facetOptionsState = this.selectionsState.get(facet);
    if (!checked) {
      if (!facetOptionsState) return;
      // Unselect the current facet option
      facetOptionsState.set(value, false);
      const optionSelected = facetOptionsState.values();
      // Check whether all the facet options in this group are unselected, if
      // yes, remove the group from the map
      if (optionSelected && [...optionSelected].every(selected => !selected)) {
        this.selectionsState.delete(facet);
      }
    } else {
      if (facetOptionsState) {
        facetOptionsState.set(value, true);
      } else {
        // It's the first time to select an option in this group,add all the
        // other unselected options to the map too
        const innerMap = new Map<string, boolean>();
        facetBuckets.forEach((facetBucket) => {
          innerMap.set(facetBucket.value, false);
        });
        innerMap.set(value, true);
        this.selectionsState.set(facet, innerMap);
      }
    }
  }

  /**
   * Makes up facet query based on the selection state, we need to append facet
   * query to the url The format of facet query is: Group:[selected] |
   * [unselected]; for example:week1 + week2 selected, basketball selected:
   * week:week1 week2|week3 week4; sport:basketball|football baseball;
   */
  private computeQuery() {
    let fullFacetQueryString = '';
    for (const facet of this.selectionsState.keys()) {
      const innerMap = this.selectionsState.get(facet);
      assertExists(innerMap);
      let unselectedOptionQuery = '';
      let selectedOptionQuery = '';
      for (const [option, selected] of innerMap) {
        if (selected) {
          selectedOptionQuery += `${encodeURIComponent(option)} `;
        } else {
          unselectedOptionQuery += `${encodeURIComponent(option)} `;
        }
      }
      fullFacetQueryString += unselectedOptionQuery ?
          `${facet}:${selectedOptionQuery.trim()}|${
              unselectedOptionQuery.trim()};` :
          `${facet}:${selectedOptionQuery.trim()};`;
    }
    return fullFacetQueryString;
  }

  /**
   * A map which stores all the options of the selected group.
   * Key is the facet group value, value is another map, with facet value as
   * the key, selected or not as the value.
   */
  private readonly selectionsState = new Map<string, Map<string, boolean>>();


  /**
   * Splits a facet query into its selected and unselected components. Updates
   * the current selection state as a side effect.
   */
  private splitAndSaveFacetQuery(facetGroup: string) {
    const spliterIndex = facetGroup.indexOf(':');
    const facet = facetGroup.slice(0, spliterIndex);
    const optionGroups =
        facetGroup.slice(spliterIndex + 1).split(OPTIONS_TYPE_SEPARATOR);
    // OptionGroups[0]: selected options string, decode it to get the
    // values
    const selectedOptions = optionGroups[0]
                                .split(OPTION_SEPARATOR)
                                .map(value => decodeURIComponent(value));
    // OptionGroup[1]: unselected options string, decode it to get the values
    const unselectedOptions = optionGroups[1] ?
        optionGroups[1]
            .split(OPTION_SEPARATOR)
            .map(value => decodeURIComponent(value)) :
        [];
    // Update the selectionsState map when the url changes
    const innerMap = new Map<string, boolean>();
    selectedOptions.forEach((selectedOption) => {
      innerMap.set(selectedOption, true);
    });
    unselectedOptions.forEach((unselectedOption) => {
      innerMap.set(unselectedOption, false);
    });
    this.selectionsState.set(facet, innerMap);
    return {facet, selectedOptions, unselectedOptions};
  }

  readFacetsFavoriteOptions() {
    this.dataService.retriveFacetsFavoriteOptions()
        .pipe(take(1))
        .subscribe({
          next: (documents) => {
            this.facetsFavoriteOptionsData = new Map();
            for (const doc of documents) {
              this.facetsFavoriteOptionsData.set(doc.id, doc.data());
            }
          },
          error: (error) => {
            this.errorService.handle(`Can't read facets favorite options (no new items will be created to avoid duplicates): ${error}`);
          },
        });
  }

  getFavoriteOptions(facet: string): string[] {
    if (this.facetsFavoriteOptionsData) {
      for (const d of this.facetsFavoriteOptionsData) {
        if (d[1]['facet'] === facet) {
          return d[1]['options'];
        }
      }
    }
    return [];
  }

  updateFavoriteOptions(facet: string, options: string[]) {
    if (!this.facetsFavoriteOptionsData) {
      return;
    }

    const data = {facet, options};
    for (const d of this.facetsFavoriteOptionsData) {
      if (d[1]['facet'] === facet) {
        this.dataService.updateFacetsFavoriteOptions(d[0], data)
            .then(() => this.facetsFavoriteOptionsData?.set(d[0], data))
            .catch(error => this.errorService.handle(`Can't update facets favorite options: ${error}`));
        return;
      }
    }
    this.dataService.createFacetsFavoriteOptions(data)
        .then(response => {
          if (response instanceof DocumentReference) {
            this.facetsFavoriteOptionsData?.set(response.id, data);
          }
          return;
        })
        .catch(error => this.errorService.handle(`Can't create facets favorite options: ${error}`));
  }
}

/**
 * Helps pass selected/unselected facet information from child to parent
 * component.
 */
export interface FacetChangeEvent {
  checked: boolean;
  id: string;
  facetGroup: FacetGroup;
  facetBucket: FacetBucket;
}

/** A selected group with the option values */
export interface SelectedFacetGroup {
  facet: string;
  facetResults: SelectedFacetResult;
}

/** A selected facet result with the buckets */
export interface SelectedFacetResult {
  bucketType: BucketType;
  buckets: SelectedFacetBucket[];
}

/** A selected facet bucket with the string values */
export interface SelectedFacetBucket {
  bucketValue: string;
  selected: boolean;
}

/** Represents the flattened facet group */
export interface FacetGroup {
  displayedName: string;
  facet: string;
  type: BucketType;
  facetBuckets: FacetBucket[];
}

/** Represents simplified facet bucket */
export interface FacetBucket {
  count: number;
  /**
   * Displayed value: simple string 'cheerleader'
   * or processed datetime string like '2020-1-1'
   */
  value: string;
  isSelected?: boolean;
  granularity?: Granularity;
  isFavorite?: boolean;
  /** Scroll width of the related checkbox element. */
  scrollWidth?: number;
}
