<div class="detail-container">
  <div class="disable-message" *ngIf="!isJobEnabled()">
    This job is disabled. It can't be run automatically via its schedule until
    the job is reenabled.
  </div>

  <h1>Run History</h1>

  <table
      mat-table
      class="runs-table"
      [trackBy]="trackByRow"
      [dataSource]="runs||[]">
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell>Status</th>
      <td mat-cell *matCellDef="let row">
        <div class="icon-with-text">
          <ng-container [ngSwitch]="row.operationState">
            <ng-container
                *ngSwitchCase="ApiCloudTransferOperationStateEnum.IN_PROGRESS">
              <mat-icon>timelapse</mat-icon>
              <span>In progress</span>
            </ng-container>
            <ng-container
                *ngSwitchCase="ApiCloudTransferOperationStateEnum.PAUSED">
              <mat-icon>pause_circle</mat-icon>
              <span>Paused</span>
            </ng-container>
            <ng-container
                *ngSwitchCase="ApiCloudTransferOperationStateEnum.SUCCEEDED">
              <mat-icon class="succeeded">check_circle</mat-icon>
              <span>Succeeded</span>
            </ng-container>
            <ng-container
                *ngSwitchCase="ApiCloudTransferOperationStateEnum.FAILED">
              <mat-icon class="error-icon">error</mat-icon>
              <span>Failed</span>
            </ng-container>
            <ng-container
                *ngSwitchCase="ApiCloudTransferOperationStateEnum.ABORTED">
              <span>Aborted</span>
            </ng-container>
            <ng-container
                *ngSwitchCase="ApiCloudTransferOperationStateEnum.QUEUED">
              <span>Queued</span>
            </ng-container>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <!-- Size Column -->
    <ng-container matColumnDef="size">
      <th *matHeaderCellDef mat-header-cell>Size</th>
      <td mat-cell *matCellDef="let row">
        {{row.size | bytes}}
      </td>
    </ng-container>

    <!-- Duration Column -->
    <ng-container matColumnDef="duration">
      <th *matHeaderCellDef mat-header-cell>Duration</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.endTime && row.startTime; else naTemplate">
          {{getDurationInSecond(row.startTime, row.endTime) | duration}}
        </span>
        <ng-template #naTemplate>-</ng-template>
      </td>
    </ng-container>

    <!-- Start Time Column -->
    <ng-container matColumnDef="startTime">
      <th *matHeaderCellDef mat-header-cell>Start time</th>
      <td mat-cell *matCellDef="let row">
        {{row.startTime | date:'MMM d, y, h:mm a'}}
      </td>
    </ng-container>

    <!-- End Time Column -->
    <ng-container matColumnDef="endTime">
      <th *matHeaderCellDef mat-header-cell>End time</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.endTime; else naTemplate">
          {{row.endTime | date:'MMM d, y, h:mm a'}}
        </span>
        <ng-template #naTemplate>-</ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        [class.error]="row.operationState === ApiCloudTransferOperationStateEnum.FAILED"
        (click)="clickOnColumn(row)">
    </tr>

  </table>

  <div *ngIf="!runs?.length" class="empty-message">
    No run history.
  </div>
</div>
