import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {checkExhaustive} from 'asserts/asserts';

import {AuthService} from '../auth/auth_service';
import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';

interface StaticView {
  title: string;
  body: string;
  image: string;
  type: StaticViewTypes;
}

enum StaticViewTypes {
  UNAUTHORIZED = 'unauthorized',
  ERROR = 'error',
  NOT_FOUND = 'not_found',
  MAINTENANCE = 'maintenance',
}

/** Indicates which type of items we are navigating between */
const viewTypes: {[type: string]: StaticView} = {
  [StaticViewTypes.UNAUTHORIZED]: {
    title: 'You currently don\'t have permission to access IAS',
    body: 'Reach out to your system administrator.',
    image: 'images/no_permission_logo.svg',
    type: StaticViewTypes.UNAUTHORIZED,
  },
  [StaticViewTypes.ERROR]: {
    title: 'Sorry. Something went wrong...',
    body: 'Please try again or reach out to your system administrator.',
    image: 'images/no_permission_logo.svg',
    type: StaticViewTypes.ERROR,
  },
  [StaticViewTypes.NOT_FOUND]: {
    title: '404 error!',
    body: 'Oops! It looks like we can’t find that page.',
    image: 'images/not_found.svg',
    type: StaticViewTypes.NOT_FOUND,
  },
  [StaticViewTypes.MAINTENANCE]: {
    title: 'IAS is currently under maintenance...',
    body: 'We expect to be back soon. Thanks for your patience.',
    image: 'images/maintenance.svg',
    type: StaticViewTypes.MAINTENANCE,
  },
};

/**
 * Static Page for: Unauthorized, Error, Not Found, Maintenance
 */
@Component({
  selector: 'mam-static',
  templateUrl: './static.ng.html',
  styleUrls: ['./static.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Static {
  currentView: StaticView;

  extraMessage = '';

  constructor(
      private readonly authService: AuthService,
      private readonly router: Router,
      private readonly route: ActivatedRoute,
      private readonly analyticsService: FirebaseAnalyticsService,
  ) {
    this.currentView = viewTypes[this.route.snapshot.data['type']] ||
        viewTypes[StaticViewTypes.ERROR];

    const page = this.route.snapshot.data['type'] || 'static';
    analyticsService.logEvent(`Navigated to ${page} page`, {
      eventType: AnalyticsEventType.NAVIGATION,
      path: `/${page}`,
      string2: `/${page}`,
    });

    // Optional message provided by the router, such as an error details.
    this.extraMessage =
        this.router.getCurrentNavigation()?.extras?.state?.['message'];
  }

  goHome() {
    switch (this.currentView.type) {
      // From 404 simply redirect to home
      case StaticViewTypes.NOT_FOUND:
        this.router.navigate(['']);
        break;
      // From an error or maintenance, reload the app in case we end up on the
      // same static page, to give a clear signal of refreshing.
      case StaticViewTypes.MAINTENANCE:
      case StaticViewTypes.ERROR:
        this.authService.reloadPage(/* resetCurrentUrl */ true);
        break;
      // For unauthorized users, log them out first then reload the app.
      case StaticViewTypes.UNAUTHORIZED:
        this.analyticsService.logEvent('automatic logout', {
          eventType: AnalyticsEventType.NAVIGATION,
        });
        this.authService.logout(/* resetCurrentUrl */ true);
        break;
      default:
        checkExhaustive(this.currentView.type);
    }
  }
}
