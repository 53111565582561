import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {Site, SiteTransferStats} from 'models';

import {environment} from '../environments/environment';
import {ErrorService} from '../error_service/error_service';

import {IasApiClient} from './api_client.module';

/** SitesApi to interact with IAS backend sites related APIs */
@Injectable({providedIn: 'root'})
export class SitesApiService {
  constructor(
      private readonly iasService: IasApiClient,
      private readonly errorService: ErrorService,
  ) {}

  /** Gets list of available sites. */
  list(pageSize: number): Observable<SitesListResponse|null> {
    try {
      return this.iasService
          .sitesList({parent: environment.mamApi.parent, pageSize})
          .pipe(
              map(resp => ({
                    sites: (resp.sites ?? []).map(s => new Site(s)),
                    nextPageToken: resp.nextPageToken
                  })),
              // Retry for > 1 hour (with backoff) since this error is not
              // recoverable without reloading the UI.
              this.errorService.retry(
                  {maxRetryAttempts: 100, scalingDuration: 1000}),
              catchError(error => {
                this.errorService.handle(error);
                return of(null);
              }),
          );
    } catch (error: unknown) {
      this.errorService.handle(error);
      return of(null);
    }
  }

  /** Gets last 24 hours stats for a given site. */
  fetchStats(site: string): Observable<SiteTransferStats|null> {
    try {
      return this.iasService
          .sitesFetchStats({
            name: `${environment.mamApi.parent}/sites/${site}`,
            timeframeTimeframeType: 'LAST_DAY',
          })
          .pipe(
              map(stats => new SiteTransferStats(stats)),
              this.errorService.retryShort(),
              catchError(error => {
                this.errorService.handle(error);
                return of(null);
              }),
          );
    } catch (error: unknown) {
      this.errorService.handle(error);
      return of(null);
    }
  }
}

interface SitesListResponse {
  sites: Site[];
  nextPageToken?: string;
}
