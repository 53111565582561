import {Pipe, PipeTransform} from '@angular/core';

import {AssetService, ClipOperationStatus} from '../services/asset_service';
import {ApiCompReelState, ApiExportStatusExportState, ApiPFRStateInfoState} from '../services/ias_types';

/**
 * A wrapper for AssetService.formatExportFolderStatus to improve performance
 * Usage:
 *   folderState | exportStatus
 * Example:
 *   {{ compreelFolderState | exportStatus }}
 *   formats to: ClipOperationStatus
 */

@Pipe({name: 'exportStatus'})
export class FolderStateToExportStatusPipe implements PipeTransform {
  constructor(private assetService: AssetService) {}
  transform(
    value: ApiCompReelState | ApiPFRStateInfoState | ApiExportStatusExportState | undefined
  ): ClipOperationStatus
  {
    return this.assetService.formatExportFolderStatus(value);
  }
}
