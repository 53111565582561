<div class="camera-view-container">
  <ng-container *ngIf="assets$ | async as assets">
    <div class="asset-results">
      <mam-asset-preview
          *ngFor="let asset of assets; let index = index; trackBy: trackAsset"
          [asset]="asset"
          [tag]="AssetPreviewTag.CAMERA_LABEL"
          (click)="closePanel.emit()"
          [routerLink]="['/asset', asset.name]"
          [queryParams]="{'type': ContextType.CAMERAS}"
          queryParamsHandling="merge">
      </mam-asset-preview>
    </div>
  </ng-container>
</div>
