import {DragDropModule} from '@angular/cdk/drag-drop';
import {TextFieldModule} from '@angular/cdk/text-field';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {FeatureFlagModule} from '../feature_flag/feature_flag_module';
import {FirebaseModule} from '../firebase/firebase_module';
import {PipesModule} from '../pipes/pipes_module';
import {PlayerModule} from '../player/player_module';
import {SharedModule} from '../shared/shared_module';

import {AssetMetadataPanel} from './asset_metadata_panel';
import {BulkClipsActions} from './bulk_clips_actions';
import {ClipBinSelection} from './clip_bin_selection';
import {CutDownDialog} from './cut_down_dialog';
import {CutDownPanel} from './cut_down_panel';
import {InsightsPanel} from './insights_panel';
import {MetadataFields} from './metadata_fields';
import {MoveClipButton} from './move_clip_button';
import {MoveClipDialog} from './move_clip_dialog';
import {PersistentPanel} from './persistent_panel';
import {PlayDescriptionDialog} from './play_description_dialog';
import {PlayFeedPanel} from './play_feed_panel';
import {StagingMetadataPanel} from './staging_metadata_panel';
import {StagingPanel} from './staging_panel';
import {StagingService} from './staging_service';

@NgModule({
  imports: [
    CommonModule,      DragDropModule,       FeatureFlagModule,
    FirebaseModule,    MatCardModule,        MatButtonModule,
    MatCheckboxModule, MatChipsModule,       MatDialogModule,
    MatDividerModule,  MatExpansionModule,   MatFormFieldModule,
    MatIconModule,     MatIconModule,        MatInputModule,
    MatMenuModule,     MatProgressBarModule, MatSelectModule,
    MatTabsModule,     MatTooltipModule,     PipesModule,
    PlayerModule,      ReactiveFormsModule,  RouterModule,
    SharedModule,      TextFieldModule,
  ],
  declarations: [
    AssetMetadataPanel,
    BulkClipsActions,
    ClipBinSelection,
    CutDownDialog,
    CutDownPanel,
    InsightsPanel,
    MetadataFields,
    MoveClipButton,
    MoveClipDialog,
    PersistentPanel,
    PlayDescriptionDialog,
    PlayFeedPanel,
    StagingMetadataPanel,
    StagingPanel,
  ],
  providers: [
    StagingService,
    { provide: Window, useValue: window },
  ],
  exports: [
    AssetMetadataPanel,
    PersistentPanel,
    PlayFeedPanel,
    MetadataFields,
    CutDownPanel,
    StagingMetadataPanel,
  ],
})
export class RightPanelModule {
}
