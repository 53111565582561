import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {FirebaseModule} from '../firebase/firebase_module';

import {Static} from './static';

@NgModule({
  declarations: [Static],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FirebaseModule,
    MatButtonModule,
    MatToolbarModule,
  ],
  exports: [Static],
})
export class StaticModule {
}
