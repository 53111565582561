import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject, QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute} from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, startWith, take, tap } from 'rxjs/operators';

import { assertTruthy } from 'asserts/asserts';
import { SharedLink } from 'models';

import {
  DetailsNavigationService,
  GET_CURRENT_URL,
} from '../details/details_navigation_service';
import { AnalyticsEventType, FirebaseAnalyticsService } from '../firebase/firebase_analytics_service';
import { BytesPipe } from '../pipes/bytes_pipe';
import { LOCATION_ORIGIN, SharedLinksService } from '../services/shared_links_service';
import { SnackBarService } from '../services/snackbar_service';
import { HomeView } from '../services/state_service';

import { IASClipBinShareLinkData, PersistedSharedLink } from './models/shared_link_clipbin.model';
import { SharedLinkClipBinService } from './services/shared_link_clipbin.service';
import { SharedLinkClipbinNavigationService } from './services/shared_link_clipbin_navigation.service';

/**
 * Page allowing to play and download a video until the link used to access it
 * expires.
 */
@Component({
    selector: 'mam-shared-clipbin',
    templateUrl: './shared_link_clipbin.ng.html',
    styleUrls: ['./shared_link_clipbin.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedLinkClipBin {
    currentSharedLinkTitleObservable$: ReplaySubject<string> = new ReplaySubject(1);
    link?: SharedLink;
    linkHash?: string | null;

    clipBinTitleObservable$: ReplaySubject<string> = new ReplaySubject(1);
    clipBin?: string | null;
    clipBinTitle?: string | null;

    rawSourceUrl? = '';
    url = new URL(this.getCurrentUrl());
    originalHash = this.url.searchParams.get('originalHash');

    clips$: ReplaySubject<PersistedSharedLink[]> = new ReplaySubject(1);
    clipList: PersistedSharedLink[] = [];

    filteredClips$: Observable<PersistedSharedLink[]>;

    currentIndex: number = 0;

    /** Used to dynamically set a `href` and trigger a download from it. */
    @ViewChild('downloadAnchor') downloadAnchor!: ElementRef<HTMLAnchorElement>;
    @ViewChild('scrollView') scrollView?: ElementRef<HTMLElement>;
    @ViewChildren('clipCard', { read: ElementRef }) clipCardList!: QueryList<ElementRef>;

    readonly sharedClipsSearchControl = new UntypedFormControl();

    constructor(
        @Inject(GET_CURRENT_URL) private readonly getCurrentUrl: () => string,
        public navigationService: SharedLinkClipbinNavigationService,
        private readonly cdr: ChangeDetectorRef,
        private readonly detailsNavigation: DetailsNavigationService,
        private readonly clipBinFirestoreService: SharedLinkClipBinService,
        private readonly sharedLinks: SharedLinksService,
        private readonly snackbar: SnackBarService,
        private readonly bytesPipe: BytesPipe,
        private activatedRoute: ActivatedRoute,
        analyticsService: FirebaseAnalyticsService,
        @Inject(LOCATION_ORIGIN) private readonly origin: string
    ) {
        analyticsService.logEvent('Visited shared clipbin', {
            eventType: AnalyticsEventType.NAVIGATION,
            path: '/shared-clipbin',
            string2: '/shared-clipbin'
        });

        this.filteredClips$ = this.sharedClipsSearchControl.valueChanges.pipe(
            startWith(''),
            map((searchText) => this.filterClipBinLinks(searchText))
        );

        this.activatedRoute.paramMap.subscribe((params) => {
            this.linkHash = params.get('linkhash');
            this.clipBin = params.get('clipbinname');
        });

        detailsNavigation.context$.pipe(take(1)).subscribe((context) => {
            cdr.markForCheck();

            if (!context.link) {
                this.snackbar.error('Video not available.');
                detailsNavigation.navigateTo404().then(() => {
                    return;
                });
            }

            this.link = context.link;
            this.rawSourceUrl = this.link?.userRenditions.renditionMap['RAW_SOURCE']?.url;
        });

        this.clipBinFirestoreService
            .retrieveIASClipBinShareLink(encodeURIComponent(this.clipBin ?? ''), this.origin, true)
            .pipe(
                tap((result: IASClipBinShareLinkData[]) => {
                    const clipWithAssets = result[0];
                    this.clipBinTitle = clipWithAssets.clipBinTitle ?? '';
                    this.clipBinTitleObservable$.next(this.clipBinTitle);
                    this.clipList = clipWithAssets.clipSharedLinks ?? [];
                    const loadClipTitle = this.clipList ? this.clipList[0].title : '';
                    this.currentSharedLinkTitleObservable$.next(this.link?.title ?? loadClipTitle);
                    this.clips$.next(this.clipList);
                    this.sharedClipsSearchControl.setValue('');
                    if (this.linkHash != '0') {
                        this.currentIndex = this.clipList.findIndex((clip) => clip.link.includes(this.linkHash ?? ''));
                    }
                }),
                filter(() => this.clipList.length > 0),
                take(1)
            )
            .subscribe(() => {
                if (this.linkHash === '0') {
                    this.detailsNavigation
                        .navigate(['/shared-clipbin', this.clipBin ?? '', 'clip', this.getFirstLink(this.clipList)], {})
                        .then((result) => {
                            this.detailsNavigation
                                .getSharedLinkContext(this.getFirstLink(this.clipList))
                                .subscribe((context) => {
                                    this.cdr.markForCheck();
                                    this.clipBinTitleObservable$.next(this.clipBinTitle ?? '');
                                    this.currentSharedLinkTitleObservable$.next(context.link?.title ?? '');
                                    this.link = context.link;
                                });
                            return result;
                        });
                }
            });
    }

    getFirstLink(clips: PersistedSharedLink[]): string {
        return clips.length > 0 ? clips[0].link : '';
    }

    navigateNext() {
        this.filteredClips$.pipe(take(1)).subscribe((clips) => {
            if (this.currentIndex < clips.length - 1) {
                this.currentIndex++;
                this.clickClipCard(this.clipList[this.currentIndex].link, this.currentIndex);
            }
        });
    }

    navigatePrevious() {
        this.filteredClips$.pipe(take(1)).subscribe(() => {
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.clickClipCard(this.clipList[this.currentIndex].link, this.currentIndex);
            }
        });
    }

    private filterClipBinLinks(searchText: string): PersistedSharedLink[] {
        if (!searchText) {
            return this.clipList;
        }
        return this.clipList.filter((clip) => clip.title.toLowerCase().includes(searchText.toLowerCase()));
    }

    /** Triggers a browser download of the video. */
    async downloadFile(rawSource = false) {
        const link = this.link;
        assertTruthy(link, 'SharedLink.downloadOriginal: Link does not exist');
        let linkName = link.name;

        if (this.originalHash) {
            const originalContext = await this.detailsNavigation
                .getSharedLinkContext(this.originalHash)
                .pipe(take(1))
                .toPromise();
            linkName = originalContext?.link ? originalContext.link.name : link.name;
        }

        // Fetch the same link again so that we get a fresh signed URL for the
        // rendition and its raw source, since it has a very short expiration time.
        this.sharedLinks.getPreview(linkName).subscribe((freshLink) => {
            const url = rawSource ? this.getRawSourceUrl(freshLink) : this.getOriginalUrl(freshLink);

            if (!url) {
                this.snackbar.error('Video cannot be downloaded.');
                return;
            }

            // Set the anchor's `href` to the newly signed original URL then click it.
            const anchor = this.downloadAnchor.nativeElement;
            anchor.href = url;
            anchor.click();

            // Download popup has been opened, clear the anchor href so that next time
            // we click on it, we don't open the obsolete href and go through this
            // freshness call again. The setTimeout do this on the next tick and
            // allows our unit tests to check the actual `href` property.
            setTimeout(() => {
                anchor.removeAttribute('href');
            });
        });
    }

    getDownloadTooltip() {
        if (!this.link?.downloadableSize || this.link.downloadableSize === '0') {
            return 'Download video';
        } else {
            const bytes = this.bytesPipe.transform(this.link.downloadableSize);
            return `Download video (${bytes})`;
        }
    }

    clickClipCard(clipLink: string, index: number) {
        this.detailsNavigation
            .navigate(['/shared-clipbin', this.clipBin ?? '', 'clip', clipLink], {})
            .then((result) => {
                this.detailsNavigation.getSharedLinkContext(clipLink).subscribe((context) => {
                    this.cdr.markForCheck();
                    this.clipBinTitleObservable$.next(this.clipBinTitle ?? '');
                    this.currentSharedLinkTitleObservable$.next(context.link?.title ?? '');
                    this.link = context.link;
                });
                return result;
            });

        this.currentIndex = index;
    }

    clearClipSearch() {
        this.sharedClipsSearchControl.setValue('');
    }

    private getOriginalUrl(link: SharedLink | null): string | undefined {
        return link?.renditions.find((r) => r.version === 'ORIGINAL')?.url;
    }

    private getRawSourceUrl(link: SharedLink | null): string | undefined {
        return link?.userRenditions.renditionMap['RAW_SOURCE']?.url;
    }

    protected readonly HomeView = HomeView;
}
