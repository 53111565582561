<div class="video-preview">
  <mam-asset-thumbnail
      *ngIf="thumbnailTime != null"
      [class.invisible]="playingLive() && playheadLeft === 0"
      [time]="thumbnailTime"
      [asset]="asset"
      (thumbnailLoad)="onThumbnailLoaded($event)"
      mam-firebase-ga="Preview seek on {{PAGE_CONTEXT_TOKEN}}"
      [mam-firebase-resource]="asset?.name"
      [mam-firebase-type]="AnalyticsEventType.MOUSE_ENTER_LEAVE">
  </mam-asset-thumbnail>
  <mam-shaka-player
      #videoPlayer
      [disableAudio]="true">
  </mam-shaka-player>
  <div
      class="playhead"
      *ngIf="playheadLeft > 0"
      [style.left.px]="playheadLeft">
  </div>
</div>
