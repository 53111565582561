import {DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, OnDestroy} from '@angular/core';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DateTime} from 'luxon';
import {Observable, ReplaySubject} from 'rxjs';
import {filter, map, switchMap, take, takeUntil} from 'rxjs/operators';

import {ApiCompReelInfo} from 'api/ias/model/models';
import {assertTruthy} from 'asserts/asserts';
import {FeatureFlagService} from 'feature_flag/feature_flag_service';
import {Folder} from 'models';


import {isErrorResponse} from '../error_service/error_response';
import {FirebaseFirestoreDataService, IASEvent, IASEventType} from '../firebase/firebase_firestore_data_service';
import {AssetService, ClipOperationStatus} from '../services/asset_service';
import {Bin, BinService} from '../services/bin.service';
import {MediaCacheService} from '../services/media_cache_service';
import {SnackBarService} from '../services/snackbar_service';
import {TimezoneService} from '../services/timezone_service';
import {UtilsService} from '../services/utils_service';

/** List of columns to render in the table. */
const ALL_COLUMNS: string[] = [
  'select',
  'folderName',
  'exportStatus',
  'fileName',
  'lastExported',
];

/**
 * Export comp reel dialog
 */
@Component({
  selector: 'mam-export-comp-reel-dialog',
  templateUrl: './export_comp_reel_dialog.ng.html',
  styleUrls: ['./export_asset_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportCompReelDialog implements OnDestroy {
  static readonly DIALOG_OPTIONS = {
    maxWidth: 1080,
    width: '60%',
  } as const;

  readonly state$:
      Observable<{folders: Folder[], compReelInfo?: ApiCompReelInfo}>;

  /**
   * The default file name has the bin name and timestamp.
   * E.g. compreel-export_bin-20220706-10_05.
   */
  readonly defaultFileName = this.getDefaultFileName();

  displayedColumns = ALL_COLUMNS;

  selectedFolder = '';

  filenameControl =
      new FormControl(this.defaultFileName, [this.filenameValidator()]);

  constructor(
      private readonly assetService: AssetService,
      private readonly binService: BinService,
      private readonly datePipe: DatePipe,
      private readonly featureFlag: FeatureFlagService,
      private readonly dataService: FirebaseFirestoreDataService,
      private readonly mediaCache: MediaCacheService,
      private readonly snackbar: SnackBarService,
      private readonly utils: UtilsService,
      private readonly timezone: TimezoneService,
      readonly dialogRef: MatDialogRef<ExportCompReelDialog>,
      @Inject(MAT_DIALOG_DATA) public data: {binName: string, binTitle: string},
  ) {
    this.state$ = this.watchCompReelState();
  }

  /**
   * Trigger the export live clip API in the folder selection for-loop.
   */
  exportCompReel() {
    assertTruthy(this.selectedFolder, 'Missing selected folder');
    this.mediaCache.state.scratchFolder$
        .pipe(
            take(1),
            switchMap(scratchFolder => {
              const scratchFolderName = scratchFolder?.name;

              const customFileName =
                  (this.featureFlag.featureOn('use-comp-reel-custom-name')) ?
                  (this.filenameControl.value ?? this.defaultFileName) :
                  undefined;

              if (this.featureFlag.featureOn('store-user-information')) {
                this.dataService.createIASEvent(
                  this.formatIASEvent(this.data.binName, this.data.binTitle, this.selectedFolder,
                    `${customFileName}.mxf` ?? '', IASEventType.COMP_REEL_EXPORT));
              }

              return this.binService.generateCompReel(
                  this.data.binName, this.selectedFolder, customFileName,
                  scratchFolderName);
            }),
            )
        .subscribe(response => {
          if (!response) {
            this.snackbar.error(
                `Failed to start comp reel export to ${this.selectedFolder}`);
            return;
          }
          this.snackbar.message(
              `Started comp reel export to ${this.selectedFolder}`);
        });
  }

  getStatus(folderName: string, compReelInfo?: ApiCompReelInfo):
      ClipOperationStatus {
    const state = compReelInfo?.compReelData?.[folderName]?.state;
    return this.assetService.formatExportFolderStatus(state);
  }

  getLastExported(folderName: string, compReelInfo?: ApiCompReelInfo): string {
    const exportTime = compReelInfo?.compReelData?.[folderName]?.updateTime;
    if (!exportTime) return '-';
    return this.datePipe.transform(exportTime, 'MMM d, y, h:mm a') || '-';
  }

  getFileName(folderName: string, compReelInfo?: ApiCompReelInfo): string
      |undefined {
    const filepath = compReelInfo?.compReelData?.[folderName]?.filepath;
    if (!filepath) return;
    return this.utils.lastPart(filepath);
  }

  getErrorMessage() {
    const filenameErrors = this.filenameControl.errors;
    return filenameErrors ? filenameErrors['invalidMsg'] : '';
  }

  trackFolder(index: number, value: Folder) {
    return value.name;
  }

  // Unsubscribe
  private readonly destroyed$ = new ReplaySubject<void>(1);

  /**
   * Based on mediacache site updates, gets the latest compreel information from
   * the current clipbin, the list of export folders and the scratch folder.
   */
  private watchCompReelState():
      Observable<{folders: Folder[], compReelInfo?: ApiCompReelInfo}> {
    return this.mediaCache.state.foldersForExport$.pipe(
        takeUntil(this.destroyed$),
        map(folders => folders.sort(
                (a, b) =>
                    a.name.localeCompare(b.name, undefined, {numeric: true}))),
        switchMap(folders => {
          return this.binService.getBin(this.data.binName)
              .pipe(
                  filter((bin): bin is Bin => !isErrorResponse(bin)),
                  map(bin => {
                    return {
                      folders,
                      compReelInfo: bin.compReelInfo,
                    };
                  }),
              );
        }));
  }

  private getDefaultFileName() {
    const today = DateTime.local().toFormat('_yMMdd_HHmm');
    return 'compreel_' +
        (this.data.binTitle + today)
            // Trim spaces from two ends of the string
            .trim()
            // Replace all spaces with underscores
            .replace(/ /g, '_')
            // Replace all hyphens with underscores
            .replace(/-/g, '_')
            // Replace consecutive underscores with a single underscore
            .replace(/_+/g, '_');
  }

  /** The given file name should be compliant with Unix AND Windows */
  private filenameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors|null => {
      const value: string = control.value;
      const reg1 = /^[^\\/:*?"<>|]+$/;  // exists characters \/:*?"<>|
      const reg2 = /^\./;                  // cannot start with dot
      const reg3 =
          /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i;  // forbidden file names

      if (!value) return null;
      if (!reg1.test(value)) {
        return {'invalidMsg': 'Special characters are not allowed.'};
      } else if (reg2.test(value)) {
        return {'invalidMsg': 'Can not start with dot (.)'};
      } else if (reg3.test(value)) {
        return {'invalidMsg': 'Can not use forbidden file name.'};
      }
      return null;
    };
  }

  private formatIASEvent(clipBinName: string, clipBinTitle: string, folderId: string, filename: string, type: string): IASEvent {

    const createTime  = new Date().toISOString();

    return { type: type,
      clipBinName: clipBinName,
      clipBinTitle: clipBinTitle,
      state: '',
      filename: filename,
      createTime: createTime,
      folderId: folderId,
      formattedCreateDate: this.timezone.formatTimeZoneStringToYyyyMMdd(createTime)
    } as IASEvent;
  }

  // Unsubscribe from pending subscriptions.
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
