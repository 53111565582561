import {Inject, Injectable, InjectionToken} from '@angular/core';

import {SearchContext} from './firebase_analytics_service';

/** Injection token for reading the current URL. */
export const GET_CURRENT_URL = new InjectionToken<() => string>(
    'Current URL', {factory: () => () => window.location.href});

// From home/home.ts
const QUERY_SEPARATOR = ';';
// From services/search_facet_service.ts
const GROUP_SEPARATOR = ';';
const GROUP_NAME_SEPARATOR = ':';
const OPTIONS_TYPE_SEPARATOR = '|';
const OPTION_SEPARATOR = ' ';

/** Message that is logged when facet expression did not parse successfully. */
export const PARSE_ERROR_MESSAGE = 'parse error';

/** Determines the active page name based on the current url. */
@Injectable()
export class PageContextResolver {
  constructor(
      @Inject(GET_CURRENT_URL) private readonly getUrl: () => string,
  ) {}

  getCurrentUrl() {
    return this.getUrl();
  }

  getActivePageName() {
    const {pathname, searchParams} = new URL(this.getUrl());
    if (searchParams.has('query')) {
      return 'search results page';
    }
    if (pathname === '/') {
      return 'landing page';
    }
    if (pathname.includes('/asset/') ||
        pathname.includes('/clipbin/')) {
      return 'details page';
    }
    if (pathname === '/error') {
      return 'error page';
    }
    if (pathname.includes('/jobs/')) {
      return 'jobs page';
    }
    if (pathname.includes('/staging/')) {
      return 'staging page';
    }
    if (pathname.includes('/live')) {
      return 'live landing page';
    }

    return 'unknown page';
  }

  /** Reads search parameters from the current url. */
  getSearchParams(): SearchContext {
    const {searchParams} = new URL(this.getUrl());
    const searchMode = searchParams.get('searchMode') ?? 'segment';

    let terms = searchParams.get('query') ?? '';
    terms = terms.replace(new RegExp(QUERY_SEPARATOR, 'g'), '; ');

    let facets = '';
    const facetParam = searchParams.get('facets');
    if (facetParam) {
      try {
        // Converts base64 encoded facet string to human-readable form. Example:
        // base64("Sport:Football Motor%20Racing|Baseball;EventLeague:NFL;")
        // becomes "Sport: Football, Motor Racing; EventLeague: NFL"
        facets =
            atob(facetParam)
                .split(GROUP_SEPARATOR)
                .filter(group => !!group)
                .map(group => {
                  const [selectedFacets] = group.split(OPTIONS_TYPE_SEPARATOR);
                  return decodeURIComponent(
                      selectedFacets
                          .replace(new RegExp(OPTION_SEPARATOR, 'g'), ', ')
                          .replace(new RegExp(GROUP_NAME_SEPARATOR), ': '));
                })
                .join('; ');
      } catch {
        // Return parse error. Original facet value can be extracted from
        // 'sourceURL' event param.
        return {terms, facets: PARSE_ERROR_MESSAGE, searchMode};
      }
    }

    return {terms, facets, searchMode};
  }
}
