import {ApiAssetCopyStats, ApiAssetCopyStatsFullCopyStats} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';


/** A UI type representing an asset copy stats */
export class AssetCopyStats implements RequiredRecursively<ApiAssetCopyStats> {
  approvedCount = this.apiStats.approvedCount ?? 0;
  completedCount = this.apiStats.completedCount ?? 0;
  errorCount = this.apiStats.errorCount ?? 0;
  totalCount = this.apiStats.totalCount ?? 0;
  full = new AssetCopyStatsFullCopyStats(this.apiStats.full ?? {});

  constructor(private apiStats: ApiAssetCopyStats = {}) {}
}

class AssetCopyStatsFullCopyStats implements
    RequiredRecursively<ApiAssetCopyStatsFullCopyStats> {
  approvedCount = this.apiStats.approvedCount ?? 0;
  completedCount = this.apiStats.completedCount ?? 0;
  errorCount = this.apiStats.errorCount ?? 0;
  totalCount = this.apiStats.totalCount ?? 0;
  completeVodAssetNames = this.apiStats.completeVodAssetNames ?? [];

  constructor(private apiStats: ApiAssetCopyStatsFullCopyStats = {}) {}
}
