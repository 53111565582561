import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {ErrorService} from '../error_service/error_service';
import {ResumableUploadService} from '../services/resumable_upload_service';

import {LocalUpload, LocalUploadStatus, LocalUploadsTrackingService, SavedUploadPayload
} from './local_uploads_tracking_service';
import {Interface} from './utils_service';


/** This is the key of the local upload payloads saved to Local Storage. */
export const MAM_LOCAL_UPLOAD = 'mam-local-upload';

const generateCompletedInstance = (id: number, resumableUploadService: ResumableUploadServiceSupplier, errorService: ErrorServiceSupplier) : LocalUpload => {
  const payload : SavedUploadPayload = {
    sessionUri: `session/34958-${id}`,
    fileName: 'a-file.mov',
    fileSize: 300,
    progress: 10,
    status: LocalUploadStatus.COMPLETED,
    errorMessage: '',
    startTime: 2_342_365
  };
  return new LocalUpload(resumableUploadService(), errorService(), new File(['foo'], 'a-file.mov', {type: 'text/plain'}), payload);
};

const generatePausedInstance = (id: number, resumableUploadService: ResumableUploadServiceSupplier, errorService: ErrorServiceSupplier) : LocalUpload => {
  const payload : SavedUploadPayload = {
    sessionUri: `session/34958-${id}`,
    fileName: 'a-file.mov',
    fileSize: 300,
    progress: 10,
    status: LocalUploadStatus.PAUSED,
    errorMessage: '',
    startTime: 2_342_365
  };
  return new LocalUpload(resumableUploadService(), errorService(), new File(['foo'], 'a-file.mov', {type: 'text/plain'}), payload);
};

const generateUploadingInstance = (id: number, resumableUploadService: ResumableUploadServiceSupplier, errorService: ErrorServiceSupplier) : LocalUpload => {
  const payload : SavedUploadPayload = {
    sessionUri: `session/34958-${id}`,
    fileName: 'a-file.mov',
    fileSize: 300,
    progress: 10,
    status: LocalUploadStatus.UPLOADING,
    errorMessage: '',
    startTime: 2_342_365
  };
  return new LocalUpload(resumableUploadService(), errorService(), new File(['foo'], 'a-file.mov', {type: 'text/plain'}), payload);
};

const generateErrorInstance = (id: number, resumableUploadService: ResumableUploadServiceSupplier, errorService: ErrorServiceSupplier) : LocalUpload => {
  const payload : SavedUploadPayload = {
    sessionUri: `session/34958-${id}`,
    fileName: 'a-file.mov',
    fileSize: 300,
    progress: 10,
    status: LocalUploadStatus.ERROR,
    errorMessage: 'Exists in Bucket',
    startTime: 2_342_365
  };
  return new LocalUpload(resumableUploadService(), errorService(), new File(['foo'], 'a-file.mov', {type: 'text/plain'}), payload);
};

/**
 * This is used to manage all uploads.
 */
@Injectable({providedIn: 'root'})
export class FakeLocalUploadsTrackingService implements Interface<LocalUploadsTrackingService> { 

  /**
   * The LocalUpload that have been created so far. They are
   * used for LocalUploadTable display.
   */
  readonly localUploads$ = new BehaviorSubject<LocalUpload[]>(this.generateMocks(
    {
      localUploadsUploading: 5, 
      localUploadsInError: 5,
      localUploadsCompleted: 5,
      localUploadsPaused: 5
    }));
  
  constructor(
    private readonly resumableUploadService: ResumableUploadService,
    private readonly errorService: ErrorService,
  ) {  }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    remove(upload: LocalUpload) {

    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    uploadFiles(selectedFiles: File[]) {
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    retryOrResumeOneUpload(selectFile: File, currentUpload: LocalUpload) {
    }

    private generateError(times: number = 0) : LocalUpload[] {
      return this.generateSequence(times).map(index => generateErrorInstance(index+1, () => this.resumableUploadService, () => this.errorService));
    }    

    private generateUploading(times: number = 0) : LocalUpload[] {
      return this.generateSequence(times).map(index => generateUploadingInstance(index+1, () => this.resumableUploadService, () => this.errorService));
    }

    private generateCompleted(times: number = 0) : LocalUpload[] {
      return this.generateSequence(times).map(index => generateCompletedInstance(index+1, () => this.resumableUploadService, () => this.errorService));
    }

    private generatePaused(times: number = 0) : LocalUpload[] {
      return this.generateSequence(times).map(index => generatePausedInstance(index+1, () => this.resumableUploadService, () => this.errorService));
    }

    private generateSequence(length: number) {
      return Array.from({ length }, (_, i) => i);
    }

    private generateMocks(options : MockOptions) : LocalUpload[] {
      const uploads : LocalUpload[] = [
        ...this.generateUploading(options?.localUploadsUploading),
        ...this.generateError(options?.localUploadsInError),
        ...this.generateCompleted(options?.localUploadsCompleted),
        ...this.generatePaused(options?.localUploadsPaused)
      ];
      return this.shuffle(uploads);
    }

    private shuffle(localUploads : LocalUpload[]) {
      const shuffledArray = localUploads.slice();  
      let currentIndex = shuffledArray.length,  randomIndex;
      
    
      // While there remain elements to shuffle.
      while (currentIndex > 0) {
    
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
    
        // And swap it with the current element.
        [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [
          shuffledArray[randomIndex], shuffledArray[currentIndex]];
      }
    
      return shuffledArray;
    }
    
}

interface MockOptions {

  localUploadsInError?: number;

  localUploadsUploading?: number;
  
  localUploadsCompleted?: number;

  localUploadsPaused?: number;

}

interface ErrorServiceSupplier {

  (): ErrorService;
}

interface ResumableUploadServiceSupplier {

  (): ResumableUploadService;
}