/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Current storage transfer job status and configurations.
 */
export interface ApiStorageTransferInfo { 
    /**
     * Current transfer job state.
     */
    jobState?: ApiStorageTransferInfo.JobStateEnum;
    /**
     * Transfer job id returned by storage transfer service.
     */
    transferJobId?: string;
}
export namespace ApiStorageTransferInfo {
    export type JobStateEnum = 'JOB_STATE_UNSPECIFIED' | 'ENABLED' | 'DISABLED' | 'DELETED';
    export const JobStateEnum = {
        JOB_STATE_UNSPECIFIED: 'JOB_STATE_UNSPECIFIED' as JobStateEnum,
        ENABLED: 'ENABLED' as JobStateEnum,
        DISABLED: 'DISABLED' as JobStateEnum,
        DELETED: 'DELETED' as JobStateEnum
    };
}


