import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {EMPTY, Observable, throwError} from 'rxjs';
import {expand, map, reduce, take} from 'rxjs/operators';

import {ApiFacetBucket, ApiFacetBucketRange, ApiFacetGroup, ApiFacetOptionsValue, ApiFacetResult, ApiSearchVideosRequest, ApiSearchVideosResponse, ApiSegment, ApiSortByOption, ApiSuggestQueriesResponse, ApiVideoSegments, GoogleTypeDateTime} from 'api/ias/model/models';
import {QuerySuggestion} from 'models';

import {environment} from '../environments/environment';

import {IasApiClient} from './api_client.module';
import {convertApiAssetToUiAsset} from './asset_api_service';
import {BucketType, BucketTypeEnum, GranularityEnum, SearchRequestType, SearchRequestTypeEnum, SuggestRequestType, SuggestRequestTypeEnum} from './ias_types';
import {FacetBucket, FacetGroup, SelectedFacetBucket, SelectedFacetGroup, SelectedFacetResult} from './search_facet_service';
import {SearchResponse, SearchSegment} from './search_service';
import {RequiredRecursively} from './utils_service';

// http://cs/f:intelligent_asset_service/transcodeworker/transcodeworker.go:493
const MAIN_RENDITION_FPS = 30;

const MAX_SEARCH_PAGE_SIZE = 500;

/** Serialization format of date-picker selection. */
export const DATE_RANGE_FORMAT = 'yyyy-MM-dd';

/**
 * SearchApiService to interact with IAS backend search API
 */
@Injectable()
export class SearchApiService {
  constructor(private readonly apiClient: IasApiClient) {}

  search(searchParams: SearchParams): Observable<SearchResponse> {
    const requestBody: ApiSearchVideosRequest = {
      pageSize: searchParams.pageSize,
      query: searchParams.query,
      pageToken: searchParams.pageToken,
      facetSelections: buildApiFacetGroups(searchParams.facetSelections),
      type: buildApiSearchRequestType(searchParams.searchMode),
      sortbyOptions: searchParams.sortOptions,
    };

    try {
      return this.apiClient
          .videosSearch({parent: environment.mamApi.parent, body: requestBody})
          .pipe(
              map(response => this.convertToUiSearchResponse(
                      response, searchParams.query)));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  /**
   * Fetches all matching results.
   *
   * Max result number limit is `1000`.
   * Rationale: Date query granularity is one day, day start/end is in UTC which
   * means that to get all live assets for 1 day in certain timezone we need to
   * query for 2 days. Max live assets per day is estimated to be 250. Proposed
   * limit is twice the expected amount of data.
   */
  searchAll(searchParams: SearchQueryParams): Observable<SearchResponse> {
    const request: SearchParams = {
      ...searchParams,
      pageToken: undefined,
      pageSize: MAX_SEARCH_PAGE_SIZE,
    };

    return this.search(request).pipe(
        expand(response => {
          if (!response.nextPageToken) return EMPTY;

          return this.search({...request, pageToken: response.nextPageToken});
        }),
        // Limit results to MAX_SEARCH_PAGE_SIZE*2 = 1000.
        take(2), reduce((r1, r2) => {
          const response: SearchResponse = {
            // Copy request information like search mode and facet groups.
            ...r1,
            // Merge results in order.
            videoSegments: [...r1.videoSegments, ...r2.videoSegments],
            // Copy page token from the latest response to signal if the are
            // more results that where left out.
            nextPageToken: r2.nextPageToken,
          };
          return response;
        }));
  }

  suggest(
      queryPrefix: string, searchMode: SearchMode, searchType: SearchType,
      resultSize: number):
      Observable<RequiredRecursively<ApiSuggestQueriesResponse>> {
    try {
      return this.apiClient
          .videosSuggestQueries({
            parent: environment.mamApi.parent,
            body: {
              queryPrefix,
              resultSize,
              type: buildApiSuggestRequestType(searchMode, searchType),
            }
          })
          .pipe(map(resp => ({
                      requestType: resp.requestType ??
                          'SUGGEST_QUERIES_REQUEST_TYPE_UNSPECIFIED',
                      suggestedQueries: (resp.suggestedQueries ??
                                         []).map(s => new QuerySuggestion(s)),
                    })));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  /** Converts to UI Search Response */
  private convertToUiSearchResponse(
      response: ApiSearchVideosResponse, query: string): SearchResponse {
    const {
      videoSegments = [],
      nextPageToken,
      facetGroups = [],
      eventId = '',
      originalRequestInfo
    } = response;
    const requestType =
        originalRequestInfo?.type ?? 'SEARCH_VIDEOS_REQUEST_TYPE_UNSPECIFIED';

    return {
      videoSegments:
          ([] as SearchSegment[])
              .concat(...videoSegments.map(
                  (apiVideoSegments) => this.convertToUiVideoSegments(
                      apiVideoSegments, query, eventId, requestType))),
      nextPageToken,
      facetGroups: ([] as FacetGroup[])
                       .concat(...facetGroups.map(
                           facetGroup => convertToUiFacetGroup(facetGroup))),
      searchMode: this.convertToUiSearchMode(requestType),
    };
  }

  /** Converts search request type to UI searchMode */
  private convertToUiSearchMode(requestType: SearchRequestType): SearchMode {
    return (requestType === SearchRequestTypeEnum.SEARCH_VIDEOS_REQUEST_TYPE_VIDEO) ?
      SearchMode.VIDEO :
      SearchMode.SEGMENT;
  }

  /** Makes up segment for video search results */
  private makeUpSegment(endTime: string): ApiSegment {
    const endTimeInSeconds = Number(endTime) / 1000;
    return {startTimestamp: '0', endTimestamp: `${endTimeInSeconds}s`};
  }

  /** Converts to UI VideoSegments */
  private convertToUiVideoSegments(
      apiVideoSegments: ApiVideoSegments, query: string, eventId: string,
      requestType: SearchRequestType): SearchSegment[] {
    const {asset = {}, segments = []} = apiVideoSegments;

    // No segments field returned for video search results, makes up segments
    const searchMode = this.convertToUiSearchMode(requestType);
    if (searchMode === SearchMode.VIDEO) {
      segments.push(this.makeUpSegment(asset.asset?.endTime ?? ''));
    }

    return segments.reduce<SearchSegment[]>((uiVideoSegments, segment) => {
      // Remove trailing 's' from the format '123.456s'.
      let startTime = Number(segment.startTimestamp?.slice(0, -1) ?? 0);
      let endTime = Number(segment.endTimestamp?.slice(0, -1) ?? 0);

      // Exclude the first and last frame of the segment. Due to frame
      // inaccuracy, these frames may not match the query: b/159482955.
      const oneFrameDuration = 1 / MAIN_RENDITION_FPS;
      startTime += oneFrameDuration;
      endTime -= oneFrameDuration;

      uiVideoSegments.push({
        asset: convertApiAssetToUiAsset(asset.asset),
        startTime,
        endTime,
        query,
        eventId,
        searchMode,
        name: `${asset.asset?.name ?? ''}:${startTime}:${endTime}`,
      });
      return uiVideoSegments;
    }, []);
  }
}

function convertToUiFacetGroup(facetGroup: ApiFacetGroup): FacetGroup[] {
  const {facet = '', displayName = '', facetResults = []} = facetGroup;

  let displayedName = displayName || facet.replace(/_/, ' ');

  // Flatten the facetResults
  // For string-type facet, there will only be one item in facetResults
  // Fro date-type facet, there could be multiple items in facetResults which
  // group by the same facet
  return facetResults.reduce<FacetGroup[]>((facetGroups, facetResult: ApiFacetResult) => {
    const {facetId = '', buckets = []} = facetResult;

    // facetId format starts as <DATE|VALUE>_*
    const type = getBucketType(facetId);

    // Only check string-type for MVP
    if (type !== BucketTypeEnum.VALUE) {
      return facetGroups;
    }

    if (type === BucketTypeEnum.DATE) {
      // date-type facetId is *ALWAYS* be formatted as
      // DATE_<granularity>_<facet> in proto
      displayedName += `By ${getGranularity(facetId)?.toLowerCase()}`;
    }

    const facetBuckets: FacetBucket[] = buckets.map((bucket) => {
      const uiFacetBucket:
          FacetBucket = {count: Number(bucket.count), value: ''};
      uiFacetBucket.isSelected = bucket.selected || false;
      if (bucket.value) {
        // After api client library, stringValue and integerValue are
        // all type string, but only one of them has actual value
        uiFacetBucket.value = bucket.value.stringValue || (bucket.value.integerValue ?? '');
      } else if (bucket.datetime) {
        uiFacetBucket.granularity = getGranularity(facetId);
        uiFacetBucket.value = parseDateTime(bucket.datetime);
      }

      return uiFacetBucket;
    });

    facetGroups.push({
      facet,
      displayedName,
      type,
      facetBuckets,
    });
    return facetGroups;
  }, []);
}

/** Helps to get BucketType from facetId. */
function getBucketType(facetId: string) {
  return [
    BucketTypeEnum.DATE,
    BucketTypeEnum.VALUE,
  ].find(type => type === facetId.split('_')[0]);
}

/** Helps to get Granularity. */
function getGranularity(facetId: string) {
  return [
    GranularityEnum.YEAR,
    GranularityEnum.MONTH,
    GranularityEnum.DAY,
  ].find(type => type === facetId.split('_')[1]);
}

/** parse GoogleTypeDateTime to UI display datetime: year-month-day */
function parseDateTime(datetime: GoogleTypeDateTime): string {
  let uiDateTime = datetime.year ? `${datetime.year}` : '';
  uiDateTime += datetime.month ? `-${datetime.month}` : '';
  uiDateTime += datetime.day ? `-${datetime.day}` : '';
  return uiDateTime;
}

function buildApiFacetGroups(facetSelections: SelectedFacetGroup[]):
    ApiFacetGroup[] {
  const apiFacetGroups = facetSelections.map(v => generateFacetGroup(v));
  return apiFacetGroups;
}

function generateFacetGroup(facetSelection: SelectedFacetGroup): ApiFacetGroup {
  return {
    facet: facetSelection['facet'],
    facetResults: generateFacetResults(
        facetSelection['facetResults'], facetSelection['facet'])
  };
}

function generateFacetResults(
    facetResults: SelectedFacetResult, facet: string): ApiFacetResult[] {
  const apiFacetResults: ApiFacetResult[] = [];
  const curtFacetResult: ApiFacetResult = {
    facetId: `${facetResults.bucketType}_${facet}`,
    buckets:
        generateFacetBuckets(facetResults.buckets, facetResults.bucketType),
    bucketType: facetResults.bucketType,
  };
  apiFacetResults.push(curtFacetResult);
  return apiFacetResults;
}

function generateFacetBuckets(
    buckets: SelectedFacetBucket[], bucketType: BucketType): ApiFacetBucket[] {
  const apiFacetBucket: ApiFacetBucket[] = [];

  switch (bucketType) {
    case BucketTypeEnum.VALUE:
      for (const bucket of buckets) {
        const curtFacetBucket: ApiFacetBucket = {
          value: generateFacetOptionValue(bucket.bucketValue),
          selected: bucket.selected
        };
        apiFacetBucket.push(curtFacetBucket);
      }
      break;

    case BucketTypeEnum.RANGE:
      // If the bucket type is range, it means that it is a date range. We
      // should extract the start date and end date from the bucket value and
      // format the start date and end date using the google time proto.
      for (const bucket of buckets) {
        const dateRangeQuery = bucket.bucketValue;
        const range = generateDateRangeBucket(dateRangeQuery);
        const curtFacetBucket: ApiFacetBucket = {range, selected: true};
        apiFacetBucket.push(curtFacetBucket);
      }
      break;

    default:
      console.error(`Bucket type not supported: ${bucketType}`);
  }

  return apiFacetBucket;
}

function generateDateRangeBucket(dateRangeQuery: string): ApiFacetBucketRange {
  const dates = dateRangeQuery.split(';');
  const startDate = toDateTime(dates[0]);
  let endDate = dates[1] ? toDateTime(dates[1]) : toDateTime(dates[0]);
  // Start date is inclusive, but end date is exclusive, so we need to add one
  // more day for end date. For example the date range "2019-12-20;2019-12-28"
  // should be passed to the backend as [2019-12-20, 2019-12-29)
  endDate = endDate.plus({day: 1});

  return {
    start: generateDateRangeValue(startDate),
    end: generateDateRangeValue(endDate)
  };
}

function toDateTime(date: string) {
  return DateTime.fromFormat(date, DATE_RANGE_FORMAT);
}

function generateDateRangeValue(date: DateTime): ApiFacetOptionsValue {
  const {year, month, day} = date;

  return {datetimeValue: {year, month, day}};
}

function generateFacetOptionValue(facetOptionValue: string):
    ApiFacetOptionsValue {
  return {stringValue: facetOptionValue};
}

function buildApiSearchRequestType(searchMode: SearchMode): SearchRequestType {
  return searchMode === SearchMode.VIDEO ?
    SearchRequestTypeEnum.SEARCH_VIDEOS_REQUEST_TYPE_VIDEO :
    SearchRequestTypeEnum.SEARCH_VIDEOS_REQUEST_TYPE_SEGMENT;
}

function buildApiSuggestRequestType(
    searchMode: SearchMode, searchType: SearchType): SuggestRequestType {
  if (searchType === SearchType.LIVE) {
    return SuggestRequestTypeEnum.SUGGEST_QUERIES_REQUEST_TYPE_LIVE_VIDEO;
  }

  if (searchMode === SearchMode.VIDEO) {
    return SuggestRequestTypeEnum.SUGGEST_QUERIES_REQUEST_TYPE_VIDEO;
  }

  return SuggestRequestTypeEnum.SUGGEST_QUERIES_REQUEST_TYPE_SEGMENT;
}

/** Options for a search request */
export interface SearchParams extends SearchQueryParams {
  pageSize: number;
  pageToken?: string;
  sortOptions?: ApiSortByOption[];
}

/** Options for a search request without control over pagination. */
export interface SearchQueryParams {
  query: string;
  facetSelections: SelectedFacetGroup[];
  searchMode: SearchMode;
  searchType: SearchType;
}

/** Represents the search mode */
export enum SearchMode {
  SEGMENT = 'segment',
  VIDEO = 'video',
}

/** Indicates if the asset is a live stream or a VOD. */
export enum SearchType {
  LIVE = 'LIVE',
  VOD = 'VOD'
}
