import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';

import {ApiLabel, ApiLabelsListLabelsResponse, ApiLabelsListLabelsWithAssetsResponse, ApiLabelWithAssetCount, ApiLabelWithAssets} from 'api/ias/model/models';
import {CompReelInfo} from 'models';

import {environment} from '../environments/environment';
import {GcmaQueryExpressions} from '../query_expressions/gcma_query_expressions';

import {IasApiClient} from './api_client.module';
import {Bin, BinWithClips, ListResponse, ListWithAssetsResponse} from './bin.service';
import {convertApiClipToUiClip} from './clip_api_service';

/** BinApiService to interact with IAS backend label related APIs */
@Injectable({providedIn: 'root'})
export class BinApiService {
  constructor(
      private readonly apiClient: IasApiClient,
      private readonly gcmaQueries: GcmaQueryExpressions,
  ) {}

  create(title: string): Observable<Bin> {
    try {
      return this.apiClient
          .labelsCreate({
            parent: environment.mamApi.parent,
            body: {
              displayName: title,
            }
          })
          .pipe(map(convertToUiBin));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  delete(name: string): Observable<null> {
    try {
      return this.apiClient.labelsDelete({name}).pipe(map(() => null));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  rename(name: string, title: string): Observable<Bin> {
    try {
      return this.apiClient
          .labelsPatch({
            name,
            body: {displayName: title},
            updateMask: 'display_name',
          })
          .pipe(map(convertToUiBin));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  listWithAssets(pageToken?: string, pageSize?: number):
      Observable<ListWithAssetsResponse> {
    try {
      return this.apiClient
          .labelsListWithAssets({
            parent: environment.mamApi.parent,
            pageToken,
            pageSize,
          })
          .pipe(map(convertToUiListWithAssetsResponse));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  getBin(name: string): Observable<Bin> {
    try {
      return this.apiClient.labelsGet({name}).pipe(
          map(convertToUiBinWithAssetCount));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  list(filters: BinListFilter, pageToken?: string, pageSize?: number):
      Observable<ListResponse> {
    let createTime = '';
    const {compReelCreateTime} = filters;
    if (compReelCreateTime) {
      // If compReelCreateTime is marked as null, API returns all comp reels.
      createTime = compReelCreateTime === 'null' ?
          this.gcmaQueries.isNot('compReelCreateTime', 'null') :
          this.gcmaQueries.is('compReelCreateTime', compReelCreateTime);
    }

    const filter = this.gcmaQueries.and([
      // The bins' comp reels are created prior to the give time.
      createTime,
      // The bins that are exported to the folders.
      filters.folders ? this.gcmaQueries.or(filters.folders) : '',
      // The bin's displayName includes text.
      filters.title ? `displayName:"${filters.title}" ` : '',
      // The bin's owner.
      filters.owner === ClipbinsOwner.ALL ?
          this.gcmaQueries.is('createdByUser', '*') :
          '',
      filters.compReelUpdateTime ?? '',
    ]);

    try {
      return this.apiClient
          .labelsList({
            parent: environment.mamApi.parent,
            filter,
            pageSize,
            pageToken,
          })
          .pipe(map(convertToUiListResponse));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  generateCompReel(
      label: string, exportFolder: string, filename?: string,
      scratchFolder?: string): Observable<Bin> {
    try {
      return this.apiClient
          .labelsGenerateCompReel({
            label,
            body: {exportFolder, filename, scratchFolder},
          })
          .pipe(map(convertToUiBin));
    } catch (error: unknown) {
      return throwError(() => error);
    }
  }

  listExportCompReels(
      userQuery: string, pageSize: number, pageToken?: string,
      date?: DateTime): Observable<ListResponse> {
    const compReelUpdateTime =
        date ? this.gcmaQueries.withinDate('compReelUpdateTime', date) : '';

    const filter: BinListFilter = {
      title: userQuery ?? '',
      compReelCreateTime: 'null',
      owner: ClipbinsOwner.ALL,
      compReelUpdateTime,
    };

    return this.list(filter, pageToken, pageSize);
  }
}

/**
 * Converts to UI ListWithAssets Response, once we sync the Label type from UI
 * with backend proto, this step can be deleted.
 */
function convertToUiListWithAssetsResponse(
    response: ApiLabelsListLabelsWithAssetsResponse): ListWithAssetsResponse {
  const {labels, nextPageToken} = response;
  return {
    binsWithAssets: (labels ?? []).map(label => convertToUiBinWithClips(label)),
    nextPageToken,
  };
}

/**
 * Converts the API Label to UI Bin type.
 */
function convertToUiBinWithClips(apiLabel: ApiLabelWithAssets): BinWithClips {
  const {label, assetCount, clips} = apiLabel;

  const binClips = (clips ?? []).map(clip => convertApiClipToUiClip(clip));
  const binsWithClips: BinWithClips = {
    ...convertToUiBin(label),
    assetCount: formatAssetCount(assetCount),
    clips: binClips.slice(0, 3),
  };

  return binsWithClips;
}

/**
 * Converts to UI List Response, once we sync the Label type from UI with
 * backend proto, this step can be deleted.
 */
function convertToUiListResponse(response: ApiLabelsListLabelsResponse):
    ListResponse {
  const {labels, nextPageToken} = response;
  return {
    bins: (labels ?? []).map(l => convertToUiBinWithAssetCount(l)),
    nextPageToken,
  };
}

/**
 * Converts the API Label to UI Bin with assetCount type.
 */
function convertToUiBinWithAssetCount(apiLabel: ApiLabelWithAssetCount): Bin {
  const {label, assetCount} = apiLabel;
  return {
    ...convertToUiBin(label),
    assetCount: formatAssetCount(assetCount),
  };
}

/**
 * Converts the API Label to UI Bin type.
 */
function convertToUiBin(apiLabel: ApiLabel = {}): Bin {
  return {
    name: apiLabel.name ?? '',
    title: apiLabel.displayName ?? '',
    createTime: Date.parse(apiLabel.createTime ?? ''),
    assetCount: '0',
    compReelInfo: new CompReelInfo(apiLabel.compReelInfo),
  };
}

/**
 * Displays the number of clips when it is defined or "100+" otherwise, see
 * http://b/177024401
 */
function formatAssetCount(assetCount = '') {
  return Number(assetCount) >= 0 ? assetCount : '100+';
}

/** Type of owner to see only the user or all clipbins. */
export enum ClipbinsOwner {
  USER = 'current_user',
  ALL = 'all_users',
}

/** List call filters. */
export interface BinListFilter {
  title?: string;
  owner?: ClipbinsOwner;
  folders?: string[];
  compReelCreateTime?: string;
  compReelUpdateTime?: string;
}
