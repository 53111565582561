<mat-card appearance="outlined" class="mam-elevation-1" [ngClass]="{'asset-width':isClipbinsAvailable && isSmallScreen}">
  <mam-seekable-preview
      [previewUrl]="(asset | bestSeekUrl | async)!"
      [preload]="'metadata'"
      [asset]="asset"
      [isRecentAsset]="isRecentAsset">
  </mam-seekable-preview>
  <section class="main-row">
    <div class="title" [attr.title]="asset.title">{{asset.title}}</div>
    <div class="duration" *ngIf="asset.duration">{{asset.duration | duration}}</div>
  </section>

  <div class="date">{{asset.eventTime | date}}</div>
</mat-card>
