import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from, interval, Observable } from 'rxjs';
import { delayWhen, mergeMap, reduce } from 'rxjs/operators';

import { SharedLink } from 'models';

import { ErrorResponse } from '../error_service/error_response';
import { ErrorService } from '../error_service/error_service';

import { makeFakeClip, makeFakeOriginal } from './asset_api_fake_service';
import { getStartTimecode, getStateInfo } from './asset_api_service';
import { Asset, Original } from './asset_service';
import { SharedLinksApiService } from './shared_links_api_service';

/** Returns the location origin. */
export const LOCATION_ORIGIN = new InjectionToken<string>(
    'Location origin', {factory: () => window.location.origin});

/** Possible number of days before a shared link expires. */
export type ExpirationDaysOption =
    SharedLinksService['expirationDaysOptions'][number];

/**
 * Provides capabilities to generate and access asset shared links.
 */
@Injectable({providedIn: 'root'})
export class SharedLinksService {
  constructor(
      private readonly sharedLinksApi: SharedLinksApiService,
      private readonly route: ActivatedRoute,
      private readonly errorService: ErrorService,
      @Inject(LOCATION_ORIGIN) private readonly origin: string,
  ) {}

  /** Possible number of days before a shared link expires. */
  readonly expirationDaysOptions = [30, 7, 1] as const;

  readonly defaultExpirationDays: ExpirationDaysOption = 30;

  createLink(asset: Asset, additionalProperties?: Record<string, string>):
      Observable<SharedLink|ErrorResponse> {
    return this.sharedLinksApi
        .create(
            asset,
            this.daysToTtl(this.defaultExpirationDays),
            additionalProperties,
            )
        .pipe(this.errorService.catchError());
  }

  createFullClipLink(asset: Asset, additionalProperties?: Record<string, string>):
      Observable<SharedLink|ErrorResponse|null> {
    return this.sharedLinksApi
        .createFullClip(
            asset,
            this.daysToTtl(this.defaultExpirationDays),
            additionalProperties,
            )
        .pipe(this.errorService.catchError());
  }

  /** Check if the clip is a full video. */
  isFullClip(endTime: number | string, duration: number | string): boolean {
    return endTime === duration;
  }

  /** Get hash of the original video link. */
  getOriginalHash(link: SharedLink) {
    const hash = this.encodeLink(link.name);
    return `?originalHash=${hash}`;
  }

  getLinkUrl(link: SharedLink) {
    const hash = this.encodeLink(link.name);
    // Location origin is such as `https://ias.google.com`
    // Shared URL is such as `https://ias.google.com/shared/HASH`
    return `${this.origin}/shared/${hash}`;
  }

  getClipBinLinkUrl(clipBinName: string) {
    // Location origin is such as `https://ias.google.com`
    // Shared CLip Bin URL is such as `https://ias.google.com/shared-clipbin/clipBinName`
    return `${this.origin}/shared-clipbin/${clipBinName}`;
  }

  getClipBinAssetLinkHash(link: SharedLink) {
    return this.encodeLink(link.name);
  }

  updateLinkTtl(linkName: string, newExpirationDays: ExpirationDaysOption):
      Observable<SharedLink|null> {
    return this.sharedLinksApi.updateExpiration(
        linkName, this.daysToTtl(newExpirationDays));
  }

  getPreviewFromHash(hash: string) {
    const linkName = this.decodeLink(hash);
    return this.getPreview(linkName);
  }

  getPreview(linkName: string) {
    return this.sharedLinksApi.preview(linkName);
  }

  search(titleQuery: string, pageSize: number, pageToken?: string) {
    return this.sharedLinksApi.list(titleQuery, pageSize, pageToken);
  }

  /**
   * Revoke all given links by making parallel requests (spaced out by 20ms to
   * cap the QPS), and returns the number of deletions that succeeded.
   */
  revokeAll(linkNames: string[]): Observable<number> {
    return from(linkNames).pipe(
        delayWhen((linkName, index) => interval(index * 20)),
        mergeMap(linkName => {
          return this.sharedLinksApi.delete(linkName);
        }),
        reduce((revokedCount, deletedLink) => {
          return revokedCount + (deletedLink != null ? 1 : 0);
        }, 0));
  }

  /** Whether the current page is a public shared link. */
  isSharedLinksPage() {
    const route = this.route.firstChild || this.route;
    return route.snapshot.data['type'] === 'shared';
  }

  /** Converts a number of days to a Time to Live in seconds. */
  private daysToTtl(days: ExpirationDaysOption): string {
    return `${days * 3600 * 24}s`;
  }

  private encodeLink(linkName: string) {
    return btoa(linkName);
  }

  private decodeLink(hash: string) {
    return atob(hash);
  }
}

/** Converts an `ApiSharedLink` to a UI-compatible `Asset` type. */
export function convertApiSharedLinkToUiAsset(link: SharedLink): Asset {
  const startOffset = Number(link.startOffset.replace('s', ''));
  const endOffset = Number(link.endOffset.replace('s', ''));

  const stateInfo = getStateInfo(link);

  // Properties used by the details component
  const playbackProperties: Partial<Original> = {
    title: link.title,
    renditions: link.renditions,
    duration: endOffset - startOffset,
    startTime: startOffset,
    endTime: endOffset,
    startTimecode: getStartTimecode(link.snippet),
    thumbnail: undefined,
    ...stateInfo,
  };

  // Make sure that the asset computed from a shared link has a `original`
  // property, so it is considered a Clip by our code.
  return link.clip ?
    makeFakeClip(playbackProperties, playbackProperties) :
    makeFakeOriginal(playbackProperties);
}
