import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {TransferTask} from 'models';

import {environment} from '../environments/environment';
import {ErrorService} from '../error_service/error_service';

import {IasApiClient} from './api_client.module';

/** Service to interact with IAS transfer tasks related APIs */
@Injectable({providedIn: 'root'})
export class TransferTaskApiService {
  constructor(
      private readonly apiClient: IasApiClient,
      private readonly errorService: ErrorService,
  ) {}

  /** Retries given task. */
  retry(taskName: string): Observable<TransferTask|null> {
    try {
      return this.apiClient.sitesTransferTasksRetry({name: taskName, body: {}})
          .pipe(
              map(task => new TransferTask(task)),
              catchError(error => {
                this.errorService.handle(error);
                return of(null);
              }),
          );
    } catch (error: unknown) {
      this.errorService.handle(error);
      return of(null);
    }
  }

  /**
   * Gets a page of transfer tasks.
   * 
   * @param site site id.
   * @param pageNumber page number, should start with 1.
   * @param pageSize number of tasks per page.
   * @param filter filter expression.
   * @param orderBy order by expression.
   */
  search(
      site: string, pageNumber: number, pageSize: number, filter?: string,
      orderBy?: string) {
    try {
      if (!pageNumber) {
        throw new Error(`Page number should start with 1, was: ${pageNumber}`);
      }
      const siteName = `${environment.mamApi.parent}/sites/${site}`;
      return this.apiClient
          .sitesTransferTasksSearch({
            parent: siteName,
            orderBy,
            filter,
            pageNumber,
            pageSize,
          })
          .pipe(
              map(resp => ({
                    transferTasks: (resp.transferTasks ??
                                    []).map(t => new TransferTask(t)),
                    totalSize: resp.totalSize ?? 0,
                  })),
              this.errorService.retryShort(),
              catchError(error => {
                this.errorService.handle(error);
                return of(null);
              }),
          );
    } catch (error: unknown) {
      this.errorService.handle(error);
      return of(null);
    }
  }
}

/**
 * Contains TransferTask property names as they are addressed in 'order by'
 * and 'filter' API queries.
 */
export enum TransferTaskPropertyName {
  FILE_NAME = 'filename',
  FILE_SIZE = 'filesize',
  TRANSFER_LAST_MODIFIED = 'transferModTime',
  TRANSFER_STATE = 'transferState',
  TRANSFER_TYPE = 'transferType',
}
