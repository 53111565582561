import {ApiStatsFetchTransferStatsResponse, ApiStatsTransferStateStat} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

/** A UI type representing Transfer stats for a site */
export class SiteTransferStats implements
    RequiredRecursively<ApiStatsFetchTransferStatsResponse> {
  folderFileCount = (this.apiStats.folderFileCount ??
                     []).map(ffc => ({
                               folder: ffc.folder ?? '',
                               fileCount: ffc.fileCount ?? '',
                             }));
  numCachefolders = this.apiStats.numCachefolders ?? 0;
  numWatchfolders = this.apiStats.numWatchfolders ?? 0;
  transferDirectionStats =
      (this.apiStats.transferDirectionStats ??
       []).map(s => ({
                 direction: s.direction ?? 'TRANSFER_DIRECTION_UNSPECIFIED',
                 transferStateStats: (s.transferStateStats ?? [])
                                       .map(s => convertTransferStateStats(s)),
               }));
  constructor(private apiStats: ApiStatsFetchTransferStatsResponse = {}) {}
}

function convertTransferStateStats(apiStat: ApiStatsTransferStateStat):
    RequiredRecursively<ApiStatsTransferStateStat> {
  return {
    transferVolumeMbytes: apiStat.transferVolumeMbytes ?? '',
    transferRateMbytes: apiStat.transferRateMbytes ?? 0,
    numTransfers: apiStat.numTransfers ?? '',
    largeFileAvgSpeed: apiStat.largeFileAvgSpeed ?? 0,
    state: apiStat.state ?? 'TRANSFER_STATE_UNSPECIFIED',
  };
}
