/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiLifecycleInfo } from './api-lifecycle-info';


/**
 * Information about a file in a watched folder.
 */
export interface ApiFile { 
    /**
     * Status of file transfer Cloud->OnPrem.
     */
    cloud2onprem?: ApiFile.Cloud2onpremEnum;
    /**
     * Absolute file path on cloud. Not always available on the standard response for a File but can be accessed by the :locate method
     */
    cloudLocation?: string;
    /**
     * Error during upload or download.
     */
    error?: string;
    /**
     * Name of the file relative to the parent folder root, potentially nested. The name is unique within its parent folder, due to how filesystem manages files. Example: \'some_media_file.mxd\' \'videos/some_media_file.mxd\'
     */
    filename?: string;
    /**
     * Size of the file in bytes.
     */
    filesize?: string;
    /**
     * Output only. Time when file was last downloaded on-prem.
     */
    readonly lastDownloadedTime?: string;
    /**
     * Timestamp of current file last seen onprem by a watcher
     */
    lastSyncTime?: string;
    lifecycleInfo?: ApiLifecycleInfo;
    /**
     * Modification time of the file.
     */
    modTime?: string;
    /**
     * Name of the file: projects/{project}/locations/{location}/sites/{site}/folders/{folder}/files/{file}
     */
    name?: string;
    /**
     * Status of file transfer OnPrem->Cloud.
     */
    onprem2cloud?: ApiFile.Onprem2cloudEnum;
    /**
     * Absolute file path on prem. Not available on the standard response for a File but can be accessed by the :locate method
     */
    onpremLocation?: string;
    /**
     * Number of bytes uploaded or downloaded.
     */
    progressBytes?: string;
    /**
     * whether file has ML annotations enabled, default false
     */
    runMl?: boolean;
    /**
     * Transfer start time.
     */
    startTime?: string;
    /**
     * Identifier of the agent performing upload or download.
     */
    transferAgentId?: string;
    /**
     * Time when transfer progress was last updated.
     */
    updateTime?: string;
}
export namespace ApiFile {
    export type Cloud2onpremEnum = 'ASSET_TRANSFER_STATE_UNSPECIFIED' | 'PENDING' | 'IN_PROGRESS' | 'COMPLETE' | 'FAILED';
    export const Cloud2onpremEnum = {
        ASSET_TRANSFER_STATE_UNSPECIFIED: 'ASSET_TRANSFER_STATE_UNSPECIFIED' as Cloud2onpremEnum,
        PENDING: 'PENDING' as Cloud2onpremEnum,
        IN_PROGRESS: 'IN_PROGRESS' as Cloud2onpremEnum,
        COMPLETE: 'COMPLETE' as Cloud2onpremEnum,
        FAILED: 'FAILED' as Cloud2onpremEnum
    };
    export type Onprem2cloudEnum = 'ASSET_TRANSFER_STATE_UNSPECIFIED' | 'PENDING' | 'IN_PROGRESS' | 'COMPLETE' | 'FAILED';
    export const Onprem2cloudEnum = {
        ASSET_TRANSFER_STATE_UNSPECIFIED: 'ASSET_TRANSFER_STATE_UNSPECIFIED' as Onprem2cloudEnum,
        PENDING: 'PENDING' as Onprem2cloudEnum,
        IN_PROGRESS: 'IN_PROGRESS' as Onprem2cloudEnum,
        COMPLETE: 'COMPLETE' as Onprem2cloudEnum,
        FAILED: 'FAILED' as Onprem2cloudEnum
    };
}


