import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {DetailsModule} from '../details/details_module';
import {FirebaseModule} from '../firebase/firebase_module';
import {PipesModule} from '../pipes/pipes_module';

import {SharedLink} from './shared_link';

@NgModule({
  imports: [
    CommonModule,
    DetailsModule,
    DetailsModule,
    FirebaseModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    PipesModule,
    RouterModule,
  ],
  declarations: [SharedLink],
})
export class SharedLinkModule {
}
