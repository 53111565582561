import {ApiExportStatus} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

import {RpcStatus} from './rpc-status';

/** A UI model representing folder specific clip export status */
export class ExportStatus implements RequiredRecursively<ApiExportStatus> {
  createTime = this.apiStatus.createTime ?? '';
  errorDetails = new RpcStatus(this.apiStatus.errorDetails);
  exportState = this.apiStatus.exportState ?? 'EXPORT_STATE_UNSPECIFIED';
  outputFilePath = this.apiStatus.outputFilePath ?? '';
  updateTime = this.apiStatus.updateTime ?? '';

  constructor(private apiStatus: ApiExportStatus = {}) {}
}
