import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';
import { StagingService } from 'right_panel/staging_service';

import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';

/** Content Staging tabs available in TransferView */
export const CONTENT_STAGING_TABS = ['live', 'vod', 'vod-error'] as const;

const TRANSFER_MONITOR_TABS = ['transfers'] as const;

const ALL_TABS = [
  ...CONTENT_STAGING_TABS,
  ...TRANSFER_MONITOR_TABS,
  'local-upload',
  'cloudIngest',
  'deleted-assets',
  'vod-export',
  'live-export',
  'comp-reel',
] as const;

/** All tabs available in transfer view.  */
export type Tab = typeof ALL_TABS[number];

enum TransferViewType {
  JOBS = 'jobs',
  STAGING = 'staging'
}

/**
 * Landing page for Transfer monitoring/content staging tab.
 */
@Component({
  selector: 'mam-transfer-landing',
  templateUrl: './transfer_landing.ng.html',
  styleUrls: ['./transfer_landing.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferLanding implements OnDestroy {
  @ViewChild('scrollView') scrollView!: ElementRef<HTMLElement>;

  /** `undefined` until the initial index is inferred from the route. */
  selectedTabIndex?: number;

  transferView = TransferViewType.JOBS;

  readonly tabs: Tab[] = [];

  readonly TransferViewType = TransferViewType;

  constructor(
      private readonly cdr: ChangeDetectorRef,
      private readonly route: ActivatedRoute,
      private readonly router: Router,
      analyticsService: FirebaseAnalyticsService,
      featureService: FeatureFlagService,
      readonly stagingService: StagingService
  ) {
    const viewType = this.route.snapshot.data['transferView'];
    if (viewType === TransferViewType.STAGING) {
      this.tabs.push(...CONTENT_STAGING_TABS);
      this.transferView = TransferViewType.STAGING;
    } else {
      this.tabs.push(...TRANSFER_MONITOR_TABS, 'local-upload');
      if (featureService.featureOn('use-cloud-ingest')) {
        this.tabs.push('cloudIngest');
      }
      this.tabs.push('deleted-assets');
      if (featureService.featureOn('use-vod-live-export-monitor')) {
        this.tabs.push('vod-export', 'live-export');
      }
      if (featureService.featureOn('use-comp-reel-export-monitor')) {
        this.tabs.push('comp-reel');
      }
    }

    analyticsService.logEvent(`Visited ${viewType} monitor`, {
      eventType: AnalyticsEventType.NAVIGATION,
      path: `/${viewType}`,
      string2: `/${viewType}`,
    });

    // Observe URL params to switch between tabs.
    this.route.paramMap
        .pipe(takeUntil(this.destroyed$), map(params => params.get('type')))
        .subscribe(type => {
          this.cdr.markForCheck();

          const index = this.tabs.indexOf(type as Tab);
          if (index < 0) {
            this.router.navigate(['/404'], {skipLocationChange: true});
            return;
          }

          this.selectedTabIndex = index;
        });
  }


  changeTab(index: number) {
    assertTruthy(
        index >= 0 && index < this.tabs.length,
        `Tab index ${index} is out of bounds.`);
    const type = this.tabs[index];
    this.router.navigate([`../${type}`], {
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });    
  }

  /** Scrolls to the top of the page */
  scrollTop() {
    this.scrollView?.nativeElement.scrollTo({top: 0, behavior: 'smooth'});
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);
}
