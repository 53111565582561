import {Inject, Injectable, InjectionToken} from '@angular/core';
import {DateTime} from 'luxon';

import {getEnvironmentParam} from '../environments/util';

/** Default timezone. */
export const DEFAULT_TIMEZONE = 'America/Los_Angeles';
const TIMEZONE_PARAM_NAME = 'tz';

/** Injection token for reading the current URL. */
export const GET_CURRENT_URL = new InjectionToken<() => string>(
    'Current URL', {factory: () => () => window.location.href});

/** Service responsible for timezone conversions. */
@Injectable({providedIn: 'root'})
export class TimezoneService {
  readonly timezone = this.getConfiguredTimezone();

  constructor(
      @Inject(GET_CURRENT_URL) private readonly getCurrentUrl: () => string) {}

  /** Converts date to pre-configured timezone. */
  convert(date: DateTime|Date): DateTime {
    if (date instanceof Date) {
      date = DateTime.fromJSDate(date);
    }
    return date.setZone(this.timezone);
  }

  /** Parse ISO string with pre-configured timezone. */
  parseFromIso(text: string): DateTime {
    return DateTime.fromISO(text, {zone: this.timezone});
  }

  /** Gets today's date at 12:00am in pre-configured timezone. */
  getTodayDate(): DateTime {
    return this.convert(DateTime.local()).startOf('day');
  }

  /**
   * Return the pre-configured timezone offset in milliseconds from GMT. For
   * instance, PST is UTC-08:00 which returns -8 hours in ms.
   */
  getTimezoneOffset() {
    return Number(this.convert(DateTime.fromMillis(0)).toFormat('Z')) *
        3_600_000;
  }

  formatTimeZoneStringToYyyyMMdd(timeZoneString: string): string {
    const dateTime = DateTime.fromISO(timeZoneString);
    return dateTime.toFormat('yyyyMMdd') ?? '';
  }

  formatTimestampToISOString(timestamp: number): string {
    const dateTime = DateTime.fromMillis(timestamp);
    return dateTime.toISO() ?? '';
  }

  private getConfiguredTimezone() {
    const timezone = getEnvironmentParam(
        TIMEZONE_PARAM_NAME, DEFAULT_TIMEZONE, this.getCurrentUrl());

    return this.isValid(timezone) ? timezone : DEFAULT_TIMEZONE;
  }

  private isValid(timezone: string) {
    const testDate = DateTime.local().setZone(timezone);
    return testDate.isValid;
  }
}
