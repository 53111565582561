import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, NgZone, OnDestroy} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AssetService} from '../services/asset_service';

/** List of processing asset path names. */
@Component({
  selector: 'mam-processing-status',
  templateUrl: './processing_status.ng.html',
  styleUrls: ['./processing_status.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger(
      'slideUp',
      [
        transition(
            ':enter',
            [style({top: 47}), animate('150ms ease-in-out', style({top: 0}))]),
        transition(
            ':leave',
            [style({top: 0}), animate('150ms ease-in-out', style({top: 47}))]),
      ])]
})
export class ProcessingStatus implements OnDestroy {
  @HostBinding('class.expanded') expanded = false;

  items: string[] = [];

  constructor(
      private readonly assetService: AssetService,
      private readonly cdr: ChangeDetectorRef,
      private readonly ngZone: NgZone,
  ) {
    // Observe periodic updates outside of NgZone so that e2e tests are not
    // stuck waiting for this observable to complete.
    this.ngZone.runOutsideAngular(() => {
      this.assetService.watchProcessing()
          .pipe(takeUntil(this.destroyed))
          .subscribe(items => {
            this.ngZone.run(() => {
              this.items = items;
              this.cdr.detectChanges();
            });
          });
    });
  }

  getHeaderMessage(itemsCount: number) {
    if (!itemsCount) return 'Import complete';
    if (itemsCount === 1) return 'Importing 1 item';
    if (itemsCount > 99) return 'Importing many items';
    return `Importing ${itemsCount} items`;
  }

  // Unsubscribe
  private readonly destroyed = new ReplaySubject<void>(1);

  // Unsubscribe from pending subscriptions.
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
