import { Injectable } from '@angular/core';

import { PlaybackAuthorizationService } from './playback-authorization.service';

@Injectable({ providedIn: 'root' })
export class FakePlaybackAuthorizationService extends PlaybackAuthorizationService {
    public constructor() {
        super();
    }

    protected override prepareForAuthorizationBatch(): Promise<void> {
        // Nothing to do.
        return Promise.resolve();
    }
    
    protected override getAuthorizedUriCore(originalUri: string): Promise<string> {
        return Promise.resolve(originalUri);
    }

    protected override hasAuthorization(): boolean {
        return true;
    }
}