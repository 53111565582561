import { Injectable, NgZone } from '@angular/core';
import { DocumentData, QueryDocumentSnapshot, QueryFieldFilterConstraint, where } from '@firebase/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth/auth_service';

import { FirebaseResolver, IASDataObject } from './firebase_resolver';

const FACETS_FAVORITE_OPTIONS_COLLECTION = 'ias-facets-favorite-options';

export enum IASEventType {
  TRANSFER = 'generic-transfer',
  VOD_CLIP_EXPORT = 'vod-clip-export',
  VOD_ORIGINAL_EXPORT = 'vod-original-export',
  LIVE_CLIP_EXPORT = 'live-clip-export',
  COMP_REEL_EXPORT = 'comp-reel-export',
  LOCAL_UPLOAD = 'local_upload',
  DELETE_ASSET = 'delete-asset',
  LOGIN = 'login',
}

export declare interface IASEvent extends IASDataObject {
  type: string
  state?: string;
  assetTitle?: string
  assetName? : string
  clipBinTitle?: string
  clipBinName?: string
  filename?: string;
  folderId? : string
}

@Injectable({ providedIn: 'root' })
export class FirebaseFirestoreDataService {
    constructor(
        private readonly firebaseResolver: FirebaseResolver,
        private readonly authService: AuthService,
        private readonly ngZone: NgZone
    ) { }

    async createIASEvent(data: IASEvent) {
      data.emailID = this.authService.getUserEmail();
      data.username = this.authService.getUserName();

      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.createFirestoreDoc('ias-event', data);
      });
    }

    async updateDocument(id: string, data: IASEvent) {
      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.updateFirestoreDoc('ias-event', id, data);
      });
    }

    retrieveIASEventsForLoginAction(emails: string[]) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('type', '==', IASEventType.LOGIN),
        where('emailID', 'in', emails),
      ];
      return this.queryIASEvent(constraints, emails.length);
    }

    retrieveDocumentForLoginAction(email: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('type', '==', IASEventType.LOGIN),
        where('emailID', '==', email),
      ];
      return this.queryDocument(constraints, 1);
    }

    retrieveIASEventForTransfer(filename: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('filename','==',filename),
        where('type','==',IASEventType.TRANSFER)
      ];
      return this.queryIASEvent(constraints,1);
    }

    retrieveIASEventForLocalUpload(filename: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('filename','==',filename),
        where('type','==',IASEventType.LOCAL_UPLOAD)
      ];
      return this.queryIASEvent(constraints,1);
    }

    retrieveIASEventForVodClipExport(assetName: string, folderId: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('assetName','==',assetName),
        where('folderId','==',folderId),
        where('type','==',IASEventType.VOD_CLIP_EXPORT)
      ];
      return this.queryIASEvent(constraints,1);
    }

    retrieveIASEventForLiveClipExport(assetName: string, folderId: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('assetName','==',assetName),
        where('folderId','==',folderId),
        where('type','==',IASEventType.LIVE_CLIP_EXPORT)
      ];
      return this.queryIASEvent(constraints,1);
    }

    retrieveIASEventForCompReelExport(filename: string, folderId: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('filename','==',filename),
        where('folderId','==',folderId),
        where('type','==',IASEventType.COMP_REEL_EXPORT)
      ];
      return this.queryIASEvent(constraints,1);
    }

    retrieveIASEventForDeleteAsset(assetName: string, assetTitle: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('assetName','==',assetName),
        where('assetTitle','==',assetTitle),
        where('type','==',IASEventType.DELETE_ASSET)
      ];
      return this.queryIASEvent(constraints,1);
    }

    private queryIASEvent(constraints: QueryFieldFilterConstraint[], limitSize: number) {
      return this.queryDocument(constraints, limitSize)
        .pipe(
          map(documents =>
            documents.map(document => {
              return document.data() as IASEvent;
            }))
        );
    }

    private queryDocument(constraints: QueryFieldFilterConstraint[], limitSize: number) {
      return this.firebaseResolver.queryCollection('ias-event', constraints, limitSize);
    }

    retriveFacetsFavoriteOptions(): Observable<QueryDocumentSnapshot[]> {
      const email = this.authService.getUserEmail();
      const constraints: QueryFieldFilterConstraint[] = [
        where('email', '==', email),
      ];
      return this.firebaseResolver.queryCollection(FACETS_FAVORITE_OPTIONS_COLLECTION, constraints, 100);
    }

    async updateFacetsFavoriteOptions(id: string, data: DocumentData) {
      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.updateFirestoreDoc(FACETS_FAVORITE_OPTIONS_COLLECTION, id, data);
      });
    }

    async createFacetsFavoriteOptions(data: DocumentData) {
      data['email'] = this.authService.getUserEmail();

      return this.ngZone.runOutsideAngular(() => {
        return this.firebaseResolver.createFirestoreDoc(FACETS_FAVORITE_OPTIONS_COLLECTION, data);
      });
    }
  }
