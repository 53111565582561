import {NgModule} from '@angular/core';

import {FeatureFlagOff} from './feature_flag_off';
import {FeatureFlagOn} from './feature_flag_on';


@NgModule({
  declarations: [
    FeatureFlagOff,
    FeatureFlagOn,
  ],
  exports: [
    FeatureFlagOff,
    FeatureFlagOn,
  ],
})
export class FeatureFlagModule {
}
