import {ApiSignedUrl} from 'api/ias/model/models';
import {RequiredRecursively} from 'services/utils_service';

/** A UI model representing a signed url */
export class SignedUrl implements RequiredRecursively<ApiSignedUrl> {
  rawUrl = this.apiSignedUrl.rawUrl ?? '';
  signedUrl = this.apiSignedUrl.signedUrl ?? '';

  constructor(private apiSignedUrl: ApiSignedUrl = {}) {}
}
