import {ApiSite} from 'api/ias/model/api-site';
import {RequiredRecursively} from 'services/utils_service';

/** A UI model for MediaCache site */
export class Site implements RequiredRecursively<ApiSite> {
  name = this.apiSite.name ?? '';
  cloudbucket = this.apiSite.cloudbucket ?? '';
  configs = (this.apiSite.configs ??
             []).map(c => ({key: c.key ?? '', value: c.value ?? ''}));
  createTime = this.apiSite.createTime ?? '';
  shortprefix = this.apiSite.shortprefix ?? '';
  siteId = this.apiSite.siteId ?? '';
  siteType = this.apiSite.siteType ?? 'SITE_TYPE_UNSPECIFIED';

  constructor(private apiSite: ApiSite = {}) {}
}
