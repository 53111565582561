import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

import {ClipbinsOwner} from '../services/bin_api.service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';

/** Dialog allowing to paste a clipbin URL for quick navigation. */
@Component({
  selector: 'mam-navigate-to-clip-bin-dialog',
  templateUrl: './navigate_to_clip_bin_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigateToClipBinDialog {
  static readonly dialogOptions = {
    hasBackdrop: true,
  };

  constructor(
      router: Router,
      snackBar: SnackBarService,
      stateService: StateService,
      readonly dialogRef: MatDialogRef<NavigateToClipBinDialog>,
  ) {
    dialogRef.afterClosed().subscribe((url?: string) => {
      // User dismissed the dialog.
      if (!url) return;

      const assetNameMatch = url.match(/asset\/([^?/]+)/);
      if (assetNameMatch) {
        const assetName = decodeURIComponent(assetNameMatch[1]);
        router.navigate(
            ['/asset', assetName],
            {queryParams: {'type': undefined}, queryParamsHandling: 'merge'});
        return;
      }

      // Get the name of the clip bin from the url and make sure any query
      // parameter is stripped out.
      const binNameMatch = url.match(/clipbin\/([^?/]+)/);

      if (!binNameMatch) {
        snackBar.error(`Malformed clipbin link: "${url}"`);
        return;
      }

      // When opening a clipbin through this dialog, make the clipbins owner
      // selector to show ALL clipbins as this one may not belong to the current
      // user.
      stateService.clipbinsOwner$.next(ClipbinsOwner.ALL);

      // Open clip if provided by URL, otherwise default to the first one of
      // the clipbin by passing "0" as the assetname.
      const clipNameMatch = url.match(/clip\/([^?/]+)/);
      const clipName =
          clipNameMatch ? decodeURIComponent(clipNameMatch[1]) : '0';

      const binName = decodeURIComponent(binNameMatch[1]);
      router.navigate(
          ['/clipbin', binName, 'clip', clipName],
          {queryParams: {'type': 'bin'}, queryParamsHandling: 'merge'});
    });
  }
}
