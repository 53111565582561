<div
    *ngFor="let field of fields"
    [hidden]="field.value == null || field.value === '' || isFieldHidden(field.name)"
    class="field">
  <h2>{{field.name | spaced}}</h2>
  <ng-container [ngSwitch]="field.type">
    <div
        *ngSwitchCase="FieldType.BYTES"
        [title]="(field.value | number) + ' bytes'">
      <span>{{field.value | bytes}}</span>
    </div>
    <div
        *ngSwitchCase="FieldType.BYTES_PER_SECOND"
        [title]="(field.value | number) + ' bytes per second'">
      <span>{{field.value | bytes}}/s</span>
    </div>
    <div *ngSwitchDefault [title]="field.value">
      <span>{{field.value}}</span>
    </div>
  </ng-container>
  <hr>
</div>
