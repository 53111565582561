/**
 * Intelligent Asset Service API (autopush)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiSuggestQueriesRequest { 
    /**
     * The prefix of a query to get query suggestions. If query_prefix is a search operator such as \"player:\", then only suggestions for that search operator will be provided. Empty query_prefix is also supported.
     */
    queryPrefix?: string;
    /**
     * The number of query suggestions to be returned. When `result_size` is too big (current max_size=30), it will return invalid argument error.
     */
    resultSize?: number;
    /**
     * Current behavior assumes TYPE_SEGMENT if TYPE_UNSPECIFIED is set.
     */
    type?: ApiSuggestQueriesRequest.TypeEnum;
}
export namespace ApiSuggestQueriesRequest {
    export type TypeEnum = 'SUGGEST_QUERIES_REQUEST_TYPE_UNSPECIFIED' | 'SUGGEST_QUERIES_REQUEST_TYPE_VIDEO' | 'SUGGEST_QUERIES_REQUEST_TYPE_SEGMENT' | 'SUGGEST_QUERIES_REQUEST_TYPE_LIVE_VIDEO';
    export const TypeEnum = {
        SUGGEST_QUERIES_REQUEST_TYPE_UNSPECIFIED: 'SUGGEST_QUERIES_REQUEST_TYPE_UNSPECIFIED' as TypeEnum,
        SUGGEST_QUERIES_REQUEST_TYPE_VIDEO: 'SUGGEST_QUERIES_REQUEST_TYPE_VIDEO' as TypeEnum,
        SUGGEST_QUERIES_REQUEST_TYPE_SEGMENT: 'SUGGEST_QUERIES_REQUEST_TYPE_SEGMENT' as TypeEnum,
        SUGGEST_QUERIES_REQUEST_TYPE_LIVE_VIDEO: 'SUGGEST_QUERIES_REQUEST_TYPE_LIVE_VIDEO' as TypeEnum
    };
}


