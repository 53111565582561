import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {take} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';
import {SharedLink as SharedLinkModel} from 'models';

import {DetailsNavigationService, GET_CURRENT_URL} from '../details/details_navigation_service';
import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {BytesPipe} from '../pipes/bytes_pipe';
import {SharedLinksService} from '../services/shared_links_service';
import {SnackBarService} from '../services/snackbar_service';

/**
 * Page allowing to play and download a video until the link used to access it
 * expires.
 */
@Component({
  selector: 'mam-shared-link',
  templateUrl: './shared_link.ng.html',
  styleUrls: ['./shared_link.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedLink {
  link?: SharedLinkModel;
  rawSourceUrl? = '';
  url = new URL(this.getCurrentUrl());
  originalHash = this.url.searchParams.get("originalHash");

  /** Used to dynamically set a `href` and trigger a download from it. */
  @ViewChild('downloadAnchor') downloadAnchor!: ElementRef<HTMLAnchorElement>;

  constructor(
      @Inject(GET_CURRENT_URL) private readonly getCurrentUrl: () => string,
      cdr: ChangeDetectorRef,
      private readonly detailsNavigation: DetailsNavigationService,
      private readonly sharedLinks: SharedLinksService,
      private readonly snackbar: SnackBarService,
      private readonly bytesPipe: BytesPipe,
      analyticsService: FirebaseAnalyticsService,
  ) {
    analyticsService.logEvent('Visited shared link', {
      eventType: AnalyticsEventType.NAVIGATION,
      path: '/shared',
      string2: '/shared',
    });

    detailsNavigation.context$.pipe(take(1)).subscribe(context => {
      cdr.markForCheck();
      if (!context.link) {
        this.snackbar.error('Video not available.');
        detailsNavigation.navigateTo404();
        return;
      }

      this.link = context.link;
      this.rawSourceUrl =
          this.link?.userRenditions.renditionMap['RAW_SOURCE']?.url;
    });
  }

  /** Triggers a browser download of the video. */
  async downloadFile(rawSource = false) {
    const link = this.link;
    assertTruthy(link, 'SharedLink.downloadOriginal: Link does not exist');
    let linkName = link.name;

    if (this.originalHash) {
      const originalContext = await this.detailsNavigation.getSharedLinkContext(this.originalHash).pipe(take(1)).toPromise();
      linkName = originalContext?.link ? originalContext.link.name : link.name;
    }

    // Fetch the same link again so that we get a fresh signed URL for the
    // rendition and its raw source, since it has a very short expiration time.
    this.sharedLinks.getPreview(linkName).subscribe(freshLink => {
      const url = rawSource ? this.getRawSourceUrl(freshLink) :
                              this.getOriginalUrl(freshLink);

      if (!url) {
        this.snackbar.error('Video cannot be downloaded.');
        return;
      }

      // Set the anchor's `href` to the newly signed original URL then click it.
      const anchor = this.downloadAnchor.nativeElement;
      anchor.href = url;
      anchor.click();

      // Download popup has been opened, clear the anchor href so that next time
      // we click on it, we don't open the obsolete href and go through this
      // freshness call again. The setTimeout do this on the next tick and
      // allows our unit tests to check the actual `href` property.
      setTimeout(() => {
        anchor.removeAttribute('href');
      });
    });
  }

  getDownloadTooltip() {
    if (!this.link?.downloadableSize || this.link.downloadableSize === '0') {
      return 'Download video';
    } else {
      const bytes = this.bytesPipe.transform(this.link.downloadableSize);
      return `Download video (${bytes})`;
    }
  }

  private getOriginalUrl(link: SharedLinkModel|null): string|undefined {
    return link?.renditions.find(r => r.version === 'ORIGINAL')?.url;
  }

  private getRawSourceUrl(link: SharedLinkModel|null): string|undefined {
    return link?.userRenditions.renditionMap['RAW_SOURCE']?.url;
  }
}
