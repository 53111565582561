import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {firstValueFrom, merge, Observable, ReplaySubject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {checkExhaustive} from 'asserts/asserts';

import {isErrorResponse} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {StatusCode} from '../error_service/status_code';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {Asset, AssetService, AssetState, Original} from '../services/asset_service';
import {PubsubApiService} from '../services/pubsub_api_service';
import {MetadataSchema} from '../services/schema_api_service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';
import {BatchOperationService} from '../shared/batch_operation_service';
import {SyncMetadataDialog, SyncMetadataDialogInputData, SyncMetadataDialogOutputData} from '../shared/sync_metadata_dialog';

import {MetadataService, SupportItemTypes} from './metadata_service';

/**
 * Asset states for which metadata editing is disabled due to potential
 * conflicts with changes done by EVS.
 */
const EDIT_DISABLED_ASSET_STATES =
    new Set([AssetState.SCHEDULED, AssetState.PENDING, AssetState.AIRING]);

const VIDEO_FORMAT_FIELDS = [
  'audioChannelCount',
  'heightPixels',
  'widthPixels',
  'frameRate',
  'pixelFormat',
  'colorSpace',
] as const;

/**
 * Side panel listing an asset's metadata properties.
 */
@Component({
  selector: 'mam-asset-metadata-panel',
  templateUrl: './asset_metadata_panel.ng.html',
  styleUrls: ['./asset_metadata_panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetMetadataPanel implements OnDestroy, OnInit {
  @Input()
  set assets(assets: Asset[]) {
    this.assetsInternal = assets;
    this.metadataService.setItems(
        {items: assets, type: SupportItemTypes.ASSET});
  }
  get assets() {
    return this.assetsInternal;
  }

  /** When true, an embedded player is visible above the metadata fields. */
  @Input() playerVisible = false;

  /** When true, a schema selector dropdown is displayed. */
  @Input() schemaSelection = false;

  /** When true, a sync metadata button are displayed. */
  @Input() showSyncButton = false;

  /** Optional actions displayed below the metadata fields.  */
  @Input() extraActions?: MetadataAction[];

  /**
   * Emits when the panel is closed. A close button is only shown if there
   * is a listener to this event.
   */
  @Output() readonly closePanel = new EventEmitter<void>();

  /** Emits when a provided extra action is clicked.  */
  @Output() readonly extraActionTriggered = new EventEmitter<MetadataAction>();

  /** Display label for the asset's location status. */
  assetLocation = '';

  /** Display label for the raw asset's location status. */
  rawAssetLocation = '';

  /** List of all available schemas. */
  schemas: MetadataSchema[] = [];

  signer = (rawUrls: string[]) =>
      this.batchOperationService.batchSignUrls(rawUrls);

  /** Emits whether a dialog is currently open. */
  isAnyDialogOpen$: Observable<boolean>;

  readonly videoFormatFields = VIDEO_FORMAT_FIELDS;

  videoFormatExpanded = false;

  originalAsset: Original | null = null;

  constructor(
      private readonly assetService: AssetService,
      private readonly batchOperationService: BatchOperationService,
      private readonly dialog: MatDialog,
      private readonly cdr: ChangeDetectorRef,
      private readonly snackBar: SnackBarService,
      private readonly errorService: ErrorService,
      private readonly pubsubApiService: PubsubApiService,
      private readonly analyticsService: FirebaseAnalyticsService,
      readonly metadataService: MetadataService,
      readonly stateService: StateService,
      readonly featureFlag: FeatureFlagService,
        ) {
    this.isAnyDialogOpen$ =
        merge(
            this.dialog.afterOpened.pipe(map(() => true)),
            this.dialog.afterAllClosed.pipe(map(() => false)),
            )
            .pipe(takeUntil(this.destroyed$));
  }

  ngOnInit() {
    if (!this.schemaSelection) {
      this.schemas = [];
      return;
    }

    this.assetService.listFilteredSchemas('asset_or_undefined')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(schemas => {
          this.cdr.markForCheck();
          if (schemas == null) {
            this.snackBar.error('Failed to load schemas');
            this.schemas = [];
            return;
          }
          this.schemas = schemas;
        });
  }

  onPlayerError(asset: Asset, message: string) {
    // Don't report expected error for assets with no renditions yet.
    if (!asset.renditions.length) return;
    this.errorService.handle('Metadata embedded player error: ' + message);
  }

  formatSchemaTitle(schema?: MetadataSchema) {
    if (!schema) return 'No schema selected';
    return schema.title;
  }

  formatSchemaName(schema?: MetadataSchema) {
    return this.assetService.formatSchemaName(schema?.name);
  }

  compareSchemas(schema1: MetadataSchema, schema2: MetadataSchema) {
    return schema1?.name === schema2?.name;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.metadataService.resetState();
  }

  getSingleAsset(assets: Asset[]): Asset|undefined {
    if (assets.length === 1) return assets[0];
    return undefined;
  }

  generateResourceKey(assets: Asset[]) {
    return assets.map(a => a.name).join('');
  }

  async openSyncMetadataDialog(asset: Asset) {
    const data = await firstValueFrom(
        this.dialog
            .open<
                SyncMetadataDialog, SyncMetadataDialogInputData,
                SyncMetadataDialogOutputData>(
                SyncMetadataDialog, SyncMetadataDialog.getDialogOptions({
                  title: 'Sync metadata',
                }))
            .afterClosed());
    if (!data) return;

    const response =
        await firstValueFrom(this.pubsubApiService
                                 .publish({
                                   'assetName': asset.name,
                                   'schemaName': data.schemaName,
                                   'foxSportsEventUri': data.foxSportsEventUri,
                                 })
                                 .pipe(this.errorService.catchError()));

    if (isErrorResponse(response)) {
      let message = 'Unexpected error';
      if (response.status === StatusCode.FORBIDDEN) {
        message = `You don't have permission`;
      }
      this.snackBar.error({
        message,
        details: response.message,
        doNotLog: true,
      });
      return;
    }

    const eventParam = {
      eventType: AnalyticsEventType.PUBSUB_MESSAGE,
      resource: response,
    };
    this.analyticsService.logEvent('Pubsub Metadata Sync', eventParam);
    this.snackBar.message(
        'Synchronization request sent, the asset should be updated shortly.');
  }

  /**
   * Metadata editing is disabled for the not-ended live assets because it may
   * conflict with metadata changes done by EVS. Editing is disabled for Live
   * approved assets because the asset is now being cut down.
   */
  isEditDisabled(assets: Asset[]) {
    if (!assets.length) return true;
    return assets.some(asset => {
      if (!asset.isLive) return false;
      return EDIT_DISABLED_ASSET_STATES.has(asset.state) ||
          (asset?.original ?? asset).approved;
    });
  }

  getVideoFormatValue(asset: Asset, field: typeof VIDEO_FORMAT_FIELDS[number]) {
    if (asset.original) {
      this.originalAsset = asset.original;
    }
    const original = asset.original || asset;
    const videoFormat = original.videoFormat;

    switch (field) {
      case 'audioChannelCount':
        return videoFormat.audioChannelCount;
      case 'widthPixels':
        return videoFormat.widthPixels;
      case 'heightPixels':
        return videoFormat.heightPixels;
      case 'frameRate':
        return videoFormat.frameRate;
      case 'colorSpace':
        return videoFormat.colorSpace;
      case 'pixelFormat':
        return videoFormat.pixelFormat;
      default:
        checkExhaustive(field);
    }
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);
  private assetsInternal: Asset[] = [];
}

/** Optional extra action displayed as a text and a button. */
export interface MetadataAction {
  id: string;
  label: string;
  text: string;
  description: string;
  icon: string;
}
