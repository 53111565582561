import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {delay, tap} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';
import {SharedLink} from 'models';

import {Asset} from '../services/asset_service';

import {makeFakePage, pseudoRandom} from './fake_api_utils';
import {SharedLinksApiService} from './shared_links_api_service';
import {Interface} from './utils_service';

/** Fake implementation of SharedLinksApiService. */
@Injectable({providedIn: 'root'})
export class FakeSharedLinksApiService implements
    Interface<SharedLinksApiService> {
  preview() {
    // Previewing a shared video is not implemented with the fake backend.
    return of(null).pipe(delay(500));
  }

  list(titleQuery: string, pageSize: number, pageToken?: string) {
    const matches = this.linksDb.filter(
        l => l.title.toLowerCase().includes(titleQuery.trim().toLowerCase()));
    const page = makeFakePage(matches, pageSize, pageToken);
    return of({
             sharedLinks: page.pageOfItems,
             nextPageToken: page.nextPageToken,
           })
        .pipe(delay(500));
  }

  create(asset: Asset, ttl: string) {
    const rand = pseudoRandom(
        `${asset.name}|${asset.title}|${ttl}|${this.linksDb.length}`);

    const sharedLink = new SharedLink({
      name: `fake-link-${rand}`,
      title: asset.title,
      asset: asset.name,
      downloadable: asset.original ? false : true,
      downloadableSize: String(Math.floor(rand * 1000 ** 3)),
      ttl,
    });

    return of(sharedLink)
        .pipe(
            tap(() => {
              this.linksDb.push(sharedLink);
            }),
            delay(500));
  }

  createFullClip(asset: Asset, ttl: string) {
    const rand = pseudoRandom(
        `${asset.original?.name}|${asset.title}|${ttl}|${this.linksDb.length}`);

    const sharedLink = new SharedLink({
      name: `fake-link-${rand}`,
      title: asset.title,
      asset: asset.original?.name,
      downloadable: true,
      downloadableSize: String(Math.floor(rand * 1000 ** 3)),
      ttl,
    });

    return of(sharedLink)
        .pipe(
            tap(() => {
              this.linksDb.push(sharedLink);
            }),
            delay(500));
  }

  updateExpiration(linkName: string, ttl: string) {
    const linkIndex = this.linksDb.findIndex(l => l.name === linkName);
    const link = this.linksDb[linkIndex];
    assertTruthy(link);
    const updatedLink = new SharedLink({
      name: link.name,
      title: link.title,
      asset: link.asset,
      downloadable: link.downloadable,
      downloadableSize: link.downloadableSize,
      ttl,
    });

    return of(updatedLink)
        .pipe(
            tap(() => {
              this.linksDb.splice(linkIndex, 1, updatedLink);
            }),
            delay(500));
  }

  signUrls() {
    return of(null);
  }

  delete(linkName: string) {
    const linkIndex = this.linksDb.findIndex(l => l.name === linkName);
    const link = this.linksDb[linkIndex];
    assertTruthy(link);

    return of(link).pipe(
        tap(() => {
          this.linksDb.splice(linkIndex, 1);
        }),
        delay(500));
  }

  private readonly linksDb: SharedLink[] = [];
}
