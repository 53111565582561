<mat-form-field class="mam-datepicker-outline date-picker__input--align" appearance="fill" subscriptSizing="dynamic">

  <mat-label class="datapicker__label--wrapper">Date range</mat-label>

  <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate" class="datapicker__input--wrapper">
    <input
        matStartDate
        (dateChange)="onRangeChange()"
        placeholder="Start Date"
        [formControl]="startDateControl">
    <input
        matEndDate
        (dateChange)="onRangeChange()"
        placeholder="End Date"
        [formControl]="endDateControl">
  </mat-date-range-input>

  <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>

  <mat-date-range-picker #picker>
    <mat-date-range-picker-actions>
      <button
          mat-button
          (click)="clearDateRange()"
          mam-firebase-ga="Clear date range">
        Clear
      </button>

      <button mat-button
          matDateRangePickerCancel
          mam-firebase-ga="Cancel date picker selection">
        Cancel
      </button>

      <button mat-raised-button color="primary"
          matDateRangePickerApply
          mam-firebase-ga="Filter search results by date picker">
        Apply
      </button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
</mat-form-field>
