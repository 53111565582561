<article class="skeleton"
	[class.skeleton--list]="display === 'list'">
	<div class="card-header-image">
		<img width="100%" height="100%" src="images/empty_bin.svg" alt="Empty Clip Bin" />
	</div>
	<div class="card-content">
		<div class="card-content-width">
			<div class="card-content-title"></div>
			<div class="card-content-subtitle"></div>
		</div>
	</div>
</article>
