<h1 mat-dialog-title class="dialog__title--size">New Clip Bin</h1>
<div mat-dialog-content>
  <mat-form-field class="dialog__input--spacing" appearance="outline" subscriptSizing="dynamic">
    <input
        placeholder="Name"
        matInput
        cdkFocusInitial
        #input
        autocomplete="off"
        (keyup.enter)="dialogRef.close(input.value)">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
      mam-firebase-ga="Cancel create bin dialog"
      mat-button
      mat-dialog-close>
    Cancel
  </button>

  <button
      mat-button
      mam-firebase-ga="Confirm create bin dialog"
      [mat-dialog-close]="input.value"
      [disabled]="!input.value.trim()"
      class="primary"
      #primary>
    Create
  </button>
</div>
