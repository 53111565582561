import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, ReplaySubject} from 'rxjs';
import {debounceTime, delay, filter, map, shareReplay, startWith, switchMap, takeUntil, throttleTime} from 'rxjs/operators';

import {assertTruthy, castExists} from '../asserts/asserts';
import {isErrorResponse} from '../error_service/error_response';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {ClipMarking} from '../services/asset_service';
import {DeviceInputService} from '../services/device_input_service';
import {DialogService} from '../services/dialog_service';
import {DisplaySegment, PlayFeedService} from '../services/play_feed_service';
import {SnackBarService} from '../services/snackbar_service';
import {ShortcutEvent, ShortcutEvents, StateService} from '../services/state_service';
import {UtilsService} from '../services/utils_service';
import {AddClipDialog, AddClipDialogInputData, AddClipDialogOutputData} from '../shared/add_clip_dialog';

import {PlayDescriptionDialog} from './play_description_dialog';

/** How long to disable auto-scroll after the last manual mousewheel event. */
const MANUAL_SCROLL_DURATION_MS = 6000;

/**
 * Time interval to throttle player updates while it is playing, which are sent
 * at very high frequency. 250ms will limit receipts to 4 per second.
 */
const PLAYING_TIME_THROTTLE_MS = 250;

/** CSS class to highlight all search matches. */
const SEARCH_MATCH_CSS_CLASS = 'play-feed-row';

/** CSS class to highlight active search match. */
const ACTIVE_SEARCH_MATCH_CSS_CLASS = 'current';

/**
 * Time interval to delay search execution by to reduce the chance of UI freeze
 * (cause by DOM updated) while user is still typing.
 */
const SEARCH_DEBOUNCE_MS = 300;

/**
 * Component for Play by Play feed.
 */
@Component({
  selector: 'mam-play-feed-panel',
  templateUrl: './play_feed_panel.ng.html',
  styleUrls: ['./play_feed_panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayFeedPanel implements OnDestroy, AfterViewInit {
  @ViewChild('searchBar') searchBarEl!: ElementRef;
  @ViewChild('scrollView') scrollView?: ElementRef<HTMLElement>;

  readonly displaySegments$: Observable<DisplaySegment[]>;

  /** Index of the currently highlighted search match. */
  activeSearchMatchIndex = -1;

  /** Total number of search matches. */
  searchMatchTotal = 0;

  readonly searchInputControl = new UntypedFormControl();

  /** Flag to show refresh button. */
  readonly showRefreshButton =
      this.featureService.featureOn('play-feed-improvements');

  constructor(
      readonly stateService: StateService,
      private readonly playFeedService: PlayFeedService,
      private readonly dialog: MatDialog,
      private readonly utils: UtilsService,
      private readonly cdr: ChangeDetectorRef,
      private readonly dialogService: DialogService,
      private readonly snackBar: SnackBarService,
      private readonly featureService: FeatureFlagService,
      private readonly deviceInputType: DeviceInputService,
  ) {
    // Trigger service segments loading when the state asset is changed while
    // this component is displayed (no need to otherwise). If the panel is
    // opened with the same asset than last time, the segments will be restored.
    this.stateService.currentAsset$.pipe(takeUntil(this.destroyed$))
        .subscribe(asset => {
          this.clearScrollState();
          playFeedService.currentAsset$.next(asset);
        });

    const searchChanged$ = this.searchInputControl.valueChanges.pipe(
        // startWith will ignore debounce due to operator order.
        debounceTime(SEARCH_DEBOUNCE_MS), startWith(''),
        map((value: string|undefined) => value ?? ''));

    // Re-read clean display segments any time search changes and mark search
    // matches.
    const segmentsWithSearchMatches$ =
        searchChanged$.pipe(switchMap(searchInput => {
          return playFeedService.displaySegments$.pipe(
              map(segments => this.createSegmentsWithSearchMatches(
                      searchInput, segments)),
              shareReplay({bufferSize: 1, refCount: true}));
        }));

    // Clear active search match when there are no matches. As a side effect
    // this will clear active match when asset changes as the service will send
    // empty segments.
    segmentsWithSearchMatches$.pipe(takeUntil(this.destroyed$))
        .subscribe(({matchTotal}) => {
          this.searchMatchTotal = matchTotal;
          if (!matchTotal) {
            this.activeSearchMatchIndex = -1;
            return;
          }

          if (this.activeSearchMatchIndex < 0) {
            this.activeSearchMatchIndex = 0;
          }
        });

    this.displaySegments$ =
        segmentsWithSearchMatches$.pipe(map(({segments}) => segments));

    // Delay the logic that relies on segments being rendered until DOM is
    // updated.
    const delayedDisplaySegments$ = this.displaySegments$.pipe(delay(0));

    delayedDisplaySegments$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.markActiveSearchMatchAndScrollToIt(this.activeSearchMatchIndex);
    });

    this.hookCurrentHighlight(delayedDisplaySegments$);
  }

  ngAfterViewInit() {
    //Subscribe to relevant shortcut events
    this.stateService.shortcutEvent$.pipe(takeUntil(this.destroyed$)).subscribe((e: ShortcutEvent | null) => {
      if (e?.intent === ShortcutEvents.CREATE_CLIP_SEGMENT) {
        this.createSegment();
      }
    });
  }

  focusSearchInput() {
    this.searchBarEl?.nativeElement.focus();
  }

  onSearchKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.nextSearchMatch();
    }
  }

  clearSearch() {
    this.searchInputControl.reset('');
  }

  nextSearchMatch() {
    this.changeActiveSearchMatch(1);
  }

  prevSearchMatch() {
    this.changeActiveSearchMatch(-1);
  }

  openDescriptionDialog(segment: DisplaySegment) {
    this.dialog.open(
        PlayDescriptionDialog,
        PlayDescriptionDialog.getDialogOptions({segment, action: 'display'}));
  }

  createSegment() {
    const marking = this.stateService.clipMarking$.value;
    assertTruthy(marking, 'PlayFeed.createdSegment called without marking');

    this.dialog.open(
        PlayDescriptionDialog, PlayDescriptionDialog.getDialogOptions({
          startOffset: marking.markIn,
          endOffset: marking.markOut,
          action: 'create',
        }));
  }

  async deleteSegment(segment: DisplaySegment) {
    const confirmed = await firstValueFrom(this.dialogService.showConfirmation({
      title: 'Delete play description',
      question: 'Play description will be deleted. Proceed?',
      primaryButtonText: 'Proceed',
    }));

    if (!confirmed) return;

    const result =
        await firstValueFrom(this.playFeedService.delete(segment.name));

    if (isErrorResponse(result)) {
      this.snackBar.error({
        message: 'Failed to delete play description',
        details: result?.message,
      });
      return;
    }

    this.snackBar.message('Successfully deleted play description');
  }

  async openAddClipDialog(segment: DisplaySegment) {
    const asset = castExists(
        this.stateService.currentAsset$.value,
        'PlayFeedPanel->openAddClipDialog: state should contain current asset.');

    const clipMarking: ClipMarking = {
      markIn: segment.startOffset,
      markOut: segment.endOffset,
    };

    this.dialog
        .open<AddClipDialog, AddClipDialogInputData, AddClipDialogOutputData>(
            AddClipDialog,
            AddClipDialog.getDialogOptions(
                {asset, clipMarking, title: segment.description},'550px'));
  }

  trackBySegment(index: number, segment: DisplaySegment) {
    return segment.name;
  }

  handleClick(options: ClickOptions) {
    this.updatePlayerTime(options.segmentStartOffset);
    this.scrollToMain();
  }

  updatePlayerTime(time: number) {
    this.stateService.playFeedClickTime$.next(time);
  }

  public scrollToMain(){
    const mainPage = document.querySelector("main");

    if(mainPage){
      mainPage.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }

  isCurrent(segment: DisplaySegment) {
    return segment.name === this.currentSegmentName;
  }

  isClosest(segment: DisplaySegment) {
    return segment.name === this.closestSegmentName;
  }

  /**
   * When the user manually scrolls the feed (mousewheel event), temporarily
   * skip auto-scroll for a few seconds.
   */
  onMouseWheel() {
    this.isManuallyScrolling$.next(true);
    clearTimeout(this.manualScrollTimer);
    this.manualScrollTimer = window.setTimeout(() => {
      this.isManuallyScrolling$.next(false);
    }, MANUAL_SCROLL_DURATION_MS);
  }

  // Unsubscribe from pending subscriptions.
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);

  /**
   * The current segment is highlighted in the view, the player time is
   * within its start and end.
   */
  private currentSegmentName = '';

  /**
   * The closest segment is used for auto-scroll and is equal to the current
   * segment when there is one, otherwise the closest segment from the
   * current time.
   */
  private closestSegmentName = '';

  /**
   * Whether to ignore auto-scroll of the play feed, used when the user manually
   * scrolls the feed while the player is playing.
   */
  private readonly isManuallyScrolling$ = new BehaviorSubject(false);

  private manualScrollTimer = -1;

  private clearScrollState() {
    this.currentSegmentName = '';
    this.closestSegmentName = '';
    this.isManuallyScrolling$.next(false);
  }

  /**
   * Emits any time update from the player when it is paused, and a lower
   * frequency number of updates while it is playing.
   */
  private throttlePlayerUpdates() {
    // Consume all updates when the player is paused, which happen when the
    // timeline is clicked.
    const updatesPaused$ =
        this.stateService.playerUpdate$.pipe(filter(event => event.paused));

    // Throttle updates when the player is playing, as we do not need such
    // a high frequency rate, which could affect performances.
    const updatesPlaying$ = this.stateService.playerUpdate$.pipe(
        filter(event => !event.paused), throttleTime(PLAYING_TIME_THROTTLE_MS));

    return merge(updatesPaused$, updatesPlaying$);
  }

  /**
   * This hook ensures that the current segment (within which the player
   * current time is) is highlighted. It also automatically scrolls the feed to
   * the closest segment from the player time, which is:
   *  - The current segment when there is one
   *  - The first one when the time is in a gap between 2 segments
   */
  private hookCurrentHighlight(segments$: Observable<DisplaySegment[]>) {
    const throttledPlayerUpdates$ = this.throttlePlayerUpdates();

    // Disable manual scrolling lock when player is paused, so that we can click
    // the timeline and auto-scroll the matching segment right after a manual
    // scroll.
    throttledPlayerUpdates$.pipe(takeUntil(this.destroyed$))
        .subscribe(update => {
          if (update.paused) {
            this.isManuallyScrolling$.next(false);
          }
        });

    // Update current segment and auto-scroll (when applicable) whenever the
    // player is updated, the list of segments is updated, or the manual scroll
    // lock is changed.
    combineLatest(
        [throttledPlayerUpdates$, segments$, this.isManuallyScrolling$])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(([update, segments, isManuallyScrolling]) => {
          this.cdr.markForCheck();
          const time = update.time;
          const previousClosest = this.closestSegmentName;
          this.currentSegmentName = '';
          this.closestSegmentName = '';

          if (time == null || !segments.length) return;

          // Browse segments in reverse order since in that order, the first one
          // starting before the player time will be the "closest" segment.
          for (let i = segments.length - 1; i >= 0; i--) {
            const segment = segments[i];

            // Assign current segment by exact match
            if (time >= segment.startOffset && time < segment.endOffset) {
              this.currentSegmentName = segment.name;
            }

            // Assign closest segment and scroll to it
            if (time >= segment.startOffset) {
              const newClosest = previousClosest !== segment.name;
              this.closestSegmentName = segment.name;

              // Only scroll if the new closest segment is different from
              // the previous one, and we are not manually scrolling.
              // If there is an active search then only scroll when user
              // clicks on the timeline and ignore playback related updates.
              if (newClosest && !isManuallyScrolling &&
                  (!this.searchInputControl.value || update.paused)) {
                // Ensure that the "closest" class is set in the DOM.
                this.cdr.detectChanges();
                this.utils.scrollToActive(
                    this.scrollView?.nativeElement, '.closest');
              }

              // The closest segment is found, exit the loop.
              break;
            }
          }
        });
  }


  /**
   * Finds all substring matching the search request and
   * filter segments with Multi-Keyword search".
   */
  private createSegmentsWithSearchMatches(input: string, segments: DisplaySegment[]) {
    let matchTotal = 0;

    if (!input) return { segments, matchTotal };
    // The search is done on html-escaped strings so we need to html-escape the
    // search query.
    input = this.utils.htmlEscape(input);

    const keywords = input.toLowerCase().trim().split(' ');

    // Create an array of regular expressions for each keyword to use in the search.
    const keywordRegexes = keywords.map(keyword => new RegExp(this.regExpEscape(keyword), 'ig'));

    const updatedSegments = segments.filter(segment => {
      const searchableValues = [
        segment.timeHtml,
        segment.playPeriodHtml,
        segment.descriptionHtml
      ];

      // Check if all keywords are present in any of the searchableValues.
      const hasAllKeywords = keywordRegexes.every(keywordRegex =>
        searchableValues.some(value => value && keywordRegex.test(value))
      );

      if (hasAllKeywords) {
        matchTotal++; // Count the match for the current segment.
        return true; // Keep the segment in the filtered results.
      }

      return false; // Exclude the segment from the filtered results.
    });

    return { segments: updatedSegments, matchTotal };
  }

  /** Moves active state to next or previous search match. */
  private changeActiveSearchMatch(direction: -1|1) {
    const maxIndex = this.searchMatchTotal - 1;
    this.activeSearchMatchIndex = this.activeSearchMatchIndex + direction;

    // Make matches cycle when reaching an end from any side.
    if (this.activeSearchMatchIndex < 0) {
      this.activeSearchMatchIndex = maxIndex;
    } else if (this.activeSearchMatchIndex > maxIndex) {
      this.activeSearchMatchIndex = 0;
    }

    this.markActiveSearchMatchAndScrollToIt(this.activeSearchMatchIndex);
  }

  private markActiveSearchMatchAndScrollToIt(matchIndex: number) {
    if (matchIndex < 0 || matchIndex >= this.searchMatchTotal) return;

    const searchMatchEls =
        Array.from(castExists(this.scrollView).nativeElement.querySelectorAll(
          `.${SEARCH_MATCH_CSS_CLASS}`));

    // Clear previous active match.
    for (const el of searchMatchEls) {
      el.classList.remove(ACTIVE_SEARCH_MATCH_CSS_CLASS);
    }
    searchMatchEls[matchIndex].classList.add(ACTIVE_SEARCH_MATCH_CSS_CLASS);

    this.utils.scrollToActive(
        this.scrollView?.nativeElement,`.${ACTIVE_SEARCH_MATCH_CSS_CLASS}`);
  }

  /** Escapes characters in the string that are not safe to use in a RegExp.  */
  private regExpEscape(value: string) {
    return value
        .replace(/[-[\]{}()*+?.<>',\\^$|#\s]/g, '\\$&')
        // eslint-disable-next-line no-control-regex
        .replace(/\u0008/g, '\\x08');
  }

  /** Refreshes the table. */
  refreshTable() {
    this.playFeedService.refreshSegments();
  }
}

interface ClickOptions {
  segmentStartOffset: number;
}
