import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';

import {castExists} from 'asserts/asserts';
import { DetailsNavigationService } from 'details/details_navigation_service';
import {SpriteSheet} from 'models';
import { VideoProtocolService } from 'services/video-protocol.service';

import {Asset} from '../services/asset_service';

import {BatchOperationService} from './batch_operation_service';

/** Cache up to 50MB of sprites before it gets cleared. */
export const MAX_CACHE_SIZE = new InjectionToken<number>(
    'Maximum cache size', {factory: () => 50_000_000});

/** Link to image used as asset thumbnail if actual thumbnail is not found. */
export const MISSING_THUMBNAIL = 'images/asset_missing_thumbnail.svg';

/** Generates a new FileReader. */
export const NEW_FILE_READER = new InjectionToken<() => FileReader>(
    'Generates a new FileReader', {factory: () => () => new FileReader()});

/** Helper methods to handle spriteSheets. */
@Injectable({providedIn: 'root'})
export class SpritesheetService {

  private linkName: string | undefined;

  constructor(
      @Inject(NEW_FILE_READER) private readonly newFileReader: () => FileReader,
      @Inject(MAX_CACHE_SIZE) private readonly maxCacheSize: number,
      private readonly batchOperationService: BatchOperationService,
      private readonly detailsNavigationService: DetailsNavigationService,
      private readonly videoProtocolService: VideoProtocolService,
      private readonly http: HttpClient,
  ) {
    if (this.videoProtocolService.isMobileWebKit() && this.detailsNavigationService.isSharedVideo()) {
      this.detailsNavigationService.context$.pipe(
        tap(context => this.linkName = context.link?.name),
        take(1)
      ).subscribe();
    }
  }

  /**
   * Renders a canonical asset thumbnail if available, or an empty placeholder.
   */
  getDefaultThumbnail(asset?: Asset): string {
    return asset?.thumbnail || MISSING_THUMBNAIL;
  }

  /**
   * Default placeholder as a CSS style instead of image URL.
   */
  getDefaultThumbnailStyle(asset?: Asset): SpriteStyle {
    return {
      background: `url(${this.getDefaultThumbnail(asset)}) center no-repeat`,
      backgroundSize: 'cover',
    };
  }

  /**
   * Returns the unique asset's spritesheet if it exists, otherwise `undefined`.
   */
  getSpriteSheet(asset: Asset): SpriteSheet|undefined {
    const midRendition = asset.renditions.find(r => {
      if (!r.spriteSheet.rowCount || !r.spriteSheet.columnCount) return false;
      return true;
    });
    return midRendition?.spriteSheet;
  }

  /**
   * Calculates which file to load given a spritesheet and the time of a
   * specific image. The file will be preloaded as a base64 string and ready to
   * be rendered.
   */
  loadSprite(spriteSheet: SpriteSheet, time = 0): Observable<Sprite|null> {
    // Interval or each sprite in seconds.
    // This line converts "4s" into 4, and any non-number into 0.
    const interval = Number(spriteSheet.interval.match(/\d+/));

    if (!interval) {
      console.warn('Unexpected spritesheet interval', spriteSheet);
      return of(null);
    }

    // Round time to the nearest sprite. We remove 1ms from the calculated time
    // so that if it was exactly between two frames (for instance 10.5s), we
    // round to the previous frame (10s) instead of the following one (11s). In
    // particular, this avoids requesting a frame at T=duration as it does not
    // exist. For instance on a 2s long video, there is only one frame at T=0.
    const spriteIndex = Math.round(time / interval - 0.001);

    const requestedTime = Date.now();

    const sheetUrl = this.getSheetUrl(spriteSheet, spriteIndex);

    // If this sprite has already been cached, use it. If the sprite is
    // currently being downloaded, it will be present in `spritesCache` but will
    // resolve later.
    if (this.spritesCache.base64.has(sheetUrl)) {
      return castExists(this.spritesCache.base64.get(sheetUrl)).pipe(map(base64 => {
        if (!base64) return null;

        return {
          base64,
          spriteSheet,
          spriteIndex,
          time,
          requestedTime,
        };
      }));
    }

    // This is the first time this sheet is requested since the cache has been
    // emptied. Set an observable that will emit the sheet's base64
    // once it is loaded.
    this.spritesCache.base64.set(sheetUrl, new ReplaySubject<string>(1));

    return this.batchOperationService.batchSignUrl(sheetUrl, this.linkName).pipe(
        switchMap(url => {
          if (!url) return of(null);
          return this.preloadImage(url);
        }),
        map(base64 => {
          if (!base64) return null;
          const sprite: Sprite = {
            base64,
            spriteSheet,
            spriteIndex,
            time,
            requestedTime,
          };
          return sprite;
        }),
        // Add sprite to cache and clear cache if it overflows.
        tap(sprite => {
          const cachedSprite$ = this.spritesCache.base64.get(sheetUrl);
          if (!cachedSprite$) return;

          if (!sprite) {
            cachedSprite$.next('');
            // Drop failed response from cache.
            this.spritesCache.base64.delete(sheetUrl);
            return;
          }

          cachedSprite$.next(sprite.base64);
          this.spritesCache.size += sprite.base64.length;
          if (this.spritesCache.size > this.maxCacheSize) {
            this.spritesCache.base64.clear();
            this.spritesCache.size = 0;
          }
        }));
  }

  /** Generate a background style that will render a particular image. */
  generateStyle(
      sprite: Sprite,
      hostWidth: number,
      hostHeight: number,
      ): SpriteStyle|null {
    const {spriteSheet, spriteIndex, base64} = sprite;

    // Format of each sprite width:height
    const spriteRatio =
        spriteSheet.spriteWidthPixels / spriteSheet.spriteHeightPixels;

    // Size of the spritesheet, zoomed to display ony one sprite.
    let size = spriteSheet.columnCount * hostWidth;

    // Desired height of the host to match the sprite format.
    let height = hostWidth / spriteRatio;

    // Calculate row/col of the thumbnail for this sprite.
    const absoluteRow = Math.floor(spriteIndex / spriteSheet.columnCount);
    const row = absoluteRow % spriteSheet.rowCount;
    const col = spriteIndex % spriteSheet.columnCount;

    let x = col * hostWidth;
    let y = row * height;

    // Stretch the background size and position so that the thumbnail covers its
    // container, with parts of it not visible.
    if (hostHeight > height) {
      // Case host taller than a thumbnail, we grow the thumbnail so that it
      // fully covers the host, and centers it.
      const zoomFactor = hostHeight / height;
      size *= zoomFactor;
      height *= zoomFactor;
      x = x * zoomFactor + ((hostWidth * zoomFactor - hostWidth) / 2);
      y = y * zoomFactor;
    } else if (hostHeight < height) {
      // Case host wider than a thumbnail, the thumbnail is already zoomed-in,
      // we simply center it vertically.
      y += (height - hostHeight) / 2;
    }

    const style: SpriteStyle = {
      backgroundImage: `url(${base64})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: `${size}px`,
      backgroundPosition: `-${x}px -${y}px`,
      height: `${height}px`,
    };

    return style;
  }

  /**
   * Keeps preloaded thumbnails in cache. The cache will be emptied whenever it
   * reaches full capacity `MAX_CACHE_SIZE`.
   */
  private readonly spritesCache = {
    /** Maps a sheetUrl to its base64 representation. */
    base64: new Map<string, ReplaySubject<string>>(),
    /** Size of the cache in bytes. */
    size: 0,
  };

  /**
   * Resolves to a preloaded image as a base64, or null if it fails.
   */
  private preloadImage(imageUrl: string): Observable<string|null> {
    return this.http
        .get(imageUrl, {
          headers: {'Accept': 'image/*'},
          responseType: 'blob',
        })
        .pipe(
            catchError(() => {
              return of(null);
            }),
            switchMap(async (blob) => {
              if (!blob) return null;
              // Note: we convert to base64 rather than rendering the Blob
              // directly  as this would still be asynchronous even though
              // immediate
              return this.blobToBase64(blob);
            }));
  }

  private async blobToBase64(blob: Blob) {
    const reader = this.newFileReader();

    const resultPromise = new Promise<string|null>(resolve => {
      reader.onerror = () => {
        resolve(null);
      };
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
    });

    reader.readAsDataURL(blob);
    return resultPromise;
  }

  /** Calculate the full URL given a prefix and parameters. */
  private getSheetUrl(spriteSheet: SpriteSheet, spriteIndex: number): string {
    // Calculate index of the thumbnail on its sheet
    const absoluteRow = Math.floor(spriteIndex / spriteSheet.columnCount);
    const sheetIndex = Math.floor(absoluteRow / spriteSheet.rowCount);

    // Generate URL with this thumbnail index
    let indexStr = String(sheetIndex);
    const l = indexStr.length;
    for (let i = 0; i < 10 - l; i++) {
      indexStr = `0${indexStr}`;
    }

    return `${spriteSheet.filePrefix}${indexStr}.jpeg`;
  }
}

/** CSS to render an image over background style */
export interface SpriteStyle {
  background?: string;
  backgroundImage?: string;
  backgroundRepeat?: string;
  backgroundSize?: string;
  backgroundPosition?: string;
  width?: string;
  height?: string;
}

/** In-memory sprite and its parameters. */
export interface Sprite {
  /** Time when the image preloading started. */
  requestedTime: number;
  spriteSheet: SpriteSheet;
  spriteIndex: number;
  /** Time of the sprite within the video. */
  time: number;
  base64: string;
}
