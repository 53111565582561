<!-- Make sure that the panels exist only when they have items to display -->
<ng-container
    *ngIf="(stagingService.activeItems$ | async) as activeItems; else noActiveItems">
  <mam-staging-metadata-panel
      *ngIf="activeItems.assets; else cutDownPanel">
  </mam-staging-metadata-panel>
  <ng-template #cutDownPanel>
    <mam-cut-down-panel></mam-cut-down-panel>
  </ng-template>
</ng-container>
<ng-template #noActiveItems>
  <div class="default-message">No active items.</div>
</ng-template>
