<ng-container *ngIf="labels$ | async as labels">
  <!-- Root breadcrumb item -->
  <button
    class="label item-header"
    mat-button
    (click)="activeButton = 0"
    [class.collapsed]="activeButton === 0">
    ...
  </button>

  <button
    class="label item-body breadcrumb__button--spacing"
    id="root-button"
    [class.expanded]="activeButton === 0"
    mat-button
    (click)="onRootLabelClick()"
    mam-firebase-ga="Select the root breadcrumb item"
    [mam-firebase-resource]="labels.root">
    {{labels.root}}
  </button>

  <!-- Clipbin breadcrumb item -->
  <ng-container *ngIf="labels.clipbin">
    <mat-icon class="separator">chevron_right</mat-icon>
    <button
      class="label item-header"
      mat-button
      (click)="activeButton = 1"
      [class.collapsed]="activeButton === 1"
      >
      ...
    </button>
    <div class="item-body"
      [class.expanded]="activeButton === 1">

      <button
          class="label"
          id="clipbin-button"
          mat-button
          [title]="labels.clipbin.title"
          (click)="onClipbinLabelClick(labels.clipbin)"
          mam-firebase-ga="Select clip bin breadcrumb item"
          [mam-firebase-resource]="labels.clipbin.name">
        {{labels.clipbin.title}}
      </button>

      <!-- Copy clipbin url button -->
      <button
          mat-icon-button
          [cdkCopyToClipboard]="getClipbinUrl()"
          (click)="showValueCopiedSnackbar('Clip Bin URL')"
          mam-firebase-ga="Copy Clip Bin Link"
          aria-label="Copy Clip Bin Link"
          matTooltip="Copy Clip Bin Link"
          class="clipboard clipboard-clipbin main__icon--size">
        <mat-icon>link</mat-icon>
      </button>
    </div>
  </ng-container>

  <!-- Main Asset -->
  <ng-container *ngIf="labels.asset?.title || labels.primaryCameraAsset">

    <mat-icon class="separator">chevron_right</mat-icon>

    <button
      class="label item-header"
      mat-button
      (click)="activeButton = 2"
      [class.collapsed]="activeButton === 2"
      >
      ...
    </button>

    <div
      class="item-body expanded asset-name-block"
      [class.expanded]="activeButton === 2">

      <ng-container *ngIf="!labels.primaryCameraAsset; else multiCameraNavigation">
        <div class="title" [title]="labels.asset?.title">
          {{labels.asset?.title}}
        </div>
      </ng-container>

      <!-- Primary camera asset title and current asset camera tag for multi-camera
            events -->
      <ng-template #multiCameraNavigation>

        <!-- Primary asset breadcrumb item -->
        <button
          class="label primary-asset-button"
          mat-button [title]="labels.primaryCameraAsset!.title"
          (click)="onPrimaryCameraAssetClick(labels.primaryCameraAsset!)"
          mam-firebase-ga="Select primary asset breadcrumb item"
          [mam-firebase-resource]="labels.primaryCameraAsset!.name">
          {{labels.primaryCameraAsset!.title}}
        </button>

        <!-- Camera label breadcrumb item -->
        <mat-icon class="separator">chevron_right</mat-icon>
        <div class="title camera-label" [title]="labels.cameraLabel">
          {{labels.cameraLabel}}
        </div>
      </ng-template>

      <!-- Copy asset url button -->
      <button mat-icon-button [cdkCopyToClipboard]="getAssetUrl()"
        (click)="showValueCopiedSnackbar(originalOrClip(labels) + ' URL')"
        [mam-firebase-ga]="'Copy ' + originalOrClip(labels) + ' Link'"
        [attr.aria-label]="'Copy ' + originalOrClip(labels) + ' Link'"
        [matTooltip]="'Copy ' + originalOrClip(labels) + ' Link'" class="main__icon--size clipboard clipboard-asset">
        <mat-icon>link</mat-icon>
      </button>
      <mam-asset-info-icon-button *ngIf="labels.asset" [asset]="labels.asset">
      </mam-asset-info-icon-button>
    </div>
  </ng-container>
</ng-container>
