import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/** Service that controls visibility of the progress bar on the home page. */
@Injectable({providedIn: 'root'})
export class ProgressbarService {
  /** Emits the current status of the progress bar */
  readonly visible$ = new BehaviorSubject(false);

  show() {
    this.visible$.next(true);
  }

  hide() {
    this.visible$.next(false);
  }
}
