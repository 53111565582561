import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {delay} from 'rxjs/operators';

import {CloudTransferOperation, Folder} from 'models';

import {CloudIngestApiService} from './cloud_ingest_api_service';
import {ApiCloudTransferOperationStateEnum, ApiStorageTransferInfoJobState, ApiStorageTransferInfoJobStateEnum, FolderTypeEnum} from './ias_types';
import {Interface} from './utils_service';

/** Fake cloud ingest API service */
@Injectable({providedIn: 'root'})
export class FakeCloudIngestApiService implements
    Interface<CloudIngestApiService> {
  initiateCloudTransfer(): Observable<Folder> {
    const folder = new Folder({
      folderId: 'fake-folder',
      // Used for frequent sync between two cloud sources
      type: FolderTypeEnum.FOLDER_TYPE_CLOUD_WATCH,
      storageTransferInfo: {
        // JOB_STATE_UNSPECIFIED is the initial value
        jobState: ApiStorageTransferInfoJobStateEnum.JOB_STATE_UNSPECIFIED,
        transferJobId: 'fake-transfer-job-id',
      },
    });

    return of(folder).pipe(delay(200));
  }

  updateTransferInfo(
      folderName: string,
      jobState: ApiStorageTransferInfoJobState): Observable<Folder> {
    const folder = new Folder({
      name: folderName,
      folderId: 'fake-folder',
      // Used for frequent sync between two cloud sources
      type: FolderTypeEnum.FOLDER_TYPE_CLOUD_WATCH,
      storageTransferInfo: {
        jobState,
        transferJobId: 'fake-transfer-job-id',
      },
    });

    return of(folder).pipe(delay(200));
  }

  listTransferOperations(jobId: string) {
    const succeedOp1 = new CloudTransferOperation({
      name: 'transfer-1',
      startTime: new Date(2022, 1, 1, 12, 0).getTime().toString(),
      endTime: new Date(2022, 1, 1, 12, 11).getTime().toString(),
      transferJobId: 'transfer-1',
      operationState: ApiCloudTransferOperationStateEnum.SUCCEEDED,
    });
    const succeedOp2 = new CloudTransferOperation({
      name: 'transfer-2',
      startTime: new Date(2022, 1, 1, 11, 0).getTime().toString(),
      endTime: new Date(2022, 1, 1, 11, 11).getTime().toString(),
      transferJobId: 'transfer-2',
      operationState: ApiCloudTransferOperationStateEnum.SUCCEEDED,
    });
    const failedOp = new CloudTransferOperation({
      name: 'transfer-3',
      startTime: new Date(2022, 1, 1, 10, 0).getTime().toString(),
      endTime: new Date(2022, 1, 1, 10, 11).getTime().toString(),
      transferJobId: 'transfer-3',
      operationState: ApiCloudTransferOperationStateEnum.FAILED,
    });
    const inProgressOp = new CloudTransferOperation({
      name: 'transfer-4',
      startTime: new Date(2022, 1, 1, 9, 0).getTime().toString(),
      transferJobId: 'transfer-4',
      operationState: ApiCloudTransferOperationStateEnum.IN_PROGRESS,
    });

    if (jobId.includes('on-demand')) {
      const operation = {transferOperations: [succeedOp1]};
      return of(operation).pipe(delay(200));
    } else if (jobId === 'folder-4') {
      const operations = {transferOperations: [failedOp, succeedOp2]};
      return of(operations).pipe(delay(200));
    }

    const operations = {
      transferOperations: [succeedOp1, succeedOp2, failedOp, inProgressOp]
    };
    return of(operations).pipe(delay(200));
  }
}
