import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

import {Site} from 'models';

import {AuthService} from '../auth/auth_service';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {HomeView} from '../services/state_service';

/**
 * Main view selector, to the left of the home page.
 */
@Component({
  selector: 'mam-home-sidenav',
  templateUrl: './home_sidenav.ng.html',
  styleUrls: ['./home_sidenav.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeSidenav {
  @Input() currentView?: HomeView;
  @Input() failedTaskCount = 0;
  @Input() isSmallScreenLandScape = false;

  @Input() sites: Site[] = [];

  @Output() readonly selectView = new EventEmitter<HomeView>();

  readonly HomeView = HomeView;

  /** Flag to show admin page. */
  readonly showAdminPageFF =
      this.featureService.featureOn('show-admin-page');

  constructor(
      private readonly featureService: FeatureFlagService,
      readonly authService: AuthService,
  ) {}

  isLandingDisplayed() {
    return this.currentView === HomeView.LANDING;
  }

  isLiveDisplayed() {
    return this.currentView === HomeView.LIVE;
  }

  isJobsDisplayed() {
    return this.currentView === HomeView.JOBS;
  }

  isStagingDisplayed() {
    return this.currentView === HomeView.STAGING;
  }

  isAdminDisplayed() {
    return this.currentView === HomeView.ADMIN;
  }
}
