<ng-container *ngIf="{
  isEditing: metadataService.isEditing$| async,
  isLoading: stateService.isPanelLoading$ | async
} as tplState">
  <ng-container *ngIf="cutdown && parent">
    <div class="panel-header">
      <h1>{{isNew(cutdown) ? 'New ' : ''}}Cutdown</h1>

      <!-- Edit/Save/Cancel buttons -->
      <div *ngIf="!tplState.isEditing">
        <button
            [disabled]="cutdown.state !== ApiCopyStateDetailStateEnum.STATE_DRAFT"
            class="edit"
            mat-button
            mam-firebase-ga="Start cutdown changes"
            [mam-firebase-resource]="cutdown.name"
            (click)="metadataService.edit()">
          Edit
        </button>
      </div>
      <button
          *ngIf="tplState.isEditing"
          class="cancel"
          mat-button
          mam-firebase-ga="Cancel metadata changes"
          [mam-firebase-resource]="cutdown.name"
          [disabled]="tplState.isLoading"
          (click)="cancel()">
        Cancel
      </button>
      <button
          *ngIf="tplState.isEditing"
          class="save"
          mat-button
          mam-firebase-ga="Save metadata changes"
          [mam-firebase-resource]="cutdown.name"
          [disabled]="!metadataService.isValid() || tplState.isLoading"
          (click)="save()">
        Save
      </button>
    </div>

    <!-- List of fields and special items -->
    <div class="panel-scrollview"
      [class.loading]="tplState.isLoading"
      [class.editing]="tplState.isEditing">

       <!-- Embedded player to preview cutdown-->
      <mam-player-with-controls
            *ngIf="fakePreviewAsset"
            [asset]="fakePreviewAsset"
            [compact]="true"
            [disabledMarking]="true"
            [disabledAddClip]="true"
            [signer]="signer"
            (playerError)="onPlayerError($event)">
        </mam-player-with-controls>

      <!-- Special schema selector -->
      <ng-container *ngIf="cutdown.name && !isNew(cutdown)">
        <mam-select-schema
            [schemas]="(schemas$ | async) ?? []"
            placeholder="Choose a schema"
            [resourceKey]="parent.name+'/'+cutdown.name"
            [control]="metadataService.selectedSchema"
            [metadataFieldStyle]="true">
        </mam-select-schema>
      </ng-container>

      <!-- Readonly cut-down file name -->
      <mat-form-field
          appearance="outline"
          class="metadata-field file-name">
        <mat-label>File name</mat-label>
        <textarea
            readonly="true"
            [value]="cutdown.fileName"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="16">
        </textarea>
      </mat-form-field>

      <!-- Cut-down metadata fields -->
      <mam-metadata-fields></mam-metadata-fields>
    </div>
  </ng-container>
</ng-container>
