import {ApiAssetStateDetail} from 'api/ias/model/api-asset-state-detail';
import {RequiredRecursively} from 'services/utils_service';

import {RpcStatus} from './rpc-status';

/** A UI model representing asset state details. */
export class AssetStateDetail implements
    RequiredRecursively<ApiAssetStateDetail> {
  errorDetails = new RpcStatus(this.apiStateDetails.errorDetails ?? {});
  state = this.apiStateDetails.state ?? 'STATE_UNSPECIFIED';

  constructor(private apiStateDetails: ApiAssetStateDetail = {}) {}
}
