import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

import {isErrorResponse} from '../error_service/error_response';
import {CloudIngestService, GcsData} from '../services/cloud_ingest_service';
import {ApiCloudTransferJobScheduleType as ScheduleType, ApiTransferJobScheduleTypeEnum as ScheduleTypeEnum} from '../services/ias_types';
import {SnackBarService} from '../services/snackbar_service';

/**
 * Cloud ingest creation dialog
 */
@Component({
  selector: 'mam-cloud-ingest-creation-dialog',
  templateUrl: './cloud_ingest_creation_dialog.ng.html',
  styleUrls: ['./cloud_ingest_creation_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloudIngestCreationDialog {
  readonly scheduleType = ScheduleTypeEnum;
  readonly cloudIngestFormGroup = new UntypedFormGroup({
    'bucketControl': new UntypedFormControl(null, [Validators.required]),
    'filePathControl': new UntypedFormControl(),
    'scheduleControl': new UntypedFormControl(null, [Validators.required]),
  });

  constructor(
      private readonly snackBar: SnackBarService,
      private readonly cloudIngestService: CloudIngestService,
      readonly dialogRef: MatDialogRef<CloudIngestCreationDialog>,
  ) {}

  submit() {
    if (!this.cloudIngestFormGroup.valid) return;

    const formControls = this.cloudIngestFormGroup.controls;
    const gcsData: GcsData = {
      bucket: formControls['bucketControl'].value,
      path: formControls['filePathControl'].value,
    };
    const scheduleType: ScheduleType = formControls['scheduleControl'].value;
    this.cloudIngestService.create(gcsData, scheduleType).subscribe(result => {
      if (isErrorResponse(result)) {
        this.snackBar.error({
          message: 'Create cloud ingest task failed.',
          details: result.message,
          doNotLog: true,
        });
      } else {
        this.snackBar.message('Cloud ingest task has been created');
      }
    });
  }
}
